import { useSelector } from 'react-redux';

import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { isBankAdvisor } from 'store/user/selectors';

interface IsForwardableAttributes {
  canForwardToCompeon: boolean;
  hasBankAdvisorRole: boolean;
  isBankAdvisorEnabled: boolean;
  isCompeonForwardingEnabled: boolean;
}

export const isForwardable = ({
  canForwardToCompeon,
  hasBankAdvisorRole,
  isBankAdvisorEnabled,
  isCompeonForwardingEnabled,
}: IsForwardableAttributes) => {
  const isForwardableToCompeon = isCompeonForwardingEnabled && canForwardToCompeon;
  const isForwardableForUser = !hasBankAdvisorRole || isBankAdvisorEnabled;

  return {
    isForwardable: isForwardableToCompeon || isForwardableForUser,
    isForwardableToCompeon,
    isForwardableForUser,
  };
};

export const useInquiryForwarding = () => {
  const hasBankAdvisorRole = useSelector(isBankAdvisor);
  const isCompeonForwardingEnabled = useConfig(CONFIG.IS_COMPEON_FORWARDING_ENABLED);
  const isBankAdvisorEnabled = useConfig(CONFIG.IS_BANK_ADVISOR_ENABLED);
  const { canForwardToCompeon } = useInquiryPermissions();

  return isForwardable({
    canForwardToCompeon,
    hasBankAdvisorRole,
    isBankAdvisorEnabled,
    isCompeonForwardingEnabled,
  });
};
