import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { clearFormProgress, clearFormData } from 'shared/inquiry/inquirySessionStorage.helpers';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { resetInquiryDetailsAction } from 'store/inquiryDetails/actions';
import {
  resetForm as resetFormAction,
  clearStages as clearStagesAction,
} from 'store/progress/actions';

const fieldController = FormFieldController.instance;

const useInquiryReset = (isInquiryForEdit = false) => {
  const [isReset, setIsReset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const reset = () => {
      // clear sessionStorage
      clearFormData();
      clearFormProgress();
      // clear redux
      if (!isInquiryForEdit) {
        dispatch(resetInquiryDetailsAction());
      }

      dispatch(resetFormAction(true));
      dispatch(clearStagesAction());

      // clear repos
      fieldController.destroy();
    };
    reset();
    setIsReset(true);
  }, [dispatch, isInquiryForEdit]);

  return { isReset };
};

export default useInquiryReset;
