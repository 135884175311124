import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { getUser } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const EmailStaticField = () => {
  const user = useSelector(getUser);
  const t = useTranslations(
    'pages.userProfile.sections.completingContactData.fields.userProfileEmail',
  );
  return <StaticField caption={t('caption')} text={user.email} />;
};

export default EmailStaticField;
