import { useMemo } from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryMapType } from 'modules/Inquiry/InquiryMapType';
import {
  combineWithOptionalValidators,
  containsLowerCase,
  containsNonAlphaNumeric,
  containsNumericChar,
  containsUpperCase,
  hasMinimalLength,
  isValidPassword,
  required,
} from 'utils/validators';

import { useTranslations } from './useTranslations';

export const usePartnerSpecificValidators = () => {
  const formConfig = useFormConfig();
  const t = useTranslations('errors');

  return useMemo(() => {
    const VALIDATORS_MAP: InquiryMapType<unknown> = {
      [InquiryType.mmv]: combineWithOptionalValidators(
        [required(t('required')), hasMinimalLength(t('passwordMinLength', { number: 10 }), 10)],
        [
          containsLowerCase(t('passwordMustHaveLowerCase')),
          containsUpperCase(t('passwordMustHaveUpperCase')),
          containsNumericChar(t('passwordMustHaveNumber')),
          containsNonAlphaNumeric(t('passwordMustHaveNonAlphanumeric')),
        ],
        1,
      ),
      default: combineWithOptionalValidators([
        required(t('required')),
        isValidPassword(t('isValidPassword')),
      ]),
    };
    return VALIDATORS_MAP[formConfig.selectedInquiryType];
  }, [formConfig, t]);
};
