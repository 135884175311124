import React, { useMemo, useEffect, useState } from 'react';

import countBy from 'lodash/countBy';
import { useDispatch, useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import {
  setProgressCounter as setProgressCounterAction,
  setProgressCounterToMax,
} from 'store/progress/actions';
import { getStagesSelector } from 'store/progress/selectors';

const useCountValidSections = (sectionValidators: any, form: any) => {
  const [numOfValidSections, setNumOfValidSections] = useState(0);
  const { selectedInquiryType } = useFormConfig();

  useEffect(() => {
    const countSections = async () => {
      if (sectionValidators && sectionValidators.length > 0) {
        const validateSections = [];
        for (const validator of sectionValidators) {
          const isSectionValid = await validator(form, selectedInquiryType);
          validateSections.push(isSectionValid);
        }

        setNumOfValidSections(countBy(validateSections).true || 0);
      }
    };

    countSections();
  }, [sectionValidators, form, selectedInquiryType]);

  return numOfValidSections;
};

// temp solution to update progress counter
const withProgressUpdater = (Component: React.FC) => (props: any) => {
  const dispatch = useDispatch();
  const currentStageState = useSelector(getStagesSelector)[props.stageName];

  const sections = useMemo(() => props.sections(props.form), [props]);
  const numOfValidSections = useCountValidSections(sections, props.form);

  if (!sections) {
    return <Component {...props} />;
  }

  const current = numOfValidSections;
  const total = sections?.length;
  const stage = props.stageName;

  useEffect(() => {
    if (sections?.length === 0) {
      dispatch(setProgressCounterToMax(stage));
    }
  }, [dispatch, sections.length, stage]);

  useEffect(() => {
    if (
      sections?.length &&
      (current !== currentStageState?.currentStep || total !== currentStageState.stepCount)
    ) {
      dispatch(setProgressCounterAction({ current, total, stage }));
    }
  }, [
    sections,
    currentStageState.currentStep,
    currentStageState.stepCount,
    currentStageState.total,
    dispatch,
    current,
    total,
    stage,
  ]);

  return <Component {...props} />;
};

export default withProgressUpdater;
