import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useIsUnderPaths = (paths: string[]) => {
  const location = useLocation();

  return useMemo(
    () => paths.find((path) => location.pathname.includes(path)),
    [location.pathname, paths],
  );
};
