import { FONT_WEIGHT_REGULAR } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const Tooltip = {
  baseStyle: {
    position: 'relative',
    px: '24px',
    py: '12px',
    boxShadow: 'default',
    ...textStyle.sizeMTight,
    fontWeight: FONT_WEIGHT_REGULAR,
    overflowWrap: 'break-word',
    color: 'text.white',
    bg: 'background.blackGrey',
    children: [],
  },
};

export default Tooltip;
