import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  COMPANY_DETAILS_CONSENT,
  USER_PROFILE,
} from 'modules/Inquiry/Form/formFields';
import ContactPerson from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { loadCompaniesAction } from 'pages/inquiryFlow/CompanyDetails/service';
import { useCompanyDetailsSectionValidators } from 'pages/inquiryFlow/CompanyDetails/useCompanyDetailsSectionValidators';
import UserProfileHeading from 'pages/inquiryFlow/UserProfile/Heading/UserProfileHeading';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { getUser as getCustomerSelector, isLoggedInSelector } from 'store/user/selectors';
import { removeQueryParamsFromUrl } from 'utils/paths';

export const UserProfileUnwrapped = () => {
  const getSectionsValidators = useCompanyDetailsSectionValidators();

  useEffect(() => {
    // Clearing out query params to not pass token in URL
    // See more at CRV-540
    removeQueryParamsFromUrl();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCompaniesAction());
  }, [dispatch]);

  // if user is loggedIn, set values from store
  const customer = useSelector(getCustomerSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userInitialValues = {
    [COMPANY_DETAILS_EMAIL]: customer.email,
    [COMPANY_DETAILS_FIRST_NAME]: customer.firstName,
    [COMPANY_DETAILS_LAST_NAME]: customer.lastName,
    [COMPANY_DETAILS_GENDER]: customer.gender,
    [COMPANY_DETAILS_CONSENT]: true,
  };
  const initialValues = isLoggedIn ? userInitialValues : undefined;

  const fieldController = FormFieldController.instance;
  fieldController.saveInitial(initialValues);

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={USER_PROFILE}
      headerComp={<UserProfileHeading />}
      getSectionValidators={getSectionsValidators}
    >
      {() => (
        <>
          <ContactPerson sectionNumber={1} />
        </>
      )}
    </InquiryFormNew>
  );
};

export default React.memo(UserProfileUnwrapped);
