import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import DownloadBlock from 'components/DownloadBlock';
import { IBankOffer } from 'models/BankOffer.model';
import AcceptOfferBtn from 'modules/Offers/InquiryOffersSection/AcceptOffer/AcceptOfferButton';
import { StyledDescription as Description } from 'modules/Offers/InquiryOffersSection/BankAdvisorOffers/OfferBox/styles';
import OfferAcceptedMsg from 'modules/Offers/InquiryOffersSection/OfferAcceptedMsg/OfferAcceptedMsg';
import { SingleOffer } from 'modules/Offers/InquiryOffersSection/styles';
import { useToasts } from 'shared/hooks/useToasts';
import { acceptBankOffer } from 'store/inquiryDetails/actions';
import { hasAcceptedBankOfferSelector } from 'store/inquiryDetails/selectors';
import { isAgent } from 'store/user/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { DOWNLOAD_STATUS_TO_OFFER } from './constants';

interface Props {
  offer: IBankOffer;
}

const OfferBox = ({ offer }: Props) => {
  const { bankName, fileId, status } = offer;
  const t = useTranslations('');
  const modalTitle = `${t('pages.inquiryDetails.offers.acceptOffer')} – ${bankName}`;
  const { makeCall } = useDispatchApiCall();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isOperationUser = useSelector(isAgent);
  const hasAcceptedCompeonOffer = useSelector(hasAcceptedBankOfferSelector);
  const { success } = useToasts();

  const file = {
    id: fileId || '',
    fileName: bankName,
    status: DOWNLOAD_STATUS_TO_OFFER[status],
  };

  const handleOfferAccepted = async () => {
    await makeCall(acceptBankOffer(offer.id), () => {
      success({ description: t('inquiry.statuses.offerAccepted') });
      onClose();
    });
  };

  const shouldDisableAcceptingOffer = hasAcceptedCompeonOffer || isOperationUser;

  return (
    <SingleOffer data-testid="single-offer">
      <DownloadBlock file={file} />
      {offer.status === 'accepted' && <OfferAcceptedMsg status={offer.status} isNeutral />}
      {!shouldDisableAcceptingOffer && (
        <AcceptOfferBtn disabled={shouldDisableAcceptingOffer} onClick={onOpen} />
      )}
      <AlertDialogComponent
        cancelText={t('pages.inquiryDetails.offers.cancel')}
        confirmText={t('pages.inquiryDetails.offers.acceptOffer')}
        testId="accept-offer-confirm"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleOfferAccepted}
        title={modalTitle}
      >
        <Description>{t('pages.inquiryDetails.offers.acceptOfferDesc', { bankName })}</Description>
      </AlertDialogComponent>
    </SingleOffer>
  );
};

export default OfferBox;
