import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { resetForm, clearStages } from 'store/progress/actions';
import { resetUser } from 'store/user/actions';
import { removeObject } from 'utils/sessionStorage/helpers';
import {
  COMPANY_DETAILS,
  FINANCING_NEED,
  PEOPLE_DETAILS,
  REQUEST_DETAILS,
  INSTALMENT_PROCESS,
  FORM_VALIDITY,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  FORM_VALUES,
  CONTACT_PERSON_DETAILS,
  USER_PROFILE,
} from 'utils/sessionStorage/keys';

import { leasePlanFormValuesCleanUp } from './../../CustomerData';

// returns function to clear form values
export const useFormCleaner = (isLoggedIn) => {
  const shouldResetForm = useSelector((store) => store.progress.shouldResetForm);
  const dispatch = useDispatch();
  const { selectedInquiryType } = useFormConfig();

  return useCallback(
    (form) => {
      if (shouldResetForm) {
        form.reset();
        dispatch(resetForm(false));
        dispatch(clearStages());
        removeObject(FINANCING_NEED);
        removeObject(COMPANY_DETAILS);
        removeObject(PEOPLE_DETAILS);
        removeObject(REQUEST_DETAILS);
        removeObject(CONTACT_PERSON_DETAILS);
        removeObject(INSTALMENT_PROCESS);
        removeObject(FORM_VALIDITY);
        removeObject(CONTRACT_DETAILS);
        removeObject(CUSTOMER_DATA);
        removeObject(USER_PROFILE);
        if (selectedInquiryType === InquiryType.leaseplan) {
          leasePlanFormValuesCleanUp();
        } else {
          removeObject(FORM_VALUES);
        }

        /*
         * BigPicture inquiry has temporary AccessToken which is stored in the user store. This should
         * be reset after inquiry was sent
         */
        if (!isLoggedIn) {
          dispatch(resetUser());
        }
      }
    },
    [dispatch, isLoggedIn, shouldResetForm, selectedInquiryType],
  );
};
