import React, { useEffect } from 'react';

import { Grid, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import PERMISSIONS from 'constants/user/permissions';
import { useGetRiskAnalysis } from 'pages/operationPortal/CompaniesDetails/helpers/useGetRiskAnalysis';
import {
  getBlackAndWhiteReport,
  getCompanyDetails as companyDetailsSelector,
  getRatingReport,
  getShortReport,
} from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { useCreditReformProducts } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/useCreditReformProducts';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import {
  IInquiryDetails,
  ReportType,
} from '../../../../models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryLane } from '../../../../modules/Inquiry/InquiryLane';
import CONFIG from '../../../../shared/featureFlagConfig/configFromAdmin';
import { useConfig } from '../../../../shared/featureFlagConfig/useConfig';
import { getInquiryDetailsSelector } from '../../../../store/inquiryDetails/selectors';
import { getAllKpis, getKpiDateRange } from '../../../../store/kpi/kpis.selectors';
import { InformationDatePickerProvider } from '../../../../theme/components/FinancialInformations/context/DatePickerContext';
import { FallbackComponent } from '../FallbackComponent';
import { BlackWhite } from './BlackWhite/BlackWhite';
import { KeyFigureAnalysis } from './KeyFigureAnalysis';
import { Rating } from './Rating';
import { RiskClass } from './RiskClass';
import { Score } from './Score';
import { TrafficLights } from './TrafficLight/TrafficLights';

const RiskAnalysis = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const t = useTranslations('pages.companiesDetails.riskAnalysis');
  const { getRiskAnalysis } = useGetRiskAnalysis();
  const companyDetails = useSelector(companyDetailsSelector);
  const report = useSelector(getRatingReport);
  const shortReport = useSelector(getShortReport);
  const blackWhiteReport = useSelector(getBlackAndWhiteReport);
  const kpisDateRange = useSelector(getKpiDateRange);
  const kpis = useSelector(getAllKpis);
  const isFallback = Object.keys(kpis).length === 0;
  const isEvalueinEnabled = useConfig(CONFIG.IS_EVALUEIN_ENABLED);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const isContract = inquiryDetails.lane === InquiryLane.contract;
  const { reformProductsMap } = useCreditReformProducts();

  useEffect(() => {
    getRiskAnalysis(companyId);
  }, [getRiskAnalysis, companyId]);

  return (
    <Container>
      <Box pt="12" pb="20">
        <HeadingComponent as="h2" variant="primary" mb={8}>
          {t('title')}

          <TextComponent
            as="span"
            display="block"
            fontWeight={FONT_WEIGHT_REGULAR}
            color="text.tertiary"
          >
            {companyDetails.companyName}
          </TextComponent>
        </HeadingComponent>

        <article>
          <HeadingComponent
            as="h3"
            variant="secondary"
            mb={6}
            data-testid={'CD-Risk-Analysis-Heading'}
          >
            {t('creditForm')}
          </HeadingComponent>

          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
            gap={6}
            mb={6}
          >
            {reformProductsMap[ReportType.TRAFFIC_LIGHT] && <TrafficLights isRiskAnalysisPage />}
            {reformProductsMap[ReportType.SHORT] && (
              <RiskClass heading={t('riskClass')} report={shortReport} isRiskAnalysisPage />
            )}
            {reformProductsMap[ReportType.COMPACT] && <Score isRiskAnalysisPage />}
            {reformProductsMap[ReportType.RATING] && (
              <Rating report={report} heading={t('ratingClass')} isRiskAnalysisPage />
            )}
            {reformProductsMap[ReportType.BLACK_WHITE] && (
              <BlackWhite report={blackWhiteReport} heading={t('blackWhite')} isRiskAnalysisPage />
            )}
          </Grid>
        </article>

        {isEvalueinEnabled && !isContract && (
          <article>
            <HeadingComponent
              as="h3"
              variant="secondary"
              mb={6}
              data-testid={'Key-Figure-Analysis-Heading'}
            >
              {t('keyFigure')}
            </HeadingComponent>

            {isFallback ? (
              <FallbackComponent />
            ) : (
              <InformationDatePickerProvider initialValue={new Date(kpisDateRange.lastKpi)}>
                <KeyFigureAnalysis />
              </InformationDatePickerProvider>
            )}
          </article>
        )}
      </Box>
    </Container>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(RiskAnalysis);
