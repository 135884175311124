import React from 'react';

import { InputWithField } from 'components/Input';
import { END_USER_FIRST_NAME } from 'modules/Inquiry/Form/formFields';

const EndUserFirstName = () => {
  return <InputWithField name={END_USER_FIRST_NAME} />;
};

export default EndUserFirstName;
