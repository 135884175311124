import { mittweidaFields } from 'mittweida/inquiry/fields';
import { MAX_AMOUNT_VALUE } from 'modules/Inquiry/inquiry.constants';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateProjectCompositionSection: ValidateSectionFunction = (form, values) => {
  return validateFieldValidationMap({
    validationMap: projectCompositionSectionValidationMap,
    form,
    values,
  });
};

export const projectCompositionSectionValidationMap = {
  [mittweidaFields.financingNeedPage.projectCompositionSection.totalInvestment]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.totalInvestmentInBuyingPhase]:
    fieldValidators.price().required().maxPrice(MAX_AMOUNT_VALUE).positive().allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownFunds]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownFundsInBuyingPhase]:
    fieldValidators.price().required().maxPrice(MAX_AMOUNT_VALUE).positive().allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.mezzanineCapital]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.subordinatedCapital]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownWork]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject]: fieldValidators
    .boolean()
    .required(),
  [mittweidaFields.financingNeedPage.projectCompositionSection.financingAmount]: fieldValidators
    .price()
    .required()
    .positive()
    .allow(0),
};
