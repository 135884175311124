import _get from 'lodash/get';

import {
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_NUMBER_OF_PARKING_LOTS,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_NUMBER_OF_GARAGES,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapPresalesValuesToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const presalesValuesData = {
    presales_area_residential: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_AREA_RESIDENTIAL)),
    ),
    presales_price_residential_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM)),
    ),
    presales_area_commercial: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_AREA_COMMERCIAL)),
    ),
    presales_price_commercial_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM)),
    ),
    presales_parking_lots: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_NUMBER_OF_PARKING_LOTS)),
    ),
    presales_price_parking_lots: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE)),
    ),
    presales_garage: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_NUMBER_OF_GARAGES)),
    ),
    presales_price_garage: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE)),
    ),
  };

  return presalesValuesData;
};

export default mapPresalesValuesToApi;
