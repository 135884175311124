import _get from 'lodash/get';
import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import {
  LOAD_COMPANIES_FAILURE,
  LOAD_USER_ATTRIBUTES_SUCCESS,
} from 'api/CompeonReverseApi/actionTypes';
import { UserRole } from 'constants/user/userRoles';
import {
  GET_IDENTITY_TOKEN_SUCCESS,
  LOG_IN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  LOG_OUT_SUCCESS,
  TOKEN_EXPIRED,
  TOKEN_SET,
} from 'shared/auth/loginLogout.actionTypes';
import { UPDATE_USER_DATA } from 'store/user/actions';
import { getUpdateState } from 'utils/storeHelpers';

import { RESET_USER, SET_EMAIL, SET_BIRTHDATE } from './actionTypes';
import { getUserAttributes, getExpirationDate, getUserTokens, getUserDetails } from './service';

interface IUserAttributes {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  gender?: string;
  vbNumber?: string;
  zipCode?: string;
  roles?: UserRole[];
  birthDate?: string;
}

interface IUserState {
  token?: string;
  refreshToken?: string;
  expiresAt?: string;
  scope?: string;
  attributes?: IUserAttributes;
}

const INITIAL_STATE: IUserState = {
  attributes: {
    roles: [],
  },
};

const reducer = (state = INITIAL_STATE, action: AnyAction): IUserState => {
  const payloadData = _get(action, 'payload.data', {});
  const status = _get(action, 'error.response.status');
  const updateState = getUpdateState(state);

  switch (action.type) {
    case LOG_IN_SUCCESS: {
      if (payloadData) {
        const attributes = getUserAttributes(payloadData.access_token);

        return {
          ...getUserTokens(payloadData),
          expiresAt: getExpirationDate(payloadData.created_at, payloadData.expires_in),
          scope: payloadData.scope,
          attributes,
        };
      }
      return INITIAL_STATE;
    }
    case GET_IDENTITY_TOKEN_SUCCESS: {
      const attributes = getUserAttributes(payloadData.access_token);

      return {
        ...getUserTokens(payloadData),
        attributes,
      };
    }
    case LOAD_USER_ATTRIBUTES_SUCCESS: {
      const { attributes } = payloadData.data;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          ...getUserDetails(attributes),
        },
      };
    }
    case LOAD_COMPANIES_FAILURE: {
      if (status === 401) {
        return INITIAL_STATE;
      }
      return state;
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        ...getUserTokens(payloadData),
        expiresAt: getExpirationDate(payloadData.created_at, payloadData.expires_in),
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        attributes: {
          ...state.attributes,
          email: action.payload.email,
        },
      };
    }
    case SET_BIRTHDATE: {
      return {
        ...state,
        attributes: {
          ...state.attributes,
          birthDate: action.payload.birthdate,
        },
      };
    }
    case LOG_OUT_SUCCESS:
    case TOKEN_EXPIRED:
    case REFRESH_TOKEN_FAILURE: {
      return INITIAL_STATE;
    }
    case TOKEN_SET: {
      return updateState({ ...state, ...action.payload });
    }
    case UPDATE_USER_DATA:
      return updateState({ attributes: { ...state.attributes, ...action.payload } });
    case RESET_USER:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'user',
  storage,
};

export default persistReducer(persistConfig, reducer);
