import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

export const StyledItemWrapper = styled.div`
  padding: 1.875rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};

  :not(:last-child) {
    margin-bottom: 1.875rem;
  }
`;

export const StyledUserField = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.secondary};

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledStatusField = styled(StyledUserField)`
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledDateField = styled(StyledUserField)`
  color: ${(props) => props.theme.colors.text.tertiary};
`;
