import React from 'react';

import _isNil from 'lodash/isNil';
import { bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import InputMask from 'react-input-mask';

import ControlWrapper from 'components/ControlWrapper';
import InputContainer from 'components/Input/InputWrapper/InputContainer';
import MaskedInput from 'components/MaskedInput';
import SymbolWrapper from 'components/SideSymbol/SymbolWrapper';
import TextInput from 'components/TextInput';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledClearButton } from './StyledClearButton';

export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_DECIMAL = 'decimal';
export const INPUT_TYPE_DATE = 'dateInput';
export const INPUT_TYPE_DECICMAL_WITH_ZERO = 'decimalZero'; // This will allow 0 as an input with decimal entries
export const INPUT_TYPE_DECIMAL_WITH_THREE_PLACES = 'decimalWithThreePlaces';

const getInputComponent = (inputProps) => {
  /**
   * Determines the value to be assigned to 'inputValue'.
   *
   * This code checks if restProps.value is null or undefined, and if restProps.modified is falsy.
   * The explicit check for null and undefined is necessary because we want to ensure that the
   * condition evaluates to true only when restProps.value is truly empty (i.e., null or undefined),
   * and not when it has a value that is considered falsy in JavaScript (e.g., 0, false, or an empty string).
   *
   * If the condition is true, inputValue will be assigned the value of restProps.initial.
   * Otherwise, inputValue will be assigned the value of restProps.value.
   */

  const inputValue =
    _isNil(inputProps.value) && !inputProps.modified ? inputProps.initial : inputProps.value;

  if (inputProps.type === INPUT_TYPE_NUMBER) {
    return (
      <MaskedInput
        sideSymbol={inputProps.sideSymbol}
        separateThousands={inputProps.separateThousands}
        label={inputProps.caption}
        data-name={inputProps.name}
        autoComplete="off"
        decimalProps={{ decimalScale: 0 }}
        {...inputProps}
        value={inputValue}
      />
    );
  }

  if (inputProps.type === INPUT_TYPE_DECIMAL) {
    return (
      <MaskedInput
        sideSymbol={inputProps.sideSymbol}
        separateThousands={inputProps.separateThousands}
        label={inputProps.caption}
        data-name={inputProps.name}
        decimalProps={{ decimalScale: 2, decimalSeparator: ',' }}
        autoComplete="off"
        {...inputProps}
        value={inputValue ? inputValue.toString().replace('.', ',') : ''}
      />
    );
  }
  if (inputProps.type === INPUT_TYPE_DECIMAL_WITH_THREE_PLACES) {
    return (
      <MaskedInput
        sideSymbol={inputProps.sideSymbol}
        separateThousands={inputProps.separateThousands}
        label={inputProps.caption}
        data-name={inputProps.name}
        decimalProps={{ decimalScale: 3, decimalSeparator: ',' }}
        autoComplete="off"
        {...inputProps}
        value={inputValue ? inputValue.toString().replace('.', ',') : ''}
      />
    );
  }
  if (inputProps.type === INPUT_TYPE_DECICMAL_WITH_ZERO) {
    return (
      <MaskedInput
        sideSymbol={inputProps.sideSymbol}
        separateThousands={inputProps.separateThousands}
        label={inputProps.caption}
        data-name={inputProps.name}
        decimalProps={{ decimalScale: 2, decimalSeparator: ',' }}
        autoComplete="off"
        {...inputProps}
        value={inputValue ? inputValue.toString().replace('.', ',') : inputValue}
      />
    );
  }
  if (inputProps.type === INPUT_TYPE_DATE) {
    return (
      <TextInput
        sideSymbol={inputProps.sideSymbol}
        label={inputProps.caption}
        autoComplete="off"
        {...inputProps}
        value={inputValue}
        as={InputMask}
        mask="**.**.****"
        maskChar={null}
      />
    );
  }

  return (
    <TextInput
      sideSymbol={inputProps.sideSymbol}
      label={inputProps.caption}
      autoComplete="off"
      {...inputProps}
      value={inputValue}
    />
  );
};

const Input = ({
  error,
  shouldShowError,
  optional,
  sideSymbol,
  tooltip,
  captionTooltip,
  visited,
  customWidth,
  validationMessage,
  additionalLabelElement,
  submitFailed,
  isClearable,
  isHorizontal,
  children,
  ...restProps
}) => {
  const component = getInputComponent(restProps);
  const t = useTranslations();

  return (
    <ControlWrapper
      caption={restProps.caption}
      tooltip={tooltip}
      visited={visited}
      error={error}
      shouldShowError={shouldShowError}
      validationMessage={validationMessage}
      optional={optional}
      additionalLabelElement={additionalLabelElement}
      submitFailed={submitFailed}
      errorMessage={restProps.errorMessage}
      isHorizontal={isHorizontal}
    >
      <InputContainer>
        {component}
        {children}
        {!children && <SymbolWrapper>{sideSymbol()}</SymbolWrapper>}
        {isClearable && restProps.value && (
          <StyledClearButton
            type="button"
            onClick={() => restProps.onChange('')}
            aria-label={t('buttons.delete')}
          >
            <CloseIcon boxSize={6} />
          </StyledClearButton>
        )}
      </InputContainer>
    </ControlWrapper>
  );
};

Input.propTypes = {
  separateThousands: bool,
  error: oneOfType([object, string]),
  shouldShowError: bool,
  validationMessage: string,
  sideSymbol: func,
  visited: bool,
  optional: bool,
  tooltip: string,
  captionTooltip: string,
  customWidth: shape({
    numerator: number,
    denominator: number,
  }),
  additionalLabelElement: func,
  submitFailed: bool,
  isHorizontal: bool,
  preventEnterSubmit: bool,
  isClearable: bool,
  children: node,
};

Input.defaultProps = {
  separateThousands: true,
  error: null,
  shouldShowError: true,
  validationMessage: null,
  sideSymbol: () => null,
  visited: false,
  optional: false,
  tooltip: null,
  captionTooltip: null,
  customWidth: null,
  additionalLabelElement: null,
  submitFailed: false,
  isHorizontal: false,
  preventEnterSubmit: true,
  isClearable: false,
  children: null,
};

export const InputAdapter = ({ input, meta, ...rest }) => (
  <Input
    {...input}
    {...meta}
    {...rest}
    onChange={(ev) => {
      const value = ev.target ? ev.target.value : ev;
      rest.onChange(value);
    }}
  />
);

InputAdapter.propTypes = FinalFormFieldPropTypes;

const InputWithField = withFormField(InputAdapter);

export default Input;

export { InputWithField };
