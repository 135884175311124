import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { createCompeonReverseClient } from 'api';
import { API_HEADERS } from 'api/apiService';
import useUploadProgress from 'components/UploadBlock/hooks/useUploadProgress';
import { getAccessToken } from 'store/user/service';

const api = createCompeonReverseClient();

const uploadHeaders = (token: string) => ({
  ...API_HEADERS.contentType.multiPart,
  ...API_HEADERS.bearerToken(token),
});

const objToFormData = (obj: any) => {
  if (!obj) {
    return new FormData();
  }
  const formData = new FormData();
  Object.keys(obj).forEach((singleParam) => formData.append(singleParam, obj[singleParam]));
  return formData;
};

const createFilePayload = (file: File, meta: any) => {
  const payload = objToFormData(meta);
  payload.append('file', file);
  return payload;
};

export const useNewUploadFile = (url: string) => {
  const { uploadProgress, handleUploadProgressChange } = useUploadProgress();
  const accessToken = useSelector(getAccessToken);

  const startUpload = useCallback(
    async (file: File, additionalContent: any) => {
      const body = createFilePayload(file, additionalContent);
      const headers = uploadHeaders(accessToken);
      return api
        .post(url, body, { headers, onUploadProgress: handleUploadProgressChange })
        .then((response) => response.data.data);
    },
    [accessToken, handleUploadProgressChange, url],
  );

  return { startUpload, uploadProgress: +uploadProgress };
};
