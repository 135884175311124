import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { IS_BENEFICIARY_OWNER } from 'modules/Inquiry/Form/formFields';

const IsBeneficiaryOwnerRadio = () => {
  return <YesNoRadioGroupWithField name={IS_BENEFICIARY_OWNER} />;
};

export default IsBeneficiaryOwnerRadio;
