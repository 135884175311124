import * as React from 'react';

import FormSection from 'components/FormSection';
import { SmallTileListWithField } from 'components/SmallTileList/SmallTileList';
import { mittweidaFields } from 'mittweida/inquiry/fields';

import { translations } from '../../../../../../new/form/common/types';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import { roleSelectOptions } from './options/roleOptions';

export const RoleSection = () => {
  const t = useTranslations();

  const options = roleSelectOptions.map((option) => ({
    key: option.value,
    title: t(option.label),
    icon: option.icon,
  }));

  return (
    <FormSection
      title={t(translations.inquiryType.mittweida.pages.financingNeed.sections.role.title)}
      name={'projectFinancingRole'}
      sectionNumber={1}
    >
      <SmallTileListWithField
        name={mittweidaFields.financingNeedPage.roleSection.projectFinancingRole}
        options={options}
      />
    </FormSection>
  );
};
