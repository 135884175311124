import _get from 'lodash/get';

import {
  USER_ROLE_CUSTOMER,
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';
import {
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_LAST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  USER_PROFILE_PHONE_NUMBER,
  VB_NUMBER,
  COMPANY_DETAILS,
  GENDER_MAN,
} from 'modules/Inquiry/Form/formFields';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

export const isLoggedInSelector = (store) => !!(store.user.token && store.user.attributes.email);

export const getUserToken = (store) => store.user.token;

const getAttributesForNewUser = (companyDetails, userProfile) => ({
  name: `${companyDetails[COMPANY_DETAILS_FIRST_NAME]} ${companyDetails[COMPANY_DETAILS_LAST_NAME]}`,
  firstName: companyDetails[COMPANY_DETAILS_FIRST_NAME],
  lastName: companyDetails[COMPANY_DETAILS_LAST_NAME],
  gender: companyDetails[COMPANY_DETAILS_GENDER],
  email: companyDetails[COMPANY_DETAILS_EMAIL],
  ...(userProfile && { phoneNumber: userProfile[USER_PROFILE_PHONE_NUMBER] }),
  vbNumber: companyDetails[VB_NUMBER],
});

const getAttributesForLoggedInUser = ({ attributes }) => ({
  name: `${attributes.firstName} ${attributes.lastName}`,
  firstName: attributes.firstName,
  lastName: attributes.lastName,
  birthDate: attributes.birthDate,
  gender:
    attributes.gender === GENDER_MAN ? COMPANY_DETAILS_GENDER__MAN : COMPANY_DETAILS_GENDER__WOMAN,
  email: attributes.email,
  phoneNumber: attributes.phoneNumber,
  vbNumber: attributes.vbNumber,
  zipCode: attributes.zipCode,
});

export const rolesSelector = (store) => _get(store, 'user.attributes.roles', []);
export const refreshTokenSelector = (store) => _get(store, 'user.refreshToken');

export const isCustomerSelector = (store) =>
  rolesSelector(store) && rolesSelector(store).includes(USER_ROLE_CUSTOMER);

export const isCallCenterAgent = (store) =>
  rolesSelector(store).includes(USER_ROLE_CALL_CENTER_AGENT);

export const isInquiryManager = (store) => rolesSelector(store).includes(USER_ROLE_INQUIRY_MANAGER);

export const isRealEstateExpert = (store) =>
  rolesSelector(store).includes(USER_ROLE_REAL_ESTATE_EXPERT);

export const isBankAdvisor = (store) => rolesSelector(store).includes(USER_ROLE_BANK_ADVISOR);

export const isAgent = (store) => {
  return (
    isCallCenterAgent(store) ||
    isInquiryManager(store) ||
    isBankAdvisor(store) ||
    isRealEstateExpert(store)
  );
};

export const hasPermission = (store) => (availableForRoles) => {
  if (!availableForRoles) {
    throw new Error('Permission does not exist in PERMISSIONS object');
  }
  return availableForRoles.some((role) => rolesSelector(store).includes(role));
};

export const getUser = (store) => {
  const {
    progress: {
      storedValues: { companyDetails, userProfile },
    },
    inquiryDetails,
    user: loggedInUser,
  } = store;

  const isCustomerRole = isCustomerSelector(store);
  const dataAreFilled = Boolean(_get(companyDetails, COMPANY_DETAILS_EMAIL));
  const editMode = !!getInquiryIdSelector(store);

  if (isCustomerRole) {
    return getAttributesForLoggedInUser(loggedInUser);
  }

  if (dataAreFilled) {
    return getAttributesForNewUser(companyDetails, userProfile);
  }

  if (editMode && inquiryDetails) {
    return getAttributesForNewUser(inquiryDetails.editDetails[COMPANY_DETAILS]);
  }

  return {};
};

export const getLoggedInUserDataSelector = (store) => store.user.attributes;
