import * as React from 'react';

import { ButtonProps, useToken, Button } from '@chakra-ui/react';

import { BadgeComponent } from '../Badge';

type VariantType = 'primary' | 'secondary' | 'tertiary' | 'link';

type Props = {
  variant?: VariantType;
  isLoading?: boolean;
  disabled?: boolean;
  parentRef?: React.RefObject<HTMLButtonElement>;
  badge?: string;
  href?: string;
  children: React.ReactNode;
  target?: string;
  rel?: string;
} & ButtonProps;

export const ButtonComponent = ({
  variant = 'primary',
  children,
  isLoading,
  disabled,
  parentRef,
  badge,
  ...props
}: Props) => {
  const buttonHoveredShadow = (color: string) => `0 2px 10px ${color}`;
  const buttonFocusedShadow = (color: string) => `0 0 0 3px ${color}`;

  const [L83, HalfS_L70, HalfS_L80] = useToken('colors', [
    'brand.default_L83',
    'brand.default_HalfS_L70',
    'brand.default_HalfS_L80',
  ]);

  const boxShadowStyle: any = {
    primary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L70),
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    secondary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L80),
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    tertiary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L80),
        borderColor: 'brand.default_L83',
        backgroundColor: 'brand.default_L93',
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    link: {
      _hover: {
        textDecoration: 'underline',
        color: 'brand.default',
      },

      _focus: {
        textDecoration: 'underline',
        color: 'brand.default',
      },
    },
  };

  const stateStyle = {
    ...boxShadowStyle[variant],
  };

  const style = {
    ...stateStyle,
  };

  return (
    <Button
      isLoading={isLoading}
      disabled={disabled || isLoading}
      ref={parentRef}
      variant={variant}
      sx={style}
      {...props}
    >
      {children}
      {badge && <BadgeComponent ml={2}>{badge}</BadgeComponent>}
    </Button>
  );
};

export default ButtonComponent;
