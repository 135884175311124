// TODO: Use `createMultiStyleConfigHelpers` from chakra,
// FIXME: probably should extend less feature component like AuthForm
const LoginForm = {
  parts: ['container', 'actions', 'inputs'],
  baseStyle: {
    container: {},
    actions: {
      mt: 16,
      justifyContent: 'flex-end',
      d: 'flex',
    },
    inputs: {
      d: 'flex',
      gap: 16,
      flexDirection: ['column', null, 'row'],
    },
  },
  variants: {
    horizontal: {
      container: {
        margin: 'auto',
      },
      inputs: {
        flexDirection: 'column',
      },
      actions: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
      },
    },
  },
  defaultProps: {
    variant: 'vertical',
  },
};

export default LoginForm;
