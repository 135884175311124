export interface IMarketDataApiRes {
  last_calculated_at: Date;
  commercial_space: number;
  living_space: number;
  location_analysis: {
    residential_location: number;
    local_trend: string;
    recent_local_trend: string;
    regional_trend: string;
  };
  possible_rental_income: number;
  potential_sales_revenue: number;
  property_evaluation: {
    market_sales_price: IPropertyEvaluationObj;
    existing_rent_housing: IPropertyEvaluationObj;
    commercial_rent: IPropertyEvaluationObj;
    residential_rent: IPropertyEvaluationObj;
    sales_duration: IPropertyEvaluationObj;
  };
}

export interface IPropertyEvaluationObj {
  market_price: string;
  market_price_min: string;
  marktet_price_max: string;
}
export interface IObjEvalTable {
  id: string;
  unit: string;
  marketPrice: string;
  marketPriceMin: string;
  marketPriceMax: string;
}

export interface IAppraiserObjEvalTable {
  name: string;
  unit: string;
  values: string;
}

export interface IObjEvalTableHeader {
  key: string;
  text: string;
  headerColor?: string;
  renderRow?: Function;
}

export interface IOnChangeProps {
  target: {
    value: string | number;
  };
}

// Market data zero obj seed.

export const marketDataZeroObj: IMarketDataApiRes = {
  last_calculated_at: new Date(),
  commercial_space: 0,
  living_space: 0,
  location_analysis: {
    residential_location: 0,
    local_trend: '0',
    recent_local_trend: '0',
    regional_trend: '0',
  },
  possible_rental_income: 0,
  potential_sales_revenue: 0,
  property_evaluation: {
    market_sales_price: { market_price: '0', market_price_min: '0', marktet_price_max: '0' },
    existing_rent_housing: { market_price: '0', market_price_min: '0', marktet_price_max: '0' },
    commercial_rent: { market_price: '0', market_price_min: '0', marktet_price_max: '0' },
    residential_rent: { market_price: '0', market_price_min: '0', marktet_price_max: '0' },
    sales_duration: { market_price: '0', market_price_min: '0', marktet_price_max: '0' },
  },
};

export const tableRowKeys: string[] = [
  'sales_duration',
  'existing_residential_rent',
  'commercial_rent',
  'residential_rent',
  'market_sales_price',
];
