import * as React from 'react';

import { Box, GridItem } from '@chakra-ui/react';

import ButtonComponent from 'theme/components/Button';

import { useFormConfig } from '../../../../../config/formConfig/hooks';
import { InquiryType } from '../../../../../modules/Inquiry/Inquiry.type';
import { CircularProgress } from '../../../../../theme/components/CircularProgress';
import ArrowRightIcon from '../../../../../theme/components/Icon/ArrowRightIcon';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';

import { SummaryCardProps } from './index';

export const ProgressItem = ({
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  openTaskId,
  canShowProgress,
}: SummaryCardProps) => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const isDvag = selectedInquiryType === InquiryType.dvag;

  return (
    <GridItem>
      {canShowProgress && !isDvag && (
        <Box
          display="flex"
          alignItems={['flex-start', null, null, 'center']}
          flexDirection="column"
          justifyContent="flex-end"
          position="relative"
          height="100%"
          _before={{
            content: "''",
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '-24px',
            width: '1px',
            backgroundColor: 'background.darkGrey',
          }}
        >
          <CircularProgress
            progressIncrement={progressIncrement!}
            progressTotal={progressTotal!}
            progressType={t('other.tasks')}
            isCompleted={isCompleted}
            completedValue={isCompleted ? 100 : completedValue}
            size="large"
            mb={4}
            isBfsService
          />

          {!isCompleted && (
            <ButtonComponent
              as="a"
              href={`#${openTaskId}`}
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              variant="tertiary"
            >
              {t('other.openTasks')}
            </ButtonComponent>
          )}
        </Box>
      )}
    </GridItem>
  );
};
