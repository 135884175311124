import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { mapInquiryManagerResponse } from 'modules/InquiryTable/mapFromApi';

const getInquiriesManagersAction = () =>
  callReverseApi({
    url: endpoints.INQUIRIES.INQUIRY_MANAGERS.compose(),
    method: API_METHODS.GET,
  });

export const getInquiryManagers = async (makeCall: any) => {
  const { payload } = await makeCall(getInquiriesManagersAction());
  return mapInquiryManagerResponse(payload);
};
