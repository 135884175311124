import React from 'react';

import { Grid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { YearPickerUnwrapped } from '../../../../components/DatePicker';
import { getKpiDateRange } from '../../../../store/kpi/kpis.selectors';
import { useDatePickerContext } from '../../../../theme/components/FinancialInformations/context/DatePickerContext';
import { KpiGrid } from './KpiGrid/KpiGrid';

export const KeyFigureAnalysis = () => {
  const kpisDateRange = useSelector(getKpiDateRange);
  const { date, setDate } = useDatePickerContext();

  const handleOnChange = (date: Date | undefined) => {
    setDate(date);
  };

  // This need to be done because if we would use the default firstKpi the first year wouldn't be accessible
  const firstKpi = new Date(
    new Date(kpisDateRange.firstKpi).setFullYear(
      new Date(kpisDateRange.firstKpi).getFullYear() - 1,
    ),
  );

  return (
    <>
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
        gap={6}
        mb={2}
      >
        <YearPickerUnwrapped
          caption={''}
          name={'KeyFigureAnalysisDatePicker'}
          placeholder={''}
          errorMessage={''}
          value={date.toString()}
          onChange={handleOnChange}
          optional={false}
          tooltip={''}
          isClearable={false}
          maxDate={new Date(kpisDateRange.lastKpi)}
          minDate={firstKpi}
        />
      </Grid>
      <KpiGrid />
    </>
  );
};
