import React, { FC } from 'react';

import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { InputWithField } from 'components/Input';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { useFormConfig } from 'config/formConfig/hooks';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_PHONE_NUMBER_VERIFYER,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';
import { required as requiredWithMessage } from 'utils/validators';

import { CardHeader, InputWrapper } from './styles';

interface GetPhoneNumberProps {
  onSubmit: any;
  pending?: boolean;
  phoneNumberCaption?: string;
}

export const GetPhoneNumber: FC<GetPhoneNumberProps> = ({
  onSubmit,
  pending,
  phoneNumberCaption = 'phoneNumberCaption',
}: GetPhoneNumberProps) => {
  const t = useTranslations();
  const form = useForm();
  const processLane = useSelector(getProcessLane);
  const phoneNumberIsValid = fieldIsValid(USER_PROFILE_PHONE_NUMBER)({ form });
  const isOffer = processLane === 'offer';
  const { isPhoneNumber, hasMobilePhonePrefix, minPhoneLength, maxPhoneLength } =
    useFieldValidators();
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  return (
    <>
      <CardHeader data-testid="phone-number-verification">
        {isOffer
          ? t('components.smsVerification.header1Indicative')
          : t('components.smsVerification.header1')}
      </CardHeader>
      <MarkdownWrapper text={t('components.smsVerification.info1')} />
      <InputWrapper>
        <InputWithField
          name={USER_PROFILE_PHONE_NUMBER}
          caption={t(`components.smsVerification.fields.${phoneNumberCaption}`)}
          placeholder={t('components.smsVerification.fields.mobileNumberPlaceholder')}
          data-testid="user-data-phonenumber"
          validate={combineValidators(
            isPhoneNumber,
            hasMobilePhonePrefix,
            minPhoneLength,
            maxPhoneLength,
          )}
        >
          <ButtonComponent
            data-testid="send-phone-number"
            disabled={!phoneNumberIsValid}
            flex="1 0 auto"
            isLoading={pending}
            onClick={onSubmit}
          >
            {t('components.smsVerification.fields.submitPhoneNumber')}
          </ButtonComponent>
        </InputWithField>
      </InputWrapper>
      {isBfsService && (
        <InputWithField
          type="hidden"
          name={USER_PROFILE_PHONE_NUMBER_VERIFYER}
          validate={requiredWithMessage(t('errors.required'))}
        ></InputWithField>
      )}
    </>
  );
};
