import React from 'react';

import { useHistory } from 'react-router-dom';

import { getUserAttributesAction } from 'api/CompeonReverseApi/operation/userData/actions';
import PortalPage from 'components/PortalPage';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import {
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';
import Form, { ILoginFormValues } from 'modules/Login/LoginForm';
import { useLoginRedirect } from 'modules/Login/useLoginRedirect';
import SectionHeader from 'modules/SectionHeader/SectionHeader';
import { logInPortalUser } from 'shared/auth/loginLogout.actions';
import { getUserAttributes } from 'store/user/service';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { setStorageExpiration } from 'utils/sessionStorage/expiration';

const OperationLogin = () => {
  const t = useTranslations('pages.loginPage');
  const afterLoginUrl = useLoginRedirect(paths.operation.inquiryList);
  const history = useHistory();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  const login = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
    isPendingInitially: false,
  });
  const attrs = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: t('error'),
    isPendingInitially: false,
  });

  async function handleLogin({ email, password }: ILoginFormValues) {
    setStorageExpiration();
    const response = await login.makeCall(logInPortalUser({ email, password }));
    if (response?.payload?.data) {
      const accessToken = response.payload.data.access_token;
      const { roles } = getUserAttributes(accessToken);
      await attrs.makeCall(getUserAttributesAction());
      const isAgent = roles?.some((role) =>
        [
          USER_ROLE_CALL_CENTER_AGENT,
          USER_ROLE_INQUIRY_MANAGER,
          USER_ROLE_BANK_ADVISOR,
          USER_ROLE_REAL_ESTATE_EXPERT,
        ].includes(role),
      );
      if (isAgent) {
        history.push(afterLoginUrl);
      }
    }
  }

  return (
    <PortalPage
      stylesThemeKey="CustomerLoginPortalPage"
      biggerHeading
      pageTitle={t('operationHeading', { partnerName })}
    >
      <SectionHeader
        subheadingTranslationKey="pages.loginPage.subheading"
        stylesThemeKey="CustomerLoginHeading"
        leadTranslationKey="pages.loginPage.lead"
      />
      <Form
        onSubmit={handleLogin}
        isLoading={!!login.isPending || !!attrs.isPending}
        forgotPasswordLink={paths.operation.passwordReset.request}
      />
    </PortalPage>
  );
};

export default OperationLogin;
