import * as React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetFoundingMonthOptions } from './useGetFoundingMonthOptions';

type Props = {
  name: string;
};

export const FoundingMonthSelect = ({ name }: Props) => {
  const options = useGetFoundingMonthOptions();
  const t = useTranslations();

  return (
    <SelectWithField
      name={name}
      options={options}
      placeholder={t(translations.placeholders.pleaseChoose)}
      data-testid={'select-field-founding-month'}
    />
  );
};
