import React from 'react';

import _chunk from 'lodash/chunk';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import {
  BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
  BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
  BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
  BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
  BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
  BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const ExplicitNeedCheckboxField = () => {
  const tCheckboxFields = useTranslations(
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.explicitNeedFurtherAdvice.options',
  );

  const explicitNeedOptions = [
    {
      name: BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
      caption: tCheckboxFields('explicitNeedOtherFinancingSolutions.caption'),
    },
    {
      name: BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
      caption: tCheckboxFields('explicitNeedExpertAdvice.caption'),
    },
    {
      name: BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
      caption: tCheckboxFields('explicitNeedCurrentAccountPayment.caption'),
    },
    {
      name: BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
      caption: tCheckboxFields('explicitNeedBillingSoftware.caption'),
    },
    {
      name: BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
      caption: tCheckboxFields('explicitNeedFactoringExchangeService.caption'),
    },
    {
      name: BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
      caption: tCheckboxFields('explicitNeedSeminars.caption'),
    },
  ];

  return (
    <>
      {_chunk(explicitNeedOptions, 2).map((pair) => (
        <FormRow>
          {pair.map((option) => (
            <CheckboxWithField
              name={option.name}
              text={<span>{option.caption}</span>}
              data-testid={`Checkbox-Field-${option.name}`}
            />
          ))}
        </FormRow>
      ))}
    </>
  );
};

export default ExplicitNeedCheckboxField;
