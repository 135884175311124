import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { StaticFieldWithField } from 'components/StaticField';
import {
  BUILDING_USE_OF_FUNDS_SUM,
  BUILDING_PURCHASE_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_SOURCE_OF_FUNDS_SUM,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { useSumOfFields } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useSumOfFields';
import { StyledSumValues } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/styles';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { formatPrice } from 'utils/valueFormats';

const fieldsToSum = [
  BUILDING_PURCHASE_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
];

const BuildingUseOfFundsSum: React.FC = () => {
  const sumOfFunds = useSumOfFields(fieldsToSum);
  const { change } = useForm();
  const fieldController = FormFieldController.instance;

  const sourceSum = useGetFieldNumericValue(BUILDING_SOURCE_OF_FUNDS_SUM);

  useEffect(() => {
    change(BUILDING_USE_OF_FUNDS_SUM, sumOfFunds);
    fieldController.saveValue(BUILDING_USE_OF_FUNDS_SUM, sumOfFunds);
  }, [fieldController, change, sumOfFunds]);

  return (
    <StyledSumValues hasBiggerSum={sourceSum > sumOfFunds}>
      <StaticFieldWithField
        isHorizontal
        name={BUILDING_USE_OF_FUNDS_SUM}
        parseValue={(val: number) => formatPrice(val, false)}
      />
    </StyledSumValues>
  );
};

export default BuildingUseOfFundsSum;
