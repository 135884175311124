import React from 'react';

import { useHasMultipleTranslations } from 'components/App/LanguageProvider/useLanguagePreferences';
import { LANGUAGE_TYPE } from 'config/partner/types';
import LanguageButton from 'modules/Header/LanguageSelector/LanguageButton';
import { StyledNavList as List } from 'modules/Header/PortalNavigation/styles';

const LanguageSelector = () => {
  const hasMultipleTranslations = useHasMultipleTranslations();

  if (!hasMultipleTranslations) {
    return null;
  }

  return (
    <List>
      <LanguageButton language={LANGUAGE_TYPE.GERMAN} />|
      <LanguageButton language={LANGUAGE_TYPE.ENGLISH} />
    </List>
  );
};

export default LanguageSelector;
