import React, { useEffect, useCallback, ReactNode } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import RouteWatcher from 'components/App/RouteWatcher';
import { tokenExpiredAction } from 'shared/auth/loginLogout.actions';
import { useToasts } from 'shared/hooks/useToasts';
import { isLoggedInSelector } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { handleDataExpiration } from 'utils/sessionStorage/expiration';

const TokenExpirationWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { warning } = useToasts();
  const t = useTranslations();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const redirectUserOnTokenExpiration = useCallback(() => {
    if (isLoggedIn) {
      const isExpired = handleDataExpiration();
      if (isExpired) {
        dispatch(tokenExpiredAction);
        warning({ description: t('pages.loginPage.sessionExpired') });
      }
    }
  }, [dispatch, isLoggedIn, warning, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(redirectUserOnTokenExpiration, []);

  return <RouteWatcher onRouteChange={redirectUserOnTokenExpiration}>{children}</RouteWatcher>;
};

export default TokenExpirationWrapper;
