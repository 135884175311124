import React from 'react';

import Separator from 'components/FormRow/Separator';

import FormRowWrapper from './FormRowWrapper';

export interface FormRowProps {
  separator?: boolean;
  alignItems?: string;
  space?: string;
  smallSeparatorSpace?: boolean;
}

const FormRow = ({
  alignItems,
  separator,
  space,
  smallSeparatorSpace,
  children,
}: React.PropsWithChildren<FormRowProps>) => {
  return (
    <>
      <FormRowWrapper alignItems={alignItems} space={space}>
        {children}
      </FormRowWrapper>
      {separator && <Separator small={smallSeparatorSpace} />}
    </>
  );
};

export default FormRow;
