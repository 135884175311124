import React from 'react';

import { useSelector } from 'react-redux';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import CoronaDetailsSection from 'modules/InquiryDetails/DetailedInformation/CoronaDetailsSection/CoronaDetailsSection';
import FinancingPurposeSection from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/FinancingPurposeSection';
import InquiryDetailsSection from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection';
import SummarySection from 'modules/InquiryDetails/DetailedInformation/SummarySection';
import UserProfileSection from 'modules/InquiryDetails/DetailedInformation/UserProfileSection/UserProfileSection';
import VbProfileSection from 'modules/InquiryDetails/DetailedInformation/VbProfileSection/index';
import InquiryRevision from 'modules/InquiryDetails/InquiryRevision/InquiryRevision';
import InquiryOffers from 'modules/Offers/InquiryOffersSection';
import AdditionalFinancingSection from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonAcceptance';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { hasOffersSelector } from 'store/inquiryDetails/selectors';

import BeneficiaryOwnerSection from './BeneficiaryOwnersSection/BeneficiaryOwnerSection';
import FinancingOfferSection from './FinancingOfferSection/FinancingOfferSection';
import LegalRepresentativesSection from './LegalRepresentativesSection/LegalRepresentativesSection';

const DefaultSections = () => {
  const hasOffers = useSelector(hasOffersSelector);
  return (
    <>
      <AdditionalFinancingSection />
      {hasOffers && <InquiryOffers />}
      <SummarySection />
      <CoronaDetailsSection />
      <FinancingPurposeSection />
      <UserProfileSection />
      <CompanyDetailsSection />
      <InquiryDetailsSection />
      <VbProfileSection />
      <InquiryRevision />
    </>
  );
};

const BfsServiceSections = () => {
  return (
    <>
      <FinancingPurposeSection />
      <CompanyDetailsSection />
      <FinancingOfferSection />
      <UserProfileSection />
      <LegalRepresentativesSection />
      <BeneficiaryOwnerSection />
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BfsServiceSections,
  default: DefaultSections,
});
