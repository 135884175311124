import { FONT_WEIGHT_MEDIUM } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const DescriptionList = {
  parts: ['description', 'term', 'descriptionList'],
  baseStyle: {
    descriptionList: {
      display: 'flex',
    },
  },
  sizes: {
    small: {
      descriptionList: {
        flexDir: 'row',
        alignItems: 'center',
      },
      term: {
        marginRight: '12px',
        ...textStyle.sizeM,
        color: 'text.tertiary',
      },
      description: {
        ...textStyle.sizeM,
        color: 'text.secondary',
      },
    },
    medium: {
      descriptionList: {
        flexDir: 'column',
      },
      term: {
        marginBottom: '4px',
        ...textStyle.sizeM,
        color: 'text.tertiary',
      },
      description: {
        ...textStyle.sizeL,
        color: 'text.secondary',
      },
    },
    large: {
      descriptionList: {
        flexDir: 'column',
      },
      term: {
        ...textStyle.sizeL,
        fontWeight: FONT_WEIGHT_MEDIUM,
        color: 'text.secondary',
      },
      description: {
        ...textStyle.sizeXxl,
        fontWeight: FONT_WEIGHT_MEDIUM,
        color: 'text.primary',
      },
    },
  },
};

export default DescriptionList;
