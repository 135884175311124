import * as React from 'react';

import { Link, LinkProps } from '@chakra-ui/react';

type VariantType = 'primary' | 'secondary';

type Props = {
  variant?: VariantType;
  children: string;
} & Pick<LinkProps, 'href'>;

export const LinkComponent = ({ variant = 'primary', children, ...props }: Props) => {
  return (
    <Link variant={variant} {...props}>
      {children}
    </Link>
  );
};
