import { SelectOption } from '../../../../../../../components/Selects/Select/StepsDropdown/types';
import { translations } from '../../../../../../../new/form/common/types';
import { FormatMessage } from '../../../../../../../utils/intl';
import { Gender } from '../../../../../fields';

export const getGenderOptions = (formatMessage: FormatMessage): SelectOption<Gender>[] => {
  const { mr, ms } =
    translations.inquiryType.mittweida.pages.userDetails.sections.contactPerson.gender.options;

  const options: Array<{ id: string; value: Gender }> = [
    {
      id: mr,
      value: 'mr',
    },
    {
      id: ms,
      value: 'ms',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
