import React from 'react';

import { Asset } from 'components/Asset';
import FormSection from 'components/FormSection';
import { SmallTileListWithField } from 'components/SmallTileList/SmallTileList';
import { useFormConfigRepo } from 'config/formConfig/hooks';
import { PURPOSE_KIND__REAL_ESTATE, REAL_ESTATE_PROJECT } from 'modules/Inquiry/Form/formFields';
import { simpleValidation } from 'modules/Inquiry/Form/validateFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateRealEstateProjectSection = simpleValidation([REAL_ESTATE_PROJECT]);

const RealEstateProjectSection = () => {
  const t = useTranslations();
  const formConfig = useFormConfigRepo();
  const realEstatePurposes = Object.keys(
    formConfig.getCurrentFormConfig()[PURPOSE_KIND__REAL_ESTATE] || {},
  );
  const realEstate = formConfig.getAssetByName('realEstate');

  const options = realEstate
    .filter(({ id }) => realEstatePurposes.includes(id))
    .map(({ id, titleId }) => ({
      key: id,
      title: t(titleId),
      icon: <Asset type="realEstate" value={id} />,
    }));

  const labelTranslator = useInquiryLabelTranslator();

  return (
    <FormSection
      name={FormSections.realEstateProject}
      sectionNumber={3}
      title={labelTranslator(REAL_ESTATE_PROJECT)}
      hideBottomBorder={true}
    >
      <SmallTileListWithField noCaption name={REAL_ESTATE_PROJECT} options={options} />
    </FormSection>
  );
};

export default RealEstateProjectSection;
