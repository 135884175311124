import React, { ReactNode } from 'react';

import FormRow from 'components/FormRow';
import {
  MACHINE_PRICE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__VEHICLE,
  VEHICLE_PURCHASE_PRICE,
  GOODS__PURCHASE_PRICE,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__HIRE_PURCHASE,
  LEASING_ADVANCE_PAYMENT,
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  LOAN_ADVANCE_PAYMENT,
} from 'modules/Inquiry/Form/formFields';
import HirePurchaseIcon from 'theme/components/Icon/ProductKind/HirePurchaseIcon';
import LeasingIcon from 'theme/components/Icon/ProductKind/LeasingIcon';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';
import GoodsIcon from 'theme/components/Icon/Purposes/GoodsIcon';
import MachineIcon from 'theme/components/Icon/Purposes/MachineIcon';
import VehicleIcon from 'theme/components/Icon/Purposes/VehicleIcon';

import LoanDuration from '../../Loan/LoanDuration/LoanDuration';
import LoanType from '../../Loan/LoanType/LoanType';

export type purposeKindData = {
  icon: ReactNode;
  purchaseField: string;
  caption: string;
};

export const selectedPurposeKindMap: { [key: string]: purposeKindData } = {
  [PURPOSE_KIND__MACHINE]: {
    icon: <MachineIcon />,
    purchaseField: MACHINE_PRICE,
    caption: 'pages.financingNeed.sections.machine.fields.machinePrice.caption',
  },
  [PURPOSE_KIND__GOODS]: {
    icon: <GoodsIcon />,
    purchaseField: GOODS__PURCHASE_PRICE,
    caption: 'pages.financingNeed.sections.goods.fields.goodsPurchasePrice.caption',
  },
  [PURPOSE_KIND__VEHICLE]: {
    icon: <VehicleIcon />,
    purchaseField: VEHICLE_PURCHASE_PRICE,
    caption: 'pages.financingNeed.sections.commercialVehicle.fields.vehiclePrice.caption',
  },
};

export type productKindData = {
  icon: ReactNode;
  advancePaymentField: string;
  caption: string;
  durationField: ReactNode;
};

export const selectedProductKindMap: { [key: string]: productKindData } = {
  [PRODUCT_KIND__LOAN]: {
    icon: <LoanIcon />,
    advancePaymentField: LOAN_ADVANCE_PAYMENT,
    caption: 'pages.requestDetails.sections.financialProduct.fields.productKind.options.loan.title',
    durationField: () => (
      <FormRow>
        <LoanType />
        <LoanDuration />
      </FormRow>
    ),
  },
  [PRODUCT_KIND__LEASING]: {
    icon: <LeasingIcon />,
    advancePaymentField: LEASING_ADVANCE_PAYMENT,
    caption:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.leasing.title',
    durationField: () => (
      <FormRow>
        <LoanType />
        <LoanDuration />
      </FormRow>
    ),
  },
  [PRODUCT_KIND__HIRE_PURCHASE]: {
    icon: <HirePurchaseIcon />,
    advancePaymentField: HIRE_PURCHASE_ADVANCE_PAYMENT,
    caption:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.hire_purchase.title',
    durationField: () => (
      <FormRow>
        <LoanType />
        <LoanDuration />
      </FormRow>
    ),
  },
};
