import React, { useCallback, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import SpinnerWrapper from '../../../../../components/App/PartnerProvider/SpinnerWrapper';
import Spinner from '../../../../../components/Spinner';
import { getMarketDataFilter } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { fetchMarketData } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import ProjectCostingHeader from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import SalesTypeSelect from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/SalesTypeSelect';
import UsageTypeRadio from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import { getInquiryCreatedAtSelector } from '../../../../../store/inquiryDetails/selectors';
import { getCreatedInquiryId } from '../../../../../store/progress/selectors';
import { formatDateWithTime } from '../../../../../utils/date';
import useDispatchApiCall from '../../../../../utils/hooks/useDispatchApiCallHook';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { formatPrice } from '../../../../../utils/valueFormats';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { setMarketData } from '../../store/action';
import { getMarketComparisonTopBoxes } from '../../store/selectors';
import LocationAnalysis from './MarketComparisonLocationAnalysis';
import ObjectEvaluation from './MarketComparisonObjectEvaluation';

interface MarketComparisonTopBox {
  living_space: number;
  commercial_space: number;
  potential_sales_revenue: string;
  possible_rental_income_per_year: string;
}

export interface MarketComparisonTopBoxValues {
  current: {
    unit: MarketComparisonTopBox;
    building: MarketComparisonTopBox;
  };
  future: {
    unit: MarketComparisonTopBox;
    building: MarketComparisonTopBox;
  };
}

const MittweidaMarketComparison = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.marketComparison');
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const inquiryCreatedAt = useSelector(getInquiryCreatedAtSelector);
  const filterData = useSelector(getMarketDataFilter);
  const dispatch = useDispatch();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });

  const fetchMarketDataMittweida = useCallback(async () => {
    await makeCall(fetchMarketData(inquiryId), ({ payload }: any) => {
      dispatch(setMarketData(payload.data));
    });
  }, [inquiryId, dispatch, makeCall]);

  useEffect(() => {
    fetchMarketDataMittweida();
    // eslint-disable-next-line
  }, [inquiryId, fetchMarketDataMittweida, filterData?.saleType, filterData?.usageType]);

  const values: MarketComparisonTopBoxValues = useSelector(getMarketComparisonTopBoxes);
  const topBoxValues =
    values?.[filterData?.usageType]?.[filterData?.saleType === 'building' ? 'building' : 'unit'];

  if (values === undefined) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <ProjectCostingHeader
        heading={t('title')}
        subheading={`${t('createdAt')} ${formatDateWithTime(inquiryCreatedAt)}`}
      />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
        <SalesTypeSelect />
      </Flex>
      <SummaryCards>
        <SummaryCard
          caption={t('summaryCards.potentialSalesRevenue')}
          value={`${formatPrice(topBoxValues?.potential_sales_revenue, true)}`}
        />
        <SummaryCard
          caption={t('summaryCards.possibleRentalIncomePerYear')}
          value={`${formatPrice(topBoxValues?.possible_rental_income_per_year, true)}`}
        />
        <SummaryCard
          caption={t('summaryCards.livingSpace')}
          value={`${topBoxValues?.living_space} m²`}
        />
        <SummaryCard
          caption={t('summaryCards.commercialSpace')}
          value={`${topBoxValues?.commercial_space} m²`}
        />
      </SummaryCards>
      <ObjectEvaluation />
      <LocationAnalysis />
    </>
  );
};

export default MittweidaMarketComparison;
