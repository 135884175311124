import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Page from 'components/Page/Page';
import PageTitle from 'components/PageTitle/PageTitle';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import FormPage from 'modules/FormPage';
import { SUMMARY, USER_PROFILE_BIRTHDATE, PEOPLE_DETAILS } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import { fetchInquiryAction } from 'modules/InquiryDetails/InquiryDetails.service';
import SummaryTable from 'modules/SummaryTable/SummaryTable';
import useSendSpecializedInquiry from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import useInquiryFastlaneCheck from 'pages/inquiryFlow/SummaryPage/Fastlane/useInquiryFastlaneCheckHook';
import SummaryHeading from 'pages/inquiryFlow/SummaryPage/SummaryHeading/SummaryHeading';
import { useRedirectUserOnRefresh } from 'pages/inquiryFlow/SummaryPage/useRedirectUserOnRefresh';
import { useSendInquiryToApi } from 'pages/inquiryFlow/SummaryPage/useSendInquiryToApi';
import { useSetProgressBarStage } from 'pages/inquiryFlow/SummaryPage/useSetProgressBartStage';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getIsSendingInquirySelector } from 'store/inquiries/inquiries.selector';
import {
  mapInquiryDetailsApiResponseAction,
  resetOfferCalculationAction,
} from 'store/inquiryDetails/actions';
import { getFastLane, getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { resetForm } from 'store/progress/actions';
import { getStoredValueSelector } from 'store/progress/selectors';
import { setBirthdate } from 'store/user/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useRedirectToStartPage } from 'utils/hooks/useRedirectToStartPage';
import { useScrollTopOnLoad } from 'utils/hooks/useScrollTopOnLoad';
import { useTranslations } from 'utils/hooks/useTranslations';

import CalculationConfirmationModal from './CalculationConfirmationModal';

const SummaryPage = () => {
  const t = useTranslations();
  useScrollTopOnLoad();
  const shouldRedirect = useRedirectUserOnRefresh();
  useSetProgressBarStage();
  useRedirectToStartPage();
  useInquiryFastlaneCheck();

  const dispatch = useDispatch();
  const isSending = useSelector(getIsSendingInquirySelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const fastlaneState = useSelector(getFastLane);
  const { pathToPreviousStep, cancelPath } = useInquiryNavigation(SUMMARY);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isFastlaneEnabled = useConfig(CONFIG.IS_FASTLANE_ENABLED);
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const sendSpecializedInquiry = useSendSpecializedInquiry();
  const { pathToNextStep } = useInquiryNavigation(SUMMARY);
  const history = useHistory();
  const birthDate = useSelector(
    getStoredValueSelector([PEOPLE_DETAILS, USER_PROFILE_BIRTHDATE]),
  ) as unknown;
  const { makeCall } = useDispatchApiCall({ errorMessage: t('errors.unknownInquiry') });
  const sendInquiryToAPI = useSendInquiryToApi();

  const fetchData = async (inquiryId: string) => {
    const { error, payload } = await makeCall(fetchInquiryAction(inquiryId));

    if (!error) {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
    }
  };

  const handleSpecializedInquiry = async () => {
    const { error } = await sendSpecializedInquiry();
    if (!error) {
      if (pathToNextStep) {
        history.push(pathToNextStep);
        if (typeof birthDate === 'string') {
          dispatch(setBirthdate(birthDate));
        }
      }
      if ([InquiryType.bfs, InquiryType.profiRLL, InquiryType.dvag].includes(selectedInquiryType)) {
        if (inquiryId !== null && inquiryId !== undefined) {
          fetchData(inquiryId as string);
        } else {
          dispatch(resetForm(true));
          dispatch(resetOfferCalculationAction());
        }
      } else {
        dispatch(resetForm(true));
        dispatch(resetOfferCalculationAction());
      }
    }
  };

  const handleSubmit = async () => {
    switch (selectedInquiryType) {
      case InquiryType.profiRLL:
        if (inquiryId) {
          onOpen();
        } else {
          handleSpecializedInquiry();
        }
        break;
      case InquiryType.bigPicture:
      case InquiryType.onlinefactoring:
      case InquiryType.bfs:
      case InquiryType.bfsService:
      case InquiryType.dvag:
        handleSpecializedInquiry();
        break;
      default:
        sendInquiryToAPI();
        if (shouldRedirect) {
          history.push(paths.financingNeed);
        }
        break;
    }
  };

  return (
    <Page hero={<SummaryHeading />}>
      <PageTitle title={t('pages.inquiryDetails.detailed.summary.title')} />
      {isProfiInquiry && (
        <CalculationConfirmationModal
          onClose={onClose}
          isOpen={isOpen}
          handleSpecializedInquiry={handleSpecializedInquiry}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        render={({ form }) => (
          <FormPage
            form={form}
            sections={() => null}
            backLinkUrl={pathToPreviousStep}
            continueButtonText={inquiryId ? 'buttons.save' : 'buttons.send'}
            isLoading={isSending || (isFastlaneEnabled && fastlaneState.isFastlaneLoading)}
            ignoreRedirection={Boolean(inquiryId)}
            cancelLink={cancelPath}
          >
            <SummaryTable />
          </FormPage>
        )}
      />
    </Page>
  );
};

export default SummaryPage;
