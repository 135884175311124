import _get from 'lodash/get';

import { PROFITABILITY_USUAL_SALES_COST_RATE } from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapSalesCostValuesFromApi = (response: unknown) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const salesCostValues = {
    [PROFITABILITY_USUAL_SALES_COST_RATE]: _get(response, 'usual_sales_costs_rate') || initialValue,
  };

  return salesCostValues;
};

export default mapSalesCostValuesFromApi;
