import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { validateNotLoggedInUserDetails } from 'modules/Inquiry/Form/Validations/default/CompanyDetails/notLoggedInUserDetails.validator';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { validateBeneficiaryOwnerSection } from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';
import { validateLegalRepresentationSection } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import { isEmailAvailibleSelector } from 'store/emailAvailable/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { isBankAdvisor, isLoggedInSelector } from 'store/user/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompanyAssociatedPersonsSelector } from '../../operationPortal/CompaniesDetails/store/selectors';
import { validateCustomerDetailsSection } from '../CustomerDetails';

export const usePeopleDetailsValidators = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const processLane = useSelector(getProcessLane);
  const associatedPersons = useSelector(getCompanyAssociatedPersonsSelector);
  const customerEmailExists = useSelector(isEmailAvailibleSelector);
  const isBA = useSelector(isBankAdvisor);
  const { selectedInquiryType } = useFormConfig();
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });
  const isBFSService = selectedInquiryType === InquiryType.bfsService;

  /*
  If we return an empty array for logged in user the progress bar does not pick it
  up as an additional step, returning a function that returns true adds the step
   */
  const validatePassword = isLoggedIn ? [() => true] : [validateNotLoggedInUserDetails(makeCall)];

  const validateInvatation = isBA
    ? [validateCustomerDetailsSection(customerEmailExists)]
    : isBFSService
    ? [validateCustomerDetailsSection(false)]
    : [() => true];

  const validateLegalRepresentation =
    processLane === InquiryLane.lead && !isBFSService
      ? [() => true]
      : selectedInquiryType === InquiryType.leaseplan
      ? [
          validateLegalRepresentationSection(
            associatedPersons.filter((a) => a.type === 'legal_representatives'),
          ),
        ]
      : [validateLegalRepresentationSection(associatedPersons)];

  const validateBeneficiaryOwner =
    selectedInquiryType === InquiryType.leaseplan || isBFSService
      ? [validateBeneficiaryOwnerSection()]
      : [() => true];

  return () => [
    ...validatePassword,
    ...validateLegalRepresentation,
    ...validateInvatation,
    ...validateBeneficiaryOwner,
  ];
};
