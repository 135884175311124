import React, { useEffect } from 'react';

import AOS from 'aos';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import LanguageProvider from 'components/App/LanguageProvider/LanguageProvider';
import { PartnerProvider } from 'components/App/PartnerProvider/PartnerProvider';
import TokenExpirationWrapper from 'components/App/TokenExpirationWrapper';
import FixedSidebar from 'components/FixedSidebar/FixedSidebar';
import Footer from 'modules/Footer';
import { Head } from 'modules/Head/Head';
import Header from 'modules/Header';
import Routes from 'routes';
import { persistor, store } from 'store';
import { GlobalStyle } from 'styles/global';

import { PortalConfigProvider } from '../../new/portals/state/portalConfigContext';
import AppWrapper from './AppWrapper';
import MainContent from './MainContent';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      startEvent: 'load',
      disable: 'mobile',
    });
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PartnerProvider>
          <PortalConfigProvider>
            <LanguageProvider>
              <TokenExpirationWrapper>
                <AppWrapper persistor={persistor}>
                  <GlobalStyle />
                  <Head />
                  <Header />
                  <MainContent>
                    <Routes />
                    <FixedSidebar />
                    <Footer />
                  </MainContent>
                </AppWrapper>
              </TokenExpirationWrapper>
            </LanguageProvider>
          </PortalConfigProvider>
        </PartnerProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
