export enum INQUIRY_SECTIONS {
  FINANCING = 'financingPurposeFields',
  USER = 'userProfileFields',
  COMPANY = 'companyFields',
  DETAILS = 'inquiryDetailFields',
  END_USER = 'endUserFields',
  ADDITIONAL_FIELDS = 'additionalFields',
  CORONA_STEP = 'coronaStep',
  CONTRACT_DETAILS = 'contractDetails',
  CUSTOMER_DATA = 'customerData',
  LEGAL_REPRESENTATIVE = 'legalRepresentativeDetails',
}

export const INQUIRY_SECTION_KEYS = Object.values(INQUIRY_SECTIONS);
