import { Kpi } from '../../theme/components/FinancialInformations/hooks/useKpiFilter';
import { SET_KPIS } from './kpis.actions';

export type Kpis = Record<string, Record<string, Kpi[]>>;

interface InitialState {
  kpis: Kpis;
  kpiDateRange: Record<string, string>;
  fiscalYearStartsAt: string;
}

export const initialState: InitialState = {
  kpis: {},
  kpiDateRange: {},
  fiscalYearStartsAt: '',
};

export const kpiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_KPIS:
      return {
        ...state,
        kpis: action.payload.kpis,
        kpiDateRange: action.payload.kpiDateRange,
        fiscalYearStartsAt: action.payload.fiscalYearStartsAt,
      };
    default:
      return state;
  }
};
