import React from 'react';

import FormRow from 'components/FormRow';
import { RadioGroupWithField } from 'components/RadioGroup';
import { SelectWithField } from 'components/Selects/Select';
import { usePartnerConfig } from 'config/partner/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  USER_PROFILE_COMPANY_INTERNAL_RATING,
  USER_PROFILE_COMPANY_RATING_KIND,
  USER_PROFILE_COMPANY_RATING_KIND__EXTERNAL,
  USER_PROFILE_COMPANY_RATING_KIND__INTERNAL,
} from 'modules/Inquiry/Form/formFields';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyInfoRating = () => {
  const t = useTranslations();
  const {
    meta: { internalRatings },
  } = usePartnerConfig();
  const internalExternalOptions = [
    {
      caption: t(
        'pages.userProfile.sections.completingCompanyDetails.fields.userProfileCompanyRatingKind.options.internal',
      ),
      value: USER_PROFILE_COMPANY_RATING_KIND__INTERNAL,
    },
    {
      caption: t(
        'pages.userProfile.sections.completingCompanyDetails.fields.userProfileCompanyRatingKind.options.external',
      ),
      value: USER_PROFILE_COMPANY_RATING_KIND__EXTERNAL,
    },
  ];

  return (
    <>
      <FormRow>
        <RadioGroupWithField
          name={USER_PROFILE_COMPANY_RATING_KIND}
          options={internalExternalOptions}
        />
      </FormRow>
      <Condition isBusinessCondition condition={fieldBusinessConditions.isInternalCompanyRating}>
        <FormRow>
          <SelectWithField
            name={USER_PROFILE_COMPANY_INTERNAL_RATING}
            options={internalRatings?.map((rating: string) => ({
              label: rating,
              value: Number(rating),
            }))}
            data-testid={'select-field-' + USER_PROFILE_COMPANY_INTERNAL_RATING}
          />
        </FormRow>
      </Condition>
    </>
  );
};

export default CompanyInfoRating;
