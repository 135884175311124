import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const submitRentalParametersValues = (data: Record<string, unknown>, inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.UPDATE_RENTAL_PARAMETERS.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: data,
      },
    },
  });

export default submitRentalParametersValues;
