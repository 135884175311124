const Link = {
  variants: {
    primary: {
      textDecoration: 'underline',
      color: 'brand.default',

      _focus: {
        textDecoration: 'none',
      },

      _hover: {
        textDecoration: 'none',
      },

      _active: {
        textDecoration: 'underline',
      },
    },
    secondary: {
      color: 'currentColor',

      _focus: {
        textDecoration: 'underline',
        color: 'brand.default',
      },

      _hover: {
        textDecoration: 'underline',
        color: 'brand.default',
      },

      _active: {
        textDecoration: 'none',
      },
    },
  },
};

export default Link;
