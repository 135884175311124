import React from 'react';

import { CheckboxWithField } from '../../../components/Checkbox';
import paths from '../../../constants/paths';
import { useFieldValidators } from '../../../shared/hooks/useFieldValidators';
import { useTranslations } from '../../../utils/hooks/useTranslations';
import { combineValidators } from '../../../utils/validators';

type Props = {
  name: string;
};

export const TermsAndConditionsField = ({ name }: Props) => {
  const { required, mustBeTrue } = useFieldValidators();
  const t = useTranslations();

  const termsAndConditionsText = t(
    'pages.companyDetails.sections.termsAndConditions.fields.companyDetailsConsent.links.termsAndConditions',
  );

  const privacyPolicyText = t(
    'pages.companyDetails.sections.termsAndConditions.fields.companyDetailsConsent.links.privacyPolicy',
  );

  return (
    <CheckboxWithField
      name={name}
      validate={combineValidators(required, mustBeTrue)}
      text={
        <span>
          {t(
            'pages.companyDetails.sections.termsAndConditions.fields.companyDetailsConsent.label',
            {
              termsAndConditions: (
                <a key="termsAndConditions" href={paths.termsAndConditions}>
                  {termsAndConditionsText}
                </a>
              ),
              privacyPolicy: (
                <a key="privacyPolicy" href={paths.privacyPolicy}>
                  {privacyPolicyText}
                </a>
              ),
            },
          )}
        </span>
      }
    />
  );
};
