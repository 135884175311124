import React from 'react';

import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import { IInquiryListFilterByOptions } from 'models/InquiryList.model';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { initialState } from 'store/inquiryList/reducer';
import { getInquiryListOptionsSelector } from 'store/inquiryList/selectors';
import { DrawerComponent } from 'theme/components/Drawer';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FiltersDrawerBody } from './FiltersDrawerBody';
import { FiltersDrawerFooter } from './FiltersDrawerFooter';

function transformFieldToInteger(value: unknown) {
  return value ? Number(value) : undefined;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FiltersDrawer = ({ isOpen, onClose }: Props) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const tableOptions = useSelector(getInquiryListOptionsSelector);

  const filteredValues = { ...tableOptions.filterBy };
  filteredValues.amountTo = transformFieldToInteger(filteredValues.amountTo);
  filteredValues.amountFrom = transformFieldToInteger(filteredValues.amountFrom);

  const onSubmit = (filterValues: IInquiryListFilterByOptions) => {
    dispatch(
      getInquiriesAction({
        ...tableOptions,
        currentPage: 1,
        touched: true,
        filterBy: { ...filterValues },
      }),
    );
  };

  const handleReset = () => {
    dispatch(
      getInquiriesAction({
        ...initialState.options,
        filterBy: {
          ...initialState.options.filterBy,
          status: undefined,
          inquiryManagerId: undefined,
        },
        touched: true,
      }),
    );
  };

  return (
    <DrawerComponent
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="lg"
      title={t('pages.inquiryList.filtersHeadline')}
      dataTestId={'filters-drawer'}
    >
      <Form<IInquiryListFilterByOptions>
        onSubmit={onSubmit}
        initialValues={filteredValues}
        render={({ handleSubmit, values }) => {
          return (
            <>
              <FiltersDrawerBody values={values} handleSubmit={handleSubmit} />
              <FiltersDrawerFooter
                handleReset={handleReset}
                handleSubmit={handleSubmit}
                onClose={onClose}
              />
            </>
          );
        }}
      />
    </DrawerComponent>
  );
};

export default FiltersDrawer;
