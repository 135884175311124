import React from 'react';

import styled from 'styled-components/macro';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import { LANGUAGE_TYPE } from 'config/partner/types';
import { FONT_WEIGHT_MEDIUM, FONT_WEIGHT_BOLD } from 'constants/globalConstants';

const StyledLanguageButton = styled.button<{ selected: boolean }>`
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: 1.125rem;
  padding: 0 5px;
  font-weight: ${({ selected }) => (selected ? FONT_WEIGHT_BOLD : FONT_WEIGHT_MEDIUM)};
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
`;

const LanguageButton = ({ language }: { language: LANGUAGE_TYPE }) => {
  const { selectedLanguage, saveLanguagePreference } = useLanguagePreferences();

  return (
    <StyledLanguageButton
      selected={selectedLanguage === language}
      type="button"
      onClick={() => {
        saveLanguagePreference(language);
      }}
      data-testid={`language-button-${language}`}
    >
      {language}
    </StyledLanguageButton>
  );
};

export default LanguageButton;
