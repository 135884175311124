import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { useDownloadFile } from 'components/DownloadBlock/useDownloadFile';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { IProgressSection } from 'pages/customerPortal/InquiryDetails/Dashboard/useProgressSections';
import {
  getBankOffersSelector,
  getlegalRepresentativesAccepted,
  isCustomerInvitationAccepted,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface UploadOffer extends Partial<IProgressSection> {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl: string;
  id?: string;
}

const canDownload = (status: string) => status !== DOWNLOAD_STATUS.NOT_UPLOADED;

export const UploadOfferStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: UploadOffer) => {
  const hasCustomerInvitationAccepted = useSelector(isCustomerInvitationAccepted);
  const { selectedInquiryType } = useFormConfig();
  const legalRepresentativesAccepted = useSelector(getlegalRepresentativesAccepted);
  const areLegalRepresentativesAccepted =
    selectedInquiryType === InquiryType.bfs && hasCustomerInvitationAccepted
      ? true
      : legalRepresentativesAccepted;
  const t = useTranslations();
  const history = useHistory();

  const [offer] = useSelector(getBankOffersSelector);

  const { bankName, fileId, status } = [offer][0]
    ? { bankName: [offer][0].bankName, fileId: [offer][0].fileId, status: [offer][0].status }
    : {
        bankName: '',
        fileId: '',
        status: DOWNLOAD_STATUS.NOT_UPLOADED,
      };
  const downloadFile = useDownloadFile({
    fileId: fileId,
    fileName: bankName,
  });

  const handleDownload = async () => {
    await downloadFile();
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('pages.inquiryDetails.dashboard.actions.uploadOffer.title')}
          </HeadingComponent>
          <TextComponent mb={3} data-testid={'Upload-Contract-Text'}>
            {t('pages.inquiryDetails.dashboard.actions.uploadOffer.description')}
          </TextComponent>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              onClick={() => history.push(actionUrl)}
              variant="primary"
              disabled={!areLegalRepresentativesAccepted}
              data-testid={'Contract-Upload'}
            >
              {t('pages.inquiryDetails.dashboard.actions.uploadOffer.action')}
            </ButtonComponent>
          )}
          {canDownload(status) && (
            <ButtonComponent onClick={handleDownload} data-testid={'Contract-Download'}>
              {t('pages.inquiryDetails.dashboard.actions.uploadOffer.downloadOffer')}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset htmlHeight="200" type="dashboard" value={ProgressSectionType.UPLOAD_OFFER} />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
