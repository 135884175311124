import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import ModalContentWithOrganizationalUnit from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor//ModalContentWithOrganizationalUnit';
import ModalContentWithoutOrganizationalUnit from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/ModalContentWithoutOrganizationalUnit';
import Section from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/Section';
import Button from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/Section/Button';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { forwardToBankAdvisorAction, useCanForwardToBankAdvisor } from './service';

const ForwardToBankAdvisorContainer = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor');
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const isOrganizationalUnitEnabled = useConfig(CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, isPending, makeCall } = useDispatchApiCall();
  const canForwardToBankAdvisor = useCanForwardToBankAdvisor(inquiryDetails);
  const { success } = useToasts();
  const dispatch = useDispatch();

  const forward = async (bankAdvisorId) => {
    onClose();

    await makeCall(
      forwardToBankAdvisorAction(inquiryDetails.inquiryId, bankAdvisorId),
      ({ payload }) => {
        dispatch(mapInquiryDetailsApiResponseAction(payload.data));
        if (isOrganizationalUnitEnabled) {
          success({ description: t('messageAfterActionOrganizationalUnit') });
        } else {
          success({ description: t('messageAfterAction') });
        }
      },
    );
  };

  const button = (
    <Button
      isLoading={isPending}
      onClick={onOpen}
      disabled={!canForwardToBankAdvisor}
      label={t('buttonConfirm')}
      data-testid="forward-to-bank-advisor"
    />
  );

  const footer = (
    <>
      <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
        {t('buttonCancel')}
      </ButtonComponent>
      <ButtonComponent form="form-forward-to-bank-advisor" type="submit">
        {t('buttonConfirm')}
      </ButtonComponent>
    </>
  );

  return (
    <Section
      title={isOrganizationalUnitEnabled ? t('titleOrganizationalUnit') : t('title')}
      description={t('description')}
      hasError={Boolean(error)}
      button={button}
    >
      {isOrganizationalUnitEnabled ? (
        <ModalComponent
          testId="forward-to-bank-advisor-modal"
          isOpen={isOpen}
          onClose={onClose}
          title={t('modalHeaderOrganizationalUnit')}
          footer={footer}
        >
          <ModalContentWithOrganizationalUnit onSubmit={forward} />
        </ModalComponent>
      ) : (
        <ModalComponent
          testId="forward-to-bank-advisor-modal"
          isOpen={isOpen}
          onClose={onClose}
          title={t('modalHeader')}
          footer={footer}
        >
          <ModalContentWithoutOrganizationalUnit onSubmit={forward} />
        </ModalComponent>
      )}
    </Section>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.FORWARD_TO_BANK_ADVISOR),
  withConfigFlagGuard(CONFIG.IS_BANK_ADVISOR_ENABLED),
)(ForwardToBankAdvisorContainer);
