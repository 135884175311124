import React, { useEffect, useState } from 'react';

import Downshift from 'downshift';
import PropTypes from 'prop-types';

import {
  StyledInputIcon,
  StyledInputWrapper,
} from 'components/PortalFormFields/InputFields/styles';
import {
  StyledSelectElement as Element,
  StyledSelectList as List,
  StyledSelectWrapper as Wrapper,
} from 'components/PortalFormFields/SelectField/styles';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';

const SelectField = ({
  options,
  shouldCloseOnSelect,
  onSelect,
  optionOnSelect,
  hasError,
  placeholder,
  name,
  value,
  dataTestId,
}) => {
  const [selectInputValue, setSelectInputValue] = useState('');

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setSelectInputValue(selectedOption.label);
    }
  }, [options, value]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const inputProps = {
    name,
    onClick: openMenu,
    onBlur: closeMenu,
    onFocus: openMenu,
    'aria-labelledby': null,
  };

  const handleSelectOption = (item) => {
    onSelect(optionOnSelect(item));
    if (shouldCloseOnSelect) {
      closeMenu();
    }
  };

  return (
    <div data-testid={dataTestId}>
      <Downshift
        onOuterClick={closeMenu}
        onSelect={handleSelectOption}
        itemToString={(item) => item && item.label}
      >
        {({ getInputProps, getRootProps, getItemProps, getMenuProps, highlightedIndex }) => (
          <Wrapper {...getRootProps({ 'aria-labelledby': null })}>
            <StyledInputWrapper hasError={hasError}>
              <input
                {...getInputProps(inputProps)}
                placeholder={placeholder}
                value={selectInputValue}
              />
              <StyledInputIcon>
                <CaretDownIcon boxSize={6} />
              </StyledInputIcon>
            </StyledInputWrapper>

            {!!options.length && (
              <List isOpen={isMenuOpen} {...getMenuProps({ 'aria-labelledby': null })}>
                {options.map((option, index) => (
                  <Element
                    data-testid={`option:${option.label}`}
                    highlighted={highlightedIndex === index}
                    {...getItemProps({ key: option.value, index, item: option })}
                  >
                    {option.label}
                  </Element>
                ))}
              </List>
            )}
          </Wrapper>
        )}
      </Downshift>
    </div>
  );
};

export const SelectOptionShape = PropTypes.shape({
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  hidden: PropTypes.bool,
  component: PropTypes.node,
});

SelectField.defaultProps = {
  shouldCloseOnSelect: true,
  onSelect: () => null,
  optionOnSelect: (option) => option.label,
  hasError: false,
  placeholder: '',
  name: null,
  value: null,
  dataTestId: '',
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(SelectOptionShape).isRequired,
  shouldCloseOnSelect: PropTypes.bool,
  optionOnSelect: PropTypes.func,
  onSelect: PropTypes.func,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
};

export default SelectField;
