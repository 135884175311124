import React from 'react';

import { Box, Grid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Rating } from '../../../../../pages/operationPortal/CompaniesDetails/RiskAnalysis/Rating';
import { RiskClass } from '../../../../../pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskClass';
import { getMarketDataFilter } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { formatDecimalWithOutRoundOff, formatPrice } from '../../../../../utils/valueFormats';
import { Card } from '../../components/Card';
import { getMarketComparisonLocationAnalysis } from '../../store/selectors';

interface MarketComparisonLocationAnalysis {
  location_index: number;
  regional_trend: string;
  local_trend_one_year: string;
  local_trend_three_years: string;
}

export interface MarketComparisonLocationAnalysisValues {
  future: {
    unit: MarketComparisonLocationAnalysis;
    building: MarketComparisonLocationAnalysis;
  };
  current: {
    unit: MarketComparisonLocationAnalysis;
    building: MarketComparisonLocationAnalysis;
  };
}

const useFormat = (number: string) => {
  const t = useTranslations();

  return `${formatPrice(formatDecimalWithOutRoundOff(number), false)} ${t('other.percent')}`;
};

const LocationAnalysis = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.marketComparison.locationAnalysis',
  );
  const filterData = useSelector(getMarketDataFilter);
  const locationAnalysisData = useSelector(getMarketComparisonLocationAnalysis);
  const locationAnalysisValues =
    locationAnalysisData?.[filterData?.usageType]?.[
      filterData?.saleType === 'building' ? 'building' : 'unit'
    ];

  const residentialLocationDescription = [
    t('residentialLocation.description.veryBasic'),
    t('residentialLocation.description.basic'),
    t('residentialLocation.description.average'),
    t('residentialLocation.description.good'),
    t('residentialLocation.description.veryGood'),
  ];

  return (
    <Box paddingTop={10}>
      <Card title={t('title')} isExpanded={true} isExpandable={true}>
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
          gap={6}
        >
          <Box position={'relative'}>
            <RiskClass
              isMarketpage
              explanationTextList={residentialLocationDescription}
              report={locationAnalysisValues?.location_index}
              heading={t('residentialLocation.title')}
              headerPadding={2}
            />
          </Box>
          <Box position={'relative'}>
            <Rating
              isMarketpage
              explanationText={t('localTrend.threeYears.description')}
              report={{ rating: useFormat(locationAnalysisValues?.local_trend_three_years) }}
              heading={t('localTrend.title')}
              headerPadding={2}
            />
          </Box>
          <Box position={'relative'}>
            <Rating
              isMarketpage
              explanationText={t('localTrend.oneYear.description')}
              report={{ rating: useFormat(locationAnalysisValues?.local_trend_one_year) }}
              heading={t('localTrend.title')}
              headerPadding={2}
            />
          </Box>
          <Box position={'relative'}>
            <Rating
              isMarketpage
              explanationText={t('regionalTrend.description')}
              report={{ rating: useFormat(locationAnalysisValues?.regional_trend) }}
              heading={t('regionalTrend.title')}
              headerPadding={2}
            />
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};

export default LocationAnalysis;
