import React from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import PERMISSIONS from 'constants/user/permissions';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { inquiryDetailsCustomerIdSelector } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledHeading } from '../../styles';
import { StyledDescription } from './styles';

const archiveUserRequest = (userId: string) =>
  callReverseApi({
    url: endpoints.CUSTOMERS.ARCHIVE.compose({ params: { id: userId } }),
    method: API_METHODS.DELETE,
  });

const ArchiveUser: React.FC = () => {
  const { isPending: isLoading, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const t = useTranslations('pages.inquiryDetails.dashboard.closeInquiry.archiveUser');
  const customerId = useSelector(inquiryDetailsCustomerIdSelector);

  const archiveUser = async () => {
    const { error } = await makeCall(archiveUserRequest(customerId));
    if (!error) {
      onClose();
      success({ description: t('userAnonymized') });
    }
  };

  return (
    <Box
      data-testid="archive-user"
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: { base: '-24px', md: '0' },
        right: { base: '0', md: 'initial' },
        bottom: { base: undefined, md: '0' },
        left: { base: '0', md: '-24px' },
        width: { base: undefined, md: '1px' },
        height: { base: '1px', md: 'initial' },
        backgroundColor: 'background.darkGrey',
      }}
    >
      <StyledHeading>{t('title')}</StyledHeading>
      <StyledDescription>{t('description')}</StyledDescription>

      <ButtonComponent onClick={onOpen} variant="secondary">
        {t('button')}
      </ButtonComponent>

      <AlertDialogComponent
        cancelText={t('cancelText')}
        confirmText={t('confirmText')}
        testId="archive-user-modal"
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={archiveUser}
        title={t('title')}
      >
        {t('modalConfirm')}
      </AlertDialogComponent>
    </Box>
  );
};

export default withRolesGuard(PERMISSIONS.ARCHIVE_USER)(ArchiveUser);
