import { useState, useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';

import { useToasts } from 'shared/hooks/useToasts';

const INITIAL_STATE = {
  errorMessage: '',
  isPendingInitially: false,
  showErrorNotification: true,
};

export const dispatchOptions = (errorMessage: string): typeof INITIAL_STATE => ({
  ...INITIAL_STATE,
  errorMessage,
});

export type IMakeCallResponse = Promise<{ error: string; payload: AxiosResponse }>;

export type IMakeCall = (apiAction: Function, onResponse?: Function) => IMakeCallResponse;

const useDispatchApiCall = ({
  errorMessage,
  isPendingInitially,
  showErrorNotification,
}: Partial<typeof INITIAL_STATE> = INITIAL_STATE) => {
  const [error, setError] = useState<Error | null>();
  const [isPending, setIsPending] = useState(isPendingInitially);
  const dispatch = useDispatch();
  const { error: notifyError } = useToasts();

  const makeCall = useCallback(
    async (apiAction, onResponse = () => {}) => {
      setError(null);
      setIsPending(true);
      const result = await dispatch(apiAction);

      if (result.error) {
        if (showErrorNotification) {
          notifyError({ description: errorMessage });
        }
        setError(result.error);
      } else {
        onResponse(result);
      }
      setIsPending(false);
      return result;
    },
    [dispatch, errorMessage, notifyError, showErrorNotification],
  );

  return {
    error,
    isPending,
    makeCall,
  };
};
export default useDispatchApiCall;
