import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { StaticFieldWithField } from 'components/StaticField';
import {
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_COMMERCIAL_SPACE,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { formatAsPercentage } from 'utils/valueFormats';

const BuildingShareOfCommercialSpace: React.FC = () => {
  const totalLivingSpace = useGetFieldNumericValue(BUILDING_TOTAL_USABLE_SPACE);
  const areaForCommercialUse = useGetFieldNumericValue(BUILDING_COMMERCIAL_SPACE);
  const { change } = useForm();
  const fieldController = FormFieldController.instance;

  useEffect(() => {
    const changeValue = formatAsPercentage(areaForCommercialUse / totalLivingSpace) || 0;

    change(BUILDING_SHARE_OF_COMMERCIAL_SPACE, changeValue);

    fieldController.saveValue(BUILDING_SHARE_OF_COMMERCIAL_SPACE, changeValue);
  }, [fieldController, areaForCommercialUse, change, totalLivingSpace]);

  return <StaticFieldWithField name={BUILDING_SHARE_OF_COMMERCIAL_SPACE} />;
};

export default BuildingShareOfCommercialSpace;
