import * as React from 'react';

import FormSection from 'components/FormSection';
import { Condition } from 'new/form/Condition';

import DisabledFormSection from '../../../../../../components/DisabledFormSection';
import { translations } from '../../../../../../new/form/common/types';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import { CurrentUsageData } from './CurrentUsageData';
import { FutureUsageData } from './FutureUsageData';
import { GeneralData } from './GeneralData';
import { ParkingData } from './ParkingData';
import { QualityData } from './QualityData';
import { RentalData } from './RentalData';

export const ObjectInformationSection = () => {
  const t = useTranslations();
  const { title, fallback } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation;

  return (
    <Condition
      validation={'projectCompositionSection'}
      fallback={
        <DisabledFormSection sectionNumber={3} placeholder={t(fallback)} title={t(title)} />
      }
    >
      <FormSection title={t(title)} name={'objectInformation'} sectionNumber={3}>
        <GeneralData />
        <QualityData />
        <ParkingData />
        <FutureUsageData />
        <CurrentUsageData />
        <RentalData />
      </FormSection>
    </Condition>
  );
};
