import React, { useEffect } from 'react';

import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import FormRow from 'components/FormRow';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  USER_REPRESENTATIVE,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_EMAIL,
  COMPANY_DETAILS,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_EXISTING_REPRESENTATIVE,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import RepresentativeBirthdate from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeBirthdate';
import RepresentativeEmail from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeEmail';
import RepresentativeFirstName from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeFirstName';
import RepresentativeGender from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeGender';
import RepresentativeLastName from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeLastName';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import {
  StyledPeopleSection,
  StyledPeopleInfo,
  StyledSinglePerson,
  StyledSinglePersonFields,
  StyledRemoveButton,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { getCompanyData } from 'store/inquiryDetails/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { PeopleDetailsMultiSelectOption } from '../../MultiSelectPeopleDetails';
import RepresentativePhoneNumber from '../fields/RepresentativePhoneNumber';
import { LegalRepresentativesWrapper } from './styles';

export type FieldValue = {
  [REPRESENTATIVE_GENDER]: string;
  [REPRESENTATIVE_FIRST_NAME]: string;
  [REPRESENTATIVE_LAST_NAME]: string;
  [REPRESENTATIVE_EMAIL]: string;
  id: string;
  isAssociatedPerson?: boolean;
};

type SinglePersonProps = {
  deleteThreshold: number;
  onRemove: () => void;
  fieldsNumber?: number;
};

interface LegalRepsMultiSelectOption extends MultiSelectOption {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthDate: Date;
  type: 'legal_representatives' | 'beneficiary_owners';
}

const SinglePerson = ({ fieldsNumber, onRemove, deleteThreshold }: SinglePersonProps) => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );

  const handleRemove = (e: any) => {
    e.preventDefault();
    // This function wrapper ensures not to pass event as an argument to onRemove
    onRemove();
  };

  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        {fieldsNumber && fieldsNumber > deleteThreshold && (
          <StyledRemoveButton
            onClick={handleRemove}
            aria-label={t('removePerson')}
            data-testid="remove-button"
          >
            <DeleteIcon boxSize={6} />
          </StyledRemoveButton>
        )}

        <FormRow>
          <RepresentativeGender />
        </FormRow>
        <FormRow>
          <RepresentativeFirstName />
          <RepresentativeLastName />
        </FormRow>
        <FormRow>
          <RepresentativeEmail />
        </FormRow>
        <FormRow>
          <RepresentativePhoneNumber />
        </FormRow>
        <FormRow>
          <RepresentativeBirthdate />
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

const LegalRepresentatives = () => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);
  const selectedCompany = useSelector<any, any>(getStoredValueSelector([COMPANY_DETAILS]));
  const companyData = useSelector(getCompanyData);
  const companyId = selectedCompany?.[COMPANY_DETAILS_EXISTING_USER_COMPANY]?.id || companyData?.id;
  const { fields: fieldsExistingRepresentative } = useFieldArray(USER_EXISTING_REPRESENTATIVE);

  const { data, isLoading } = useFetchAssociatedPersons(companyId);
  const legalRepresentatives = data.filter(
    (p: IAssociatedPerson) => p.type === 'legal_representatives',
  );

  /*
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
   */
  const associatedPersons = legalRepresentatives
    .map<PeopleDetailsMultiSelectOption>((person) => ({
      key: person.id,
      label: `${person.firstName} ${person.lastName}`,
      value: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      gender: person.salutation,
      birthDate: person.birthDate,
      type: person.type,
    }))
    .filter((p) => p.type === 'legal_representatives');

  const handleAddPerson = () => {
    const index = fieldsUserRepresentative.length;

    const representative = {
      [REPRESENTATIVE_GENDER]: '',
      [REPRESENTATIVE_FIRST_NAME]: '',
      [REPRESENTATIVE_LAST_NAME]: '',
      [REPRESENTATIVE_EMAIL]: '',
      id: uuidv4(),
    };

    FormFieldController.instance.saveValue(`${USER_REPRESENTATIVE}[${index}]`, representative);
    fieldsUserRepresentative.push(representative);
  };

  useEffect(() => {
    if (!isLoading && legalRepresentatives.length === 0 && fieldsUserRepresentative.length === 0) {
      handleAddPerson();
    }
  }, [legalRepresentatives, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handelRemovePerson = (index: number) => () => {
    const fieldController = FormFieldController.instance;
    const singleLegalRep = fieldsUserRepresentative.value[index];
    fieldController.deleteValue(USER_REPRESENTATIVE, index);
    fieldsUserRepresentative.remove(index);
    // Removes the person from fieldsExistingRepresentative if it exists
    const indexFieldsExistingRepresentative = fieldsUserRepresentative.value.findIndex(
      (representative) => representative.id === singleLegalRep.id,
    );
    if (indexFieldsExistingRepresentative > -1) {
      fieldsExistingRepresentative.remove(indexFieldsExistingRepresentative);
    }
  };

  const isAssociatedPerson = associatedPersons.length > 0;
  const { required } = useFieldValidators();
  const [search, setSearch] = React.useState('');

  const handleSelect = (newPerson: LegalRepsMultiSelectOption) => {
    const index = fieldsUserRepresentative.length;
    //REPRESENTATIVE_PHONE_NUMBER
    // wow
    const representative = {
      [REPRESENTATIVE_GENDER]: newPerson.gender,
      [REPRESENTATIVE_FIRST_NAME]: newPerson.firstName,
      [REPRESENTATIVE_LAST_NAME]: newPerson.lastName,
      [REPRESENTATIVE_EMAIL]: newPerson.email,
      [REPRESENTATIVE_BIRTHDATE]: formatDateDays(newPerson.birthDate),
      [REPRESENTATIVE_PHONE_NUMBER]: newPerson.phoneNumber,
      id: newPerson.value.toString(),
      isAssociatedPerson: true,
    };
    FormFieldController.instance.saveValue(`${USER_REPRESENTATIVE}[${index}]`, representative);
    fieldsUserRepresentative.push(representative);
  };

  const handleRemove = (keyToRemove: string) => {
    const itemExistsIndex = fieldsExistingRepresentative.value?.findIndex(
      ({ key }) => key === keyToRemove,
    );

    fieldsExistingRepresentative.remove(itemExistsIndex > -1 ? itemExistsIndex : 0);

    const fieldController = FormFieldController.instance;
    const indexToRemove = fieldsUserRepresentative.value.findIndex(
      (representative) => representative.id === keyToRemove,
    );
    if (indexToRemove > -1) {
      fieldController.deleteValue(USER_REPRESENTATIVE, indexToRemove);
      fieldsUserRepresentative.remove(indexToRemove);
    }
  };

  const togglePerson = (person: LegalRepsMultiSelectOption) => {
    if (fieldsExistingRepresentative.value?.findIndex(({ key }) => key === person.key) > -1) {
      handleRemove(person.key);
    } else {
      handleSelect(person);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleValidate = (
    value: unknown,
    fields: {
      [USER_REPRESENTATIVE]?: unknown[];
    },
  ) => {
    const field = fields[USER_REPRESENTATIVE];
    const isAdditionalPerson = field && field?.length > 0;
    return isAdditionalPerson ? undefined : required(value, fields);
  };

  const options = associatedPersons.filter(({ label }) =>
    label.toLocaleLowerCase().includes(search.toLowerCase()),
  );

  return (
    <StyledPeopleSection>
      <StyledPeopleInfo>{t('heading')}</StyledPeopleInfo>
      {isAssociatedPerson && (
        <>
          <MultiSelectWithField
            name={USER_EXISTING_REPRESENTATIVE}
            placeholder={t('placeholders.pleaseChoose')}
            options={options}
            validate={handleValidate}
            selectedOptions={fieldsExistingRepresentative.value || []}
            isLoading={false}
            onSelect={togglePerson}
            onRemove={handleRemove}
            onInputChange={handleSearch}
            caption={t('existingLegalRepresentatives')}
            data-testid={'select-field-' + USER_EXISTING_REPRESENTATIVE}
          />

          {fieldsUserRepresentative.length === 0 && (
            <>
              <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
              <ButtonComponent
                leftIcon={<AddIcon boxSize={6} d="block" />}
                onClick={handleAddPerson}
              >
                {t('addPerson')}
              </ButtonComponent>
            </>
          )}
        </>
      )}
      {fieldsUserRepresentative.map((fieldName, index) => (
        <LegalRepresentativesWrapper>
          <ParentFieldContext.Provider
            value={{
              parentFieldName: '',
              parentIndex: 0,
            }}
            key={fieldsUserRepresentative.value[index].id}
          >
            <LegalRepresentativesContext.Provider value={{ userIndex: index, fieldName }}>
              <SinglePerson
                fieldsNumber={fieldsUserRepresentative.length}
                onRemove={handelRemovePerson(index)}
                deleteThreshold={isAssociatedPerson ? 0 : 1}
              />
            </LegalRepresentativesContext.Provider>
          </ParentFieldContext.Provider>
        </LegalRepresentativesWrapper>
      ))}
      {fieldsUserRepresentative.length ? (
        <>
          <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
          <ButtonComponent leftIcon={<AddIcon boxSize={6} d="block" />} onClick={handleAddPerson}>
            {t('addPerson')}
          </ButtonComponent>
        </>
      ) : null}
    </StyledPeopleSection>
  );
};

export default React.memo(LegalRepresentatives);
