import { InitialFormStateType } from 'new/form/model';

import { MittweidaFieldTypes } from '../fields';

export const mittweidaInitialFormState: InitialFormStateType<MittweidaFieldTypes> = {
  currentStep: 'financingNeedPage',
  inquiryMode: 'create',
  pageValidations: {
    financingNeedPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        roleSection: false,
        projectCompositionSection: false,
        objectInformationSection: false,
        otherProjectInformation: false,
      },
    },
    fundingDetailsPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        otherFundingSection: false,
      },
    },
    userProfilePage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        contactPersonSection: false,
        passwordSection: false,
        termsAndConditionsSection: false,
      },
    },
    companyDetailPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        companyDetailsSection: false,
        completingCompanyDetailsSection: false,
      },
    },
  },
};
