import React from 'react';

import { Flex, useRadioGroup } from '@chakra-ui/react';

import RadioButtonComponent from '../RadioButton';

type props = {
  name: string;
  defaultValue?: string;
  options: { caption?: any; value: string | number | undefined; isDisabled?: boolean }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentValue: string;
  radioWidth: string;
};

const RadioGroupComponent = ({
  name,
  defaultValue,
  options,
  currentValue,
  ...restProps
}: props) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: defaultValue,
  });

  const group = getRootProps();

  const check = options.find((element) => element.value === defaultValue);
  if (check === undefined && defaultValue) {
    throw new Error('RadioGroup: The default value must be included in the Options Array');
  }

  return (
    <Flex {...group} width={'100%'} justifyContent={'start'} mr={5} gap={6}>
      {options.map(({ value, caption, isDisabled }) => {
        const radio = getRadioProps({ value });
        const id = `${name}-${value}`;
        return (
          <RadioButtonComponent
            {...radio}
            {...restProps}
            id={id}
            isChecked={value === currentValue}
            value={value}
            key={value}
            isDisabled={isDisabled}
          >
            {caption}
          </RadioButtonComponent>
        );
      })}
    </Flex>
  );
};

export default RadioGroupComponent;
