import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import Condition from 'components/Condition';
import FileRequestBlock from 'components/UploadBlock/NewFileRequestBlock/FileRequestBlock';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import { useFormConfig } from 'config/formConfig/hooks';
import { IFileRequest } from 'models/FileRequest.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import type { ApiFile } from 'shared/documentExchange/documentExchange.service';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { uploadFileToFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getFileRequestsSelector } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionSubtitle from '../FileSectionSubtitle';
import FileSectionTitle from '../FileSectionTitle';

const FileRequestsFromBank: React.FC = () => {
  const fileRequests: IFileRequest[] = useSelector(getFileRequestsSelector);
  const dispatch = useDispatch();
  const { selectedInquiryType } = useFormConfig();

  const handleFileUploaded = (requestId: string) => (file: ApiFile) => {
    dispatch(uploadFileToFileRequestAction(requestId, mapApiFile(file)));
  };

  const inquiryId = useSelector(getInquiryIdSelector);
  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });

  const t = useTranslations(
    `pages.inquiryDetails.documentExchange${
      selectedInquiryType === InquiryType.leaseplan ? `.${InquiryType.leaseplan}` : ''
    }`,
  );
  const subtitleTranslate = useTranslations('pages.inquiryDetails.documentExchange');
  const numOfFilledRequests = fileRequests.filter((req) => !!req.files?.length).length;
  const numOfRequests = fileRequests.length;

  let requestDescriptionLength = 100;

  if (selectedInquiryType === InquiryType.bfsService) {
    requestDescriptionLength = 0;
  }

  const requiredFiles = fileRequests.filter((file) => file.required);

  const optionalFiles = fileRequests.filter((file) => !file.required);

  const isMultipleFilesPerUploadRequestEnabled = useConfig(
    CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST,
  );

  return (
    <section>
      <FileSectionTitle>
        {t('filesRequestedFromBank')} ({numOfFilledRequests}/{numOfRequests})
      </FileSectionTitle>

      <FileSectionSubtitle>{subtitleTranslate('requiredFiles')}</FileSectionSubtitle>
      <VStack>
        {requiredFiles.map((request) =>
          isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
            <MultipleFilesBlock
              key={request.id}
              fileRequestId={request.id}
              isFileUploadingEnabled
              {...request}
            />
          ) : (
            <FileRequestBlock
              key={request.id}
              fileRequest={request}
              url={uploadUrl}
              onUpload={handleFileUploaded(request.id)}
              requestDescriptionLength={requestDescriptionLength}
            />
          ),
        )}
      </VStack>

      <Condition condition={!!optionalFiles.length}>
        <FileSectionSubtitle>{subtitleTranslate('optionalFiles')}</FileSectionSubtitle>
        <VStack>
          {optionalFiles.map((request) =>
            isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
              <MultipleFilesBlock
                key={request.id}
                fileRequestId={request.id}
                isFileUploadingEnabled
                {...request}
              />
            ) : (
              <FileRequestBlock
                key={request.id}
                fileRequest={request}
                url={uploadUrl}
                onUpload={handleFileUploaded(request.id)}
                requestDescriptionLength={requestDescriptionLength}
              />
            ),
          )}
        </VStack>
      </Condition>
    </section>
  );
};

export default FileRequestsFromBank;
