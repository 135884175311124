import React from 'react';

import CloseIcon from 'theme/components/Icon/CloseIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import CloseButton from './CloseButton';
import TagLabel from './TagLabel';
import TagWrapper from './TagWrapper';

interface TagProps {
  label: string;
  children: React.ReactNode;
  onRemove?: () => void;
}

const Tag = ({ onRemove, children, label }: TagProps) => {
  const t = useTranslations();

  const handleRemove = () => {
    if (onRemove) onRemove();
  };

  return (
    <TagWrapper>
      <TagLabel>{children}</TagLabel>
      <CloseButton
        onClick={handleRemove}
        type="button"
        aria-label={t('other.remove', { bank: label })}
      >
        <CloseIcon boxSize={4} />
      </CloseButton>
    </TagWrapper>
  );
};

export default Tag;
