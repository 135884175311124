import { PRIMARY_FONT, FONT_WEIGHT_BOLD } from 'constants/globalConstants';

import { textStyle } from './themeConstants';

const globalStyles = {
  // CSS Reset
  'body, h1, h2, h3, h4, h5, h6, p, figure, blockquote, ul, ol, dl, dt, dd': {
    margin: 0,
    padding: 0,
  },

  'h1, h2, h3, h4, h5, h6': {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },

  // Superior Styles
  html: {
    boxSizing: 'border-box',
  },

  '*, *::before, *::after': {
    boxSizing: 'inherit',
    borderColor: 'inherit', // Chakra overwrite
    wordWrap: 'normal', // Chakra overwrite
  },

  '*::placeholder': {
    color: 'inherit', // Chakra overwrite
  },

  '[hidden]': {
    display: 'none',
  },

  body: {
    minHeight: '100vh',
    fontFamily: PRIMARY_FONT,
    ...textStyle.sizeM,
    fontFeatureSettings: "'kern' 1",
    fontKerning: 'normal',
    textSizeAdjust: '100%',
    textAlign: 'left',
    color: 'text.primary',
    bg: 'background.white',
    transition: 'none', // Chakra overwrite
  },

  // Generell Text Formating
  'abbr[title]': {
    borderBottom: 'none',
    textDecoration: 'none',
  },

  'address, dfn': {
    fontStyle: 'normal',
  },

  'b, strong': {
    fontWeight: FONT_WEIGHT_BOLD,
  },

  'i, em': {
    fontStyle: 'italic',
  },

  'pre, code, kbd, samp, var': {
    fontFamily: 'monospace, monospace',
  },

  small: {
    fontSize: '80%',
  },

  'sub, sup': {
    position: 'relative',
    verticalAlign: 'baseline',
    fontSize: '75%',
  },

  sub: {
    bottom: '-0.25em',
  },

  sup: {
    top: '-0.5em',
  },

  mark: {
    color: 'text.white',
    backgroundColor: 'background.neutral',
  },

  '::selection': {
    textShadow: 'none',
    color: 'text.white',
    bgColor: 'background.neutral',
  },

  // Horizontal Rules
  hr: {
    height: '1px',
    margin: 0,
    border: 0,
    padding: 0,
    bgColor: 'border.lightGrey',
  },

  // Images
  'img, picture': {
    display: 'block',
    borderStyle: 'none',
    maxWidth: '100%',
  },

  // Links
  a: {
    textDecorationSkip: 'objects',
    bgColor: 'transparent',
    cursor: 'pointer',

    ':focus': {
      outline: 0, // focus-style will be set individually
    },

    '&[href^="tel:"]:focus': {
      boxShadow: 'none', // Chakra overwrite
    },
  },

  // Buttons
  button: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    appearance: 'none',
    display: 'inline-block',
    overflow: 'visible',
    position: 'relative',
    verticalAlign: 'top',
    margin: 0,
    border: 0,
    padding: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    textAlign: 'center',
    textTransform: 'none',
    color: 'inherit',
    bgColor: 'transparent',
    cursor: 'pointer',

    ':focus': {
      outline: 0, // focus-style will be set individually
    },

    '::-moz-focus-inner': {
      border: 0,
      padding: 0,
    },

    ':-moz-focusring': {
      outline: '1px dotted ButtonText',
    },

    '&[disabled]': {
      opacity: '0.3',
      cursor: 'not-allowed',
    },
  },

  // Forms
  fieldset: {
    minWidth: 0,
    margin: 0,
    border: 0,
    padding: '0.01em 0 0 0',
  },

  legend: {
    display: 'table',
    maxWidth: '100%',
    padding: 0,
    whiteSpace: 'normal',
    color: 'inherit',
  },

  'input, optgroup, select, textarea': {
    margin: 0,
    fontFamily: 'inherit',
    fontSize: '16px',
    lineHeight: '1.25',
  },

  select: {
    textTransform: 'none',

    '::-ms-expand': {
      display: 'none',
    },
  },

  textarea: {
    overflowY: 'auto',
  },

  input: {
    overflow: 'visible',

    "&[type='button']::-moz-focus-inner, &[type='reset']::-moz-focus-inner, &[type='submit']::-moz-focus-inner":
      {
        border: 0,
        padding: 0,
      },

    "&[type='checkbox'], &[type='radio']": {
      padding: 0,
      cursor: 'pointer',
    },

    "&[type='date']": {
      textTransform: 'uppercase',
    },

    "&[type='number']": {
      appearance: 'textfield',

      '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
        appearance: 'none',
        height: 'auto',
      },
    },

    "&[type='range']::-moz-focus-outer": {
      border: 0,
    },

    "&[type='search']": {
      appearance: 'none',

      '::-webkit-search-decoration, ::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration':
        {
          display: 'none',
        },
    },

    '&[placeholder]': {
      textOverflow: 'ellipsis',
    },

    '&[disabled]': {
      opacity: '0.3',
      cursor: 'not-allowed',
    },

    '::-ms-clear, ::-ms-reveal': {
      display: 'none',
    },
  },

  '::-webkit-file-upload-button': {
    appearance: 'button',
    font: 'inherit',
  },

  '::-webkit-input-placeholder': {
    WebkitFontSmoothing: 'antialiased',
    color: 'text.tertiary',
  },

  '::-moz-placeholder': {
    opacity: '1',
    MozOsxFontSmoothing: 'grayscale',
    color: 'text.tertiary',
  },

  '::-ms-input-placeholder': {
    color: 'text.tertiary',
  },

  // Misc
  summary: {
    display: 'list-item',
  },

  template: {
    display: 'none',
  },
};

export default globalStyles;
