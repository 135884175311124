import React, { useCallback, useEffect, useState } from 'react';

import { Box, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import paths from 'constants/paths';
import { USER_SCOPE } from 'constants/userScopes';
import type { IFile } from 'models/File.model';
import type { ApiFile } from 'shared/documentExchange/documentExchange.service';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { useFetchInquiryFiles } from 'shared/documentExchange/useFetchInquiryFiles';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalFilesSendByOperation } from 'store/documentExchange/documentExchange.selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { isRealEstateExpert } from 'store/user/selectors';
import { formatDateWithTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimalWithOutRoundOff, formatPrice } from 'utils/valueFormats';

import mapRealEstateValuesToEdit from '../EditMarketValuesReeProfitability/mapRealEstateValuesToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import {
  getMarketDataFilter,
  RESET_MARKETDATA_FILTER,
} from '../SharedComponent/filterManagementReducer';
import { fetchMarketData } from '../SharedComponent/PlanningEvaluation.service';
import RealStateMarketDetails from '../SharedComponent/RealStateMarketDetails';
import {
  appraiserObjectEvalNameRowTranslationPaths,
  appraiserObjectFromApiMapping,
  APPRAISE_OBJECT_EVALUATION_ROW_TYPES,
  getAppraiserObjectEvalUnitRows,
  IPropertyEvaluation,
  IRealStateApiResponse,
} from './utils';

export const Appraiser = () => {
  const { editMarketValueDetailsRee } = paths.operation.inquiryDetails.planningEvaluation;
  const download = useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.OPERATION_PORTAL });
  let files: IFile[] = useSelector(getAdditionalFilesSendByOperation);
  files = files && files.filter((file: IFile) => !!file.isRealEstateExpert);
  const t = useTranslations('pages.planningEvaluation.appraiser');
  const unitTranslations = useTranslations();
  const { makeCall, isPending } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });
  const filterData = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getCreatedInquiryId) || '';

  const isRealEstateExpertEnabled = useSelector(isRealEstateExpert);

  const [payloadResponse, setPayloadResponse] = useState<IRealStateApiResponse>();
  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });

  const dispatch = useDispatch();
  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  const fetchValues = useCallback(async () => {
    const response = await makeCall(fetchMarketData(inquiryId));
    if (typeof response.payload.data === 'object') {
      setPayloadResponse(response.payload.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryId, filterData?.usageType, filterData?.saleType]);

  useEffect(() => {
    fetchValues();
  }, [fetchValues]);

  useEffect(() => {
    dispatch({ type: RESET_MARKETDATA_FILTER });
  }, [dispatch]);

  if (download.isLoading || (isPending && !payloadResponse)) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }
  return (
    <>
      <RealStateMarketDetails
        heading={t('pageTitle')}
        subheading={t('subheading')}
        subheadingDate={formatDateWithTime(payloadResponse?.last_calculated_at)}
        pathToEdit={editMarketValueDetailsRee(inquiryId)}
        recordType={PROFI_RECORD_TYPE.REE}
        editFormStructure={mapRealEstateValuesToEdit()}
        clientDetailBoxes={{
          saleRevenue: payloadResponse?.potential_sales_revenue || 0,
          rentalPerYear: payloadResponse?.possible_rental_income || 0,
          livingSpace: payloadResponse?.living_space || 0,
          commercialSpace: payloadResponse?.commercial_space || 0,
        }}
        tableData={
          payloadResponse &&
          Object.keys(APPRAISE_OBJECT_EVALUATION_ROW_TYPES).map(
            (type) => ({
              name: t(`table.rows.${appraiserObjectEvalNameRowTranslationPaths[type]}`),
              unit: getAppraiserObjectEvalUnitRows(unitTranslations)[type],
              values:
                formatPrice(
                  formatDecimalWithOutRoundOff(
                    (payloadResponse?.property_evaluation as IPropertyEvaluation)[
                      appraiserObjectFromApiMapping[type]
                    ],
                  ),
                ) || '0,00',
            }),
            false,
          )
        }
        tableHeaders={[
          {
            key: 'name',
            text: '',
          },
          {
            key: 'unit',
            text: t('table.columns.unit'),
          },
          {
            key: 'values',
            text: t('table.columns.values'),
          },
        ]}
      />
      <Box boxShadow="xl" p="0">
        {files && files.length === 0 ? (
          <>
            {isRealEstateExpertEnabled && (
              <UploadAnyFile onUpload={addFileToList} url={uploadUrl} isReePage={true} />
            )}
          </>
        ) : (
          <UploadedFileBlock
            file={files[0]}
            canRemove
            isReeEnabled={isRealEstateExpertEnabled}
            isReePage={true}
          />
        )}
      </Box>
    </>
  );
};
