import React from 'react';

import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { USER_REPRESENTATIVE, USER_SHARED_REPRESENTATION } from 'modules/Inquiry/Form/formFields';
import { FormValuesStore } from 'store/FormFieldController/FormValuesStore';
import { useTranslations } from 'utils/hooks/useTranslations';

const UserSharedRepresentation = () => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.userSharedRepresentation',
  );
  return (
    <FormRow>
      <YesNoRadioGroupWithField
        onChange={(value: boolean) => {
          if (value) {
            FormValuesStore.instance.saveValue(USER_REPRESENTATIVE, [], { override: true });
          }
        }}
        name={USER_SHARED_REPRESENTATION}
        tooltip={t('tooltip')}
      />
    </FormRow>
  );
};

export default UserSharedRepresentation;
