import React from 'react';

import { size } from 'lodash';
import { useSelector } from 'react-redux';

import { useMultipleFiles } from 'components/UploadBlock/hooks/useMultipleFiles';
import { IState } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileReclassificationButton from './FileReclassificationButton';
import { FileReclassificationButtonSelector } from './selectors';

const OTHER_PLACEHOLDER = '__OTHER__';

interface FileReclassificationButtonProps {
  fileId: string;
  fileRequestId?: string;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

const FileReclassificationButtonContainer = ({
  fileId,
  fileRequestId,
  isPrivateFile,
  isAssessmentFile,
}: FileReclassificationButtonProps) => {
  const { reclassifyFile } = useMultipleFiles({ fileRequestId, isPrivateFile });
  const t = useTranslations('components.upload.reclassifyDocumentModal');
  const { classificationOptions, file, fileRequest } = useSelector((state: IState) =>
    FileReclassificationButtonSelector(state, { fileId, fileRequestId }),
  );

  const handleReclassification = (classification: string) =>
    reclassifyFile
      ? reclassifyFile(fileId, classification === OTHER_PLACEHOLDER ? null : classification)
      : null;
  const options = [...classificationOptions, { label: t('other'), value: OTHER_PLACEHOLDER }];
  const isReclassifiable = size(options) > 1;
  const isPotentiallyIncorrectlyClassified =
    !isAssessmentFile &&
    !isPrivateFile &&
    file.classification != null &&
    file.classification !== fileRequest?.classification;

  if (!isReclassifiable) return null;

  return (
    <FileReclassificationButton
      isPotentiallyIncorrectlyClassified={isPotentiallyIncorrectlyClassified}
      initialClassification={fileRequest?.classification || OTHER_PLACEHOLDER}
      onReclassify={handleReclassification}
      options={options}
    />
  );
};

export default FileReclassificationButtonContainer;
