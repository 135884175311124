import React from 'react';

import { FinalFormFieldPropTypes } from 'utils/form/propTypesTS';
import withFormField from 'utils/form/withFormField';

import { CheckboxComponent } from '../../theme/components/Checkbox';
import ControlWrapper from '../ControlWrapper/index';

type CheckboxComponentProps = {
  caption?: string;
  tooltip?: string;
  error?: string;
  optional?: boolean;
  value?: boolean;
  disabled?: boolean;
  text?: React.ReactNode | string;
  name: string;
  errorMessage?: string;
  size?: 'sm' | 'lg';
};

const Checkbox = ({
  caption,
  tooltip,
  error,
  optional,
  value,
  disabled,
  text,
  name,
  errorMessage,
  size = 'lg',
  ...restProps
}: CheckboxComponentProps) => {
  return (
    <ControlWrapper
      caption={caption}
      tooltip={tooltip}
      isCheckboxTooltip={!!tooltip}
      optional={optional}
      errorMessage={errorMessage}
      isCheckBox
    >
      <CheckboxComponent disabled={disabled} size={size} value={value} name={name} {...restProps}>
        {text}
      </CheckboxComponent>
    </ControlWrapper>
  );
};

export const CheckboxAdapter = ({ input, meta, ...rest }: FinalFormFieldPropTypes) => {
  return <Checkbox {...input} {...meta} {...rest} />;
};

export const CheckboxWithField = withFormField(CheckboxAdapter);

export default Checkbox;
