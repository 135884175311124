import React from 'react';

import PropTypes from 'prop-types';

import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateDays, formatDateTime } from 'utils/date';

import {
  StyledWrapper as Wrapper,
  StyledHeader as Header,
  StyledColumn as Column,
  StyledComment as Comment,
} from './styles';

const InternalCommentsItem = ({ firstName, lastName, date, comment }) => {
  return (
    <Wrapper>
      <Header>
        <Column>
          <UserIcon boxSize={6} mr={2} />
          {firstName} {lastName}
        </Column>
        <Column>
          <TimeIcon boxSize={6} mr={2} />
          {formatDateDays(date)}; {formatDateTime(date)}
        </Column>
      </Header>
      <Comment>{comment}</Comment>
    </Wrapper>
  );
};

InternalCommentsItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default InternalCommentsItem;
