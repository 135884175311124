import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import {
  FILTERS_SALE_TYPES,
  FILTERS_USAGE_TYPES,
  PROFITABILITY_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';

import ProfitabilitySale, { SaleType } from '../../ProfitabilitySale';
import SaleAsUnitBoxes from './SaleAsUnitBoxes';
import SalesInPresalePhase, { SalePresaleType } from './SalesInPresalePhase';

interface Props {
  data: {
    sales_in_presales_phase_data: SalePresaleType;
    sale_as_unit_data: SaleType;
  };
  boxes: { [key: string]: string | number };
}

const SaleAsUnit: React.FC<Props> = ({ data, boxes }) => {
  const { saleType, usageType } = useSelector(getMarketDataFilter);
  const { sales_in_presales_phase_data, sale_as_unit_data } = data || {};

  return (
    <Condition condition={saleType === FILTERS_SALE_TYPES.UNIT}>
      <ProfitabilitySale
        data={sale_as_unit_data}
        profitabilityFormType={PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT}
      />
      <Condition condition={usageType === FILTERS_USAGE_TYPES.FUTURE}>
        <SaleAsUnitBoxes tiles={boxes} />
        <SalesInPresalePhase data={sales_in_presales_phase_data} />
      </Condition>
    </Condition>
  );
};

export default SaleAsUnit;
