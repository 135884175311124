import { useContext, useEffect, useState } from 'react';

import _get from 'lodash/get';
import { useField } from 'react-final-form';

import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';

import { FormFieldController } from '../FormFieldController/FieldController';
import { FormValueDataEmit } from '../FormFieldController/types';

const formFieldController = FormFieldController.instance;

const useCreateUnexistingField = (fieldName: string): void => {
  useField(fieldName);
};

export const useFormValues = (filtersArray?: []): Record<string, unknown> => {
  const [values, setValues] = useState<Record<string, unknown>>(formFieldController.formValues);

  useEffect(() => {
    const subscription = formFieldController.subscribe(
      (data: FormValueDataEmit) => setValues(data?.formValues),
      filtersArray,
    );

    return () => {
      if (subscription) {
        formFieldController.unsubscribe(subscription);
      }
    };
  }, [filtersArray, setValues]);

  return values;
};

export const useFieldChangedValue = <T>(name: string, defaultValue: T, parentCheck = true): T => {
  const { parentFieldName } = useContext(ParentFieldContext);
  const fieldName =
    parentCheck && parentFieldName && !name.includes(parentFieldName)
      ? `${parentFieldName}.${name}`
      : name;
  const { value: originalValue } = formFieldController.getValue(fieldName);

  const [value, setValue] = useState<unknown>(originalValue || defaultValue);

  // TODO - this is needed due to the fact that useField from react-final-form under the hood
  //  creates a field object. When we remove useField and exchange it with useFieldChangedValue object
  //  is not being created and validation fails.
  useCreateUnexistingField(fieldName);

  useEffect(() => {
    const subscription = formFieldController.subscribe(
      (data) => {
        if (data.changedField) {
          setValue(data.changedField.newValue);
        }
      },
      [subscribeByName(fieldName)],
    );

    return () => {
      if (subscription) {
        formFieldController.unsubscribe(subscription);
      }
    };
  }, [name, fieldName, setValue]);

  return value as T;
};

export const useFieldValue = <T = unknown>(name: string, defaultValue: T): T => {
  const [values, setValues] = useState<Record<string, unknown>>(formFieldController.formValues);
  const { parentFieldName } = useContext(ParentFieldContext);
  const fieldName =
    parentFieldName && !name.includes(parentFieldName) ? `${parentFieldName}.${name}` : name;

  // TODO - this is needed due to the fact that useField from react-final-form under the hood
  //  creates a field object. When we remove useField and exchange it with useFieldValue object
  //  is not being created and validation fails.
  useCreateUnexistingField(fieldName);

  useEffect(() => {
    const subscription = formFieldController.subscribe((data) => {
      setValues(data.formValues);
    });

    return () => {
      if (subscription) {
        formFieldController.unsubscribe(subscription);
      }
    };
  }, [setValues]);

  const value = _get(values, fieldName) as T;

  return value || defaultValue;
};

const subscribeByName = (name: string) => (data: FormValueDataEmit) => {
  return !!data?.changedField?.name?.includes(name);
};
