import jwt from 'jsonwebtoken';
import _get from 'lodash/get';

import {
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';
import { UserRole, UserRolesFromApi } from 'models/UserData.model';
import { IState } from 'store';

type RawUserData = {
  salutation: unknown;
  roles?: UserRolesFromApi;
};

function hasOwnProps<T extends object>(obj: T, ...args: string[]) {
  return args.every((key) => Object.prototype.hasOwnProperty.call(obj, key));
}

const isUser = (item: unknown): item is RawUserData => {
  return typeof item === 'object' && item !== null && hasOwnProps(item, 'roles');
};

type Token = {
  /* eslint-disable camelcase */
  access_token: string;
  refresh_token: string;
  identity_token: string;
  /* eslint-enable camelcase */
};

export const getAccessToken = (state: IState) => _get(state, 'user.token');

export const getUserTokens = ({ access_token: token, refresh_token: refreshToken }: Token) => ({
  token,
  refreshToken,
});

const mapUserRoles = (roles: UserRolesFromApi): UserRole[] => {
  const mappedRoles = {
    [USER_ROLE_INQUIRY_MANAGER]: roles.inquiry_manager,
    [USER_ROLE_CUSTOMER]: roles.customer,
    [USER_ROLE_CALL_CENTER_AGENT]: roles.call_center_agent,
    [USER_ROLE_BANK_ADVISOR]: roles.bank_advisor,
    [USER_ROLE_REAL_ESTATE_EXPERT]: roles.real_estate_expert,
  };
  return (Object.keys(mappedRoles) as Array<keyof typeof mappedRoles>).filter(
    (role) => mappedRoles[role],
  );
};

export const getUserAttributes = (accessToken: string) => {
  const payload = jwt.decode(accessToken);
  if (typeof payload !== 'string' && payload?.user && isUser(payload.user)) {
    const { roles } = payload.user;
    return {
      roles: roles ? mapUserRoles(roles) : [],
    };
  }
  return {};
};

export const getUserDetails = (user: any) => {
  const {
    id,
    email,
    first_name: firstName,
    last_name: lastName,
    birth_date: birthDate,
    phone_number: phoneNumber,
    salutation: gender,
    vb_number: vbNumber,
    zip_code: zipCode,
  } = user;
  return {
    email,
    id,
    firstName,
    lastName,
    birthDate,
    phoneNumber,
    gender,
    vbNumber,
    zipCode,
  };
};

export const getExpirationDate = (issuedAt: number, secondsToExpire: number) => {
  const expiresAt = new Date((issuedAt + secondsToExpire) * 1000);
  return expiresAt.toString();
};
