import _get from 'lodash/get';

import {
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FEE_RATE,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS,
  FINANCING_DETAILS_REPAYMENT_RATE,
} from 'modules/Inquiry/Form/formFields';

const mapFinancingDetailsFromApi = (response: unknown) => {
  const data = _get(response, 'data.attributes.details.financing_details');

  const financingValue = {
    [FINANCING_DETAILS_FEE_DURATION]: _get(data, 'fee_duration', '0,00'),
    [FINANCING_DETAILS_FEE_RATE]: _get(data, 'fee_rate', '0,00'),
    [FINANCING_DETAILS_FINANCING_DURATION]: _get(data, 'financing_duration', '0,00'),
    [FINANCING_DETAILS_INTEREST_RATE]: _get(data, 'interest_rate', '0,00'),
    [FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS]: _get(
      data,
      'notes_on_financing_parameters',
      '',
    ),
    [FINANCING_DETAILS_REPAYMENT_RATE]: _get(data, 'repayment_rate', '0,00'),
  };

  return financingValue;
};

export default mapFinancingDetailsFromApi;
