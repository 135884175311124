import styled from 'styled-components/macro';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledErrors = styled.p`
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 1rem 0 0;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.status.error};
`;

export const StyledUploaderWrapper = styled.div.attrs({ tabIndex: 0 })`
  position: relative;

  &:focus {
    outline: none;
    box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.primary};
  }
`;
