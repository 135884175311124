import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { INPUT_TYPE_NUMBER } from 'components/Input';
import { simpleValidation } from 'modules/Inquiry/Form/validateFields';
import { NUMBER_OF_LIFTS } from 'onlinefactoring/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateYourFinanceSection = simpleValidation([NUMBER_OF_LIFTS]);

export const YourFinanceSection = () => {
  const t = useTranslations('inquiryType.kfinanz.steps.purpose.sections.yourBusiness');

  return (
    <FormSection title={t('title')} sectionNumber={1}>
      <FormRow>
        <InputWithField
          name={NUMBER_OF_LIFTS}
          caption={t('fields.numberOfLifts.caption')}
          sideSymbol={() => <>{t('fields.numberOfLifts.sideSymbol')}</>}
          type={INPUT_TYPE_NUMBER}
        />
      </FormRow>
    </FormSection>
  );
};
