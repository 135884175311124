import { AUTH_API_CLIENT_NAME } from 'api';
import endpoints from 'api/AuthApi/endpoints';
import { REFRESH_TOKEN } from 'shared/auth/loginLogout.actionTypes';

export const refreshTokenAction = () => (dispatch, getState) => {
  const {
    user: { refreshToken: userRefreshToken, scope },
  } = getState();
  if (userRefreshToken && scope) {
    return dispatch({
      type: REFRESH_TOKEN,
      payload: {
        client: AUTH_API_CLIENT_NAME,
        request: {
          method: 'post',
          url: endpoints.LOG_IN.compose(),
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            refresh_token: userRefreshToken,
            grant_type: 'refresh_token',
            scope,
          },
        },
      },
    });
  }
  return Promise.resolve();
};
