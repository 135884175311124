import _get from 'lodash/get';

import {
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
  QUICK_CHECK_INFO_ON_COLLATERAL,
  QUICK_CHECK_INITIATOR,
  QUICK_CHECK_JUSTIFICATION_TEMPLATE,
  QUICK_CHECK_NEW_COLLATERAL,
  QUICK_CHECK_NEW_COMMITMENT,
  QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
  QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
  QUICK_CHECK_PROJECT_DESCRIPTION,
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
  QUICK_CHECK_SELECTING_PROFITABILITY_TYPE,
  QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
  QUICK_CHECK_TYPE_AND_GUARANTOR,
  QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
} from 'modules/Inquiry/Form/formFields';
import { FILTERS_USAGE_TYPES } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

interface IQuickCheckUsageType {
  future: boolean;
  current: boolean;
}

interface IQuickCheckRoles {
  building: boolean;
  unit: boolean;
  rent: boolean;
}

const mapQuickCheckFromApi = (data: Record<string, unknown>) => {
  const initialUsageType = {
    future: false,
    current: false,
  };
  const initialRoles = {
    building: false,
    unit: false,
    rent: false,
  };
  const usageType: IQuickCheckUsageType =
    (_get(data, 'project_calculation[0].qc_type_of_use') as IQuickCheckUsageType) ||
    initialUsageType;
  let selectedUsageType: string = '';
  if (usageType[FILTERS_USAGE_TYPES.FUTURE as keyof typeof usageType]) {
    selectedUsageType = FILTERS_USAGE_TYPES.FUTURE;
  } else if (usageType[FILTERS_USAGE_TYPES.CURRENT as keyof typeof usageType]) {
    selectedUsageType = FILTERS_USAGE_TYPES.CURRENT;
  }
  const quickCheckRolesFields =
    (_get(data, 'project_calculation[0].qc_role') as IQuickCheckRoles) || initialRoles;
  return {
    [QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS]: _get(
      data,
      'customer_information.previous_business_relationship_info',
    ),
    [QUICK_CHECK_INITIATOR]: _get(data, 'customer_information.initiator_info'),
    [QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE]: _get(
      data,
      'customer_information.project_financing_status_inhouse',
    ),
    [QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER]: _get(
      data,
      'customer_information.economic_circumstances',
    ),
    [QUICK_CHECK_PROJECT_DESCRIPTION]: _get(data, 'project_information.project_description'),
    [QUICK_CHECK_INFO_ON_COLLATERAL]: _get(data, 'project_information.collateral_info'),
    [QUICK_CHECK_WITHDRAWAL_REQUIREMENTS]: _get(
      data,
      'project_information.withdrawal_requirements',
    ),
    [QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION]: _get(data, 'other_information.other_notes_info'),
    [QUICK_CHECK_JUSTIFICATION_TEMPLATE]: _get(data, 'other_information.justification_template'),
    [QUICK_CHECK_TYPE_AND_GUARANTOR]: _get(data, 'loan_value.security_and_warrantor'),
    [QUICK_CHECK_AMOUNT_IN_EURO]: _get(data, 'loan_value.amount_in_euros'),
    [QUICK_CHECK_CURRENT_TOTAL_COMMITMENT]: _get(
      data,
      'unsecured_portion.current_total_commitment_in_euro',
    ),
    [QUICK_CHECK_NEW_COMMITMENT]: _get(data, 'unsecured_portion.new_commitment_in_euro'),
    [QUICK_CHECK_CURRENT_COLLATERAL_VALUE]: _get(
      data,
      'unsecured_portion.current_total_collateral_in_euro',
    ),
    [QUICK_CHECK_NEW_COLLATERAL]: _get(data, 'unsecured_portion.new_collateral_in_euro'),
    [QUICK_CHECK_SELECTING_PROFITABILITY_TYPE]: selectedUsageType,
    [QUICK_CHECK_ROLES_PROPERTY_DEVELOPER]: quickCheckRolesFields.building,
    [QUICK_CHECK_ROLES_PARTITION]: quickCheckRolesFields.unit,
    [QUICK_CHECK_ROLES_FILE_HALTER]: quickCheckRolesFields.rent,
  };
};

export default mapQuickCheckFromApi;
