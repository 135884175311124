import React from 'react';

import { HiddenInputWithField } from 'components/HiddenInput';
import StaticField from 'components/StaticField';
import { LOAN_TYPE } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';
import { required } from 'utils/validators';

const DvagLoanType = () => {
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanType');
  const tMain = useTranslations();
  return (
    <div>
      <StaticField caption={t('caption')} text={t('options.anniuity')} />
      <HiddenInputWithField name={LOAN_TYPE} validate={required(tMain('errors.required'))} />
    </div>
  );
};

export default DvagLoanType;
