import React, { useCallback, useEffect, useState } from 'react';

import { SimpleGrid, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { EDIT_INQUIRY_CALLER_COMPONENT_NAME } from 'store/applicationConfig/types';
import { getInquiryDetailsSelector, hasOnlyFutureUsage } from 'store/inquiryDetails/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  removeInnerPropertyFromSessionStorage,
  retrieveObject,
} from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG } from 'utils/sessionStorage/keys';

import MittweidaProfitabilityCalculation from '../../../../mittweida/portals/operations/pages/ProfitabilityCalculation';
import { FILTERS_USAGE_TYPES, PROFITABILITY_RECORD_TYPE } from '../SharedComponent/constants';
import {
  getMarketDataFilter,
  RESET_MARKETDATA_FILTER,
  setMarketDataFilter,
} from '../SharedComponent/filterManagementReducer';
import { fetchProfitabilityData } from '../SharedComponent/PlanningEvaluation.service';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';
import AreaInformation from './ProfitabilityCalculationComponents/Sections/AreaInformation';
import FinancingBoxes from './ProfitabilityCalculationComponents/Sections/FinancingBoxes';
import FinancingParametersTable from './ProfitabilityCalculationComponents/Sections/FinancingParameters';
import InvestmentCalculation from './ProfitabilityCalculationComponents/Sections/InvestmentCalculation';
import RentProfitability from './ProfitabilityCalculationComponents/Sections/Rent';
import SaleAsBuilding from './ProfitabilityCalculationComponents/Sections/SaleAsBuilding';
import SaleAsUnit from './ProfitabilityCalculationComponents/Sections/SaleAsUnit';

export const ProfitabilityCalculation = () => {
  const t = useTranslations('pages.planningEvaluation.profitabilityCalculation');
  const [payloadResponse, setPayloadResponse] = useState<any>();
  const disableCurrentUsage = useSelector(hasOnlyFutureUsage);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const filterData = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const { makeCall, isPending } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });
  const dispatch = useDispatch();
  const fetchValues = useCallback(async () => {
    const response = await makeCall(
      fetchProfitabilityData(inquiryId, filterData?.usageType, filterData?.saleType),
    );
    if (typeof response.payload?.data === 'object') {
      setPayloadResponse(response.payload.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryId, filterData?.usageType, filterData?.saleType]);

  useEffect(() => {
    if (disableCurrentUsage) {
      dispatch(
        setMarketDataFilter({
          usageType: FILTERS_USAGE_TYPES.FUTURE,
          saleType: filterData.saleType,
        }),
      );
    }
    fetchValues();
  }, [disableCurrentUsage, dispatch, fetchValues, filterData.saleType]);

  useEffect(() => {
    const applicationConfig = retrieveObject(APPLICATION_CONFIG);

    if (
      applicationConfig &&
      Object.hasOwnProperty.call(applicationConfig, EDIT_INQUIRY_CALLER_COMPONENT_NAME) &&
      applicationConfig[EDIT_INQUIRY_CALLER_COMPONENT_NAME] !==
        PROFITABILITY_RECORD_TYPE.AREA_INFORMATION
    ) {
      dispatch({ type: RESET_MARKETDATA_FILTER });
    } else if (
      applicationConfig &&
      Object.hasOwnProperty.call(applicationConfig, EDIT_INQUIRY_CALLER_COMPONENT_NAME)
    ) {
      removeInnerPropertyFromSessionStorage(APPLICATION_CONFIG, EDIT_INQUIRY_CALLER_COMPONENT_NAME);
    }
  }, [dispatch]);

  if (isPending && !payloadResponse) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }
  return (
    <>
      <ProjectCostingHeader
        heading={t('mainHeading')}
        subheading={inquiryDetails.company['name']}
      />
      <ProjectCostingFilters hideLabel disableCurrentUsage={disableCurrentUsage} />
      <FinancingBoxes boxes={payloadResponse?.top_tiles} />
      <SimpleGrid
        columns={{
          md: 1,
          lg: 2,
        }}
        gap={'3rem'}
        mb={'2rem'}
      >
        <FinancingParametersTable data={payloadResponse?.financing_paramter_data} />
        <AreaInformation data={payloadResponse?.area_information_data} />
      </SimpleGrid>
      <InvestmentCalculation data={payloadResponse?.investment_calculation_data} />
      <SaleAsBuilding data={payloadResponse && payloadResponse} />
      <SaleAsUnit data={payloadResponse && payloadResponse} boxes={payloadResponse?.bottom_tiles} />
      <RentProfitability data={payloadResponse?.sale_as_rent_data} />
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: ProfitabilityCalculation,
  [InquiryType.profiMittweida]: MittweidaProfitabilityCalculation,
});
