import React, { useMemo, useEffect } from 'react';

import { useForm } from 'react-final-form';

import { StaticFieldWithField } from 'components/StaticField';
import {
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  FINANCING_AMOUNT,
  PROJECT_FINANCING_OWN_WORK,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { formatPrice } from 'utils/valueFormats';

const useCalculateFinancingAmount = () => {
  const investmentAmount = useGetFieldNumericValue(PROJECT_FINANCING_INVESTMENT_AMOUNT);
  const ownFunds = useGetFieldNumericValue(PROJECT_FINANCING_OWN_FUNDS);
  const mezzanine = useGetFieldNumericValue(PROJECT_FINANCING_MEZZANINE);
  const subordinatedCapital = useGetFieldNumericValue(PROJECT_FINANCING_SUBORDINATED_CAPITAL);
  const personalContributions = useGetFieldNumericValue(PROJECT_FINANCING_OWN_WORK);
  return useMemo(
    () => investmentAmount - (ownFunds + mezzanine + subordinatedCapital + personalContributions),
    [investmentAmount, mezzanine, ownFunds, subordinatedCapital, personalContributions],
  );
};

const ProjectFinancingAmount: React.FC = () => {
  const financingAmount = useCalculateFinancingAmount();
  const { change } = useForm();
  const fieldController = FormFieldController.instance;

  useEffect(() => {
    change(FINANCING_AMOUNT, financingAmount);
    fieldController.saveValue(FINANCING_AMOUNT, financingAmount);
  }, [fieldController, change, financingAmount]);

  return (
    <StaticFieldWithField
      name={FINANCING_AMOUNT}
      parseValue={(value: number) => formatPrice(value, false)}
    />
  );
};

export default ProjectFinancingAmount;
