import React from 'react';

import Condition from 'components/Condition';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import useCountriesData from 'utils/hooks/useCountriesData/useCountriesData';

interface CountriesComponentProps {
  name: string;
  disabled?: boolean;
}
const CountriesComponent: React.FC<CountriesComponentProps> = (props: CountriesComponentProps) => {
  const countryArr = useCountriesData();

  return (
    <Condition
      condition={!props.disabled}
      fallback={<InputWithField name={props.name} disabled={true} />}
    >
      <SelectWithField
        options={countryArr}
        name={props.name}
        disabled={props.disabled}
        data-testid={'select-field-' + props.name}
      />
    </Condition>
  );
};

export default CountriesComponent;
