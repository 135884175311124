import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { isBankAdvisor } from 'store/user/selectors';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';

const withPortalInquiry = getEnvironmentFeatureFlag(EnvironmentVariable.PORTAL_INQUIRY);

const useShowButtonForInquiryType = () => {
  const { selectedInquiryType } = useFormConfig();
  const hasBankAdvisorRole = useSelector(isBankAdvisor);

  const inquiryTypes = [
    InquiryType.bfs,
    InquiryType.profiRLL,
    InquiryType.dvag,
    InquiryType.bigPicture,
    InquiryType.bfsService,
  ];

  return !inquiryTypes.includes(selectedInquiryType) && withPortalInquiry && hasBankAdvisorRole;
};

export default useShowButtonForInquiryType;
