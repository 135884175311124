import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

import Condition from 'components/Condition';
import PortalTable from 'components/PortalTable/PortalTable';
import { ITableHeader } from 'components/PortalTable/TableHeader.model';
import { FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface DetailsProps {
  tableHeaders: ITableHeader[];
  tableData: {
    [key: string]: string | boolean | undefined;
  }[];
  canEdit?: boolean;
  onEdit?(): void;
  title?: string;
  componentName: string;
  editLink?: string;
}

export const StyledHeading = styled.h3`
  margin-bottom: 1.75rem;
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.5;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

const HeaderText = ({ text }: { text: string }) => (
  <TextComponent color={'text.tertiary'} fontWeight={FONT_WEIGHT_REGULAR}>
    {text}
  </TextComponent>
);

export const withStyledHeaderCell = (props: ITableHeader, index: number) => ({
  ...props,
  renderHeader: (text: string) => {
    return (
      <Condition condition={index !== 0} fallback={<HeaderText text={text} />}>
        <Box textAlign="right" w="100%">
          <HeaderText text={text} />
        </Box>
      </Condition>
    );
  },
  renderRow: (text: string) => {
    const row = props.renderRow ? props.renderRow(text) : text;
    return (
      <Condition
        condition={index !== 0}
        fallback={
          <Box maxW={'fit-content'}>
            <TextComponent fontWeight={FONT_WEIGHT_MEDIUM}>{row}</TextComponent>
          </Box>
        }
      >
        <Box textAlign="right" w="100%">
          {row}
        </Box>
      </Condition>
    );
  },
});

const TableCardWithEdit: React.FC<DetailsProps> = ({
  tableData,
  tableHeaders,
  canEdit = false,
  componentName,
  onEdit = () => {},
  editLink,
  title,
}: DetailsProps) => {
  const t = useTranslations();

  return (
    <Flex direction={'column'} h={'100%'} justifyContent="space-between">
      <Box>
        <Condition condition={!!title}>
          <StyledHeading>{title}</StyledHeading>
        </Condition>
        <PortalTable
          onRowClick={() => {}}
          onSortChange={() => {}}
          sortBy="id"
          isLoading={false}
          isInitialized={true}
          headers={tableHeaders.map(withStyledHeaderCell)}
          tableData={tableData}
        ></PortalTable>
      </Box>
      <Condition condition={canEdit}>
        <EditStepWrapper>
          {editLink ? (
            <ButtonComponent
              leftIcon={<EditIcon boxSize={6} d="block" />}
              onClick={onEdit}
              as="a"
              href={editLink}
              variant="tertiary"
              data-testid={`edit_link_button_${componentName}`}
            >
              {t('buttons.edit')}
            </ButtonComponent>
          ) : (
            <ButtonComponent
              leftIcon={<EditIcon boxSize={6} d="block" />}
              onClick={() => onEdit()}
              variant="tertiary"
              data-testid={`edit_button_${componentName}`}
            >
              {t('buttons.edit')}
            </ButtonComponent>
          )}
        </EditStepWrapper>
      </Condition>
    </Flex>
  );
};

export default TableCardWithEdit;
