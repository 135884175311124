import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';

import { mittweidaConditions } from 'mittweida/inquiry/conditions';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';

export function shouldShowField(name: string, values: MittweidaFieldTypes, form: FormApi): boolean {
  const condition = mittweidaConditions[name];
  return condition ? condition({ values, form }) : true;
}

export function useCanShowField(name: string) {
  const { values } = useFormState<MittweidaFieldTypes>();
  const form = useForm();
  return shouldShowField(name, values, form);
}
