import React from 'react';

import Condition from 'components/Condition';
import { usePartnerConfig } from 'config/partner/hooks';
import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  USER_EXISTING_REPRESENTATIVE,
  USER_PROFILE_BIRTHDATE,
  USER_PROFILE_PHONE_NUMBER,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import SummaryField from 'modules/SummaryTable/SummaryField';
import { useSalutationTranslator } from 'shared/hooks/useSalutationTranslator';
import { getFormDataFromStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import { HeadingComponent } from 'theme/components/Heading';

import { PeopleDetailsMultiSelectOption } from '../PeopleDetails/MultiSelectPeopleDetails';
import { StyledSummaryRows } from './NewSummaryPage/styles';

const mapSelfUserToLegalRepresentative = (formValues: { [x: string]: any }) => ({
  salutation: formValues[COMPANY_DETAILS_GENDER],
  first_name: formValues[COMPANY_DETAILS_FIRST_NAME],
  last_name: formValues[COMPANY_DETAILS_LAST_NAME],
  email: formValues[COMPANY_DETAILS_EMAIL],
  birth_date: formValues[USER_PROFILE_BIRTHDATE],
  phone_number: formValues[USER_PROFILE_PHONE_NUMBER],
});

const LegalRepresentativesSummaryStep = ({ headerText }: { headerText?: string }) => {
  const {
    details: { id: partnerShortName },
  } = usePartnerConfig();
  const translateSalutation = useSalutationTranslator();
  const formValues = getFormDataFromStorage(partnerShortName);
  let legalRepresentatives = [];

  if (formValues[USER_REPRESENTATIVE]) {
    legalRepresentatives = formValues[USER_REPRESENTATIVE].filter((people: any) => !!people).map(
      (people: any) => ({
        salutation: people[REPRESENTATIVE_GENDER],
        first_name: people[REPRESENTATIVE_FIRST_NAME],
        last_name: people[REPRESENTATIVE_LAST_NAME],
        email: people[REPRESENTATIVE_EMAIL],
        birth_date: people[REPRESENTATIVE_BIRTHDATE],
        phone_number: people[REPRESENTATIVE_PHONE_NUMBER],
      }),
    );
  }

  let existingLegalRepresentatives: Array<PeopleDetailsMultiSelectOption> = [];
  if (formValues[USER_EXISTING_REPRESENTATIVE]) {
    existingLegalRepresentatives = formValues[USER_EXISTING_REPRESENTATIVE];
  }

  if (formValues[USER_REPRESENTATION] === true) {
    legalRepresentatives = [mapSelfUserToLegalRepresentative(formValues), ...legalRepresentatives];
  }

  return (
    <>
      <Condition condition={legalRepresentatives.length || existingLegalRepresentatives.length}>
        <Condition condition={!!headerText}>
          <HeadingComponent as="h4" mb={4} variant="tertiary">
            {headerText}
          </HeadingComponent>
        </Condition>
        {legalRepresentatives.map((person: any) => (
          <StyledSummaryRows>
            {[
              {
                name: REPRESENTATIVE_GENDER,
                value: translateSalutation(person.salutation),
              },
              {
                name: REPRESENTATIVE_FIRST_NAME,
                value: person.first_name,
              },
              {
                name: REPRESENTATIVE_LAST_NAME,
                value: person.last_name,
              },
              {
                name: REPRESENTATIVE_EMAIL,
                value: person.email,
              },
              {
                name: REPRESENTATIVE_PHONE_NUMBER,
                value: person.phone_number,
              },
              {
                name: REPRESENTATIVE_BIRTHDATE,
                value: person.birth_date,
              },
            ].map((field) => (
              <SummaryField fieldName={field.name} fieldValue={field.value} />
            ))}
          </StyledSummaryRows>
        ))}
        {existingLegalRepresentatives.map(
          (existingLegalRepresentative: PeopleDetailsMultiSelectOption) => {
            return (
              <StyledSummaryRows>
                <SummaryField
                  fieldName={REPRESENTATIVE_FIRST_NAME}
                  fieldValue={existingLegalRepresentative.firstName}
                />
                <SummaryField
                  fieldName={REPRESENTATIVE_LAST_NAME}
                  fieldValue={existingLegalRepresentative.lastName}
                />
              </StyledSummaryRows>
            );
          },
        )}
      </Condition>
    </>
  );
};

export default LegalRepresentativesSummaryStep;
