import styled from 'styled-components';

export const BfsServiceDatePickerContainer = styled.div`
  .react-datepicker {
    &__day--selected {
      /* Eslint will mark this as a violation against rule 'declaration-no-important' but the date picker needs the color to work properly in firefox */
      /* stylelint-disable */
      color: #292929 !important;
    }
    &__day--keyboard-selected,
    &__day--selected {
      background-color: transparent;
      &:hover {
        background-color: ${(props) => props.theme.colors.background.darkGrey};
      }
    }
  }
`;
