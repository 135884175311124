import React from 'react';

import { InquiryConfigContext } from '.';

export function useInquiryProcessConfig() {
  const context = React.useContext(InquiryConfigContext);
  if (context === undefined) {
    throw new Error('usePageStateContext must be used within a PageStateProvider');
  }
  return context;
}

export function useMode() {
  const { state } = useInquiryProcessConfig();
  return state.inquiryMode;
}

export function useFormValidations() {
  const {
    state: { inquiryMode },
    formStateData: { validations },
  } = useInquiryProcessConfig();

  return validations[inquiryMode];
}
