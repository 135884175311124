import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { useRecalculateProgressBarOnMount } from 'modules/FormPage';
import {
  COMPANY_DETAILS_COMPANY_KYC,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
} from 'modules/Inquiry/Form/formFields';
import { CompanySuggestion } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/CompanySuggestion.model';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

export const SelectKycPossibleCompanies = ({ companies }: { companies: CompanySuggestion[] }) => {
  useRecalculateProgressBarOnMount();

  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.resolveKycDataBank.companyToBeSearched',
  );
  const { required } = useFieldValidators();

  const companyOptions = companies.map((company) => ({ label: company.name, value: company }));
  const additionalOption = {
    label: t('additionalOption'),
    value: COMPANY_DETAILS_COMPANY__NOT_FOUND,
  };

  return (
    <SelectWithField
      name={COMPANY_DETAILS_COMPANY_KYC}
      caption={t('caption')}
      validate={required}
      options={[...companyOptions, additionalOption]}
      data-testid={'select-field-' + COMPANY_DETAILS_COMPANY_KYC}
    />
  );
};
