import React, { useCallback } from 'react';

import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import { InputWithField, INPUT_TYPE_DATE, INPUT_TYPE_DECICMAL_WITH_ZERO } from 'components/Input';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  IS_BENEFICIARY_OWNER,
  SELF_BENEFICIARY_OWNER,
} from 'modules/Inquiry/Form/formFields';
import {
  StyledSinglePerson,
  StyledSinglePersonFields,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useFormValues } from 'store/hooks/useFormValues';
import { inquiryDetailsCustomerIdSelector } from 'store/inquiryDetails/selectors';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';
import { formatDateDays } from 'utils/date';

import BeneficiaryTaxId from '../../fields/BeneficiaryTaxId';
import CountriesComponent from '../../fields/CountriesComponent/CountriesComponent';

export type FieldValue = {
  [BENEFICIARY_GENDER]: string;
  [BENEFICIARY_FIRST_NAME]: string;
  [BENEFICIARY_LAST_NAME]: string;
  [BENEFICIARY_COMPANY_SHARE]: string;
  [BENEFICIARY_TAX_ID]?: string;
  [BENEFICIARY_PRIVATE_ADDRESS]: string;
  [BENEFICIARY_ZIP_CODE]: string;
  [BENEFICIARY_PRIVATE_CITY]: string;
  [BENEFICIARY_PRIVATE_COUNTRY]: string;
  [BENEFICIARY_BIRTH_COUNTRY]: string;
  [BENEFICIARY_BIRTHDAY]: string;
  id: string;
  isAssociatedPerson: boolean;
};

type SinglePersonProps = {
  fieldName: string;
  shouldBeDisabled: boolean;
};

const SelfBeneficiaryOwnerFields = ({ fieldName, shouldBeDisabled }: SinglePersonProps) => {
  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        <FormRow>
          <InputWithField
            name={`${fieldName}.${BENEFICIARY_COMPANY_SHARE}`}
            type={INPUT_TYPE_DECICMAL_WITH_ZERO}
          />
        </FormRow>
        <BeneficiaryTaxId name={`${fieldName}.${BENEFICIARY_TAX_ID}`} />
        <FormRow>
          <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_ADDRESS}`} />
        </FormRow>
        <FormRow>
          <InputWithField
            name={`${fieldName}.${BENEFICIARY_ZIP_CODE}`}
            type="tel"
            pattern="[0-9]*"
            inputMaxLimit={MAX_ZIP_CODE_LENGTH}
            separateThousands={false}
          />
          <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_CITY}`} />
        </FormRow>
        <FormRow>
          <CountriesComponent name={`${fieldName}.${BENEFICIARY_PRIVATE_COUNTRY}`} />
        </FormRow>
        <FormRow>
          <CountriesComponent
            name={`${fieldName}.${BENEFICIARY_BIRTH_COUNTRY}`}
            disabled={shouldBeDisabled}
          />
          <InputWithField
            name={`${fieldName}.${BENEFICIARY_BIRTHDAY}`}
            sideSymbol={() => <CalendarIcon boxSize={6} />}
            disabled={shouldBeDisabled}
            type={INPUT_TYPE_DATE}
          />
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

interface Props {
  selfBeneficiary?: IAssociatedPerson;
  isLoading?: boolean;
}

const SelfBeneficiaryOwner = ({ selfBeneficiary, isLoading }: Props) => {
  const { fields } = useFieldArray<FieldValue>(SELF_BENEFICIARY_OWNER);
  const formValues = useFormValues();
  const isBeneficiaryOwner = formValues[IS_BENEFICIARY_OWNER] === 'true';
  const customerId = useSelector(inquiryDetailsCustomerIdSelector);

  const handleRemove = useCallback(
    (keyToRemove: string) => {
      const indexToRemove = fields.value.findIndex((person) => person.id === keyToRemove);
      if (indexToRemove > -1) {
        FormFieldController.instance.deleteValue(SELF_BENEFICIARY_OWNER, indexToRemove);
        fields.remove(indexToRemove);
      }
    },
    [fields],
  );

  React.useEffect(() => {
    if (isBeneficiaryOwner && fields.length === 0 && !isLoading) {
      const index = fields.length;

      let representative: any = {
        [BENEFICIARY_GENDER]: '',
        [BENEFICIARY_FIRST_NAME]: '',
        [BENEFICIARY_LAST_NAME]: '',
        [BENEFICIARY_COMPANY_SHARE]: '',
        [BENEFICIARY_TAX_ID]: '',
        [BENEFICIARY_PRIVATE_ADDRESS]: '',
        [BENEFICIARY_ZIP_CODE]: '',
        [BENEFICIARY_PRIVATE_CITY]: '',
        [BENEFICIARY_PRIVATE_COUNTRY]: '',
        [BENEFICIARY_BIRTH_COUNTRY]: '',
        [BENEFICIARY_BIRTHDAY]: '',
        id: customerId,
        isAssociatedPerson: false,
      };

      if (selfBeneficiary) {
        representative = {
          [BENEFICIARY_GENDER]: selfBeneficiary.salutation,
          [BENEFICIARY_FIRST_NAME]: selfBeneficiary.firstName,
          [BENEFICIARY_LAST_NAME]: selfBeneficiary.lastName,
          [BENEFICIARY_COMPANY_SHARE]: selfBeneficiary.companyShare,
          [BENEFICIARY_TAX_ID]: selfBeneficiary.taxId,
          [BENEFICIARY_PRIVATE_ADDRESS]: selfBeneficiary.address,
          [BENEFICIARY_ZIP_CODE]: selfBeneficiary.zipCode,
          [BENEFICIARY_PRIVATE_CITY]: selfBeneficiary.city,
          [BENEFICIARY_PRIVATE_COUNTRY]: selfBeneficiary.country,
          [BENEFICIARY_BIRTH_COUNTRY]: selfBeneficiary.birthPlace,
          [BENEFICIARY_BIRTHDAY]: formatDateDays(selfBeneficiary.birthDate),
          id: customerId,
          isAssociatedPerson: true,
        };
      }
      FormFieldController.instance.saveValue(`${SELF_BENEFICIARY_OWNER}[${index}]`, representative);
      fields.push(representative);
    }
  }, [customerId, fields, handleRemove, isBeneficiaryOwner, isLoading, selfBeneficiary]);

  return (
    <>
      {fields.map((fieldName) => {
        return (
          <SelfBeneficiaryOwnerFields fieldName={fieldName} shouldBeDisabled={!!selfBeneficiary} />
        );
      })}
    </>
  );
};

export default React.memo(SelfBeneficiaryOwner);
