import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import Condition from 'modules/ConditionalSubform/Condition';
import { not } from 'modules/Inquiry/Form/formConditions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';

import LoanDuration from '../LoanDuration/LoanDuration';
import LoanType from '../LoanType/LoanType';

const LoanSummary = () => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<LoanIcon />} />
      </FormRow>
      <FormRow>
        <LoanType />
        <LoanDuration />
      </FormRow>
    </>
  );
};

const DvagLoanSummary = () => {
  return (
    <>
      <Condition
        condition={not(fieldBusinessConditions.hasCalculatedAmountField)}
        isBusinessCondition
      >
        <LoanSummary />
      </Condition>
      <Condition condition={fieldBusinessConditions.hasCalculatedAmountField} isBusinessCondition>
        <FormRow>
          <LoanType />
          <LoanDuration />
        </FormRow>
      </Condition>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagLoanSummary,
  default: LoanSummary,
});
