import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import PERMISSIONS from 'constants/user/permissions';
import { USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR } from 'constants/user/userRoles';
import { CompeonStatus } from 'models/types/CompeonStatus.type';
import {
  PROPERTY_LOAN_KIND__CLASSIC,
  PRODUCT_KIND__PROPERTY_LOAN,
} from 'modules/Inquiry/Form/formFields';
import { INQUIRY_STATUSES, INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { rolesSelector } from 'store/user/selectors';
import { doArraysHaveCommonPart } from 'utils/helpers';

export const isCompeonBlocker = (inquiry: any, userRoles: any) => {
  const propertyLoan =
    _get(inquiry, 'inquiryDetailFields.product-kind') === PRODUCT_KIND__PROPERTY_LOAN;
  const loanType =
    _get(inquiry, 'inquiryDetailFields.property-loan-kind') === PROPERTY_LOAN_KIND__CLASSIC;
  const favoredDecision = _get(inquiry, 'inquiryDetailFields.favored-decision-date');
  const loanTermsInMonths = _get(inquiry, 'inquiryDetailFields.property-loan-term-in-months');
  const notForwardedToCustomer = inquiry.substatus !== INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER;
  const notForwardedCompeon = !inquiry.wasForwardToCompeon;

  const validStatuses = [
    INQUIRY_STATUSES.NEW,
    INQUIRY_STATUSES.ASSIGNED,
    INQUIRY_STATUSES.IN_PROGRESS,
  ];

  const compeonConditions = [
    // Wrong status
    () =>
      !(notForwardedCompeon && notForwardedToCustomer) || validStatuses.includes(inquiry.status),

    // Already forwarded to COMPEON
    () => notForwardedCompeon,

    // Already forwarded to customer
    () => notForwardedToCustomer,

    // Already assigned
    () =>
      userRoles.includes(USER_ROLE_BANK_ADVISOR) ||
      !userRoles.includes(USER_ROLE_INQUIRY_MANAGER) ||
      !inquiry.bankAdvisor,

    // Loan kind wrong
    () => (propertyLoan ? loanType : true),

    // Favored decision date is missing
    () => (propertyLoan ? favoredDecision : true),

    // Loan term is missing
    () => (propertyLoan ? loanTermsInMonths : true),

    // No permission
    () => doArraysHaveCommonPart(PERMISSIONS.INQUIRY.FORWARD_TO_CUSTOMER, userRoles),
  ];

  return !compeonConditions.every((condition) => condition());
};

const canArchive = (inquiry: any, userRoles: any) =>
  inquiry.status !== INQUIRY_STATUSES.ARCHIVED &&
  doArraysHaveCommonPart(PERMISSIONS.INQUIRY.ARCHIVE, userRoles);

const canShowAdditionalFinancingPartners = (inquiry: any) =>
  inquiry.substatus === INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER;

const canShowCompeonOffers = (inquiry: any) =>
  inquiry.status === INQUIRY_STATUSES.FORWARDED_TO_COMPEON || inquiry.wasForwardToCompeon;

const canMoveToNonFastlane = (inquiry: any) =>
  inquiry.compeonStatus === CompeonStatus.awaitingDecision && !inquiry.fastlaneCancelledDate;

const useInquiryPermissions = () => {
  const userRoles = useSelector(rolesSelector);
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  return {
    canForwardToCompeon: !isCompeonBlocker(inquiryDetails, userRoles),
    canArchive: canArchive(inquiryDetails, userRoles),
    canShowAdditionalFinancingPartners: canShowAdditionalFinancingPartners(inquiryDetails),
    canShowCompeonOffers: canShowCompeonOffers(inquiryDetails),
    canMoveToNonFastlane: canMoveToNonFastlane(inquiryDetails),
  };
};
export default useInquiryPermissions;
