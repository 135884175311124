import React from 'react';

import Contact from 'components/FixedSidebar/Contact';
import { StyledFixedOptionBar } from 'components/FixedSidebar/styles';
import RemoveInquiryDraftButton from 'modules/Inquiry/DraftMode/Sidebar/RemoveInquiryDraftButton';
import SaveDraftModeButton from 'modules/Inquiry/DraftMode/Sidebar/SaveInquiryDraftButton';

const FixedSidebar: React.FC = () => {
  return (
    <StyledFixedOptionBar>
      <Contact />
      <SaveDraftModeButton />
      <RemoveInquiryDraftButton />
    </StyledFixedOptionBar>
  );
};

export default FixedSidebar;
