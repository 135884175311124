import React, { ReactNode } from 'react';

import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import Title from 'components/FormSection/Title';
import Text from 'components/Text/Text';

interface IFormSectionLayout {
  sectionNumber?: number;
  disabled?: boolean;
  title: string;
  children: ReactNode;
  showSectionTitle?: boolean;
  hideBottomBorder?: boolean;
  hideTopBorder?: boolean;
  hideSectionNumber?: boolean;
}

const FormSectionLayout = ({
  sectionNumber,
  disabled,
  title,
  children,
  showSectionTitle,
  hideBottomBorder,
  hideTopBorder,
}: IFormSectionLayout) => {
  return (
    <Section hideBottomBorder={hideBottomBorder} hideTopBorder={hideTopBorder}>
      {title && (
        <Title data-aos="fade-right" data-aos-once>
          {showSectionTitle && (
            <Text as="span" inactive={disabled}>
              {!!sectionNumber && `${sectionNumber}. `}
              {title}
            </Text>
          )}
        </Title>
      )}

      <Content data-aos="fade-left" data-aos-once inactive={disabled}>
        {children}
      </Content>
    </Section>
  );
};

export default FormSectionLayout;
