import React from 'react';

import FormRow from 'components/FormRow';
import StaticField from 'components/StaticField';
import { IUserData } from 'models/UserData.model';
import { StyledFieldsWrapper, StyledFooter } from 'modules/UserData/styles';
import { useSalutationTranslator } from 'shared/hooks/useSalutationTranslator';
import { ButtonComponent } from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const StaticUserDataForm = ({
  onEdit,
  userData,
}: {
  onEdit: () => void;
  userData: Partial<IUserData>;
}) => {
  const t = useTranslations('pages.userData');
  const translateSalutation = useSalutationTranslator();

  return (
    <StyledFieldsWrapper>
      <FormRow>
        <StaticField
          caption={t('fields.salutation.caption')}
          text={translateSalutation(userData.gender ?? '')}
          dataTestId="userdata-salutation"
        />
      </FormRow>

      <FormRow>
        <StaticField
          caption={t('fields.firstName')}
          text={userData.firstName}
          dataTestId="userdata-first-name"
        />
        <StaticField
          caption={t('fields.lastName')}
          text={userData.lastName}
          dataTestId="userdata-last-name"
        />
      </FormRow>

      <FormRow>
        <StaticField
          caption={t('fields.email')}
          text={userData.email}
          dataTestId="userdata-email"
        />
        <StaticField
          caption={t('fields.phoneNumber')}
          text={userData.phoneNumber}
          dataTestId="userdata-phone-number"
        />
      </FormRow>
      <StyledFooter>
        <ButtonComponent
          data-testid="userdata-edit-btn"
          onClick={onEdit}
          type="submit"
          variant="tertiary"
          leftIcon={<EditIcon boxSize={6} d="block" />}
        >
          {t('edit')}
        </ButtonComponent>
      </StyledFooter>
    </StyledFieldsWrapper>
  );
};

export default StaticUserDataForm;
