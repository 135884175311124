import React from 'react';

import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { InputWithField } from 'components/Input';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import EmailNotAvailable from 'modules/AvailableEmail/EmailNotAvailable';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import {
  isEmailAvailibleSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { useFieldChangedValue } from 'store/hooks/useFormValues';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { buildValidator, combineValidators } from 'utils/validators';

type Props = {
  name: string;
};

const ContactPersonEmail = ({ name }: Props) => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail',
  );

  const { meta } = useField(name);
  const companyDetailsEmail = useFieldChangedValue<string>(name, '');
  const { required, isEmail } = useFieldValidators();

  const noAccountForEmail = useSelector(isEmailAvailibleSelector);
  const isLoadingEmailAvailability = useSelector(isLoadingEmailAvailabilitySelector);
  const emailValidator = buildValidator(() => noAccountForEmail, '');

  const shouldShowLoginLink =
    !isLoadingEmailAvailability &&
    !noAccountForEmail &&
    companyDetailsEmail &&
    isEmailRegex.test(companyDetailsEmail);

  const shouldShowEmailIcon = !!companyDetailsEmail && isEmailRegex.test(companyDetailsEmail);

  return (
    <div>
      <InputWithField
        name={name}
        validate={combineValidators(required, isEmail, emailValidator)}
        type="email"
        caption={t('caption')}
        sideSymbol={() =>
          shouldShowEmailIcon && (
            <AvailableEmailSymbol
              isLoading={isLoadingEmailAvailability}
              isAllowedToInquiry={meta.valid}
            />
          )
        }
      />
      {shouldShowLoginLink && <EmailNotAvailable email={companyDetailsEmail} />}
    </div>
  );
};

export default ContactPersonEmail;
