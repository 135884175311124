import { useSelector, useDispatch } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { IUserData } from 'models/UserData.model';
import { useToasts } from 'shared/hooks/useToasts';
import { updateUserDataAction } from 'store/user/actions';
import { isAgent } from 'store/user/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const saveOperationUserData = (apiValues: any) =>
  callReverseApi({
    url: endpoints.OP_USERS.LIST.compose(),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: apiValues,
      },
    },
  });

const saveCustomerData = (apiValues: any) =>
  callReverseApi({
    url: endpoints.CUSTOMERS.LIST.compose(),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: apiValues,
      },
    },
  });

const mapUserDataToApi = (userData: IUserData): any => ({
  salutation: userData.gender,
  first_name: userData.firstName,
  last_name: userData.lastName,
  phone_number: userData.phoneNumber,
});

const mapApiToUserData = (apiData: any): IUserData => ({
  email: apiData.email,
  firstName: apiData.first_name,
  lastName: apiData.last_name,
  gender: apiData.salutation,
  phoneNumber: apiData.phone_number,
});

export const useUpdateUserDataCall = () => {
  const callApiAction = useSelector(isAgent) ? saveOperationUserData : saveCustomerData;
  const { makeCall } = useDispatchApiCall();

  return (formValues: IUserData, callback: Function) =>
    makeCall(callApiAction(mapUserDataToApi(formValues)), callback);
};

export const useUpdateUserData = () => {
  const { success } = useToasts();
  const dispatch = useDispatch();
  const t = useTranslations('pages.userData');

  const callUpdateApi = useUpdateUserDataCall();
  return (formValues: IUserData) =>
    callUpdateApi(formValues, ({ payload }: any) => {
      success({ description: t('profileUpdated') });
      dispatch(updateUserDataAction(mapApiToUserData(payload.data.data.attributes ?? {})));
    });
};
