import React from 'react';

import { PARTNERS } from 'constants/partner';
import paths from 'constants/paths';
import NavigationLink from 'modules/Header/NavigationLink';
import PortalLogoutBtn from 'modules/Header/PortalLogoutBtn';
import NewInquiryButton from 'modules/Header/PortalNavigation/NewInquiryButton';
import {
  StyledNavElement as Element,
  StyledNavList as List,
} from 'modules/Header/PortalNavigation/styles';
import { CONTRACT_DETAILS, CUSTOMER_DATA } from 'modules/Inquiry/Form/formFields';
import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useTranslations } from 'utils/hooks/useTranslations';
import { removeObject } from 'utils/sessionStorage/helpers';

const CustomerNavigation = () => {
  const t = useTranslations('components.header');
  const enableNewInquiryButton = useConfig(CONFIG.IS_NEW_INQUIRY_BUTTON_ENABLED);

  return (
    <List>
      {enableNewInquiryButton && (
        <Element>
          <NewInquiryButton />
        </Element>
      )}
      <Element>
        <NavigationLink url={paths.customer.inquiryList} label={t('inquiries')} />
      </Element>
      <Element>
        <NavigationLink url={paths.customer.userData} label={t('userProfile')} />
      </Element>
      <Element>
        <PortalLogoutBtn />
      </Element>
    </List>
  );
};

const BfsServiceCustomerNavigation = () => {
  const t = useTranslations('components.header');

  return (
    <List>
      <Element>
        <NavigationLink url={paths.customer.inquiryList} label={t('inquiries')} />
      </Element>
      <Element>
        <NavigationLink url={paths.customer.userData} label={t('userProfile')} />
      </Element>
      <Element>
        <PortalLogoutBtn />
      </Element>
    </List>
  );
};

const AgriculturalCustomerNavigation = () => {
  const t = useTranslations('components.header');
  return (
    <List>
      <Element>
        <NavigationLink url={paths.customer.inquiryList} label={t('inquiries')} />
      </Element>
      <Element>
        <NavigationLink url={paths.customer.userData} label={t('userProfile')} />
      </Element>
      <Element>
        <PortalLogoutBtn />
      </Element>
    </List>
  );
};

const LeasePlanCustomerNavigation = () => {
  const t = useTranslations('components.header');

  return (
    <List>
      <Element>
        <NavigationLink
          url={() => {
            removeObject(CONTRACT_DETAILS);
            removeObject(CUSTOMER_DATA);
            return paths.customer.inquiryList;
          }}
          label={t('inquiries')}
        />
      </Element>
      <Element>
        <NavigationLink url={paths.customer.userData} label={t('userProfile')} />
      </Element>
      <Element>
        <PortalLogoutBtn />
      </Element>
    </List>
  );
};
export default choosePartnerSpecificComponent({
  default: CustomerNavigation,
  [PARTNERS.RENTENBANK]: AgriculturalCustomerNavigation,
  [PARTNERS.AGRICULTURAL]: AgriculturalCustomerNavigation,
  [PARTNERS.LEASEPLAN]: LeasePlanCustomerNavigation,
  [PARTNERS.BFSS]: BfsServiceCustomerNavigation,
});
