import { useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useInquiryValueTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryValueTranslator';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import {
  useGetUnitsForField,
  useUnitsMap,
} from 'modules/Inquiry/inquiryFieldsUnits/useGetUnitsForField';
import { INQUIRY_SECTION_KEYS } from 'modules/InquiryDetails/InquirySections.model';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { formatDateDays, formatMonthWithYear } from 'utils/date';
import { filterObjectKeys } from 'utils/helpers';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  formatPrice,
  formatPriceAsThousands,
  replaceDotWithComma,
  formatKilometer,
  formatPercentage,
} from 'utils/valueFormats';

import customValueTranslationMap from './customTranslations';

const getAllInquiryDetailsFields = (inquiryDetails) =>
  INQUIRY_SECTION_KEYS.reduce(
    (fields, sectionKey) => ({ ...fields, ...inquiryDetails[sectionKey] }),
    {},
  );

const getFields = (fieldsToGet, inquiryDetails) => {
  if (!fieldsToGet.length) {
    return [];
  }
  const allFields = getAllInquiryDetailsFields(inquiryDetails);
  return fieldsToGet.reduce(
    (result, fieldKey) => ({ ...result, [fieldKey]: allFields[fieldKey] }),
    {},
  );
};

const formatters = {
  [FIELD_UNIT_TYPE.CURRENCY]: formatPrice,
  [FIELD_UNIT_TYPE.THOUSANDS_CURRENCY]: formatPriceAsThousands,
  [FIELD_UNIT_TYPE.DATE]: formatDateDays,
  [FIELD_UNIT_TYPE.PERCENTAGE]: replaceDotWithComma,
  [FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL]: formatPercentage,
  [FIELD_UNIT_TYPE.MONTH_AND_YEAR]: formatMonthWithYear,
  [FIELD_UNIT_TYPE.CURRENCY_PER_MONTH]: formatPrice,
  [FIELD_UNIT_TYPE.KILOMETERS]: formatKilometer,
};

const isCurrency = {
  [FIELD_UNIT_TYPE.CURRENCY_PER_MONTH]: false,
};

const useFormatValueBasedOnUnit = () => {
  const unitsMap = useUnitsMap();

  return useCallback(
    (fieldValue, fieldKey) => {
      const fieldUnit = unitsMap[fieldKey];
      return formatters[fieldUnit]
        ? formatters[fieldUnit](fieldValue, isCurrency[fieldUnit])
        : fieldValue;
    },
    [unitsMap],
  );
};

export const useInquiryValueParserForDisplay = () => {
  const translateValue = useInquiryValueTranslator();
  const appendUnitsToValue = useGetUnitsForField();
  const formatValue = useFormatValueBasedOnUnit();
  const t = useTranslations();

  return useCallback(
    (fieldValue, fieldKey) => {
      const customTranslator = customValueTranslationMap[fieldKey];
      const translated = customTranslator
        ? customTranslator(fieldValue, t)
        : translateValue(fieldValue, fieldKey);
      const formatted = formatValue(translated, fieldKey);
      return appendUnitsToValue(formatted, fieldKey);
    },
    [appendUnitsToValue, formatValue, translateValue, t],
  );
};

const useInquiryFieldsForSection = ({
  sectionId: sectionName,
  excludedFields = [],
  includeFields = [],
}) => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  const fieldsFromSection = inquiryDetails[sectionName] || [];

  const includedFields = getFields(includeFields, inquiryDetails);
  const notExcludedFields = filterObjectKeys(excludedFields, fieldsFromSection);

  return useMemo(
    () => ({ ...includedFields, ...notExcludedFields }),
    [includedFields, notExcludedFields],
  );
};

const useInquiryDetailsFields = ({ sectionId, excludedFields = [], includeFields = [] }) => {
  const fieldsToShow = useInquiryFieldsForSection({ sectionId, excludedFields, includeFields });

  const labelTranslator = useInquiryLabelTranslator();
  const formatValue = useInquiryValueParserForDisplay();

  return useMemo(
    () =>
      Object.entries(fieldsToShow)
        .filter(([, fieldValue]) => fieldValue != null)
        .map(([fieldKey, fieldValue]) => ({
          label: labelTranslator(fieldKey),
          value: formatValue(fieldValue, fieldKey),
          key: fieldKey,
        })),
    [fieldsToShow, formatValue, labelTranslator],
  );
};

export default useInquiryDetailsFields;
