import React from 'react';

import _isNil from 'lodash/isNil';
import PropTypes from 'prop-types';

import InputRadio from 'components/PortalFormFields/YesNoField/InputRadio';
import { StyledBooleanFields as Fields } from 'components/PortalFormFields/YesNoField/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

const YesNoField = ({ value, name, onChange }) => {
  const t = useTranslations();

  const handleChange = (ev) => onChange(String(ev.target.value));

  const valueAsString = !_isNil(value) && value.toString();

  return (
    <Fields>
      <InputRadio
        isChecked={valueAsString === 'true'}
        label={t('other.yes')}
        onChange={handleChange}
        name={name}
        id={`${name}_yes`}
        value="true"
      />
      <InputRadio
        isChecked={valueAsString === 'false'}
        label={t('other.no')}
        onChange={handleChange}
        name={name}
        id={`${name}_no`}
        value="false"
      />
    </Fields>
  );
};

YesNoField.defaultProps = {
  value: null,
  onChange: () => null,
};

YesNoField.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default YesNoField;
