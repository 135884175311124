import React from 'react';

import { NavLink, Link, useParams } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledCompanyNav, StyledCompanyHeadline } from './styles';

const CompanyNavigation: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const t = useTranslations();
  return (
    <StyledCompanyNav aria-labelledby={ELEMENT_IDS.COMPANY_NAVIGATION}>
      <Container>
        <StyledCompanyHeadline id={ELEMENT_IDS.COMPANY_NAVIGATION}>
          Unternehmensnavigation
        </StyledCompanyHeadline>
        <ul>
          <li>
            <NavLink activeClassName="active" to={paths.operation.companies.overview(companyId)}>
              {t('pages.companiesDetails.overview.title')}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to={paths.operation.companies.associatedPersons(companyId)}
            >
              {t('pages.companiesDetails.associatedPerson.title')}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to={paths.operation.companies.riskAnalysis(companyId)}
            >
              {t('pages.companiesDetails.riskAnalysis.title')}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to={paths.operation.companies.financialInformation(companyId)}
            >
              {t('pages.companiesDetails.financialInformation.title')}
            </NavLink>
          </li>
        </ul>
        <Link to={paths.operation.companies.root}>{t('pages.companiesDetails.close')}</Link>
      </Container>
    </StyledCompanyNav>
  );
};

export default CompanyNavigation;
