import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { USER_REPRESENTATION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const LeasePlanUserRepresentation = () => {
  const t = useTranslations(
    'pages.customerData.sections.legalRepresentation.fields.isUserRepresentationRadio',
  );

  return <YesNoRadioGroupWithField name={USER_REPRESENTATION} caption={t('caption')} />;
};

export default LeasePlanUserRepresentation;
