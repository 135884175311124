import React, { useCallback, useEffect, useState } from 'react';

import { Box, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import { DEFAULT_CURRENCY, FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { PROJECT_FINANCING_MARKET_TRIGGER_CONDITION } from 'modules/Inquiry/Form/formFields';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { AppDispatch } from 'store';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { TextComponent } from 'theme/components/Text';
import { formatDateWithTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimalWithOutRoundOff, formatPrice } from 'utils/valueFormats';

import MittweidaMarketComparison from '../../../../mittweida/portals/operations/pages/MarketComparison';
import { InquiryType } from '../../../../modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import { textStyle } from '../../../../theme/themeConstants';
import mapMarketDetailsToEdit from '../EditMarketValuesReeProfitability/mapMarketDetailsToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import {
  getMarketDataFilter,
  RESET_MARKETDATA_FILTER,
} from '../SharedComponent/filterManagementReducer';
import { LocationAnalysisTiles } from '../SharedComponent/LocationAnalysisTiles';
import { fetchMarketData } from '../SharedComponent/PlanningEvaluation.service';
import RealStateMarketDetails from '../SharedComponent/RealStateMarketDetails';
import { RealStateNoDataComponent } from '../SharedComponent/RealStateNoDataComponent';
import {
  IMarketDataApiRes,
  IObjEvalTable,
  IObjEvalTableHeader,
  marketDataZeroObj,
  tableRowKeys,
} from './types';

const ProfiMarketComparison = () => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const tUnit = useTranslations();
  const { makeCall, isPending } = useDispatchApiCall({
    showErrorNotification: true,
    isPendingInitially: true,
  });
  const [marketDataObj, setMarketDataObj] = useState<IMarketDataApiRes>();
  const [objEvalData, setObjEvalData] = useState<IObjEvalTable[]>();
  const filterData = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const [shouldShowError, setShouldShowError] = useState<boolean>(true);
  const isMarketDataEnabled = useConfig(CONFIG.IS_MARKET_DATA_ENABLED);
  const isProfitabilityCalculationEnabled = useConfig(CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED);
  const isRealEstateExpertEnabled = useConfig(CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED);
  const { profitabilityCalculation, appraiser, editMarketValueDetails } =
    paths.operation.inquiryDetails.planningEvaluation;
  const { dashboard } = paths.operation.inquiryDetails;
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: RESET_MARKETDATA_FILTER });
  }, [dispatch]);

  const objEvaluationheaders: IObjEvalTableHeader[] = [
    {
      key: 'id',
      text: '',
      renderRow: (data: string) => (
        <TextComponent {...textStyle.sizeM} fontWeight={FONT_WEIGHT_BOLD}>
          {data}
        </TextComponent>
      ),
    },
    {
      key: 'unit',
      text: t('marketObjEvaluation.headers.unit'),
    },
    {
      key: 'marketPrice',
      text: t('marketObjEvaluation.headers.marketPrice'),
      renderRow: (data: number) => (
        <TextComponent color={'brand.default'} {...textStyle.sizeM} fontWeight={FONT_WEIGHT_MEDIUM}>
          {data}
        </TextComponent>
      ),
      headerColor: 'default',
    },
    {
      key: 'marketPriceMin',
      text: t('marketObjEvaluation.headers.marketPriceMin'),
    },
    {
      key: 'marketPriceMax',
      text: t('marketObjEvaluation.headers.marketPriceMax'),
    },
  ];

  const orderOfTableData = [
    'market_sales_price',
    'residential_rent',
    'existing_residential_rent',
    'commercial_rent',
    'sales_duration',
  ];

  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const displayMarketData =
    inquiryDetails.financingPurposeFields?.[PROJECT_FINANCING_MARKET_TRIGGER_CONDITION]
      ?.displayMarketData;
  const typesDefaults0 =
    inquiryDetails.financingPurposeFields?.[PROJECT_FINANCING_MARKET_TRIGGER_CONDITION]
      ?.typesDefaults0;

  const fetchFormTemplate = useCallback(async () => {
    const response = await makeCall(fetchMarketData(inquiryId));
    const unitCurrencySize = `${DEFAULT_CURRENCY}/${tUnit('other.m2')}`;
    if (typeof response.payload.data === 'object') {
      const tableData = response.payload.data.property_evaluation;
      const dataArray: IObjEvalTable[] = orderOfTableData.map((key: string) => {
        return {
          id: t(`marketObjEvaluation.rows.${key}`),
          unit: key === 'sales_duration' ? 'Tage' : unitCurrencySize,
          marketPrice:
            key === 'sales_duration'
              ? formatDecimalWithOutRoundOff(tableData[key].actual)
              : formatPrice(formatDecimalWithOutRoundOff(tableData[key].actual), false) ?? '0,00',
          marketPriceMin:
            key === 'sales_duration'
              ? formatDecimalWithOutRoundOff(tableData[key].min)
              : formatPrice(formatDecimalWithOutRoundOff(tableData[key].min), false) ?? '0,00',
          marketPriceMax:
            key === 'sales_duration'
              ? formatDecimalWithOutRoundOff(tableData[key].max)
              : formatPrice(formatDecimalWithOutRoundOff(tableData[key].max), false) ?? '0,00',
        };
      });
      setObjEvalData(dataArray);
      setMarketDataObj(response.payload.data);
      setShouldShowError(false);
    } else {
      if (displayMarketData) {
        setShouldShowError(false);
        if (typesDefaults0.includes(filterData.usageType)) {
          setMarketDataObj(marketDataZeroObj);
          const dataArray: IObjEvalTable[] = tableRowKeys.map((key: string) => {
            return {
              id: t(`marketObjEvaluation.rows.${key}`),
              unit: key === 'sales_duration' ? 'Tage' : unitCurrencySize,
              marketPrice: '0',
              marketPriceMin: '0',
              marketPriceMax: '0',
            };
          });
          setObjEvalData(dataArray);
        }
      } else {
        setShouldShowError(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryId, filterData.usageType, filterData.saleType]);

  useEffect(() => {
    fetchFormTemplate();
  }, [fetchFormTemplate, inquiryId, filterData.usageType, filterData.saleType]);

  if (isPending && !marketDataObj) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }

  if (!isMarketDataEnabled)
    if (isProfitabilityCalculationEnabled)
      return <Redirect to={profitabilityCalculation(inquiryId)} />;
    else if (isRealEstateExpertEnabled) return <Redirect to={appraiser(inquiryId)} />;
    else return <Redirect to={dashboard.replace(':id', inquiryId)} />;

  return (
    <Box pb="20">
      {shouldShowError ? (
        <RealStateNoDataComponent />
      ) : (
        <>
          <RealStateMarketDetails
            heading={t('title')}
            subheading={t('subheading')}
            subheadingDate={formatDateWithTime(marketDataObj?.last_calculated_at)}
            recordType={PROFI_RECORD_TYPE.VALUE_API}
            editFormStructure={mapMarketDetailsToEdit()}
            clientDetailBoxes={{
              saleRevenue: marketDataObj?.potential_sales_revenue || 0,
              rentalPerYear: marketDataObj?.possible_rental_income || 0,
              livingSpace: marketDataObj?.living_space || 0,
              commercialSpace: marketDataObj?.commercial_space || 0,
            }}
            tableData={objEvalData}
            tableHeaders={objEvaluationheaders}
            pathToEdit={editMarketValueDetails(inquiryId)}
          />
          <LocationAnalysisTiles marketDataObj={marketDataObj} />
        </>
      )}
    </Box>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: ProfiMarketComparison,
  [InquiryType.profiMittweida]: MittweidaMarketComparison,
});
