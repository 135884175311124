import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  atLeastOne,
  fieldHasAnyValue,
  fieldHasValue,
  validateSectionFields,
} from 'modules/Inquiry/Form/formConditions';
import {
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATION,
  USER_SHARED_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import UserRepresentation from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/UserRepresentation';
import UserSharedRepresentation from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/UserSharedRepresentation';
import LegalRepresentatives from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseLaneSpecificComponent } from 'shared/chooseLaneSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateLegalRepresentationSection = (
  associatedPersons: Array<IAssociatedPerson>,
  form: any,
) => {
  const commonFields = [USER_REPRESENTATION, USER_SHARED_REPRESENTATION];
  if (fieldHasAnyValue(USER_REPRESENTATION, ['true', true])({ form })) {
    if (associatedPersons.length > 0) {
      return validateSectionFields([...commonFields, USER_EXISTING_REPRESENTATIVE]);
    }
    return validateSectionFields(commonFields);
  }
  if (fieldHasValue(USER_REPRESENTATION, 'false')) {
    return validateSectionFields([USER_REPRESENTATION]);
  }
};

interface Props {
  sectionNumber?: number;
}

const LegalRepresentationSection = ({ sectionNumber }: Props) => {
  const t = useTranslations();

  return (
    <FormSection
      name={FormSections.legalRepresentation}
      title={t('pages.peopleDetails.sections.legalRepresentation.title')}
      sectionNumber={sectionNumber ?? 2}
    >
      <FormRow>
        <UserRepresentation />
      </FormRow>

      <Condition condition={fieldHasAnyValue(USER_REPRESENTATION, ['true', true])}>
        <UserSharedRepresentation />
      </Condition>

      <Condition
        condition={atLeastOne(
          fieldHasValue(USER_SHARED_REPRESENTATION, 'false'),
          fieldHasValue(USER_REPRESENTATION, 'false'),
        )}
      >
        <LegalRepresentatives />
      </Condition>
    </FormSection>
  );
};

const InquiryTypeSpecificLegalRepresentation = chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: LegalRepresentationSection,
  [InquiryType.bfsService]: LegalRepresentationSection,
  default: () => null,
});

export default chooseLaneSpecificComponent({
  [InquiryLane.contract]: LegalRepresentationSection,
  [InquiryLane.offer]: LegalRepresentationSection,
  default: InquiryTypeSpecificLegalRepresentation,
});
