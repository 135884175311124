import React from 'react';

import PropTypes from 'prop-types';

import { StyledBooleanInput as InputWrapper } from 'components/PortalFormFields/YesNoField/styles';

const InputRadio = ({ name, isChecked, onChange, label, id, value }) => {
  return (
    <InputWrapper>
      <input
        id={id}
        type="radio"
        name={name}
        checked={isChecked}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={id}>{label}</label>
    </InputWrapper>
  );
};

InputRadio.defaultProps = {
  isChecked: false,
  onChange: () => null,
  value: '',
};

InputRadio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default InputRadio;
