const Drawer = {
  parts: ['content', 'header'],
  baseStyle: {
    content: {
      boxShadow: 'boxShadow.default',
      backgroundColor: 'background.white',
    },
    header: {
      paddingTop: '3rem',
    },
  },
};

export default Drawer;
