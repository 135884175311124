import { useEffect } from 'react';

import _merge from 'lodash/merge';
import { useDispatch, useSelector } from 'react-redux';

import { getInquiryManagers } from 'api/CompeonReverseApi/operation/loadInquiryManagers/actions';
import { getLoggedInInquiryManager } from 'api/CompeonReverseApi/operation/userData/actions';
import { PARTNERS } from 'constants/partner';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { setInquiriesListInquiryManagersAction } from 'store/inquiryList/actions';
import { getInquiryListOptionsSelector } from 'store/inquiryList/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const useLoggedInInquiryManagerFilter = () => {
  const { makeCall } = useDispatchApiCall({ isPendingInitially: true });
  const tableOptions = useSelector(getInquiryListOptionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const inquiryManagers = await getInquiryManagers(makeCall);
      dispatch(setInquiriesListInquiryManagersAction(inquiryManagers));

      const { id: loggedInInquiryManager, hasAssignedZipCodes } = await getLoggedInInquiryManager(
        makeCall,
      );

      const loggedInUserIsInInquiryManagerList = inquiryManagers.some(
        ({ id = '' }) => id === loggedInInquiryManager,
      );
      if (loggedInUserIsInInquiryManagerList && hasAssignedZipCodes) {
        if (tableOptions && !tableOptions.touched) {
          dispatch(
            getInquiriesAction(
              _merge(tableOptions, {
                filterBy: { inquiryManagerId: loggedInInquiryManager },
              }),
            ),
          );
        }
      }
    })();
  }, [dispatch, makeCall]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default () =>
  useSelectedInquiryTypeSpecificValue({
    [PARTNERS.DVAG]: useLoggedInInquiryManagerFilter,
    default: () => null,
  });
