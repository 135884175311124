import { FONT_WEIGHT_MEDIUM, PRIMARY_FONT } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const Heading = {
  baseStyle: {
    fontFamily: PRIMARY_FONT,
    fontWeight: FONT_WEIGHT_MEDIUM,
  },
  variants: {
    primary: {
      ...textStyle.sizeXxl,
      color: 'text.primary',
    },
    secondary: {
      ...textStyle.sizeL,
      color: 'text.secondary',
    },
    tertiary: {
      ...textStyle.sizeM,
      color: 'text.tertiary',
    },
    hidden: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1px',
      height: '1px',
      border: '0',
      padding: '0px',
      whiteSpace: 'nowrap',
      clipPath: 'inset(50%)',
    },
  },
};

export default Heading;
