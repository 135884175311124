import env from '@beam-australia/react-env';
import moment from 'moment';

import { retrieveObject, saveObjectToSessionStorage, clearSession } from './helpers';

export const USER_EXPIRATION_TIMESTAMP = 'USER_EXPIRATION_TIMESTAMP';
const DEFAULT_TIMEOUT_IN_MINUTES = 60;

export const isDataExpired = () => {
  const timestamp = retrieveObject(USER_EXPIRATION_TIMESTAMP);
  if (!timestamp) {
    return true;
  }
  const wrappedTimestamp = moment(timestamp);

  return moment().isAfter(wrappedTimestamp);
};

export const clearExpirationTimestamp = () => sessionStorage.removeItem(USER_EXPIRATION_TIMESTAMP);

export const setStorageExpiration = () => {
  const envOverride = env('OVERRIDE_PERSISTENCE_TIMEOUT');
  const timeout = envOverride || DEFAULT_TIMEOUT_IN_MINUTES;
  const newTimestamp = moment().add(timeout, 'minutes');
  saveObjectToSessionStorage(USER_EXPIRATION_TIMESTAMP, newTimestamp);
};

export const handleDataExpiration = () => {
  if (isDataExpired()) {
    clearSession(); // it might be not enough because progressbar in inquiry form does asynchronous actions with setting new state
    setStorageExpiration();
    window.location.reload(); // To Fetch formConfigs and other set other states in sessionStorage
    return true;
  }
  clearExpirationTimestamp();
  setStorageExpiration();
  return false;
};
