import React from 'react';

import { useMultiStyleConfig } from '@chakra-ui/react';

import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledPageHeading, StyledRightSection, StyledSearch } from './styles';

interface PageHeadingProps {
  onSearchChange: (value: string) => void;
}

const PageHeading = ({ onSearchChange }: PageHeadingProps) => {
  const t = useTranslations('pages.inquiryList');
  const styles = useMultiStyleConfig('CustomerInquiryListHeading', {});

  return (
    <StyledRightSection>
      <StyledPageHeading as="h2">{t('pageTitle')}</StyledPageHeading>
      {styles.search?.display === 'none' ? null : <StyledSearch onChange={onSearchChange} />}
    </StyledRightSection>
  );
};

export default PageHeading;
