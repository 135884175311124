import * as React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import paths from 'constants/paths';
import {
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_DATA_SECURITY,
} from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

type Props = {
  sectionNumber: number;
};

export const BigPictureTermsAndConditions = ({ sectionNumber }: Props) => {
  const { required, mustBeTrue } = useFieldValidators();
  const t = useTranslations('pages.companyDetails.sections.termsAndConditions.bigPicture');

  return (
    <FormSection sectionNumber={sectionNumber} title={t('title')}>
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_CONSENT}
          validate={combineValidators(required, mustBeTrue)}
          text={
            <span>
              {t('fields.companyDetailsConsent.label', {
                termsAndConditions: (
                  <a href={paths.termsAndConditions}>
                    {t('fields.companyDetailsConsent.links.termsAndConditions')}
                  </a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_DATA_SECURITY}
          validate={combineValidators(required, mustBeTrue)}
          text={
            <span>
              {t('fields.companyDetailsDataSecurity.label', {
                dataSecurity: (
                  <a href={paths.privacyPolicy}>
                    {t('fields.companyDetailsDataSecurity.links.dataSecurity')}
                  </a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
    </FormSection>
  );
};
