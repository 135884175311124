import { createSelector } from 'reselect';

import { IState } from 'store';

export const getInquiryListSelector = (state: IState) => state.inquiriesList.inquiries;
export const getInquiryListOptionsSelector = (state: IState) => state.inquiriesList.options;
export const getInquiryListIsInitializedSelector = (state: IState) =>
  state.inquiriesList.isInitialized;
export const getInquiryListIsLoadingSelector = (state: IState) => state.inquiriesList.isLoading;
export const getInquiryListStatuses = (state: IState) => state.inquiriesList.statuses;
export const getInquiryListManagers = (state: IState) => state.inquiriesList.inquiryManagers;

export const getInquiryListPageSize = createSelector(
  getInquiryListOptionsSelector,
  (options) => options.pageSize,
);
export const getInquiryListPageNum = createSelector(
  getInquiryListOptionsSelector,
  (options) => options.currentPage,
);
export const getInquiryListTotalPages = createSelector(
  getInquiryListOptionsSelector,
  (options) => options.pageCount,
);
export const getInquiryListSortBy = createSelector(
  getInquiryListOptionsSelector,
  (options) => options.sortBy,
);
export const getInquiryListSortDirection = createSelector(
  getInquiryListOptionsSelector,
  (options) => options.sortDirection,
);
