const ProgressSection = {
  parts: ['container', 'notLast', 'after'],
  baseStyle: {
    container: {
      position: 'relative',
      py: 12,
      pr: 8,
      pl: '96px',
    },
    notLast: {
      borderBottom: '1px solid',
      borderBottomColor: 'border.lightGrey',
    },
    after: {
      content: "''",
      position: 'absolute',
      bottom: '0',
      right: '0',
      left: '96px',
      height: '1px',
      backgroundColor: 'background.darkGrey',
    },
  },
};

export default ProgressSection;
