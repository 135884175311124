import {
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__LEASING,
} from 'modules/Inquiry/Form/formFields';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { AssetType } from 'shared/hooks/useAssets';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';

export enum STEPS {
  CHECK = 'check',
  SIGHTS = 'sights',
  DECIDE = 'decide',
  CLOSE = 'close',
  CLOSE_SIMPLE = 'closeSimple',
  CONTACT = 'contact',
}

export const iconMap: { [key in STEPS]: { type: AssetType; key: string } } = {
  [STEPS.CHECK]: {
    type: 'dashboard',
    key: ProgressSectionType.ADDITIONAL_FIELDS,
  },
  [STEPS.SIGHTS]: {
    type: 'inquiry',
    key: InquiryAssetKeys.NEXT_STEPS,
  },
  [STEPS.DECIDE]: {
    type: 'dashboard',
    key: ProgressSectionType.SUCCESS,
  },
  [STEPS.CLOSE]: {
    type: 'inquiry',
    key: InquiryAssetKeys.OFFER,
  },
  [STEPS.CLOSE_SIMPLE]: {
    type: 'inquiry',
    key: InquiryAssetKeys.OFFER,
  },
  [STEPS.CONTACT]: {
    type: 'inquiry',
    key: InquiryAssetKeys.NEXT_STEPS,
  },
};

export const conditionalStepList = (fastlaneState: boolean, product: string): STEPS[] => {
  const productList = [PRODUCT_KIND__HIRE_PURCHASE, PRODUCT_KIND__LEASING];

  if (fastlaneState && productList.includes(product)) {
    return [STEPS.CHECK, STEPS.SIGHTS, STEPS.DECIDE, STEPS.CLOSE];
  }
  if (fastlaneState && !productList.includes(product)) {
    return [STEPS.CHECK, STEPS.SIGHTS, STEPS.DECIDE, STEPS.CLOSE_SIMPLE];
  }
  if (!fastlaneState) {
    return [STEPS.CONTACT];
  }

  return [];
};
