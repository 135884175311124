import { Dispatch } from 'redux';

import { IUserData } from 'models/UserData.model';
import { TOKEN_SET } from 'shared/auth/loginLogout.actionTypes';
import { AuthScope } from 'shared/auth/types';

import { LOG_OUT, RESET_USER, SET_EMAIL, SET_BIRTHDATE } from './actionTypes';

export const logOut = () => (dispatch: Dispatch) =>
  dispatch({
    type: LOG_OUT,
  });

export const setEmail = (email: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_EMAIL,
    payload: {
      email,
    },
  });

export const setBirthdate = (birthdate: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_BIRTHDATE,
    payload: {
      birthdate,
    },
  });

export const resetUser = () => (dispatch: Dispatch) =>
  dispatch({
    type: RESET_USER,
  });

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const updateUserDataAction = (userData: IUserData) => (dispatch: Dispatch) =>
  dispatch({
    type: UPDATE_USER_DATA,
    payload: userData,
  });

export const setTokenAction =
  (tokenData: { token: string; refreshToken: string; expiresAt: string; scope: AuthScope }) =>
  (dispatch: Dispatch) =>
    dispatch({
      type: TOKEN_SET,
      payload: tokenData,
    });
