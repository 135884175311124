import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { LEASING_TAKEOVER } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const LeasingTakeover = () => {
  const options = useInquirySelectOptions(LEASING_TAKEOVER);
  return (
    <SelectWithField
      name={LEASING_TAKEOVER}
      options={options}
      data-testid={'select-field-' + LEASING_TAKEOVER}
    />
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => null,
  default: LeasingTakeover,
});
