import React, { useState, useMemo, useCallback } from 'react';

import { Field } from 'react-final-form';

import FormRow from 'components/FormRow';
import FieldGroup from 'components/PortalFormFields/FieldGroup';
import Autocomplete from 'components/PortalFormFields/SelectField/Autocomplete/Autocomplete';
import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import { mapCompaniesToSelectOptions } from 'pages/inquiryFlow/CompanyDetails/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyDetailsExistingUserCompany = ({
  companies: rawCompanies,
  isFetching,
  tooltip = false,
}: {
  companies: any[];
  isFetching?: boolean;
  tooltip?: any;
}) => {
  const t = useTranslations(
    'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany',
  );

  const [inputValue, setInputValue] = useState('');
  const companies = mapCompaniesToSelectOptions(rawCompanies);

  const additionalOption = {
    label: t('additionalOption.labelOnSelected'),
    value: COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  };

  const companiesToOptions = companies
    .filter(({ value }) => !!value.name)
    .map(({ value, label }) => ({
      label: value.name,
      component: label,
      value,
    }));

  const filterOptionsByInput = useCallback(
    (option) => {
      if (inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      }
      return true;
    },
    [inputValue],
  );

  const { required } = useFieldValidators();

  const autocompleteOptions = useMemo(
    () => companiesToOptions.filter(filterOptionsByInput),
    [companiesToOptions, filterOptionsByInput],
  );

  return (
    <FormRow>
      <Field
        name={COMPANY_DETAILS_EXISTING_USER_COMPANY}
        validate={required}
        tooltip={tooltip}
        render={({ input, meta }) => (
          <FieldGroup label={t('caption')} error={meta.visited && meta.error ? meta.error : null}>
            <div data-testid={COMPANY_DETAILS_EXISTING_USER_COMPANY}>
              <Autocomplete
                name={input.name}
                onFocus={input.onFocus}
                onChange={setInputValue}
                placeholder={t('placeholder', { roleSpecific: true })}
                options={autocompleteOptions}
                onSelect={input.onChange}
                value={input.value}
                menuShowThreshold={0}
                inputInitialValue={input.value && input.value.name}
                loading={isFetching}
                fixedOptions={[additionalOption]}
              />
            </div>
          </FieldGroup>
        )}
      />
    </FormRow>
  );
};

export default CompanyDetailsExistingUserCompany;
