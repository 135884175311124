import React, { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { filterXSS } from 'xss';

import { isBankAdvisor } from 'store/user/selectors';

const LINK_REGEXP = /\[(blank:)?(.+)](.+)/;
export const useTranslations = (prefix, options = { tranformLinks: true }) => {
  const { formatMessage } = useIntl();
  const prefixWithDot = prefix ? `${prefix}.` : '';

  // switch translation based on user role, use `roleSpecific` in usage
  // use simply condition because of this currently only 2 cases
  const bankAdvisor = useSelector(isBankAdvisor);
  const roleSuffix = bankAdvisor ? '.bankAdvisor' : '.customer';

  /*
   * Find any <link> tags and allow to transform them to url's
   * format: <link>[(blank:)URL]Text</link>
   * This way we can add links through BE translations
   */
  const linkTransformation = useMemo(
    () => ({
      link: (args) => {
        const [, blank, link, text] = args.match(LINK_REGEXP);
        const blankProp = blank ? { target: '_blank', rel: 'noopener noreferrer' } : {};
        return (
          <a key="link" href={filterXSS(link)} {...blankProp}>
            {filterXSS(text)}
          </a>
        );
      },
    }),
    [],
  );

  const simpleLinkTransformation = useMemo(
    () => ({
      link: (args) => {
        const [, link, text] = args.match(LINK_REGEXP);
        return `${filterXSS(text)}(${link})`;
      },
    }),
    [],
  );

  const transformStrong = useMemo(
    () => ({
      strong: (args) => {
        return <strong>{filterXSS(args)}</strong>;
      },
    }),
    [],
  );

  const transformLinks = options.tranformLinks ? linkTransformation : simpleLinkTransformation;
  return useCallback(
    /**
     * Avoid on production to show id for custom translation if transfered to `roleSpecific`
     * return roleSpecific message if not exist try to return message without roleSuffix
     * i.e.: try `my.message.customer` if not exist, than `my.message`
     * @param values: `{ roleSpecific: true }` for roleSpecific translations with `.customer`/`.bankAdvisor` suffix
     */
    (msgId, values = {}) => {
      if (!msgId) {
        return msgId;
      }
      const getMessageFor = (id) =>
        formatMessage({ id }, { ...transformLinks, ...transformStrong, ...values });

      const roleId = `${prefixWithDot}${values.roleSpecific ? msgId.concat(roleSuffix) : msgId}`;
      const id = `${prefixWithDot}${msgId}`;

      // try get roleSpecific message if roleSpecific flag
      const roleMessage = values.roleSpecific ? getMessageFor(roleId) : roleId;

      // fallback to message without suffix for roleSpecific if not exists
      return roleMessage === roleId ? getMessageFor(id) : roleMessage;
    },
    [formatMessage, prefixWithDot, transformLinks, transformStrong, roleSuffix],
  );
};
