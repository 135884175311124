import { useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useFeatureFlags } from 'config/featureFlags/hooks';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS, PartnersName } from 'constants/partner';
import {
  USER_PROFILE_FOUNDING_MONTH,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  LEASING_RESIDUAL_VALUE,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  OVERDRAFT_END_AT,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
  PROJECT_FINANCING_ANNUAL_RENT_AMOUNT,
  REAL_ESTATE_CONSTRUCTION_DATE,
  EXCLUDED_BANKS,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  STRUCTURE_NUMBER,
  ADP_NUMBER,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_TYPE__OTHER,
  BUILDING_COOWNERSHIP,
  BUILDING_TYPE__CONDOMINIUM,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TYPE__APARTMENT,
  BUILDING_LIVING_SPACE,
  BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_PROJECT__PURCHASE,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_PROJECT__NEW,
  BUILDING_PROJECT__RECONSTRUCTION,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_DELIVERY_DATE,
  VEHICLE_YEAR,
  VEHICLE_OTHER_DESCRIPTION,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_TAXATION,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_PROPERTY_DOCUMENT,
  PROJECT_FINANCING_ADDITIONAL_DETAILS,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  PROJECT_FINANCING_OBJECT_FUTURE_USAGE_FORM,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_OWN_WORK,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  CORONA_FINANCING_AMOUNT,
  CONSIDER_SUBSIDIES,
  PARTNER_SPECIFIC_IBAN,
  CONTRACTOR_COMPANY_INFORMATION,
  USER_PROFILE_COMPANY_RATING_KIND,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
  VEHICLE_DESCRIPTION,
  MACHINE_CATEGORY,
  MACHINE_PERIOD_OF_USE,
  MACHINE_MANUFACTURER,
  MACHINE_KIND,
  MACHINE_NEW,
  MACHINE_IS_ORDERED,
  VEHICLE_NEW,
  VEHICLE_KIND,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  PROJECT_FINANCING_FILE_HALTER_PARTITION_FIELDS,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  PRE_FINANCING_PERIOD,
  BFS_SERVICE__SOURCE,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
} from 'modules/Inquiry/Form/formFields';
import { PAYMENT_TERM } from 'onlinefactoring/formFields';
import { fieldBusinessConditions as conditions } from 'schema/inquirySchema.conditions';
import { schemaHelpers as helpers } from 'schema/inquirySchema.helpers';
import { BusinessDataPopulators, BusinessConditionMap } from 'schema/inquirySchema.models';
import { useFormValues } from 'store/hooks/useFormValues';
import { rolesSelector } from 'store/user/selectors';
import { extractNestedFieldName } from 'utils/form/getFieldName';

export const businessConditionMap: BusinessConditionMap = {
  [CONSIDER_SUBSIDIES]: conditions.shouldShowSubsidies,
  [CORONA_FINANCING_AMOUNT]: conditions.hasSelectedQuickCredit,
  [USER_PROFILE_FOUNDING_MONTH]: conditions.isPassYearsThreshold,
  [HIRE_PURCHASE_RESIDUAL_VALUE]: conditions.isHirePurchasePartialAmortisation,
  [INVESTMENT_LOAN_RESIDUAL_VALUE]: conditions.isInvestmentLoanPartialAmortisation,
  [LEASING_RESIDUAL_VALUE]: conditions.isLeasingPartialAmortisation,
  [LOAN_FIXED_INTEREST_RATE]: conditions.isNotAnnuityLoan,
  [LOAN_INTEREST_PERIOD_IN_MONTHS]: conditions.shouldInvolveLoanInterestPeriod,
  [OVERDRAFT_END_AT]: conditions.isOverdraftTerminability,
  [USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: conditions.shouldConsiderSubsidies,
  [USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]: conditions.shouldConsiderSubsidies,
  [USER_PROFILE_INNOVATIVE_COMPANY]: conditions.shouldConsiderSubsidies,
  [USER_PROFILE_ENERGY_EFFICIENT]: conditions.shouldConsiderSubsidies,
  [PROJECT_FINANCING_ANNUAL_RENT_AMOUNT]: conditions.knowsAnnualRent,
  [REAL_ESTATE_CONSTRUCTION_DATE]: conditions.isRealEstateBuilding,
  [OVERDRAFT_END_AT]: conditions.overdraftHasTerminability,
  [LEASING_RESIDUAL_VALUE]: conditions.leasingHasPartialAmortisation,
  [INVESTMENT_LOAN_RESIDUAL_VALUE]: conditions.investmentLoanHasPartialAmortisation,
  [HIRE_PURCHASE_RESIDUAL_VALUE]: conditions.hirePurchaseHasPartialAmortisation,
  [EXCLUDED_BANKS]: conditions.canShowExcludedBanks,
  [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]:
    conditions.canShowUserProfileAdditionalAssociationCompany,
  [VEHICLE_INTERESTED_IN_INSURANCE]: conditions.hasSelectedVehiclePurposeKind,
  [MACHINE_INTERESTED_IN_INSURANCE]: conditions.canShowIntrestedInInsurance,
  [STRUCTURE_NUMBER]: false,
  [ADP_NUMBER]: false,
  [BUILDING_TYPE_DESCRIPTION]: conditions.hasSelectedBuildingType(BUILDING_TYPE__OTHER),
  [BUILDING_COOWNERSHIP]: conditions.hasSelectedBuildingType(BUILDING_TYPE__CONDOMINIUM),
  [BUILDING_CONDOMINIUM_NUM]: conditions.hasSelectedBuildingType(BUILDING_TYPE__CONDOMINIUM),
  [BUILDING_NUMBER_OF_RESIDENTIAL_UNITS]:
    conditions.hasSelectedBuildingType(BUILDING_TYPE__APARTMENT),
  [BUILDING_LIVING_SPACE]: helpers.oneOfConditions(
    conditions.hasSelectedBuildingType(BUILDING_TYPE__OTHER),
    conditions.hasSelectedBuildingType(BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL),
  ),
  [BUILDING_COMMERCIAL_SPACE]: helpers.oneOfConditions(
    conditions.hasSelectedBuildingType(BUILDING_TYPE__OTHER),
    conditions.hasSelectedBuildingType(BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL),
  ),
  [BUILDING_SHARE_OF_COMMERCIAL_SPACE]: conditions.hasSelectedBuildingType(
    BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  ),
  [BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL]: helpers.combineConditions(
    conditions.isBuildingWithThirdParty,
    conditions.isBuildingCommercialRentableType,
  ),
  [BUILDING_RENTED_SPACE_COMMERCIAL]: helpers.combineConditions(
    conditions.isBuildingWithThirdParty,
    conditions.isBuildingCommercialRentableType,
  ),
  [BUILDING_COLD_RENT_PER_YEAR_LIVING]: helpers.combineConditions(
    conditions.isBuildingWithThirdParty,
    conditions.isBuildingLivingRentableType,
  ),
  [BUILDING_RENTED_SPACE_LIVING]: helpers.combineConditions(
    conditions.isBuildingWithThirdParty,
    conditions.isBuildingLivingRentableType,
  ),
  [BUILDING_PURCHASE_PRICE]: conditions.buildingReconstructionOrPurchase,
  [BUILDING_PLOT_PRICE]: conditions.buildingReconstructionOrPurchase,
  [BUILDING_ADDITIONAL_COSTS]: conditions.hasSelectedBuildingProject([BUILDING_PROJECT__PURCHASE]),
  [BUILDING_CONSTRUCTION_MANUFACTURE_COST]: conditions.hasSelectedBuildingProject([
    BUILDING_PROJECT__NEW,
    BUILDING_PROJECT__RECONSTRUCTION,
  ]),
  [BUILDING_MODERNISATION_COSTS]: conditions.buildingReconstructionOrPurchase,
  [BUILDING_PERSONAL_CONTRIBUTION]: conditions.hasSelectedBuildingProject([
    BUILDING_PROJECT__PURCHASE,
    BUILDING_PROJECT__RECONSTRUCTION,
    BUILDING_PROJECT__NEW,
  ]),
  [BUILDING_PROPERTY]: conditions.hasSelectedBuildingProject([BUILDING_PROJECT__NEW]),
  [MACHINE_COUNT]: conditions.isBFSForm,
  [MACHINE_CATEGORY]: conditions.isNotBFSForm,
  [MACHINE_PERIOD_OF_USE]: conditions.isNotBFSForm,
  [MACHINE_MANUFACTURER]: conditions.isNotBFSForm,
  [MACHINE_KIND]: conditions.isNotBFSForm,
  [MACHINE_NEW]: conditions.isNotBFSForm,
  [MACHINE_IS_ORDERED]: conditions.isNotBFSForm,
  [MACHINE_DESCRIPTION]: conditions.isBFSForm,
  [MACHINE_AGE]: conditions.machineIsNotNew,
  [MACHINE_ORIGINAL_PRICE]: conditions.machineIsNotNew,
  [MACHINE_DELIVERY_DATE]: conditions.machineIsOrdered,
  [VEHICLE_DESCRIPTION]: conditions.isBFSForm,
  [VEHICLE_NEW]: conditions.isNotBFSForm,
  [VEHICLE_KIND]: conditions.isNotBFSForm,
  [VEHICLE_MANUFACTURER]: conditions.isNotBFSForm,
  [VEHICLE_MODEL]: conditions.isNotBFSForm,
  [VEHICLE_YEARLY_MILEAGE]: conditions.isNotBFSForm,
  [VEHICLE_YEAR]: conditions.vehicleIsNotNew,
  [VEHICLE_OTHER_DESCRIPTION]: conditions.hasSelectedOtherVehicle,
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION]: conditions.hasHeritableBuildingRights,
  [REAL_ESTATE_RENTED_OUT_AREA]: conditions.isRentable,
  [REAL_ESTATE_COLD_RENT]: conditions.isRentable,
  [REAL_ESTATE_TAXATION]: conditions.isRentable,
  [PROJECT_FINANCING_LIST_OF_RENTERS]: conditions.isPartitionOrFileHalter,
  [PROJECT_FINANCING_ANCHOR_TENANT]: helpers.combineConditions(
    conditions.isPartitionOrFileHalter,
    conditions.hasListOfRenters,
  ),
  [PROJECT_FINANCING_RENTAL_CONTRACTS]: conditions.isPartitionOrFileHalter,
  [PROJECT_FINANCING_RENOVATION_PLANNED]: conditions.isPartitionOrFileHalter,
  [PROJECT_FINANCING_RENOVATION_DESCRIPTION]: helpers.combineConditions(
    conditions.isPartitionOrFileHalter,
    conditions.isPlanningRenovation,
  ),
  [COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS]: conditions.companyReceivedGeneralSubsidies,
  [COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS]:
    conditions.companyReceivedAgriculturalSubsidies,
  [COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS]: conditions.companyReceivedFishingSubsidies,
  [COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS]: conditions.companyReceivedDawiSubsidies,
  [USER_PROFILE_LEGAL_FORM_DESCRIPTION]: conditions.hasSelectedOtherLegalForm,
  [PARTNER_SPECIFIC_IBAN]: conditions.isAlreadyCompanyCustomer,
  [CONTRACTOR_COMPANY_INFORMATION]: conditions.isContractor,
  [USER_PROFILE_COMPANY_RATING_KIND]: helpers.combineConditions(
    conditions.isAlreadyCompanyCustomer,
    conditions.isBankAdvisor,
  ),
  [PROJECT_FINANCING_PROPERTY_DOCUMENT]: conditions.isProFiForm,
  [PROJECT_FINANCING_ADDITIONAL_DETAILS]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__BUILDING_YEAR]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__MODERNIZATION_YEAR]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__QUALITY_CONDITION]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__FURNISHING_QUALITY]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__GUEST_BATHROOM]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__BALKONY]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__LIFT]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__CELLAR]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__NUMBER_OF_GARAGES]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__PARKING_LOTS_RENT_NOW]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__GARAGES_RENT_NOW]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING__GARAGES_RENT_FUTURE]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE]: conditions.isNotLandBuyerInProFiForm,
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]: conditions.isDeviatingFromFutureUsage,
  [PROJECT_FINANCING__OBJECT_USAGE]: conditions.isDeviatingFromFutureUsage,
  [PROJECT_FINANCING_OBJECT_FUTURE_USAGE_FORM]: conditions.isDeviatingFromFutureUsage,
  [PROJECT_FINANCING_FILE_HALTER_PARTITION_FIELDS]: conditions.isPartitionOrFileHalter,
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: conditions.isNotProFiForm,
  [PROJECT_FINANCING_OWN_WORK]: conditions.isProFiForm,
  [BFS_SERVICE__COMPANY_OTHER_INDUSTRY]: conditions.isBFSIndustryOther,
  [BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER]: conditions.isFactoringFormFinance,
  [PRE_FINANCING_PERIOD]: conditions.isBFSFactomatAndNotPatientTransport,
  [BFS_SERVICE__SOURCE]: conditions.isBFSFactoringOffer,
  [BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING]: conditions.isBFSIndustrySoftwareOther,
  [BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON]: helpers.oneOfConditions(
    conditions.isNotBFSIndustryOther,
    conditions.isBFSFactomat,
  ),
  [PAYMENT_TERM]: conditions.isNotBFSFactoringOfferOrOther,
  [PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE]: conditions.isNotLandBuyerInProFiForm,
};

const BuildingSocietyConditions: BusinessConditionMap = {
  [STRUCTURE_NUMBER]: conditions.canShowStructureNumber,
  [ADP_NUMBER]: conditions.canShowADPNumber,
};
export const shouldShowField = (
  fieldName: string,
  data: BusinessDataPopulators,
  conditionsMap: BusinessConditionMap = businessConditionMap,
): boolean => {
  const condition = conditionsMap[fieldName];
  if (typeof condition === 'boolean') {
    return condition;
  }
  if (typeof condition === 'function') {
    return condition(data);
  }
  return true;
};

export const useFieldConditionsForPartner = () => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const partnersMap: {
    [key in PartnersName]?: BusinessConditionMap;
  } & {
    default: BusinessConditionMap;
  } = {
    [PARTNERS.BUILDING_SOCIETY]: { ...businessConditionMap, ...BuildingSocietyConditions },
    default: businessConditionMap,
  };

  return (partnerName && partnersMap[partnerName as PartnersName]) || partnersMap.default;
};

export const useCheckCanShowField = () => {
  const featureFlags = useFeatureFlags();
  const formValues = useFormValues();
  const { selectedInquiryType } = useFormConfig();
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const userRoles = useSelector(rolesSelector);

  const conditionsForPartner = useFieldConditionsForPartner();

  return useCallback(
    (fieldName: string) =>
      shouldShowField(
        fieldName,
        {
          formValues,
          config: featureFlags,
          formType: selectedInquiryType,
          partnerId,
          userRoles,
        },
        conditionsForPartner,
      ),
    [conditionsForPartner, featureFlags, selectedInquiryType, formValues, partnerId, userRoles],
  );
};

export const useCanShowField = (name: string) => {
  const fieldName = extractNestedFieldName(name);
  const canShowField = useCheckCanShowField();
  return useMemo(() => canShowField(fieldName), [canShowField, fieldName]);
};
