import React, { useCallback } from 'react';

import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  LOAN_TERM,
  REPRESENTATIVE_BIRTHDATE,
  USER_PROFILE_BIRTHDATE,
} from 'modules/Inquiry/Form/formFields';
import { coronaStepUnits } from 'modules/Inquiry/inquiryFieldsUnits/coronaStep.units';
import { financingNeedUnits } from 'modules/Inquiry/inquiryFieldsUnits/financingNeeds.units';
import {
  UNIT_TYPE_SUFFIX,
  FIELD_UNIT_TYPE,
} from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { leaseplanUnits } from 'modules/Inquiry/inquiryFieldsUnits/leaseplan.units';
import { requestDetailsUnits } from 'modules/Inquiry/inquiryFieldsUnits/requestDetails.units';
import { onlinefactoringUnits } from 'onlinefactoring/units';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { InquiryType } from '../Inquiry.type';
import { bankValuesUnits } from './bankValues.units';
import { bfsServiceUnits } from './bfsService.units';
import { mittweidaProfitabilityUnits } from './mittweidaProfitability.units';
import { profiUnits } from './profi.units';

// Function for getting possible additional data like currency/units to show next to values
// See more at CRV-861
export const unitsToFieldMap = {
  ...financingNeedUnits,
  ...requestDetailsUnits,
  ...coronaStepUnits,
  ...bankValuesUnits,
  ...mittweidaProfitabilityUnits,
};

// because formatPrice add currency by himself (in formatPriceValues function)
const EXCLUDED_UNIT_TYPES = [FIELD_UNIT_TYPE.CURRENCY, FIELD_UNIT_TYPE.THOUSANDS_CURRENCY];

export const useUnitsMap = () => {
  const commonUnits = {
    [USER_PROFILE_BIRTHDATE]: FIELD_UNIT_TYPE.DATEINPUT,
    [REPRESENTATIVE_BIRTHDATE]: FIELD_UNIT_TYPE.DATEINPUT,
    [BENEFICIARY_BIRTHDAY]: FIELD_UNIT_TYPE.DATEINPUT,
    [BENEFICIARY_COMPANY_SHARE]: FIELD_UNIT_TYPE.PERCENTAGE,
  };

  const inquiryTypeSpecificUnits = useSelectedInquiryTypeSpecificValue({
    [InquiryType.onlinefactoring]: onlinefactoringUnits,
    [InquiryType.leaseplan]: leaseplanUnits,
    [InquiryType.profiRLL]: profiUnits,
    [InquiryType.dvag]: {
      [LOAN_TERM]: null,
    },
    [InquiryType.bfsService]: bfsServiceUnits,
    default: {},
  });

  return { ...unitsToFieldMap, ...commonUnits, ...inquiryTypeSpecificUnits };
};

export const useGetUnitsForField = () => {
  const translator = useTranslations();
  const fullUnitsMap = useUnitsMap();

  return useCallback(
    (fieldValue, fieldKey) => {
      const unitName = fullUnitsMap[fieldKey];

      if (!unitName || EXCLUDED_UNIT_TYPES.includes(unitName)) {
        return fieldValue;
      }

      const unitValue = UNIT_TYPE_SUFFIX[unitName];

      return (
        <>
          {fieldValue} {unitValue(translator)}
        </>
      );
    },
    [fullUnitsMap, translator],
  );
};
