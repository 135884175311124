import styled from 'styled-components/macro';

import Text from 'components/Text/Text';
import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';

export const marginBottom = '1.25rem';

const InputCaption = styled(Text)`
  margin-bottom: ${marginBottom};
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_LIGHT};
  text-transform: ${(props) => props.fontCase};
  color: ${(props) => props.theme.colors.text.tertiary};
`;

export default InputCaption;
