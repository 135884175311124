import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const requestCallback = ({
  firstName,
  lastName,
  phoneNumber,
  zipCode,
  message,
  partnerName,
}) =>
  callReverseApi({
    url: endpoints.CUSTOMERS.CALL_REQUEST.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          'first-name': firstName,
          'last-name': lastName,
          'phone-number': phoneNumber,
          'zip-code': zipCode,
          message,
          'compeon-partner': partnerName,
        },
      },
    },
  });
