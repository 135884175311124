import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  BFS_SERVICE__NEED_FURTHER_ADVICE,
  BFS_SERVICE__FACTORING_OFFER,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';

const offerSectionValidation = (form: any): boolean => {
  return fieldIsValid(USER_PROFILE_PHONE_NUMBER)({ form });
};

const additionalNeedSectionValidation = (form: any): boolean => {
  return fieldIsValid(BFS_SERVICE__NEED_FURTHER_ADVICE)({ form });
};

const hintSectionValidation = (form: any): boolean => {
  return fieldIsValid(BFS_SERVICE__FACTORING_OFFER)({ form });
};

export const useRequestDetailsContractValidators = () => () =>
  [offerSectionValidation, additionalNeedSectionValidation, hintSectionValidation];

export const useRequestDetailsLeadValidators = () => () => [() => true];
