import React from 'react';

import _orderBy from 'lodash/orderBy';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import { DayPickerWithField } from 'components/DatePicker';
import FormRowBase from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import Spinner from 'components/Spinner';
import { useInquiryStatusTranslator, INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS, INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { StyledHalfRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import {
  StyledFormRow,
  StyledNumberedSelectRow,
} from 'pages/operationPortal/OperationInquiryList/InquiryStateFilter/styles';
import { InquiryFilters } from 'pages/operationPortal/OperationInquiryList/PageHeading/InquiryFilters';
import { useInquiryTabStatuses } from 'pages/operationPortal/OperationInquiryList/useInquiryTabStatuses';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getInquiryListStatuses, getInquiryListOptionsSelector } from 'store/inquiryList/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const InquiryStateFilter = () => {
  const states = useSelector(getInquiryListStatuses);
  const t = useTranslations();
  const dispatch = useDispatch();
  const tableOptions = useSelector(getInquiryListOptionsSelector);
  const { isPending } = useInquiryTabStatuses(); // Also fetched inquiries initially for default status

  const translateStatus = useInquiryStatusTranslator();
  const orderedStates = _orderBy(states, [
    (option) =>
      [INQUIRY_STATUSES.CLOSED, INQUIRY_STATUSES.FULFILLED, INQUIRY_STATUSES.ARCHIVED].indexOf(
        option.status as INQUIRY_STATUSES,
      ),
  ]);

  const stateAllCount = states
    .map((state) => state.counter)
    .reduce((a: number, b: number) => a + b, 0);

  const allState = {
    value: 'all',
    complexLabel: (
      <StyledNumberedSelectRow>
        <div>{translateStatus(INQUIRY_STATUSES.ALL)}</div>
        <span>{stateAllCount}</span>
      </StyledNumberedSelectRow>
    ),
    label: translateStatus(INQUIRY_STATUSES.ALL),
  };

  const statusesOptions = orderedStates.map(({ counter, status }) => ({
    value: status,
    complexLabel: (
      <StyledNumberedSelectRow>
        <div>{translateStatus(status as INQUIRY_STATUSES)}</div>
        <span>{counter}</span>
      </StyledNumberedSelectRow>
    ),
    label: translateStatus(status as INQUIRY_STATUSES),
  }));
  const { isSameOrAfter, isSameOrBefore } = useFieldValidators();

  const filteredValues = { ...tableOptions.filterBy };
  const onSubmitDate = (values: { createdAtFrom?: string; createdAtTo?: string }) => {
    dispatch(
      getInquiriesAction({
        ...tableOptions,
        currentPage: 1,
        filterBy: { ...tableOptions.filterBy, ...values },
      }),
    );
  };
  const onSubmitFrom = (value: string) => onSubmitDate({ createdAtFrom: value });
  const onSubmitTo = (value: string) => onSubmitDate({ createdAtTo: value });

  const onSubmitState = (state: string) => {
    dispatch(
      getInquiriesAction({
        ...tableOptions,
        currentPage: 1,
        sortBy: INQUIRY_TABLE_HEADERS.CREATED_AT,
        filterBy: { ...tableOptions.filterBy, status: state },
      }),
    );
  };

  const countActiveFilters = (): string => {
    const count = Object.values(filteredValues).filter((filteredValue) => {
      return filteredValue;
    }).length;
    return count > 0 ? count.toString() : '';
  };

  if (isPending) {
    return (
      <SpinnerWrapper data-testid="spinner">
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Form
      onSubmit={() => {}}
      initialValues={filteredValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <StyledFormRow>
            <FormRowBase alignItems="flex-start">
              <StyledHalfRow>
                <SelectWithField
                  name={INQUIRY_FILTERS.STATUS}
                  caption={t('pages.inquiryList.table.headers.status')}
                  options={[allState, ...statusesOptions]}
                  placeholder={t('placeholders.pleaseChoose')}
                  onChange={onSubmitState}
                  data-testid={'select-field-' + INQUIRY_FILTERS.STATUS}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <DayPickerWithField
                  name={INQUIRY_FILTERS.CREATED_AT_FROM}
                  validate={isSameOrBefore(values.createdAtTo)}
                  caption={t('pages.inquiryList.filtersDrawer.fields.createdAtFrom.caption')}
                  onChange={onSubmitFrom}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <DayPickerWithField
                  name={INQUIRY_FILTERS.CREATED_AT_TO}
                  validate={isSameOrAfter(values.createdAtFrom)}
                  caption={t('pages.inquiryList.filtersDrawer.fields.createdAtTo.caption')}
                  onChange={onSubmitTo}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <InquiryFilters badge={countActiveFilters()} />
              </StyledHalfRow>
            </FormRowBase>
          </StyledFormRow>
        </form>
      )}
    />
  );
};

export default InquiryStateFilter;
