import React from 'react';

import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import PortalPage from 'components/PortalPage';
import paths from 'constants/paths';
import ErrorBoundary from 'modules/ErrorBoundary';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import DataProvider from 'modules/InquiryDetails/FetchInquiryDetails';
import BigPictureInquiryDetails from 'pages/customerPortal/InquiryDetails/BigPictureInquiryDetails';
import CustomerDashboard from 'pages/customerPortal/InquiryDetails/Dashboard';
import DetailedInformation from 'pages/customerPortal/InquiryDetails/DetailedInformation';
import CustomerDocumentExchange from 'pages/customerPortal/InquiryDetails/DocumentExchange/CustomerDocumentExchange';
import NavigationBar from 'pages/customerPortal/InquiryDetails/NavigationBar';
import InquiryEdit from 'pages/customerPortal/InquiryEdit/CustomerInquiryEdit';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import LeasePlanInquiryDetails from './LeasePlanInquiryDetails';

type Props = RouteComponentProps<{ id: string }>;

const InquiryDetails = ({
  match: {
    params: { id },
  },
}: Props) => {
  const redirectPath = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dvag]: paths.customer.inquiryDetails.details.replace(':id', id),
    default: paths.customer.inquiryDetails.dashboard.replace(':id', id),
  });

  return (
    <ErrorBoundary>
      <DataProvider inquiryId={id}>
        <Switch>
          <CustomerPrivateRoute
            path={paths.customer.inquiryDetails.edit.root}
            component={InquiryEdit}
          />
          <CustomerPrivateRoute
            path="*"
            render={() => (
              <PortalPage heading={<NavigationBar id={id} />}>
                <Switch>
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.dashboard}
                    component={CustomerDashboard}
                  />
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.details}
                    component={DetailedInformation}
                  />
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.documentExchange}
                    component={CustomerDocumentExchange}
                  />
                  <Route path="*" render={() => <Redirect to={redirectPath} />} />
                </Switch>
              </PortalPage>
            )}
          />
        </Switch>
      </DataProvider>
    </ErrorBoundary>
  );
};

const DisplayCondition = (props: Props) => {
  return (
    <InquiryTypeCondition
      portal
      cases={{
        onlinefactoring: <BigPictureInquiryDetails />,
        big_picture: <BigPictureInquiryDetails />,
        leaseplan: <LeasePlanInquiryDetails />,
        default: <InquiryDetails {...props} />,
      }}
    />
  );
};

export default DisplayCondition;
