interface HeaderThemeProps {
  hideLoginLink?: boolean;
  hideLanguageSelector?: boolean;
}

const Header = {
  parts: ['languageSelector', 'loginLink'],
  baseStyle: ({ hideLanguageSelector, hideLoginLink }: HeaderThemeProps) => ({
    loginLink: {
      display: hideLoginLink ? 'none' : undefined,
    },
    languageSelector: {
      display: hideLanguageSelector ? 'none' : undefined,
    },
  }),
  variants: {},
  defaultProps: {},
};

export default Header;
