import { IInquiryFinancingDetailsFields } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING__OBJECT_USAGE,
} from 'modules/Inquiry/Form/formFields';

import { calculateUsageSpaceSum } from './marketInformationFieldsMap';

export const calculateUsageSum = (
  financingPurposeFields: IInquiryFinancingDetailsFields,
  usageKind: string,
) => {
  const financingUsageObjectsData = financingPurposeFields[PROJECT_FINANCING__OBJECT_USAGE];
  const result = calculateUsageSpaceSum(
    financingUsageObjectsData,
    usageKind,
    PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  );
  return result;
};
