import React from 'react';

import { Text } from 'components/Text';
import intlShape from 'shared/intlShape';
import { withIntl } from 'utils/intl';

const Confirmation = ({ intl: { formatMessage } }) => (
  <div data-testid="request-password-reset-confirmation">
    <Text>{formatMessage({ id: 'pages.requestPasswordReset.subheading' })}</Text>
    <br />
    <Text semibold>{formatMessage({ id: 'pages.requestPasswordReset.submitSuccess' })}</Text>
  </div>
);

Confirmation.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(Confirmation);
