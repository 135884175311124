import React from 'react';

import StyledLogoutButton from 'components/LogoutButton/StyledLogoutButton';
import { logOutAction } from 'shared/auth/loginLogout.actions';
import { useToasts } from 'shared/hooks/useToasts';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const LogoutButton = () => {
  const { success } = useToasts();
  const { makeCall } = useDispatchApiCall();
  const t = useTranslations('pages.companyDetails.sections.contactPerson.buttons');

  function logoutUser() {
    makeCall(logOutAction(), () => success({ description: t('loggedOut') }));
  }

  return (
    <StyledLogoutButton type="button" onClick={logoutUser}>
      {t('logOut')}
    </StyledLogoutButton>
  );
};

export default LogoutButton;
