import React from 'react';

import { ConfigController } from 'config/ConfigController';
import { useFormConfig } from 'config/formConfig/hooks';

/*
 * Use this inside the inquiry process
 */
export const chooseSelectedInquiryTypeSpecificComponent = (componentMap) => (props) => {
  const { selectedInquiryType } = useFormConfig();
  if (componentMap[selectedInquiryType] === null) {
    return null;
  }
  const SelectedComp = componentMap[selectedInquiryType] || componentMap.default;
  return <SelectedComp {...props} />;
};

export const useSelectedInquiryTypeSpecificValue = (valueMap) => {
  const { selectedInquiryType } = useFormConfig();

  if (valueMap[selectedInquiryType] === null) {
    return null;
  }
  const SelectedValue = valueMap[selectedInquiryType] || valueMap.default;
  return SelectedValue;
};

/*
 * Use this inside functions/services etc
 */
export const chooseSelectedInquiryTypeSpecificValue = (valuesMap) => {
  const selectedInquiryType = ConfigController.form.getSelectedInquiryType();
  if (valuesMap[selectedInquiryType] === null) {
    return null;
  }
  const selectedValue = valuesMap[selectedInquiryType] || valuesMap.default;
  return selectedValue;
};
