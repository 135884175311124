import styled, { css } from 'styled-components/macro';

import { FormColumn } from 'components/FormRow/FormRowWrapper';
import {
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_BOLD,
} from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';
import { transition } from 'theme/themeConstants';

export const StyledFinancingPlanHeadline = styled.div`
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const SmallRow = styled.div`
  margin-bottom: 1rem;
`;

export const StyledSumValues = styled.div<{ hasBiggerSum: boolean }>`
  .value {
    font-size: 2rem;
    font-weight: ${FONT_WEIGHT_BOLD};
    color: ${({ theme }) => theme.colors.text.tertiary};
    transition: color ${transition.default};
  }

  .symbol {
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT_REGULAR};
    color: ${({ theme }) => theme.colors.text.tertiary};
    transition: color ${transition.default};
  }

  ${({ hasBiggerSum }) =>
    hasBiggerSum &&
    css`
      .value {
        color: ${({ theme }) => theme.colors.status.error};
      }

      .symbol {
        color: ${({ theme }) => theme.colors.text.tertiary};
      }
    `}
`;

export const StyledCalculationTable = styled.div`
  display: grid;
  grid-template-areas: 'useOf' 'sumOfUse' 'sourceOf' 'sumOfSource';

  ${mqMedium`
    grid-template-areas: 'useOf sourceOf' 'sumOfUse sumOfSource';
  `}
`;

export const StyledTableOfUse = styled(FormColumn)`
  grid-area: useOf;
`;

export const StyledTableOfSource = styled(FormColumn)`
  grid-area: sourceOf;
`;

export const StyledSumOfUse = styled(FormColumn)`
  grid-area: sumOfUse;
  margin-bottom: 4rem;

  ${mqMedium`
    margin-bottom: 0;
  `}
`;

export const StyledSumOfSource = styled(FormColumn)`
  grid-area: sumOfSource;
`;
