import React from 'react';

import FormSection from 'components/FormSection';

import DisabledFormSection from '../../../../../../components/DisabledFormSection';
import FormRow from '../../../../../../components/FormRow';
import { PasswordInputWithField } from '../../../../../../components/PasswordInput';
import { translations } from '../../../../../../new/form/common/types';
import { Condition } from '../../../../../../new/form/Condition';
import { TextComponent } from '../../../../../../theme/components/Text';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import {
  combineValidators,
  isValidPassword,
  required,
  equalTo,
} from '../../../../../../utils/validators';
import { mittweidaFields } from '../../../../fields';

const PasswordSectionContainer = ({ children }: { children: React.ReactNode }) => {
  const tMain = useTranslations();
  const { title, fallback } =
    translations.inquiryType.mittweida.pages.userDetails.sections.password;

  return (
    <Condition
      validation={'contactPersonSection'}
      fallback={
        <DisabledFormSection sectionNumber={2} placeholder={tMain(fallback)} title={tMain(title)} />
      }
    >
      <FormSection title={tMain(title)} name={'password'} sectionNumber={2}>
        {children}
      </FormSection>
    </Condition>
  );
};

export const PasswordSection = () => {
  const { password, passwordConfirmation } = mittweidaFields.userProfilePage.passwordSection;
  const tMain = useTranslations();
  const t = useTranslations('pages.userProfile.sections.completingContactData.fields');

  return (
    <PasswordSectionContainer>
      <FormRow>
        <PasswordInputWithField
          name={password}
          validate={combineValidators(
            required(tMain('errors.required')),
            isValidPassword(t('userProfilePassword.errors.isValid')),
          )}
          caption={t('userProfilePassword.caption')}
        />
        <PasswordInputWithField
          name={passwordConfirmation}
          validate={combineValidators(
            required(tMain('errors.required')),
            equalTo(password, t('userProfilePasswordRepeated.errors.equalTo')),
          )}
          caption={t('userProfilePasswordRepeated.caption')}
        />
      </FormRow>
    </PasswordSectionContainer>
  );
};

export const LoggedInPasswordSection = () => {
  const t = useTranslations();

  return (
    <PasswordSectionContainer>
      <FormRow>
        <TextComponent>
          {t(translations.inquiryType.mittweida.pages.userDetails.sections.password.loggedInText)}
        </TextComponent>
      </FormRow>
    </PasswordSectionContainer>
  );
};
