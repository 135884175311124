import createDecorator from 'final-form-focus';

import { TOP_BAR_HEIGHT } from 'constants/globalConstants';
import { scrollTo } from 'utils/scroll';

const SCROLL_OFFSET_FOR_LABEL = 80;

const getFieldNameForStandardAndArrayFields = ([fieldName, errorValue]) => {
  if (Array.isArray(errorValue)) {
    return Object.keys(errorValue[0])[0];
  }
  return fieldName;
};

const getNamesOfInvalidFields = (errors) =>
  Object.entries(errors)
    .filter(([key]) => errors[key])
    .map(getFieldNameForStandardAndArrayFields) || [];

const getFirstNotValidElement = (inputs, errors) => {
  const invalidFields = getNamesOfInvalidFields(errors);

  return inputs.find((input) => {
    const fieldName = input.name.split('.').pop(); // some fields has names in format base[index].further
    return invalidFields.includes(fieldName || input['data-name']);
  });
};

const createFocusDecorator = () =>
  createDecorator(null, (inputs, errors) => {
    const firstNotValidFieldElement = getFirstNotValidElement(inputs, errors);

    if (!firstNotValidFieldElement) {
      return null;
    }

    const inputOffsetTop = firstNotValidFieldElement.getBoundingClientRect().top;

    const scrollValue =
      window.pageYOffset + inputOffsetTop - TOP_BAR_HEIGHT - SCROLL_OFFSET_FOR_LABEL;

    // use smooth scroll instead of default one
    scrollTo(window, scrollValue);

    // There is small issue in chrome that makes preventScroll not work
    // workaround is to add it to event loop so this ugly workaround works :(
    // if you will find better solution, please do so
    setTimeout(() => firstNotValidFieldElement.focus({ preventScroll: true }), 0);

    // since create decorator doesn't allow me to prevent scroll on default focus behaviour
    // I do it on my own higher in this func
    return null;
  });

export const focusDecorator = createFocusDecorator();

export const areSectionsValid = (sectionValidators, form) =>
  !sectionValidators.find((validator) => !validator(form));
