import React from 'react';

import { useSelector } from 'react-redux';

import PortalTable from 'components/PortalTable/PortalTable';
import { useInquiryStatusTranslator } from 'modules/Inquiry/inquiryStatuses';
import { getCompanyInquiries } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const useCompanyInquiryTableHeaders = () => {
  const statusTranslator = useInquiryStatusTranslator();
  const translateProduct = useTranslations(
    'pages.inquiryDetails.offers.compeon.properties.product.options',
  );

  const t = useTranslations('pages.companiesDetails.overview');
  return [
    {
      key: 'id',
      text: t('id'),
    },
    {
      key: 'createdAt',
      text: t('createdAt'),
      renderRow: formatDateDays,
    },
    {
      key: 'productKind',
      text: t('productKind'),
      renderRow: translateProduct,
    },
    {
      key: 'financingAmount',
      text: t('financingAmount'),
      renderRow: formatPrice,
    },
    {
      key: 'status',
      text: t('status'),
      renderRow: statusTranslator,
    },
  ];
};

const CompanyOverviewInquiries: React.FC = () => {
  const headers = useCompanyInquiryTableHeaders();
  const state = useSelector(getCompanyInquiries);

  return (
    <PortalTable
      headers={headers}
      tableData={state}
      onRowClick={() => {}}
      onSortChange={() => {}}
      sortBy="createdAt"
      isInitialized
    />
  );
};

export default CompanyOverviewInquiries;
