import React from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';

import intlShape from 'shared/intlShape';
import { ButtonComponent } from 'theme/components/Button';
import { withIntl } from 'utils/intl';
import { combineValidators, required, maxLength } from 'utils/validators';

import { StyledHeading } from '../../styles';
import { StyledDescription, StyledTextArea } from './styles';

const TEXT_AREA_MAX_LENGTH = 500;

const Form = ({ onSubmit, intl: { formatMessage } }) => {
  const t = (id) =>
    formatMessage({ id: `pages.inquiryDetails.dashboard.closeInquiry.archiveInquiry.${id}` });

  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StyledHeading>{t('title')}</StyledHeading>
            <StyledDescription>{t('description')}</StyledDescription>

            <Field
              name="archivingReason"
              data-testid="Rejection-Reason"
              validate={combineValidators(
                required(formatMessage({ id: 'errors.required' })),
                maxLength(
                  TEXT_AREA_MAX_LENGTH,
                  formatMessage(
                    { id: 'errors.maxLengthTextCharacters' },
                    { number: TEXT_AREA_MAX_LENGTH },
                  ),
                ),
              )}
            >
              {({ input, meta }) => <StyledTextArea {...input} {...meta} />}
            </Field>

            <ButtonComponent mt={4} type="submit">
              {t('archiveInquiryBtn')}
            </ButtonComponent>
          </form>
        )}
      />
    </>
  );
};

Form.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withIntl(Form);
