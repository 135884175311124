import { useCallback, useEffect, useState } from 'react';

import _get from 'lodash/get';

import { getKycCompanyData } from 'store/inquiryDetails/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export interface KycCompanyDataSuggestion {
  city: string;
  kyc_company_id: string;
  name: string;
  street: string;
  zip_code: string;
  id: string;
}

const mapKycCompanyData = (apiCompany: any): KycCompanyDataSuggestion => ({
  street: apiCompany.attributes.street,
  city: apiCompany.attributes.city,
  name: apiCompany.attributes.name,
  zip_code: apiCompany.attributes.zip_code,
  kyc_company_id: apiCompany.attributes.kyc_company_id,
  id: apiCompany.id,
});

export const useFetchKycCompaniesData = (companyId?: string) => {
  const { makeCall } = useDispatchApiCall();
  const [data, setData] = useState([]);

  const fetchKycCompanyData = useCallback(
    async (companyId: string) => {
      await makeCall(getKycCompanyData(companyId));
      const { payload } = await makeCall(getKycCompanyData(companyId));
      const mappedData = _get(payload, 'data.included', []).map(mapKycCompanyData) || [];

      setData(mappedData);
    },
    [makeCall],
  );

  useEffect(() => {
    if (companyId) {
      fetchKycCompanyData(companyId);
    }
  }, [companyId, fetchKycCompanyData]);

  return data;
};
