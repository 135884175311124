import { DEFAULT_CURRENCY } from 'constants/globalConstants';

export enum APPRAISE_OBJECT_EVALUATION_ROW_TYPES {
  SALES_PRICE = 'SALES_PRICE',
  SALES_PRICE_PARKING_LOT = 'SALES_PRICE_PARKING_LOT',
  SALES_PRICE_GARAGE = 'SALES_PRICE_GARAGE',
  RESIDENTIAL_RENT = 'RESIDENTIAL_RENT',
  COMERCIAL_RENT = 'COMERCIAL_RENT',
  PARKING_LOT_RENT = 'PARKING_LOT_RENT',
  GARAGE_RENT = 'GARAGE_RENT',
}

export interface IRealStateApiResponse {
  last_calculated_at: Date;
  potential_sales_revenue: number;
  possible_rental_income: number;
  living_space: number;
  commercial_space: number;
  property_evaluation: IPropertyEvaluation;
}
export interface IPropertyEvaluation extends Record<string, string> {
  sales_price: string;
  sales_price_parking_lot: string;
  sales_price_garage: string;
  rent_residential: string;
  rent_commercial: string;
  rent_parking_lot: string;
  rent_garage: string;
}

export const appraiserObjectEvalNameRowTranslationPaths: {
  [key: string]: string;
} = {
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE]: 'salesPrice',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_PARKING_LOT]: 'salesPriceParkingLot',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_GARAGE]: 'salesPriceGarage',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.RESIDENTIAL_RENT]: 'residentialRent',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.COMERCIAL_RENT]: 'comercialRent',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.PARKING_LOT_RENT]: 'parkingLotRent',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.GARAGE_RENT]: 'garageRent',
};

export const appraiserObjectFromApiMapping: {
  [key: string]: string;
} = {
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE]: 'sales_price',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_PARKING_LOT]: 'sales_price_parking_lot',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_GARAGE]: 'sales_price_garage',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.RESIDENTIAL_RENT]: 'rent_residential',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.COMERCIAL_RENT]: 'rent_commercial',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.PARKING_LOT_RENT]: 'rent_parking_lot',
  [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.GARAGE_RENT]: 'rent_garage',
};

export const getAppraiserObjectEvalUnitRows: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const unitCurrencySize = `${DEFAULT_CURRENCY}/${unitTranslations('other.m2')}`;
  const unitCurrencyPiece = `${DEFAULT_CURRENCY}/${unitTranslations('other.perPiece')}`;
  return {
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE]: unitCurrencySize,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_PARKING_LOT]: unitCurrencyPiece,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.SALES_PRICE_GARAGE]: unitCurrencyPiece,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.RESIDENTIAL_RENT]: unitCurrencySize,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.COMERCIAL_RENT]: unitCurrencySize,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.PARKING_LOT_RENT]: unitCurrencyPiece,
    [APPRAISE_OBJECT_EVALUATION_ROW_TYPES.GARAGE_RENT]: unitCurrencyPiece,
  };
};
