import React from 'react';

import _get from 'lodash/get';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { checkEmailAvailabilityAction } from 'api/CompeonReverseApi/customer/actions';
import DebouncedInputField from 'components/Input/DebouncedInputField';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import EmailNotAvailable from 'modules/AvailableEmail/EmailNotAvailable';
import { ErrorTextWrapper } from 'modules/AvailableEmail/EmailNotAvailable/styles';
import { COMPANY_DETAILS_EMAIL } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import {
  isEmailAvailibleSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { useFieldChangedValue } from 'store/hooks/useFormValues';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { combineValidators } from 'utils/validators';

/*
 * There is a little workaround here as we push first letter as error message when async validation is not positive
 * I couldn't figure out good way of combining several solution and after 2 days of fighting I decided to keep
 * double error messages overlapping each other
 *
 */

export const EMAIL_FIELD_DEBOUNCE = 300;
let lastArg: any;
let lastResult: any;
export const simpleMemoize = (fn: Function) => {
  return (arg: any) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

const DebounceEmail: React.FC = () => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail',
  );

  const { meta } = useField(COMPANY_DETAILS_EMAIL);

  const companyDetailsEmail = useFieldChangedValue<string>(COMPANY_DETAILS_EMAIL, '');
  const { required, isEmail } = useFieldValidators();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  const validateEmailAvailability = simpleMemoize(async (email: string) => {
    const { payload } = await makeCall(checkEmailAvailabilityAction(email));
    const emailAlreadyExist = _get(payload, 'data.data.allowed_to_inquiry');

    return emailAlreadyExist ? t('errors.emailTaken.haveAccount') : null;
  });

  const noAccountForEmail = useSelector(isEmailAvailibleSelector);
  const isLoadingEmailAvailability = useSelector(isLoadingEmailAvailabilitySelector);

  const shouldShowLoginLink =
    !isLoadingEmailAvailability &&
    !noAccountForEmail &&
    companyDetailsEmail &&
    isEmailRegex.test(companyDetailsEmail);

  const shouldShowError = !shouldShowLoginLink && meta.error && (meta.dirty || meta.touched);

  const shouldShowEmailIcon = !!companyDetailsEmail && isEmailRegex.test(companyDetailsEmail);

  return (
    <div>
      <DebouncedInputField
        name={COMPANY_DETAILS_EMAIL}
        debounce={EMAIL_FIELD_DEBOUNCE}
        validate={combineValidators(required, isEmail, validateEmailAvailability)}
        type="email"
        caption={t('caption')}
        sideSymbol={() =>
          shouldShowEmailIcon && (
            <AvailableEmailSymbol
              isLoading={isLoadingEmailAvailability}
              isAllowedToInquiry={meta.valid}
            />
          )
        }
      />
      {shouldShowLoginLink && <EmailNotAvailable email={companyDetailsEmail} />}
      {shouldShowError && <ErrorTextWrapper>{meta.error}</ErrorTextWrapper>}
    </div>
  );
};

export default DebounceEmail;
