import { useEffect, useCallback } from 'react';

import normalize from 'json-api-normalizer';
import { useDispatch, useSelector } from 'react-redux';

import { USER_SCOPE } from 'constants/userScopes';
import { IFileRequest } from 'models/FileRequest.model';
import { mapApiFileRequest } from 'shared/documentExchange/documentExchange.service';
import type { ApiFile } from 'shared/documentExchange/documentExchange.service';
import type { ApiFileRequest } from 'shared/documentExchange/documentExchange.service';
import {
  setFileRequestsAction,
  getDocumentsToUploadAction,
} from 'store/documentExchange/documentExchange.actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

interface ApiReponse {
  file_upload_requests: Record<string, ApiFileRequest>;
  uploaded_files?: Record<string, ApiFile>;
}

const mapFromApi = (rawData: any): Array<IFileRequest> => {
  if (!rawData.data.length) {
    return [];
  }
  const normalizedResponse: ApiReponse = normalize(rawData, { camelizeKeys: false });

  const { file_upload_requests, uploaded_files } = normalizedResponse;

  const fileUploadRequests = Object.values(file_upload_requests);
  const uploadedFiles = Object.values(uploaded_files ?? {});

  return fileUploadRequests.map((request) => {
    const fileIds = request.relationships.uploaded_files?.data.map((file) => file.id) ?? [];
    const filteredFiles = uploadedFiles.filter(({ id }) => fileIds.includes(id)) ?? [];
    return mapApiFileRequest(request, filteredFiles);
  });
};

export const useFetchInquiryFileRequests = ({
  isDownloadedBy,
  selectedLanguage,
}: {
  isDownloadedBy: USER_SCOPE;
  selectedLanguage?: string;
}) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isPending, error, makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();

  const fetchData = useCallback(
    (id) =>
      makeCall(getDocumentsToUploadAction(id, selectedLanguage), ({ payload }: any) => {
        const mappedResponse = mapFromApi(payload.data);
        dispatch(setFileRequestsAction(mappedResponse));
      }),
    [makeCall, dispatch, selectedLanguage],
  );

  useEffect(() => {
    fetchData(inquiryId);
  }, [fetchData, inquiryId]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending, fetchData };
};
