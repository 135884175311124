import { Observable } from 'utils/Observable';
import { removeObject } from 'utils/sessionStorage/helpers';
import { FORM_VALUES } from 'utils/sessionStorage/keys';

import { FormValidationStore } from './FormValidationStore';
import { FormValuesStore } from './FormValuesStore';
import {
  FormValues,
  FormValueDataEmit,
  IFieldValue,
  IFormStore,
  ISaveBulkOptions,
  ISaveOptions,
} from './types';

export class FormFieldController
  extends Observable<FormValueDataEmit>
  implements IFormStore<IFieldValue>
{
  private static Instance: FormFieldController;

  public formValuesStore = FormValuesStore.instance;
  public formValidationStore = FormValidationStore.instance;

  private constructor() {
    super();
  }

  public static get instance(): FormFieldController {
    if (!FormFieldController.Instance) {
      FormFieldController.Instance = new FormFieldController();
    }

    return FormFieldController.Instance;
  }

  public destroy() {}

  public saveValue(
    name: string | undefined,
    newValue: unknown,
    { emit = true, customValidator = null, override = false }: ISaveOptions = {},
  ) {
    this.formValidationStore.validate(name, newValue, this.formValuesStore.formValues, {
      customValidator,
    });

    this.formValuesStore.saveValue(name, newValue, { emit, override });

    if (emit) {
      this.emit({
        formValues: this.formValuesStore.formValues,
        changedField: {
          name,
          newValue,
          isValid: this.formValidationStore.isFieldValid(name),
        },
      });
    }
  }

  public validate(name: string): boolean {
    return this.formValidationStore.validate(
      name,
      this.formValuesStore.getValue(name),
      this.formValuesStore.formValues,
    );
  }

  public saveBulk(data: FormValues, { emit = true, force = false }: ISaveBulkOptions = {}) {
    this.formValuesStore.saveBulk(data, { force });

    if (emit) {
      this.emit({
        formValues: this.formValuesStore.formValues,
      });
    }
  }

  public saveInitial(data?: FormValues) {
    this.formValuesStore.saveInitial(data);
  }

  public deleteBulk(keys: string[]) {
    this.formValuesStore.deleteBulk(keys);

    this.emit({
      formValues: this.formValuesStore.formValues,
    });
  }

  public get formValues(): FormValues {
    return this.formValuesStore.formValues;
  }

  public getValue(name: string): IFieldValue {
    return {
      isValid: this.formValidationStore.isFieldValid(name),
      value: this.formValuesStore.getValue(name),
    };
  }

  public deleteValue(name: string, index?: number) {
    this.formValuesStore.deleteValue(name, index);
  }

  public deleteBulkPair(keys: string[]) {
    const formValues = this.formValuesStore.formValues;
    keys.forEach((e) => delete formValues[e]);
    removeObject(FORM_VALUES);
    this.saveBulk(formValues);
  }
}
