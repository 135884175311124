import { useIntl } from 'react-intl';

import { IntlFormatMessage } from 'models/Intl.model';

export enum INQUIRY_STATUSES {
  ALL = 'all',
  PENDING = 'pending',
  NEW = 'new',
  ASSIGNED = 'assigned',
  IN_PROGRESS = 'in_process',
  FORWARDED_TO_CUSTOMER = 'forwarded_to_customer',
  FORWARDED_TO_COMPEON = 'forwarded_to_compeon',
  FORWARDED_TO_COMPEON_SHORTHAND = 'forwarded_to_compeon_shorthand',
  CLOSED = 'closed',
  FULFILLED = 'fulfilled',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  INITIATED_EXTERNALLY = 'initiated_externally',
  FAILED = 'failed',
}

export enum INQUIRY_SUBSTATUSES {
  FORWARDED_TO_CUSTOMER = 'waiting_for_accept_to_forward_to_compeon',
  AUTOMATIC_PROCESS_IDENT_AND_SIGN = 'automatic_process_ident_and_sign',
  AUTOMATIC_PROCESS_FINAL_INFO_REQUEST = 'automatic_process_final_info_request',
  AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED = 'automatic_process_final_info_provided',
  UNOPENED = 'unopened',
  OPENED = 'opened',
  WELCOME_CALL_PERFORMED = 'welcome_call_performed',
  ASSIGNED = 'assigned',
  WITH_BANK_OFFERS = 'with_bank_offers',
  WITH_COMPEON_OFFERS = 'with_compeon_offers',
  WITH_OFFER_INTERESTED_IN = 'with_offer_interested_in',
  OFFER_ACCEPTED = 'offer_accepted',
  OFFER_EXPIRED = 'offer_expired',
  INITIATED_EXTERNALLY = 'initiated_externally',
  IN_PROCESS = 'in_process',
  FAILED = 'failed',
}

// API -> app
const TRANSLATION_MAP = {
  [INQUIRY_STATUSES.ALL]: 'all',
  [INQUIRY_STATUSES.PENDING]: 'pending',
  [INQUIRY_STATUSES.NEW]: 'new',
  [INQUIRY_STATUSES.ASSIGNED]: 'assigned',
  [INQUIRY_STATUSES.IN_PROGRESS]: 'inProgress',
  [INQUIRY_STATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON]: 'forwardedToCompeon',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON_SHORTHAND]: 'forwardedToCompeonShorthand',
  [INQUIRY_STATUSES.CLOSED]: 'closed',
  [INQUIRY_STATUSES.FULFILLED]: 'fulfilled',
  [INQUIRY_STATUSES.ARCHIVED]: 'archived',
  [INQUIRY_STATUSES.DRAFT]: 'draft',
  [INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'initiatedExternally',
  [INQUIRY_SUBSTATUSES.WITH_BANK_OFFERS]: 'withBankOffers',
  [INQUIRY_SUBSTATUSES.WITH_COMPEON_OFFERS]: 'withCompeonOffers',
  [INQUIRY_SUBSTATUSES.WITH_OFFER_INTERESTED_IN]: 'withOfferInterestedIn',
  [INQUIRY_SUBSTATUSES.UNOPENED]: 'unopened',
  [INQUIRY_SUBSTATUSES.OPENED]: 'opened',
  [INQUIRY_SUBSTATUSES.WELCOME_CALL_PERFORMED]: 'welcomeCallPerformed',
  [INQUIRY_SUBSTATUSES.OFFER_ACCEPTED]: 'offerAccepted',
  [INQUIRY_SUBSTATUSES.OFFER_EXPIRED]: 'offerExpired',
  [INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_IDENT_AND_SIGN]: 'automaticProcessIdentAndSign',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_REQUEST]: 'automaticProcessFinalInfoRequest',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED]: 'automaticProcessFinalInfoProvided',
  [INQUIRY_SUBSTATUSES.FAILED]: 'failed',
  default: 'partial',
};

export const allStatuses = Object.values({ ...INQUIRY_STATUSES, ...INQUIRY_SUBSTATUSES });

export const translateInquiryStatus = (
  intlFormatMessage: IntlFormatMessage,
  status: INQUIRY_STATUSES | INQUIRY_SUBSTATUSES,
) => {
  return intlFormatMessage({
    id: `inquiry.statuses.${TRANSLATION_MAP[status] || TRANSLATION_MAP.default}`,
  });
};

export const useInquiryStatusTranslator = () => {
  const { formatMessage } = useIntl();
  return (status: INQUIRY_STATUSES | INQUIRY_SUBSTATUSES) =>
    translateInquiryStatus(formatMessage, status);
};

export const isInquirySubstatus = (
  inquirySubstatus: string,
): inquirySubstatus is INQUIRY_SUBSTATUSES => inquirySubstatus !== undefined;
