import styled from 'styled-components/macro';

import { mqMedium, mqExtraLarge } from 'styles/breakpoints';
import { borderRadius, transition } from 'theme/themeConstants';

export const StyledTile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  padding: 0 1.75rem;
  background-color: transparent;
  transition: border-color ${transition.default}, background-color ${transition.default};

  ${mqMedium`
    height: 8rem;
  `}

  ${mqExtraLarge`
    height: 10rem;
  `}

  svg {
    display: block;
    width: 100%;
    height: 40px;

    ${mqMedium`
      height: 60px;
    `}

    ${mqExtraLarge`
      height: 80px;
    `}

    [fill] {
      fill: ${({ theme }) => theme.colors.background.darkGrey};
      stroke: none;
      transition: fill ${transition.default};
    }

    [stroke] {
      fill: none;
      stroke: ${({ theme }) => theme.colors.border.lightGrey};
      transition: stroke ${transition.default};
    }
  }

  .icon {
    width: 5rem;

    ${mqMedium`
      width: 6rem;
    `}

    ${mqExtraLarge`
      width: 7rem;
    `}
  }

  .title {
    margin: 1rem;
    font-size: 1.25rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.text.tertiary};
    transition: color ${transition.default};

    ${mqMedium`
      font-size: 1.5rem;
    `}

    ${mqExtraLarge`
      font-size: 1.75rem;
    `}
  }

  &.selected {
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};

    svg {
      [fill] {
        fill: ${(props) => props.theme.colors.background.white};
      }

      [stroke] {
        stroke: ${(props) => props.theme.colors.border.white};
      }
    }

    .title {
      color: ${(props) => props.theme.colors.text.white};
    }
  }

  &:focus,
  &:hover {
    &:not(.selected) {
      border-color: ${({ theme }) => theme.colors.primary};

      svg {
        [fill] {
          fill: ${({ theme }) => theme.colors.primary};
        }

        [stroke] {
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }

      .title {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
`;
