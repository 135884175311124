import React from 'react';

import { useSelector } from 'react-redux';

import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import {
  getAdditionalCustomerFilesWithoutFileRequest,
  getNumOfNewFilesUploadedForOperation,
} from 'store/documentExchange/documentExchange.selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const AdditionalFilesFromCustomer: React.FC = () => {
  const files = useSelector(getAdditionalCustomerFilesWithoutFileRequest);
  const t = useTranslations('pages.inquiryDetails.documentExchange');
  const numOfNewFiles = useSelector(getNumOfNewFilesUploadedForOperation) || 0;
  const tUpload = useTranslations('components.upload');

  if (!files.length) {
    return null;
  }

  return (
    <section>
      <FileSectionTitle>
        {t('additionalFilesfromClient')}
        {!!numOfNewFiles && ` (${numOfNewFiles} ${tUpload('newFile')})`}
      </FileSectionTitle>
      <MultipleFilesBlock
        title={tUpload('otherDocuments.title')}
        files={files}
        showFileDownloadStatusBadge
      />
    </section>
  );
};

export default AdditionalFilesFromCustomer;
