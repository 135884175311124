import _isObject from 'lodash/isObject';

import { mittweidaFields } from 'mittweida/inquiry/fields';
import { StepSequence } from 'new/form/model';

/**
 * The object for storing our inquiry data looks like this:
 * {
 *   [pageName]: {
 *      [sectionName]: {
 *        [fieldName]: string | number | boolean | undefined
 *      }
 *  }
 *
 *  We have some special cases where we have array fields and where we want to nest our fields one level deeper for more
    context:
    {
      [pageName]: {
       [sectionName]: {
         [fieldName]: Array<{
            [fieldName]: string | number | boolean | undefined
         }>
       }
    }
*  In this case we need to treat the field differently and not just return the fieldName but an object with a fieldName
*  to differentiate in the useBuildFieldForSummary hook.
*
*  For the inquiry summary page and the inquiry detail page in the operations / customer portal we just need the
*  pageName and the fields which are on that page. This hook generates an object with the pageName as key and an array of
*  fieldNames as value.
 */
export function useGeneratePagesWithFieldNames(
  stepSequence: StepSequence<any> | undefined,
): Record<string, Array<string | { fieldName: string }>> {
  if (stepSequence) {
    return stepSequence.reduce((pagesAccumulator, pageName) => {
      const sections = (mittweidaFields as any)[pageName];

      const fields = Object.keys(sections).reduce<Array<string>>(
        (fieldsAccumulator, sectionName) => {
          const section = sections[sectionName];

          const sectionFields = Object.keys(section).map((fieldName) => {
            const field = section[fieldName];
            if (_isObject(field)) {
              return {
                fieldName: `${pageName as string}.${sectionName}.${fieldName}`,
              };
            }
            return field;
          });

          return [...fieldsAccumulator, ...sectionFields.flat()];
        },
        [],
      );

      return { ...pagesAccumulator, [pageName]: fields };
    }, {});
  }

  return {};
}
