import React from 'react';

import _get from 'lodash/get';
import { Link } from 'react-router-dom';

import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { List, ListItem } from 'modules/Footer/FooterContent/FooterLinkList/List';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { scrollTop } from 'utils/scroll';

interface FooterLink {
  href: string;
  text: string;
}

const LinkList = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();

  const footerLinks: FooterLink[] = [
    {
      href: _get(paths, `${selectedInquiryType}.termsAndConditions`) || paths.termsAndConditions,
      text: `footerLinks${
        selectedInquiryType === InquiryType.leaseplan ? `.${selectedInquiryType}` : ``
      }.termsAndConditions`,
    },
    {
      href: _get(paths, `${selectedInquiryType}.privacyPolicy`) || paths.privacyPolicy,
      text: `footerLinks${
        selectedInquiryType === InquiryType.leaseplan ? `.${selectedInquiryType}` : ``
      }.privacyPolicy`,
    },
    {
      href: _get(paths, `${selectedInquiryType}.impressum`) || paths.impressum,
      text: `footerLinks${
        selectedInquiryType === InquiryType.leaseplan ? `.${selectedInquiryType}` : ``
      }.impressum`,
    },
    {
      href: _get(paths, `${selectedInquiryType}.genderNote`),
      text: `footerLinks${
        selectedInquiryType === InquiryType.leaseplan ? `.${selectedInquiryType}` : ``
      }.genderNote`,
    },
  ];

  return (
    <List>
      {footerLinks.map(({ href, text }) => {
        return (
          href && (
            <ListItem key={href}>
              <Link to={{ pathname: href }} target="_blank" onClick={scrollTop} key={text}>
                {t(text)}
              </Link>
            </ListItem>
          )
        );
      })}
    </List>
  );
};

export default LinkList;
