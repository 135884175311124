import styled from 'styled-components/macro';

import { Container } from 'components/PortalPage/styles';
import { HEADER_HEIGHT_CLOSED } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';
import { boxShadow, transition, zIndex } from 'theme/themeConstants';

export const StyledPortalHeader = styled.header`
  position: fixed;
  z-index: ${zIndex.header};
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  box-shadow: ${boxShadow.default};
  transform: translateZ(0);
  background-color: ${(props) => props.theme.colors.background.white};
  transition: padding ${transition.default};
  will-change: padding;

  ${mqMedium`
    padding-top: ${({ biggerHeader }) => (biggerHeader ? '1.5rem' : '0')};
    padding-bottom: ${({ biggerHeader }) => (biggerHeader ? '1.5rem' : '0')};
  `}

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
  }
`;

export const PageHeadline = styled.h1`
  overflow: hidden;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
`;

export const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;

  ${mqMedium`
    display: none;
  `}

  .bars {
    position: relative;
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.background.blackGrey};
    transition: background-color ${transition.default};

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: ${({ theme }) => theme.colors.background.blackGrey};
      transition: transform ${transition.default};
    }

    &::before {
      transform: translateY(-9px);
    }

    &::after {
      transform: translateY(9px);
    }
  }

  &.active {
    .bars {
      background-color: transparent;

      &::before {
        transform: rotate(45deg) translateY(0);
      }

      &::after {
        transform: rotate(-45deg) translateY(0);
      }
    }
  }
`;

export const StyledRwdMenu = styled.nav`
  opacity: 0;
  display: ${({ show }) => (show ? 'block' : 'none')};
  overflow: hidden;
  position: fixed;
  top: ${HEADER_HEIGHT_CLOSED}px;
  left: 0;
  width: 100%;
  height: 0;
  background-color: ${(props) => props.theme.colors.background.white};
  transition: opacity ${transition.default}, height ${transition.default};

  ${mqMedium`
    opacity: 1;
    display: block;
    overflow: visible;
    position: static;
    top: 0;
    width: auto;
    height: auto;
    margin-left: 2rem;
  `}

  &.open {
    opacity: 1;
    height: calc(100vh - ${HEADER_HEIGHT_CLOSED}px);

    ${mqMedium`
      height: auto;
    `}
  }
`;

export const StyledRwdMenuHeadline = styled.h2`
  overflow: hidden;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
`;
