import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { setMarketData } from 'mittweida/portals/operations/store/action';
import mapMwMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapMwMarketValueFromApi';
import { MW_MARKET_DATA_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { flattenObject } from 'utils/helpers';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

const getMarketValueAction = (inquiryId: string, recordType: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({
      params: { inquiryId },
      query: { record_type: recordType },
    }),
    method: API_METHODS.GET,
  });

const loadMwMarketData = async (
  makeCall: IMakeCall,
  inquiryId: string,
  recordType: string,
  dispatch?: Function,
) => {
  const { payload, error } = await makeCall(getMarketValueAction(inquiryId, recordType));
  const data = _get(payload, 'data');

  if (dispatch) {
    dispatch(setMarketData(payload.data));
  }

  let editFormValues;

  if (recordType === MW_MARKET_DATA_RECORD_TYPE) {
    editFormValues = mapMwMarketValueFromApi(data);
  }

  FormFieldController.instance.saveBulk(flattenObject(editFormValues), { emit: false });
  return { error };
};

export default loadMwMarketData;
