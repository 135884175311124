import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

export const mittweidaStepData: StepData<MittweidaFieldTypes> = {
  financingNeedPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.financingNeed.progressBarTitle,
    nextStep: 'fundingDetailsPage',
  },
  companyDetailPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.companyDetails.progressBarTitle,
    previousStep: 'userProfilePage',
  },
  fundingDetailsPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.fundingDetails.progressBarTitle,
    previousStep: 'financingNeedPage',
    nextStep: 'userProfilePage',
  },
  userProfilePage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.userDetails.progressBarTitle,
    previousStep: 'fundingDetailsPage',
    nextStep: 'companyDetailPage',
  },
};
