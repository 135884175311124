import * as React from 'react';

import { Flex, Box, Link, Text } from '@chakra-ui/react';

import { IInternalFile } from 'models/File.model';
import {
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_GENDER,
} from 'modules/Inquiry/Form/formFields';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import EditIcon from 'theme/components/Icon/EditIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import WarningIcon from 'theme/components/Icon/WarningIcon';
import { IconButtonComponent } from 'theme/components/IconButton';

import { EditFormValues, LegalRepresentative } from './types';

const kycDocumentsIds = [
  'kycnow_money_laundry',
  'kycnow_ad_document',
  'kycnow_cd_document',
  'kycnow_gss_document',
  'kycnow_lg_document',
];

export const getRepresentativesTable = (
  legalRepresentatives: LegalRepresentative[],
  handleOpen: () => void,
  setActiveRepresentative: React.Dispatch<React.SetStateAction<EditFormValues | undefined>>,
  handleDelete: (id: string) => void,
) =>
  legalRepresentatives.map(
    ({
      id,
      firstName,
      lastName,
      birthDate,
      salutation,
      email,
      phoneNumber,
      isComplete,
      isCustomer,
    }) => ({
      id,
      cols: [
        <>
          {!isComplete && !isCustomer && <WarningIcon boxSize={4} mr={2} color="status.error" />}
          {firstName} {lastName} {birthDate ? `, geb. ${birthDate}` : ''}
        </>,
        isCustomer ? (
          <Box height={46} />
        ) : (
          <Flex justifyContent="center">
            <IconButtonComponent
              icon={<EditIcon boxSize={6} d="block" />}
              label="Edit"
              onClick={() => {
                setActiveRepresentative({
                  [REPRESENTATIVE_GENDER]: salutation,
                  [REPRESENTATIVE_FIRST_NAME]: firstName,
                  [REPRESENTATIVE_LAST_NAME]: lastName,
                  [REPRESENTATIVE_EMAIL]: email || '',
                  [REPRESENTATIVE_PHONE_NUMBER]: phoneNumber,
                  id: id || '',
                });
                handleOpen();
              }}
            />
            <IconButtonComponent
              icon={<DeleteIcon boxSize={6} d="block" />}
              label="Delete"
              onClick={() => handleDelete(id)}
            />
          </Flex>
        ),
      ],
    }),
  );

export const getDocumentsTable = (files: IInternalFile[]) =>
  files
    .filter(({ id }) => kycDocumentsIds.includes(id))
    .map(({ id, fileName, downloadUrl }) => ({
      id,
      cols: [
        <Text as="span" wordBreak="break-all">
          {fileName}
        </Text>,
        <Flex justifyContent="center" height={50}>
          <Link href={downloadUrl} target="_blank" rel="noopener noreferrer" display="inline-flex">
            <IconButtonComponent as="span" icon={<ViewIcon boxSize={6} />} label="" />
          </Link>
        </Flex>,
      ],
    }));

export const getModifiedRepresentativesTable = (modifiedRepresentatives: LegalRepresentative[]) =>
  modifiedRepresentatives.map(({ firstName, lastName, salutation, email, phoneNumber, id }) => ({
    id,
    cols: [`${salutation} ${firstName} ${lastName}, ${email}, ${phoneNumber}`],
  }));
