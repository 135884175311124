import * as React from 'react';

import { Center, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import { CUSTOMER_EMAIL } from 'modules/Inquiry/Form/formFields';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { useToasts } from 'shared/hooks/useToasts';
import { inviteCustomerAction } from 'store/inquiryDetails/actions';
import { getCustomerData } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const InviteCustomerStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.inviteCustomer');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error: notifyError, success: notifySuccess } = useToasts();
  const { makeCall } = useDispatchApiCall();
  const customerData = useSelector(getCustomerData);

  const handleTrigger = async () => {
    const { error } = await makeCall(inviteCustomerAction(customerData[CUSTOMER_EMAIL]));

    if (error) {
      notifyError({
        description: t('error'),
      });
    } else {
      notifySuccess({ description: t('success') });
      onClose();
    }

    onClose();
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('description')}</TextComponent>
          <TextComponent mb={3}>{t('subDescription')}</TextComponent>
          {!isCompleted && (
            <ButtonComponent
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              onClick={onOpen}
              variant="primary"
            >
              {t('action')}
            </ButtonComponent>
          )}

          <AlertDialogComponent
            cancelText={t('modal.cancelText')}
            confirmText={t('modal.confirmText')}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleTrigger}
            title={t('modal.title')}
          >
            {t('modal.text')}
          </AlertDialogComponent>
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset type="dashboard" value={ProgressSectionType.CREFO_QUERY} htmlHeight="200" />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
