import React from 'react';

import { chakra, Flex } from '@chakra-ui/react';
import type { SortDirection } from '@tanstack/react-table';

import type { TableStyleProps } from '../../types';
import SortingArrows from './SortingArrows';

interface TableHeaderProps extends TableStyleProps {
  children: React.ReactNode;
  onSortChange?: (...args: Array<any>) => void;
  showSortingArrows?: boolean;
  isSorted?: boolean | SortDirection;
}

export const TableHeader = ({
  children,
  onSortChange = () => {},
  isSorted,
  showSortingArrows = false,
  color = 'text.primary',
  width,
}: TableHeaderProps) => (
  <chakra.th
    paddingTop={0}
    paddingX={4}
    paddingBottom={8}
    fontSize="md"
    fontWeight="medium"
    lineHeight="shorter"
    color={color}
    cursor={showSortingArrows ? 'pointer' : 'auto'}
    scope="col"
    onClick={onSortChange}
    width={width}
    _notFirst={{ textAlign: 'end' }}
  >
    {showSortingArrows ? (
      <Flex alignItems="center">
        {children}
        {showSortingArrows && <SortingArrows isSorted={isSorted} />}
      </Flex>
    ) : (
      children
    )}
  </chakra.th>
);

export default TableHeader;
