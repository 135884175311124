import React from 'react';

import { useSelector } from 'react-redux';

import { hasPermission as hasPermissionSelector } from 'store/user/selectors';

const withRolesGuard = (roles) => (Component) => (props) => {
  const hasPermission = useSelector(hasPermissionSelector);
  return hasPermission(roles) ? <Component {...props} /> : null;
};
export default withRolesGuard;
