import styled from 'styled-components/macro';

export const StyledFormGroupTitle = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledDocumentFieldWrapper = styled.div`
  width: 21rem;
  margin-bottom: 4rem;
`;
