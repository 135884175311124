import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { success } from 'utils/actionTypesHelpers';

export const SET_INQUIRY_FORWARDED_TO_INSURANCE = 'SET_INQUIRY_FORWARDED_TO_INSURANCE';
export const SET_INQUIRY_FORWARDED_TO_INSURANCE_SUCCESS = success(
  SET_INQUIRY_FORWARDED_TO_INSURANCE,
);

export const forwardToInsuranceAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INSURANCE_OFFER.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    actionName: SET_INQUIRY_FORWARDED_TO_INSURANCE,
  });
