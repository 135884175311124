import React from 'react';

import includes from 'lodash/includes';

import { useFormConfigRepo } from 'config/formConfig/hooks';
import { ProductKindType } from 'models/ProductKind.model';
import { PurposeKind } from 'models/PurposeKind.model';
import { RealEstateProject } from 'models/RealEstateProject.model';
import {
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__PROJECT_FINANCING,
  PURPOSE_KIND,
  REAL_ESTATE_PROJECT,
  PRODUCT_KIND__PROPERTY_LOAN,
  PURPOSE_KIND__REAL_ESTATE,
  PRODUCT_KIND__AVAL,
  PRODUCT_KIND__LIQUIDITY,
} from 'modules/Inquiry/Form/formFields';
import { useFieldChangedValue } from 'store/hooks/useFormValues';
import FactoringIcon from 'theme/components/Icon/ProductKind/FactoringIcon';
import HirePurchaseIcon from 'theme/components/Icon/ProductKind/HirePurchaseIcon';
import InvestionsIcon from 'theme/components/Icon/ProductKind/InvestionsIcon';
import LeasingIcon from 'theme/components/Icon/ProductKind/LeasingIcon';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';
import MezzanineIcon from 'theme/components/Icon/ProductKind/MezzanineIcon';
import OtherIcon from 'theme/components/Icon/ProductKind/OtherIcon';
import OverdraftIcon from 'theme/components/Icon/ProductKind/OverdraftIcon';
import ProjectFinancingIcon from 'theme/components/Icon/ProductKind/ProjectFinancingIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const PRODUCT_KIND_OPTIONS = [
  {
    id: PRODUCT_KIND__PROJECT_FINANCING,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.project_financing.title',
    icon: <ProjectFinancingIcon />,
  },
  {
    id: PRODUCT_KIND__LOAN,
    titleId: 'pages.requestDetails.sections.financialProduct.fields.productKind.options.loan.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.loan.description',
    icon: <LoanIcon />,
  },
  {
    id: PRODUCT_KIND__PROPERTY_LOAN,
    titleId: 'pages.requestDetails.sections.financialProduct.fields.productKind.options.loan.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.loan.description',
    icon: <LoanIcon />,
  },
  {
    id: PRODUCT_KIND__MEZZANINE,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.mezzanine.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.mezzanine.description',
    icon: <MezzanineIcon />,
  },
  {
    id: PRODUCT_KIND__LEASING,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.leasing.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.leasing.description',
    icon: <LeasingIcon />,
  },
  {
    id: PRODUCT_KIND__HIRE_PURCHASE,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.hire_purchase.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.hire_purchase.description',
    icon: <HirePurchaseIcon />,
  },
  {
    id: PRODUCT_KIND__FACTORING,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.factoring.title',
    icon: <FactoringIcon />,
  },
  {
    id: PRODUCT_KIND__INVESTMENT_LOAN,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.investment_loan.title',
    icon: <InvestionsIcon />,
  },
  {
    id: PRODUCT_KIND__OVERDRAFT,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.overdraft.title',
    icon: <OverdraftIcon />,
  },
  {
    id: PRODUCT_KIND__OTHER,
    titleId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.other.title',
    descriptionId:
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.other.description',
    icon: <OtherIcon />,
  },
  {
    id: PRODUCT_KIND__AVAL,
    titleId: 'pages.requestDetails.sections.financialProduct.fields.productKind.options.aval.title',
  },
  {
    id: PRODUCT_KIND__LIQUIDITY,
    titleId: 'pages.requestDetails.sections.financialProduct.fields.productKind.options.aval.title',
  },
];

export const useProductKindOptions = () => {
  const translate = useTranslations();
  const purposeKind = useFieldChangedValue<PurposeKind | undefined>(PURPOSE_KIND, undefined);
  const realEstateProject = useFieldChangedValue<RealEstateProject | undefined>(
    REAL_ESTATE_PROJECT,
    undefined,
  );
  const formConfig = useFormConfigRepo();
  let products: ProductKindType[] = [];
  if (purposeKind === PURPOSE_KIND__REAL_ESTATE && realEstateProject) {
    products = formConfig.getCurrentProducts(realEstateProject);
  } else if (purposeKind) {
    products = formConfig.getCurrentProducts(purposeKind);
  }

  return PRODUCT_KIND_OPTIONS.filter((product) => includes(products, product.id)).map(
    (product) => ({
      ...product,
      title: product.titleId ? translate(product.titleId) : null,
      description: product.descriptionId ? translate(product.descriptionId) : null,
    }),
  );
};
