export const PROFI_RECORD_TYPE = {
  VALUE_API: 'value_api',
  REE: 'ree',
};

export const FILTERS_SALE_TYPES = {
  BUILDING: 'building',
  UNIT: 'unit',
  RENT: 'rent',
};

export const FILTERS_USAGE_TYPES = {
  FUTURE: 'future',
  CURRENT: 'current',
};

export enum BANK_VALUES_RECORD_TYPE {
  OBJECT_DATA = 'bank_values_object_data',
  ADDITIONAL_INFORMATION = 'bank_values_additional_information',
}

export const FINANCING_CRITERIA_RECORD_TYPE = 'financing_criteria';

export const MW_MARKET_DATA_RECORD_TYPE = 'mw_market_data';

export const FILTERS_FINANCING_ROLE = {
  STOCKHOLDER: 'stockholder',
  DEVELOPER: 'developer',
  PARTITION: 'partition',
};

export enum PROFITABILITY_RECORD_TYPE {
  LENDING_VALUE_DETERMINATION = 'lending_value_determination',
  INVESTMENT_COSTS = 'investment_cost',
  PRESALES_DATA = 'sales_in_presales_phase',
  FINANCING_PARAMETERS = 'financing_parameter',
  SALES_COST_RATE = 'profitability_sale_as_building',
  SALE_AS_UNIT = 'profitability_sale_as_unit',
  RENTAL_PARAMETERS = 'profitability_sale_as_rent',
  AREA_INFORMATION = 'areaInformation',
}

export enum MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE {
  FINANCING_DETAILS = 'financing_details',
  INVESTMENT_PLAN = 'investment_plan',
  SOURCE_OF_FUNDS = 'source_of_funds',
  PROFITABILITY_SALES = 'profitability_sales',
  LENDING_VALUE = 'lending_value',
  RENT = 'rent',
}
