import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { USER_SCOPE } from 'constants/userScopes';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import { Channels, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { LeasePlanSummaryCard } from './LeasePlanSummaryCard';
import { ProgressSuccess } from './ProgressSuccess';
import { useProgressSections } from './useProgressSections';

import { SectionByType } from '.';

const LeaseplanCustomerDashboard = () => {
  const { selectedLanguage } = useLanguagePreferences();
  const requests = useFetchInquiryFileRequests({
    isDownloadedBy: USER_SCOPE.OPERATION_PORTAL,
    selectedLanguage,
  });
  const { firstOpenTask, completedValue, sectionsDone, sectionsOpen, sections, lastItemIndex } =
    useProgressSections();

  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();

  useWebSocket({
    channel: Channels.INQUIRY,
    customProps: {
      id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  if (requests.isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <article>
      <LeasePlanSummaryCard
        completedValue={completedValue}
        progressIncrement={sectionsDone}
        progressTotal={sectionsDone + sectionsOpen}
        isCompleted={sectionsOpen === 0}
        openTaskId={firstOpenTask}
      />
      {sections &&
        Object.values(sections)
          .filter((item) => item.visible)
          .map((section, index) => {
            const sectionProps = {
              completedValue: section.completedValue,
              isFirst: index === 0,
              isLast: sectionsOpen !== 0 && index === lastItemIndex,
              isCompleted: completedValue === 100,
              isPending: section.isPending,
              progressIncrement: section.increment,
              actionUrl: section.data?.url,
              disableButton: section.data?.disableButton,
              progressTotal: section.total,
              id: firstOpenTask === section.type ? firstOpenTask : undefined,
            };
            return <SectionByType sectionProps={sectionProps} section={section} />;
          })}
      {sectionsOpen === 0 && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
    </article>
  );
};

export default LeaseplanCustomerDashboard;
