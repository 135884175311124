import { ValidationErrorItem } from '@hapi/joi';
import _set from 'lodash/set';

import { ConfigController } from 'config/ConfigController';
import { getFieldValidator } from 'modules/Inquiry/inquiryFieldValidation/validateField';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { Store } from 'types/Store';
import { FORM_VALIDITY } from 'utils/sessionStorage/keys';
import { SessionStorageStore } from 'utils/sessionStorage/SessionStorageStore';

import { FormValues } from './types';

type Validator = (fieldValue: any, fieldValues?: any) => ValidationErrorItem | undefined;

interface IValidateProps {
  customValidator?: Validator | null;
}

const normalize = <T>(data: string | undefined): T | undefined => {
  if (data) {
    return data.replaceAll('"', '') as unknown as T;
  } else {
    return undefined;
  }
};

export class FormValidationStore {
  private static Instance: FormValidationStore;
  private store: Store<Record<string, unknown>> = new SessionStorageStore(FORM_VALIDITY, {});

  private constructor() {}

  public static get instance(): FormValidationStore {
    if (!FormValidationStore.Instance) {
      FormValidationStore.Instance = new FormValidationStore();
    }

    return FormValidationStore.Instance;
  }

  validate(
    fieldName: string | undefined,
    value: unknown,
    formValues: FormValues,
    { customValidator }: IValidateProps = {},
  ): boolean {
    if (!fieldName) {
      return false;
    }

    const validator = customValidator || this._getValidator(fieldName);

    // fallback in case if there's no validator for field
    const validationError = validator ? validator(value, formValues) : null;

    if (validationError) {
      this._saveToStore(fieldName, false);
      return false;
    } else {
      this._saveToStore(fieldName, true);
      return true;
    }
  }

  isFieldValid(fieldName?: string): boolean | undefined {
    if (!fieldName) {
      return;
    }
    return this.store.getSingleValue(fieldName) as boolean;
  }

  private _getValidator(fieldName: string): Validator {
    // TODO - fixme - this method is a major workaround, use config store in future
    const sessionStoreObj = sessionStorage.getItem('persist:inquiryProcess') || '';
    const { processLane } = JSON.parse(sessionStoreObj) || {};
    const process = normalize<InquiryLane | 'null'>(processLane);
    const selectedProcessLane = process === 'null' ? undefined : process || undefined;

    const selectedInquiryType = ConfigController.form.getSelectedInquiryType();

    return getFieldValidator(fieldName, selectedInquiryType, selectedProcessLane);
  }

  private _saveToStore(name: string, value: unknown) {
    const valueToSave = {};
    _set(valueToSave, name, value);

    this.store.save(valueToSave);
  }
}
