import React from 'react';

import OtherSection from '../OtherSection';
import BfsServiceBusinessSection from './BfsServiceBusinessSection';
import FactoringNeedSection from './FactoringNeedSection';

const BfsServiceDetailSection = () => (
  <>
    <FactoringNeedSection />
    <BfsServiceBusinessSection />
    <OtherSection />
  </>
);

export default BfsServiceDetailSection;
