import React from 'react';

import { Grid, GridItem, useDisclosure, useToken } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { USER_SCOPE } from '../../constants/userScopes';
import { IInquiryDetails } from '../../models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryLane } from '../../modules/Inquiry/InquiryLane';
import { useFetchInquiryFiles } from '../../shared/documentExchange/useFetchInquiryFiles';
import CONFIG from '../../shared/featureFlagConfig/configFromAdmin';
import { useConfig } from '../../shared/featureFlagConfig/useConfig';
import { getFilesSelector } from '../../store/documentExchange/documentExchange.selectors';
import { getInquiryDetailsSelector } from '../../store/inquiryDetails/selectors';
import ButtonComponent from '../../theme/components/Button';
import { HeadingComponent } from '../../theme/components/Heading';
import ArrowRightIcon from '../../theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from '../../theme/components/ProgressSection';
import { TextComponent } from '../../theme/components/Text';
import { useTranslations } from '../../utils/hooks/useTranslations';
import FileChooserModal from './components/FileChooserModal';
import useDocumentValidation, { useAnalysisValidation } from './hooks/useDocumentValidation';

interface SelectionForDigitizationProps {
  completedValue?: number;
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const SelectionForDigitization = ({
  completedValue,
  isCompleted,
  isFirst,
  isLast,
  progressIncrement,
  progressTotal,
  id,
}: SelectionForDigitizationProps) => {
  const [background] = useToken('colors', ['border.lightGrey']);

  useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.OPERATION_PORTAL });

  const {
    isOpen: isEvalueinOpen,
    onOpen: onEvalueinOpen,
    onClose: onEvalueinClose,
  } = useDisclosure();
  const {
    isOpen: isDigitizationOpen,
    onOpen: onDigitizationOpen,
    onClose: onDigitizationClose,
  } = useDisclosure();

  const files = useSelector(getFilesSelector);
  const digitizationValidation = useDocumentValidation({
    files,
  });

  const analysisValidation = useAnalysisValidation({
    files,
  });

  const digitizationFiles = files.filter((file: any) => file.digitizationRequestedAt === null);
  const analysisFiles = files.filter(
    (file: any) => file.wasDigitizedAt !== null && file.markedForKpiCalculationAt === null,
  );

  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.documentDigitizationAndAnalysis',
  );

  const isEvalueinEnabled = useConfig(CONFIG.IS_EVALUEIN_ENABLED);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const isContract = inquiryDetails.lane === InquiryLane.contract;

  return (
    <>
      {isEvalueinEnabled && !isContract && (
        <>
          <ProgressSection
            isFirst={isFirst}
            isLast={isLast}
            isCompleted={isCompleted}
            completedValue={completedValue}
            progressIncrement={progressIncrement}
            progressTotal={progressTotal}
          >
            <HeadingComponent as={'h2'} color={'brand.default'} mb={2} variant={'secondary'}>
              {t('title')}
            </HeadingComponent>

            <Grid
              templateColumns={'1fr 1fr'}
              background={`linear-gradient(${background},${background}) center/2px 100% no-repeat`}
            >
              <GridItem marginX={'25px'}>
                <HeadingComponent as={'h3'} variant={'tertiary'}>
                  {t('digitization.headline')}
                </HeadingComponent>
              </GridItem>
              <GridItem marginX={'25px'}>
                <HeadingComponent as={'h3'} variant={'tertiary'}>
                  {t('analysis.headline')}
                </HeadingComponent>
              </GridItem>

              <GridItem marginX={'25px'}>
                <TextComponent mb={2}>{t('digitization.summary')}</TextComponent>
              </GridItem>
              <GridItem marginX={'25px'}>
                <TextComponent mb={2}>{t('analysis.summary')}</TextComponent>
              </GridItem>
              <GridItem marginX={'25px'}>
                <ButtonComponent
                  onClick={onDigitizationOpen}
                  variant={'primary'}
                  leftIcon={<ArrowRightIcon boxSize={6} d={'block'} />}
                  disabled={digitizationValidation}
                >
                  {t('digitization.submit')}
                </ButtonComponent>
              </GridItem>
              <GridItem marginX={'25px'}>
                <ButtonComponent
                  onClick={onEvalueinOpen}
                  variant={'primary'}
                  leftIcon={<ArrowRightIcon boxSize={6} d={'block'} />}
                  disabled={analysisValidation}
                >
                  {t('analysis.submit')}
                </ButtonComponent>
              </GridItem>
            </Grid>
          </ProgressSection>

          <FileChooserModal
            isOpen={isEvalueinOpen}
            onClose={onEvalueinClose}
            type={'analysis'}
            files={analysisFiles}
          />

          <FileChooserModal
            isOpen={isDigitizationOpen}
            onClose={onDigitizationClose}
            type={'digitization'}
            files={digitizationFiles}
          />
        </>
      )}
    </>
  );
};
