import React from 'react';

import { InputWithField } from 'components/Input';
import { END_USER_EMAIL } from 'modules/Inquiry/Form/formFields';

const EndUserEmail = () => {
  return <InputWithField name={END_USER_EMAIL} type="email" />;
};

export default EndUserEmail;
