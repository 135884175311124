import { useSelector } from 'react-redux';

import {
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__VEHICLE,
  VEHICLE_KIND__CAR,
  VEHICLE_KIND__MOTORCYCLE,
} from 'modules/Inquiry/Form/formFields';
import {
  getInquiryProductKind,
  getInquiryPurposeKind,
  getInquiryVehicleKind,
} from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';

export const useCanForwardToInsurance = () => {
  const productKind = useSelector(getInquiryProductKind);
  const allowedProducts = [PRODUCT_KIND__LEASING, PRODUCT_KIND__HIRE_PURCHASE];

  const purposeKind = useSelector(getInquiryPurposeKind);
  const allowedPurposes = [
    PURPOSE_KIND__MACHINE,
    PURPOSE_KIND__VEHICLE,
    PURPOSE_KIND__OFFICE_EQUIPMENT,
  ];

  const vehicleKind = useSelector(getInquiryVehicleKind);
  const nonAllowedVehicleKinds = [VEHICLE_KIND__CAR, VEHICLE_KIND__MOTORCYCLE];

  const isCustomer = useSelector(isCustomerSelector);

  return (
    isCustomer &&
    allowedPurposes.includes(purposeKind) &&
    allowedProducts.includes(productKind) &&
    !nonAllowedVehicleKinds.includes(vehicleKind)
  );
};
