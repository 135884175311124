import React, { Component } from 'react';

import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  StyledInputWrapper,
  StyledInput,
  StyledSearchIcon,
} from 'pages/customerPortal/CustomerInquiryList/SearchInput/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import intlShape from 'shared/intlShape';
import SearchIcon from 'theme/components/Icon/SearchIcon';
import { withIntl } from 'utils/intl';

const DEBOUNCE_TIME = 500;

// use class component to fix specific issue with rerenders which prevents from debounce
class SearchInput extends Component {
  debounceOnChange = _debounce(this.onSearchChange, DEBOUNCE_TIME);

  // Do not call onChange directly as it would break saving table options
  onSearchChange(value) {
    this.props.onChange(value);
  }

  render() {
    const {
      intl: { formatMessage },
      className,
    } = this.props;
    return (
      <StyledInputWrapper className={className}>
        <StyledInput
          type="text"
          onChange={(event) => this.debounceOnChange(event.target.value)}
          placeholder={formatMessage({ id: 'components.search.placeholder' })}
        />
        <StyledSearchIcon>
          <SearchIcon boxSize={6} color="icon.lightGrey" />
        </StyledSearchIcon>
      </StyledInputWrapper>
    );
  }
}

SearchInput.defaultProps = {
  className: '',
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  className: PropTypes.string,
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: null,
  default: withIntl(SearchInput),
});
