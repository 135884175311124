import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { usePreviewFile } from 'components/DownloadBlock/usePreviewFile';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  onRemove: (id: string) => void;
  file: { id: string; fileName: string };
}

export const UploadOfferTableActions = ({ onRemove, file }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles.table');
  const previewFile = usePreviewFile({ fileId: file.id, fileName: file.fileName });

  return (
    <Flex as="ul" justifyContent="center" listStyleType="none">
      <Box
        as="li"
        position="relative"
        mr="1px"
        px={3}
        _after={{
          content: "''",
          position: 'absolute',
          top: '14px',
          right: '-1px',
          bottom: '14px',
          width: '1px',
          backgroundColor: 'background.neutral',
        }}
      >
        <button onClick={previewFile} type="button">
          <IconButtonComponent
            icon={<ViewIcon boxSize={6} d="block" />}
            label={t('preview')}
            data-testid={'Button-Delete-Contract-Icon'}
          />
        </button>
      </Box>

      <Box as="li" position="relative" px={3}>
        <button onClick={() => onRemove(file.id)}>
          <IconButtonComponent
            icon={<DeleteIcon boxSize={6} d="block" />}
            label={t('delete')}
            data-testid={'Button-Delete-Contract-Icon'}
          />
        </button>
      </Box>
    </Flex>
  );
};

export const GeneratedContractTableActions = ({ onRemove, file }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles.table');

  return (
    <Flex as="ul" justifyContent="center" listStyleType="none">
      <Box as="li" position="relative" px={3}>
        <button onClick={() => onRemove(file.id)}>
          <IconButtonComponent icon={<DeleteIcon boxSize={6} d="block" />} label={t('delete')} />
        </button>
      </Box>
    </Flex>
  );
};
