import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';

import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { isBankAdvisor } from 'store/user/selectors';

import { ErrorRequest } from './ErrorRequest';
import { GetPhoneNumber } from './GetPhoneNumber';
import { GetCodeNumber } from './GetPinNumber';
import { ShowCondition } from './ShowCondition';
import { useInstalmentHandler } from './smsVerification.service';
import { Card } from './styles';

export const INSTALMENT = 'instalment';

interface PostsProps {
  phoneNumberCaption?: string;
}

const SMSVerification: FC<PostsProps> = (props) => {
  const {
    dropInstalment,
    getInstalmentAccess,
    data,
    submitPinNumber,
    submitPhoneNumber,
    requestValues,
    resetPhoneNumber,
    state,
    isPendingPhoneNumberConfirmation,
    isPendingValues,
  } = useInstalmentHandler();
  const hasBankAdvisorRole = useSelector(isBankAdvisor);
  const isSmsTokenEnabled = useConfig(CONFIG.IS_SMS_TOKEN_ENABLED);

  useEffect(() => {
    dropInstalment();
  }, [dropInstalment]);

  useEffect(() => {
    if (
      (getInstalmentAccess() && getInstalmentAccess().accessToken) ||
      hasBankAdvisorRole ||
      !isSmsTokenEnabled
    ) {
      requestValues();
    }
  }, [data.loanTermInMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    !isSmsTokenEnabled ||
    state.codeSend ||
    data.calculations ||
    (data.amount && data.rate) ||
    (hasBankAdvisorRole && data.amount && data.rate)
  ) {
    return (
      <Card>
        <ShowCondition />
      </Card>
    );
  }

  if (state.phoneSend) {
    return (
      <Card>
        <GetCodeNumber
          onSubmit={submitPinNumber}
          phoneNumber={data.phoneNumber}
          submitRetry={resetPhoneNumber}
          pending={isPendingPhoneNumberConfirmation || isPendingValues}
        />
      </Card>
    );
  }

  if (state.unavailable) {
    return (
      <Card>
        <ErrorRequest />
      </Card>
    );
  }

  if (hasBankAdvisorRole && !(data.amount && data.rate)) {
    return null;
  }

  return (
    <Card>
      <GetPhoneNumber
        onSubmit={submitPhoneNumber}
        pending={isPendingPhoneNumberConfirmation}
        {...props}
      />
    </Card>
  );
};

export default SMSVerification;
