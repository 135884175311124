import React, { useEffect } from 'react';

import _merge from 'lodash/merge';
import { useSelector, useDispatch } from 'react-redux';

import PortalPage from 'components/PortalPage';
import ElementsPerPage from 'components/PortalTable/ElementsPerPage/ElementsPerPage';
import Pagination from 'components/PortalTable/Pagination/Pagination';
import PortalTable from 'components/PortalTable/PortalTable';
import { ConfigController } from 'config/ConfigController';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mapInquiriesToTableData } from 'modules/InquiryTable/mapFromApi';
import { mapInquiriesResponse } from 'modules/InquiryTable/mapFromApi';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { useInquiryTable } from 'modules/InquiryTable/useInquiryTable';
import NewInquiryButton from 'pages/customerPortal/CustomerInquiryList/NewInquiryButton';
import NoInquiry from 'pages/customerPortal/CustomerInquiryList/NoInquiry';
import {
  showSomeSubStatusesForInquiries,
  useCustomerInquiryTableHeaders,
} from 'pages/customerPortal/CustomerInquiryList/useCustomerInquiryTableHeaders';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { initialState } from 'store/inquiryList/reducer';
import {
  getInquiryListSelector,
  getInquiryListIsLoadingSelector,
  getInquiryListOptionsSelector,
} from 'store/inquiryList/selectors';
import { clearInquiryDraftId, setInquiryType } from 'store/inquiryProcess/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useRedirectOnSingleInquiry } from 'utils/hooks/useRedirectToInquiryEdit';
import { useTranslations } from 'utils/hooks/useTranslations';

import PageHeading from './PageHeading';

const InquiryList = () => {
  const { selectedInquiryType } = useFormConfig(); // Use this inside the inquiry process
  const isLoading = useSelector(getInquiryListIsLoadingSelector);
  const inquiries = useSelector(getInquiryListSelector);
  const inquiriesOptions = useSelector(getInquiryListOptionsSelector);
  const isLeasePlanInquiry = selectedInquiryType === InquiryType.leaseplan;
  const t = useTranslations();
  const {
    totalPages,
    currentPage,
    pageSize,
    onPageSizeChange,
    onPaginationChange,
    isInitialized,
    onSortChange,
    sortDirection,
    sortBy,
  } = useInquiryTable();
  const { redirect, checkAfterInquiriesLoad } = useRedirectOnSingleInquiry(inquiries, currentPage);
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();
  const enableNewInquiryButton = useConfig(CONFIG.IS_NEW_INQUIRY_BUTTON_ENABLED);

  useEffect(() => {
    makeCall(
      getInquiriesAction({
        ...initialState.options,
      }),
      ({ payload }) => {
        const { inquiries } = mapInquiriesResponse(payload);
        checkAfterInquiriesLoad(inquiries, currentPage);
      },
    );
    dispatch(clearInquiryDraftId()); // clear if any draft id already saved for edit inquiry/draft case. If there is any id then trash button will be shown on edit/new inquiry.
  }, [dispatch]); // eslint-disable-line

  const handleRowClick = (row) => {
    const { id, status } = row;
    const selectedInquiry = inquiries.find((inquiry) => inquiry.id === id);
    dispatch(setInquiryType(selectedInquiry.formType));
    ConfigController.form.selectInquiryType(selectedInquiry.formType, true);

    redirect(status, id);
  };

  const handleSearchChange = (searchValue) =>
    dispatch(
      getInquiriesAction(
        _merge(inquiriesOptions, { currentPage: 1, filterBy: { companyName: searchValue } }),
      ),
    );

  const tableData = mapInquiriesToTableData(inquiries).map(showSomeSubStatusesForInquiries);
  const tableHeaders = useCustomerInquiryTableHeaders();

  const noInquiries = !inquiries.length && !isLoading;

  return (
    <PortalPage
      title={t('pages.inquiryList.pageTitle')}
      heading={<PageHeading onSearchChange={handleSearchChange} />}
    >
      {noInquiries ? (
        <NoInquiry />
      ) : (
        <PortalTable
          headers={tableHeaders}
          tableData={tableData}
          onRowClick={handleRowClick}
          isInitialized={isInitialized}
          isLoading={isLoading}
          onSortChange={onSortChange}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          <ElementsPerPage onPageSizeChange={onPageSizeChange} pageSize={pageSize} />

          {!isLeasePlanInquiry && enableNewInquiryButton && <NewInquiryButton />}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginationChange={onPaginationChange}
          />
        </PortalTable>
      )}
    </PortalPage>
  );
};

export default InquiryList;
