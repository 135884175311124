import { IInquiryFinancingDetailsFields } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
  PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
  PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { calculateUsageSum } from 'pages/customerPortal/MarketInformationPage/CalculateUsageSum';

export const mapMarketInformationToApi = (
  financingPurposeFields: IInquiryFinancingDetailsFields,
) => {
  const qualityFurnishingList = ['simple', 'normal', 'good', 'very_good', 'high_quality', 'luxury'];
  const qualityConditionList = ['simple', 'medium', 'good'];

  type usageType = { name: string; value: number };
  type UsageSpaceType = { space_commercial?: string; space_living: string };

  const currentusageSpaceSum = calculateUsageSum(
    financingPurposeFields,
    PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
  );
  const futureusageSpaceSum = calculateUsageSum(
    financingPurposeFields,
    PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
  );

  const futureUsage: UsageSpaceType = { space_living: '' };
  const currentUsage: UsageSpaceType = { space_living: '' };

  const convertToDecimalString = (num: number): string => {
    return num.toFixed(1).toString();
  };
  futureusageSpaceSum.forEach((v: usageType) => {
    if (v.name === PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL && v.value > 0)
      futureUsage.space_commercial = convertToDecimalString(v.value);
    else futureUsage.space_living = convertToDecimalString(v.value);
  });

  currentusageSpaceSum.forEach((v: usageType) => {
    if (v.name === PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL && v.value > 0)
      currentUsage.space_commercial = convertToDecimalString(v.value);
    else currentUsage.space_living = convertToDecimalString(v.value);
  });

  // Convert quality_condition & quality_furnishings value strings into respective number values
  const findQualityValue = (value: string, list: Array<string>) => {
    const qualityValue = list.findIndex((v: string) => v === value);
    if (qualityValue !== -1) return qualityValue;
    else return null;
  };

  const spacePlot = financingPurposeFields[PROJECT_FINANCING_LOT_SIZE];
  const propGarage = financingPurposeFields[PROJECT_FINANCING__NUMBER_OF_GARAGES];
  const isPropGarage = spacePlot + propGarage > 0 ? true : false;

  const { toBoolean } = fieldParsers;
  const controlObjectData = {
    address: financingPurposeFields[PROJECT_FINANCING_OBJECT_ADDRESS],
    zip_code: financingPurposeFields[PROJECT_FINANCING_OBJECT_ZIP_CODE],
    object_location: financingPurposeFields[PROJECT_FINANCING_OBJECT_CITY],
    space_plot: convertToDecimalString(spacePlot),
    year_of_construction: financingPurposeFields[PROJECT_FINANCING__BUILDING_YEAR],
    quality_furnishings: findQualityValue(
      financingPurposeFields[PROJECT_FINANCING__FURNISHING_QUALITY],
      qualityFurnishingList,
    ),
    quality_condition: findQualityValue(
      financingPurposeFields[PROJECT_FINANCING__QUALITY_CONDITION],
      qualityConditionList,
    ),
    year_of_modernization: financingPurposeFields[PROJECT_FINANCING__MODERNIZATION_YEAR],
    prop_elevator: toBoolean(financingPurposeFields[PROJECT_FINANCING__LIFT]),
    prop_balcony_terrace: toBoolean(financingPurposeFields[PROJECT_FINANCING__BALKONY]),
    prop_additional_bathroom: toBoolean(financingPurposeFields[PROJECT_FINANCING__GUEST_BATHROOM]),
    prop_garage: isPropGarage,
    prop_cellar: toBoolean(financingPurposeFields[PROJECT_FINANCING__CELLAR]),
    discount_safety_lending: '15.0',
    level_significance: '5.0',
    object_id: '',
  };

  return {
    control_object_data: controlObjectData,
    future_usage: futureUsage,
    current_usage: currentUsage,
  };
};
