import { mittweidaFields } from 'mittweida/inquiry/fields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateRoleSection: ValidateSectionFunction = (form, values) => {
  return validateFieldValidationMap({ validationMap: roleSectionValidationMap, form, values });
};

export const roleSectionValidationMap = {
  [mittweidaFields.financingNeedPage.roleSection.projectFinancingRole]: fieldValidators
    .string()
    .required(),
};
