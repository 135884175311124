import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import {
  addInternalCommentAction,
  getInternalCommentsAction,
} from 'api/CompeonReverseApi/operation/internalComments/actions';
import Spinner from 'components/Spinner';
import { ErrorText } from 'components/Text';
import PERMISSIONS from 'constants/user/permissions';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledHeading } from '../../styles';
import Form from './Form';
import InternalCommentsItem from './Item';
import mapFromApiInterface from './service';

const InternalComments = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();

  const [internalComments, setInternalComments] = useState([]);
  const [isFetchingComments, setIsFetchingComments] = useState(true);
  const [isSendingNewComment, setIsSendingNewComment] = useState(false);
  const [error, setError] = useState(null);

  const t = useTranslations('pages.inquiryDetails.dashboard.communication.internalComments');
  const { success } = useToasts();

  const getInternalComments = useCallback(async () => {
    setIsFetchingComments(true);
    const { error: isError, payload } = await dispatch(getInternalCommentsAction(inquiryId));

    if (!isError) {
      const entries = _get(payload, 'data');
      const mappedEntries = mapFromApiInterface(entries);

      setInternalComments(mappedEntries);
    }
    setIsFetchingComments(false);
  }, [dispatch, inquiryId]);

  useEffect(() => {
    getInternalComments();
  }, [getInternalComments]);

  async function addInternalComment({ internalComment }) {
    setError(null);
    setIsSendingNewComment(true);
    const response = await dispatch(addInternalCommentAction(inquiryId, internalComment));

    if (response.error) {
      setError(response.error.message);
    } else {
      getInternalComments();
      success({ description: t('commentAdded') });
    }
    setIsSendingNewComment(false);
  }

  if (isFetchingComments) {
    return <Spinner />;
  }

  return (
    <Box
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: { base: '-24px', md: '0' },
        right: { base: '0', md: 'initial' },
        bottom: { base: undefined, md: '0' },
        left: { base: '0', md: '-24px' },
        width: { base: undefined, md: '1px' },
        height: { base: '1px', md: 'initial' },
        backgroundColor: 'background.darkGrey',
      }}
    >
      <StyledHeading data-testid={'Comment-Internal'}>{t('title')}</StyledHeading>

      {internalComments.map(({ id, user, createdAt, content }) => (
        <InternalCommentsItem
          key={id}
          firstName={user.firstName}
          lastName={user.lastName}
          date={createdAt}
          comment={content}
        />
      ))}
      <Form onSubmit={addInternalComment} isSendButtonDisabled={isSendingNewComment} />
      {error && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.COMMUNICATION.INTERNAL_COMMENTS),
)(InternalComments);
