import * as React from 'react';

import StaticField from 'components/StaticField';
import { SummaryPageField } from 'new/summary/hooks/useBuildFieldsForSummary';

type Props = {
  summaryPageField: SummaryPageField;
};
export const SummaryField = ({ summaryPageField }: Props) => {
  return <StaticField caption={summaryPageField.caption} text={summaryPageField.text} />;
};
