import React from 'react';

import { Box, Divider, Grid, GridItem } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import { useToasts } from 'shared/hooks/useToasts';

import { API_METHODS, callReverseApi } from '../../../api/apiService';
import endpoints from '../../../api/CompeonReverseApi/endpoints';
import ButtonComponent from '../../../theme/components/Button';
import { HeadingComponent } from '../../../theme/components/Heading';
import ArrowRightIcon from '../../../theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from '../../../theme/components/Modal';
import { TextComponent } from '../../../theme/components/Text';
import useDispatchApiCall from '../../../utils/hooks/useDispatchApiCallHook';
import { useTranslations } from '../../../utils/hooks/useTranslations';
import { CheckboxWithField } from '../../Checkbox';
import { DocumentProps } from '../hooks/useDocumentValidation';

type ComponentType = 'digitization' | 'analysis';

interface MapValues {
  translationKey: string;
  requestUrl: any;
}

interface Props {
  isOpen: boolean;
  onClose: () => any;
  type: ComponentType;
  files: DocumentProps[];
}

const ComponentTypeMap: Record<ComponentType, MapValues> = {
  digitization: {
    translationKey: 'digitization',
    requestUrl: endpoints.FILES.MARK_FOR_DIGITIZATION,
  },
  analysis: {
    translationKey: 'analysis',
    requestUrl: endpoints.FILES.MARK_FOR_KPI_CALCULATION,
  },
};

const validateFileSelection = (values: { [key: string]: boolean }) => {
  let hasValidObject = false;

  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (!hasValidObject) {
      if (value) {
        hasValidObject = true;
      }
    }
  });

  if (hasValidObject) {
    return {};
  }

  return {
    error: true,
  };
};

const requestDocument = ({ id, requestUrl }: { id: string; requestUrl: any }) => {
  return callReverseApi({ url: requestUrl.compose({ params: { id } }), method: API_METHODS.POST });
};

const useRequestDocumentDigitization = (type: ComponentType) => {
  const mapValues = ComponentTypeMap[type];

  const t = useTranslations(
    `pages.inquiryDetails.dashboard.actions.documentDigitizationAndAnalysis.${mapValues.translationKey}`,
  );
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: t('fileChooser.error'),
    isPendingInitially: false,
    showErrorNotification: true,
  });
  const requestDocumentDigitization = async (id: string) => {
    const { payload, error } = await makeCall(
      requestDocument({ id, requestUrl: mapValues.requestUrl }),
    );

    return { payload, error };
  };
  return {
    isPending,
    requestDocumentDigitization,
  };
};

const FileChooserModal = ({ isOpen, onClose, type, files }: Props) => {
  const mapValues = ComponentTypeMap[type];

  const { success } = useToasts();
  const t = useTranslations(
    `pages.inquiryDetails.dashboard.actions.documentDigitizationAndAnalysis.${mapValues.translationKey}`,
  );

  const onSubmit = async (values: any) => {
    const documentsReady = Object.keys(values).filter((key) => values[key]);

    for (let i = 0; i < documentsReady.length; i++) {
      await requestDocumentDigitization(documentsReady[i]);
    }
    success({ description: t('fileChooser.success') });
    onClose();
  };

  const { isPending, requestDocumentDigitization } = useRequestDocumentDigitization(type);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateFileSelection}
      render={({ handleSubmit, submitting, pristine, values, errors }) => {
        return (
          <ModalComponent
            isOpen={isOpen}
            scrollBehavior={'inside'}
            onClose={onClose}
            size={'sm'}
            footer={
              <Footer
                submitting={submitting}
                error={errors?.error}
                onClose={onClose}
                isPending={isPending}
                pristine={pristine}
                type={type}
              />
            }
          >
            <form onSubmit={handleSubmit} id={type}>
              <Box width={'75%'} margin={'auto'} padding={'10px'}>
                <Box width={'50%'} margin={'auto'}>
                  <HeadingComponent>{t('fileChooser.headline')}</HeadingComponent>
                  <TextComponent>{t('fileChooser.summary')}</TextComponent>
                </Box>

                <Divider
                  opacity="1"
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  borderColor="border.lightGrey"
                />

                <Grid templateColumns={'1fr 3fr'}>
                  <GridItem>
                    <HeadingComponent variant={'tertiary'}>
                      {t('fileChooser.sectionHeadline')}
                    </HeadingComponent>
                  </GridItem>
                  <GridItem>
                    {files.map((file: any) => {
                      return (
                        <Box pb={2}>
                          <CheckboxWithField
                            name={file.id}
                            text={
                              <Box color={'text.primary'} textAlign={'left'}>
                                {file.fileName}
                              </Box>
                            }
                          />
                        </Box>
                      );
                    })}
                  </GridItem>
                </Grid>
                <Divider
                  opacity="1"
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  borderColor="border.lightGrey"
                />
              </Box>
            </form>
          </ModalComponent>
        );
      }}
    />
  );
};

interface FooterProps {
  onClose: () => any;
  submitting: boolean;
  pristine: boolean;
  error: boolean;
  isPending: boolean | undefined;
  type: 'digitization' | 'analysis';
}

const Footer = ({ onClose, pristine, submitting, error, isPending, type }: FooterProps) => {
  const mapValues = ComponentTypeMap[type];

  const t = useTranslations(
    `pages.inquiryDetails.dashboard.actions.documentDigitizationAndAnalysis.${mapValues.translationKey}`,
  );

  return (
    <>
      <ButtonComponent variant={'tertiary'} mr={2} onClick={onClose}>
        {t('fileChooser.cancel')}
      </ButtonComponent>

      <ButtonComponent
        leftIcon={<ArrowRightIcon boxSize={6} d={'block'} />}
        type={'submit'}
        form={type}
        disabled={submitting || pristine || error}
        isLoading={isPending}
      >
        {t('fileChooser.submit')}
      </ButtonComponent>
    </>
  );
};

export default FileChooserModal;
