import React from 'react';

import { bool, func, string, object } from 'prop-types';
import NumberFormat from 'react-number-format';

import InputWrapper from './InputWrapper';

export const removeMinuses = (value) => value.replace('-', '');

const MaskedInput = ({
  label,
  onChange,
  placeholder,
  onBlur,
  onFocus,
  separateThousands,
  sideSymbol,
  value,
  disabled,
  valid,
  autoComplete,
  'data-name': dataName,
  decimalProps,
  name,
}) => {
  const handleChange = ({ floatValue, value: integer }) => {
    if (decimalProps?.decimalSeparator) {
      onChange(Math.abs(floatValue));
    } else {
      onChange(removeMinuses(integer));
    }
  };
  return (
    <InputWrapper sideSymbol={sideSymbol} valid={valid} disabled={disabled}>
      <NumberFormat
        name={name}
        data-testid={dataName}
        value={typeof value === 'object' ? value.value : value}
        thousandSeparator={separateThousands ? ' ' : ''}
        onValueChange={handleChange}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        onKeyPress={(e) => {
          if (['Enter', '-'].includes(e.key)) e.preventDefault();
        }}
        autoComplete={autoComplete}
        aria-label={label}
        data-name={dataName}
        {...decimalProps}
      />
    </InputWrapper>
  );
};

MaskedInput.propTypes = {
  label: string,
  onChange: func,
  placeholder: string,
  onBlur: func,
  onFocus: func,
  separateThousands: bool,
  sideSymbol: func,
  value: string,
  disabled: bool,
  valid: bool,
  autoComplete: string,
  'data-name': string,
  decimalProps: object,
  name: string,
};

MaskedInput.defaultProps = {
  label: null,
  onChange: () => null,
  placeholder: null,
  onBlur: () => null,
  onFocus: () => null,
  separateThousands: false,
  sideSymbol: () => null,
  value: '',
  disabled: false,
  valid: false,
  autoComplete: 'off',
  'data-name': undefined,
  decimal: {},
  name: null,
};

export default MaskedInput;
