import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import Separator from 'components/FormRow/Separator';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { useFormConfig } from 'config/formConfig/hooks';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_BORROWED_CAPITAL,
} from 'modules/Inquiry/Form/formFields';
import BorrowedCapital from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BorrowedCapital';
import BuildingSourceOfFundsSum from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BuildingSourceOfFunds';
import BuildingUseOfFundsSum from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BuildingUseOfFundsSum';
import {
  StyledFinancingPlanHeadline as Headline,
  SmallRow,
  StyledCalculationTable,
  StyledTableOfUse,
  StyledTableOfSource,
  StyledSumOfUse,
  StyledSumOfSource,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { getInquiryDraftIdSelector } from 'store/inquiryProcess/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetFieldNumericValue } from './fields/useGetFieldNumericValue';

export const validateBuildingFinancingPlanSection = validateSectionFields([
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
]);

const BuildingFinancingPlanSection: React.FC = () => {
  const t = useTranslations();
  const draftId = useSelector(getInquiryDraftIdSelector);

  const { batch, change } = useForm();
  const fieldController = FormFieldController.instance;

  const debtCapitalOther = useGetFieldNumericValue(BUILDING_DEBT_CAPITAL_OTHER);
  const constructionCosts = useGetFieldNumericValue(BUILDING_CONSTRUCTION_MANUFACTURE_COST);
  const debtRedemption = useGetFieldNumericValue(BUILDING_DEBT_REDEMPTION);
  const equity = useGetFieldNumericValue(BUILDING_EQUITY);
  const personalContribution = useGetFieldNumericValue(BUILDING_PERSONAL_CONTRIBUTION);
  const siteCost = useGetFieldNumericValue(BUILDING_PROPERTY);
  const { selectedInquiryType } = useFormConfig();
  const isDefaultTypeInquiry = selectedInquiryType === InquiryType.default;

  useEffect(() => {
    if (!draftId) {
      batch(() => {
        change(BUILDING_PURCHASE_PRICE, 0);
        change(BUILDING_PLOT_PRICE, 0);
        change(BUILDING_ADDITIONAL_COSTS, 0);
        change(BUILDING_CONSTRUCTION_MANUFACTURE_COST, constructionCosts);
        change(BUILDING_MODERNISATION_COSTS, 0);
        change(BUILDING_DEBT_REDEMPTION, debtRedemption);
        change(BUILDING_EQUITY, equity);
        change(BUILDING_PERSONAL_CONTRIBUTION, personalContribution);
        change(BUILDING_PROPERTY, siteCost);
        change(BUILDING_DEBT_CAPITAL_OTHER, debtCapitalOther);
      });

      fieldController.saveBulk({
        [BUILDING_PURCHASE_PRICE]: 0,
        [BUILDING_PLOT_PRICE]: 0,
        [BUILDING_ADDITIONAL_COSTS]: 0,
        [BUILDING_CONSTRUCTION_MANUFACTURE_COST]: constructionCosts,
        [BUILDING_MODERNISATION_COSTS]: 0,
        [BUILDING_DEBT_REDEMPTION]: debtRedemption,
        [BUILDING_EQUITY]: equity,
        [BUILDING_PERSONAL_CONTRIBUTION]: personalContribution,
        [BUILDING_PROPERTY]: siteCost,
        [BUILDING_DEBT_CAPITAL_OTHER]: debtCapitalOther,
      });
    }
  }, [
    fieldController,
    batch,
    change,
    draftId,
    debtCapitalOther,
    constructionCosts,
    debtRedemption,
    equity,
    personalContribution,
    siteCost,
  ]);

  return (
    <FormSection
      name={FormSections.buildingFinancingPlan}
      title={t('pages.financingNeed.sections.buildingFinancingPlan.title')}
      sectionNumber={isDefaultTypeInquiry ? 3 : 1}
    >
      <StyledCalculationTable>
        <StyledTableOfUse>
          <Headline>{t('pages.financingNeed.sections.buildingFinancingPlan.useOfFunds')}</Headline>
          <Separator small />
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PURCHASE_PRICE} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PLOT_PRICE} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_ADDITIONAL_COSTS} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField
              type="number"
              name={BUILDING_CONSTRUCTION_MANUFACTURE_COST}
              initial={0}
            />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_MODERNISATION_COSTS} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_DEBT_REDEMPTION} initial={0} />
          </SmallRow>
        </StyledTableOfUse>

        <StyledTableOfSource>
          <Headline>
            {t('pages.financingNeed.sections.buildingFinancingPlan.sourceOfFunds')}
          </Headline>
          <Separator small />
          <SmallRow>
            <InputWithField type="number" name={BUILDING_EQUITY} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PERSONAL_CONTRIBUTION} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PROPERTY} initial={0} />
          </SmallRow>
          <SmallRow>
            <BorrowedCapital />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_DEBT_CAPITAL_OTHER} initial={0} />
          </SmallRow>
        </StyledTableOfSource>

        <StyledSumOfUse>
          <SmallRow>
            <Separator />
            <BuildingUseOfFundsSum />
          </SmallRow>
        </StyledSumOfUse>

        <StyledSumOfSource>
          <SmallRow>
            <Separator />
            <BuildingSourceOfFundsSum />
          </SmallRow>
        </StyledSumOfSource>
      </StyledCalculationTable>
    </FormSection>
  );
};

export default React.memo(BuildingFinancingPlanSection);
