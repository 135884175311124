import * as React from 'react';

import { useSelector } from 'react-redux';

import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { InputConditionsAction } from 'pages/operationPortal/InquiryDetails/Dashboard/NonBindingOffer/InputConditionsAction';
import { ShowConditionsAction } from 'pages/operationPortal/InquiryDetails/Dashboard/NonBindingOffer/ShowConditionsAction';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import { ProgressSection } from 'theme/components/ProgressSection';

type NonBindingOffer = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl: string;
  id?: string;
};

export const NonBindingOfferStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: NonBindingOffer) => {
  const lane = useSelector(getInquiryLane) as InquiryLane;
  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      {!isCompleted && lane === InquiryLane.lead ? (
        <InputConditionsAction id={id} actionUrl={actionUrl} />
      ) : (
        <ShowConditionsAction id={id} />
      )}
    </ProgressSection>
  );
};
