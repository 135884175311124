import { PARTNERS } from 'constants/partner';
import { ReportType } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { Observable } from 'utils/Observable';

export interface PartnerDetails {
  id?: PARTNERS;
  fullName?: string;
  contactPerson?: string;
  phoneNumber?: string;
  city?: string;
  postalCode?: string;
  street?: string;
  email?: string;
}

export enum LANGUAGE_TYPE {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export interface PartnerMeta {
  pageTitle?: string;
  logoUrl?: string;
  faviconUrl?: string;
  bankCodes?: string[];
  chatToken?: string;
  widgetId?: string;
  enableTranslation?: boolean;
  gtmId?: string;
  webtrekkId?: string;
  etrackerId?: string;
  webtrekkDomain?: string;
  internalRatings?: string[];
  excludingQuestionCount?: number;
  color?: string;
  language?: string;
  creditReformProducts?: ReportType[];
  translation?: {
    [key in LANGUAGE_TYPE]?: object;
  };
}

export interface PartnerCopy {
  copyright?: string;
  termsAndConditions?: string;
  imprint?: string;
  dataProtection?: string;
}

export interface PartnerConfig {
  details: PartnerDetails;
  meta: PartnerMeta;
  copy: PartnerCopy;
}

export interface PartnerConfigSaveOptions {
  force?: boolean;
}

export interface IPartnerRepo extends Observable<PartnerConfig> {
  save(config: PartnerConfig, options?: PartnerConfigSaveOptions): void;
  getConfig(): PartnerConfig;
  clear(): void;
}
