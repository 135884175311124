import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import paths from 'constants/paths';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import {
  probabilityLendingValueTranslationMapping,
  PROFITABILITY_LENDING_VALUE_DETERMINATION,
  PROFITABILITY_LENDING_VALUE_DETERMINATION_HEADERS,
} from '../../../types';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';
import { orderMapping } from '../../utils';

const StyledHeading = styled.h3`
  margin-bottom: 1.75rem;
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.5;
`;

export type LendingValueType = {
  [key: string]: {
    ree_values_for_building: number;
    safety_markdown: number;
    value: number;
  };
};

interface IProps {
  data: LendingValueType;
}

const LendingValuesDetermination: React.FC<IProps> = ({ data }: IProps) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { lendingValues } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const { onEdit } = useEditFormConfig({
    pathToEdit: lendingValues(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION,
    loadData: loadEditProfitabilitySection,
    editFormStructure:
      mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION](),
  });
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;

  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.lendingValueDetermination',
  );

  const headers = [
    {
      key: 'type',
      text: t('tableHeaders.type'),
    },
    {
      key: PROFITABILITY_LENDING_VALUE_DETERMINATION_HEADERS.REE_VALUES,
      text: t('tableHeaders.reeValues'),
    },
    {
      key: PROFITABILITY_LENDING_VALUE_DETERMINATION_HEADERS.SECURITY_MARKDOWN,
      text: t('tableHeaders.safetyMarkdown'),
    },
    {
      key: PROFITABILITY_LENDING_VALUE_DETERMINATION_HEADERS.VALUE,
      text: t('tableHeaders.value'),
    },
  ];

  const orderedData: LendingValueType = orderMapping<LendingValueType>(
    data,
    probabilityLendingValueTranslationMapping,
  );

  const highlightedRows = [
    `${PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_VALUATION_APPROACH_TANGIBLE_ASSETS}`,
    `${PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_VALUE}`,
    `${PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_RESIDENTIAL}`,
    `${PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_COMMERCIAL}`,
    `${PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_MIXED_USED}`,
  ];

  const rows =
    orderedData &&
    Object.keys(orderedData).map((item: string) => ({
      id: item,
      type: t(`tableRows.${probabilityLendingValueTranslationMapping[item]}`),
      ree_values_for_building: formatColumnValues(orderedData[item]?.ree_values_for_building),
      safety_markdown: formatColumnValues(orderedData[item]?.safety_markdown),
      value: formatColumnValues(orderedData[item]?.value),
      ...(highlightedRows.includes(item) && { rowHighlight: true, valueBold: true }),
    }));

  return (
    <>
      <StyledHeading>{t('sectionHeader')}</StyledHeading>
      <AccordionComponent headingLevel="h3" mb={8} title={t('accordianTitle')} defaultIndex={[0]}>
        <TableCardWithEdit
          onEdit={onEdit}
          canEdit={canEdit}
          tableHeaders={headers}
          tableData={rows}
          componentName={PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION}
        />
      </AccordionComponent>
    </>
  );
};

export default LendingValuesDetermination;
