import { useSelector } from 'react-redux';

import { getAllKpis, getFiscalYearStartsAt } from '../../../../store/kpi/kpis.selectors';
import { useDatePickerContext } from '../context/DatePickerContext';
import { useKpiFilter } from './useKpiFilter';

const aktivaKpis = [
  'AV',
  'IMMV',
  'SA',
  'FA',
  'UV',
  'VORR',
  'FORDLUL',
  'SONSTVW',
  'SONSTWP',
  'KUB',
  'ARAP',
  'AKTLATST',
  'SONSTA',
  'BSA',
];
const passivaKpis = [
  'EK',
  'GK',
  'KR',
  'GV',
  'SOPO',
  'RKST',
  'VB',
  'VBVERZ',
  'VBLUL',
  'SONSTVB',
  'PRAP',
  'PASSLATST',
  'BSP',
];

export const useBalanceSheetData = () => {
  const information = useSelector(getAllKpis);

  const { date } = useDatePickerContext();

  const selectedYear = date.getFullYear().toString();
  const fiscalYearStartsAt = useSelector(getFiscalYearStartsAt);
  const month = new Date(fiscalYearStartsAt).getMonth();
  let selectedMonth = '12';

  if (month !== 0) {
    selectedMonth = month.toString();
  }

  const aktivaCurrentYearData = useKpiFilter({
    information,
    selectedMonth,
    selectedYear,
    selectedKpis: aktivaKpis,
  });

  const aktivaPreviousYearData = useKpiFilter({
    information,
    selectedMonth,
    selectedYear: (parseInt(selectedYear) - 1).toString(),
    selectedKpis: aktivaKpis,
  });
  const passivaCurrentYearData = useKpiFilter({
    information,
    selectedMonth,
    selectedYear,
    selectedKpis: passivaKpis,
  });
  const passivaPreviousYearData = useKpiFilter({
    information,
    selectedMonth,
    selectedYear: (parseInt(selectedYear) - 1).toString(),
    selectedKpis: passivaKpis,
  });

  if (
    aktivaCurrentYearData &&
    aktivaPreviousYearData &&
    passivaCurrentYearData &&
    passivaPreviousYearData
  ) {
    return {
      aktivaCurrentYearData,
      aktivaPreviousYearData,
      passivaCurrentYearData,
      passivaPreviousYearData,
    };
  }
  return undefined;
};
