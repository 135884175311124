import React, { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import PATHS from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fetchInquiryAction, FeToBeMapping } from 'modules/InquiryDetails/InquiryDetails.service';
import useInquiryReset from 'modules/InquiryFlowReset/useInquiryReset';
import { usePortalConfig } from 'new/portals/state/portalConfigContext/hooks';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  mapInquiryDetailsApiResponseAction,
  mapInquiryEditApiResponseAction,
} from 'store/inquiryDetails/actions';
import {
  getInquiryDetailsSelector,
  getInquiryDetailsEditSelector,
} from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  children: React.ReactNode;
  inquiryId: string;
};

const FetchInquiryDetails = ({ children, inquiryId }: Props) => {
  const t = useTranslations();
  const history = useHistory();
  const dispatch = useDispatch();
  const { makeCall, isPending } = useDispatchApiCall({ errorMessage: t('errors.unknownInquiry') });
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const inquiryDetailsForEdit = useSelector(getInquiryDetailsEditSelector);
  const { portalConfig } = usePortalConfig();
  const feToBeMappingKeys = portalConfig?.feToBeMappingKeys as FeToBeMapping;

  // TODO: Need to change default form-type name to avoid future confusion
  useSelectedInquiryTypeSpecificValue({
    [InquiryType.dvag]: useInquiryReset,
    [InquiryType.bigPicture]: () => {},
    [InquiryType.onlinefactoring]: () => {},
    [InquiryType.leaseplan]: () => {},
    [InquiryType.bfs]: () => {},
    [InquiryType.profiRLL]: () => {},
    [InquiryType.bfsService]: () => {},
    default: useInquiryReset,
  })();

  const fetchData = useCallback(
    async (id) => {
      const { error, payload } = await makeCall(fetchInquiryAction(id));
      if (error) {
        history.push(PATHS.financingNeed);
      } else {
        dispatch(mapInquiryDetailsApiResponseAction(payload.data, feToBeMappingKeys));
        dispatch(mapInquiryEditApiResponseAction(payload.data));
      }
    },
    [dispatch, history, makeCall, feToBeMappingKeys],
  );

  useEffect(() => {
    fetchData(inquiryId);
  }, [fetchData, inquiryId]);
  if (isPending || !inquiryDetails || !inquiryDetailsForEdit) {
    return (
      <SpinnerWrapper data-testid="spinner">
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return <>{children}</>;
};

export default FetchInquiryDetails;
