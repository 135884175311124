import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PrivateRoute from 'components/PrivateRoute';
import paths from 'constants/paths';
import { isCustomerSelector } from 'store/user/selectors';

const { customer: customerPaths } = paths;

const CustomerPrivateRoute = ({ isAuthorized, ...rest }) => {
  return <PrivateRoute isAuthorized={isAuthorized} authPath={customerPaths.login} {...rest} />;
};

CustomerPrivateRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  isAuthorized: isCustomerSelector(store),
});

export default connect(mapStateToProps)(CustomerPrivateRoute);
