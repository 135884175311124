import React from 'react';

import FormRow from 'components/FormRow';
import LoanDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanDescription';
import LoanInterestPeriodInMonths from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanInterestPeriodInMonths/LoanInterestPeriodInMonths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import DvagAnnuityLoan from './DvagAnnuityLoan';

const AnnuityLoan = () => (
  <>
    <FormRow separator>
      <LoanInterestPeriodInMonths />
    </FormRow>

    <FormRow>
      <LoanDescription />
    </FormRow>
  </>
);

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagAnnuityLoan,
  default: AnnuityLoan,
});
