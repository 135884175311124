import React from 'react';

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { RadioGroupWithField } from 'components/RadioGroup';
import { TextAreaWithField } from 'components/TextArea';
import paths from 'constants/paths';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { usePropertyProfile } from '../../hooks/usePropertyProfile';
import { getPropertyProfileNotes } from '../../store/selectors';
import type { FinancingRole, UsageType } from '../../types';
import { UploadObjectImage } from './UploadObjectImages';

export interface PropertyProfileValues {
  usageType: UsageType;
  financingRoleStockholder: boolean;
  financingRolePartition: boolean;
  financingRoleDeveloper: boolean;
  notesOnCustomer: string;
  descriptionOfFinancingProject: string;
  notesOnObject: string;
  notesOnFinancingPlan: string;
  notesOnFinancingParameters: string;
  notesOnDebtService: string;
  notesOnFinancingCriteria: string;
  notesOnLendingValueRemainingRisk: string;
}

const financingRoleMap: Record<
  keyof Pick<
    PropertyProfileValues,
    'financingRoleDeveloper' | 'financingRolePartition' | 'financingRoleStockholder'
  >,
  FinancingRole
> = {
  financingRoleStockholder: 'stockholder',
  financingRolePartition: 'partition',
  financingRoleDeveloper: 'developer',
};

export const PropertyProfile = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile');

  const initialValues = useSelector(getPropertyProfileNotes);

  const [isDownloadPending, setIsDownloadPending] = React.useState(false);
  const history = useHistory();
  const { dashboard } = paths.operation.inquiryDetails;

  const { error, success, info } = useToasts();
  const { updateValues, downloadPdf } = usePropertyProfile();

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  const handleCancel = () => {
    history.push(dashboard.replace(':id', inquiryId));
  };

  const handleUpdateValues = async (values: PropertyProfileValues) => {
    await updateValues(values);
    success({ description: t('update.success') });
  };

  const handleDownloadPdf = async (values: PropertyProfileValues) => {
    const financingRoles = Object.entries(financingRoleMap)
      .map(([key, value]) => (values[key as keyof PropertyProfileValues] ? value : null))
      .filter(Boolean);
    if (financingRoles.length) info({ description: t('download.started') });
    else error({ description: t('download.noRoleSelectedError') });

    setIsDownloadPending(true);
    await updateValues(values);
    await Promise.all(financingRoles.map((role) => downloadPdf(role!, values.usageType)));
    success({ description: t('download.finished') });
    setIsDownloadPending(false);
  };

  return (
    <>
      <Box paddingLeft={{ lg: 80 }} paddingBottom={8}>
        <HeadingComponent variant="primary">{t('title')}</HeadingComponent>
        <HeadingComponent variant="secondary" color="text.tertiary">
          {t('heading')}
        </HeadingComponent>
      </Box>
      <Form<PropertyProfileValues>
        onSubmit={handleUpdateValues}
        initialValues={{ usageType: 'future', ...initialValues }}
        render={({ values, handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <VStack align="stretch">
              <FormSection title={t('sections.customer.title')} sectionNumber={1}>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnCustomer"
                    caption={t('sections.customer.fields.notesOnCustomer.caption')}
                    placeholder={t('sections.customer.fields.notesOnCustomer.placeholder')}
                  />
                </FormRow>
              </FormSection>
              <FormSection title={t('sections.object.title')} sectionNumber={2}>
                <FormRow>
                  <TextAreaWithField
                    name="descriptionOfFinancingProject"
                    caption={t('sections.object.fields.descriptionOfFinancingProject.caption')}
                    placeholder={t(
                      'sections.object.fields.descriptionOfFinancingProject.placeholder',
                    )}
                  />
                </FormRow>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnObject"
                    caption={t('sections.object.fields.notesOnObject.caption')}
                    placeholder={t('sections.object.fields.notesOnObject.placeholder')}
                  />
                </FormRow>
              </FormSection>
              <FormSection title={t('sections.funding.title')} sectionNumber={3}>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnFinancingPlan"
                    caption={t('sections.funding.fields.notesOnFinancingPlan.caption')}
                    placeholder={t('sections.funding.fields.notesOnFinancingPlan.placeholder')}
                  />
                </FormRow>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnFinancingParameters"
                    caption={t('sections.funding.fields.notesOnFinancingParameters.caption')}
                    placeholder={t(
                      'sections.funding.fields.notesOnFinancingParameters.placeholder',
                    )}
                  />
                </FormRow>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnDebtService"
                    caption={t('sections.funding.fields.notesOnDebtService.caption')}
                    placeholder={t('sections.funding.fields.notesOnDebtService.placeholder')}
                  />
                </FormRow>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnFinancingCriteria"
                    caption={t('sections.funding.fields.notesOnFinancingCriteria.caption')}
                    placeholder={t('sections.funding.fields.notesOnFinancingCriteria.placeholder')}
                  />
                </FormRow>
                <FormRow>
                  <TextAreaWithField
                    name="notesOnLendingValueRemainingRisk"
                    caption={t('sections.funding.fields.notesOnLendingValueRemainingRisk.caption')}
                    placeholder={t(
                      'sections.funding.fields.notesOnLendingValueRemainingRisk.placeholder',
                    )}
                  />
                </FormRow>
              </FormSection>
              <FormSection title={t('sections.upload.title')} sectionNumber={4}>
                <UploadObjectImage
                  title={t('sections.upload.fields.objectPhotoCurrent.title')}
                  description={t('sections.upload.fields.objectPhotoCurrent.description')}
                  imageType={'object_photo_current'}
                />
                <UploadObjectImage
                  title={t('sections.upload.fields.objectVisualization.title')}
                  description={t('sections.upload.fields.objectVisualization.description')}
                  imageType={'object_visualization'}
                />
              </FormSection>
              <FormSection title={t('sections.profile.title')} sectionNumber={5}>
                <VStack spacing={12} alignItems="start">
                  <RadioGroupWithField
                    name="usageType"
                    caption={t('sections.profile.selectUsageType')}
                    options={[
                      { value: 'future', caption: t('sections.profile.usageTypeFuture') },
                      { value: 'current', caption: t('sections.profile.usageTypeCurrent') },
                    ]}
                  />
                  <Box>
                    <Text color="text.tertiary" textTransform="uppercase">
                      {t('sections.profile.selectFinancingRole')}
                    </Text>
                    <HStack width="full" spacing={24} marginTop={5}>
                      <CheckboxWithField
                        name="financingRoleDeveloper"
                        text={t('sections.profile.financingRoleDeveloper')}
                      />
                      <CheckboxWithField
                        name="financingRolePartition"
                        text={t('sections.profile.financingRolePartition')}
                      />
                      <CheckboxWithField
                        name="financingRoleStockholder"
                        text={t('sections.profile.financingRoleStockholder')}
                      />
                    </HStack>
                  </Box>
                </VStack>
              </FormSection>
            </VStack>
            <HStack justifyContent="end" spacing={12} marginTop={16}>
              <ButtonComponent onClick={handleCancel} variant="tertiary" type="button">
                {t('cancel')}
              </ButtonComponent>
              <ButtonComponent type="submit" leftIcon={<SaveIcon />} disabled={submitting}>
                {t('save')}
              </ButtonComponent>
              <ButtonComponent
                type="button"
                onClick={() => handleDownloadPdf(values)}
                disabled={isDownloadPending}
                leftIcon={<DownloadIcon />}
              >
                {t('download.button')}
              </ButtonComponent>
            </HStack>
          </form>
        )}
      />
    </>
  );
};

export default PropertyProfile;
