import React, { ReactNode } from 'react';

import { Box, Flex, useChakra, useMultiStyleConfig } from '@chakra-ui/react';

interface LoginFormContainerProps {
  login: ReactNode;
  password: ReactNode;
  forgotPassword?: ReactNode;
  actions?: ReactNode;
}
const LoginForm = ({ login, password, forgotPassword, actions }: LoginFormContainerProps) => {
  const { theme } = useChakra();
  const styles = useMultiStyleConfig('LoginForm', {});

  // using directly theme as `useProps` hook does not return variant prop
  const { defaultProps } = theme.components.LoginForm;

  if (defaultProps.variant === 'horizontal') {
    return (
      <Box __css={styles.container}>
        <Flex __css={styles.inputs}>
          <Box flex={1}>{login}</Box>
          <Box flex={1}>{password}</Box>
        </Flex>
        <Box __css={styles.actions}>
          {actions}
          {forgotPassword}
        </Box>
      </Box>
    );
  }

  return (
    <Box __css={styles.container}>
      <Flex __css={styles.inputs}>
        <Box flex={1}>{login}</Box>
        <Flex pos="relative" flex={1} flexDirection="column">
          {password}
          <Box pos="absolute" top={0} right={0}>
            {forgotPassword}
          </Box>
        </Flex>
      </Flex>
      <Box __css={styles.actions}>{actions}</Box>
    </Box>
  );
};

export default LoginForm;
