import React, { useMemo, useState, useCallback, useEffect } from 'react';

import _get from 'lodash/get';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { PasswordInputWithField } from 'components/PasswordInput';
import PortalPage from 'components/PortalPage';
import { Text, ErrorText } from 'components/Text';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { COMPANY_DETAILS_CONSENT } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import SectionHeader from 'modules/SectionHeader/SectionHeader';
import TermAndConditions from 'pages/inquiryFlow/CompanyDetails/sections/TermAndConditionsField';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { usePartnerSpecificValidators } from 'utils/hooks/usePartnerSpecificValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getObjectFromQueryParamsString } from 'utils/paths';
import { combineValidators, required, equalTo } from 'utils/validators';

import { acceptInvitationAction, validateInvitationTokenAction } from './service';
import { StyledFieldWrapper } from './styles';

const CONFIRM_PASSWORD_FIELD = 'confirmPassword';
const PASSWORD_FIELD = 'password';

interface Values {
  password: string;
  [COMPANY_DETAILS_CONSENT]?: boolean;
}

const FinishRegistration = () => {
  const history = useHistory();
  const t = useTranslations('pages.finishRegistration');
  const tMain = useTranslations();
  const { makeCall, isPending, error } = useDispatchApiCall();
  const isActivationEnabled = useConfig(CONFIG.CUSTOMER_ACTIVATION_ON_INVITATION);
  const { selectedInquiryType } = useFormConfig();
  const partnerSpecificValidators = usePartnerSpecificValidators();

  const {
    token,
    inquiry_id: inquiryId,
    scope,
  } = useMemo(() => getObjectFromQueryParamsString(window.location.search), []);

  const [isTokenExpired, setIsTokenExpired] = useState(false);

  if (!token) {
    history.push(paths.error404);
  }

  const submitLabel = t('confirmBtn');
  const pageTitle = t('heading');
  const linkExpired = t('linkExpired');

  const isOperationPortal = useIsUnderOperationPortalPath();

  const validateToken = useCallback(
    async (token) => {
      try {
        const { payload } = await makeCall(validateInvitationTokenAction({ token }));
        const isTokenValid = _get(payload, 'data.data.valid');

        if (!isTokenValid) {
          setIsTokenExpired(true);
        }
      } catch (err) {
        setIsTokenExpired(true);
      }
    },
    [makeCall],
  );

  useEffect(() => {
    if (token) {
      validateToken(token);
    }
  }, [token, validateToken]);

  const onSubmit = async (values: Values) => {
    let hasAcceptedTerms = !isOperationPortal ? values[COMPANY_DETAILS_CONSENT] : undefined;

    hasAcceptedTerms =
      selectedInquiryType === InquiryType.bfs && isActivationEnabled ? true : hasAcceptedTerms;

    if (token) {
      await makeCall(
        acceptInvitationAction({
          invitationToken: token,
          password: values.password,
          terms: hasAcceptedTerms,
        }),
        () => {
          if (
            (scope === 'documentExchange' || scope === 'details') &&
            typeof inquiryId === 'string'
          ) {
            history.push(paths.customer.inquiryDetails[scope].replace(':id', inquiryId));
          } else {
            history.push(isOperationPortal ? paths.operation.login : paths.customer.login);
          }
        },
      );
    }
  };

  if (!token || isTokenExpired) {
    return (
      <PortalPage pageTitle={pageTitle}>
        <Text data-testid="invitation-link-expired">{linkExpired}</Text>
      </PortalPage>
    );
  }

  return (
    <PortalPage stylesThemeKey="CustomerLoginPortalPage" biggerHeading pageTitle={t('heading')}>
      <SectionHeader
        stylesThemeKey="CustomerLoginHeading"
        leadTranslationKey="pages.finishRegistration.newPasswordRequirements"
      />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <StyledFieldWrapper>
              <PasswordInputWithField
                name={PASSWORD_FIELD}
                validate={partnerSpecificValidators}
                caption={t('passwordField.caption')}
              />
              <PasswordInputWithField
                name={CONFIRM_PASSWORD_FIELD}
                validate={combineValidators(
                  required(tMain('errors.required')),
                  equalTo(PASSWORD_FIELD, t('confirmPassword.error')),
                )}
                caption={t('confirmPassword.caption')}
              />
            </StyledFieldWrapper>
            {!isOperationPortal && (
              <StyledFieldWrapper>
                <TermAndConditions sectionNumber={1} />
              </StyledFieldWrapper>
            )}
            <ButtonComponent
              data-testid="accept-invitation-btn"
              disabled={isPending || (!isOperationPortal && !valid)}
              isLoading={isPending}
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              type="submit"
            >
              {submitLabel}
            </ButtonComponent>

            {error && <ErrorText>{error.message}</ErrorText>}
          </form>
        )}
      />
    </PortalPage>
  );
};

export default FinishRegistration;
