import React from 'react';

import styled from 'styled-components/macro';

import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

const HiddenInput = styled.input`
  display: none;
`;

const HiddenInputAdapter = ({ input, meta, ...rest }) => (
  <HiddenInput {...input} {...meta} {...rest} />
);

HiddenInputAdapter.propTypes = FinalFormFieldPropTypes;
export const HiddenInputWithField = withFormField(HiddenInputAdapter);

export default HiddenInput;
