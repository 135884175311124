import Joi, { CustomHelpers, NumberSchema, StringSchema } from '@hapi/joi';
import { isValid, isAfter, isBefore, parse } from 'date-fns';
import { isValidIBAN, isValidBIC } from 'ibantools';
import moment from 'moment';

import { MIN_PHONE_NUMBER_LENGTH, MAX_PHONE_NUMBER_LENGTH } from 'constants/globalConstants';
import { CORONA_FINANCING_OPTION__QUICK_CREDIT } from 'modules/Inquiry/Form/formFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import {
  isPostcodeRegex,
  isEmailRegex,
  isNumberRegex,
  isPhoneNumberRegex,
  phoneNumberPrefixRegex,
  isPostcodeInternationalRegex,
} from 'utils/regexes';

/*
 * Extension of joi validations
 */

interface PriceSchema extends NumberSchema {
  maxPrice(value: number): this;
  minPrice(value: number): this;
}

interface PostalSchema extends StringSchema {
  isValid(): this;
  isValidInternational(): this;
}

interface MaxLengthSchema extends StringSchema {
  number(length: number): this;
  string(length: number): this;
}

interface BankSchema extends StringSchema {
  isValidIban(): this;
  isGermanIban(): this;
  matchesSpecificBankCode(bankCode: string): this;
  isValidBic(): this;
}

interface StringBoolSchema extends StringSchema {
  mustBeTrue(): this;
}

interface PhoneNumberSchema extends StringSchema {
  isPhoneNumber(): this;
  hasPhonePrefix(): this;
  minPhoneNumberLength(minValue?: number): this;
  maxPhoneNumberLength(): this;
}

interface DateFormatSchema extends StringSchema {
  isDateFormat(): this;
  minDate(minDate: Date): this;
  maxDate(maxDate: Date): this;
}

interface YearFormatSchema extends NumberSchema {
  minYear(minYear: number): this;
  maxYear(maxYear: number): this;
}

export interface CustomValidations extends Joi.Root {
  companyTaxNumber(): unknown;
  price: () => PriceSchema;
  postalCode: () => PostalSchema;
  bank: () => BankSchema;
  stringBoolean: () => StringBoolSchema;
  maxLength: () => MaxLengthSchema;
  phoneNumber: () => PhoneNumberSchema;
  dateFormat: () => DateFormatSchema;
  yearFormat: () => YearFormatSchema;
}

export const fieldValidators: CustomValidations = Joi.extend(
  (joi) => ({
    type: 'price',
    base: joi.number(),
    rules: {
      maxPrice: {
        method(maxValue: number) {
          return this.$_addRule({
            name: 'maxPrice',
            args: { max: maxValue },
          });
        },
        validate(value: any, helpers: CustomHelpers, args: any): any {
          if (value > args.max) {
            return helpers.error('price.max', { max: args.max });
          }
          return value;
        },
      },
      minPrice: {
        method(minValue: number) {
          return this.$_addRule({
            name: 'minPrice',
            args: { min: minValue },
          });
        },
        validate(value: any, helpers: CustomHelpers, args: any): any {
          if (value < args.min) {
            return helpers.error('price.min', { min: args.min });
          }
          return value;
        },
      },
    },
  }),
  (joi) => ({
    type: 'postalCode',
    base: joi.string(),
    rules: {
      isValid: {
        method() {
          return this.$_addRule({
            name: 'isValid',
          });
        },
        validate(value: any, helpers: any, args: any): any {
          if (isPostcodeRegex.test(value)) {
            return value;
          }
          return helpers.error('postal.valid');
        },
      },
      isValidInternational: {
        method() {
          return this.$_addRule({
            name: 'isValidInternational',
          });
        },
        validate(value: any, helpers: any): any {
          if (isPostcodeInternationalRegex.test(value)) {
            return value;
          }

          return helpers.error('postal.valid');
        },
      },
    },
  }),
  (joi) => ({
    type: 'bank',
    base: joi.string(),
    rules: {
      isValidIban: {
        method() {
          return this.$_addRule({
            name: 'isValidIban',
          });
        },
        validate(value: any, helpers: any): any {
          value = value.replace(/\s/g, '');
          if (isValidIBAN(value)) {
            return value;
          }

          return helpers.error('bank.iban.invalid');
        },
      },
      isGermanIban: {
        method() {
          return this.$_addRule({
            name: 'isGermanIban',
          });
        },
        validate(value: any, helpers: any): any {
          if (value.startsWith('DE')) {
            return value;
          }

          return helpers.error('bank.iban.notGermanIban');
        },
      },
      matchesSpecificBankCode: {
        method(bankCode: string) {
          return this.$_addRule({
            name: 'matchesSpecificBankCode',
            args: { bankCode },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          const bankCode = value.substring(4, 12);
          if (bankCode === args.bankCode) {
            return value;
          }

          return helpers.error('bank.iban.noMatchingBankCode');
        },
      },
      isValidBic: {
        method() {
          return this.$_addRule({
            name: 'isValidBic',
          });
        },
        validate(value: any, helpers: any): any {
          if (isValidBIC(value)) {
            return value;
          }

          return helpers.error('bank.bic.invalid');
        },
      },
    },
  }),
  (joi) => ({
    type: 'stringBoolean',
    base: joi.string(),
    rules: {
      mustBeTrue: {
        method() {
          return this.$_addRule({
            name: 'mustBeTrue',
          });
        },
        validate(value: any, helpers: any): any {
          if (value === 'true') {
            return value;
          }

          return helpers.error('stringBool.mustBeTrue');
        },
      },
    },
  }),
  (joi) => ({
    type: 'maxLength',
    base: joi.string(),
    rules: {
      number: {
        method(length: number) {
          return this.$_addRule({
            name: 'number',
            args: { length },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          if (isNumberRegex.test(value)) {
            if (value.length <= args.length) {
              return value;
            }
            return helpers.error('max.length', { length: args.length });
          }
          return helpers.error('only.numbers');
        },
      },
      string: {
        method(length: number) {
          return this.$_addRule({
            name: 'string',
            args: { length },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          if (value.length <= args.length) {
            return value;
          }
          return helpers.error('max.length', { length: args.length });
        },
      },
    },
  }),
  (joi) => ({
    type: 'phoneNumber',
    base: joi.string(),
    rules: {
      isPhoneNumber: {
        method() {
          return this.$_addRule({
            name: 'isPhoneNumber',
          });
        },
        validate(value: any, helpers: any): any {
          if (isPhoneNumberRegex.test(value)) {
            return value;
          }
          return helpers.error('phoneNumber.isPhoneNumber');
        },
      },
      hasPhonePrefix: {
        method() {
          return this.$_addRule({
            name: 'hasPhonePrefix',
          });
        },
        validate(value: any, helpers: any): any {
          if (phoneNumberPrefixRegex.test(value)) {
            return value;
          }
          return helpers.error('phoneNumber.hasPhonePrefix');
        },
      },
      minPhoneNumberLength: {
        method(minValue) {
          return this.$_addRule({
            name: 'minPhoneNumberLength',
            args: { minValue },
          });
        },
        validate(value: any, helpers: any, args = { minValue: MIN_PHONE_NUMBER_LENGTH }): any {
          if (value.length >= (args.minValue ?? MIN_PHONE_NUMBER_LENGTH)) {
            return value;
          }
          return helpers.error('phoneNumber.minLength', {
            minValue: args.minValue ?? MIN_PHONE_NUMBER_LENGTH,
          });
        },
      },
      maxPhoneNumberLength: {
        method() {
          return this.$_addRule({
            name: 'maxPhoneNumberLength',
          });
        },
        validate(value: any, helpers: any): any {
          if (value.length <= MAX_PHONE_NUMBER_LENGTH) {
            return value;
          }
          return helpers.error('phoneNumber.maxLength');
        },
      },
    },
  }),
  (joi) => ({
    type: 'dateFormat',
    base: joi.string(),
    rules: {
      isDateFormat: {
        method() {
          return this.$_addRule({
            name: 'isDateFormat',
          });
        },
        validate(value: any, helpers: any): any {
          if (isValid(parse(value, 'dd.MM.yyyy', new Date()))) {
            return value;
          }
          return helpers.error('dateFormat.isDateFormat');
        },
      },
      minDate: {
        method(minDate: Date) {
          return this.$_addRule({
            name: 'minDate',
            args: { minDate },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          const date = parse(value, 'dd.MM.yyyy', new Date());
          if (isValid(date) && isAfter(date, args.minDate)) {
            return value;
          }
          return helpers.error('dateFormat.isDateMin', { date: args.minDate });
        },
      },
      maxDate: {
        method(maxDate: Date) {
          return this.$_addRule({
            name: 'maxDate',
            args: { maxDate },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          const date = parse(value, 'dd.MM.yyyy', new Date());
          if (isValid(date) && isBefore(date, args.maxDate)) {
            return value;
          }
          return helpers.error('dateFormat.isDateMax', { date: args.maxDate });
        },
      },
    },
  }),
  (joi) => ({
    type: 'yearFormat',
    base: joi.number(),
    rules: {
      minYear: {
        method(minYear: number) {
          return this.$_addRule({
            name: 'minYear',
            args: { minYear },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          if (value >= args.minYear) {
            return value;
          }
          return helpers.error('yearFormat.isMinYear', { year: args.minYear });
        },
      },
      maxYear: {
        method(maxYear: number) {
          return this.$_addRule({
            name: 'maxYear',
            args: { maxYear },
          });
        },
        validate(value: any, helpers: any, args: any): any {
          if (value < args.maxYear) {
            return value;
          }
          return helpers.error('yearFormat.isMaxYear', { year: args.maxYear });
        },
      },
    },
  }),
);

const checkIsYoungCompany = (foundingYear: string, foundingMonth: string) => {
  const foundingYearNum = parseInt(foundingYear, 10);
  const foundingMonthNum = parseInt(foundingMonth, 10);
  const foundingDate = moment([foundingYearNum, foundingMonthNum, 1]);
  const yearsLong = Math.round(moment().diff(foundingDate, 'years', true) * 10) / 10;

  return yearsLong < InquiryConstants.COMPANY_YOUNG_THRESHOLD;
};

export const customValidations = {
  maxTwoYearsFromNow: (value: any, helpers: CustomHelpers) =>
    moment(value).isAfter(moment().add(2, 'years')) ? helpers.error('date.after2Years') : undefined,
  notHigherThanLoanTerm: (loanTermValue: number) => (value: any, helpers: CustomHelpers) =>
    value > loanTermValue ? helpers.error('custom.higherThanLoanTerm') : undefined,
  coronaFoundingMonthBeforeJan2019:
    (foundingYear: number) => (value: any, helpers: CustomHelpers) => {
      const isAbove2019 = foundingYear > InquiryConstants.CORONA_FOUNDING_YEAR_THRESHOLD;
      const isAboveJanuaryIn2019 =
        foundingYear === InquiryConstants.CORONA_FOUNDING_YEAR_THRESHOLD &&
        value > InquiryConstants.CORONA_FOUNDING_MONTH_THRESHOLD;

      if (isAbove2019 || isAboveJanuaryIn2019) {
        return helpers.error('custom.coronaFoundingMonth');
      }
      return undefined;
    },
  coronaFoundingYearBefore2019: (value: any, helpers: CustomHelpers) =>
    value > InquiryConstants.CORONA_FOUNDING_YEAR_THRESHOLD
      ? helpers.error('custom.coronaFoundingYear')
      : undefined,
  coronaViabilityTrue: (value: any, helpers: CustomHelpers) =>
    !value ? helpers.error('custom.coronaViability') : undefined,
  coronaViability2020True: (value: any, helpers: CustomHelpers) =>
    !value ? helpers.error('custom.coronaViability2020') : undefined,
  coronaEmployeesNumber:
    (foundingMonth: string, foundingYear: string) => (value: any, helpers: CustomHelpers) => {
      return checkIsYoungCompany(foundingYear, foundingMonth) &&
        value <= InquiryConstants.QUICKCREDIT_MINIMUM_EMPLOYEES_NUMBER
        ? helpers.error('custom.coronaEmployees')
        : undefined;
    },
  coronaFinancingOption:
    (foundingMonth: string, foundingYear: string) => (value: any, helpers: CustomHelpers) => {
      return checkIsYoungCompany(foundingYear, foundingMonth) &&
        value !== CORONA_FINANCING_OPTION__QUICK_CREDIT
        ? helpers.error('custom.financingOption')
        : undefined;
    },
  companyTaxNumber: (value: any, helpers: CustomHelpers) => {
    value = value.replace(/\s/g, '');
    let taxNumberValues = value.split('/').join('');
    const reg = new RegExp('^[0-9]*$');

    if (
      taxNumberValues.length < 10 ||
      taxNumberValues.length > 13 ||
      reg.test(taxNumberValues) === false
    ) {
      return helpers.error('custom.companyTaxNumber');
    }
    return undefined;
  },
  isEmail: (value: any, helpers: CustomHelpers) =>
    isEmailRegex.test(value.toLowerCase()) ? undefined : helpers.error('custom.isEmail'),
  appliedPaymentNotNegative: (value: any, helpers: CustomHelpers) =>
    value < 0 ? helpers.error('custom.appliedPaymentNotNegative') : undefined,
  requestedPaymentThreshold: (payment: number) => (value: any, helpers: CustomHelpers) => {
    return payment < InquiryConstants.FORESTRY_USAGE_LIST_REQUESTED_PAYMENT_THRESHOLD &&
      value === false
      ? helpers.error('custom.requestedPaymentThreshold')
      : undefined;
  },
  optionalNotEmpty: (baseValue: boolean | undefined) => (value: string, helpers: CustomHelpers) => {
    if (baseValue && value) {
      return undefined;
    }

    return helpers.error('custom.isNotEmpty');
  },
  expectedGrantAmount: (kindOfCompany: string) => (value: number, helpers: CustomHelpers) => {
    function isBiggerThan(formValue: number, validation: number) {
      if (formValue > validation) {
        return helpers.error('price.max', {
          max: validation,
        });
      }

      return formValue;
    }

    return isBiggerThan(value, InquiryConstants.MAX_SUM_EXPECTED_GRANT_AMOUNT);
  },
  buildingYearsRange:
    (minYear: number, maxNumberOfYears: number) => (value: any, helpers: CustomHelpers) => {
      if (Number(value) > moment().add(maxNumberOfYears, 'years').year())
        return helpers.error('custom.afterFewYears', { year: maxNumberOfYears });
      else if (Number(value) < minYear) return helpers.error('custom.olderThan1900');
      return undefined;
    },
  modernizationYearsRange: (minYear: number) => (value: any, helpers: CustomHelpers) => {
    if (Number(value) > moment().year()) return helpers.error('custom.afterCurrentYear');
    else if (Number(value) < minYear) return helpers.error('custom.olderThan1900');
    return undefined;
  },
  numberOfUnitsRange: (min: number, max: number) => (value: number, helpers: CustomHelpers) => {
    if (value < min) return helpers.error('custom.greaterThanX', { num: min });
    else if (value > max) return helpers.error('custom.lessThanX', { num: max });
    return undefined;
  },
};
