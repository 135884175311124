import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { USER_SCOPE } from 'constants/userScopes';
import type { IFile, IPrivateFile, IAssessmentFile, IInternalFile } from 'models/File.model';
import type { IFileRequest } from 'models/FileRequest.model';
import { DownloadStatusType } from 'models/types/DownloadStatus.type';
import { FileScanStatusType } from 'models/types/FileScanStatus.type';

export const changeFileStatusToDownloaded = (files: Array<IFile>, fileId: string): Array<IFile> =>
  files.map((file) =>
    file.id === fileId ? { ...file, status: DOWNLOAD_STATUS.DOWNLOADED } : file,
  );

export const removeFile = (files: Array<IFile>, fileId: string): Array<IFile> =>
  files.filter((file) => file.id !== fileId);

export const removePrivateFile = (
  files: Array<IPrivateFile>,
  fileId: string,
): Array<IPrivateFile> => files.filter((file) => file.id !== fileId);

export const removeAssessmentFile = (
  files: Array<IAssessmentFile>,
  fileId: string,
): Array<IAssessmentFile> => files.filter((file) => file.id !== fileId);

const fileRequestContainsFile = (request: IFileRequest, fileId: string): boolean =>
  request.files?.map(({ id }) => id).includes(fileId) ?? false;

export const removeFileFromRequest = (
  requests: Array<IFileRequest>,
  fileId: string,
): Array<IFileRequest> =>
  requests.map((request) =>
    fileRequestContainsFile(request, fileId)
      ? {
          ...request,
          status: request.files && request.files.length > 1 ? request.status : 'notUploaded',
          files: request.files?.filter(({ id }) => id !== fileId),
        }
      : request,
  );

export const updateFileClassification = (files: Array<IFile>, file: IFile): Array<IFile> =>
  files.map((f) => (f.id === file.id ? file : f));

export const updatePrivateFileClassification = (
  files: Array<IPrivateFile>,
  file: IPrivateFile,
): Array<IPrivateFile> => files.map((f) => (f.id === file.id ? file : f));

export const updateFileRequestFileClassification = (
  requests: Array<IFileRequest>,
  file: IFile,
  requestId?: string,
): Array<IFileRequest> =>
  requests.map((r) => {
    if (r.id === requestId) return { ...r, status: 'uploaded', files: [...(r.files ?? []), file] };
    if (fileRequestContainsFile(r, file.id))
      return {
        ...r,
        status: r.files && r.files.length > 1 ? 'uploaded' : 'notUploaded',
        files: r.files?.filter(({ id }) => id !== file.id),
      };
    return r;
  });

export interface ApiFile {
  id: string;
  type: string;
  attributes: {
    classification: string;
    status: string;
    downloaded_by_customer_at: string;
    downloaded_by_portal_user_at: string;
    digitization_requested_at: string;
    marked_for_kpi_calculation_at: string;
    was_digitized_at: string;
    uploaded_by_id: string;
    created_at: string;
    is_real_estate_expert: boolean;
    filename: string;
    uploaded_by: string;
  };
}

export const mapApiFile = (file: ApiFile): IFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    status:
      Boolean(attributes.downloaded_by_customer_at) ||
      Boolean(attributes.downloaded_by_portal_user_at)
        ? DOWNLOAD_STATUS.DOWNLOADED
        : DOWNLOAD_STATUS.NOT_DOWNLOADED,
    uploadedBy: attributes.uploaded_by as USER_SCOPE,
    createdAt: new Date(attributes.created_at),
    isRealEstateExpert: attributes.is_real_estate_expert ?? null,
    classification: attributes.classification,
  };
};

export interface ApiPrivateFile {
  id: string;
  type: string;
  attributes: {
    classification: string;
    status: string;
    created_at: string;
    filename: string;
  };
}

export const mapApiPrivateFile = (file: ApiPrivateFile): IPrivateFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    createdAt: new Date(attributes.created_at),
    classification: attributes.classification,
    status: attributes.status as DownloadStatusType | string,
  };
};

export interface ApiAssessmentFile {
  id: string;
  type: string;
  attributes: {
    status: string;
    created_at: string;
    filename: string;
  };
}

export const mapApiAssessmentFile = (file: ApiAssessmentFile): IAssessmentFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    createdAt: new Date(attributes.created_at),
    status: attributes.status as DownloadStatusType | string,
  };
};

export interface ApiInternalFile {
  id: string;
  attributes: {
    filename: string;
    download_url: string;
    content_type: string;
  };
}

export const mapApiInternalFile = (file: ApiInternalFile): IInternalFile => {
  const { id, attributes } = file;
  return {
    id,
    fileName: attributes.filename,
    downloadUrl: attributes.download_url,
    contentType: attributes.content_type,
  };
};

export interface ApiFileRequest {
  id: string;
  type: string;
  attributes: {
    classification: string | null;
    description: string;
    file_key: string;
    predefined: boolean;
    required: boolean;
    supports_reclassification: boolean;
    title: string;
  };
  relationships: { uploaded_files?: { data: Array<{ id: string; type: string }> } };
}

export const mapApiFileRequest = (
  request: ApiFileRequest,
  files?: Array<ApiFile>,
): IFileRequest => {
  const { id, attributes } = request;

  return {
    id,
    classification: attributes.classification,
    description: attributes.description,
    fileKey: attributes.file_key,
    files: files?.map(mapApiFile) ?? [],
    predefined: attributes.predefined,
    required: attributes.required,
    status: files?.length ? 'uploaded' : 'notUploaded',
    supportsReclassification: attributes.supports_reclassification,
    title: attributes.title,
  };
};

export const updateRequestWithNewFile =
  (requestId: string, file: IFile) =>
  (request: IFileRequest): IFileRequest =>
    request.id === requestId
      ? {
          ...request,
          status: 'uploaded',
          files: [...(request.files ?? []), file],
        }
      : request;
