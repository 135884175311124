import React, { useContext, useEffect } from 'react';

import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import paths from 'constants/paths';
import FormSubmitButton from 'modules/FormPage/FormSubmitButton';
import withProgressUpdater from 'modules/InquiryFormNew/hooks/withProgresUpdater';
import intlShape from 'shared/intlShape';
import {
  disableRedirectingToFirstPage as disableRedirectingToFirstPageAction,
  storeValues as storeValuesAction,
} from 'store/progress/actions';
import { ButtonComponent } from 'theme/components/Button';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { withIntl } from 'utils/intl';
import { scrollTop } from 'utils/scroll';
import saveFormStateToSessionStorage from 'utils/sessionStorage/saveFormStateToSessionStorage';

import BottomBar from './BottomBar';
import NavWrapper from './NavWrapper';
import { StyledCancelBarWrapper, StyledLink } from './styles';

export const FormContext = React.createContext({});
export const ProgressCalculationContext = React.createContext(() => {});

export const useRecalculateProgressBarOnMount = () => {
  const recalculate = useContext(ProgressCalculationContext);
  useEffect(() => {
    recalculate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export class FormPageUnwrapped extends React.Component {
  formSubscription = null;

  localStorageSubscription = null;

  constructor(props) {
    super(props);

    const { form } = props;

    this.redirectToFirstPage();

    form.resumeValidation();
  }

  componentDidMount() {
    if (this.bailOutFromLoading) {
      return;
    }

    const { form, stageName, subscriber, onFormLoad } = this.props;

    this.localStorageSubscription = form.subscribe(saveFormStateToSessionStorage(stageName), {
      dirty: true,
      values: true,
    });
    onFormLoad(form);
    subscriber(form.getState());
  }

  componentWillUnmount() {
    // unsubscribing
    if (this.formSubscription) {
      this.formSubscription();
    }
    if (this.localStorageSubscription) {
      this.localStorageSubscription();
    }
  }

  redirectToFirstPage = () => {
    const {
      history: { push: historyPush },
      ignoreRedirection,
      shouldRedirectToFirstStage,
      location: { search, pathname },
    } = this.props;
    const redirectedFromOAuth = search && pathname === paths.companyDetails;

    this.bailOutFromLoading =
      !ignoreRedirection && shouldRedirectToFirstStage && !redirectedFromOAuth;

    if (this.bailOutFromLoading) {
      historyPush(paths.financingNeed);
    }
  };

  handleSubmit = () => {
    const { form, stageName, storeValues, disableRedirectingToFirstPage } = this.props;

    disableRedirectingToFirstPage();

    storeValues({ [stageName]: form.getState().values });
    form.submit();
  };

  handleCancel = () => {
    const {
      history: { push },
      cancelLink,
    } = this.props;

    push(cancelLink);
  };

  render() {
    if (this.bailOutFromLoading) {
      return null;
    }

    const {
      intl: { formatMessage },
      children,
      form,
      backLinkUrl,
      isLoading,
      subscriber,
      continueButtonText,
      cancelLink,
      showCancelButton,
    } = this.props;

    const backLinkText = formatMessage({ id: 'buttons.back' });
    const cancelEditText = formatMessage({ id: 'buttons.cancelEdit' });
    const isEditModeOn = Boolean(cancelLink);

    return (
      <ProgressCalculationContext.Provider value={subscriber}>
        <FormContext.Provider value={form}>
          <form autoComplete="off">
            {children}
            <BottomBar>
              <NavWrapper>
                {backLinkUrl && (
                  <StyledLink to={backLinkUrl}>
                    <ButtonComponent
                      leftIcon={<ArrowLeftIcon boxSize={6} d="block" />}
                      mr={4}
                      onClick={scrollTop}
                      textDecoration="none"
                      variant="tertiary"
                    >
                      {backLinkText}
                    </ButtonComponent>
                  </StyledLink>
                )}
                <FormSubmitButton
                  onSubmit={this.handleSubmit}
                  isLoading={isLoading}
                  continueButtonText={continueButtonText}
                />
              </NavWrapper>
            </BottomBar>
            {isEditModeOn && showCancelButton && (
              <StyledCancelBarWrapper>
                <BottomBar>
                  <ButtonComponent onClick={this.handleCancel} variant="secondary">
                    {cancelEditText}
                  </ButtonComponent>
                </BottomBar>
              </StyledCancelBarWrapper>
            )}
          </form>
        </FormContext.Provider>
      </ProgressCalculationContext.Provider>
    );
  }
}

FormPageUnwrapped.propTypes = {
  form: shape({
    subscribe: func.isRequired,
  }).isRequired,
  subscriber: func,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  stageName: string,
  storeValues: func.isRequired,
  backLinkUrl: string,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({}).isRequired,
  shouldRedirectToFirstStage: bool.isRequired,
  isLoading: bool,
  disableRedirectingToFirstPage: func.isRequired,
  ignoreRedirection: bool,
  continueButtonText: string,
  intl: intlShape.isRequired,
  cancelLink: string,
  onFormLoad: func,
};

FormPageUnwrapped.defaultProps = {
  subscriber: () => null,
  stageName: null,
  backLinkUrl: null,
  ignoreRedirection: false,
  isLoading: false,
  continueButtonText: 'buttons.continue',
  cancelLink: null,
  showCancelButton: true,
  onFormLoad: () => null,
};

export default compose(
  withProgressUpdater,
  withRouter,
  withIntl,
  connect(
    ({ progress: { shouldRedirectToFirstStage, stages } }) => ({
      shouldRedirectToFirstStage,
      progressState: stages,
    }),
    {
      storeValues: storeValuesAction,
      disableRedirectingToFirstPage: disableRedirectingToFirstPageAction,
    },
  ),
)(FormPageUnwrapped);
