import {
  EXCLUDING_QUESTION_FIFTH,
  EXCLUDING_QUESTION_FIRST,
  EXCLUDING_QUESTION_FOURTH,
  EXCLUDING_QUESTION_SECOND,
  EXCLUDING_QUESTION_THIRD,
} from '../../Form/formFields';
import { InquiryFormMapper } from '../InquiryFormMapper.model';

export const mapExcludingQuestionsToApi: InquiryFormMapper = (allFields) => {
  return {
    first_excluding_question: allFields[EXCLUDING_QUESTION_FIRST],
    second_excluding_question: allFields[EXCLUDING_QUESTION_SECOND],
    third_excluding_question: allFields[EXCLUDING_QUESTION_THIRD],
    fourth_excluding_question: allFields[EXCLUDING_QUESTION_FOURTH],
    fifth_excluding_question: allFields[EXCLUDING_QUESTION_FIFTH],
  };
};
