import { mittweidaFields } from 'mittweida/inquiry/fields';

import {
  validateFields,
  ValidateSectionFunction,
} from '../../../../../../new/form/useValidateFields';
import { isLoggedInSelector } from '../../../../../../store/user/selectors';

const { password, passwordConfirmation } = mittweidaFields.userProfilePage.passwordSection;

// Validations that are dependent on other fields do not work with the current validation map flow, therefore
// we added the fieldValidators to the field directly and do not export a fieldValidationMap here
export const validatePasswordSection: ValidateSectionFunction = (form, values, store) => {
  const isLoggedIn = isLoggedInSelector(store.getState());

  if (isLoggedIn) {
    return true;
  }

  return validateFields({
    fields: [password, passwordConfirmation],
    form,
    values,
  });
};
