import React from 'react';

import { Switch } from 'react-router-dom';

import paths from 'constants/paths';
import CustomerDashboard from 'pages/customerPortal/InquiryDetails/Dashboard';
import ContractDetails from 'pages/inquiryFlow/ContractDetails/ContractDetailsStep';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';

import CustomerData from './CustomerData';
import { LeasePlanFinalPage } from './FinalPage/LeasePlanFinalPage';

export const LeasePlanForm = () => {
  return (
    <Switch>
      <CustomerPrivateRoute exact path={paths.contractDetails} component={ContractDetails} />
      <CustomerPrivateRoute exact path={paths.customerData} component={CustomerData} />
      <CustomerPrivateRoute exact path={paths.finalPage} component={LeasePlanFinalPage} />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.dashboard}
        component={CustomerDashboard}
      />
    </Switch>
  );
};
