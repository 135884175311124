import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import PortalPage from 'components/PortalPage';
import Confirmation from 'pages/RequestPasswordReset/Confirmation';
import Form from 'pages/RequestPasswordReset/Form';
import { requestPasswordResetAction } from 'pages/RequestPasswordReset/service';
import intlShape from 'shared/intlShape';
import { withIntl } from 'utils/intl';

const RequestPasswordReset = ({ intl: { formatMessage } }) => {
  const [isSent, setIsSent] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  async function handleForgotPassword({ email }) {
    setIsPending(true);
    const response = await dispatch(requestPasswordResetAction(email));

    if (!response.error) {
      setIsSent(true);
    }
    setIsPending(false);
  }

  return (
    <PortalPage
      biggerHeading
      pageTitle={formatMessage({ id: 'pages.requestPasswordReset.heading' })}
    >
      {isSent ? (
        <Confirmation />
      ) : (
        <Form onSubmit={handleForgotPassword} formatMessage={formatMessage} isPending={isPending} />
      )}
    </PortalPage>
  );
};

RequestPasswordReset.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(withIntl)(RequestPasswordReset);
