import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import loadQuickCheckFormValues from 'api/CompeonReverseApi/operation/loadQuickCheckFormValues/actions';
import { useDownloadFileFromResponse } from 'components/DownloadBlock/useDownloadFile';
import FormSection from 'components/FormSection';
import paths from 'constants/paths';
import BottomBar from 'modules/FormPage/BottomBar';
import {
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
} from 'modules/Inquiry/Form/formFields';
import mapQuickCheckToApi from 'modules/QuickCheck/mapQuickCheckToApi';
import ButtonsWrapper from 'pages/inquiryFlow/FinalPage/SuccessButtons/ButtonsWrapper';
import { isQuickCheckStartedAction } from 'store/inquiryDetails/actions';
import {
  getInquiryIdSelector,
  getInquiryProjectFinancingObjectAddress,
} from 'store/inquiryDetails/selectors';
import ButtonComponent from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { formatYearMonthDay } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import EditRealStateHeading from '../EditMarketValuesReeProfitability/EditRealStateHeading';
import CreditValueSection from './CreditValueSection';
import InformationAboutCustomerSection from './InformationAboutCustomerSection';
import OtherInformationSection from './OtherInformationSection';
import ProjectCalculationSection from './ProjectCalculationSection';
import ProjectInformationSection from './ProjectInformationSection';
import UnsecuredPortionSection from './UnsecuredPortionSection';

const sections: {
  component: JSX.Element;
  title: string;
}[] = [
  {
    component: <InformationAboutCustomerSection />,
    title: 'sections.informationAboutCustomer.title',
  },
  {
    component: <ProjectInformationSection />,
    title: 'sections.projectInformation.title',
  },
  {
    component: <OtherInformationSection />,
    title: 'sections.otherInformation.title',
  },
  {
    component: <CreditValueSection />,
    title: 'sections.creditValue.title',
  },
  {
    component: <UnsecuredPortionSection />,
    title: 'sections.unsecuredPortion.title',
  },
  {
    component: <ProjectCalculationSection />,
    title: 'sections.projectCalculation.title',
  },
];

enum SubmitTypes {
  DOWNLOAD = 'DOWNLOAD',
  SAVE = 'SAVE',
}

const submitQuickCheckValues = (data: Record<string, unknown>, inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.UPDATE_QUICK_CHECK_PAGE.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: data,
      },
    },
  });

const downloadQuickCheckValues = (data: Record<string, unknown>, inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.DOWNLOAD_QUICK_CHECK_PDF.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: data,
      },
    },
    responseType: 'arraybuffer',
  });

const QUICK_CHECK_ROLES = [
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
];

const FILENAME_MAP = {
  [QUICK_CHECK_ROLES_FILE_HALTER]: (date: string, objectAddress: string) =>
    `${date}_Quick Check Bestandshalter_${objectAddress}.pdf`,
  [QUICK_CHECK_ROLES_PARTITION]: (date: string, objectAddress: string) =>
    `${date}_Quick Check Aufteiler_${objectAddress}.pdf`,
  [QUICK_CHECK_ROLES_PROPERTY_DEVELOPER]: (date: string, objectAddress: string) =>
    `${date}_Quick Check Bauträger_${objectAddress}.pdf`,
};

const ROLES_MAP = {
  [QUICK_CHECK_ROLES_FILE_HALTER]: 'rent',
  [QUICK_CHECK_ROLES_PARTITION]: 'unit',
  [QUICK_CHECK_ROLES_PROPERTY_DEVELOPER]: 'building',
};

const useHandleSubmit = () => {
  const { makeCall, isPending } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { makeDownloadCall, isPending: downloadPending } =
    useDownloadFileFromResponse(downloadQuickCheckValues);

  const objectAddress = useSelector(getInquiryProjectFinancingObjectAddress) || '';
  const date = formatYearMonthDay();

  const history = useHistory();

  const navigateToDashboard = () => {
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId));
  };

  const handleSubmit = async (data: Record<string, unknown>) => {
    const mappedDataToPayload = mapQuickCheckToApi(data);
    if (mappedDataToPayload) {
      if (data.submitType === SubmitTypes.SAVE) {
        const { error } = await makeCall(submitQuickCheckValues(mappedDataToPayload, inquiryId));
        if (!error) {
          navigateToDashboard();
        }
      } else if (data.submitType === SubmitTypes.DOWNLOAD) {
        const selectedRoles = QUICK_CHECK_ROLES.reduce((prev, curr) => {
          if (data[curr]) {
            return [...prev, curr];
          }
          return prev;
        }, [] as string[]);
        for (let i = 0; i < selectedRoles.length; i++) {
          let key = selectedRoles[i];
          const mappedData = mapQuickCheckToApi(data, ROLES_MAP[key as keyof typeof ROLES_MAP]);
          makeDownloadCall(
            FILENAME_MAP[key as keyof typeof FILENAME_MAP](date, objectAddress),
            () => {
              if (selectedRoles.length === i + 1) {
                navigateToDashboard();
              }
            },
            mappedData,
            inquiryId,
          );
        }
      }
    }
  };
  return {
    isPending: isPending || downloadPending,
    handleSubmit,
  };
};

const SUBMIT_TYPE = 'submitType';

const QuickCheck = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck');
  const { makeCall } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const [initialValues, setInitialValues] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  const getInitialValues = useCallback(async () => {
    let { error, formValues } = await loadQuickCheckFormValues(makeCall, inquiryId);
    if (!error) {
      setInitialValues(formValues);
      dispatch(isQuickCheckStartedAction());
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryId, makeCall]);

  const { handleSubmit, isPending } = useHandleSubmit();

  const navigateToDashboard = () => {
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId));
  };

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  const handleDownload = (form: FormApi) => {
    form.change(SUBMIT_TYPE, SubmitTypes.DOWNLOAD);
    form.submit();
  };

  const handleSave = (form: FormApi) => {
    form.change(SUBMIT_TYPE, SubmitTypes.SAVE);
    form.submit();
  };

  const isLoading = (form: FormApi) => {
    const formSubmitType = form.getState().values[SUBMIT_TYPE] as SubmitTypes | unknown;
    const isSaving = isPending && formSubmitType === SubmitTypes.SAVE;
    const isDownloading = isPending && formSubmitType === SubmitTypes.DOWNLOAD;
    return { isSaving, isDownloading };
  };

  return (
    <Box>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ form }) => (
          <form>
            <EditRealStateHeading heading={t('pageTitle')} subHeading={t('subheading')} />
            {sections.map(({ component, title }, index) => (
              <FormSection sectionNumber={index + 1} title={t(title)}>
                {component}
              </FormSection>
            ))}
            <BottomBar>
              <ButtonsWrapper>
                <ButtonComponent
                  variant="tertiary"
                  mr={4}
                  data-testid={'cancel'}
                  onClick={navigateToDashboard}
                >
                  {t('actions.cancel')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<SaveIcon boxSize={6} d="block" />}
                  onClick={() => handleSave(form)}
                  mr={4}
                  data-testid={'saveChanges'}
                  isLoading={isLoading(form).isSaving}
                  disabled={isLoading(form).isDownloading}
                >
                  {t('actions.saveChanges')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
                  data-testid={'downloadPdfFile'}
                  onClick={() => handleDownload(form)}
                  isLoading={isLoading(form).isDownloading}
                  disabled={isLoading(form).isSaving}
                >
                  {t('actions.downloadPdfFile')}
                </ButtonComponent>
              </ButtonsWrapper>
            </BottomBar>
          </form>
        )}
      />
    </Box>
  );
};

export default QuickCheck;
