import * as React from 'react';

import {
  Box,
  BoxProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  Portal,
  useMultiStyleConfig,
  Placement,
} from '@chakra-ui/react';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';

type Props = {
  heading?: string;
  text: string;
  trigger: React.ReactElement;
  popoverPlacement?: Placement;
} & Pick<BoxProps, 'mt' | 'ml' | 'transform'>;

type RenderersProps = {
  href: string | undefined;
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
};

export const PopoverComponent = ({ heading, text, trigger, popoverPlacement, ...props }: Props) => {
  const styles = useMultiStyleConfig('Popover', {});
  return (
    <Popover trigger="click" placement={popoverPlacement}>
      <PopoverTrigger>
        {/* There is a weird CSS behavior from the past which does not align the Popover with the caption, therefore
         we have to set mb={2} here
         */}
        <Box as="span" mb={2} {...props}>
          {trigger}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent sx={styles.content}>
          {heading && <PopoverHeader sx={styles.header}>{heading}</PopoverHeader>}
          {/*BackgroundColor needs to be*/}
          {/*set in Arrow component because Chakra UI will override it if it's definde in popover.ts*/}
          <PopoverArrow backgroundColor={'background.blackGrey'} />
          <PopoverBody sx={styles.body}>
            <MarkdownWrapper
              text={text}
              renderers={{
                link: (prop: RenderersProps) => <a href={prop.href}>{prop.children}</a>,
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
