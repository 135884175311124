import _get from 'lodash/get';

import {
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
  QUICK_CHECK_INFO_ON_COLLATERAL,
  QUICK_CHECK_INITIATOR,
  QUICK_CHECK_JUSTIFICATION_TEMPLATE,
  QUICK_CHECK_NEW_COLLATERAL,
  QUICK_CHECK_NEW_COMMITMENT,
  QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
  QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
  QUICK_CHECK_PROJECT_DESCRIPTION,
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
  QUICK_CHECK_SELECTING_PROFITABILITY_TYPE,
  QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
  QUICK_CHECK_TYPE_AND_GUARANTOR,
  QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { FILTERS_USAGE_TYPES } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import { unknownToString } from './../EditMarketValuesReeProfitability/mapProfitabilitySectionsToApi/utils';

const mapQuickCheckToApi = (data: Record<string, unknown>, key?: string | undefined) => {
  const { toFloatPrecision2 } = fieldParsers;
  return {
    customer_information: {
      previous_business_relationship_info: _get(data, QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS),
      initiator_info: _get(data, QUICK_CHECK_INITIATOR),
      project_financing_status_inhouse: _get(
        data,
        QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
      ),
      economic_circumstances: _get(data, QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER),
    },
    project_information: {
      project_description: _get(data, QUICK_CHECK_PROJECT_DESCRIPTION),
      collateral_info: _get(data, QUICK_CHECK_INFO_ON_COLLATERAL),
      withdrawal_requirements: _get(data, QUICK_CHECK_WITHDRAWAL_REQUIREMENTS),
    },
    other_information: {
      other_notes_info: _get(data, QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION),
      justification_template: _get(data, QUICK_CHECK_JUSTIFICATION_TEMPLATE),
    },
    loan_value: {
      security_and_warrantor: _get(data, QUICK_CHECK_TYPE_AND_GUARANTOR),
      amount_in_euros: toFloatPrecision2(unknownToString(_get(data, QUICK_CHECK_AMOUNT_IN_EURO))),
    },
    unsecured_portion: {
      current_total_commitment_in_euro: toFloatPrecision2(
        unknownToString(_get(data, QUICK_CHECK_CURRENT_TOTAL_COMMITMENT)),
      ),
      new_commitment_in_euro: toFloatPrecision2(
        unknownToString(_get(data, QUICK_CHECK_NEW_COMMITMENT)),
      ),
      current_total_collateral_in_euro: toFloatPrecision2(
        unknownToString(_get(data, QUICK_CHECK_CURRENT_COLLATERAL_VALUE)),
      ),
      new_collateral_in_euro: toFloatPrecision2(
        unknownToString(_get(data, QUICK_CHECK_NEW_COLLATERAL)),
      ),
    },
    project_calculation: [
      {
        qc_type_of_use: {
          current:
            _get(data, QUICK_CHECK_SELECTING_PROFITABILITY_TYPE) === FILTERS_USAGE_TYPES.CURRENT,
          future:
            _get(data, QUICK_CHECK_SELECTING_PROFITABILITY_TYPE) === FILTERS_USAGE_TYPES.FUTURE,
        },
        qc_role: {
          building: !!_get(data, QUICK_CHECK_ROLES_PROPERTY_DEVELOPER),
          unit: !!_get(data, QUICK_CHECK_ROLES_PARTITION),
          rent: !!_get(data, QUICK_CHECK_ROLES_FILE_HALTER),
        },
      },
    ],
    selected_sale_type: key,
  };
};

export default mapQuickCheckToApi;
