import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { VEHICLE_INTERESTED_IN_INSURANCE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const VehicleInterestedInInsurance = () => {
  return <YesNoRadioGroupWithField name={VEHICLE_INTERESTED_IN_INSURANCE} />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => null,
  default: VehicleInterestedInInsurance,
});
