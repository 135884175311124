import {
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__MONTHLY_TURNOVER,
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  PURPOSE_KIND_BFS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  PRE_FINANCING_PERIOD,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
  OTHER_PURPOSE_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { PAYMENT_TERM } from 'onlinefactoring/formFields';

export const mapFactomatToApi: InquiryFormMapper = (allFields: any) => ({
  industry: allFields[PURPOSE_KIND_BFS].replaceAll('-', '_'),
  other_industry: allFields[BFS_SERVICE__COMPANY_OTHER_INDUSTRY],
  factoring_sales_gross: allFields[BFS_SERVICE__MONTHLY_TURNOVER],
  first_invoices_purchase_time: allFields[BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE],
  industry_software_for_billing: allFields[BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING],
  other_industry_software_for_billing: allFields[BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING],
  pre_financing_period: allFields[PRE_FINANCING_PERIOD],
  purchased_invoices_paid_out_time: allFields[BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON],

  standard_payment_term: allFields[PAYMENT_TERM],
  use_factoring_form: allFields[BFS_SERVICE__USE_FACTORING_AS_FINANCING],
  billing_service_provider: allFields[BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER],
  claims_assigned_already: allFields[BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES],

  special_features: allFields[OTHER_PURPOSE_DESCRIPTION],
});
