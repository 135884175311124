import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import { callReverseApi, API_METHODS } from 'api/apiService';
import type { ApiFileRequest } from 'shared/documentExchange/documentExchange.service';
import { mapApiFileRequest } from 'shared/documentExchange/documentExchange.service';

import { ACTIONS } from './actions';

const FastlaneService = {
  fastlaneCheckApiAction: (data: any) =>
    callReverseApi({
      url: '/fastlane_checks',
      method: API_METHODS.POST,
      data,
      actionName: ACTIONS.CHECK_FASTLANE,
    }),
  mapFromApiInterface: (rawResponse: any) => {
    const data = _get(rawResponse, 'data.data');
    if (!data) {
      return {};
    }
    const {
      file_upload_requests: rawFileUploadRequests,
      fastlane_mode: fastlaneMode,
      files,
      fastlane_errors: fastlaneErrors,
    } = data;

    const included = files || [];

    const jsonToNormalize = {
      data: [...rawFileUploadRequests],
      included,
    };
    const normalizedResponse = normalize(jsonToNormalize, {
      camelizeKeys: false,
    });

    const fileUploadRequests = normalizedResponse.file_upload_requests
      ? Object.values(normalizedResponse.file_upload_requests as Array<ApiFileRequest>).map(
          (request) => mapApiFileRequest(request),
        )
      : [];

    return {
      fileUploadRequests,
      fastlaneMode,
      fastlaneErrors,
    };
  },
};

export default FastlaneService;
