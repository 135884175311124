import React from 'react';

import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  PEOPLE_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE_PHONE_NUMBER,
  USER_REPRESENTATIVE,
  USER_PROFILE_BIRTHDATE,
} from 'modules/Inquiry/Form/formFields';
import ContactPerson from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import PasswordSection from 'pages/inquiryFlow/CompanyDetails/PasswordSection/PasswordSection';
import LegalRepresentationSection from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import PeopleDetailsHeading from 'pages/inquiryFlow/PeopleDetails/PeopleDetailsHeading';
import { usePeopleDetailsValidators } from 'pages/inquiryFlow/PeopleDetails/usePeopleDetailsValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { getUser as getCustomerSelector, isLoggedInSelector } from 'store/user/selectors';

import BeneficiaryOwnersSection from './BeneficiaryOwnersSection/BeneficiaryOwnersSection';

const PeopleDetailsUnwrapped = () => {
  const getSectionsValidators = usePeopleDetailsValidators();

  // if user is loggedIn, set values from store
  const customer = useSelector(getCustomerSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const processLane = useSelector(getProcessLane);

  const phoneNumber = useSelector(
    getStoredValueSelector([REQUEST_DETAILS, USER_PROFILE_PHONE_NUMBER]),
  );
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  const userInitialValues = {
    [COMPANY_DETAILS_EMAIL]: customer.email,
    [COMPANY_DETAILS_FIRST_NAME]: customer.firstName,
    [COMPANY_DETAILS_LAST_NAME]: customer.lastName,
    [COMPANY_DETAILS_GENDER]: customer.gender,
    [USER_PROFILE_PHONE_NUMBER]: phoneNumber,
    [USER_PROFILE_BIRTHDATE]: customer.birthDate,
  };

  const initialValues = isLoggedIn
    ? { ...userInitialValues, [USER_REPRESENTATIVE]: [{}] }
    : { [USER_REPRESENTATIVE]: [{}], [USER_PROFILE_PHONE_NUMBER]: phoneNumber };

  const fieldController = FormFieldController.instance;
  fieldController.saveInitial(initialValues);

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={PEOPLE_DETAILS}
      headerComp={<PeopleDetailsHeading />}
      getSectionValidators={getSectionsValidators}
    >
      {() => (
        <>
          <ContactPerson sectionNumber={1} />
          <LegalRepresentationSection sectionNumber={2} />
          {isBfsService && <BeneficiaryOwnersSection sectionNumber={3} />}
          {!isLoggedIn && (
            <PasswordSection
              sectionNumber={isBfsService ? 4 : processLane === InquiryLane.lead ? 2 : 3}
            />
          )}
        </>
      )}
    </InquiryFormNew>
  );
};

export default PeopleDetailsUnwrapped;
