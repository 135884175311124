import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import DetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/DetailedInquiryInformation';
import OnlineFactoringDetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/OnlineFactoringDetailedInquiryInformation';
import DvagCustomerDetailedInformation from 'pages/customerPortal/InquiryDetails/DetailedInformation/DvagCustomerDetailedInformation';
import { Channels, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { MittweidaDetailedInquiryInformation } from './mittweida';

const InquirySpecificDisplayCondition = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();

  useWebSocket({
    channel: Channels.INQUIRY,
    customProps: {
      id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  return (
    <InquiryTypeCondition
      portal
      cases={{
        onlinefactoring: <OnlineFactoringDetailedInquiryInformation />,
        [InquiryType.profiMittweida]: (
          <MittweidaDetailedInquiryInformation
            pathToEditInquiry={paths.customer.inquiryDetails.edit.root}
          />
        ),
        [InquiryType.dvag]: <DvagCustomerDetailedInformation />,
        default: (
          <DetailedInquiryInformation pathToEditInquiry={paths.customer.inquiryDetails.edit.root} />
        ),
      }}
    />
  );
};

export default InquirySpecificDisplayCondition;
