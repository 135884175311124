import React from 'react';

import { useSelector } from 'react-redux';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import paths from 'constants/paths';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import {
  probabilityPreSaleObjectTranslationMapping,
  probabilityPreSaleTypeEvalUnitMapping,
  PROFITABILITY_PRE_SALE_KEYS,
} from '../../../types';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';
import { orderMapping } from '../../utils';

export type SalePresaleType = {
  [key: string]: {
    area: number;
    share_of_whole_area: number;
    price_per_sqm: number;
    revenue: number;
  };
};
interface ISalePresale {
  data: SalePresaleType;
}

const SalesInPresalePhase: React.FC<ISalePresale> = ({ data }: ISalePresale) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { lendingValues } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.salesInPresalePhase',
  );
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const unitTranslation = useTranslations();

  const { onEdit } = useEditFormConfig({
    pathToEdit: lendingValues(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.PRESALES_DATA,
    loadData: loadEditProfitabilitySection,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.PRESALES_DATA](),
  });
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;

  const headers = [
    {
      key: 'type',
      text: t('tableHeaders.type'),
    },
    {
      key: 'size',
      text: t('tableHeaders.size'),
    },
    {
      key: 'shareOfWholeArea',
      text: t('tableHeaders.shareOfWholeArea'),
    },
    {
      key: 'pricePerSqm',
      text: t('tableHeaders.pricePerSqm'),
    },
    {
      key: 'revenue',
      text: t('tableHeaders.revenue'),
    },
  ];

  const orderedData: SalePresaleType = orderMapping<SalePresaleType>(
    data,
    probabilityPreSaleObjectTranslationMapping,
  );

  const highlightedRows = [`${PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_TOTAL_AREA}`];

  const rows =
    orderedData &&
    Object.keys(orderedData).map((item: string) => ({
      id: item,
      type: t(`tableRows.${probabilityPreSaleObjectTranslationMapping[item]}`),
      size: formatColumnValues(
        orderedData[item]?.area,
        probabilityPreSaleTypeEvalUnitMapping(unitTranslation)[item].area,
      ),
      shareOfWholeArea: formatColumnValues(
        orderedData[item]?.share_of_whole_area,
        probabilityPreSaleTypeEvalUnitMapping(unitTranslation)[item].share_of_whole_area,
      ),
      pricePerSqm: formatColumnValues(
        orderedData[item]?.price_per_sqm,
        probabilityPreSaleTypeEvalUnitMapping(unitTranslation)[item].price_per_sqm,
      ),
      revenue: formatColumnValues(
        orderedData[item]?.revenue,
        probabilityPreSaleTypeEvalUnitMapping(unitTranslation)[item].revenue,
      ),
      ...(highlightedRows.includes(item) && { rowHighlight: true, valueBold: true }),
    }));

  return (
    <>
      <AccordionComponent headingLevel="h3" mb={8} title={t('title')} defaultIndex={[0]}>
        <TableCardWithEdit
          canEdit={canEdit}
          onEdit={onEdit}
          tableHeaders={headers}
          tableData={rows}
          componentName={PROFITABILITY_RECORD_TYPE.PRESALES_DATA}
        />
      </AccordionComponent>
    </>
  );
};

export default SalesInPresalePhase;
