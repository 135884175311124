import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const HirePurchaseInterestedInInvestmentLoan: React.FC = () => {
  return <YesNoRadioGroupWithField name={HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN} />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => null,
  default: HirePurchaseInterestedInInvestmentLoan,
});
