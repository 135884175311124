import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { hasPermission } from 'store/user/selectors';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';

const withCompanies = getEnvironmentFeatureFlag(EnvironmentVariable.COMPANIES_LIST);

const useShowCompanyDetails = () => {
  const hasCompaniesListPermission = useSelector(hasPermission);
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  return (
    withCompanies &&
    hasCompaniesListPermission(PERMISSIONS.COMPANY_DETAILS.DISPLAY) &&
    !isBfsService
  );
};

export default useShowCompanyDetails;
