import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { forwardToCompeonAction } from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../Section';
import Button from '../Section/Button';

const ForwardToCompeonWithoutConfirmation = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.forwardToCompeon');

  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);

  const forward = () =>
    makeCall(forwardToCompeonAction(inquiryId), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t('messageAfterAction') });
    });

  const button = <Button isLoading={isPending} onClick={forward} label={t('buttonLabel')} />;

  return (
    <Section
      title={t('title')}
      description={t('description')}
      hasError={Boolean(error)}
      button={button}
    />
  );
};

export default ForwardToCompeonWithoutConfirmation;
