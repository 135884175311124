import {
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_EXTRA_MARKDOWN,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_BUFFER,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
  PROFITABILITY_USUAL_SALES_COST_RATE,
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_NEW_COMMITMENT,
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_NEW_COLLATERAL,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const profiUnits: Record<string, FIELD_UNIT_TYPE> = {
  [CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_EXISTING_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_EXISTING_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_PARKING_LOT_VALUE]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_GARAGE_VALUE]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_GARAGE_RENT]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_PARKING_LOT_RENT]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_PARKING_LOT_VALUE]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_GARAGE_VALUE]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_GARAGE_RENT]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_PARKING_LOT_RENT]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING]: FIELD_UNIT_TYPE.CURRENCY,
  [CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING]: FIELD_UNIT_TYPE.CURRENCY,
  [FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_SAFETY_MARKDOWN]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_EXTRA_MARKDOWN]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_PURCHASE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_LEGAL_FEES]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_AGENT_FEES]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_MEASUREMENT_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OTHER_FEES]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OTHER_PURCHASE_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OTHER_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_COMPENSATIONS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_LAND_DEVELOPMENT_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_PUBLIC_DISPOSAL]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_NON_PUBLIC_DISPOSAL]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OTHER_DEVELOPMENT_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_CONSTRUCTION_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OUTDOOR_FACILITIES_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_OTHER_CONSTRUCTION_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_ARCHITECT_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_ADDITIONAL_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_THIRD_PARTY_FINANCING_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_FINANCING_COSTS_FEES_MANUAL]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_BUFFER]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_MARKETING_SALES_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  // presales start
  [PROFITABILITY_PRESALES_AREA_RESIDENTIAL]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_PRESALES_AREA_COMMERCIAL]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE]: FIELD_UNIT_TYPE.CURRENCY,
  // presales end
  // field types for financing parameters START
  [PROFITABILITY_FINANCING_FEE_BUILDING]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_FINANCING_DURATION_BUILDING]: FIELD_UNIT_TYPE.MONTHS,
  [PROFITABILITY_INTEREST_RATE_BUILDING]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_FINANCING_FEE_UNIT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_FINANCING_DURATION_UNIT]: FIELD_UNIT_TYPE.MONTHS,
  [PROFITABILITY_INTEREST_RATE_UNIT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_FINANCING_DURATION_RENT]: FIELD_UNIT_TYPE.MONTHS,
  [PROFITABILITY_INTEREST_RATE_RENT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_INTEREST_RATE_RENT_SHORT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_REPAYMENT_RATE_RENT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  // field types for financing parameters END
  [PROFITABILITY_USUAL_SALES_COST_RATE]: FIELD_UNIT_TYPE.PERCENTAGE,
  // fields for profitability rental parameters START
  [PROFITABILITY_OPERATING_COSTS_RESIDENTIAL]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_OPERATING_COSTS_COMMERCIAL]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  [PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO]: FIELD_UNIT_TYPE.CURRENCY,
  [PROFITABILITY_TAX_RATE_IN_PERCENT]: FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL,
  // fields for profitability rental parameters END
  // fields for quick check start
  [QUICK_CHECK_AMOUNT_IN_EURO]: FIELD_UNIT_TYPE.CURRENCY,
  // unsecured portion start
  [QUICK_CHECK_CURRENT_TOTAL_COMMITMENT]: FIELD_UNIT_TYPE.CURRENCY,
  [QUICK_CHECK_NEW_COMMITMENT]: FIELD_UNIT_TYPE.CURRENCY,
  [QUICK_CHECK_CURRENT_COLLATERAL_VALUE]: FIELD_UNIT_TYPE.CURRENCY,
  [QUICK_CHECK_NEW_COLLATERAL]: FIELD_UNIT_TYPE.CURRENCY,
  // unsecured portion end
  // fields for quick check end
};
