import React from 'react';

import {
  StyledElementsPerPageButton as Button,
  StyledElementsPerPageList as List,
  StyledElementsPerPageOption as NumOfElements,
} from 'components/PortalTable/ElementsPerPage/StyledElementsPerPage';

const PAGE_SIZE_OPTIONS = [5, 25, 50];

const ElementsPerPage = ({
  pageSize,
  onPageSizeChange,
}: {
  pageSize: number;
  onPageSizeChange: (newPage: number) => any;
}) => {
  const handleClick = (newPageSize: number) => () => onPageSizeChange(newPageSize);

  return (
    <List>
      {PAGE_SIZE_OPTIONS.map((numOfElements) => (
        <NumOfElements key={numOfElements}>
          <Button
            type="button"
            active={+pageSize === +numOfElements}
            onClick={handleClick(numOfElements)}
          >
            {numOfElements}
          </Button>
        </NumOfElements>
      ))}
    </List>
  );
};

export default ElementsPerPage;
