import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _set from 'lodash/set';

import { Store } from 'types/Store';
import { FORM_VALUES } from 'utils/sessionStorage/keys';
import { SessionStorageStore } from 'utils/sessionStorage/SessionStorageStore';

import { IFormStore, ISaveBulkOptions, ISaveOptions } from './types';

export class FormValuesStore implements IFormStore<unknown> {
  private static Instance: FormValuesStore;
  private store: Store<Record<string, unknown>> = new SessionStorageStore<Record<string, unknown>>(
    FORM_VALUES,
    {},
  );

  private state: Record<string, unknown> = {};

  private constructor() {}

  public static get instance(): FormValuesStore {
    if (!FormValuesStore.Instance) {
      FormValuesStore.Instance = new FormValuesStore();
    }

    return FormValuesStore.Instance;
  }

  public destroy() {
    this.store.destroy();
  }

  public saveValue(
    name: string | undefined,
    newValue: unknown,
    { emit = true, override = false }: ISaveOptions = {},
  ) {
    if (!name) {
      console.error('Name is not defined');
      return;
    }
    const parsedObj = FormValuesStore._mapNameAndValueToObject(name, newValue);

    this.store.save(parsedObj, { override });
    this._saveToState(parsedObj);
  }

  public saveBulk(
    data: Record<string, unknown>,
    { emit = true, force = false }: ISaveBulkOptions = {},
  ) {
    this.store.saveBulk(data, force);
  }

  public saveInitial(data?: Record<string, unknown>) {
    if (!data) {
      // initial data can be undefined, it's expected;
      return;
    }

    const currentValues = this.store.getAllValues();

    const keysFromStore = Object.keys(currentValues);
    const keysFromData = Object.keys(data);

    // Already existing keys are skipped
    const valuesToRemove = keysFromData.filter((key: string) => keysFromStore.includes(key));
    const objectToSave = valuesToRemove.reduce((acc, curr) => _omit(acc, curr), data);

    this.store.save(objectToSave);
  }

  public deleteBulk(keys: string[]) {
    const clearData = keys.reduce<Record<string, unknown>>(
      (acc, key) => ({
        ...acc,
        [key]: null,
      }),
      {},
    );
    this.saveBulk(clearData);
  }

  public deleteValue(name: string, index?: number) {
    const values = this.store.getAllValues();

    let newValues = values;
    if (index !== undefined && index > -1) {
      const array = _get(values, name) as object[];
      array.splice(index, 1);
      _set(newValues, name, array);
    } else {
      newValues = _omit(values, name);
    }
    this.store.save(newValues, { override: true });
  }

  public get formValues(): Record<string, unknown> {
    return this.store.getAllValues();
  }

  public getValue(name: string): unknown {
    return this.store.getSingleValue(name);
  }

  private _saveToState(obj: Record<string, unknown>): void {
    this.state = obj;
  }

  private static _mapNameAndValueToObject(name: string, value: unknown): Record<string, unknown> {
    const obj = {};
    _set(obj, name, value);
    return obj;
  }
}
