import React from 'react';

import TextExpander from 'components/TextExpander/TextExpander';
import {
  StyledIconWrapper as IconWrapper,
  StyledRequestActions as Actions,
  UnuploadedIcon,
  StyledRequestBlockWrapper as BlockWrapper,
  StyledRequestContent as Content,
  StyledRequestTitleWrapper as TitleWrapper,
  StyledRequestTitle as Title,
  StyledUploadedFileName as FileName,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import { IInternalFile } from 'models/File.model';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const InternalFileDescriptor: { [key: string]: string } = {
  crefo_scoring_compact: 'crefoCompact',
  crefo_scoring_short: 'crefoShort',
  crefo_scoring_traffic_light: 'crefoTrafficLight',
  crefo_scoring_black_white: 'crefoBlackWhite',
  idnow: 'videoIdent',
  video_identifications: 'videoIdentificationFile',
  kycnow_money_laundry: 'kycNowMoneyLaundry',
  kycnow_ad_document: 'kycAdDocument',
  kycnow_cd_document: 'kycCdDocument',
  kycnow_gss_document: 'kycGssDocument',
  kycnow_lg_document: 'kycLgDocument',
  transparency_document: 'transparencyDocument',
};

interface FileDownloadProps {
  file: IInternalFile;
}

const FileDownloadBlock: React.FC<FileDownloadProps> = ({ file }: FileDownloadProps) => {
  const t = useTranslations();
  const viewAbleFileTypes = ['application/pdf'];

  const formatForIDnow = (id: string) => {
    return file.id.includes('idnow') ? 'idnow' : id;
  };

  return (
    <BlockWrapper>
      <IconWrapper>
        <UnuploadedIcon />
      </IconWrapper>
      <Content>
        <TitleWrapper>
          <Title>
            {t(
              `pages.inquiryDetails.documentExchange.internalFiles.${
                InternalFileDescriptor[formatForIDnow(file.id)]
              }`,
            )}
          </Title>
        </TitleWrapper>
        <FileName>
          <TextExpander text={file.fileName} maxTextLength={30} expandable={false} />
        </FileName>
      </Content>
      <Actions>
        {viewAbleFileTypes.includes(file.contentType) && (
          <ButtonComponent
            data-testid="preview-file-btn"
            leftIcon={<ViewIcon boxSize={6} d="block" />}
            onClick={() => window.open(file.downloadUrl)}
          >
            {t('components.upload.preview')}
          </ButtonComponent>
        )}
        <ButtonComponent
          leftIcon={<DownloadIcon boxSize={6} d="block" />}
          onClick={() => window.open(`${file.downloadUrl}?disposition=attachment`, '_blank')}
          data-testid="Download-Button"
        >
          {t('components.download.button')}
        </ButtonComponent>
      </Actions>
    </BlockWrapper>
  );
};

export default FileDownloadBlock;
