import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqSmall, mqMedium } from 'styles/breakpoints';

export const Sidebar = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
`;

export const IconButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 70px;
  min-height: 50px;
  padding: 0.75rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) => props.theme.colors.primary};

  ${mqSmall`
    min-width: 110px;
    min-height: 90px;
  `};

  ${mqMedium`
    min-width: 125px;
    min-height: 112px;
    padding: 1.25rem;
  `};

  &[disabled] {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.background.darkGrey};
  }

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: 2px;
  }
`;

export const ButtonText = styled.span`
  display: none;

  ${mqSmall`
    display: block;
  `};
`;
