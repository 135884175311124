import React from 'react';

import { useIntl } from 'react-intl';

import { SelectWithField } from 'components/Selects/Select';
import StaticField from 'components/StaticField';
import {
  USER_PROFILE_LEGAL_FORM,
  BFS_SERVICE_USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
  USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import getLegalFormOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/legalFormOptions';
import {
  getSelectedLabelForOptions,
  COMPANY_FIELDS,
} from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetMappedPartnerOptions } from '../../../../../../utils/hooks/useGetMappedOptions';

const LegalForm = ({ tooltip = false }) => {
  const intl = useIntl();
  const tFields = useTranslations('pages.userProfile.sections.completingCompanyDetails.fields');
  const t = useTranslations();
  const excludedDefaultOptions = [
    USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
    USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
    USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
  ];

  const legalFormOPtionsKey = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: BFS_SERVICE_USER_PROFILE_LEGAL_FORM,
    default: USER_PROFILE_LEGAL_FORM,
  });

  const legalForm = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: null,
    default: useGetSelectedCompanyParam(COMPANY_FIELDS.legalForm),
  });

  const legalFormOptions = useInquirySelectOptions(legalFormOPtionsKey);
  const defaultLegalOptions = legalFormOptions.filter((obj) => {
    return !excludedDefaultOptions.includes(obj.value);
  });

  const partnerLegalOptions = useGetMappedPartnerOptions('legal_forms');
  const legalOptions = partnerLegalOptions.length !== 0 ? partnerLegalOptions : defaultLegalOptions;

  if (legalForm) {
    return (
      <StaticField
        caption={tFields('userProfileLegalForm.caption')}
        text={getSelectedLabelForOptions(getLegalFormOptions, legalForm, intl.formatMessage)}
      />
    );
  }

  return (
    <SelectWithField
      name={USER_PROFILE_LEGAL_FORM}
      options={legalOptions}
      tooltip={tooltip}
      placeholder={t('placeholders.pleaseChoose')}
      data-testid={'select-field-' + USER_PROFILE_LEGAL_FORM}
    />
  );
};

export default LegalForm;
