import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  InquiryRevision,
  RevisionModification,
  RevisionAttributeType,
  InquiryRevisionListElement,
  UserDetails,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import { INQUIRY_ATTRIBUTES } from 'shared/inquiry/inquiryFieldsTranslations/inquiryAttributesTranslations/InquiryAttributes.model';

import { REVISION_ACTIONS } from './actions';

const EXCLUDED_ATTRIBUTE_KEYS = [
  'id',
  'partner_id',
  'customer_id',
  'created_at',
  'compeon_response_body',
  'compeon_response_code',
  'file-upload-request-ids',
];

export const fetchInquiryRevisions = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.CHANGELOG.compose({ params: { id: inquiryId } }),
    method: API_METHODS.GET,
    actionName: REVISION_ACTIONS.FETCH_INQUIRY_REVISIONS,
  });

export const fetchInquiryRevision = (revisionId: string) =>
  callReverseApi({
    url: endpoints.CHANGELOG.compose({ params: { id: revisionId } }),
    method: API_METHODS.GET,
    actionName: REVISION_ACTIONS.FETCH_INQUIRY_REVISION,
  });

const getUser = (
  normalizedApiData: any,
  relationship: string,
  userId: string | null,
): UserDetails | null => {
  if (!userId) {
    return null;
  }
  const user = _get(normalizedApiData, [relationship, userId]);
  if (!user) {
    return null;
  }
  const { salutation, firstName, lastName } = user.attributes;
  return { salutation, firstName, lastName };
};

const customModificationMapper = (normalizedApiData: any) => (modification: any) => {
  const customParsers = {
    [INQUIRY_ATTRIBUTES.inquiryManagerId as string]: {
      ...modification,
      current: getUser(normalizedApiData, 'portalUsers', modification.current),
      previous: getUser(normalizedApiData, 'portalUsers', modification.previous),
    } as RevisionModification,
  };

  return customParsers[modification.attribute] || modification;
};

const filterModificationAttributes = (modification: RevisionModification) =>
  !EXCLUDED_ATTRIBUTE_KEYS.includes(modification.attribute);

const mapModifications = (apiModification: any): RevisionModification => {
  const attributePath = apiModification.attribute.split('.');
  const isFieldModification = attributePath[0] === 'details';

  return {
    attributeType: isFieldModification
      ? RevisionAttributeType.field
      : RevisionAttributeType.attribute,
    attribute: isFieldModification ? attributePath[1] : apiModification.attribute,
    current: apiModification.current,
    previous: apiModification.previous,
    type: apiModification.type,
  };
};

const getChangelog = (normalizedData: any): any => {
  const changelogs = Object.values(normalizedData.changelogs);
  return changelogs[0];
};

const getOwner = (normalizedData: any): UserDetails | null => {
  const changelog = getChangelog(normalizedData);
  const owner = changelog?.relationships?.owner?.data;
  if (!owner) {
    return null;
  }

  return getUser(normalizedData, owner.type, owner.id) || null;
};
export const mapRevisionFromApi = (apiData: any): InquiryRevision => {
  const normalized = normalize(apiData);
  const changelog = getChangelog(normalized);

  return {
    id: changelog.id,
    occurredAt: new Date(changelog.attributes.occurredAt),
    createdAt: new Date(changelog.attributes.updatedAt),
    updatedAt: new Date(changelog.attributes.updatedAt),
    initiator: changelog.attributes.initiatorType,
    owner: getOwner(normalized),
    modification: changelog.attributes.modifications
      .map(mapModifications)
      .map(customModificationMapper(normalized))
      .filter(filterModificationAttributes),
  };
};

export const mapRevisionsListFromApi = (apiPayload: any): InquiryRevisionListElement[] => {
  if (!apiPayload) {
    return [];
  }

  const data = normalize(apiPayload);
  return Object.entries(data.changelogs || []).map(([id, changelog]: any) => ({
    id,
    createdAt: new Date(changelog.attributes.createdAt),
  }));
};

export const checkCanGoNext = (list: InquiryRevisionListElement[], currentId: string) =>
  list.findIndex((rev) => rev.id === currentId) !== list.length - 1;
export const checkCanGoPrevious = (list: InquiryRevisionListElement[], currentId: string) =>
  list.findIndex((rev) => rev.id === currentId) !== 0;
