import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getCompanyDetails } from '../store/selectors';

const CompanyOverviewContact: React.FC = () => {
  const companyDetails = useSelector(getCompanyDetails);
  const t = useTranslations();

  return (
    <>
      <Subtitle>{t('pages.companiesDetails.overview.contact')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('pages.companiesDetails.overview.phone')}
          text={companyDetails.phoneNumber ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.fax')}
          text={companyDetails.faxNumber ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.email')}
          text={companyDetails.email ?? '-'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.website')}
          text={companyDetails.website ?? '-'}
        />
      </StyledOverviewFields>
    </>
  );
};

export default CompanyOverviewContact;
