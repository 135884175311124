import { useSelector } from 'react-redux';

import { isBankAdvisor, isRealEstateExpert, isInquiryManager } from 'store/user/selectors';

export const useRoles = () => {
  const hasRoleBankAdvisor = useSelector(isBankAdvisor);
  const hasRoleRealEstateExpert = useSelector(isRealEstateExpert);
  const hasRoleInquiryManager = useSelector(isInquiryManager);
  const hasRoleBankAdvisorOrRealEstateExpert = hasRoleBankAdvisor || hasRoleRealEstateExpert;
  return {
    hasRoleInquiryManager,
    hasRoleBankAdvisorOrRealEstateExpert,
  };
};
