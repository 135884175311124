import { FONT_WEIGHT_MEDIUM } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const baseStyle = {
  position: 'relative',
  p: 12,
  minWidth: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: 'default',
  ...textStyle.sizeM,
  fontWeight: FONT_WEIGHT_MEDIUM,
  textDecoration: 'none',
  transition:
    'border-color 0.2s ease-in, box-shadow 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in',
  cursor: 'pointer',

  _disabled: {
    opacity: 1,
  },
};

const disabledPrimary = {
  borderColor: 'border.darkGrey',
  color: 'text.tertiary',
  backgroundColor: 'background.darkGrey',

  _hover: {
    boxShadow: 'none',

    _hover: {
      backgroundColor: 'background.darkGrey',
    },
  },
};

const disabledSecondary = {
  borderColor: 'border.darkGrey',
  color: 'text.tertiary',
  backgroundColor: 'background.lightGrey',

  _hover: {
    boxShadow: 'none',

    // we sadly have to overwrite Chakra UI’s background: initial
    _hover: {
      backgroundColor: 'background.lightGrey',
    },
  },
};

const disabledTertiary = {
  color: 'text.tertiary',

  _hover: {
    borderColor: 'inherit',
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
};

const disabledLink = {
  color: 'inherit',

  _hover: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
};

const primary = {
  borderColor: 'brand.default',
  px: '24px',
  py: '12px',
  color: 'text.white',
  backgroundColor: 'brand.default',
  height: 'auto',

  _active: {
    backgroundColor: 'brand.default_Minus5L',
  },

  _disabled: {
    ...disabledPrimary,
  },
};

const secondary = {
  borderColor: 'brand.default',
  px: '24px',
  py: '12px',
  color: 'brand.default',
  backgroundColor: 'background.white',
  height: 'auto',

  _active: {
    backgroundColor: 'brand.default_L93',
  },

  _disabled: {
    ...disabledSecondary,
  },
};

const tertiary = {
  borderColor: 'transparent',
  px: '24px',
  py: '12px',
  color: 'brand.default',
  backgroundColor: 'transparent',
  height: 'auto',

  _active: {
    backgroundColor: 'brand.default_L83',
  },

  _disabled: {
    ...disabledTertiary,
  },
};

const link = {
  borderColor: 'transparent',
  px: '0',
  py: '0',
  color: 'currentColor',
  backgroundColor: 'transparent',
  height: 'auto',

  _active: {
    textDecoration: 'none',
  },

  _disabled: {
    ...disabledLink,
  },
};

const Button = {
  baseStyle: {
    ...baseStyle,
  },
  variants: {
    primary,
    secondary,
    tertiary,
    link,
  },
};

export default Button;
