import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import FileDownloadBlock from 'components/UploadBlock/NewFileRequestBlock/FileDownloadBlock';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { StyledFilesSection as Section } from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/styles';
import type { ApiFile } from 'shared/documentExchange/documentExchange.service';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalOperationFilesWithoutFileRequest } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector, getVideoIdentificationFile } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FilesForCustomer: React.FC = () => {
  const files = useSelector(getAdditionalOperationFilesWithoutFileRequest);
  const inquiryId = useSelector(getInquiryIdSelector);
  const videoIdentificationFile = useSelector(getVideoIdentificationFile);
  const t = useTranslations('pages.inquiryDetails.documentExchange');

  const dispatch = useDispatch();

  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  return (
    <section>
      <FileSectionTitle>{t('forClient')}</FileSectionTitle>
      <UploadAnyFile
        description={t('uploadOtherFilesDesc')}
        onUpload={addFileToList}
        url={endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } })}
      />
      <Section>
        {files.map((file) => (
          <li key={file.id}>
            <UploadedFileBlock file={file} canRemove />
          </li>
        ))}
        {videoIdentificationFile?.downloadUrl && (
          <FileDownloadBlock file={videoIdentificationFile} />
        )}
      </Section>
    </section>
  );
};

export default FilesForCustomer;
