import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import mapFinancingParametersToApi from './mapProfitabilitySectionsToApi/mapFinancingParametersToApi';
import mapInvestmentCostsToApi from './mapProfitabilitySectionsToApi/mapInvestmentCostsToApi';
import mapLendingValuesToApi from './mapProfitabilitySectionsToApi/mapLendingValuesToApi';
import mapPresalesValuesToApi from './mapProfitabilitySectionsToApi/mapPresalesValuesToApi';
import mapRentalParametersToApi from './mapProfitabilitySectionsToApi/mapRentalParametersToApi';
import mapSalesCostRateToApi from './mapProfitabilitySectionsToApi/mapSalesCostRateToApi';

export type MapProfitabilityToApiType<T> = (
  data: Record<string, unknown>,
) => { [key in PROFITABILITY_RECORD_TYPE]?: T };

const mapProfitabilityToApi: MapProfitabilityToApiType<Record<string, unknown>> = (
  data: Record<string, unknown>,
) => {
  return {
    [PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS]: mapFinancingParametersToApi(data),
    [PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS]: mapInvestmentCostsToApi(data),
    [PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION]: mapLendingValuesToApi(data),
    [PROFITABILITY_RECORD_TYPE.SALES_COST_RATE]: mapSalesCostRateToApi(data),
    [PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT]: mapSalesCostRateToApi(data),
    [PROFITABILITY_RECORD_TYPE.PRESALES_DATA]: mapPresalesValuesToApi(data),
    [PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS]: mapRentalParametersToApi(data),
  };
};

export default mapProfitabilityToApi;
