import { all, fieldsAreValid, getFieldValidatorIfExist } from 'modules/Inquiry/Form/formConditions';
import {
  LEASING_ADVANCE_PAYMENT,
  LEASING_RESIDUAL_VALUE,
  LEASING_TERM_IN_MONTHS,
  LEASING_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { leasingResidualValueCondition } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/LeasingSection';

export const validateLeasingSection = (form) => {
  const alwaysVisibleFields = fieldsAreValid([
    LEASING_ADVANCE_PAYMENT,
    LEASING_TERM_IN_MONTHS,
    LEASING_DESCRIPTION,
  ]);

  const residentialValue = getFieldValidatorIfExist(
    LEASING_RESIDUAL_VALUE,
    leasingResidualValueCondition,
  );

  return all(alwaysVisibleFields, residentialValue)({ form });
};
