import React from 'react';

import { Box as ChakraBox } from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ICompeonOfferProperty, IBankFile, ICompeonOffer } from 'models/CompeonOffer.model';
import { CompeonStatus } from 'models/types/CompeonStatus.type';
import AcceptOffer from 'modules/Offers/InquiryOffersSection/AcceptOffer/AcceptOffer';
import {
  Box,
  Wrapper,
  Heading,
  InformationGroup,
  StyledPropertiesWrapper,
  ButtonContainer,
} from 'modules/Offers/InquiryOffersSection/CompeonOffers/styles';
import OfferAcceptedMsg from 'modules/Offers/InquiryOffersSection/OfferAcceptedMsg/OfferAcceptedMsg';
import ShowInterestInOffer from 'modules/Offers/InquiryOffersSection/ShowInterestInOffer/ShowInterestInOffer';
import {
  getInquiryCompeonStatus,
  hasAcceptedCompeonOfferSelector,
  hasShownInterestInCompeonOfferSelector,
} from 'store/inquiryDetails/selectors';
import { isAgent } from 'store/user/selectors';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { textStyle } from '../../../../../theme/themeConstants';
import { OFFER_CREATED_AT_FORMAT, OFFER_STATE } from '../../constants';

export const isPartnerValueAdded = (offer: any) =>
  offer.fromPremiumPartner || offer.fromSpecialPartner;

export const getPartnerTypeName = (offer: any) => {
  let partnerName = 'basic';
  if (offer.fromPremiumPartner) {
    partnerName = 'premium';
  } else if (offer.fromSpecialPartner) {
    partnerName = 'special';
  }
  return partnerName;
};
interface Props {
  offer: ICompeonOffer;
  onDownloadClick: (fileId: string, bankName: string) => void;
}

const OfferBox = ({ offer, onDownloadClick }: Props) => {
  const { bankName, productName, fileId, requiredFiles, filesRequiredByBank } = offer;
  const t = useTranslations('pages.inquiryDetails.offers.compeon.properties.product');
  const tDefault = useTranslations();
  const isOperationUser = useSelector(isAgent);
  const compeonStatus = useSelector(getInquiryCompeonStatus);

  const canShowInterest =
    compeonStatus === CompeonStatus.new ||
    compeonStatus === CompeonStatus.pending ||
    compeonStatus === CompeonStatus.awaitingReview ||
    compeonStatus === CompeonStatus.awaitingRelease ||
    compeonStatus === CompeonStatus.open;
  const isOfferUnavailable = offer.state === OFFER_STATE.UNAVAILABLE;
  const hasAcceptedCompeonOffer = useSelector(hasAcceptedCompeonOfferSelector);
  const hasShownInterestInCompeonOffer = useSelector(hasShownInterestInCompeonOfferSelector);
  const shouldDisableAcceptingOffer =
    hasAcceptedCompeonOffer ||
    isOperationUser ||
    compeonStatus !== CompeonStatus.awaitingDecision ||
    hasShownInterestInCompeonOffer ||
    isOfferUnavailable;
  const shouldDisableShowingInterest =
    !canShowInterest ||
    hasAcceptedCompeonOffer ||
    isOperationUser ||
    hasShownInterestInCompeonOffer ||
    isOfferUnavailable;
  const isAcceptedOrInterested = offer.status === 'accepted' || offer.hasShownInterest;

  const properties: ICompeonOfferProperty[] | undefined = offer.properties?.filter(
    (property): property is ICompeonOfferProperty & { label: string } => !!property.label,
  );

  const partner = tDefault(
    'pages.inquiryDetails.offers.compeon.partner.' + getPartnerTypeName(offer),
  );

  return (
    <>
      <Box>
        <Wrapper>
          <Heading>{bankName}</Heading>
          <TextComponent
            color={isPartnerValueAdded(offer) ? 'brand.default' : 'text.tertiary'}
            {...textStyle.sizeL}
            mb={4}
            textAlign="center"
          >
            {partner}
          </TextComponent>
          <InformationGroup>
            <dt>{t('createdAt')}</dt>
            <dd>{moment(offer.createdAt).format(OFFER_CREATED_AT_FORMAT)}</dd>
          </InformationGroup>
          <InformationGroup>
            <dt>{t('caption')}</dt>
            <dd>{t(`options.${productName}`)}</dd>
          </InformationGroup>
          <StyledPropertiesWrapper>
            {properties?.map(({ label, value }) => (
              <InformationGroup key={label}>
                <dt>{label}</dt>
                <dd>{value}</dd>
              </InformationGroup>
            ))}

            {!!requiredFiles.length && (
              <InformationGroup>
                <dt>{t('requiredFiles')}</dt>
                {requiredFiles.map((file: string) => (
                  <dd key={file}>{file}</dd>
                ))}
              </InformationGroup>
            )}

            {!!filesRequiredByBank.length && (
              <InformationGroup>
                <dt>{t('requiredFiles')}</dt>
                <ul>
                  {filesRequiredByBank.map((file: IBankFile) => (
                    <dd key={file.id}>
                      <li>{file.name}</li>
                    </dd>
                  ))}
                </ul>
              </InformationGroup>
            )}
          </StyledPropertiesWrapper>
          {!isAcceptedOrInterested &&
          (hasAcceptedCompeonOffer || hasShownInterestInCompeonOffer) ? null : (
            <ButtonContainer>
              {fileId && (
                <ButtonComponent
                  leftIcon={<DownloadIcon boxSize={6} d="block" />}
                  onClick={() => onDownloadClick(fileId, bankName)}
                  w="100%"
                  mb={4}
                  data-testid="Download-Button"
                >
                  {tDefault('components.download.button')}
                </ButtonComponent>
              )}
              <ShowInterestInOffer
                bankName={offer.bankName}
                disabled={shouldDisableShowingInterest}
                offer={offer}
              />
              <AcceptOffer
                bankName={offer.bankName}
                offer={offer}
                disabled={shouldDisableAcceptingOffer}
              />
            </ButtonContainer>
          )}
        </Wrapper>
        {isAcceptedOrInterested && (
          <OfferAcceptedMsg status={offer.hasShownInterest ? 'shownInterest' : 'accepted'} />
        )}
        {isOfferUnavailable && (
          <ChakraBox
            bg="red.200"
            w="100%"
            p={4}
            color="red.500"
            fontSize="1.2em"
            textAlign={'center'}
          >
            {t('offerNotAvailable')}
          </ChakraBox>
        )}
      </Box>
    </>
  );
};

export default OfferBox;
