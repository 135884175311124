import React, { useCallback, useEffect, useState } from 'react';

import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import PortalPage from 'components/PortalPage';
import Spinner from 'components/Spinner';
import { Text } from 'components/Text';
import SectionHeader from 'modules/SectionHeader/SectionHeader';
import Form from 'pages/ChangePassword/Form';
import { validateRessetPasswordTokenAction } from 'pages/ChangePassword/service';
import intlShape from 'shared/intlShape';
import { withIntl } from 'utils/intl';
import { getObjectFromQueryParamsString } from 'utils/paths';

const ChangePassword = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  const [isTokenExpired, setIsTokenExpired] = useState();

  const pageTitle = formatMessage({ id: 'pages.changePassword.heading' });

  const validateToken = useCallback(
    async (token) => {
      try {
        const { payload } = await dispatch(validateRessetPasswordTokenAction(token));
        const isTokenValid = _get(payload, 'data.data.valid');

        if (!isTokenValid) {
          setIsTokenExpired(true);
        }
      } catch (err) {
        setIsTokenExpired(true);
      }
      setIsLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    const { token } = getObjectFromQueryParamsString(window.location.search);

    if (token) {
      setResetPasswordToken(token);
      validateToken(token);
    }
  }, [validateToken]);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!resetPasswordToken || isTokenExpired) {
    return (
      <PortalPage pageTitle={pageTitle}>
        <Text data-testid="change-password-link-expired">
          {formatMessage({ id: 'pages.changePassword.linkExpired' })}
        </Text>
      </PortalPage>
    );
  }

  return (
    <PortalPage pageTitle={pageTitle} biggerHeading>
      <SectionHeader
        subheadingTranslationKey="pages.changePassword.subheading"
        leadTranslationKey="pages.changePassword.lead"
        stylesThemeKey="CustomerLoginHeading"
      />
      <Form resetPasswordToken={resetPasswordToken} />
    </PortalPage>
  );
};

ChangePassword.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(withIntl, withRouter)(ChangePassword);
