export const FINAL_PAGE_STATE = 'finalPageState';
export const FINANCING_NEED = 'financingNeed';
export const COMPANY_DETAILS = 'companyDetails';
export const REQUEST_DETAILS = 'financingDetails';
export const USER_PROFILE = 'userProfile';
export const PEOPLE_DETAILS = 'peopleDetails';
export const INSTALMENT_PROCESS = 'instalmentProcess';
export const FORM_VALUES = 'formValues';
export const FORM_VALIDITY = 'formValidity';
export const CONTACT_PERSON_DETAILS = 'contactPerson';

export const KEY_REFERRAL_WIDGET_CODE = 'referralWidgetCode';
export const KEY_INQUIRY_PROCESS = 'inquiryProcess';

export const CONTRACT_DETAILS = 'contractDetails';
export const CUSTOMER_DATA = 'customerData';

export const PLANNING_EVALUATION_EDIT_FORM_STRUCTURE = 'editFormStructurePlanningEvaluation';

export const SELECTED_LANGUAGE = 'selectedLanguage';
export const APPLICATION_CONFIG = 'applicationConfig';
