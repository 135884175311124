import { DEFAULT_CURRENCY } from 'constants/globalConstants';

// financing/sale-type parameters types and enums
export enum PROFITABILITY_SALE_TYPE_KEY {
  PROFITABILITY_FEE = 'profitability_fee',
  PROFITABILITY_FINANCING_COSTS = 'profitability_financing_costs',
  PROFITABILITY_SENTENCE_COSTS = 'profitability_sentence_costs',
  PROFITABILITY_FINANCING_AMOUNT = 'profitability_financing_amount',
  PROFITABILITY_FINANCING_DURATION = 'profitability_financing_duration',
  PROFITABILITY_COMPENSATION_PERCENT = 'profitability_compensation_percent',
  PROFITABILITY_INTEREST_RATE_LONG_TERM = 'profitability_interest_rate_long_term',
  PROFITABILITY_INTEREST_RATE_SHORT_TERM = 'profitability_interest_rate_short_term',
  PROFITABILITY_REPAYMENT_RATE = 'profitability_repayment_rate',
}

export const probabilitySaleTypeObjectTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FEE]: 'feeEuro',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_AMOUNT]: 'financingAmount',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_COSTS]: 'financingCost',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_DURATION]: 'duration',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_COMPENSATION_PERCENT]: 'compensationPercent',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_INTEREST_RATE_LONG_TERM]: 'interestRateLongTerm',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_INTEREST_RATE_SHORT_TERM]: 'interestRateShortTerm',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_SENTENCE_COSTS]: 'sentenceCost',
  [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_REPAYMENT_RATE]: 'repaymentRate',
};

export const probabilitySaleTypeEvalUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const month = `${unitTranslations('other.months')}`;
  const percentage = `${unitTranslations('other.percent')}`;
  return {
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FEE]: DEFAULT_CURRENCY,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_AMOUNT]: DEFAULT_CURRENCY,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_COSTS]: DEFAULT_CURRENCY,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_FINANCING_DURATION]: month,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_COMPENSATION_PERCENT]: percentage,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_INTEREST_RATE_LONG_TERM]: percentage,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_SENTENCE_COSTS]: DEFAULT_CURRENCY,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_INTEREST_RATE_SHORT_TERM]: percentage,
    [PROFITABILITY_SALE_TYPE_KEY.PROFITABILITY_REPAYMENT_RATE]: percentage,
  };
};

// Area/usage-type parameters types and enums

export enum PROFITABILITY_USAGE_TYPE_KEY {
  COMMERCIAL_SPACE = 'commercial_space',
  GARAGE_PARKING_LOTS = 'garage_parking_lots',
  LIVING_SPACE = 'living_space',
  PARKING_LOTS = 'parking_lots',
  TOTAL_AREA = 'total_area',
  TOTAL_PARKING_LOTS = 'total_parking_lots',
  TOTAL_UNITS = 'total_units',
  UNIT_COMMERCIAL = 'unit_commercial',
  UNITS_HOUSING = 'units_housing',
}

// The order of this list is used to sort area information list on FE
export const probabilityUsageTypeObjectTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_USAGE_TYPE_KEY.LIVING_SPACE]: 'livingSpace',
  [PROFITABILITY_USAGE_TYPE_KEY.COMMERCIAL_SPACE]: 'commercialSpace',
  [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_AREA]: 'totalArea',
  [PROFITABILITY_USAGE_TYPE_KEY.UNITS_HOUSING]: 'numberOfHousingUnits',
  [PROFITABILITY_USAGE_TYPE_KEY.UNIT_COMMERCIAL]: 'commercialUnits',
  [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_UNITS]: 'totalUnits',
  [PROFITABILITY_USAGE_TYPE_KEY.PARKING_LOTS]: 'parkingLots',
  [PROFITABILITY_USAGE_TYPE_KEY.GARAGE_PARKING_LOTS]: 'garageParkingSpaces',
  [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_PARKING_LOTS]: 'totalParking',
};

export const probabilityUsageTypeEvalUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const m2 = `${unitTranslations('other.m2')}`;
  const space = `${unitTranslations('other.space')}`;
  const units = `${unitTranslations('other.units')}`;
  return {
    [PROFITABILITY_USAGE_TYPE_KEY.COMMERCIAL_SPACE]: m2,
    [PROFITABILITY_USAGE_TYPE_KEY.GARAGE_PARKING_LOTS]: space,
    [PROFITABILITY_USAGE_TYPE_KEY.LIVING_SPACE]: m2,
    [PROFITABILITY_USAGE_TYPE_KEY.PARKING_LOTS]: space,
    [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_AREA]: m2,
    [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_PARKING_LOTS]: space,
    [PROFITABILITY_USAGE_TYPE_KEY.TOTAL_UNITS]: units,
    [PROFITABILITY_USAGE_TYPE_KEY.UNIT_COMMERCIAL]: units,
    [PROFITABILITY_USAGE_TYPE_KEY.UNITS_HOUSING]: units,
  };
};

export enum PROFITABILITY_PRE_SALE_KEYS {
  PROFITABILITY_TOTAL_AREA = 'profitability_total_area',
  PROFITABILITY_RESIDENTIAL = 'profitability_residential',
  PROFITABILITY_AREA_IN_ADVANCE = 'profitability_area_in_advance',
  PROFITABILITY_COMMERCIAL = 'profitability_commercial',
  PROFITABILITY_PARKING_SPACES_IN_ADVANCE = 'profitability_parking_spaces_in_advance',
  PROFITABILITY_GARAGE_PARKING_SPACES_IN_ADVANCE = 'profitability_garage_parking_spaces_in_advance',
}

export const probabilityPreSaleObjectTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_TOTAL_AREA]: 'totalArea',
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_AREA_IN_ADVANCE]: 'areaInAdvance',
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_RESIDENTIAL]: 'residential',
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_COMMERCIAL]: 'commercial',
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_PARKING_SPACES_IN_ADVANCE]: 'parkingSpacesInAdvance',
  [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_GARAGE_PARKING_SPACES_IN_ADVANCE]:
    'garageParkingSpacesInAdvance',
};

export const probabilityPreSaleTypeEvalUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const area = unitTranslations('other.m2');
  const percentage = unitTranslations('other.percent');
  return {
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_TOTAL_AREA]: {
      area: area,
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_RESIDENTIAL]: {
      area: area,
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_AREA_IN_ADVANCE]: {
      area: area,
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_COMMERCIAL]: {
      area: area,
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_PARKING_SPACES_IN_ADVANCE]: {
      area: '',
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
    [PROFITABILITY_PRE_SALE_KEYS.PROFITABILITY_GARAGE_PARKING_SPACES_IN_ADVANCE]: {
      area: '',
      share_of_whole_area: percentage,
      price_per_sqm: DEFAULT_CURRENCY,
      revenue: DEFAULT_CURRENCY,
    },
  };
};

/* Top Tiles Keys */
export enum PROFITABILITY_TOP_TILES_KEYS {
  TOTAL_AREA = 'total_area',
  PROFITABILITY_FINANCING_AMOUNT = 'profitability_financing_amount',
  PROFITABILITY_FINANCING_COSTS = 'profitability_financing_costs',
  PROFITABILITY_SUM_OF_TOTAL_COST = 'profitability_sum_of_total_costs',
}

export const probabilityTopTilesTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_TOP_TILES_KEYS.TOTAL_AREA]: 'totalArea',
  [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_FINANCING_AMOUNT]: 'financingAmount',
  [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_FINANCING_COSTS]: 'financingCosts',
  [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_SUM_OF_TOTAL_COST]: 'sumOfTotalCosts',
};

export const probabilityTopTilesUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const area = unitTranslations('other.m2');
  return {
    [PROFITABILITY_TOP_TILES_KEYS.TOTAL_AREA]: area,
    [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_FINANCING_AMOUNT]: DEFAULT_CURRENCY,
    [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_FINANCING_COSTS]: DEFAULT_CURRENCY,
    [PROFITABILITY_TOP_TILES_KEYS.PROFITABILITY_SUM_OF_TOTAL_COST]: DEFAULT_CURRENCY,
  };
};

/* Top Tiles Keys */
export enum PROFITABILITY_BOTTOM_TILES_KEYS {
  NEEDED_COSTS_PER_SQM = 'needed_costs_per_sqm',
  NEEDED_COSTS_PER_SQM_WITH_PRESALES = 'needed_costs_per_sqm_with_presales',
  PRESALE_RATE = 'presale_rate',
  REE_UNION = 'ree_opinion',
}

export const probabilityBottomTilesTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_BOTTOM_TILES_KEYS.NEEDED_COSTS_PER_SQM]: 'neededCostsPerSqm',
  [PROFITABILITY_BOTTOM_TILES_KEYS.NEEDED_COSTS_PER_SQM_WITH_PRESALES]:
    'neededCostsPerSqmWithPresales',
  [PROFITABILITY_BOTTOM_TILES_KEYS.PRESALE_RATE]: 'presaleRate',
  [PROFITABILITY_BOTTOM_TILES_KEYS.REE_UNION]: 'reeOpinion',
};

export const probabilityBottomTilesUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const percentage = unitTranslations('other.percent');
  return {
    [PROFITABILITY_BOTTOM_TILES_KEYS.NEEDED_COSTS_PER_SQM]: DEFAULT_CURRENCY,
    [PROFITABILITY_BOTTOM_TILES_KEYS.NEEDED_COSTS_PER_SQM_WITH_PRESALES]: DEFAULT_CURRENCY,
    [PROFITABILITY_BOTTOM_TILES_KEYS.PRESALE_RATE]: percentage,
    [PROFITABILITY_BOTTOM_TILES_KEYS.REE_UNION]: DEFAULT_CURRENCY,
  };
};

export enum PROFITABILITY_SALE_AS_BUILDING {
  PROFITABILITY_SALES_REVENUE_RESIDENTIAL = 'profitability_sales_revenue_residential',
  PROFITABILITY_SALES_REVENUE_COMMERCIAL = 'profitability_sales_revenue_commercial',
  PROFITABILITY_SURPLUS_EURO = 'profitability_surplus_euro',
  PROFITABILITY_SURPLUS_PERCENT = 'profitability_surplus_percent',
  PROFITABILITY_USUAL_SALES_COST = 'profitability_usual_sales_cost',
  PROFITABILITY_SALES_REVENUE_GARAGES = 'profitability_sales_revenue_gararges',
  PROFITABILITY_SALES_REVENUE_TOTAL = 'profitability_sales_revenue_total',
  PROFITABILITY_SALES_REVENUE_PARKING_LOTS = 'profitability_sales_revenue_parking_lots',
  PROFITABILITY_INVESTMENT_COST = 'profitability_investment_cost',
  PROFITABILITY_EXCESS = 'profitablity_excess_percent',
}

export const probabilitySaleAsBuildingTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_RESIDENTIAL]:
    'salesRevenueResidential',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_COMMERCIAL]: 'salesRevenueCommercial',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_PARKING_LOTS]:
    'salesRevenueParkingLots',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_GARAGES]: 'salesRevenueGarages',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SALES_REVENUE_TOTAL]: 'salesRevenueTotal',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_INVESTMENT_COST]: 'investmentCost',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_USUAL_SALES_COST]: 'usualSalesCost',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SURPLUS_EURO]: 'surplusEuro',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SURPLUS_PERCENT]: 'surplusPercent',
  [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_EXCESS]: 'excess',
};

export const probabilitySaleAsBuildingUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const percentage = unitTranslations('other.percent');
  return {
    [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_EXCESS]: percentage,
    [PROFITABILITY_SALE_AS_BUILDING.PROFITABILITY_SURPLUS_PERCENT]: percentage,
  };
};

export enum PROFITABILITY_LENDING_VALUE_DETERMINATION {
  PROFITABILITY_DEDUCTION = 'profitability_deduction',
  PROFITABILITY_COMMERCIAL = 'profitability_commercial',
  PROFITABILITY_LOAN_VALUE = 'profitability_loan_value',
  PROFITABILITY_RESIDENTIAL = 'profitability_residential',
  PROFITABILITY_PARKING_LOTS = 'profitability_parking_lots',
  PROFITABILITY_GARAGE_PARKING_SPACES = 'profitability_garage_parking_spaces',
  PROFITABILITY_DEDUCTION_FOR_ROUNDING = 'profitability_deduction_for_rounding',
  PROFITABILITY_BLANK_PORTION_COMMERCIAL = 'profitability_blank_portion_commercial',
  PROFITABILITY_BLANK_PORTION_MIXED_USED = 'profitability_blank_portion_mixed_used',
  PROFITABILITY_BLANK_PORTION_RESIDENTIAL = 'profitability_blank_portion_residential',
  PROFITABILITY_LOAN_TO_VALUE_LIMIT_70_PERCENT = 'profitability_loan_to_value_limit_70_percent',
  PROFITABILITY_LOAN_TO_VALUE_LIMIT_80_PERCENT = 'profitability_loan_to_value_limit_80_percent',
  PROFITABILITY_VALUATION_APPROACH_TANGIBLE_ASSETS = 'profitability_Valuation_approach_tangible_assets',
  PROFITABILITY_MORTGAGE_CREDIT_LIMIT_PFANDBG_60_PERCENT = 'profitability_mortgage_credit_limit_PfandBG_60_percent',
}

export const probabilityLendingValueTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_RESIDENTIAL]: 'residential',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_COMMERCIAL]: 'commercial',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_PARKING_LOTS]: 'parkingLots',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_GARAGE_PARKING_SPACES]:
    'garageParkingSpaces',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_VALUATION_APPROACH_TANGIBLE_ASSETS]:
    'valuationApproachTangibleAssets',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_DEDUCTION]: 'deduction',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_DEDUCTION_FOR_ROUNDING]:
    'deductionForRounding',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_VALUE]: 'loanValue',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_TO_VALUE_LIMIT_80_PERCENT]:
    'loanToValueLimitEightyPercent',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_TO_VALUE_LIMIT_70_PERCENT]:
    'loanToValueLimitSeventyPercent',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_MORTGAGE_CREDIT_LIMIT_PFANDBG_60_PERCENT]:
    'mortgageCreditLimitSixtyPercent',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_RESIDENTIAL]:
    'blankPortionResidential',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_MIXED_USED]:
    'blankPortionMixedUsed',
  [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_COMMERCIAL]:
    'blankPortionCommercial',
};

export const probabilityLendingValueUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  const percentage = unitTranslations('other.percent');
  return {
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_DEDUCTION]: DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_COMMERCIAL]: DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_VALUE]: DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_RESIDENTIAL]: DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_PARKING_LOTS]: DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_GARAGE_PARKING_SPACES]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_DEDUCTION_FOR_ROUNDING]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_COMMERCIAL]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_MIXED_USED]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_BLANK_PORTION_RESIDENTIAL]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_TO_VALUE_LIMIT_70_PERCENT]:
      percentage,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_LOAN_TO_VALUE_LIMIT_80_PERCENT]:
      percentage,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_VALUATION_APPROACH_TANGIBLE_ASSETS]:
      DEFAULT_CURRENCY,
    [PROFITABILITY_LENDING_VALUE_DETERMINATION.PROFITABILITY_MORTGAGE_CREDIT_LIMIT_PFANDBG_60_PERCENT]:
      percentage,
  };
};

export enum PROFITABILITY_SALE_AS_RENT {
  PROFITABILITY_TAXES = 'profitability_taxes',
  PROFITABILITY_REPAYMENT = 'profitability_repayment',
  PROFITABILITY_ECF_AFTER_TAXES = 'profitability_ecf_after_taxes',
  PROFITABILITY_INTEREST_CHARGES = 'profitability_interest_charges',
  PROFITABILITY_NOI_BEFORE_TAXES = 'profitability_noi_before_taxes',
  PROFITABILITY_OVER_UNDER_FUNDING = 'profitability_over_under_funding',
  PROFITABILITY_RENT_LIVING_PER_SQM = 'profitability_rent_living_per_sqm',
  PROFITABILITY_OUTCOME_AFTER_TAXES = 'profitability_outcome_after_taxes',
  PROFITABILITY_TOTAL_RENTAL_INCOME = 'profitability_total_rental_income',
  PROFITABILITY_EARNING_BEFORE_TAXES = 'profitability_earning_before_taxes',
  PROFITABILITY_RENTAL_INCOME_HOUSING = 'profitability_rental_income_housing',
  PROFITABILITY_RENTAL_INCOME_BUSINESS = 'profitability_rental_income_business',
  PROFITABILITY_RENT_COMMERCIAL_PER_SQM = 'profitability_rent_commercial_per_sqm',
  PROFITABILITY_HOUSING_MANAGEMENT_COST = 'profitability_housing_management_cost',
  PROFITABILITY_BUSINESS_MANAGEMENT_COST = 'profitability_business_management_cost',
  PROFITABILITY_CALCULATED_INTEREST_CHARGES = 'profitability_calculated_interest_charges',
  PROFITABILITY_RENTAL_INCOME_PARKING_SPACE = 'profitability_rental_income_parking_space',
  PROFITABILITY_RENT_PARKING_SPACE_PER_PIECE = 'profitability_rent_parking_space_per_piece',
  PROFITABILITY_RENT_PER_GARAGE_SPACE_PER_PIECE = 'profitability_rent_per_garage_space_per_piece',
  PROFITABILITY_RENTAL_INCOME_GARAGE_PARKING_SPACES = 'profitability_rental_income_garage_parking_spaces',
  PROFITABILITY_DEPRECIATION = 'profitability_depreciation',
}

export enum PROFITABILITY_SALE_AS_RENT_HEADERS {
  LABEL = 'label',
  CUSTOMER_CURRENT = 'current_customer',
  CUSTOMER_CURRNET = 'currnet_customer',
  CUSTOMER_FUTURE = 'future_customer',
  MARKET_DATA = 'from_market_data',
  REE = 'from_ree',
}

export enum PROFITABILITY_LENDING_VALUE_DETERMINATION_HEADERS {
  TYPE = 'type',
  REE_VALUES = 'ree_values_for_building',
  SECURITY_MARKDOWN = 'safety_markdown',
  VALUE = 'value',
}

export const profitabilitySaleAsRentValueTranslationsMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENT_LIVING_PER_SQM]: 'rentLivingPerSqm',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENT_COMMERCIAL_PER_SQM]: 'rentCommercialPerSqm',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENT_PARKING_SPACE_PER_PIECE]:
    'rentParkingSpacePerPiece',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENT_PER_GARAGE_SPACE_PER_PIECE]:
    'rentPerGarageSpacePerPiece',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENTAL_INCOME_HOUSING]: 'rentalIncomeHousing',

  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENTAL_INCOME_BUSINESS]: 'rentalIncomeBusiness',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENTAL_INCOME_PARKING_SPACE]:
    'rentalIncomeParkingSpace',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_RENTAL_INCOME_GARAGE_PARKING_SPACES]:
    'rentalIncomeGarageParkingSpaces',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_TOTAL_RENTAL_INCOME]: 'totalRentalIncome',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_HOUSING_MANAGEMENT_COST]: 'housingManagementCost',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_BUSINESS_MANAGEMENT_COST]: 'businessManagementCost',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_NOI_BEFORE_TAXES]: 'noiBeforeTaxes',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_INTEREST_CHARGES]: 'interestCharges',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_DEPRECIATION]: 'depreciation',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_EARNING_BEFORE_TAXES]: 'earningBeforeTaxes',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_TAXES]: 'taxes',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_OUTCOME_AFTER_TAXES]: 'outcomeAfterTaxes',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_ECF_AFTER_TAXES]: 'ecfAfterTaxes',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_CALCULATED_INTEREST_CHARGES]:
    'calculatedInterestCharges',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_REPAYMENT]: 'repayment',
  [PROFITABILITY_SALE_AS_RENT.PROFITABILITY_OVER_UNDER_FUNDING]: 'overUnderFunding',
};

export enum PROFITABILITY_INVESTMENT_COST {
  PROFITABILITY_PURCHASE_PRICE = 'profitability_purchase_price',
  PROFITABILITY_COURT_NOTARY_COSTS = 'profitability_court_notary_costs',
  PROFITABILITY_AGENT_FEE = 'profitability_agent_fee',
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX = 'profitability_real_estate_transfer_tax',
  PROFITABILITY_SURVEY_COSTS = 'profitability_survey_costs',
  PROFITABILITY_FEES_VALUE_CALCULATIONS_OFFICIAL_APPROVALS_DIVISION = 'profitability_fees_value_calculations_official_approvals_division',
  PROFITABILITY_SOIL_SURVEY_COSTS = 'profitability_soil_survey_costs',
  PROFITABILITY_OTHER_ACQUISITION_COST = 'profitability_other_acquisition_cost',
  PROFITABILITY_SEVERANCE_PAYMENTS_THIRD_PARTIES_COMPENSATION = 'profitability_severance_payments_third_parties_compensation',
  PROFITABILITY_COSTS_FOR_PREPARATION_DEVELOPMENT = 'profitability_costs_for_preparation_development',
  PROFITABILITY_COSTS_OF_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS = 'profitability_costs_of_public_drainage_supply_systems',
  PROFITABILITY_COSTS_OF_NON_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS = 'profitability_costs_of_non_public_drainage_supply_systems',
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS = 'profitability_other_development_costs',
  PROFITABILITY_SUM_OF_BUILDING_PLOT_COSTS = 'profitability_sum_of_building_plot_costs',
  PROFITABILITY_CONSTRUCTION_WORK_MODERNIZATION_CONVERSION_COSTS = 'profitability_construction_work_modernization_conversion_costs',
  PROFITABILITY_COST_OF_OUTDOOR_FACILITIES = 'profitability_cost_of_outdoor_facilities',
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS = 'profitability_other_construction_costs',
  PROFITABILITY_SUM_OF_CONSTRUCTION_COSTS = 'profitability_sum_of_construction_costs',
  PROFITABILITY_COSTS_FOR_ARCHITECT_ENGINEERING_SERVICE = 'profitability_costs_for_architect_engineering_service',
  PROFITABILITY_OTHER_ANCILLARY_CONSTRUCTION_COSTS = 'profitability_other_ancillary_construction_costs',
  PROFITABILITY_SUM_OF_ADDITIONAL_CONSTRUCTION_COSTS = 'profitability_sum_of_additional_construction_costs',
  PROFITABILITY_FINANCING_COSTS_THIRD_PARTY_FUNDS = 'profitability_financing_costs_third_party_funds',
  PROFITABILITY_FINANCING_COSTS_INTEREST = 'profitability_financing_costs_interest',
  PROFITABILITY_FINANCING_COSTS_FEES_SPECIAL_REPAYMENTS = 'profitability_financing_costs_fees_special_repayments',
  PROFITABILITY_SUM_OF_FINANCING_COSTS = 'profitability_sum_of_financing_costs',
  PROFITABILITY_COST_BUFFER = 'profitability_cost_buffer',
  PROFITABILITY_MARKETING_SALES_EXPENSES = 'profitability_marketing_sales_expenses',
  PROFITABILITY_SUM_OF_TOTAL_COSTS = 'profitability_sum_of_total_costs',
  PROFITABILITY_EQUITY_CAPITAL = 'profitability_equity_capital',
  PROFITABILITY_PERSONAL_CONTRIBUTION = 'profitability_personal_contribution',
  PROFITABILITY_MEZZANINE_CAPITALS = 'profitability_mezzanine_capitals',
  PROFITABILITY_SUBORDINATE_CAPITAL = 'profitability_subordinate_capital',
  PROFITABILITY_FINANCING_AMOUNT = 'profitability_financing_amount',
}

export const probabilityInvestmentCalculationTranslationMapping: {
  [key: string]: string;
} = {
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_PURCHASE_PRICE]: 'purchasePrice',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COURT_NOTARY_COSTS]: 'courtNotaryCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_AGENT_FEE]: 'agentFee',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]: 'realEstateTransferTax',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SURVEY_COSTS]: 'surveyCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FEES_VALUE_CALCULATIONS_OFFICIAL_APPROVALS_DIVISION]:
    'feesValueCalculationsOfficialApprovalsDivision',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SOIL_SURVEY_COSTS]: 'soilSurveyCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_ACQUISITION_COST]: 'otherAcquisitionCost',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SEVERANCE_PAYMENTS_THIRD_PARTIES_COMPENSATION]:
    'severancePaymentsThirdPartiesCompensation',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_FOR_PREPARATION_DEVELOPMENT]:
    'costsForPreparationDevelopment',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_OF_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS]:
    'costsOfPublicDrainageSupplySystems',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_OF_NON_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS]:
    'costsOfNonPublicDrainageSupplySystems',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_DEVELOPMENT_COSTS]: 'otherDevelopmentCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_BUILDING_PLOT_COSTS]:
    'sumOfBuildingPlotCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_CONSTRUCTION_WORK_MODERNIZATION_CONVERSION_COSTS]:
    'constructionWorkModernizationConversionCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COST_OF_OUTDOOR_FACILITIES]:
    'costOfOutdoorFacilities',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_CONSTRUCTION_COSTS]: 'otherConstructionCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_CONSTRUCTION_COSTS]: 'sumOfConstructionCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_FOR_ARCHITECT_ENGINEERING_SERVICE]:
    'costsForArchitectEngineeringService',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_ANCILLARY_CONSTRUCTION_COSTS]:
    'otherAncillaryConstructionCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_ADDITIONAL_CONSTRUCTION_COSTS]:
    'sumOfAdditionalConstructionCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_THIRD_PARTY_FUNDS]:
    'financingCostsThirdPartyFunds',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_INTEREST]: 'financingCostsInterest',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_FEES_SPECIAL_REPAYMENTS]:
    'financingCostsFeesSpecialRepayments',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_FINANCING_COSTS]: 'sumOfFinancingCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COST_BUFFER]: 'costBuffer',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_MARKETING_SALES_EXPENSES]: 'marketingSalesExpenses',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_TOTAL_COSTS]: 'sumOfTotalCosts',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_EQUITY_CAPITAL]: 'equityCapital',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_PERSONAL_CONTRIBUTION]: 'personalContribution',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_MEZZANINE_CAPITALS]: 'mezzanineCapitals',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUBORDINATE_CAPITAL]: 'subordinateCapital',
  [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_AMOUNT]: 'financingAmount',
};

enum UNIT_TYPE {
  BUILDING_PRICE = 'buildingPrice',
  PURCHASE_PRICE = 'purchasePrice',
  TOTAL_COST = 'totalCost',
}

const investmentCostUnits = (
  unitTranslations: (translationPath: string) => string,
  type?: UNIT_TYPE,
) => {
  const percentage = unitTranslations('other.percent');
  const percentageOnPurchasePrice = `${percentage} ${unitTranslations('other.purchasePrice')}`;
  const percentageOnBuildingPrice = `${percentage} ${unitTranslations('other.buildingCost')}`;
  const percentageOnTotalCost = `${percentage} ${unitTranslations('other.totalCost')}`;

  let unit = percentage;

  if (type === UNIT_TYPE.BUILDING_PRICE) unit = percentageOnBuildingPrice;
  else if (type === UNIT_TYPE.PURCHASE_PRICE) unit = percentageOnPurchasePrice;
  else if (type === UNIT_TYPE.TOTAL_COST) unit = percentageOnTotalCost;

  return {
    investment_costs: DEFAULT_CURRENCY,
    total_area_costs_per_sqm: DEFAULT_CURRENCY,
    percentage: unit,
  };
};
export const probabilityInvestmentCalculationUnitMapping: any = (
  unitTranslations: (translationPath: string) => string,
) => {
  return {
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_PURCHASE_PRICE]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COURT_NOTARY_COSTS]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.PURCHASE_PRICE,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_AGENT_FEE]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.PURCHASE_PRICE,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.PURCHASE_PRICE,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SURVEY_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FEES_VALUE_CALCULATIONS_OFFICIAL_APPROVALS_DIVISION]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SOIL_SURVEY_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_ACQUISITION_COST]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SEVERANCE_PAYMENTS_THIRD_PARTIES_COMPENSATION]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_FOR_PREPARATION_DEVELOPMENT]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_OF_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_OF_NON_PUBLIC_DRAINAGE_SUPPLY_SYSTEMS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_DEVELOPMENT_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_BUILDING_PLOT_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_CONSTRUCTION_WORK_MODERNIZATION_CONVERSION_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COST_OF_OUTDOOR_FACILITIES]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.BUILDING_PRICE,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_CONSTRUCTION_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_CONSTRUCTION_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COSTS_FOR_ARCHITECT_ENGINEERING_SERVICE]:
      investmentCostUnits(unitTranslations, UNIT_TYPE.BUILDING_PRICE),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_OTHER_ANCILLARY_CONSTRUCTION_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_ADDITIONAL_CONSTRUCTION_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_THIRD_PARTY_FUNDS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_INTEREST]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_COSTS_FEES_SPECIAL_REPAYMENTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_FINANCING_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_COST_BUFFER]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.BUILDING_PRICE,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_MARKETING_SALES_EXPENSES]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_TOTAL_COSTS]:
      investmentCostUnits(unitTranslations),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_EQUITY_CAPITAL]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.TOTAL_COST,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_PERSONAL_CONTRIBUTION]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.TOTAL_COST,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_MEZZANINE_CAPITALS]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.TOTAL_COST,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUBORDINATE_CAPITAL]: investmentCostUnits(
      unitTranslations,
      UNIT_TYPE.TOTAL_COST,
    ),
    [PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_AMOUNT]:
      investmentCostUnits(unitTranslations),
  };
};
