import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import paths from 'constants/paths';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import {
  APPLICATION_CONFIG_OBJECT,
  EDIT_INQUIRY_CALLER_COMPONENT_NAME,
} from 'store/applicationConfig/types';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { saveObjectToSessionStorage } from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG } from 'utils/sessionStorage/keys';
import { formatColumnValues } from 'utils/valueFormats';

import {
  probabilityUsageTypeEvalUnitMapping,
  probabilityUsageTypeObjectTranslationMapping,
} from '../../types';
import { useRoles } from '../hooks';
import TableCardWithEdit from '../TableCardWithEdit';

interface props {
  data: { [key: string]: string | number | object };
}

const AreaInformation: React.FC<props> = ({ data }: props) => {
  const { hasRoleBankAdvisorOrRealEstateExpert, hasRoleInquiryManager } = useRoles();
  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.areaInformation',
  );
  const unitTranslations = useTranslations();

  const headers = [
    {
      key: 'label',
      text: t('tableHeaders.parameter'),
    },
    {
      key: 'value',
      text: t('tableHeaders.value'),
    },
  ];

  const canEditInquiry = useCanEdit();

  const canEdit = (hasRoleBankAdvisorOrRealEstateExpert || hasRoleInquiryManager) && canEditInquiry;

  const inquiryId = useSelector(getInquiryIdSelector);

  const callerComponentName: APPLICATION_CONFIG_OBJECT = {
    [EDIT_INQUIRY_CALLER_COMPONENT_NAME]: PROFITABILITY_RECORD_TYPE.AREA_INFORMATION,
  };

  const onEdit = () => {
    saveObjectToSessionStorage(APPLICATION_CONFIG, callerComponentName);
  };

  return (
    <Box
      border="1px"
      borderColor="border.lightGrey"
      p="8"
      bgColor="background.white"
      boxShadow="default"
      h="100%"
    >
      <TableCardWithEdit
        canEdit={canEdit}
        onEdit={onEdit}
        editLink={paths.operation.inquiryDetails.edit.root.replace(':id', inquiryId!)}
        title={t('title')}
        tableHeaders={headers}
        componentName={PROFITABILITY_RECORD_TYPE.AREA_INFORMATION}
        tableData={
          data &&
          Object.keys(probabilityUsageTypeObjectTranslationMapping).map((key) => ({
            id: key,
            label: t(`tableRows.${probabilityUsageTypeObjectTranslationMapping[key]}`),
            ...(typeof data[key] === 'object'
              ? {
                  value: formatColumnValues(
                    (data[key] as Record<string, string>).value,
                    probabilityUsageTypeEvalUnitMapping(unitTranslations)[key],
                  ),
                  rowHighlight: true,
                  valueBold: true,
                }
              : {
                  value: formatColumnValues(
                    (data as Record<string, string>)[key],
                    probabilityUsageTypeEvalUnitMapping(unitTranslations)[key],
                  ),
                }),
          }))
        }
      />
    </Box>
  );
};

export default AreaInformation;
