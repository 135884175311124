import { formatDateDays, formatMonthWithYear } from '../../../../utils/date';
import {
  formatDecimalWithOutPrecision,
  formatKilometer,
  formatPercentage,
  formatPrice,
  formatPriceAsThousands,
  replaceDotWithComma,
} from '../../../../utils/valueFormats';

export type FieldUnitType =
  | 'defaultCurrency'
  | 'currency'
  | 'thousandsCurrency'
  | 'squareMeters'
  | 'quadraMeters'
  | 'kilometers'
  | 'years'
  | 'months'
  | 'percentage'
  | 'percentageDecimal'
  | 'date'
  | 'dateinput'
  | 'from1000'
  | 'm2m3'
  | 'monthAndYear'
  | 'invoices'
  | 'days'
  | 'currencyPerMonth'
  | 'none';

export type SummaryType = {
  unit: FieldUnitType;
  showOnSummaryPage: boolean;
};

export const unitFormatters: { [key in FieldUnitType]: Function | undefined } = {
  defaultCurrency: formatPrice,
  currency: formatPrice,
  thousandsCurrency: formatPriceAsThousands,
  squareMeters: replaceDotWithComma,
  quadraMeters: undefined,
  kilometers: formatKilometer,
  years: undefined,
  months: undefined,
  percentage: replaceDotWithComma,
  percentageDecimal: formatPercentage,
  date: formatDateDays,
  dateinput: undefined,
  from1000: undefined,
  m2m3: undefined,
  invoices: undefined,
  days: formatDecimalWithOutPrecision,
  monthAndYear: formatMonthWithYear,
  none: undefined,
  currencyPerMonth: undefined,
};

export const unitTypeSuffixMap: { [key in FieldUnitType]: (translator: Function) => any } = {
  defaultCurrency: (translator) => translator('other.defaultCurrency'),
  currency: (translator) => translator('currency.euro'),
  thousandsCurrency: (translator) => translator('currency.thousandEuro'),
  squareMeters: (translator) => translator('other.m2'),
  quadraMeters: (translator) => translator('other.qm'),
  kilometers: (translator) => translator('other.kilometers'),
  years: (translator) => translator('other.years'),
  months: (translator) => translator('other.months'),
  percentage: (translator) => translator('other.percent'),
  percentageDecimal: (translator) => translator('other.percent'),
  date: () => null,
  dateinput: () => null,
  from1000: (translator) => translator('other.from1000'),
  m2m3: (translator) => translator('other.m2m3'),
  invoices: (translator) => translator('other.invoices'),
  days: (translator) => translator('other.days'),
  monthAndYear: () => null,
  none: () => null,
  currencyPerMonth: (translator) => translator('other.currencyPerMonth'),
};
