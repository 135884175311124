import * as React from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { Solvency } from './TrafficLights';

type BubbleProps = {
  color: Solvency | undefined;
};

const Bubble = ({ color }: BubbleProps) => {
  const trafficLightColor = color ? `trafficLight.${color}` : 'border.lightGrey';
  return <Box w={8} h={8} borderRadius="circle" bgColor={trafficLightColor} />;
};

type BubblesProps = {
  colors: Array<Solvency | undefined>;
};

export const Bubbles = ({ colors }: BubblesProps) => {
  return (
    <Stack direction="row" spacing="2" role="presentation">
      {colors.map((color) => (
        <Bubble color={color} />
      ))}
    </Stack>
  );
};
