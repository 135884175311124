import styled from 'styled-components/macro';

import {
  FONT_WEIGHT_LIGHT,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_BOLD,
  PRIMARY_FONT,
} from 'constants/globalConstants';
import { inputStyles, fixInputWidthAndHeight } from 'styles/form';
import { boxShadow, zIndex } from 'theme/themeConstants';

const DatePickerWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background.white};

  div:first-of-type {
    position: relative;
  }

  .react-datepicker {
    width: 100%;
    border: 0;
    box-shadow: ${boxShadow.default};
    font-family: ${PRIMARY_FONT};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.background.white};

    &__navigation {
      outline: 0;

      &--previous {
        top: 1.8rem;
        left: 1.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-top: 2px solid ${(props) => props.theme.colors.primary};
        border-right: none;
        border-bottom: none;
        border-left: 2px solid ${(props) => props.theme.colors.primary};
        transform: rotate(-45deg);
      }

      &--next {
        top: 1.8rem;
        right: 1.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-top: 2px solid ${(props) => props.theme.colors.primary};
        border-right: 2px solid ${(props) => props.theme.colors.primary};
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg);
      }
    }

    &__header {
      border-bottom: none;
      background: none;

      &.react-datepicker-year-header {
        padding: 1.375rem 0 1rem;
        font-weight: ${FONT_WEIGHT_REGULAR};
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.text.tertiary};
      }
    }

    &__current-month {
      padding: 1rem 0;
      font-weight: ${FONT_WEIGHT_REGULAR};
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.text.tertiary};
    }

    &__day-name {
      position: relative;
      top: 6px;
      font-weight: ${FONT_WEIGHT_BOLD};
      color: ${(props) => props.theme.colors.text.primary};

      &::first-letter {
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    &-wrapper {
      width: 100%;
    }

    &__day,
    &__month-text,
    &__quarter-text,
    &__year-text {
      &:hover {
        background-color: ${(props) => props.theme.colors.background.darkGrey};
      }

      &--disabled {
        &:hover {
          background-color: transparent;
        }
      }
    }

    &__day {
      font-weight: ${FONT_WEIGHT_REGULAR};
      color: ${(props) => props.theme.colors.text.primary};

      &--selected,
      &--selected:hover {
        font-weight: ${FONT_WEIGHT_BOLD};
        color: ${(props) => props.theme.colors.primary};
        background: none;
      }

      &--keyboard-selected,
      &--keyboard-selected:hover {
        background: none;
      }

      &--disabled {
        color: ${(props) => props.theme.colors.text.tertiary};
        opacity: 0.3;
      }

      &.react-datepicker__day--in-range {
        background-color: transparent;

        &:hover {
          background-color: ${(props) => props.theme.colors.background.darkGrey};
        }

        &.react-datepicker__day--selected {
          &:hover {
            background-color: transparent;
            cursor: auto;
          }
        }
      }
    }

    &__year,
    &__year-text {
      padding: 1rem 0;
      font-weight: ${FONT_WEIGHT_REGULAR};
      color: ${(props) => props.theme.colors.text.primary};

      &--selected,
      &--selected:hover {
        font-weight: ${FONT_WEIGHT_BOLD};
        color: ${(props) => props.theme.colors.primary};
        background: none;
      }

      &--keyboard-selected,
      &--keyboard-selected:hover {
        background: none;
      }

      &--disabled {
        color: ${(props) => props.theme.colors.text.tertiary};
      }
    }

    &__year-text {
      font-size: 0.9rem;
      /* Eslint will mark this as a violation against rule 'declaration-no-important' but the year picker needs the width to work properly */
      /* stylelint-disable */
      width: 100% !important;
      /* stylelint-enable */
      &.react-datepicker__year--in-range {
        background-color: transparent;

        &:hover {
          background-color: ${(props) => props.theme.colors.background.darkGrey};
        }

        &.react-datepicker__year--selected {
          &:hover {
            background-color: transparent;
            cursor: auto;
          }
        }
      }
    }

    &__month,
    &__month-text {
      padding: 1rem 0;
      font-weight: ${FONT_WEIGHT_REGULAR};
      color: ${(props) => props.theme.colors.text.primary};

      &--selected,
      &--selected:hover {
        font-weight: ${FONT_WEIGHT_BOLD};
        color: ${(props) => props.theme.colors.primary};
        background: none;
      }

      &--keyboard-selected,
      &--keyboard-selected:hover {
        background: none;
      }

      &--disabled {
        color: ${(props) => props.theme.colors.text.tertiary};
      }
    }

    &__month-text {
      &.react-datepicker__month--in-range {
        background-color: transparent;

        &:hover {
          background-color: ${(props) => props.theme.colors.background.darkGrey};
        }

        &.react-datepicker__month--selected {
          &:hover {
            background-color: transparent;
            cursor: auto;
          }
        }
      }
    }

    &__day--weekend {
      color: ${(props) => props.theme.colors.text.tertiary};
    }

    &__day--outside-month,
    &__day--outside-month&__day--weekend {
      font-weight: ${FONT_WEIGHT_LIGHT};
      color: ${(props) => props.theme.colors.text.tertiary};
    }

    &__input-container {
      width: inherit;
    }

    &__input-container input {
      ${inputStyles()};
      ${fixInputWidthAndHeight()};
    }

    &-popper {
      width: 100%;
      z-index: ${zIndex.select};
    }

    &__triangle {
      display: none;
    }

    &__month-container {
      width: 100%;
      margin-top: 0;
    }

    &__year-container {
      width: 100%;
      margin-top: 0;
    }

    &__day-names,
    &__week,
    &__month-wrapper,
    &__year-wrapper {
      display: flex;
      padding: 0.4rem 0;
    }

    &__day-name,
    &__day,
    &__month-text,
    &__year-text {
      flex: 1 1 0%;
    }

    &-popper[data-placement^='bottom'] {
      margin-top: 0;
    }

    &-popper[data-placement^='top'] {
      margin-bottom: 0;
    }

    &__day-names {
      margin: 0 0.4rem;
    }

    &__year-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: inherit;
    }
  }
`;

export default DatePickerWrapper;
