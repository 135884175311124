import { useCallback } from 'react';

import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';

import { downloadFileAction } from 'store/documentExchange/documentExchange.actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useDownloadFile = ({ fileId, fileName }) => {
  const dispatch = useDispatch();
  return async () => {
    const response = await dispatch(downloadFileAction(fileId));
    const blob = new Blob([response.payload.data], {
      type: response.payload.headers['content-type'],
    });
    saveAs(blob, fileName);
  };
};

export const useDownloadFileFromResponse = (action) => {
  const { makeCall, isPending } = useDispatchApiCall();

  const makeDownloadCall = useCallback(
    async (fileName = '', onSuccess, ...actionParams) => {
      const { error, payload } = await makeCall(action(...actionParams));
      if (!error) {
        const blob = new Blob([payload.data], {
          type: payload.headers['content-type'],
        });
        saveAs(blob, fileName);
        if (onSuccess) {
          onSuccess(payload);
        }
      }
    },
    [action, makeCall],
  );
  return {
    makeDownloadCall,
    isPending,
  };
};
