import React from 'react';

import PropTypes from 'prop-types';

import RadioGroup from 'components/RadioGroup';
import intlShape from 'shared/intlShape';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { withIntl } from 'utils/intl';

export const ValueRadioGroupUnwrapped = ({ intl: { formatMessage }, ...props }) => {
  const handleChange = (ev) => props.onChange(ev.target.value);

  return (
    <RadioGroup
      options={[
        {
          caption: formatMessage({ id: 'components.valueRadioGroup.days45' }),
          value: '45',
        },
        {
          caption: formatMessage({ id: 'components.valueRadioGroup.days60' }),
          value: '60',
        },
      ]}
      {...props}
      onChange={handleChange}
    />
  );
};

ValueRadioGroupUnwrapped.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ValueRadioGroup = withIntl(ValueRadioGroupUnwrapped);

const fieldController = FormFieldController.instance;

const ValueRadioGroupAdapter = ({ input, meta, ...rest }) => {
  return (
    <ValueRadioGroup
      {...input}
      value={input.value}
      onChange={(value) => {
        input.onChange(value);
        fieldController.saveValue(input.name, value, { emit: false });
      }}
      {...meta}
      {...rest}
    />
  );
};
ValueRadioGroupAdapter.propTypes = FinalFormFieldPropTypes;

const ValueRadioGroupWithField = withFormField(ValueRadioGroupAdapter);

export default ValueRadioGroup;

export { ValueRadioGroupWithField };
