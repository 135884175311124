import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqMedium, mqLarge } from 'styles/breakpoints';

export const StyledNavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;

  ${mqMedium`
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    padding: 0;
  `}
`;

export const StyledNavElement = styled.li`
  width: 100%;
  list-style: none;
  padding: 1rem 0;

  ${mqMedium`
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  `}

  ${mqLarge`
    &:not(:last-child) {
      margin-right: 2rem;
    }
  `}

  > button {
    width: 100%;

    ${mqMedium`
      width: auto;
    `}
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;

  ${mqMedium`
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-13px);
  `}

  ${mqLarge`
    flex-direction: row;
    transform: translateY(0);
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${mqLarge`
    margin-left: 1rem;
  `}
`;

export const StyledGroupButton = styled.button<{
  first?: boolean;
  last?: boolean;
  active?: boolean;
}>`
  width: 100%;
  min-width: initial;
  padding: 0.75rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${(props) =>
    props.active ? props.theme.colors.text.white : props.theme.colors.text.secondary};
  background-color: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.background.darkGrey};

  ${mqMedium`
    width: auto;
    padding: 0.25rem 0.5rem;
    font-size: 1.125rem;

    ${(props) =>
      props.first &&
      `
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    `};

    ${(props) =>
      props.last &&
      `
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    `};
  `}

  ${mqLarge`
    padding: 0.75rem;
  `}

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.text.white};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
