import React from 'react';

import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { Cell, Row } from 'react-table';

const aktivaNumbering = [
  { value: 'A. ', isHeading: true },
  { value: 'I. ', isHeading: false },
  { value: 'II. ', isHeading: false },
  { value: 'III. ', isHeading: false },
  { value: 'B. ', isHeading: true },
  { value: 'I. ', isHeading: false },
  { value: 'II. ', isHeading: false },
  { value: 'III. ', isHeading: false },
  { value: 'IV. ', isHeading: false },
  { value: 'V. ', isHeading: false },
  { value: 'C. ', isHeading: true },
  { value: 'D. ', isHeading: true },
  { value: 'E. ', isHeading: true },
  { value: '', isHeading: false },
];

const passivaNumbering = [
  { value: 'A. ', isHeading: true },
  { value: 'I. ', isHeading: false },
  { value: 'II. ', isHeading: false },
  { value: 'III. ', isHeading: false },
  { value: 'B. ', isHeading: true },
  { value: 'C. ', isHeading: true },
  { value: 'D. ', isHeading: true },
  { value: 'I. ', isHeading: false },
  { value: 'II. ', isHeading: false },
  { value: 'III. ', isHeading: false },
  { value: 'E. ', isHeading: true },
  { value: 'F. ', isHeading: true },
  { value: '', isHeading: false },
  { value: '', isHeading: false },
];

interface BalanceSheetRowProps {
  row: Row<any>;
  index: number;
}

export const BalanceSheetRow = ({ row, index }: BalanceSheetRowProps) => {
  const styles = useMultiStyleConfig('FinancialBalanceSheet', {});

  const aktivaHeadline = aktivaNumbering[index].isHeading;
  const passivaHeadline = passivaNumbering[index].isHeading;

  const isAktivaColumn = (index: number) => {
    const aktivaColumns = [0, 1, 2];
    return aktivaColumns.indexOf(index) !== -1;
  };

  const isPassivaColumn = (index: number) => {
    const passivaColumns = [3, 4, 5];
    return passivaColumns.indexOf(index) !== -1;
  };

  const getHeadingStyles = (cell: Cell<any>, index: number) => {
    if (
      (isAktivaColumn(index) && aktivaHeadline && index === 0) ||
      (isPassivaColumn(index) && passivaHeadline && index === 3)
    ) {
      return {
        backgroundColor: 'background.lightGrey',
        paddingLeft: '16px',
      };
    } else if (
      (isAktivaColumn(index) && aktivaHeadline) ||
      (isPassivaColumn(index) && passivaHeadline)
    ) {
      return {
        backgroundColor: 'background.lightGrey',
      };
    }

    return { backgroundColor: 'inherit' };
  };

  return (
    <chakra.tr sx={styles.tr} {...row.getRowProps()}>
      {row.cells.map((cell, index) => {
        return (
          <chakra.td
            sx={{
              ...styles.td,
              color:
                typeof cell.value === 'number' && cell.value < 0 ? 'red.500' : 'text.secondary',
              textAlign: [1, 2, 4, 5].includes(index) ? 'left' : 'right',
              ...getHeadingStyles(cell, index),
            }}
            {...cell.getCellProps()}
          >
            <Box display={'flex'} flexDirection={'row'}>
              <Box width={'100%'} textAlign={[1, 2, 4, 5].includes(index) ? 'right' : 'left'}>
                {cell.column.id === 'col1' && aktivaNumbering?.[parseInt(row.id)].value}
                {cell.column.id === 'col4' && passivaNumbering?.[parseInt(row.id)].value}
                {cell.render('Cell')}
              </Box>
            </Box>
          </chakra.td>
        );
      })}
    </chakra.tr>
  );
};
