import React from 'react';

import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import { Card } from 'mittweida/portals/operations/components/Card';
import { getProfitabilityRentTable } from 'mittweida/portals/operations/store/selectors';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { translations } from '../../../../../../new/form/common/types';
import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { RentItem } from '.';

const partitionMapping: MappingProps<RentItem> = [
  { key: 'rental_income', unit: 'currency', styles: { bold: true } },
  { key: 'operating_costs', unit: 'currency', styles: { bold: true } },
  { key: 'net_income', unit: 'currency', styles: { bold: true } },
  { key: 'service_year', unit: 'currency', styles: { bold: true } },
  { key: 'surplus_year', unit: 'currency', styles: { bold: true } },
  { key: 'surplus_financing_duration', unit: 'currency', styles: { bold: true } },
  { key: 'free_income', unit: 'currency', styles: { bold: true } },
  { key: 'asset_surplus', unit: 'currency', styles: { bold: true } },
  { key: 'equity_proof', unit: 'currency', styles: { bold: true } },
  {
    key: 'surplus_securities_financing_duration',
    unit: 'currency',
    styles: { bold: true },
  },
];

function useRentPartitionTableData() {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.rent.partition',
  );
  const formatter = useUnitFormatter();
  const rentPartitionTableData = useSelector(getProfitabilityRentTable);

  /**
   * For role developer the bank employee will always only look for the customer_now values, therefore we do not
   * differentiate based on the usageType here.
   */
  const data = partitionMapping.map(({ key, unit }) => {
    const formatterForUnit = formatter[unit];
    const customerNowValue = rentPartitionTableData.customer_now[key];
    const customerNow = formatterForUnit(customerNowValue);

    return {
      id: key,
      position: t(`rows.${key}`),
      customerNow,
    };
  });

  const headers = [
    { key: 'position', text: t('headers.position') },
    { key: 'customerNow', text: t('headers.customerNow') },
  ];

  return usePortalTable({
    data,
    headers,
  });
}

export const RentPartition = () => {
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const rentPartitionTable = useRentPartitionTableData();
  const { rent } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: rent(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT](),
  });

  const rowStyles = getRowStylesMap(partitionMapping);
  if (!rentPartitionTable) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <Card
      title={t(
        translations.pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.rent
          .partition.title,
      )}
      onEdit={onEdit}
      isExpandable
      isExpanded
    >
      <PortalTable {...rentPartitionTable} rowStylesMap={{ ...rowStyles }} />;
    </Card>
  );
};
