import _get from 'lodash/get';

import {
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_BUFFER,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapInvestmentCostsToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const investmentCostsData = {
    plot_costs: {
      purchase_price: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_PURCHASE_PRICE))),
      legal_fee: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_LEGAL_FEES))),
      agent_fee: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_AGENT_FEES))),
      real_estate_transfer_tax: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_REAL_ESTATE_TRANSFER_TAX)),
      ),
      measurement_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_MEASUREMENT_COSTS)),
      ),
      other_fees: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_OTHER_FEES))),
      other_purchase_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_OTHER_PURCHASE_COSTS)),
      ),
      other_costs: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_OTHER_COSTS))),
      compensations: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_COMPENSATIONS))),
      land_development_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_LAND_DEVELOPMENT_COSTS)),
      ),
      public_disposal: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_PUBLIC_DISPOSAL)),
      ),
      non_public_disposal: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_NON_PUBLIC_DISPOSAL)),
      ),
      other_development_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_OTHER_DEVELOPMENT_COSTS)),
      ),
    },
    building_costs: {
      construction_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_CONSTRUCTION_COSTS)),
      ),
      outdoor_facilities_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_OUTDOOR_FACILITIES_COSTS)),
      ),
      other_construction_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_OTHER_CONSTRUCTION_COSTS)),
      ),
    },
    ancillary_construction_costs: {
      architect_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_ARCHITECT_COSTS)),
      ),
      additional_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_ADDITIONAL_COSTS)),
      ),
    },
    financing_costs: {
      third_party_financing_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_THIRD_PARTY_FINANCING_COSTS)),
      ),
      financing_costs_interests_manual: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL)),
      ),
      financing_costs_fees_manual: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_FINANCING_COSTS_FEES_MANUAL)),
      ),
    },
    other_costs: {
      buffer: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_BUFFER))),
      marketing_sales_costs: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_MARKETING_SALES_COSTS)),
      ),
    },
  };

  return investmentCostsData;
};

export default mapInvestmentCostsToApi;
