import {
  validateFields,
  ValidateSectionFunction,
} from '../../../../../../new/form/useValidateFields';
import { mittweidaFields } from '../../../../fields';

export const validateTermsAndConditionsSection: ValidateSectionFunction = (form, values) => {
  return validateFields({
    fields: [mittweidaFields.userProfilePage.termsAndConditionsSection.termsAndConditionsAccepted],
    form,
    values,
  });
};
