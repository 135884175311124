import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { INPUT_TYPE_NUMBER } from 'components/Input';
import { RadioGroupWithField } from 'components/RadioGroup';
import SectionHeading from 'components/Text/SectionHeading';
import {
  FINANCING_AMOUNT,
  NUMBER_OF_TECHNOLOGIES,
  NUMBER_OF_TECHNOLOGIES__MORE,
  NUMBER_OF_TECHNOLOGIES__ONE,
} from 'modules/Inquiry/Form/formFields';
import { simpleValidation } from 'modules/Inquiry/Form/validateFields';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateTechnologySection = simpleValidation([
  FINANCING_AMOUNT,
  NUMBER_OF_TECHNOLOGIES,
]);

export const TechnologySection = () => {
  const t = useTranslations('inquiryType.energyEfficiency.steps.purpose.sections.technology');

  const options = [
    {
      caption: t('fields.technologies.options.one'),
      value: NUMBER_OF_TECHNOLOGIES__ONE,
    },
    {
      caption: t('fields.technologies.options.more'),
      value: NUMBER_OF_TECHNOLOGIES__MORE,
    },
  ];

  return (
    <FormSection title={t('title')} sectionNumber={2}>
      <FormRow>
        <InputWithField name={FINANCING_AMOUNT} type={INPUT_TYPE_NUMBER} />
      </FormRow>
      <SectionHeading>{t('fields.technologies.heading')}</SectionHeading>
      <FormRow>
        <RadioGroupWithField name={NUMBER_OF_TECHNOLOGIES} options={options} />
      </FormRow>
    </FormSection>
  );
};
