import { mittweidaFeToBeMapping } from 'mittweida/inquiry/state/feToBeMappingKeys';
import { mittweidaInitialFormState } from 'mittweida/inquiry/state/initialFormState';
import { mittweidaInitialFormValues } from 'mittweida/inquiry/state/initialFormValues';
import { mittweidaRouteToPageNameMap } from 'mittweida/inquiry/state/routeToPageNameMap';
import { mittweidaSummaryFields } from 'mittweida/inquiry/state/summaryFields';
import { mittweidaSummaryPath } from 'mittweida/inquiry/state/summaryPath';
import { mittweidaValidations } from 'mittweida/inquiry/state/validations';
import { InquiryProcessConfig } from 'new/form/model';

import { MittweidaFieldTypes } from '../fields';
import { mittweidaStepData } from './stepData';
import { mittweidaStepSequence } from './stepSequence';


export const mittweidaInquiryProcessConfig: InquiryProcessConfig<MittweidaFieldTypes> = {
  validations: mittweidaValidations,
  summaryFields: mittweidaSummaryFields,
  feToBeMappingKeys: mittweidaFeToBeMapping,
  summaryPath: mittweidaSummaryPath,
  stepData: mittweidaStepData,
  stepSequence: mittweidaStepSequence,
  routeToPageNameMap: mittweidaRouteToPageNameMap,
  initialFormState: mittweidaInitialFormState,
  initialFormValues: mittweidaInitialFormValues,
};
