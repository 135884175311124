import React from 'react';

import { usePreviewFile } from 'components/DownloadBlock/usePreviewFile';
import { IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ButtonComponent } from 'theme/components/Button';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IPreviewFileInRequest {
  file: IFile | CompeonDocument;
}

const PreviewFileInRequest = ({ file }: IPreviewFileInRequest) => {
  const t = useTranslations('components.upload');
  const previewFile = usePreviewFile({ fileId: file.id, fileName: file.fileName });

  return (
    <ButtonComponent
      data-testid="preview-file-btn"
      leftIcon={<ViewIcon boxSize={6} d="block" />}
      onClick={previewFile}
    >
      {t('preview')}
    </ButtonComponent>
  );
};

export default PreviewFileInRequest;
