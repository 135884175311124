export const retrieveObject = (key) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : item;
};

export const saveObjectToSessionStorage = (key, data) =>
  sessionStorage.setItem(key, JSON.stringify(data));

export const removeObject = (key) => sessionStorage.removeItem(key);

export const removeInnerPropertyFromSessionStorage = (mainKey, keyToBeRemoved) => {
  const data = retrieveObject(mainKey);
  if (Object.prototype.hasOwnProperty.call(data, keyToBeRemoved)) {
    delete data[keyToBeRemoved];
    sessionStorage.setItem(mainKey, JSON.stringify(data));
  }
};

export const clearSession = () => sessionStorage.clear();
