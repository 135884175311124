import React, { ReactNode } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import PageTitle from 'components/PageTitle/PageTitle';

interface IPage {
  children: ReactNode;
  hero?: ReactNode;
  title?: string;
}

export const InnerPageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box width={'full'} maxWidth={'69rem'} margin={'0 auto'}>
      {children}
    </Box>
  );
};

const Page = ({ children, hero, title }: IPage) => (
  <Flex
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    padding={'18rem 4rem 4rem'}
  >
    <PageTitle title={title} />
    {hero}
    <InnerPageContainer>{children}</InnerPageContainer>
  </Flex>
);

Page.defaultProps = {
  hero: null,
};

export default Page;
