import React from 'react';

import { Switch } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import useInquiryEditFormPopulator from 'modules/InquiryEdit/useInquiryEditInitializerHook';
import { useSetProgressForEdit } from 'modules/InquiryEdit/useSetProgressBarForEdit';
import { withCanEditInquiryGuard } from 'modules/InquiryEdit/WithCanEditInquiryGuard';
import CompanyDetails from 'pages/inquiryFlow/CompanyDetails';
import ContactPersonStep from 'pages/inquiryFlow/ContactPersonStep/ContactPersonStep';
import { FinancingNeedStep } from 'pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import RequestDetails from 'pages/inquiryFlow/RequestDetails/RequestDetailsStep';
import SummaryPage from 'pages/inquiryFlow/SummaryPage/SummaryPageStep';
import UserProfile from 'pages/inquiryFlow/UserProfile';
import RootEditRedirect from 'pages/operationPortal/InquiryEdit/RootEditRedirect';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';

import { MittweidaEditOperationPortalForm } from '../../../mittweida/inquiry';
import { InquiryType } from '../../../modules/Inquiry/Inquiry.type';
import { InquiryProcessConfigProvider } from '../../../new/form/state/inquiryProcessConfig';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import InquiryCancelEdit from './Cancel';

const InquiryRoutes = () => {
  return (
    <Switch>
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.financingNeed}
        render={() => <FinancingNeedStep />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.companyDetails}
        render={() => <CompanyDetails />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.requestDetails}
        render={() => <RequestDetails />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.contactPersonDetails}
        render={() => <ContactPersonStep />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.userProfile}
        render={() => <UserProfile />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.summaryPage}
        render={() => <SummaryPage />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.cancel}
        render={() => <InquiryCancelEdit />}
      />
      <OperationPrivateRoute
        exact
        path={paths.operation.inquiryDetails.edit.root}
        render={() => <RootEditRedirect />}
      />
    </Switch>
  );
};

const EditOperationInquiryRoutes = withCanEditInquiryGuard(InquiryRoutes);

const OperationInquiryEdit = () => {
  const isInitialized = useInquiryEditFormPopulator();
  useSetProgressForEdit();

  if (!isInitialized) {
    return <Spinner />;
  }

  return (
    <main>
      <ProgressBar />
      <InquirySettingsProvider>
        <EditOperationInquiryRoutes />
      </InquirySettingsProvider>
    </main>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  default: OperationInquiryEdit,
  [InquiryType.profiMittweida]: () => (
    <InquiryProcessConfigProvider>
      <MittweidaEditOperationPortalForm />
    </InquiryProcessConfigProvider>
  ),
});
