import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';

export const getCompany = (store) => {
  let company = null;

  const { companyDetails } = store.progress.storedValues;

  if (companyDetails) {
    const userCompany = companyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY];
    const companySuggestion = companyDetails[COMPANY_DETAILS_COMPANY];
    if (userCompany && userCompany !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY) {
      company = { ...userCompany, zipCode: userCompany.zipCode || userCompany.zip_code };
    } else if (companySuggestion && companySuggestion !== COMPANY_DETAILS_COMPANY__NOT_FOUND) {
      company = {
        ...companySuggestion,
        zipCode: companySuggestion.zipCode || companySuggestion['zip-code'],
      };
    }
  }
  return company;
};
