import React from 'react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { CoronaDelayInfoStyled } from 'modules/Offers/InquiryOffersSection/CoronaDelayInfo/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const CoronaDelayInfo = () => {
  const t = useTranslations('pages.inquiryDetails.offers');

  return <CoronaDelayInfoStyled>{t('coronaDelay')}</CoronaDelayInfoStyled>;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: CoronaDelayInfo,
  default: () => null,
});
