import React from 'react';

import { HStack, useMultiStyleConfig } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { Container } from 'components/PortalPage/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import LanguageSelector from 'modules/Header/LanguageSelector';
import NavigationLink from 'modules/Header/NavigationLink';
import PartnerLogo from 'modules/Header/PartnerLogo';
import PortalNavigation from 'modules/Header/PortalNavigation';
import {
  StyledPortalHeader as StyledHeader,
  StyledMenuButton,
  StyledRwdMenu,
  StyledRwdMenuHeadline,
  PageHeadline,
} from 'modules/Header/styles';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { showRwdMenu } from 'store/layout/actions';
import { rwdMenuOpenSelector } from 'store/layout/selectors';
import { isLoggedInSelector } from 'store/user/selectors';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

const Header = () => {
  const isScrolledToTop = useIsScrolledToTop();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const styles = useMultiStyleConfig('Header', {});

  const isRwdMenuOpen = useSelector(rwdMenuOpenSelector);
  const dispatch = useDispatch();

  const loginUrl = useIsUnderOperationPortalPath() ? paths.operation.login : paths.customer.login;

  const t = useTranslations('pages.loginPage');

  return (
    <StyledHeader biggerHeader={isScrolledToTop}>
      <Container>
        <PageHeadline>eco.banking</PageHeadline>
        <PartnerLogo />
        <HStack>
          {isLoggedIn && (
            <>
              <StyledMenuButton
                type="button"
                className={isRwdMenuOpen && 'active'}
                onClick={() => dispatch(showRwdMenu(!isRwdMenuOpen))}
                aria-label={t('toggleNavigation')}
              >
                <span className="bars" role="presentation" />
              </StyledMenuButton>

              <StyledRwdMenu
                show={isLoggedIn}
                className={isRwdMenuOpen && 'open'}
                aria-labelledby={ELEMENT_IDS.MAIN_NAVIGATION}
              >
                <StyledRwdMenuHeadline id={ELEMENT_IDS.MAIN_NAVIGATION}>
                  Navigation
                </StyledRwdMenuHeadline>
                <PortalNavigation />
              </StyledRwdMenu>
            </>
          )}
          {!isLoggedIn && styles.loginLink?.display !== 'none' ? (
            <NavigationLink url={loginUrl} label={t('login')} />
          ) : null}
          {styles.languageSelector?.display === 'none' ? null : <LanguageSelector />}
        </HStack>
      </Container>
    </StyledHeader>
  );
};

export default Header;
