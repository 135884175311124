import React from 'react';

import { Link } from 'react-router-dom';

import paths from 'constants/paths';
import { StyledRegisterInfo } from 'modules/Login/styles';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useTranslations } from 'utils/hooks/useTranslations';

const RegistrationLink: React.FC = () => {
  const t = useTranslations('pages.loginPage');
  return (
    <StyledRegisterInfo>
      {t('registration.caption')} <Link to={paths.registration}>{t('registration.linkLabel')}</Link>
    </StyledRegisterInfo>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_REGISTRATION_ENABLED]: RegistrationLink,
  default: () => null,
});
