import { API_METHODS, callCompeonApi, callReverseApi } from 'api/apiService';
import CompeonEndpoints from 'api/CompeonApi/endpoints';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { IBankOffer } from 'models/BankOffer.model';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import {
  IInquiryDetails,
  IInquiryEditDetails,
  PayloadType,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { AppActionThunk } from 'models/types/AppThunk.type';
import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import {
  FeToBeMapping,
  mapInquiryDetailsFromApi,
  mapNewInquiryDetailsFromApi,
} from 'modules/InquiryDetails/InquiryDetails.service';
import { mapInquiryEditFromApiInterface } from 'modules/InquiryEdit/mapInquiryForEditFromApi';
import { Content } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/service';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { success } from 'utils/actionTypesHelpers';
import { flattenObject } from 'utils/helpers';

import { setNewInquiryDetailsAction } from '../newInquiryDetails/actions';

export const SET_INQUIRY_DETAILS = 'SET_INQUIRY_DETAILS';
export const setInquiryDetailsAction =
  (details: IInquiryDetails): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_INQUIRY_DETAILS,
      payload: details,
    });

export const RESET_INQUIRY_DETAILS = 'RESET_INQUIRY_DETAILS';
export const resetInquiryDetailsAction = (): AppActionThunk => (dispatch) =>
  dispatch({ type: RESET_INQUIRY_DETAILS });

export const SET_INQUIRY_EDIT_DETAILS = 'SET_INQUIRY_EDIT_DETAILS';
export const setInquiryEditDetailsAction =
  (details: IInquiryEditDetails): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_INQUIRY_EDIT_DETAILS,
      payload: details,
    });

export const SET_BANK_OFFERS = 'SET_BANK_OFFERS';
export const setBankOffersAction =
  (offers: IBankOffer[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_BANK_OFFERS,
      payload: offers,
    });

export const SET_COMPEON_OFFERS = 'SET_COMPEON_OFFERS';
export const setCompeonOffersAction =
  (offers: ICompeonOffer[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_COMPEON_OFFERS,
      payload: offers,
    });

export const ACCEPT_COMPEON_OFFER = 'ACCEPT_COMPEON_OFFER';
export const acceptCompeonOfferAction =
  (offerId: string): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ACCEPT_COMPEON_OFFER,
      payload: offerId,
    });

export const MARKET_DATA_SENT = 'MARKET_DATA_SENT';
export const marketDataSentAction = (): AppActionThunk => (dispatch) => {
  dispatch({
    type: MARKET_DATA_SENT,
  });
};

export const ASSIGNED_REAL_ESTATE_EXPERT = 'ASSIGNED_REAL_ESTATE_EXPERT';
export const assignedRealEstateExpertAction = (): AppActionThunk => (dispatch) => {
  dispatch({
    type: ASSIGNED_REAL_ESTATE_EXPERT,
  });
};

export const IS_QUICK_CHECK_STARTED = 'IS_QUICK_CHECK_STARTED';
export const isQuickCheckStartedAction = (): AppActionThunk => (dispatch) => {
  dispatch({
    type: IS_QUICK_CHECK_STARTED,
  });
};

export const ACCEPT_BANK_OFFER = 'ACCEPT_BANK_OFFER';
export const ACCEPT_BANK_OFFER_SUCCESS = success(ACCEPT_BANK_OFFER);

export const acceptBankOffer = (offerId: string) =>
  callReverseApi({
    url: endpoints.OFFERS.ACCEPT.compose({ params: { id: offerId } }),
    method: API_METHODS.PATCH,
    actionName: ACCEPT_BANK_OFFER,
  });

export const mapInquiryDetailsApiResponseAction =
  (apiInquiry: any, feToBeMapping?: FeToBeMapping | undefined): AppActionThunk =>
  (dispatch) => {
    const mappedData: IInquiryDetails = mapInquiryDetailsFromApi(apiInquiry);
    if (feToBeMapping) {
      const newInquiryDetails = mapNewInquiryDetailsFromApi(apiInquiry, feToBeMapping);
      dispatch(setNewInquiryDetailsAction(newInquiryDetails));
    }

    dispatch(setInquiryDetailsAction(mappedData));
    dispatch(setBankOffersAction(mappedData.bankOffers));
    dispatch(setCompeonOffersAction(mappedData.compeonOffers));
  };

export const mapInquiryEditApiResponseAction =
  (apiEditInquiry: any): AppActionThunk =>
  (dispatch) => {
    const data = mapInquiryEditFromApiInterface(apiEditInquiry);

    FormFieldController.instance.saveBulk(flattenObject(data), { emit: false });

    return dispatch(setInquiryEditDetailsAction(data));
  };

export const CLEAR_INQUIRY_DETAILS = 'CLEAR_INQUIRY_DETAILS';
export const clearInquiryDetails = (): AppActionThunk => (dispatch) =>
  dispatch({
    type: CLEAR_INQUIRY_DETAILS,
  });

export const SHOW_INTEREST_IN_OFFER = 'SHOW_INTEREST_IN_OFFER';
export const SHOW_INTEREST_IN_OFFER_SUCCESS = success(SHOW_INTEREST_IN_OFFER);
export const showInterestInOffer = (offerId: string) =>
  callReverseApi({
    url: endpoints.OFFERS.INTEREST.compose({ params: { id: offerId } }),
    method: API_METHODS.PATCH,
    actionName: SHOW_INTEREST_IN_OFFER,
  });

export const SET_ALLOW_DIGITAL_PROCESSING_SUCCESS = 'SET_ALLOW_DIGITAL_PROCESSING_SUCCESS';
export const SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE = 'SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE';

export const BINDING_OFFER = 'BINDING_OFFER';
export const BINDING_OFFER_SUCCESS = success(BINDING_OFFER);

export const nonBindingOfferAction = (inquiryId: string, formValues: Content) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INDICATIVE_OFFER.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          instalment_rate: formValues[OFFER_INSTALLMENT_RATE],
          monthly_instalment: formValues[OFFER_MONTHLY_INSTALLMENT],
          factoring_line: formValues[OFFER_FACTORING_LINE],
          factoring_fee: Number(formValues[OFFER_FACTORING_FEE]) / 100,
          payout_ratio: formValues[OFFER_PAYOUT_RATIO],
        },
      },
    },
    actionName: BINDING_OFFER,
  });

export const OFFER_CALCULATION = 'OFFER_CALCULATION';
export const OFFER_CALCULATION_SUCCESS = success(OFFER_CALCULATION);

export const offerCalculationAction = (inquiryId: string, accessToken?: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INDICATIVE_OFFER_CALC.compose({
      params: { id: inquiryId },
      query: { access_token: accessToken },
    }),
    method: API_METHODS.GET,
    actionName: OFFER_CALCULATION,
  });

export const RESET_OFFER_CALCULATION = 'RESET_OFFER_CALCULATION';
export const resetOfferCalculationAction = (): AppActionThunk => (dispatch) =>
  dispatch({ type: RESET_OFFER_CALCULATION });

export const TRIGGER_KYC = 'TRIGGER_KYC';
export const TRIGGER_KYC_SUCCESS = success(TRIGGER_KYC);

export const triggerKycAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.KYC_REQUEST.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    actionName: TRIGGER_KYC,
  });

export const INVITE_CUSTOMER = 'INVITE_CUSTOMER';
export const INVITE_CUSTOMER_SUCCESS = success(INVITE_CUSTOMER);

export const inviteCustomerAction = (customerEmail: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INVITE_CUSTOMER_REQUEST.compose(),
    method: API_METHODS.POST,
    data: {
      email: customerEmail,
    },
    actionName: INVITE_CUSTOMER,
  });

export const GENERATE_CONTRACT = 'GENERATE_CONTRACT';
export const GENERATE_CONTRACT_SUCCESS = success(GENERATE_CONTRACT);

export const generateContractAction = (id: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.CREATE_CONTRACT.compose({ params: { id } }),
    method: API_METHODS.POST,
  });

export const UPDATE_KYC_COMPANY_DATA = 'UPDATE_KYC_COMPANY_DATA';
export const UPDATE_KYC_COMPANY_DATA_SUCCESS = success(TRIGGER_KYC);

export const updateKycCompanyDataAction = (kyc_id: string, inquiryId: string) =>
  callReverseApi({
    url: endpoints.COMPANIES.UPDATE_KYC_COMPANY_DATA.compose({ params: { kyc_id } }),
    method: API_METHODS.PUT,
    data: {
      inquiry_id: inquiryId,
    },
    actionName: TRIGGER_KYC,
  });

export const INCONSISTENCY_REPORT = 'INCONSISTENCY_REPORT';
export const INCONSISTENCY_REPORT_SUCCESS = success(INCONSISTENCY_REPORT);

export const inconsistencyReportAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INCONSISTENCY_REPORT.compose({ params: { id: inquiryId } }),
    method: API_METHODS.GET,
    actionName: INCONSISTENCY_REPORT,
  });

export const TRIGGER_CREFO = 'TRIGGER_CREFO';
export const TRIGGER_CREFO_SUCCESS = success(TRIGGER_CREFO);

export const triggerCrefoAction = (inquiryId: string, reportTypes: PayloadType[]) =>
  callReverseApi({
    url: endpoints.INQUIRIES.CREFO_REPORT.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          report_types: reportTypes,
        },
      },
    },
    actionName: TRIGGER_CREFO,
  });

export const START_SIGNING_PROCESS = 'START_SIGNING_PROCESS';
export const START_SIGNING_PROCESS_SUCCESS = success(START_SIGNING_PROCESS);
export const startSigningProcess = (offerId: string, fileIds: string[]) =>
  callReverseApi({
    url: endpoints.OFFERS.SIGNING_PROCESS.compose({ params: { id: offerId } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          uploaded_file_ids: fileIds,
        },
      },
    },
    actionName: START_SIGNING_PROCESS,
  });

export const updateLegalRepresentatives = (
  inquiryId: string,
  legalRepresentative: any,
  legalRepresentativesAccepted?: boolean,
): AppActionThunk =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.UPDATE_LEGAL_REPRESENTATIVES.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          legal_representatives: legalRepresentative,
          legal_representatives_accepted: legalRepresentativesAccepted,
        },
      },
    },
  });

export const TRANSPARENCY_REGISTER_PICK_ORDER = 'TRANSPARENCY_REGISTER_PICK_ORDER';
export const TRANSPARENCY_REGISTER_PICK_ORDER_SUCCESS = success(TRANSPARENCY_REGISTER_PICK_ORDER);
export const transparencyRegisterPickOrderAction = (inquiryId: string, orderId: number | null) =>
  callReverseApi({
    url: endpoints.INQUIRIES.TRANSPARENCY_REGISTER_SEARCH_RESULT.compose({
      params: { id: inquiryId },
    }),
    method: API_METHODS.POST,
    actionName: TRANSPARENCY_REGISTER_PICK_ORDER,
    data: {
      data: {
        attributes: {
          order_id: orderId,
        },
      },
    },
  });

export const GET_COMPEON_INQUIRY = 'GET_COMPEON_INQUIRY';
export const GET_COMPEON_INQUIRY_SUCCESS = success(GET_COMPEON_INQUIRY);

export const getCompeonInquiryAction = (inquiryId: string) =>
  callCompeonApi({
    url: endpoints.INQUIRIES.COMPEON_DOCUMENTS.compose({
      params: { id: inquiryId },
      query: { include: 'documents' },
    }),
    method: API_METHODS.GET,
    actionName: GET_COMPEON_INQUIRY,
  });

export const getCompeonDocumentDownloadAction = (fileId: string) =>
  callCompeonApi({
    url: endpoints.INQUIRIES.COMPEON_DOCUMENT_DOWNLOAD.compose({
      params: { id: fileId },
    }),
    method: API_METHODS.GET,
  });

export const getKycCompanyData = (companyId: string) =>
  callReverseApi({
    url: endpoints.COMPANIES.KYC_COMPANIES.compose({ params: { id: companyId } }),
    method: API_METHODS.GET,
  });

export const GET_COMPEON_INQUIRY_STATE = 'GET_COMPEON_INQUIRY_STATE';
export const GET_COMPEON_INQUIRY_STATE_SUCCESS = success(GET_COMPEON_INQUIRY_STATE);

export const getCompeonInquiryExternalStateAction = (inquiryId: string) =>
  callCompeonApi({
    url: CompeonEndpoints.INQUIRY.STATE.compose({
      params: { id: inquiryId },
    }),
    method: API_METHODS.GET,
    actionName: GET_COMPEON_INQUIRY_STATE,
  });
