import * as React from 'react';

import { Box, GridItem, VisuallyHidden } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { InquiryType } from '../../../../../modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from '../../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import { getExternalReference } from '../../../../../store/inquiryDetails/selectors';
import { isCustomerSelector } from '../../../../../store/user/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from '../../../../../theme/components/DescriptionList';
import { HeadingComponent } from '../../../../../theme/components/Heading';
import TimeIcon from '../../../../../theme/components/Icon/TimeIcon';
import { TextComponent } from '../../../../../theme/components/Text';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { useSummaryCardData } from '../useSummaryCardData';

export const FinancingProductItem = () => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const externalRef = useSelector(getExternalReference);
  const isCustomer = useSelector(isCustomerSelector);

  const financingProduct = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: data.role,
    [InquiryType.bfsService]: data.purpose,
    [InquiryType.dvag]: `${data.product}${t(
      'pages.inquiryDetails.dashboard.summary.purposeProductSeparator',
    )}${data.purpose}`,
    default: `${data.product}${
      !isCustomer
        ? `${t('pages.inquiryDetails.dashboard.summary.purposeProductSeparator')}${data.purpose}`
        : ''
    }`,
  });

  return (
    <GridItem>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        paddingBottom={3}
      >
        <HeadingComponent as="h4" variant="hidden">
          {t('pages.inquiryDetails.detailed.summary.title')}
        </HeadingComponent>

        <DescriptionList mb={1}>
          <DescriptionListTerm isHidden>
            {t('pages.inquiryDetails.dashboard.summary.financingProduct')}
          </DescriptionListTerm>
          <DescriptionListDescription>{financingProduct}</DescriptionListDescription>
        </DescriptionList>

        <DescriptionList size="large" mb={8}>
          <DescriptionListTerm isHidden>
            {t('pages.inquiryDetails.detailed.summary.financingRequirement')}
          </DescriptionListTerm>
          <DescriptionListDescription>{data.amount}</DescriptionListDescription>
        </DescriptionList>

        <DescriptionList size="small" mb={8}>
          <DescriptionListTerm>
            <VisuallyHidden>
              {t('pages.inquiryDetails.detailed.summary.creationDate')}
            </VisuallyHidden>
            <TimeIcon boxSize={6} d="block" mr={-1} />
          </DescriptionListTerm>
          <DescriptionListDescription>
            <time dateTime="2020-09-19T11:25:00">
              <TextComponent as="span" display="block">
                {data.createdAtSince} {t('pages.inquiryDetails.dashboard.summary.sinceCreation')}
              </TextComponent>
              <TextComponent as="span" display="block" color="text.tertiary">
                ({data.createdAt})
              </TextComponent>
            </time>
          </DescriptionListDescription>
        </DescriptionList>

        <DescriptionList size="small">
          <DescriptionListTerm mr={3}>
            {t('pages.inquiryDetails.dashboard.summary.id')}
          </DescriptionListTerm>
          <DescriptionListDescription>{data.id}</DescriptionListDescription>
        </DescriptionList>
        {!isCustomer && externalRef && (
          <DescriptionList size="small">
            <DescriptionListTerm mr={3}>
              {t('pages.inquiryDetails.dashboard.summary.externalReference')}
            </DescriptionListTerm>
            <DescriptionListDescription>{externalRef}</DescriptionListDescription>
          </DescriptionList>
        )}
      </Box>
    </GridItem>
  );
};
