const Modal = {
  parts: ['content', 'header', 'body', 'footer'],
  baseStyle: {
    content: {
      maxWidth: '80rem',
      margin: '3.5rem',
      borderRadius: '0',
    },
    header: {
      pt: 8,
      pr: 16,
      pb: 2,
      pl: 8,
    },
    body: {
      pt: 2,
      pr: 8,
      pb: 4,
      pl: 8,
    },
    footer: {
      pt: 4,
      pr: 8,
      pb: 8,
      pl: 8,
    },
  },
  variants: {
    md: {
      content: {
        maxWidth: '40rem',
      },
    },
  },
};

export default Modal;
