import React from 'react';

import { ValidationErrorItem } from '@hapi/joi';

import { ErrorText } from 'components/Text';
import { StyledFieldError } from 'modules/Inquiry/Form/Field/FieldStyles';
import { useErrorTranslator } from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';

const FieldError = ({ message }: { message: ValidationErrorItem | string }) => {
  const errorTranslator = useErrorTranslator();

  return (
    <StyledFieldError hasError={Boolean(message)}>
      <ErrorText>{errorTranslator(message)}</ErrorText>
    </StyledFieldError>
  );
};

export default FieldError;
