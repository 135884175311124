import React from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { StyledTextArea } from './styles';

const TEXT_AREA_MAX_LENGTH = 1000;

const Form = ({ onSubmit, isSendButtonDisabled }) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.communication.internalComments');
  const { required, maxLength } = useFieldValidators();
  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StyledTextArea
              name="internalComment"
              placeholder={t('commentPlaceholder')}
              validate={combineValidators(required, maxLength(TEXT_AREA_MAX_LENGTH))}
            />
            <ButtonComponent
              mt={4}
              disabled={isSendButtonDisabled}
              type="submit"
              data-testid={'Comment-Button'}
            >
              {t('addButton')}
            </ButtonComponent>
          </form>
        )}
      />
    </>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSendButtonDisabled: PropTypes.bool.isRequired,
};

export default Form;
