import { FormApi } from 'final-form';
import { useFormState } from 'react-final-form';

import { validateField } from '../../new/form/useValidateFields';
import { mittweidaFields, MittweidaFieldTypes } from './fields';

export const mittweidaConditions: Record<
  string,
  ({ values, form }: { values: MittweidaFieldTypes; form: FormApi }) => boolean
> = {
  [mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject]: ({
    values,
  }) => {
    return values.financingNeedPage.roleSection.projectFinancingRole === 'developer';
  },
  [mittweidaFields.financingNeedPage.projectCompositionSection.totalInvestmentInBuyingPhase]: ({
    values,
  }) => {
    return isDeveloper(values);
  },
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownFundsInBuyingPhase]: ({
    values,
  }) => {
    return isDeveloper(values);
  },
  [mittweidaFields.financingNeedPage.objectInformationSection.rentalIntends]: ({ values }) => {
    return isRentalIntends(values);
  },
  [mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company.nameOfContactPerson]:
    ({ values }) => {
      return alreadyHadContactWithBank(values);
    },
  [mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company.foundingMonth]: ({
    values,
    form,
  }) => {
    return validateField({
      field: mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company.foundingYear,
      values,
      form,
    });
  },
};

export function isUsageDeviation(values: MittweidaFieldTypes): boolean {
  const { usageDeviation } = values.financingNeedPage?.objectInformationSection;

  if (usageDeviation) {
    return JSON.parse(usageDeviation as any);
  }

  return false;
}

export function useIsUsageDeviation(): boolean {
  const { values } = useFormState<MittweidaFieldTypes>();
  return isUsageDeviation(values);
}

export function isPartitionOrStockholder(values: MittweidaFieldTypes): boolean {
  const { projectFinancingRole } = values.financingNeedPage.roleSection;

  return projectFinancingRole === 'partition' || projectFinancingRole === 'stockholder';
}

export function useIsPartitionOrStockholder(): boolean {
  const { values } = useFormState<MittweidaFieldTypes>();

  return isPartitionOrStockholder(values);
}

export function isDeveloper(values: MittweidaFieldTypes): boolean {
  const { projectFinancingRole } = values.financingNeedPage.roleSection;

  return projectFinancingRole === 'developer';
}

export function useIsDeveloper(): boolean {
  const { values } = useFormState<MittweidaFieldTypes>();

  return isDeveloper(values);
}

export function isRentalIntends(values: MittweidaFieldTypes): boolean {
  const { rentalContracts } = values.financingNeedPage.objectInformationSection;

  if (rentalContracts) {
    return !JSON.parse(rentalContracts as any);
  }

  return false;
}

export function useShowRentalIntends(): boolean {
  const { values } = useFormState<MittweidaFieldTypes>();
  return isRentalIntends(values);
}

export function alreadyHadContactWithBank(values: MittweidaFieldTypes): boolean {
  const alreadyContactWithBank =
    values.companyDetailPage.completingCompanyDetailsSection?.company?.alreadyContactWithBank;

  if (alreadyContactWithBank) {
    return JSON.parse(alreadyContactWithBank as any);
  }

  return false;
}

export function useShowNameOfContactPerson(): boolean {
  const { values } = useFormState<MittweidaFieldTypes>();
  return alreadyHadContactWithBank(values);
}
