import { textStyle } from '../../../themeConstants';

const Accordion = {
  parts: ['accordion', 'item', 'button', 'panel'],
  baseStyle: {
    accordion: {
      flexDir: 'column',
      border: '1px',
      borderColor: 'border.lightGrey',
      px: 6,
      py: 8,
      bgColor: 'background.white',
      boxShadow: 'default',
    },
    item: {
      border: 0,
    },
    button: {
      p: 0,
      ...textStyle.sizeLTight,
      textAlign: 'left',
      color: 'brand.default',
      _focus: {
        boxShadow: 'none',
        color: 'text.primary',
      },
      _hover: {
        color: 'text.primary',
        backgroundColor: 'inherit',
      },
    },
    panel: {
      position: 'relative',
      pt: 16,
      px: 8,
      pb: 4,
      _before: {
        content: `''`,
        position: 'absolute',
        top: '2rem',
        right: '0',
        left: '2rem',
        height: '1px',
        backgroundColor: 'background.darkGrey',
      },
    },
  },
};

export default Accordion;
