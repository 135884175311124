import React from 'react';

import { isArray } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
import { CurrentFutureUsageType } from 'modules/Inquiry/CurrentFutureUsageType';
import {
  FUNDING_CLOSING_DATE,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING__OBJECT_USAGE,
  REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION,
  FINANCING_NEED,
  USER_PROFILE_INDUSTRY,
  BFS_USER_PROFILE_INDUSTRY,
  COMPEON_PARTNER,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  USER_PROFILE_FOUNDING_YEAR,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
  BENEFICIARY_OWNER_DETAILS,
  BANK_DETAILS_IBAN,
  OFFER_FACTORING_FEE,
  LEGAL_REPRESENTATIVE_DETAILS,
  OFFER_PAYOUT_RATIO,
  OFFER_FACTORING_LINE,
  BFS_SERVICE__SOURCE,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__NEED_FURTHER_ADVICE,
  FURTHER_NEED_VALUES,
  PRODUCT_KIND__BFSS,
  PRODUCT_KIND,
  REQUEST_DETAILS,
  COMPANY_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import { INDUSTRY_OPTIONS } from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/Select';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { InquiryMapType } from 'modules/Inquiry/InquiryMapType';
import { customSummaryDataParsers } from 'modules/SummaryTable/customSummaryDataParsers';
import { legalRepresentativesDataParser } from 'modules/SummaryTable/legalRepresentativesDataParser';
import SummaryField from 'modules/SummaryTable/SummaryField';
import { summaryMap, summaryStepFieldsMap } from 'modules/SummaryTable/summaryFieldsMap';
import { useCheckCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { StyledSummaryRows } from 'pages/inquiryFlow/SummaryPage/NewSummaryPage/styles';
import { getSelectedLabelForOptions } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { TURNOVER_CLASS_OPTIONS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/TurnoverClass/TurnoverClass';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getFormDataFromStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { formatDateMonth } from 'utils/date';
import { retrieveObject } from 'utils/sessionStorage/helpers';
import { formatPercentage } from 'utils/valueFormats';

import BeneficiaryOwnerSummaryStep from '../BeneficiaryOwnerSummaryStep';
import LegalRepresentativesSummaryStep from '../LegalRepresentativesSummaryStep';

const fieldsToFormat = [FUNDING_CLOSING_DATE, REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION];
const fieldsToFormatPercentage = [OFFER_FACTORING_FEE];
const defaultFieldsToOmit = [
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
];
const objectTypeFields = [PROJECT_FINANCING__OBJECT_USAGE, PROJECT_FINANCING__OBJECT_FUTURE_USAGE];
const objectTypeFieldsToOmit = [
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  'id',
  PROJECT_FINANCING_TYPE_OF_USAGE,
];

interface SummaryFieldType {
  name: string;
  value: unknown;
}

interface IFieldOmitFunc {
  [stepName: string]: (isLead?: boolean) => string[];
}

const BFSServiceFieldOmitSteps: IFieldOmitFunc = {
  [COMPANY_DETAILS]: () => [...defaultFieldsToOmit, BANK_DETAILS_IBAN],
  [REQUEST_DETAILS]: (isLead) =>
    isLead
      ? [
          ...defaultFieldsToOmit,
          OFFER_FACTORING_FEE,
          OFFER_FACTORING_LINE,
          OFFER_PAYOUT_RATIO,
          BFS_SERVICE__FACTORING_OFFER,
          BFS_SERVICE__SOURCE,
          BFS_SERVICE__NEED_FURTHER_ADVICE,
          BANK_DETAILS_IBAN,
          FURTHER_NEED_VALUES,
        ]
      : defaultFieldsToOmit,
};

const SummaryTableStep = ({ stepName }: { stepName: string }) => {
  const {
    details: { id: partnerShortName },
  } = usePartnerConfig();
  const formValues = getFormDataFromStorage(partnerShortName);
  const processLane = useSelector(getProcessLane);
  const isLead = processLane === InquiryLane.lead;
  const companyDetails = retrieveObject(COMPANY_DETAILS);
  const checkCanShowField = useCheckCanShowField();
  const { selectedInquiryType } = useFormConfig();
  const isFinancingNeed = stepName === FINANCING_NEED;
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;

  // Mapping datalist labels dynamically for bfs only. Datalist are of turnover class and profile industry.
  const companyDetailsDatalistOptionFieldsToLabel: InquiryMapType<
    { field: string; options: string }[]
  > = {
    [InquiryType.bfs]: [
      { field: USER_PROFILE_TURNOVER_CLASS, options: TURNOVER_CLASS_OPTIONS },
      { field: USER_PROFILE_INDUSTRY, options: INDUSTRY_OPTIONS },
    ],
    default: [],
  };

  let fieldsToOmit = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: BFSServiceFieldOmitSteps[stepName]?.(isLead),
    default: defaultFieldsToOmit,
  });

  const datalistOptionFieldsToLabel =
    companyDetailsDatalistOptionFieldsToLabel[selectedInquiryType] ||
    companyDetailsDatalistOptionFieldsToLabel.default;

  for (const item of datalistOptionFieldsToLabel) {
    formValues[item.field] = getSelectedLabelForOptions(
      companyDetails[item.options],
      formValues[item.field],
    );
  }

  if (InquiryType.bfs === formValues[COMPEON_PARTNER]) {
    formValues[BFS_USER_PROFILE_INDUSTRY] = formValues[USER_PROFILE_INDUSTRY];
    delete formValues[USER_PROFILE_INDUSTRY];
  }

  if (formValues['compeon-partner'] === PARTNERS.BFSS) {
    formValues[PRODUCT_KIND] = PRODUCT_KIND__BFSS;
    formValues[BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE] = moment(
      formValues[BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE],
    ).format('DD.MM.YYYY');
  }

  if (
    formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] &&
    formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] !==
      COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    fieldsToOmit.push(USER_PROFILE_FOUNDING_YEAR);
    fieldsToOmit.push(USER_PROFILE_COMPANY_ALREADY_CUSTOMER);
    fieldsToOmit.push(USER_PROFILE_LEGAL_FORM);
    fieldsToOmit.push(USER_PROFILE_TURNOVER_CLASS);
  }

  const additionalFieldsAfter = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: null,
    default: legalRepresentativesDataParser[stepName]
      ? legalRepresentativesDataParser[stepName](formValues)
      : null,
  });

  if (stepName === BENEFICIARY_OWNER_DETAILS) {
    return <BeneficiaryOwnerSummaryStep />;
  }
  if (stepName === LEGAL_REPRESENTATIVE_DETAILS) {
    return <LegalRepresentativesSummaryStep />;
  }

  const fieldsForStep =
    summaryStepFieldsMap[stepName] && partnerShortName
      ? summaryMap(stepName, formValues, partnerShortName, processLane)
      : [];

  const filterNullOrUndefined = (fieldName: string) => formValues[fieldName];

  const formatFields = (fieldName: string) => {
    let name = fieldName,
      value = formValues[fieldName];
    if (fieldsToFormat.some((v: string) => fieldName === v))
      value = formatDateMonth(formValues[fieldName]);
    if (fieldsToFormatPercentage.some((v: string) => fieldName === v))
      value = formatPercentage(formValues[fieldName]).toString();
    return { name, value };
  };

  const omittedFields = (fieldName: string) => !fieldsToOmit.includes(fieldName);
  const omittedObjectTypeFields = ({ name }: { name: string }) =>
    !objectTypeFieldsToOmit.includes(name);

  const getFormattedSummaryFieldObjects = () =>
    objectTypeFields
      .flatMap((fieldName: string) => {
        let fieldValues: Array<CurrentFutureUsageType> = formValues[fieldName];
        const summaryFieldObjects =
          fieldValues[0] !== null
            ? fieldValues?.flatMap((fieldValue) =>
                (Object.keys(fieldValue) as Array<keyof typeof fieldValue>).map((key) => {
                  return { name: key, value: fieldValue[key] };
                }),
              )
            : [];
        return summaryFieldObjects;
      })
      .filter(omittedObjectTypeFields);

  const fieldsToShow = fieldsForStep
    .filter(checkCanShowField)
    .filter(filterNullOrUndefined)
    .filter(omittedFields)
    .map(formatFields);

  const summaryFields =
    isFinancingNeed && isProfiInquiry
      ? [...fieldsToShow, ...getFormattedSummaryFieldObjects()]
      : fieldsToShow;

  const additionalFieldsBefore = customSummaryDataParsers[stepName]
    ? customSummaryDataParsers[stepName](formValues, selectedInquiryType)
    : null;

  return (
    <>
      {additionalFieldsBefore}
      <StyledSummaryRows>
        {summaryFields?.map((field: SummaryFieldType | Array<SummaryFieldType>) => {
          return field && !isArray(field) ? (
            <SummaryField
              key={stepName + field.name}
              fieldName={field.name}
              fieldValue={field.value}
            />
          ) : (
            field &&
              field.map(
                (item: SummaryFieldType) =>
                  item && (
                    <SummaryField
                      key={stepName + item.name}
                      fieldName={item.name}
                      fieldValue={item.value}
                    />
                  ),
              )
          );
        })}
      </StyledSummaryRows>
      {additionalFieldsAfter}
    </>
  );
};

export default SummaryTableStep;
