import React from 'react';

import { Grid, Box } from '@chakra-ui/react';

import { Rating } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/Rating';
import { RiskClass } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskClass';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimalWithOutRoundOff, formatPrice } from 'utils/valueFormats';

import { IMarketDataApiRes } from '../MarketComparison/types';

interface Props {
  marketDataObj: IMarketDataApiRes | undefined;
}
export const LocationAnalysisTiles = ({ marketDataObj }: Props) => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const tUnit = useTranslations();
  const topHeaderPadding = 2;

  const listResidentialLocDes = [
    t('tilesDetails.residentialLocation.bottomText.first'),
    t('tilesDetails.residentialLocation.bottomText.second'),
    t('tilesDetails.residentialLocation.bottomText.third'),
    t('tilesDetails.residentialLocation.bottomText.forth'),
    t('tilesDetails.residentialLocation.bottomText.fifth'),
  ];

  const listOfRatingTooltipTrans = [
    t('tilesDetails.residentialLocation.popUpText.first'),
    t('tilesDetails.residentialLocation.popUpText.second'),
    t('tilesDetails.residentialLocation.popUpText.third'),
    t('tilesDetails.residentialLocation.popUpText.forth'),
    t('tilesDetails.residentialLocation.popUpText.fifth'),
  ];

  const calculateReport = () => {
    if (marketDataObj) {
      if (marketDataObj?.location_analysis?.residential_location === 0) return 1;
      else return Math.ceil(marketDataObj?.location_analysis?.residential_location / 20);
    } else return 0;
  };

  return (
    <AccordionComponent headingLevel="h3" mb={8} title={t('crousalTilesHeader')}>
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
        gap={6}
      >
        <Box position="relative">
          <RiskClass
            isMarketpage
            explanationTextList={listResidentialLocDes}
            report={calculateReport()}
            tooltip={listOfRatingTooltipTrans}
            heading={t('tilesDetails.residentialLocation.header')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.localTrendSecond.bottomText')}
            report={{
              rating: `${
                marketDataObj &&
                formatPrice(
                  formatDecimalWithOutRoundOff(
                    marketDataObj?.location_analysis?.recent_local_trend,
                  ),
                  false,
                )
              } ${tUnit('other.percent')}`,
            }}
            heading={t('tilesDetails.localTrendSecond.header')}
            tooltip={t('tilesDetails.localTrendSecond.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.localTrendFirst.bottomText')}
            report={{
              rating: `${
                marketDataObj &&
                formatPrice(
                  formatDecimalWithOutRoundOff(marketDataObj?.location_analysis?.local_trend),
                  false,
                )
              } ${tUnit('other.percent')}`,
            }}
            heading={t('tilesDetails.localTrendFirst.header')}
            tooltip={t('tilesDetails.localTrendFirst.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
        <Box position="relative">
          <Rating
            isMarketpage
            explanationText={t('tilesDetails.regionalTrend.bottomText')}
            report={{
              rating: `${
                marketDataObj &&
                formatPrice(
                  formatDecimalWithOutRoundOff(marketDataObj?.location_analysis?.regional_trend),
                  false,
                )
              } ${tUnit('other.percent')}`,
            }}
            heading={t('tilesDetails.regionalTrend.header')}
            tooltip={t('tilesDetails.regionalTrend.tooltip')}
            headerPadding={topHeaderPadding}
          />
        </Box>
      </Grid>
    </AccordionComponent>
  );
};
