import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { StyledWrapper, StyledLabelWrapper, StyledLabel } from 'components/Label/styles';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import { BFS_SERVICE__NEED_FURTHER_ADVICE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import ExplicitNeedCheckboxField from './ExplicitNeedCheckboxField';

const BfsServiceFurtherNeedsSection = () => {
  const t = useTranslations('pages.bfsService.sections.factoringAndFactomatFurtherNeeds');
  const { required } = useFieldValidators();

  return (
    <FormSection title={t('title')} sectionNumber={3}>
      <FormRow>
        <YesNoRadioGroupWithField
          name={BFS_SERVICE__NEED_FURTHER_ADVICE}
          validate={combineValidators(required)}
        />
      </FormRow>
      <Condition condition={fieldHasValue(BFS_SERVICE__NEED_FURTHER_ADVICE, 'true')}>
        <StyledWrapper>
          <StyledLabelWrapper>
            <StyledLabel>{t('fields.explicitNeedFurtherAdvice.caption')}</StyledLabel>
          </StyledLabelWrapper>
        </StyledWrapper>

        <ExplicitNeedCheckboxField />
      </Condition>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BfsServiceFurtherNeedsSection,
  default: () => null,
});
