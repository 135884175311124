import { failure, success } from 'utils/actionTypesHelpers';

export const GET_IDENTITY_TOKEN = 'GET_IDENTITY_TOKEN';
export const GET_IDENTITY_TOKEN_SUCCESS = success(GET_IDENTITY_TOKEN);

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_FAILURE = failure(REFRESH_TOKEN);
export const REFRESH_TOKEN_SUCCESS = success(REFRESH_TOKEN);

export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = success(LOG_IN);

export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = success(LOG_OUT);

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const TOKEN_SET = 'TOKEN_SET';
