import React from 'react';

import PropTypes from 'prop-types';

import RadioGroup from 'components/RadioGroup';
import intlShape from 'shared/intlShape';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { withIntl } from 'utils/intl';

export const YesNoRadioGroupUnwrapped = ({ intl: { formatMessage }, ...props }) => {
  const handleChange = (ev) => props.onChange(ev.target.value);

  return (
    <RadioGroup
      options={[
        {
          caption: formatMessage({ id: 'components.radioGroup.yes' }),
          value: 'true',
        },
        {
          caption: formatMessage({ id: 'components.radioGroup.no' }),
          value: 'false',
        },
      ]}
      {...props}
      onChange={handleChange}
    />
  );
};

YesNoRadioGroupUnwrapped.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
};

const YesNoRadioGroup = withIntl(YesNoRadioGroupUnwrapped);

const fieldController = FormFieldController.instance;

const YesNoRadioGroupAdapter = ({ input, meta, ...rest }) => {
  const parseValueToString = (value) => {
    if (typeof value === 'boolean') {
      return String(value);
    }
    return value;
  };

  const parseStringToBoolean = (stringValue) => {
    if (typeof stringValue === 'string') {
      return stringValue === 'true';
    }
    return stringValue;
  };

  return (
    <YesNoRadioGroup
      {...input}
      value={parseValueToString(input.value)}
      onChange={(value) => {
        const parsedValue = parseStringToBoolean(value);
        input.onChange(parsedValue);
        fieldController.saveValue(input.name, parsedValue, { emit: false });
      }}
      {...meta}
      {...rest}
    />
  );
};
YesNoRadioGroupAdapter.propTypes = FinalFormFieldPropTypes;

const YesNoRadioGroupWithField = withFormField(YesNoRadioGroupAdapter);

export default YesNoRadioGroup;

export { YesNoRadioGroupWithField };
