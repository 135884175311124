import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { setPlanningEvaluationProfitability } from 'mittweida/portals/operations/store/action';
import mapBankValuesAdditionalInformationFromApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesAdditionalInformationFromApi';
import mapBankValuesObjectDataFromApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesObjectDataFromApi';
import mapFinancingCriteriaFromApi from 'modules/EditMarketValuesReeProfitability/mapFinancingCriteriaFromApi';
import mapMittweidaProfitabilityFromApi from 'modules/EditMarketValuesReeProfitability/mapMittweidaProfitabilityFromApi';
import {
  BANK_VALUES_RECORD_TYPE,
  FINANCING_CRITERIA_RECORD_TYPE,
  MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { flattenObject } from 'utils/helpers';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

const getPlanningEvaluationProfitabilityAction = (inquiryId: string, recordType: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
      params: { inquiryId },
      query: { record_type: recordType },
    }),
    method: API_METHODS.GET,
  });

function isMittweidaProfitabilityCalculationRecordType(
  value: string,
): value is MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE {
  return Object.values(MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE).includes(
    value as MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  );
}

const loadPlanningEvaluationProfitabilityData = async (
  makeCall: IMakeCall,
  inquiryId: string,
  recordType: string,
  dispatch?: Function,
) => {
  const { payload, error } = await makeCall(
    getPlanningEvaluationProfitabilityAction(inquiryId, recordType),
  );
  const data: unknown = _get(payload, 'data');

  if (dispatch) {
    dispatch(setPlanningEvaluationProfitability(payload.data));
  }

  let editFormValues;

  if (recordType === BANK_VALUES_RECORD_TYPE.OBJECT_DATA) {
    editFormValues = mapBankValuesObjectDataFromApi(data);
  }
  if (recordType === BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION) {
    editFormValues = mapBankValuesAdditionalInformationFromApi(data);
  }
  if (recordType === FINANCING_CRITERIA_RECORD_TYPE) {
    editFormValues = mapFinancingCriteriaFromApi(data);
  }
  if (isMittweidaProfitabilityCalculationRecordType(recordType)) {
    editFormValues =
      mapMittweidaProfitabilityFromApi(data)[
        recordType as MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE
      ];
  }
  FormFieldController.instance.saveBulk(flattenObject(editFormValues), { emit: false });
  return { error };
};

export default loadPlanningEvaluationProfitabilityData;
