import styled from 'styled-components/macro';

import Text from 'components/Text/Text';

const ModalSectionHeader = styled(Text)`
  padding-bottom: 3rem;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

export default ModalSectionHeader;
