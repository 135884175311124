import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import mapFinancingDetailsToApi from './mapMittweidaProfitabilityCalculationToApi/mapFinancingDetailsToApi';
import mapInvestmentPlanToApi from './mapMittweidaProfitabilityCalculationToApi/mapInvestmentPlanToApi';
import mapLendingValueToApi from './mapMittweidaProfitabilityCalculationToApi/mapLendingValueToApi';
import mapProfitabilitySalesToApi from './mapMittweidaProfitabilityCalculationToApi/mapProfitabilitySalesToApi';
import mapRentStockholderToApi from './mapMittweidaProfitabilityCalculationToApi/mapRentStockholderToApi';
import mapSourceOfFundsToApi from './mapMittweidaProfitabilityCalculationToApi/mapSourceOfFundsToApi';

export type MapMittweidaProfitabilityToApiType<T> = (
  data: Record<string, unknown>,
) => { [key in MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE]?: T };

const mapMittweidaProfitabilityToApi: MapMittweidaProfitabilityToApiType<Record<string, unknown>> =
  (data: Record<string, unknown | undefined>) => {
    return {
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS]:
        mapFinancingDetailsToApi(data),
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN]:
        mapInvestmentPlanToApi(data),
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE]: mapLendingValueToApi(data),
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES]:
        mapProfitabilitySalesToApi(data),
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS]:
        mapSourceOfFundsToApi(data),
      [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT]: mapRentStockholderToApi(data),
    };
  };

export default mapMittweidaProfitabilityToApi;
