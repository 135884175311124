import _get from 'lodash/get';

import {
  SOURCE_OF_FUNDS_EQUITY_GRANTS,
  SOURCE_OF_FUNDS_EQUITY_RELEASE,
  SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS,
  SOURCE_OF_FUNDS_GAK_LOAN,
  SOURCE_OF_FUNDS_LOAN_PROJECT,
  SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN,
} from 'modules/Inquiry/Form/formFields';

const mapSourceOfFundsFromApi = (response: unknown) => {
  const data = _get(response, 'data.attributes.details.source_of_funds');

  const sourceOfFundsValue = {
    [SOURCE_OF_FUNDS_EQUITY_GRANTS]: _get(data, 'equity_grants', '0,00'),
    [SOURCE_OF_FUNDS_EQUITY_RELEASE]: _get(data, 'equity_release', '0,00'),
    [SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS]: _get(
      data,
      'gak_equity_share_buying_costs',
      '0,00',
    ),
    [SOURCE_OF_FUNDS_GAK_LOAN]: _get(data, 'gak_loan', '0,00'),
    [SOURCE_OF_FUNDS_LOAN_PROJECT]: _get(data, 'loan_project', '0,00'),
    [SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN]: _get(data, 'notes_on_financing_plan', ''),
  };

  return sourceOfFundsValue;
};

export default mapSourceOfFundsFromApi;
