import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import paths from 'constants/paths';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import mapProfitabilityToEdit from '../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import {
  probabilitySaleTypeEvalUnitMapping,
  probabilitySaleTypeObjectTranslationMapping,
} from '../../types';
import { useRoles } from '../hooks';
import TableCardWithEdit from '../TableCardWithEdit';

interface props {
  data: { [key: string]: string | number | object };
}
const FinancingParameters: React.FC<props> = ({ data }: props) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { financingParameters } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.financingParameters',
  );
  const unitTranslations = useTranslations();

  const headers = [
    {
      key: 'label',
      text: t('tableHeaders.parameter'),
    },
    {
      key: 'value',
      text: t('tableHeaders.value'),
    },
  ];

  const { onEdit } = useEditFormConfig({
    pathToEdit: financingParameters(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS,
    loadData: loadEditProfitabilitySection,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS](),
  });
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;

  return (
    <Box
      border="1px"
      borderColor="border.lightGrey"
      p="8"
      bgColor="background.white"
      boxShadow="default"
      h="100%"
    >
      <TableCardWithEdit
        canEdit={canEdit}
        onEdit={onEdit}
        title={t('title')}
        tableHeaders={headers}
        componentName={PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS}
        tableData={
          data &&
          Object.keys(data).map((key) => ({
            id: key,
            label: t(`tableRows.${probabilitySaleTypeObjectTranslationMapping[key]}`),
            ...(typeof data[key] === 'object'
              ? {
                  value: formatColumnValues(
                    (data[key] as Record<string, string>)?.value,
                    probabilitySaleTypeEvalUnitMapping(unitTranslations)[key],
                  ),
                  rowHighlight: true,
                  valueBold: true,
                }
              : {
                  value: formatColumnValues(
                    (data as Record<string, string>)[key],
                    probabilitySaleTypeEvalUnitMapping(unitTranslations)[key],
                  ),
                }),
          }))
        }
      />
    </Box>
  );
};

export default FinancingParameters;
