import * as React from 'react';

import { Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import Page from 'components/Page/Page';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useIsProfiInquiry } from 'shared/hooks/useIsProfiInquiry';
import { getMeta } from 'store/progress/selectors';

import ActivateAccountAction from '../actions/ActivateAccountAction';
import DescriptionAction from '../actions/DescriptionAction';
import DocumentUploadAction from '../actions/DocumentUploadAction';
import IndicativeOfferAction from '../actions/IndicativeOfferAction';
import StaticActions from '../actions/StaticActions';
import VideoLegitimationAction from '../actions/VideoLegitimationAction';
import Footer from './Footer';
import Header from './Header';

export const SpecializedFinalPage: React.FC = () => {
  const isActivationEnabled = useConfig(CONFIG.CUSTOMER_ACTIVATION_ON_INVITATION);
  const metaData = useSelector(getMeta);
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = useIsProfiInquiry();
  const isBFSService = selectedInquiryType === InquiryType.bfsService;

  // fix unintended scrolling to bottom of page behaviour #CRV-6295
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <Header />

      {!isBFSService && <DescriptionAction />}

      {!isActivationEnabled && metaData?.summaryInfo?.activateAccount && (
        <>
          {!isBFSService ? (
            <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
          ) : (
            <Divider opacity="0" mt={10} mb={20} />
          )}
          <ActivateAccountAction />
        </>
      )}

      {!isBFSService && !metaData?.summaryInfo?.indicativeOffer && (
        <>
          <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
          <IndicativeOfferAction />
        </>
      )}

      <Condition condition={!isBFSService}>
        {!isProfiInquiry &&
          !isActivationEnabled &&
          metaData?.summaryInfo?.requestedDocuments &&
          !metaData?.fastlaneEligible && (
            <>
              <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
              <DocumentUploadAction />
            </>
          )}
      </Condition>

      {metaData?.summaryInfo?.videoIdentification && (
        <>
          <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
          <VideoLegitimationAction />
        </>
      )}
      {!isBFSService && (
        <>
          <StaticActions />

          <Footer />
        </>
      )}
    </Page>
  );
};
