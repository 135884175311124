import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import submitFinancingParametersValues from './submitFinancingParametersValues';
import submitInvestmentCostsValues from './submitInvestmentCostsValues';
import submitLendingValues from './submitLendingValues';
import submitPresalesValues from './submitPresalesValues';
import submitRentalParametersValues from './submitRentalParametersValues';
import submitSalesCostRateValues from './submitSalesCostRateValues';

export type MapProfitabilityActionsType<T> = (
  data: Record<string, unknown>,
  inquiryId: string,
) => { [key in PROFITABILITY_RECORD_TYPE]?: T };

const mapProfitabilityActionsToTypes: MapProfitabilityActionsType<unknown> = (data, inquiryId) => {
  return {
    [PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS]: submitFinancingParametersValues(
      data,
      inquiryId,
    ),
    [PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS]: submitInvestmentCostsValues(data, inquiryId),
    [PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION]: submitLendingValues(data, inquiryId),
    [PROFITABILITY_RECORD_TYPE.SALES_COST_RATE]: submitSalesCostRateValues(data, inquiryId),
    [PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT]: submitSalesCostRateValues(data, inquiryId),
    [PROFITABILITY_RECORD_TYPE.PRESALES_DATA]: submitPresalesValues(data, inquiryId),
    [PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS]: submitRentalParametersValues(data, inquiryId),
  };
};

export default mapProfitabilityActionsToTypes;
