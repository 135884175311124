import { useEffect } from 'react';

import _merge from 'lodash/merge';
import { useDispatch, useSelector } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { mapCountersToTabs } from 'modules/InquiryTable/mapFromApi';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { setInquiriesListStatusesAction } from 'store/inquiryList/actions';
import { getInquiryListOptionsSelector } from 'store/inquiryList/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const getInquiriesStatusesAction = () =>
  callReverseApi({
    url: endpoints.INQUIRIES.STATUSES.compose(),
    method: API_METHODS.GET,
  });

export const useInquiryTabStatuses = () => {
  const { makeCall, isPending, error } = useDispatchApiCall({ isPendingInitially: true });
  const tableOptions = useSelector(getInquiryListOptionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    makeCall(getInquiriesStatusesAction(), ({ payload }: any) => {
      const mappedTabs = mapCountersToTabs(payload.data);
      dispatch(setInquiriesListStatusesAction(mappedTabs));

      const defaultStatus = tableOptions.filterBy.status ?? '';
      dispatch(
        getInquiriesAction(
          _merge(tableOptions, {
            filterBy: { status: defaultStatus },
          }),
        ),
      );
    });
  }, [dispatch, makeCall]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isPending, error };
};
