import _get from 'lodash/get';
import _set from 'lodash/set';

import { refreshTokenAction } from 'middlewares/refreshToken/actions';
import { REFRESH_TOKEN } from 'shared/auth/loginLogout.actionTypes';

const AUTHORIZATION_HEADER_PATH = 'payload.request.headers.Authorization';

const getActionWithNewToken = (action, token) => {
  const actionWithNewToken = JSON.parse(JSON.stringify(action));
  _set(actionWithNewToken, AUTHORIZATION_HEADER_PATH, `Bearer ${token}`);

  return actionWithNewToken;
};

let refreshAccessTokenPromise = null;

const getAccessToken = (dispatch, getState) => {
  const {
    user: { token, expiresAt },
  } = getState();

  const isExpired = new Date(expiresAt) <= new Date();

  if (!isExpired) {
    return Promise.resolve(token);
  }

  if (refreshAccessTokenPromise === null) {
    refreshAccessTokenPromise = new Promise((resolve, reject) => {
      dispatch(refreshTokenAction())
        .then((response) => {
          const newAccessToken = _get(response, 'payload.data.access_token');
          resolve(newAccessToken);
        })
        .catch(reject);
    });

    refreshAccessTokenPromise.then(() => {
      refreshAccessTokenPromise = null;
    });
    refreshAccessTokenPromise.catch(() => {
      refreshAccessTokenPromise = null;
    });
  }

  return refreshAccessTokenPromise;
};

const refreshTokenMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    // check if this is redux-axios-middleware's action or request to refresh token
    if (!_get(action, 'payload.request') || action.type === REFRESH_TOKEN) {
      return next(action);
    }

    const {
      user: { refreshToken },
    } = getState();
    const authorizationHeader = _get(action, AUTHORIZATION_HEADER_PATH);

    if (authorizationHeader && refreshToken) {
      const accessToken = await getAccessToken(dispatch, getState);
      return next(getActionWithNewToken(action, accessToken));
    }

    return next(action);
  };

export default refreshTokenMiddleware;
