import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { setProcessLane } from 'store/inquiryProcess/actions';
import { SET_PROCESS_LANE } from 'store/inquiryProcess/actionTypes';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { InquiryType } from '../../modules/Inquiry/Inquiry.type';

export const callCheckProcessLane = (inquiryId: string) =>
  callReverseApi({
    actionName: SET_PROCESS_LANE,
    url: endpoints.INQUIRIES.BIG_PICTURE_CHECK.compose({ params: { id: inquiryId } }),
    method: API_METHODS.GET,
  });

export const useProcessLaneCheck = () => {
  const dispatch = useDispatch();
  const [isRetryPending, setRetryPending] = useState(false);
  const { error, makeCall, isPending } = useDispatchApiCall({
    isPendingInitially: false,
    showErrorNotification: false,
    errorMessage: '',
  });

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const checkProcessLane = useCallback(
    async (inquiryId) => {
      const { payload, error } = await makeCall(callCheckProcessLane(inquiryId));
      if (!error) {
        return payload.data.data.attributes.lane;
      }
      return undefined;
    },
    [makeCall],
  );

  const checkRepeatedlyLane = async (
    inquiryId: string,
    attempt: number,
    inquiryType: InquiryType,
  ): Promise<any> => {
    setRetryPending(true);
    try {
      let lane;

      if (
        [InquiryType.bigPicture, InquiryType.onlinefactoring, InquiryType.bfsService].includes(
          inquiryType,
        )
      ) {
        lane = await checkProcessLane(inquiryId);

        if (!lane) {
          throw new Error('Wait for crefo response ...');
        }
      }

      dispatch(setProcessLane(lane ?? InquiryLane.lead));
      setRetryPending(false);
      return lane;
    } catch (error) {
      if (attempt <= 2) {
        const lane = await checkProcessLane(inquiryId);
        setRetryPending(false);
        return lane;
      }
      await sleep(1000);
      return checkRepeatedlyLane(inquiryId, attempt - 1, inquiryType);
    }
  };
  return { error, isPending, checkProcessLane, isRetryPending, checkRepeatedlyLane };
};
