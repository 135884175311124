import React from 'react';

import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import ProjectCostingBoxes from '../../../SharedComponent/ProjectCostingBoxes';
import { probabilityTopTilesTranslationMapping, probabilityTopTilesUnitMapping } from '../../types';

interface Props {
  boxes: { [key: string]: string | number };
}

const FinancingBoxes: React.FC<Props> = ({ boxes }: Props) => {
  const t = useTranslations('pages.planningEvaluation.profitabilityCalculation.boxes');
  const tUnit = useTranslations();
  const boxValues =
    boxes &&
    Object.keys(boxes).map((item) => {
      return {
        id: item,
        name: t(`${probabilityTopTilesTranslationMapping[item]}`),
        value: formatColumnValues(boxes[item], probabilityTopTilesUnitMapping(tUnit)[item], true),
      };
    });

  return <ProjectCostingBoxes values={boxValues} />;
};

export default FinancingBoxes;
