import React from 'react';

import { Box, Divider } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';

import FormRow from 'components/FormRow';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { InputWithField } from 'components/Input';
import { Container } from 'components/PortalPage/styles';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import {
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_EMPLOYEE_COUNT,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useToasts } from 'shared/hooks/useToasts';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { useAdditionalFields } from './useAdditionalFieldsCall';

const CustomerAdditionalFields = () => {
  const t = useTranslations('pages.inquiryDetails.additionalFields');
  const history = useHistory();
  const callAdditionalFields = useAdditionalFields();
  const { id: inquiryId } = useParams() as { id: string };
  const { error: errorNotify, success } = useToasts();

  const submit = async (formValues: any) => {
    const { error } = await callAdditionalFields(
      inquiryId,
      formValues[USER_PROFILE_DETAILED_ANNUAL_TURNOVER],
      formValues[USER_PROFILE_BALANCE_SHEET],
      formValues[USER_PROFILE_EMPLOYEE_COUNT],
    );
    if (!error) {
      success({ description: t('successful') });
      history.push(paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId));
    } else {
      errorNotify({ description: t('error') });
    }
  };
  return (
    <Container as="main">
      <Box pt="12" pb="20">
        <Box maxWidth="800px" pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t('title')}
          </HeadingComponent>

          <TextComponent color="text.tertiary">{t('headline')}</TextComponent>
        </Box>
        <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />

        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormSectionLayout sectionNumber={1} title={t('sectionTitle')}>
                <FormRow>
                  <AmountWithField
                    name={USER_PROFILE_DETAILED_ANNUAL_TURNOVER}
                    parseValue={formatPrice}
                  />
                </FormRow>
                <FormRow>
                  <AmountWithField name={USER_PROFILE_BALANCE_SHEET} parseValue={formatPrice} />
                </FormRow>
                <FormRow>
                  <InputWithField name={USER_PROFILE_EMPLOYEE_COUNT} type="number" />
                </FormRow>
              </FormSectionLayout>
              <StyledActions>
                <ButtonComponent
                  leftIcon={<CloseIcon boxSize={6} d="block" />}
                  mr={4}
                  onClick={() =>
                    history.push(paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId))
                  }
                  variant="tertiary"
                >
                  {t('cancel')}
                </ButtonComponent>
                <ButtonComponent leftIcon={<SaveIcon boxSize={6} d="block" />} type="submit">
                  {t('submit')}
                </ButtonComponent>
              </StyledActions>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};

export default CustomerAdditionalFields;
