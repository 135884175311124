import styled from 'styled-components/macro';

import { marginBottom } from 'components/Text/InputCaption';
import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLabel = styled.span`
  display: block;
  margin-bottom: ${marginBottom};
  font-weight: ${FONT_WEIGHT_LIGHT};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
