import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import mapFinancingDetailsFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapFinancingDetailsFromApi';
import mapInvestmentPlanFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapInvestmentPlanFromApi';
import mapLendingValueFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapLendingValueFromApi';
import mapProfitabilitySalesFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapProfitabilitySalesFromApi';
import mapRentStockholderFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapRentStockholderFromApi';
import mapSourceOfFundsFromApi from './mapMittweidaProfitabilityCalculationFromApi/mapSourceOfFundsFromApi';

export type MapMittweidaProfitabilityFromApiType<T> = (
  response: unknown,
) => { [key in MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE]?: T };

const mapMittweidaProfitabilityFromApi: MapMittweidaProfitabilityFromApiType<unknown> = (
  response: unknown,
) => {
  return {
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS]:
      mapFinancingDetailsFromApi(response),
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN]:
      mapInvestmentPlanFromApi(response),
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE]:
      mapLendingValueFromApi(response),
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES]:
      mapProfitabilitySalesFromApi(response),
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT]: mapRentStockholderFromApi(response),
    [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS]:
      mapSourceOfFundsFromApi(response),
  };
};

export default mapMittweidaProfitabilityFromApi;
