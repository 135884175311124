import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import InquiryTypeCondition from 'components/InquiryTypeCondition';
import Spinner from 'components/Spinner';
import { UploadMultipleFiles } from 'components/UploadBlock/NewFileRequestBlock/UploadMultipleFiles';
import { useFormConfig } from 'config/formConfig/hooks';
import { USER_SCOPE } from 'constants/userScopes';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FileRequestsFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileRequestsFromBank/FileRequestsFromBank';
import FilesFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/FilesFromBank';
import FilesToBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToBank/FilesToBank';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import {
  useFetchInquiryFiles,
  useFetchUpdatedFile,
} from 'shared/documentExchange/useFetchInquiryFiles';
import { useToasts } from 'shared/hooks/useToasts';
import { useWebSocket, Channels } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector, getInquiryStatusSelector } from 'store/inquiryDetails/selectors';

import InquiryTypeSpecificHeader from './InquiryDetailsHeaderButton';
import LatestSignedContractFromBank from './LatestSignedContractFromBank/LatestSignedContractFromBank';

const CustomerDocumentExchange = () => {
  const dispatch = useDispatch();
  const download = useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.CUSTOMER });
  const { selectedLanguage } = useLanguagePreferences();
  const { isLoading, fetchData: fetchFileRequests } = useFetchInquiryFileRequests({
    isDownloadedBy: USER_SCOPE.OPERATION_PORTAL,
    selectedLanguage,
  });
  const inquiryStatus = useSelector(getInquiryStatusSelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const { fetchUpdatedFile } = useFetchUpdatedFile();
  const { selectedInquiryType } = useFormConfig();
  const showMultipleFileUpload = selectedInquiryType === InquiryType.profiMittweida;
  const { error } = useToasts();

  useWebSocket({
    channel: Channels.INQUIRY,
    customProps: {
      id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  useWebSocket({
    channel: Channels.UPLOADABLE_FILE,
    customProps: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      try {
        const data = JSON.parse(m.data as string);
        const fileId = data.message?.id;
        const relatedMessageType = data.type === 'uploaded_files' && fileId;
        if (relatedMessageType) {
          fetchUpdatedFile(fileId);
          fetchFileRequests(inquiryId);
        }
      } catch (err) {
        error({});
      }
    },
  });

  if (download.isLoading || isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const fulfilledOrArchivedStatus =
    inquiryStatus === INQUIRY_STATUSES.FULFILLED || inquiryStatus === INQUIRY_STATUSES.ARCHIVED;

  return (
    <article>
      <InquiryDetailsHeader>
        <InquiryTypeSpecificHeader />
      </InquiryDetailsHeader>
      <Condition condition={fulfilledOrArchivedStatus}>
        <LatestSignedContractFromBank />
      </Condition>
      <Condition condition={showMultipleFileUpload}>
        <UploadMultipleFiles />
      </Condition>
      <FilesFromBank />
      <FileRequestsFromBank />
      <FilesToBank />
      <FilesToCompeon />
    </article>
  );
};

const LeasePlanCustomerDocumentExchange = () => {
  const dispatch = useDispatch();
  const download = useFetchInquiryFiles({ isDownloadedBy: USER_SCOPE.CUSTOMER });
  const inquiryStatus = useSelector(getInquiryStatusSelector);
  const { selectedLanguage } = useLanguagePreferences();
  const requests = useFetchInquiryFileRequests({
    isDownloadedBy: USER_SCOPE.CUSTOMER,
    selectedLanguage,
  });
  const inquiryId = useSelector(getInquiryIdSelector);

  useWebSocket({
    channel: Channels.INQUIRY,
    customProps: {
      id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  if (download.isLoading || requests.isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const fulfilledOrArchivedStatus =
    inquiryStatus === INQUIRY_STATUSES.FULFILLED || inquiryStatus === INQUIRY_STATUSES.ARCHIVED;
  return (
    <article>
      <InquiryDetailsHeader>
        <InquiryTypeSpecificHeader />
      </InquiryDetailsHeader>
      {fulfilledOrArchivedStatus && <LatestSignedContractFromBank />}
      <FileRequestsFromBank />
      <FilesToBank />
    </article>
  );
};

const DisplayCondition = () => {
  return (
    <InquiryTypeCondition
      portal
      cases={{
        leaseplan: <LeasePlanCustomerDocumentExchange />,
        default: <CustomerDocumentExchange />,
      }}
    />
  );
};

export default DisplayCondition;
