import env from '@beam-australia/react-env';
import Axios from 'axios';

export const WHITE_LABEL_API_CLIENT_NAME = 'whiteLabel';
export const COMPEON_API_CLIENT_NAME = 'compeon';
export const AUTH_API_CLIENT_NAME = 'auth';
export const COMPEON_OAUTH_API_CLIENT_NAME = 'compeonOAuth';
export const MOCK_API_CLIENT_NAME = 'mockAPI';

export const createMockApiClient = () =>
  Axios.create({
    baseURL: '/',
    responseType: 'json',
  });

export const createCompeonReverseClient = () =>
  Axios.create({
    baseURL: env('WHITE_LABEL_API_URL'),
    headers: { 'Content-Type': 'application/vnd.api+json' },
  });

export const createAuthClient = () =>
  Axios.create({
    baseURL: env('AUTH_API_URL'),
    responseType: 'json',
  });

export const createCompeonOAuthClient = () =>
  Axios.create({
    baseURL: env('COMPEON_OAUTH_API_URL'),
    responseType: 'json',
  });

export const createCompeonClient = () =>
  Axios.create({
    baseURL: env('COMPEON_API_URL'),
    responseType: 'json',
  });
