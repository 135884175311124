import React from 'react';

import paths from 'constants/paths';
import { ErrorTextWrapper, StyledLink } from 'modules/AvailableEmail/EmailNotAvailable/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  email: string;
};

const EmailNotAvailable = ({ email }: Props) => {
  const redirectPath = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: paths.contactPersonDetails,
    default: paths.userProfile,
  });

  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.errors.emailTaken',
  );

  return (
    <ErrorTextWrapper>
      {t('haveAccount')}
      <StyledLink
        to={{
          pathname: paths.customer.login,
          state: { redirectTo: redirectPath, email },
        }}
      >
        {t('logIn')}
      </StyledLink>
    </ErrorTextWrapper>
  );
};

export default EmailNotAvailable;
