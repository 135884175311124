import styled from 'styled-components/macro';

import { ZoomAnimation } from 'components/Animations/styles';
import { mqMedium, mqLarge } from 'styles/breakpoints';

export const StyledProductKinds = styled.div`
  ${mqMedium`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

export const StyledSingleProduct = styled(ZoomAnimation)`
  flex: 0 1 auto;
  width: 100%;
  padding: 1rem;

  ${mqMedium`
    width: 50%;
  `}

  ${mqLarge`
    width: 33.333333%;
  `}
`;
