import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { SAVE_INQUIRIES_SUCCESS } from 'api/CompeonReverseApi/actionTypes';
import {
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  REGISTRATION,
  CONTACT_PERSON_STEP,
  PEOPLE_DETAILS,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  CONTACT_PERSON_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import { PURPOSE_STEP } from 'onlinefactoring/formFields';
import {
  SET_PROGRESS_COUNTER_TO_MAX,
  SET_SECTION_VALIDATION,
  RESET_SECTION_VALIDATION,
} from 'store/progress/actions';
import { getUpdateState } from 'utils/storeHelpers';

import { mittweidaSteps } from '../../mittweida/inquiry/steps';
import {
  CLEAR_STAGES,
  DISABLE_REDIRECTING_TO_FIRST_PAGE,
  RESET_FORM,
  RESET_STAGE_PROGRESS,
  SET_CURRENT_STAGE,
  SET_PROGRESS_COUNTER,
  STORE_VALUES,
  SAVE_CREATED_INQUIRY_ID,
} from './actionTypes';

export interface ISummaryInfoAttributes {
  activate_account: ISummaryInfo['activateAccount'];
  indicative_offer: ISummaryInfo['indicativeOffer'];
  requested_documents: ISummaryInfo['requestedDocuments'];
  video_identification: ISummaryInfo['videoIdentification'];
}

export interface ISummaryInfo {
  activateAccount: boolean;
  indicativeOffer: boolean;
  requestedDocuments: boolean;
  videoIdentification: boolean;
}

export interface IMetaAttributes {
  summary_info: ISummaryInfoAttributes;
}

export interface IMeta {
  summaryInfo: ISummaryInfo;
  fastlaneEligible: boolean;
}

interface IProgressState {
  currentStage: number;
  sectionValidations: Object;
  stages: {
    [key: string]: {
      currentStep: number;
      stepCount: number;
    };
  };
  storedValues: Object;
  shouldRedirectToFirstStage: boolean;
  shouldResetForm: boolean;
  createdInquiry?: string;
  meta?: IMeta;
}

export const mapMeta = (payload: IMetaAttributes, fastlaneEligible: boolean = false): IMeta => ({
  summaryInfo: {
    activateAccount: payload.summary_info.activate_account,
    indicativeOffer: payload.summary_info.indicative_offer,
    requestedDocuments: payload.summary_info.requested_documents,
    videoIdentification: payload.summary_info.video_identification,
  },
  fastlaneEligible,
});

const initialState: IProgressState = {
  currentStage: 0,
  sectionValidations: {},
  stages: {
    [REGISTRATION]: {
      currentStep: 0,
      stepCount: 1,
    },
    [FINANCING_NEED]: {
      currentStep: 0,
      stepCount: 3,
    },
    [CONTRACT_DETAILS]: {
      currentStep: 4,
      stepCount: 4,
    },
    [CUSTOMER_DATA]: {
      currentStep: 0,
      stepCount: 4,
    },
    [COMPANY_DETAILS]: {
      currentStep: 0,
      stepCount: 3,
    },
    [REQUEST_DETAILS]: {
      currentStep: 0,
      stepCount: 3,
    },
    [USER_PROFILE]: {
      currentStep: 0,
      stepCount: 3,
    },
    [CONTACT_PERSON_STEP]: {
      currentStep: 0,
      stepCount: 2,
    },
    [PEOPLE_DETAILS]: {
      currentStep: 0,
      stepCount: 4,
    },
    [PURPOSE_STEP]: {
      currentStep: 1,
      stepCount: 3,
    },
    [CONTACT_PERSON_DETAILS]: {
      currentStep: 0,
      stepCount: 2,
    },
    [mittweidaSteps.fundingDetailsPage]: {
      currentStep: 0,
      stepCount: 3,
    },
    [mittweidaSteps.companyDetailPage]: {
      currentStep: 0,
      stepCount: 3,
    },
    [mittweidaSteps.userProfilePage]: {
      currentStep: 0,
      stepCount: 3,
    },
    [mittweidaSteps.financingNeedPage]: {
      currentStep: 0,
      stepCount: 3,
    },
  },
  storedValues: {},
  shouldRedirectToFirstStage: true,
  shouldResetForm: false,
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
  const updateState = getUpdateState(state);
  switch (type) {
    case SET_PROGRESS_COUNTER:
      return updateState({
        stages: {
          ...state.stages,
          [payload.stage]: {
            currentStep: payload.current,
            stepCount: payload.total,
          },
        },
      });
    case SAVE_INQUIRIES_SUCCESS: {
      return {
        ...state,
        meta:
          payload?.data?.meta &&
          mapMeta(payload?.data.meta, payload?.data.data.attributes.fastlane_eligible),
        createdInquiry: payload?.data.data.id,
      };
    }
    case SET_PROGRESS_COUNTER_TO_MAX: {
      return updateState({
        stages: {
          ...state.stages,
          [payload]: {
            ...state.stages[payload],
            currentStep: state.stages[payload].stepCount,
          },
        },
      });
    }
    case SET_CURRENT_STAGE:
      return updateState({
        currentStage: payload.currentStage,
      });
    case STORE_VALUES:
      return updateState({
        storedValues: {
          ...state.storedValues,
          ...payload,
        },
      });
    case DISABLE_REDIRECTING_TO_FIRST_PAGE:
      return updateState({ shouldRedirectToFirstStage: false });
    case CLEAR_STAGES:
      return {
        ...initialState,
      };
    case RESET_STAGE_PROGRESS:
      return updateState({ stages: initialState.stages });
    case RESET_FORM:
      return updateState({ shouldResetForm: payload.shouldResetForm });
    case SAVE_CREATED_INQUIRY_ID:
      return updateState({ createdInquiry: payload });
    case SET_SECTION_VALIDATION:
      return updateState({ sectionValidations: payload });
    case RESET_SECTION_VALIDATION:
      return updateState({ sectionValidations: initialState.sectionValidations });
    default:
      return state;
  }
};

const persistConfig = {
  key: 'progress',
  storage,
};

export default persistReducer(persistConfig, reducer);
