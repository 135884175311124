interface FieldWrapperThemeProps {
  captionFontCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
}

const FieldWrapper = {
  parts: ['caption'],
  baseStyle: ({ captionFontCase }: FieldWrapperThemeProps) => ({
    caption: {
      textTransform: captionFontCase ?? 'uppercase',
    },
  }),
  variants: {},
  defaultProps: {},
};

export default FieldWrapper;
