import React from 'react';

import { Box, Divider } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import FormRow from 'components/FormRow';
import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import Title from 'components/FormSection/Title';
import { INPUT_TYPE_DECICMAL_WITH_ZERO, INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { Container } from 'components/PortalPage/styles';
import { StyledActions } from 'components/StyledActions';
import Text from 'components/Text/Text';
import Unit from 'components/Unit/Unit';
import paths from 'constants/paths';
import { useOfferInputFields } from 'modules/helpers/useOfferFields';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { Content as Values } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import { nonBindingOfferAction } from 'store/inquiryDetails/actions';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import OfferCalculations from './OfferCalculations';

const NonBindingOffer: React.FC = () => {
  const t = useTranslations();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error: notifyError, success } = useToasts();
  const inputs = useOfferInputFields();
  const { required, maxValue, minValue } = useFieldValidators();
  const { isPending, makeCall } = useDispatchApiCall();
  const inquiryLane = useSelector(getInquiryLane);
  const isLead = inquiryLane === InquiryLane.lead;

  const submitOffer = async (formValues: Values) => {
    const { error } = await makeCall(nonBindingOfferAction(id, formValues));

    if (error) {
      notifyError({
        description: t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.error'),
      });
    } else {
      success({ description: t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.success') });
      history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id));
    }
  };

  return (
    <Container>
      <Box pt="12" pb="20">
        <Box maxWidth="800px" pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.headline')}
          </HeadingComponent>

          <TextComponent color="text.tertiary">
            {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.summary')}
          </TextComponent>
        </Box>

        {isLead && <OfferCalculations />}
        <Section>
          <Title data-aos="fade-right" data-aos-once>
            <Text as="span">
              {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.offerParameter')}
            </Text>
          </Title>

          <Content data-aos="fade-left" data-aos-once>
            <Form onSubmit={submitOffer}>
              {({ valid, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box pr={['20rem']}>
                    {inputs.map((input) => (
                      <FormRow key={input.caption}>
                        <InputWithField
                          name={input.input}
                          caption={t(input.caption)}
                          key={input.input}
                          sideSymbol={() => <Unit type={input.unit} />}
                          type={input.isDecimal ? INPUT_TYPE_DECICMAL_WITH_ZERO : INPUT_TYPE_NUMBER}
                          validate={combineValidators(
                            required,
                            maxValue(input.maxValue),
                            input.minValue ? minValue(input.minValue) : required,
                          )}
                          decimal={input.isDecimal}
                          captionTooltip={t(input.captionTooltip)}
                        />
                      </FormRow>
                    ))}
                  </Box>
                  <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
                  <StyledActions>
                    <ButtonComponent
                      mr={4}
                      onClick={() =>
                        history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id))
                      }
                      variant="tertiary"
                    >
                      {t('buttons.cancel')}
                    </ButtonComponent>
                    <ButtonComponent
                      leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
                      type="submit"
                      disabled={!valid || isPending}
                      data-testid="offer-submit-button"
                    >
                      {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.submit')}
                    </ButtonComponent>
                  </StyledActions>
                </form>
              )}
            </Form>
          </Content>
        </Section>
      </Box>
    </Container>
  );
};

export default NonBindingOffer;
