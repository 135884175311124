import { isProduction, isDevelopment } from 'utils/environment';
import {
  init as rollbarInit,
  getInstance as getRollbarInstance,
} from 'utils/errorTracking/Rollbar';

const isOn = () => isProduction();

export const init = () => {
  if (isOn()) {
    rollbarInit();
  }
};

export const reportError = (error, extra) => {
  if (isOn()) {
    getRollbarInstance().error(error, extra);
  }

  if (isDevelopment()) {
    console.error({ error, extra }); // eslint-disable-line no-console
  }
};
