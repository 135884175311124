import React from 'react';

import { Field } from 'react-final-form';

import { RadioGroupWithField } from 'components/RadioGroup';
import {
  LOAN_FIXED_INTEREST_RATE,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  FINANCING_NEED,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryRadioValues } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryRadioValues';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useTranslations } from 'utils/hooks/useTranslations';

const fieldController = FormFieldController.instance;

const LoanFixedInterestRate = () => {
  const options = useInquiryRadioValues(LOAN_FIXED_INTEREST_RATE);
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanFixedInterestRate');

  return (
    <RadioGroupWithField name={LOAN_FIXED_INTEREST_RATE} options={options} tooltip={t('tooltip')} />
  );
};

const CoronaLoanFixedInterestRate = () => {
  const { optional } = useFieldValidators();
  return (
    <Field
      name={LOAN_FIXED_INTEREST_RATE}
      validate={optional}
      render={({ input }) => {
        input.onChange(LOAN_FIXED_INTEREST_RATE__FIXED);
        fieldController.saveValue(input.name, LOAN_FIXED_INTEREST_RATE__FIXED, {
          emit: false,
        });
        return null;
      }}
    />
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaLoanFixedInterestRate,
    },
  ],
  LoanFixedInterestRate,
);
