import _get from 'lodash/get';

import { PROFITABILITY_USUAL_SALES_COST_RATE } from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapSalesCostRateToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const salesCostRateData = {
    usual_sales_costs_rate: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_USUAL_SALES_COST_RATE)),
    ),
  };

  return salesCostRateData;
};

export default mapSalesCostRateToApi;
