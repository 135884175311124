const VerticalProgressBar = {
  parts: ['container', 'circularProgress'],
  baseStyle: {
    container: {
      position: 'absolute',
      zIndex: 'verticalProgressBar.base',
      top: '0',
      left: '0',
      bottom: '0',
      w: '80px',
    },
    circularProgress: {
      position: 'absolute',
      zIndex: 'verticalProgressBar.counter',
      top: '0',
      left: '50%',
      transform: 'translate(-50%, 2.5rem)',
    },
  },
};

export default VerticalProgressBar;
