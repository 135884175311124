import React, { useCallback, useState } from 'react';

import { Divider, Flex, HStack, Icon, useDisclosure, VStack, Text, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ReactComponent as DocumentIcon } from 'assets/specific-icons/file.svg';
import Condition from 'components/Condition';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { useMultipleFiles } from 'components/UploadBlock/hooks/useMultipleFiles';
import type { IPrivateFile, UploadableFileTypes } from 'models/File.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getFileClassificationMap } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';
import { AlertDialogComponent as DeleteFileInFileRequestDialog } from 'theme/components/AlertDialog';
import { BadgeComponent, BadgeStatus } from 'theme/components/Badge';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FileReclassificationButton } from './FileReclassificationButton';

interface FileListProps {
  files: Array<UploadableFileTypes>;
  fileRequestId?: string;
  showFileDownloadStatusBadge?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}
export const FileList = ({
  files,
  fileRequestId,
  showFileDownloadStatusBadge,
  isPrivateFile,
  isAssessmentFile,
}: FileListProps) => {
  const { deleteFile, downloadFile, previewFile } = useMultipleFiles({
    fileRequestId,
    isPrivateFile,
    isAssessmentFile,
  });
  const {
    isOpen: isDeleteFileDialogOpen,
    onOpen: onDeleteFileDialogOpen,
    onClose: onDeleteFileDialogClose,
  } = useDisclosure();
  const t = useTranslations();
  const { id, fileName } = files[0];
  const [selectedFile, setSelectedFile] = useState<{ id: string; fileName: string }>({
    id,
    fileName,
  });

  const fileClassificationMap = useSelector(getFileClassificationMap);
  const isCustomer = useSelector(isCustomerSelector);
  const isMittweida = useSelector(getInquiryFormTypeSelector) === InquiryType.profiMittweida;
  const isFileReclassificationEnabled = !isCustomer && isMittweida && !isAssessmentFile;

  const shouldShowInlineFileClassification = useCallback(
    (file: Partial<UploadableFileTypes>) =>
      Boolean(isPrivateFile) && 'classification' in file && file.classification !== null,
    [isPrivateFile],
  );

  return (
    <>
      <VStack divider={<Divider borderColor="border.lightGrey" />} spacing={4} align="stretch">
        {files.map(({ id, createdAt, fileName, status, ...fileProps }: UploadableFileTypes) => (
          <Flex alignItems="center" justifyContent="space-between" key={id}>
            <Center width={8}>
              {showFileDownloadStatusBadge && status === DOWNLOAD_STATUS.NOT_DOWNLOADED ? (
                <BadgeComponent status={BadgeStatus.INFO} fontSize="sm">
                  {t('components.upload.newFile')}
                </BadgeComponent>
              ) : (
                <Icon as={DocumentIcon} boxSize={4} />
              )}
            </Center>
            <HStack flexGrow="1" spacing={16} paddingLeft={8}>
              <Text color="text.secondary" width={40}>
                {formatDateWithTime(createdAt)}
              </Text>
              <Text
                color="text.secondary"
                width={isPrivateFile ? 80 : 96}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {fileName}
              </Text>
              <Condition condition={shouldShowInlineFileClassification(fileProps)}>
                <Text
                  color="text.secondary"
                  width={80}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {fileClassificationMap[(fileProps as IPrivateFile).classification!]}
                </Text>
              </Condition>
            </HStack>
            <HStack spacing={4}>
              <Condition condition={isFileReclassificationEnabled}>
                <FileReclassificationButton
                  fileId={id}
                  fileRequestId={fileRequestId}
                  isPrivateFile={isPrivateFile}
                  isAssessmentFile={isAssessmentFile}
                />
              </Condition>
              <ViewIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => previewFile(id)}
              />
              <DownloadIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => downloadFile(id, fileName)}
              />
              <DeleteIcon
                color="brand.default"
                boxSize={4}
                cursor="pointer"
                onClick={() => {
                  setSelectedFile({ id, fileName });
                  onDeleteFileDialogOpen();
                }}
              />
            </HStack>
          </Flex>
        ))}
      </VStack>
      <DeleteFileInFileRequestDialog
        cancelText={t('components.upload.cancelText')}
        confirmText={t('components.upload.confirmText')}
        isOpen={isDeleteFileDialogOpen}
        onClose={onDeleteFileDialogClose}
        onConfirm={() => {
          deleteFile(selectedFile.id);
          onDeleteFileDialogClose();
        }}
        title={t('components.upload.remove')}
        children={t('components.upload.doYouWantToRemove', { filename: selectedFile.fileName })}
      />
    </>
  );
};
