import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import { Card } from 'mittweida/portals/operations/components/Card';
import SummaryCards from 'mittweida/portals/operations/components/SummaryCards';
import SummaryCard from 'mittweida/portals/operations/components/SummaryCards/SummaryCard';
import {
  getProfitabilityDataRentBoxes,
  getProfitabilityRentTable,
} from 'mittweida/portals/operations/store/selectors';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { RentItem } from '.';

interface RentBoxItem {
  bank_rental_income: string;
  bank_rental_income_commercial: string;
  bank_rental_income_residential: string;
  bank_rental_income_total_parking: string;
}

export interface RentBoxes {
  current: RentBoxItem;
  future: RentBoxItem;
}

const stockholderMapping: MappingProps<RentItem> = [
  { key: 'rental_income', unit: 'currency', styles: { bold: true } },
  { key: 'operating_costs', unit: 'currency', styles: { bold: true } },
  { key: 'operating_costs_percent', unit: 'percent', styles: { bold: true, italic: true } },
  { key: 'net_income', unit: 'currency', styles: { bold: true } },
  { key: 'service_percent', unit: 'percent', styles: { bold: true } },
  { key: 'service_year', unit: 'currency', styles: { bold: true } },
  { key: 'surplus_year', unit: 'currency', styles: { bold: true, highlighted: true } },
  {
    key: 'surplus_financing_duration',
    unit: 'currency',
    styles: { bold: true, highlighted: true },
  },
  { key: 'free_income', unit: 'currency', styles: { indented: true, italic: true } },
  { key: 'asset_surplus', unit: 'currency', styles: { indented: true, italic: true } },
  { key: 'equity_proof', unit: 'currency', styles: { indented: true, italic: true } },
  { key: 'surplus_securities_year', unit: 'currency', styles: { bold: true, highlighted: true } },
  {
    key: 'surplus_securities_financing_duration',
    unit: 'currency',
    styles: { bold: true, highlighted: true },
  },
  { key: 'profit_external_funds', unit: 'percent', styles: { bold: true } },
  { key: 'profit_total_funds', unit: 'percent', styles: { bold: true } },
  { key: 'service_utilization', unit: 'percent', styles: { bold: true } },
  { key: 'dscr', unit: 'none', styles: { bold: true } },
];

export const RentStockholder = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.profitabilityCalculation');
  const formatter = useUnitFormatter();
  const rentStockholderTableData = useSelector(getProfitabilityRentTable);
  const rentBoxes = useSelector(getProfitabilityDataRentBoxes);
  const { usageType } = useSelector(getMarketDataFilter);
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { rent } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: rent(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT](),
  });

  const showWarnings = Number(rentStockholderTableData.current.bank_values.surplus_year) < 0;
  const maxFinancingAmount = formatter.currency(
    rentStockholderTableData.additional_information[usageType].max_financing_amount,
  );
  const additionalEquityRequired = formatter.currency(
    rentStockholderTableData.additional_information[usageType].additional_equity_required,
  );

  const rentStockholderTable = usePortalTable({
    data: stockholderMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`tables.rent.stockholder.rows.${key}`),
      customerNow: formatter[unit](rentStockholderTableData.customer_now[key]),
      customerPlan: formatter[unit](rentStockholderTableData.customer_plan[key]),
      bankValues: formatter[unit](rentStockholderTableData[usageType].bank_values[key]),
      minimumRent: formatter[unit](rentStockholderTableData[usageType].minimum_rent[key]),
    })),
    headers: [
      { key: 'position', text: t('tables.rent.stockholder.headers.position') },
      { key: 'customerNow', text: t('tables.rent.stockholder.headers.customerNow') },
      { key: 'customerPlan', text: t('tables.rent.stockholder.headers.customerPlan') },
      { key: 'bankValues', text: t('tables.rent.stockholder.headers.bankValues') },
      { key: 'minimumRent', text: t('tables.rent.stockholder.headers.minimumRent') },
    ],
  });

  const rowStyles = getRowStylesMap(stockholderMapping);

  if (!rentStockholderTable || !rentBoxes) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <>
      <SummaryCards>
        <SummaryCard
          caption={t('boxes.rent.bankRentalIncomeResidential')}
          value={formatter.currency(rentBoxes[usageType].bank_rental_income_residential)}
        />
        <SummaryCard
          caption={t('boxes.rent.bankRentalIncomeCommercial')}
          value={formatter.currency(rentBoxes[usageType].bank_rental_income_commercial)}
        />
        <SummaryCard
          caption={t('boxes.rent.bankRentalIncomeTotalParking')}
          value={formatter.currency(rentBoxes[usageType].bank_rental_income_total_parking)}
        />
        <SummaryCard
          caption={t('boxes.rent.bankRentalIncome')}
          value={formatter.currency(rentBoxes[usageType].bank_rental_income)}
        />
      </SummaryCards>
      <Card title={t(`tables.rent.stockholder.title`)} onEdit={onEdit} isExpandable isExpanded>
        <PortalTable {...rentStockholderTable} rowStylesMap={{ ...rowStyles }} />
        <Flex direction="column">
          <Condition condition={showWarnings}>
            <Box>
              {t('tables.rent.stockholder.warnings.maxFinancingAmount', {
                maxFinancingAmount,
              })}
            </Box>
            <Box>
              {t('tables.rent.stockholder.warnings.additionalEquityRequired', {
                additionalEquityRequired,
              })}
            </Box>
          </Condition>
        </Flex>
      </Card>
    </>
  );
};
