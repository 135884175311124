import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import paths from 'constants/paths';
import { isLoggedInSelector } from 'store/user/selectors';

const PrivateRoute = ({
  isLoggedIn,
  component: Component,
  render,
  path,
  isAuthorized,
  authPath,
  location,
  ...routeProps
}) => {
  const renderComponent = (props) => (render ? render(props) : <Component {...props} />);
  return (
    <Route
      {...routeProps}
      path={path}
      location={location}
      render={(props) => {
        if (isLoggedIn && isAuthorized) {
          return renderComponent(props);
        }

        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: authPath,
                search: location.search,
                state: {
                  redirectTo: location.pathname,
                },
              }}
              push
            />
          );
        }

        return <Redirect to={paths.error403} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.func,
  render: PropTypes.func,
  path: PropTypes.string.isRequired,
  authPath: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
  render: null,
};

const mapStateToProps = (store) => ({
  isLoggedIn: isLoggedInSelector(store),
});

export default connect(mapStateToProps)(PrivateRoute);
