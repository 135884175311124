import { useDispatch } from 'react-redux';

import { downloadFileAction } from 'store/documentExchange/documentExchange.actions';

export const usePreviewFile = ({ fileId, fileName }) => {
  const dispatch = useDispatch();
  return async () => {
    const response = await dispatch(downloadFileAction(fileId));
    const blob = new Blob([response.payload.data], {
      type: response.payload.headers['content-type'],
    });
    const blobURL = URL.createObjectURL(blob);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      window.open(blobURL);
    }
  };
};
