import styled from 'styled-components/macro';

import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';

export const StyledTitle = styled.h5`
  margin-bottom: 0.5rem;
  padding-top: 0.25em;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledDescription = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};

  ul {
    padding-left: 1.25rem;
  }
`;

export const StyledUploadBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`;
