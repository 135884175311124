import React from 'react';

import { Box } from '@chakra-ui/react';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { textStyle } from '../../../../theme/themeConstants';

export const RealStateNoDataComponent = () => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');

  return (
    <Box ml={'15'} maxWidth={'60%'}>
      <TextComponent
        as="span"
        display="block"
        {...textStyle.sizeXlTight}
        fontWeight={FONT_WEIGHT_MEDIUM}
        color="brand.default"
        mb={'6'}
      >
        {t('errorHeading')}
      </TextComponent>

      <TextComponent as="span" display="block" color="text.tertiary">
        {t('errorSubHeading')}
      </TextComponent>
    </Box>
  );
};
