import * as React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import paths from 'constants/paths';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_GENDER,
} from 'modules/Inquiry/Form/formFields';
import { SourceType } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPersonMergeSections';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { useToasts } from 'shared/hooks/useToasts';
import { SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE } from 'store/inquiryDetails/actions';
import { updateLegalRepresentatives } from 'store/inquiryDetails/actions';
import {
  getLegalRepresentatives,
  getInquiryIdSelector,
  getCompanyId,
  getCustomerLegalRepresentativeId,
} from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getModifiedRepresentativesTable, getRepresentativesTable } from './helpers';
import { AddFormValues, EditFormValues, LegalRepresentative } from './types';

export const useLegalRepresentativesTable = () => {
  const history = useHistory();
  const { error: fetchError } = useToasts();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { makeCall, isPending } = useDispatchApiCall({ showErrorNotification: false });

  const edit = useDisclosure();
  const add = useDisclosure();
  const save = useDisclosure();

  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');

  const legalRepresentatives = useSelector(getLegalRepresentatives);
  const customerLegalRepresentativeId = useSelector(getCustomerLegalRepresentativeId);
  const companyId = useSelector(getCompanyId);
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  const { data } = useFetchAssociatedPersons(companyId, 'legal_representatives');

  // hanlders

  const handleDeleteRepresentative = (representativeId: string) => {
    setRepresentatives((previousState) =>
      previousState?.filter(({ id }) => id !== representativeId),
    );
  };

  const handleEditFormOpen = () => {
    edit.onOpen();
  };

  const handleEditFormSubmit = (values: EditFormValues) => {
    setRepresentatives((previousState) =>
      previousState.map((representative) =>
        representative.id === values.id
          ? {
              email: values[REPRESENTATIVE_EMAIL] || '',
              firstName: values[REPRESENTATIVE_FIRST_NAME],
              lastName: values[REPRESENTATIVE_LAST_NAME],
              phoneNumber: values[REPRESENTATIVE_PHONE_NUMBER],
              salutation: values[REPRESENTATIVE_GENDER],
              birthDate: representative.birthDate,
              id: representative.id,
              isComplete: true,
              isModified: true,
              isCustomer: false,
            }
          : representative,
      ),
    );
    edit.onClose();
  };

  const handleEditFormCancel = () => {
    edit.onClose();
  };

  const handleAddFormSubmit = (
    formValues: AddFormValues,
    fetchedValues?: Partial<IAssociatedPerson>,
  ) => {
    setRepresentatives((previousState) => [
      ...previousState,
      {
        email: formValues[REPRESENTATIVE_EMAIL] || '',
        firstName: fetchedValues?.firstName || '',
        lastName: fetchedValues?.lastName || '',
        phoneNumber: formValues[REPRESENTATIVE_PHONE_NUMBER],
        salutation: formValues[REPRESENTATIVE_GENDER] || '',
        birthDate: fetchedValues?.birthDate || '',
        id: fetchedValues?.id || '',
        isModified: true,
        isComplete: true,
        isCustomer: false,
      },
    ]);
    add.onClose();
  };

  const handleAddFormCancel = () => {
    add.onClose();
  };

  const handleLegalRepresentativesSave = async () => {
    const data = representatives?.map(
      ({ id, firstName, lastName, phoneNumber, salutation, birthDate, email }) => ({
        id,
        firstName,
        lastName,
        phoneNumber,
        salutation,
        birthDate,
        email,
      }),
    );
    const { error } = await makeCall(updateLegalRepresentatives(inquiryId, data, true));

    if (error) {
      fetchError({ description: t('submitModal.errorMessage') });
    } else {
      dispatch({ type: SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE });
      history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id));
    }
  };

  const handleLegalRepresentativesCancel = () =>
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id));

  // states

  const [representatives, setRepresentatives] = React.useState<LegalRepresentative[]>(
    () =>
      legalRepresentatives?.map((legalRepresentative) => {
        return {
          ...legalRepresentative,
          isComplete:
            legalRepresentative.firstName &&
            legalRepresentative.lastName &&
            legalRepresentative.salutation &&
            legalRepresentative.email &&
            legalRepresentative.phoneNumber
              ? true
              : false,
          isModified: false,
          isCustomer: customerLegalRepresentativeId === legalRepresentative.id,
        };
      }) || [],
  );

  const [hasErrors, setHasError] = React.useState(() =>
    representatives.some(({ isComplete }) => isComplete === false),
  );

  const [activeRepresentative, setActiveRepresentative] =
    React.useState<EditFormValues | undefined>();
  const [representativesTable, setRepresentativesTable] = React.useState(() =>
    getRepresentativesTable(
      representatives,
      handleEditFormOpen,
      setActiveRepresentative,
      handleDeleteRepresentative,
    ),
  );
  const [modifiedRepresentatives, setModifiedRepresentatives] = React.useState<
    LegalRepresentative[]
  >([]);

  const modifiedRepresentativesTable = getModifiedRepresentativesTable(modifiedRepresentatives);

  React.useEffect(() => {
    setRepresentativesTable(() =>
      getRepresentativesTable(
        representatives,
        handleEditFormOpen,
        setActiveRepresentative,
        handleDeleteRepresentative,
      ),
    );
    setModifiedRepresentatives(() =>
      representatives.filter((representative) => representative.isModified),
    );
    setHasError(() => representatives.some(({ isComplete }) => isComplete === false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representatives]);

  const associatedPersons = data
    .filter(({ type, source }) => type === 'legal_representatives' && source === SourceType.KYC)
    .filter(({ id }) => !representatives.map(({ id }) => id).includes(id));

  return {
    modals: {
      edit,
      add,
      save,
    },
    setRepresentatives,
    activeRepresentative,
    representativesTable,
    associatedPersons,
    modifiedRepresentativesTable,
    hasErrors,
    isPending,
    handleEditFormSubmit,
    handleEditFormCancel,
    handleAddFormSubmit,
    handleAddFormCancel,
    handleLegalRepresentativesSave,
    handleLegalRepresentativesCancel,
  };
};
