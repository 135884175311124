import _get from 'lodash/get';

import {
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_EXTRA_MARKDOWN,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapLendingValuesToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const lendingValuesData = {
    profitability_safety_markdown: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_SAFETY_MARKDOWN)),
    ),
    profitability_extra_markdown: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_EXTRA_MARKDOWN)),
    ),
  };

  return lendingValuesData;
};

export default mapLendingValuesToApi;
