import { API_METHODS, callAuthApi } from 'api/apiService';
import endpoints from 'api/AuthApi/endpoints';
import { ConfigController } from 'config/ConfigController';
import { LOG_IN, LOG_OUT, TOKEN_EXPIRED } from 'shared/auth/loginLogout.actionTypes';
import { IState } from 'store';

import { AuthScope } from './types';

export const logOutAction = () =>
  callAuthApi({
    url: endpoints.LOG_OUT.compose(),
    method: API_METHODS.POST,
    actionName: LOG_OUT,
    data: (state: IState) => ({
      token: state.user.token,
    }),
  });

export const logInAction = (email: string, password: string, scope: AuthScope) => {
  const {
    details: { id: patrnetId },
  } = ConfigController.partner.getConfig();
  return callAuthApi({
    url: endpoints.LOG_IN.compose(),
    method: API_METHODS.POST,
    actionName: LOG_IN,
    data: {
      username: email,
      password,
      grant_type: 'password',
      scope,
      partner: patrnetId,
    },
  });
};

interface LoginVars {
  email: string;
  password: string;
}

export const logInCustomer = ({ email, password }: LoginVars) => {
  return logInAction(email, password, 'customer');
};

export const logInPortalUser = ({ email, password }: LoginVars) => {
  return logInAction(email, password, 'portal_user');
};

export const tokenExpiredAction = (dispatch: any) =>
  dispatch({
    type: TOKEN_EXPIRED,
  });
