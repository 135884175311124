import { PAYMENT_TERM } from 'onlinefactoring/formFields';

import {
  BFS_SERVICE__MONTHLY_TURNOVER,
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from '../Form/formFields';
import { FIELD_UNIT_TYPE } from './inquiryFieldsUnitTypes';

export const bfsServiceUnits: Record<string, FIELD_UNIT_TYPE> = {
  [BFS_SERVICE__MONTHLY_TURNOVER]: FIELD_UNIT_TYPE.CURRENCY,
  [PAYMENT_TERM]: FIELD_UNIT_TYPE.DAYS,
  [OFFER_FACTORING_FEE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [OFFER_FACTORING_LINE]: FIELD_UNIT_TYPE.CURRENCY,
  [OFFER_PAYOUT_RATIO]: FIELD_UNIT_TYPE.PERCENTAGE,
};
