import { useSelector } from 'react-redux';

import PERMISSIONS from 'constants/user/permissions';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { isInquiryManager, rolesSelector } from 'store/user/selectors';
import { doArraysHaveCommonPart } from 'utils/helpers';

const canShowProcessorColumn = (isManager, status, isBankAdvisorEnabled) =>
  isManager &&
  isBankAdvisorEnabled &&
  status !== INQUIRY_STATUSES.NEW &&
  status !== INQUIRY_STATUSES.PENDING;

export const canPerformWelcomeCall = (inquiry, userRoles) => {
  if (!inquiry || !Array.isArray(userRoles)) {
    return false;
  }
  return (
    !inquiry.welcomeCallPerformedDate &&
    doArraysHaveCommonPart(PERMISSIONS.INQUIRY.PERFORM_WELCOME_CALL, userRoles) &&
    inquiry.status === INQUIRY_STATUSES.PENDING
  );
};

const usePortalPermissions = () => {
  const isManager = useSelector(isInquiryManager);
  const isBankAdvisorEnabled = useConfig(CONFIG.IS_BANK_ADVISOR_ENABLED);
  const inquiry = useSelector(getInquiryDetailsSelector);
  const userRoles = useSelector(rolesSelector);

  return {
    canPerformWelcomeCall: canPerformWelcomeCall(inquiry, userRoles),
    canShowProcessorColumn: (status) =>
      canShowProcessorColumn(isManager, status, isBankAdvisorEnabled),
  };
};

export default usePortalPermissions;
