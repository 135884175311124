import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { getCompanyDetails } from '../store/selectors';

const CompanyOverviewAllInfo: React.FC = () => {
  const companyDetails = useSelector(getCompanyDetails);

  const t = useTranslations();
  return (
    <>
      <Subtitle>{t('pages.companiesDetails.overview.additionalInfo')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('pages.companiesDetails.overview.companyStaff')}
          text={companyDetails.companyStaff ?? '–'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.companyTurnover')}
          text={companyDetails.companyTurnover ?? '–'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.debtServiceAbility')}
          text={
            companyDetails.debtServiceAbility ? formatPrice(companyDetails.debtServiceAbility) : '–'
          }
        />
      </StyledOverviewFields>
      <StyledOverviewFields>
        <StaticField
          caption={t('pages.companiesDetails.overview.companyTurnoverClientInput')}
          text={
            companyDetails.detailedAnnualTurnover
              ? formatPrice(companyDetails.detailedAnnualTurnover)
              : '–'
          }
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.companyStaffClientInput')}
          text={companyDetails.countOfEmployees ?? '–'}
        />
        <StaticField
          caption={t('pages.companiesDetails.overview.balanceSheet')}
          text={
            companyDetails.balanceSheet ? formatPrice(Number(companyDetails.balanceSheet)) : '–'
          }
        />
      </StyledOverviewFields>
    </>
  );
};

export default CompanyOverviewAllInfo;
