import React, { useMemo, useContext, FC, useEffect } from 'react';

import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import Unit from 'components/Unit/Unit';
import { useFormConfig } from 'config/formConfig/hooks';
import { getFieldValidator } from 'modules/Inquiry/inquiryFieldValidation/validateField';
import { useFieldCaption } from 'modules/Inquiry/useFieldCaption';
import { useFieldPlaceholder } from 'modules/Inquiry/useFieldPlaceholder';
import { useFieldUnit } from 'modules/Inquiry/useFieldUnit';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { getErrorMessageForField } from 'utils/form/withForm.helpers';

import { PASSWORD_FIELD } from '../../modules/Inquiry/Form/formFields';

const animationProps = {
  'data-aos': 'fade-in-bottom',
  'data-aos-once': true,
};

interface IWithFormField {
  name: string;
  animateEntry?: boolean;
  caption?: string;
  onChange?: Function;
  [key: string]: any;
}

const withFormField =
  <T,>(Component: any): FC<IWithFormField> =>
  ({
    animateEntry = false,
    name,
    caption = undefined,
    placeholder = undefined,
    onChange,
    initial,
    ...rest
  }) => {
    const { parentFieldName } = useContext(ParentFieldContext);

    const fieldController = FormFieldController.instance;

    const animations = animateEntry ? animationProps : {};
    const processLane = useSelector(getProcessLane);
    const { selectedInquiryType } = useFormConfig();

    const validator = useMemo(
      () => rest.validate || getFieldValidator(name, selectedInquiryType, processLane),
      [rest.validate, name, selectedInquiryType, processLane],
    );
    const unitType = useFieldUnit(name);
    const defaultPlaceholder = placeholder || useFieldPlaceholder(name);
    const translatedCaption = caption || useFieldCaption(name);

    const fieldName = parentFieldName ? `${parentFieldName}.${name}` : name;

    const saveFieldControllerValue = (value: any) => {
      // This condition is necessary because otherwise the password from the login would be stored in the session storage.
      // All other password inputs were renamed to 'passwordWithSessionStorage',
      if (name !== PASSWORD_FIELD) {
        fieldController.saveValue(fieldName, value?.target?.value || value, {
          customValidator: rest.validate,
        });
      }
    };

    const handleChange = (inputOnChange: Function, inputOnFocus: Function) => (value: any) => {
      if (rest.inputMaxLimit) value = value.substr(0, rest.inputMaxLimit);
      // save value in storage without spaces.
      saveFieldControllerValue(rest.noWhiteSpaces ? value.replace(/\s/g, '') : value);
      inputOnChange(value); // save to final-form-state

      // Safari and Firefox on Mac doesn't apply focus state on clicked radio buttons/checkboxes
      // https://bugs.webkit.org/show_bug.cgi?id=19104
      inputOnFocus();
      if (onChange) {
        onChange(value);
      }
    };

    const handleInitialValue = (pristine: boolean | undefined) => {
      if (pristine && initial !== undefined) {
        saveFieldControllerValue(initial);
      }
    };

    const canShow = useCanShowField(name);
    if (!canShow) {
      return null;
    }

    return (
      <Field
        name={fieldName}
        validate={validator}
        render={({ input, meta }) => {
          useEffect(() => {
            // Since we are using FieldController, we need to set initial value in FieldController
            // without this FieldsController will only get value onChange
            if (!input.value) {
              handleInitialValue(meta.pristine);
            }
          }, [meta.pristine, input.value]);

          return (
            <Component
              input={input}
              onBlur={(e: any) => {
                // on blur remove white spaces
                if (rest.noWhiteSpaces) {
                  input.onChange(e.target?.value.replace(/\s/g, ''));
                  if (onChange) {
                    onChange(e.target?.value.replace(/\s/g, ''));
                  }
                }
                input.onBlur(e);
              }}
              meta={meta}
              placeholder={defaultPlaceholder}
              errorMessage={getErrorMessageForField(meta)}
              caption={rest.noCaption ? '' : translatedCaption}
              sideSymbol={unitType ? () => <Unit type={unitType} /> : undefined}
              onChange={handleChange(input.onChange, input.onFocus)}
              fieldName={fieldName}
              initial={initial}
              {...animations}
              {...rest}
            />
          ) as unknown as T;
        }}
      />
    );
  };

export default withFormField;
