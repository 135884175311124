import React from 'react';

import { number } from 'prop-types';
import { useIntl } from 'react-intl';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import InquiryTypeCondition from 'components/InquiryTypeCondition';
import { fieldIsValid, validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_DATA_SECURITY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import TermAndConditionsField from 'pages/inquiryFlow/CompanyDetails/sections/TermAndConditionsField';
import { FormSections } from 'schema/inquirySchema.models';

import { BfsTermsAndConditions } from './BfsTermsAndCondions';
import { BigPictureTermsAndConditions } from './BigPictureTermsAndConditions';

export const validateConsentSection = (inquiryType: InquiryType | undefined) => (form: any) => {
  switch (inquiryType) {
    case InquiryType.bigPicture:
    case InquiryType.bfsService:
      return validateSectionFields([COMPANY_DETAILS_CONSENT, COMPANY_DETAILS_DATA_SECURITY])(form);
    default:
      return fieldIsValid(COMPANY_DETAILS_CONSENT)({ form });
  }
};

const TermsAndConditions = ({ sectionNumber }: any) => {
  const { formatMessage } = useIntl();

  return (
    <FormSection
      name={FormSections.termsOfUsage}
      sectionNumber={sectionNumber}
      title={formatMessage({ id: 'pages.companyDetails.sections.termsAndConditions.title' })}
    >
      <FormRow>
        <TermAndConditionsField sectionNumber={0} />
      </FormRow>
    </FormSection>
  );
};

TermsAndConditions.propTypes = {
  sectionNumber: number.isRequired,
};

const DisplayConditions = ({ sectionNumber }: any) => {
  return (
    <InquiryTypeCondition
      cases={{
        big_picture: <BigPictureTermsAndConditions sectionNumber={sectionNumber} />,
        bfs: <BfsTermsAndConditions sectionNumber={sectionNumber} />,
        default: <TermsAndConditions sectionNumber={sectionNumber} />,
      }}
    />
  );
};

export default DisplayConditions;
