import {
  PROFITABILITY_EXTRA_MARKDOWN,
  PROFITABILITY_NUMBER_OF_GARAGES,
  PROFITABILITY_NUMBER_OF_PARKING_LOTS,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_BUFFER,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_USUAL_SALES_COST_RATE,
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
  QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
  QUICK_CHECK_INITIATOR,
  QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
  QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
  QUICK_CHECK_PROJECT_DESCRIPTION,
  QUICK_CHECK_INFO_ON_COLLATERAL,
  QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
  QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
  QUICK_CHECK_JUSTIFICATION_TEMPLATE,
  QUICK_CHECK_TYPE_AND_GUARANTOR,
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_NEW_COMMITMENT,
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_NEW_COLLATERAL,
  QUICK_CHECK_SELECTING_PROFITABILITY_TYPE,
} from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

const Constants = {
  PRECISION: 2,
  MIN_PERCENTAGE: 0,
  MAX_PERCENTAGE: 100,
  MIN_PRICE: 0,
  MAX_PRICE: 999999999.99,
  MIN_PARKING_LOTS: 0,
  MAX_PARKING_LOTS: 999,
  MIN_FINANCING_DURATION: 1,
  MAX_FINANCING_DURATION: 200,
};

export const profiProjectCostingEditMap = {
  [PROFITABILITY_EXTRA_MARKDOWN]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_SAFETY_MARKDOWN]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  [PROFITABILITY_PURCHASE_PRICE]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_LEGAL_FEES]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_AGENT_FEES]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_MEASUREMENT_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OTHER_FEES]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OTHER_PURCHASE_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OTHER_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_COMPENSATIONS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_LAND_DEVELOPMENT_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_PUBLIC_DISPOSAL]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_NON_PUBLIC_DISPOSAL]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OTHER_DEVELOPMENT_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_CONSTRUCTION_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OUTDOOR_FACILITIES_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_OTHER_CONSTRUCTION_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_ARCHITECT_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_ADDITIONAL_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_THIRD_PARTY_FINANCING_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_FINANCING_COSTS_FEES_MANUAL]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_BUFFER]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  [PROFITABILITY_MARKETING_SALES_COSTS]: fieldValidators
    .number()
    .precision(InquiryConstants.PROJECT_COSTING_VALUE_CURRENCY_DECIMALS)
    .min(InquiryConstants.MIN_PROJECT_COSTING_CURRENCY_VALUE)
    .max(InquiryConstants.MAX_PROJECT_COSTING_CURRENCY_VALUE),
  // fields validation for presales START
  [PROFITABILITY_PRESALES_AREA_RESIDENTIAL]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  [PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE),
  [PROFITABILITY_PRESALES_AREA_COMMERCIAL]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  [PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE),
  [PROFITABILITY_NUMBER_OF_PARKING_LOTS]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PARKING_LOTS)
    .max(Constants.MAX_PARKING_LOTS),
  [PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE),
  [PROFITABILITY_NUMBER_OF_GARAGES]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PARKING_LOTS)
    .max(Constants.MAX_PARKING_LOTS),
  [PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE),
  // fields validation for presales END
  // fields validation for financing parameters START
  [PROFITABILITY_FINANCING_FEE_BUILDING]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_FINANCING_DURATION_BUILDING]: fieldValidators
    .number()
    .integer()
    .min(Constants.MIN_FINANCING_DURATION)
    .max(Constants.MAX_FINANCING_DURATION),
  [PROFITABILITY_INTEREST_RATE_BUILDING]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_FINANCING_FEE_UNIT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_FINANCING_DURATION_UNIT]: fieldValidators
    .number()
    .integer()
    .min(Constants.MIN_FINANCING_DURATION)
    .max(Constants.MAX_FINANCING_DURATION),
  [PROFITABILITY_INTEREST_RATE_UNIT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_FINANCING_DURATION_RENT]: fieldValidators
    .number()
    .integer()
    .min(Constants.MIN_FINANCING_DURATION)
    .max(Constants.MAX_FINANCING_DURATION),
  [PROFITABILITY_INTEREST_RATE_RENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_INTEREST_RATE_RENT_SHORT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  [PROFITABILITY_REPAYMENT_RATE_RENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE)
    .allow(0),
  // fields validation for financing parameters END
  [PROFITABILITY_USUAL_SALES_COST_RATE]: fieldValidators
    .number()
    .max(InquiryConstants.MAX_PERCENT_VALUE),

  // fields validation for profitability rental parameters START
  [PROFITABILITY_OPERATING_COSTS_RESIDENTIAL]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  [PROFITABILITY_OPERATING_COSTS_COMMERCIAL]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  [PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE),
  [PROFITABILITY_TAX_RATE_IN_PERCENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PERCENTAGE)
    .max(Constants.MAX_PERCENTAGE),
  // fields validation for profitability rental parameters END

  // fields for quick check start
  [QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS]: fieldValidators
    .string()
    .max(700)
    .allow(null)
    .optional(),
  [QUICK_CHECK_INITIATOR]: fieldValidators.string().max(700).allow(null).optional(),
  [QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE]: fieldValidators
    .string()
    .max(700)
    .allow(null)
    .optional(),
  [QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER]: fieldValidators
    .string()
    .max(700)
    .allow(null)
    .optional(),
  [QUICK_CHECK_PROJECT_DESCRIPTION]: fieldValidators.string().max(700).allow(null).optional(),
  [QUICK_CHECK_INFO_ON_COLLATERAL]: fieldValidators.string().max(700).allow(null).optional(),
  [QUICK_CHECK_WITHDRAWAL_REQUIREMENTS]: fieldValidators.string().max(700).allow(null).optional(),
  [QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION]: fieldValidators
    .string()
    .max(700)
    .allow(null)
    .optional(),
  [QUICK_CHECK_JUSTIFICATION_TEMPLATE]: fieldValidators.string().max(700).allow(null).optional(),
  // load value start
  [QUICK_CHECK_TYPE_AND_GUARANTOR]: fieldValidators.string().max(50).allow(null).optional(),
  [QUICK_CHECK_AMOUNT_IN_EURO]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE)
    .allow(null)
    .optional(),
  // load value end
  // unsecured portion start
  [QUICK_CHECK_CURRENT_TOTAL_COMMITMENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE)
    .required(),
  [QUICK_CHECK_NEW_COMMITMENT]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE)
    .required(),
  [QUICK_CHECK_CURRENT_COLLATERAL_VALUE]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE)
    .required(),
  [QUICK_CHECK_NEW_COLLATERAL]: fieldValidators
    .number()
    .precision(Constants.PRECISION)
    .min(Constants.MIN_PRICE)
    .max(Constants.MAX_PRICE)
    .required(),
  // unsecured portion end
  // project calculation section start
  [QUICK_CHECK_SELECTING_PROFITABILITY_TYPE]: fieldValidators.string().required(),
  // project calculation section end

  // fields for quick check end
};
