import React from 'react';

import PropTypes from 'prop-types';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from 'react-virtualized';

import Element from './Element';

const VirtualList = ({
  getItemProps,
  optionMinHeight,
  getDropdownHeight,
  options,
  highlightedIndex,
  onScrollEnd,
}) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 62,
    minHeight: optionMinHeight,
  });

  const notifyOnScrollEnd = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (clientHeight + scrollTop === scrollHeight && scrollHeight > 0 && onScrollEnd) {
      onScrollEnd();
    }
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          height={getDropdownHeight()}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowCount={options.length}
          onScroll={notifyOnScrollEnd}
          rowRenderer={({ index, key, style, parent }) => (
            <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
              <div style={style}>
                <Element
                  itemProps={getItemProps({ index, item: options[index] })}
                  option={options[index]}
                  key={key}
                  highlighted={index === highlightedIndex}
                />
              </div>
            </CellMeasurer>
          )}
        />
      )}
    </AutoSizer>
  );
};

VirtualList.defaultProps = {
  onScrollEnd: () => {},
};

VirtualList.propTypes = {
  getItemProps: PropTypes.func.isRequired,
  optionMinHeight: PropTypes.number.isRequired,
  getDropdownHeight: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onScrollEnd: PropTypes.func,
};

export default VirtualList;
