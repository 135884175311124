import React from 'react';

import { Field } from 'react-final-form';

import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';

import CompanyDetailsExistingUserCompany from './CompanyDetailsExistingUserCompany';
import { useLoadCompanies } from './useLoadCompanies';

const DvagExistingUserCompanySelect = () => {
  const { isFetching, companies, isAgent } = useLoadCompanies();
  if (!isFetching && !companies.length) {
    return (
      <Field
        name={COMPANY_DETAILS_EXISTING_USER_COMPANY}
        initialValue={COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY}
        render={() => null}
      />
    );
  }

  return (
    isAgent && <CompanyDetailsExistingUserCompany companies={companies} isFetching={isFetching} />
  );
};

export default DvagExistingUserCompanySelect;
