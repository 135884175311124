import React from 'react';

import { MonthPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import Unit from 'components/Unit/Unit';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_ZIP_CODE,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import RealEstateHeritableBuildingRightObligation from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateHeritableBuildingRightObligation';
import RealEstateRenterOutArea from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateRenterOutArea';
import RealEstateTaxation from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateTaxation';
import RealEstateUsageKind from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateUsageKind';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateDetailsOfBuildingSection = validateSectionFields([
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_ZIP_CODE,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_TAXATION,
]);

const DetailsOfBuilding = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstateBuildingDetails}
      sectionNumber={5}
      title={t('pages.financingNeed.sections.detailsOfBuilding.title')}
    >
      <FormRow>
        <MonthPickerWithField
          name={REAL_ESTATE_CONSTRUCTION_DATE}
          placeholder={t('placeholders.pleaseChoose')}
          optional
        />
      </FormRow>

      <FormRow>
        <InputWithField
          name={REAL_ESTATE_LAND_SIZE}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
          separateThousands
          optional
          type="number"
        />

        <InputWithField
          name={REAL_ESTATE_BUILDING_SIZE}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
          optional
          separateThousands
          type="number"
        />
      </FormRow>

      <FormRow separator>
        <YesNoRadioGroupWithField name={REAL_ESTATE_HERITABLE_BUILDING_RIGHT} optional />
        <RealEstateHeritableBuildingRightObligation />
      </FormRow>

      <FormRow>
        <RealEstateUsageKind />
        <RealEstateRenterOutArea />
      </FormRow>

      <FormRow>
        <AmountWithField name={REAL_ESTATE_COLD_RENT} optional />
        <RealEstateTaxation />
      </FormRow>

      <Separator />

      <FormRow separator>
        <InputWithField
          name={REAL_ESTATE_ZIP_CODE}
          type="tel"
          pattern="[0-9]*"
          optional
          inputMaxLimit={MAX_ZIP_CODE_LENGTH}
          separateThousands={false}
        />
      </FormRow>

      <FormRow>
        <TextAreaWithField name={REAL_ESTATE_PROJECT_DESCRIPTION} />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: null,
  default: DetailsOfBuilding,
});
