import React from 'react';

import { Page } from 'new/form/Page';

import { translations } from '../../../../new/form/common/types';
import { OtherFundingSection } from './sections/otherFundingSection';

export const MittweidaFundingDetailsStep = () => {
  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mittweida.pages.fundingDetails.progressBarTitle
      }
    >
      <OtherFundingSection />
    </Page>
  );
};
