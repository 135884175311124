import React, { ReactNode, useMemo, useEffect, useState } from 'react';

import _merge from 'lodash/merge';
import { IntlProvider } from 'react-intl';

import { usePartnerConfig } from 'config/partner/hooks';
import langMessages from 'shared/locale';
import '@formatjs/intl-pluralrules/dist/locale-data/de';

import useLanguagePreferences from './useLanguagePreferences';

const canBeAnInteger = (key: string | number) =>
  typeof key === 'number' || !Number.isNaN(parseInt(key, 10));

const getPrefixedKey = (prefix: string, key: string | number) =>
  canBeAnInteger(key) ? `${prefix}[${key}]` : `${prefix}.${key}`;

/*
 * Transforms nested json into flat key-value object
 * from:
 * pages: {
 *   page1: {
 *    title: 'foo',
 *    desc: 'bar',
 * }
 *
 * to:
 * pages.page1.title: 'foo',
 * pages.page1.desc: 'bar',
 */
const flattenMessagesObject = (nestedMessages: any, prefix = '') =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? getPrefixedKey(prefix, key) : key;
    const messagesRef: any = messages;

    if (typeof value !== 'object') {
      messagesRef[prefixedKey] = value;
    } else {
      Object.assign(messagesRef, flattenMessagesObject(value, prefixedKey));
    }

    return messagesRef;
  }, {});

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const {
    meta: { translation = {} },
  } = usePartnerConfig();
  const { selectedLanguage, saveLanguagePreference } = useLanguagePreferences();
  const [firstRender, setFirstRender] = useState(true);
  const browserLanguage = navigator.language.split(/[-_]/)[0];

  useEffect(() => {
    if (!selectedLanguage || firstRender) {
      saveLanguagePreference(browserLanguage);
      setFirstRender(false);
    }
    // eslint-disable-next-line
  }, [firstRender]);

  const customizedTranslations = useMemo(() => {
    return flattenMessagesObject(
      _merge(
        {},
        langMessages[selectedLanguage],
        Object.prototype.hasOwnProperty.call(translation, selectedLanguage)
          ? translation[selectedLanguage]
          : {},
      ),
    );
  }, [translation, selectedLanguage]);

  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={customizedTranslations}
      onError={() => {}} // avoid error messages on missing id cause we have fallbacks sometimes
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
