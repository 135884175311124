import * as React from 'react';

import { NavLink } from 'react-router-dom';

import { useConfig } from 'shared/featureFlagConfig/useConfig';

import { FeatureFlags } from '../../../../shared/featureFlagConfig/configFromAdmin';

type Props = {
  featureFlag: FeatureFlags;
  to: string;
  children: React.ReactNode;
};

export const FeatureFlagNavLink = ({ featureFlag, to, children }: Props) => {
  const showLink = useConfig(featureFlag);

  if (showLink) {
    return (
      <li>
        <NavLink activeClassName="active" to={to}>
          {children}
        </NavLink>
      </li>
    );
  }

  return null;
};
