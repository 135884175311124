import _get from 'lodash/get';

import {
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapRentalParametersToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const rentalParametersData = {
    residential_operating_costs: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_OPERATING_COSTS_RESIDENTIAL)),
    ),
    commercial_operating_costs: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_OPERATING_COSTS_COMMERCIAL)),
    ),
    depreciation: toFloatPrecision2(
      unknownToString(_get(data, PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO)),
    ),
    tax_rate: toFloatPrecision2(unknownToString(_get(data, PROFITABILITY_TAX_RATE_IN_PERCENT))),
  };

  return rentalParametersData;
};

export default mapRentalParametersToApi;
