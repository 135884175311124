import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';

import mapFinancingParametersFromApi from './mapProfitabilitySectionsFromApi/mapFinancingParametersFromApi';
import mapInvestmentCostsFromApi from './mapProfitabilitySectionsFromApi/mapInvestmentCostsFromApi';
import mapLendingValuesFromApi from './mapProfitabilitySectionsFromApi/mapLendingValuesFromApi';
import mapPresalesValuesFromApi from './mapProfitabilitySectionsFromApi/mapPrefillValuesFromApi';
import mapRentalParametersFromApi from './mapProfitabilitySectionsFromApi/mapRentalParametersFromApi';
import mapSalesCostRateFromApi from './mapProfitabilitySectionsFromApi/mapSalesCostRateFromApi';

export type MapProfitabilityFromApiType<T> = (
  response: unknown,
) => { [key in PROFITABILITY_RECORD_TYPE]?: T };

const mapProfitabilityFromApi: MapProfitabilityFromApiType<unknown> = (response: unknown) => {
  return {
    [PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS]: mapFinancingParametersFromApi(response),
    [PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS]: mapInvestmentCostsFromApi(response),
    [PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION]: mapLendingValuesFromApi(response),
    [PROFITABILITY_RECORD_TYPE.SALES_COST_RATE]: mapSalesCostRateFromApi(response),
    [PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT]: mapSalesCostRateFromApi(response),
    [PROFITABILITY_RECORD_TYPE.PRESALES_DATA]: mapPresalesValuesFromApi(response),
    [PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS]: mapRentalParametersFromApi(response),
  };
};

export default mapProfitabilityFromApi;
