export enum ProgressSectionType {
  // customer portal
  INDICATIVE_OFFER = 'indicativeOffer',
  VIDEO_IDENT = 'videoIdent',
  ADDITIONAL_FIELDS = 'additionalFields',
  DOCUMENT_UPLOAD = 'documentUpload',
  FACTORING_OFFER = 'factoringOffer',

  // operation portal
  NON_BINDING_OFFER = 'nonBindingOffer',
  CREFO_QUERY = 'crefoQuery',
  DIGITAL_PROCESSING = 'digitalProcessing',
  AUTO_MERGE = 'autoMerge',
  TRIGGER_KYC = 'triggerKyc',
  UPLOAD_OFFER = 'uploadOffer',
  LEGAL_REPRESENTATION_CORRECTION = 'legalRepresentationCorrection',
  INCONSISTENCY_REPORT = 'inconsistencyReport',
  TRANSPARENCY_COMPANY = 'transparencyCompany',
  INVITE_CUSTOMER = 'inviteCustomer',
  TRIGGER_MARKET_RESEARCH = 'triggerMarketResearch',
  RESOLVE_KYC_DATABANK = 'resolveKycDataBank',
  ASSIGN_INQUIRY_TO_REAL_ESTATE_EXPERT = 'assignInquiryToRealEstateExpert',
  TRIGGER_PROFITABILITY_CALCULATION = 'triggerProfitabilityCalculation',
  DIGITIZATION = 'digitization',
  TRIGGER_QUICK_CHECK = 'triggerQuickCheck',

  SUCCESS = 'success',
}
