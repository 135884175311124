export enum ProcessLogActivity {
  open = 'open',
  welcomeCallChange = 'welcomeCallChange',
  forwardToInquiryManager = 'forwardToInquiryManager',
  forwardToCompeon = 'forwardToCompeon',
  forwardToCustomer = 'forwardToCustomer',
  forwardToBankAdvisor = 'forwardToBankAdvisor',
  createBankOffer = 'createBankOffer',
  acceptOffer = 'acceptOffer',
  archive = 'archive',
  userArchived = 'userArchived',
  enabledDigitalProcessing = 'enabledDigitalProcessing',
  kycStarted = 'kycStarted',
  enteredIndicativeConditions = 'enteredIndicativeConditions',
  enabledManualCrefoReport = 'enabledManualCrefoReport',
  uploadedAllDocuments = 'uploadedAllDocuments',
  completedIdnowProcess = 'completedIdnowProcess',
  forwardToRealEstateExpert = 'forwardToRealEstateExpert',
  digitizationRequested = 'digitizationRequested',
  markedForKPICalculation = 'markedForKPICalculation',
}

export enum ProcessLogType {
  user = 'UserLoggedActivity',
  system = 'SystemLoggedActivity',
}

export interface ProcessLog {
  id: string;
  type: ProcessLogType;
  user?: {
    firstName: string;
    lastName: string;
  };
  activity: ProcessLogActivity;
  createdAt: string;
}
