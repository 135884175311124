import React from 'react';

import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

type PropType = {
  stepNumber: number;
  editFormStructure: Array<EditMarketValue>;
  setDisplayPage: React.Dispatch<React.SetStateAction<EditMarketValue>>;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  onClick: () => void;
};

type PrimaryButtonMapType = {
  [T in 'firstAndMiddleSteps' | 'finalStep']: {
    icon: JSX.Element;
    label: string;
    action: () => void;
  };
};

const PrimaryButton: React.FC<PropType> = ({
  stepNumber,
  editFormStructure,
  setDisplayPage,
  setStepNumber,
  onClick,
}) => {
  let step: keyof PrimaryButtonMapType;
  const t = useTranslations();

  const displayNextPage = () => {
    if (stepNumber < editFormStructure.length) {
      let stepCount = stepNumber + 1;
      setDisplayPage(editFormStructure[stepCount]);
      setStepNumber(stepCount);
    }
  };

  const primaryButtonMap: PrimaryButtonMapType = {
    firstAndMiddleSteps: {
      icon: <ArrowRightIcon boxSize={6} d="block" />,
      label: t('buttons.continue'),
      action: () => displayNextPage(),
    },
    finalStep: {
      icon: <SaveIcon boxSize={6} d="block" />,
      label: t('buttons.saveChanges'),
      action: () => onClick(),
    },
  };

  if (stepNumber >= 0 && stepNumber < editFormStructure.length - 1) step = 'firstAndMiddleSteps';
  else if (stepNumber === editFormStructure.length - 1) step = 'finalStep';
  else return null;
  return (
    <ButtonComponent
      leftIcon={primaryButtonMap[step].icon}
      onClick={() => primaryButtonMap[step].action()}
    >
      {t(primaryButtonMap[step].label)}
    </ButtonComponent>
  );
};

export default PrimaryButton;
