import React from 'react';

import { useSelector } from 'react-redux';

import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedSignedContractBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedSignedContractBlock/UploadedSignedContractBlock';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { StyledFilesSection as Section } from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/styles';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getInquiryDetailsSelector,
  getInquiryIdSelector,
  getLegalRepresentatives,
  getVideoIdentificationsDetails,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionTitle from '../FileSectionTitle';

const LatestSignedContractFromBank: React.FC = () => {
  const videIdentificationsDetail = useSelector(getVideoIdentificationsDetails);
  const inquiryId = useSelector(getInquiryIdSelector);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const legalRepresentatives = useSelector(getLegalRepresentatives);
  const total = legalRepresentatives ? legalRepresentatives.length : 0;

  const latestSignedContractDetails = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.leaseplan]: {
      signedContractUrl: inquiryDetails.finalSignedContract,
    },
    default: videIdentificationsDetail
      ? videIdentificationsDetail.length > 1
        ? videIdentificationsDetail.filter(
            (option) =>
              option.success && option.signedContractUrl.includes(`${inquiryId}-${total - 1}`),
          )[0]
        : videIdentificationsDetail.filter((option) => option.success)[0]
      : null,
  });

  const t = useTranslations('pages.inquiryDetails.documentExchange');
  const tUpload = useTranslations('components.upload');

  if (!latestSignedContractDetails) {
    return null;
  }

  return (
    <section>
      <FileSectionTitle>{t('signedContractFromBank')}</FileSectionTitle>
      <Section>
        <li>
          <UploadedSignedContractBlock
            key={latestSignedContractDetails.id}
            signedContractUrl={latestSignedContractDetails.signedContractUrl}
            canRemove={false}
            label={<StatusLabel>{tUpload('newFile')}</StatusLabel>}
          />
        </li>
      </Section>
    </section>
  );
};

export default LatestSignedContractFromBank;
