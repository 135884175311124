import React from 'react';

import SectionHeading from 'components/Text/SectionHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyNoteSection = () => {
  const t = useTranslations(
    'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany',
  );
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  return (
    <SectionHeading as="small">{t('notice', { partnerName, roleSpecific: true })}</SectionHeading>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: null,
  [InquiryType.bfsService]: null,
  default: CompanyNoteSection,
});
