import _get from 'lodash/get';

import {
  CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
  CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
  CURRENT_USAGE_PRICE_GARAGE_PARKING,
  CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
  CURRENT_USAGE_SQM_PRICE_PROPERTY,
  CURRENT_USAGE_FAIR_MARKET_VALUE,
  CURRENT_USAGE_LOAN_VALUE,
  CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
  CURRENT_USAGE_SQM_RENT_COMMERCIAL,
  CURRENT_USAGE_RENT_GARAGE_PARKING,
  CURRENT_USAGE_RENT_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
  FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
  FUTURE_USAGE_PRICE_GARAGE_PARKING,
  FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_PROPERTY,
  FUTURE_USAGE_FAIR_MARKET_VALUE,
  FUTURE_USAGE_LOAN_VALUE,
  FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
  FUTURE_USAGE_SQM_RENT_COMMERCIAL,
  FUTURE_USAGE_RENT_GARAGE_PARKING,
  FUTURE_USAGE_RENT_OUTSIDE_PARKING,
} from 'modules/Inquiry/Form/formFields';

const mapBankValuesObjectDataFromApi = (response: unknown) => {
  const current = _get(response, 'data.attributes.details.bank_values_object_data.current');
  const future = _get(response, 'data.attributes.details.bank_values_object_data.future');

  const bankValue = {
    [CURRENT_USAGE_SQM_PRICE_RESIDENTIAL]: _get(current, 'sqm_price_residential') || '0,00',
    [CURRENT_USAGE_SQM_PRICE_COMMERCIAL]: _get(current, 'sqm_price_commercial') || '0,00',
    [CURRENT_USAGE_PRICE_GARAGE_PARKING]: _get(current, 'price_garage_parking') || '0,00',
    [CURRENT_USAGE_PRICE_OUTSIDE_PARKING]: _get(current, 'price_outside_parking') || '0,00',
    [CURRENT_USAGE_SQM_PRICE_PROPERTY]: _get(current, 'sqm_price_property') || '0,00',
    [CURRENT_USAGE_FAIR_MARKET_VALUE]: _get(current, 'fair_market_value') || '0,00',
    [CURRENT_USAGE_LOAN_VALUE]: _get(current, 'loan_value') || 0,
    [CURRENT_USAGE_SQM_RENT_RESIDENTIAL]: _get(current, 'sqm_rent_residential') || '0,00',
    [CURRENT_USAGE_SQM_RENT_COMMERCIAL]: _get(current, 'sqm_rent_commercial') || '0,00',
    [CURRENT_USAGE_RENT_GARAGE_PARKING]: _get(current, 'rent_garage_parking') || '0,00',
    [CURRENT_USAGE_RENT_OUTSIDE_PARKING]: _get(current, 'rent_outside_parking') || '0,00',
    [FUTURE_USAGE_SQM_PRICE_RESIDENTIAL]: _get(future, 'sqm_price_residential') || '0,00',
    [FUTURE_USAGE_SQM_PRICE_COMMERCIAL]: _get(future, 'sqm_price_commercial') || '0,00',
    [FUTURE_USAGE_PRICE_GARAGE_PARKING]: _get(future, 'price_garage_parking') || '0,00',
    [FUTURE_USAGE_PRICE_OUTSIDE_PARKING]: _get(future, 'price_outside_parking') || '0,00',
    [FUTURE_USAGE_SQM_PRICE_PROPERTY]: _get(future, 'sqm_price_property') || '0,00',
    [FUTURE_USAGE_FAIR_MARKET_VALUE]: _get(future, 'fair_market_value') || '0,00',
    [FUTURE_USAGE_LOAN_VALUE]: _get(future, 'loan_value') || 0,
    [FUTURE_USAGE_SQM_RENT_RESIDENTIAL]: _get(future, 'sqm_rent_residential') || '0,00',
    [FUTURE_USAGE_SQM_RENT_COMMERCIAL]: _get(future, 'sqm_rent_commercial') || '0,00',
    [FUTURE_USAGE_RENT_GARAGE_PARKING]: _get(future, 'rent_garage_parking') || '0,00',
    [FUTURE_USAGE_RENT_OUTSIDE_PARKING]: _get(future, 'rent_outside_parking') || '0,00',
  };

  return bankValue;
};

export default mapBankValuesObjectDataFromApi;
