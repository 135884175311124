import { LOAD_PARTNER_SETTINGS_SUCCESS } from 'api/CompeonReverseApi/actionTypes';
import { FeatureFlagsMapper } from 'config/featureFlags/FeatureFlagsMapper';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
const { customer } = paths;

const initialState = {
  [CONFIG.IS_BANK_ADVISOR_ENABLED]: false,
  [CONFIG.IS_FASTLANE_ENABLED]: false,
  [CONFIG.IS_INQUIRY_DRAFT_MODE_ENABLED]: false,
  [CONFIG.IS_INQUIRY_EDIT_ALLOWED_FOR_CUSTOMER]: true,
  [CONFIG.IS_SSO_ENABLED]: false,
  [CONFIG.IS_COMPEON_MANAGED]: false,
  [CONFIG.IS_CHANGELOG_ON]: false,
  [CONFIG.IS_SALES_PERSONS_ENABLED]: false,
  [CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED]: false,
  [CONFIG.IS_REGISTRATION_ENABLED]: false,
  [CONFIG.IS_AGRICULTURAL_INQUIRY_PROCESS_ENABLED]: true,
  [CONFIG.IS_FORESTRY_INQUIRY_PROCESS_ENABLED]: true,
  [CONFIG.IS_COLLECTING_LEADS_ENABLED]: false,
  [CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD]: false,
  [CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER]: false,
  [CONFIG.IS_PREMIUM_PARTNER_ENABLED]: false,
  [CONFIG.IS_DIGITAL_PROCESSING_ENABLED]: true,
  [CONFIG.IS_CREFO_MANUAL_REPORT_ENABLED]: true,
  [CONFIG.REQUIRE_FINAL_INFO]: true,
  inquiryFlowStartUrl: paths.financingNeed,
};

export const startUrlForInquiryType = {
  [InquiryType.default]: paths.financingNeed,
  [InquiryType.leaseplan]: customer.inquiryList,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_PARTNER_SETTINGS_SUCCESS: {
      // let's set all configs here
      // if they come from API
      // but also if they depend on partner name
      const { attributes } = payload.data.data;
      const formsConfig = attributes['process-config']['forms-config'];
      const availableFormTypes = Object.keys(formsConfig) || [InquiryType.default];
      const mapper = new FeatureFlagsMapper(attributes);

      return {
        ...state,
        inquiryFlowStartUrl:
          startUrlForInquiryType[availableFormTypes[0]] || initialState.inquiryFlowStartUrl,
        ...mapper.resolveFlags(),
      };
    }
    default:
      return state;
  }
};
