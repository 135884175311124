import * as React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import ProfileIndustry from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { schemaHelpers } from 'schema/inquirySchema.helpers';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import AdditionalAssociationCompany from './AdditionalAssociationCompany';
import AlreadyCustomer from './AlreadyCustomer/AlreadyCustomer';
import CompanyInfoRating from './CompanyInfoRating';
import CompanyInformationForm from './CompanyInformationForm';
import ConsiderSubsidies from './ConsiderSubsidies';
import { ExcludingQuestions } from './ExcludingQuestions';
import FoundingMonth from './FoundingMonth';
import FoundingYear from './FoundingYear';
import IncomeSurplus from './IncomeSurplus';
import LegalForm from './LegalForm/LegalForm';
import PartnerSpecificIban from './PartnerSpecificIban';
import TurnoverClass from './TurnoverClass/TurnoverClass';

export const BigPictureCompletingCompanyDetails: React.FC = () => {
  const t = useTranslations();
  const canShowUserProfileLegalFormDescription = useCanShowField(
    USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  );

  return (
    <FormSection
      name={FormSections.companyDetails}
      sectionNumber={2}
      title={t('pages.userProfile.sections.completingCompanyDetails.title')}
    >
      <CompanyInformationForm />

      <FormRow>
        <LegalForm />
      </FormRow>

      <Condition condition={() => canShowUserProfileLegalFormDescription}>
        <FormRow>
          <InputWithField name={USER_PROFILE_LEGAL_FORM_DESCRIPTION} />
        </FormRow>
      </Condition>

      <ProfileIndustry />

      <FormRow>
        <TurnoverClass />
      </FormRow>
      <FormRow>
        <FoundingYear />

        <Condition condition={fieldIsValid(USER_PROFILE_FOUNDING_YEAR)}>
          <FoundingMonth />
        </Condition>
      </FormRow>
      <ExcludingQuestions />
      <FormRow>
        <IncomeSurplus />
      </FormRow>
      <FormRow>
        <AlreadyCustomer />
      </FormRow>
      <PartnerSpecificIban />
      <FormRow>
        <AdditionalAssociationCompany />
      </FormRow>

      <ConsiderSubsidies />

      <Condition
        isBusinessCondition
        condition={schemaHelpers.combineConditions(
          fieldBusinessConditions.isAlreadyCompanyCustomer,
          fieldBusinessConditions.isBankAdvisor,
        )}
      >
        <CompanyInfoRating />
      </Condition>
    </FormSection>
  );
};
