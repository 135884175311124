import React from 'react';

import { Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { getCompeonInquiryState } from 'store/inquiryDetails/selectors';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { compeonExternalStateStepKeys, compeonExternalStateSteps } from './types';

const { COMPEON_EXTERNAL_STATE_STEP__DEFAULT } = compeonExternalStateStepKeys;

const ProcessingStatusList: React.FC = () => {
  const compeonState = useSelector(getCompeonInquiryState);
  const t = useTranslations();

  const { step: currentCompeonStateStep } =
    compeonExternalStateSteps[compeonState || COMPEON_EXTERNAL_STATE_STEP__DEFAULT];

  return (
    <>
      {Object.keys(compeonExternalStateSteps)
        .slice(5)
        .map((key) => {
          const { step, languageKey } =
            compeonExternalStateSteps[key as compeonExternalStateStepKeys];
          return (
            <>
              <TextComponent
                color={currentCompeonStateStep >= step ? 'green.500' : ''}
                fontWeight={
                  currentCompeonStateStep === step ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR
                }
              >
                {`${step}. ${t(languageKey)}`}
              </TextComponent>
              <Divider opacity="1" mt={2} mb={2} borderColor="border.lightGrey" />
            </>
          );
        })}
    </>
  );
};

export default ProcessingStatusList;
