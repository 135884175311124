import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const OtherIcon = (props: IconProps) => {
  return (
    <Icon
      width="42"
      height="42"
      viewBox="0 0 42 42"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M24.4 42h-6.8a.58.58 0 01-.59-.58v-3.28a17.37 17.37 0 01-5.31-2.2l-2.32 2.32a.58.58 0 01-.41.17H9a.57.57 0 01-.41-.17l-4.85-4.82a.59.59 0 010-.82l2.32-2.32a17.37 17.37 0 01-2.2-5.3H.58a.59.59 0 01-.58-.6v-6.8a.59.59 0 01.58-.6h3.28a17.37 17.37 0 012.2-5.31L3.74 9.38a.58.58 0 010-.82l4.82-4.82A.61.61 0 019 3.57a.63.63 0 01.41.17l2.29 2.33A17.29 17.29 0 0117 3.86V.58a.58.58 0 01.6-.58h6.8a.58.58 0 01.6.58v3.28a17.37 17.37 0 015.31 2.2l2.32-2.32a.59.59 0 01.82 0l4.82 4.82a.59.59 0 010 .82l-2.34 2.32a17.15 17.15 0 012.21 5.3h3.28a.59.59 0 01.58.59v6.8a.59.59 0 01-.58.59h-3.28a17.25 17.25 0 01-2.21 5.31l2.33 2.32a.6.6 0 010 .83l-4.82 4.81a.59.59 0 01-.82 0l-2.32-2.31a17.47 17.47 0 01-5.3 2.2v3.28a.58.58 0 01-.6.58zm-6.22-1.17h5.64v-3.16a.6.6 0 01.47-.57 16.13 16.13 0 005.77-2.4.58.58 0 01.73.08L33 37l4-4-2.24-2.24a.58.58 0 01-.08-.73 16.13 16.13 0 002.4-5.77.59.59 0 01.57-.47h3.16v-5.61h-3.14a.57.57 0 01-.57-.47 16.31 16.31 0 00-2.4-5.77.58.58 0 01.08-.73L37 9l-4-4-2.21 2.22a.58.58 0 01-.73.08 16.31 16.31 0 00-5.77-2.4.58.58 0 01-.47-.57V1.17h-5.64v3.16a.59.59 0 01-.47.57 16.34 16.34 0 00-5.77 2.4.58.58 0 01-.73-.08L9 5 5 9l2.24 2.24a.58.58 0 01.08.73 16.31 16.31 0 00-2.4 5.77.58.58 0 01-.57.47H1.17v5.64h3.16a.58.58 0 01.57.47 16.12 16.12 0 002.4 5.76.59.59 0 01-.08.74L5 33l4 4 2.24-2.24a.58.58 0 01.73-.08 16.31 16.31 0 005.77 2.4.59.59 0 01.47.57zM21 28.88A7.88 7.88 0 1128.87 21 7.9 7.9 0 0121 28.88zm0-14.59A6.71 6.71 0 1027.71 21 6.72 6.72 0 0021 14.29z" />
    </Icon>
  );
};

export default OtherIcon;
