import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { createCompeonClient } from 'api';
import { API_HEADERS } from 'api/apiService';
import endpoints from 'api/CompeonApi/endpoints';
import { UPLOAD_STATUS } from 'components/UploadBlock/constants';
import {
  useFileValidator,
  validateFileExtension,
  validateFileSize,
} from 'components/UploadBlock/hooks/useFileValidator';
import UploadIdleState from 'components/UploadBlock/UploadIdleState/UploadIdleState';
import NewFileUploader from 'components/UploadBlock/UploadUploadingState/UploadUploadingState';
import { MAX_FILE_SIZE } from 'constants/file';
import { FieldTypeComponentProps } from 'modules/FormGenerator/propTypes';
import { StyledDocumentFieldWrapper } from 'modules/FormGenerator/styles';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { getAccessToken } from 'store/user/service';
import { useTranslations } from 'utils/hooks/useTranslations';

const compeonApi = createCompeonClient();

const sendFile = (file, compeonId, token) =>
  compeonApi.put(
    endpoints.DOCUMENTS.compose({
      query: {
        title: file.name,
        'company-id': compeonId,
      },
    }),
    file,
    {
      headers: {
        'Content-Type': file.type,
        ...API_HEADERS.bearerToken(token),
      },
    },
  );

const ALLOWED_COMPEON_DOC_EXTENSIONS = ['pdf', 'jpg', 'png', 'xls', 'xlsx', 'doc', 'docx'];
const DOCUMENT_VALIDATIONS = [
  validateFileExtension(ALLOWED_COMPEON_DOC_EXTENSIONS),
  validateFileSize(MAX_FILE_SIZE),
];

const DocumentTypeField = ({ field: { label, value }, onChange }) => {
  const [uploadStatus, setUploadStatus] = useState(
    value ? UPLOAD_STATUS.UPLOADED : UPLOAD_STATUS.NOT_UPLOADED,
  );
  const [errors, setErrors] = useState('');
  const token = useSelector(getAccessToken);
  const tErrors = useTranslations('errors');
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const validateFile = useFileValidator(DOCUMENT_VALIDATIONS);

  const sendFileToCompeon = (file) =>
    validateFile(file)
      .then((validatedFile) => {
        setUploadStatus(UPLOAD_STATUS.SCANNING);
        sendFile(validatedFile, inquiryDetails.company.compeonId, token)
          .then((response) => {
            setUploadStatus(UPLOAD_STATUS.UPLOADED);
            onChange(response.data.data.id);
          })
          .catch(() => {
            setUploadStatus(UPLOAD_STATUS.NOT_UPLOADED);
            setErrors(tErrors('cannotUploadFile'));
          });
      })
      .catch((error) => {
        setUploadStatus(UPLOAD_STATUS.NOT_UPLOADED);
        setErrors(error);
      });

  if (uploadStatus === UPLOAD_STATUS.SCANNING) {
    return (
      <StyledDocumentFieldWrapper>
        <NewFileUploader uploadProgress={100} />
      </StyledDocumentFieldWrapper>
    );
  }

  return (
    <StyledDocumentFieldWrapper>
      <UploadIdleState
        title={label}
        status={uploadStatus}
        onFileSelected={sendFileToCompeon}
        errors={errors}
      />
    </StyledDocumentFieldWrapper>
  );
};

DocumentTypeField.propTypes = FieldTypeComponentProps;

export default DocumentTypeField;
