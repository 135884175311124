import React from 'react';

import { useSelector } from 'react-redux';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import paths from 'constants/paths';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import mapProfitabilityToEdit from '../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import {
  probabilityInvestmentCalculationTranslationMapping,
  probabilityInvestmentCalculationUnitMapping,
  PROFITABILITY_INVESTMENT_COST,
} from '../../types';
import { useRoles } from '../hooks';
import TableCardWithEdit from '../TableCardWithEdit';
import { orderMapping } from '../utils';

export type InvestmentCostType = {
  [key: string]: {
    investment_costs: number;
    total_area_costs_per_sqm: number;
    percentage: number;
  };
};

interface props {
  data: InvestmentCostType;
}

const InvestmentCalculation: React.FC<props> = ({ data }: props) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { investmentCosts } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.investmentCalculation',
  );

  const unitTranslations = useTranslations();

  const headers = [
    {
      key: 'position',
      text: t('tableHeaders.position'),
    },
    {
      key: 'investmentCost',
      text: t('tableHeaders.investmentCost'),
    },
    {
      key: 'costPerMeterSq',
      text: t('tableHeaders.costPerMeterSq'),
    },
    {
      key: 'inPercentage',
      text: t('tableHeaders.inPercentage'),
    },
  ];

  const orderedData: InvestmentCostType = orderMapping<InvestmentCostType>(
    data,
    probabilityInvestmentCalculationTranslationMapping,
  );

  const highlightedRows = [
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_BUILDING_PLOT_COSTS}`,
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_CONSTRUCTION_COSTS}`,
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_ADDITIONAL_CONSTRUCTION_COSTS}`,
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_FINANCING_COSTS}`,
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_SUM_OF_TOTAL_COSTS}`,
    `${PROFITABILITY_INVESTMENT_COST.PROFITABILITY_FINANCING_AMOUNT}`,
  ];

  const rows =
    orderedData &&
    Object.keys(orderedData).map((item: string, index: number) => ({
      id: item,
      position: t(`tableRows.${probabilityInvestmentCalculationTranslationMapping[item]}`),
      investmentCost: formatColumnValues(
        orderedData[item]?.investment_costs,
        probabilityInvestmentCalculationUnitMapping(unitTranslations)[item].investment_costs,
      ),
      costPerMeterSq: formatColumnValues(
        orderedData[item]?.total_area_costs_per_sqm,
        probabilityInvestmentCalculationUnitMapping(unitTranslations)[item]
          .total_area_costs_per_sqm,
      ),
      inPercentage: formatColumnValues(
        orderedData[item]?.percentage,
        probabilityInvestmentCalculationUnitMapping(unitTranslations)[item].percentage,
      ),
      ...(highlightedRows.includes(item) && { rowHighlight: true, valueBold: true }),
    }));

  const { onEdit } = useEditFormConfig({
    pathToEdit: investmentCosts(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS,
    loadData: loadEditProfitabilitySection,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS](),
  });
  const { hasRoleBankAdvisorOrRealEstateExpert } = useRoles();
  const canEdit = hasRoleBankAdvisorOrRealEstateExpert;

  return (
    <AccordionComponent headingLevel="h3" mb={8} title={t('title')}>
      <TableCardWithEdit
        onEdit={onEdit}
        canEdit={canEdit}
        tableHeaders={headers}
        tableData={rows}
        componentName={PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS}
      />
    </AccordionComponent>
  );
};

export default InvestmentCalculation;
