import React from 'react';

import { useTranslations } from 'utils/hooks/useTranslations';
import { formatColumnValues } from 'utils/valueFormats';

import ProjectCostingBoxes from '../../../../SharedComponent/ProjectCostingBoxes';
import {
  probabilityBottomTilesTranslationMapping,
  probabilityBottomTilesUnitMapping,
} from '../../../types';

interface Props {
  tiles: { [key: string]: string | number };
}

const SaleAsUnitBoxes: React.FC<Props> = ({ tiles }: Props) => {
  const t = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.boxes',
  );

  const tUnit = useTranslations();
  const boxValues =
    tiles &&
    Object.keys(tiles).map((item) => {
      return {
        id: item,
        name: t(`${probabilityBottomTilesTranslationMapping[item]}`),
        value: formatColumnValues(tiles[item], probabilityBottomTilesUnitMapping(tUnit)[item]),
      };
    });

  return <ProjectCostingBoxes values={boxValues} />;
};

export default SaleAsUnitBoxes;
