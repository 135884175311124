import React from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import { CheckboxWithField } from 'components/Checkbox';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import PERMISSIONS from 'constants/user/permissions';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useIsMittweidaInquiry } from 'shared/hooks/useIsMittweidaInquiry';
import { createFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Text } from './styles';

const AddNewRequest: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { required, optional } = useFieldValidators();
  const inquiryId = useSelector(getInquiryIdSelector);

  const { selectedLanguage } = useLanguagePreferences();

  const isMittweidaInquiry = useIsMittweidaInquiry();

  const { makeCall, isPending } = useDispatchApiCall();
  const createNewRequest = async (values: {
    title: string;
    description: string;
    customerNotification: boolean;
  }) => {
    await makeCall(
      createFileRequestAction(
        inquiryId,
        values.title,
        values.description,
        !values.customerNotification,
        selectedLanguage,
      ),
    );
    onClose();
  };

  const t = useTranslations('pages.inquiryDetails.documentExchange.requestDocuments');

  return (
    <>
      {!isMittweidaInquiry ? (
        <ButtonComponent data-testid="add-new-request" onClick={onOpen}>
          {t('title')}
        </ButtonComponent>
      ) : null}
      <ModalComponent
        testId="add-new-request-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('title')}
        footer={
          <>
            <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
              {t('buttonCancel')}
            </ButtonComponent>
            <ButtonComponent form="form-add-new-request" type="submit" isLoading={isPending}>
              {t('buttonConfirm')}
            </ButtonComponent>
          </>
        }
      >
        <TextComponent mb={8}>{t('lead')}</TextComponent>
        <Form
          onSubmit={createNewRequest}
          initialValues={{
            customerNotification: true,
          }}
          render={({ handleSubmit }) => (
            <form id="form-add-new-request" onSubmit={handleSubmit}>
              <Box mb="2rem">
                <InputWithField validate={required} name="title" caption={t('name.caption')} />
              </Box>

              <Box mb="2rem">
                <TextAreaWithField
                  validate={optional}
                  name="description"
                  caption={t('description.caption')}
                />
              </Box>

              <Box mb="2rem">
                <CheckboxWithField
                  name="customerNotification"
                  text={<Text>{t('customerNotification')}</Text>}
                  small
                />
              </Box>
            </form>
          )}
        />
      </ModalComponent>
    </>
  );
};

export default withRolesGuard(
  PERMISSIONS.INQUIRY.DETAILS.DOCUMENT_EXCHANGE.CREATE_DOCUMENT_REQUEST,
)(AddNewRequest);
