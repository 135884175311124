import React from 'react';

import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import Condition from 'components/Condition';
import paths from 'constants/paths';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { FILTERS_FINANCING_ROLE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../../components/Card';
import { LendingValueDeveloper } from './LendingValueDeveloper';
import { LendingValuePartition } from './LendingValuePartition';
import { LendingValueStockholder } from './LendingValueStockholder';

export interface LendingValueItem {
  base_value: string;
  cost_transfer: string;
  lending_value: string;
  risk_deduction: string;
  loan_value_ratio: string;
  unsecured_portion: string;
  lending_limit_euro: string;
  adjusted_base_value: string;
  lending_limit_percent: string;
}
export interface LendingValueProps {
  current: { indication: LendingValueItem; whole_project: LendingValueItem };
  future: { indication: LendingValueItem; whole_project: LendingValueItem };
  buying_phase: LendingValueItem;
  notes_on_remaining_risk: string;
}

export const LendingValue = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { financingRole } = useSelector(getMarketDataFilter);
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.lendingValue',
  );
  const { lendingValue } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: lendingValue(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[
        MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE
      ](),
  });

  return (
    <Card title={t('title')} onEdit={onEdit} isExpandable isExpanded>
      <Condition condition={financingRole === FILTERS_FINANCING_ROLE.DEVELOPER}>
        <LendingValueDeveloper />
      </Condition>
      <Condition condition={financingRole === FILTERS_FINANCING_ROLE.PARTITION}>
        <LendingValuePartition />
      </Condition>
      <Condition condition={financingRole === FILTERS_FINANCING_ROLE.STOCKHOLDER}>
        <LendingValueStockholder />
      </Condition>
    </Card>
  );
};
