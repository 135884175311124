import React, { useState } from 'react';

import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'theme/components/Button';

import FormSection from '../../../../../../components/FormSection';
import { StyledActions } from '../../../../../../components/StyledActions';
import paths from '../../../../../../constants/paths';
import {
  MITTWEIDA_CONTROL_OBJECT_DATA_STEP,
  MITTWEIDA_CURRENT_USAGE_STEP,
  MITTWEIDA_FUTURE_USAGE_STEP,
} from '../../../../../../modules/Inquiry/Form/formFields';
import { translations } from '../../../../../../new/form/common/types';
import MarketInformationHeading from '../../../../../../pages/customerPortal/MarketInformationPage/MarketInformationHeading';
import MarketInformationTableStep from '../../../../../../pages/customerPortal/MarketInformationPage/MarketInformationTableStep';
import { useRequestMarketData } from '../../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import { useToasts } from '../../../../../../shared/hooks/useToasts';
import { marketDataSentAction } from '../../../../../../store/inquiryDetails/actions';
import { getInquiryIdSelector } from '../../../../../../store/inquiryDetails/selectors';
import ArrowRightIcon from '../../../../../../theme/components/Icon/ArrowRightIcon';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import MittweidaMarketInfoAddressSelectionPage, {
  IAddress,
} from '../MarketInfoAddressSelectionPage';

export const MittweidaMarketInformationInquiryTable = () => {
  const InquiryTableSteps = [
    MITTWEIDA_CONTROL_OBJECT_DATA_STEP,
    MITTWEIDA_FUTURE_USAGE_STEP,
    MITTWEIDA_CURRENT_USAGE_STEP,
  ];
  const t = useTranslations();
  const history = useHistory();
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);
  const [ambiguousAddresses, setAmbiguousAddresses] = useState<IAddress[]>([]);
  const { error, success } = useToasts();
  const { isPending, requestMarketData } = useRequestMarketData(inquiryId!);

  const toastLabels = translations.pages.inquiryDetails.dashboard.actions.triggerMarketResearch;
  const stepTitle = (stepName: string) => {
    const camelStepName = _.camelCase(stepName);
    return t(`pages.marketInformationInquiryPage.steps.${camelStepName}.title`);
  };

  const path = paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId!);
  const redirectToDashboard = () => history.push(path);

  const handleSubmit = async () => {
    const res = await requestMarketData();
    if (res) {
      if (res.data.data.attributes.status === 'ambiguous_address') {
        setAmbiguousAddresses(res.data.data.attributes.status_details.ambiguous_addresses);
      } else if (res.data.data.attributes.status === 'error') {
        error({ description: toastLabels.error });
      } else {
        dispatch(marketDataSentAction());
        success({ description: toastLabels.success });
        redirectToDashboard();
      }
    }
  };

  if (!_.isEmpty(ambiguousAddresses)) {
    return <MittweidaMarketInfoAddressSelectionPage ambiguousAddresses={ambiguousAddresses} />;
  } else {
    return (
      <>
        <MarketInformationHeading />
        {InquiryTableSteps?.map((stepName, index) => (
          <FormSection key={stepName} sectionNumber={index + 1} title={stepTitle(stepName)}>
            <MarketInformationTableStep stepName={stepName} />
          </FormSection>
        ))}
        <StyledActions>
          <ButtonComponent mr={4} onClick={redirectToDashboard} variant="tertiary">
            {t(translations.buttons.cancel)}
          </ButtonComponent>
          <ButtonComponent
            leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
            type="submit"
            onClick={handleSubmit}
            isDisabled={isPending}
          >
            {t(translations.pages.finishRegistration.confirmBtn)}
          </ButtonComponent>
        </StyledActions>
      </>
    );
  }
};
