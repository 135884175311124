import { ParsedQs } from 'qs';

import { API_METHODS, callReverseApi } from 'api/apiService';
import { CHECK_EMAIL_AVAILABILITY } from 'api/CompeonReverseApi/customer/actionTypes';
import commonEndpoints from 'api/CompeonReverseApi/endpoints';

interface Props {
  invitationToken: string | ParsedQs | string[] | ParsedQs[];
  password: string;
  terms?: boolean;
}

interface validateTokenProps {
  token: string | ParsedQs | string[] | ParsedQs[] | undefined;
}

export const acceptInvitationAction = ({ invitationToken, password, terms }: Props) =>
  callReverseApi({
    actionName: CHECK_EMAIL_AVAILABILITY,
    url: commonEndpoints.USERS.INVITATION_ACCEPT.compose(),
    method: API_METHODS.PATCH,
    data: {
      data: { invitation_token: invitationToken, password, terms_and_conditions_accepted: terms },
    },
  });

export const validateInvitationTokenAction = ({ token }: validateTokenProps) =>
  callReverseApi({
    url: commonEndpoints.USERS.PASSWORD_RESET_TOKEN_VALIDATE.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        token,
        operation: 'invitation',
      },
    },
  });
