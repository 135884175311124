import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  GOODS__DESCRIPTION,
  GOODS__ESTIMATED_DELIVERY_DATE,
  GOODS__PURCHASE_PRICE,
  GOODS__SUPPLIER,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateGoodsSection = (form) =>
  fieldsAreValid([
    GOODS__DESCRIPTION,
    GOODS__ESTIMATED_DELIVERY_DATE,
    GOODS__PURCHASE_PRICE,
    GOODS__SUPPLIER,
  ])({ form });

const Goods = () => {
  const t = useTranslations();
  const sectionNumber = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dvag]: 2,
    default: 3,
  });

  return (
    <FormSection
      name={FormSections.goods}
      sectionNumber={sectionNumber}
      title={t('pages.financingNeed.sections.goods.title')}
    >
      <FormRow>
        <AmountWithField name={GOODS__PURCHASE_PRICE} />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={GOODS__DESCRIPTION} />
      </FormRow>
      <FormRow>
        <InputWithField name={GOODS__SUPPLIER} />
        <DayPickerWithField
          name={GOODS__ESTIMATED_DELIVERY_DATE}
          placeholder={t('placeholders.pleaseChoose')}
        />
      </FormRow>
    </FormSection>
  );
};

export default Goods;
