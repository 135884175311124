import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import UserDataPage from 'modules/UserData/UserData';
import ChangePassword from 'pages/ChangePassword';
import CompanyDetails from 'pages/operationPortal/CompaniesDetails/CompanyDetails';
import CompaniesList from 'pages/operationPortal/CompaniesList/CompaniesList';
import FinishRegistration from 'pages/operationPortal/FinishRegistration';
import InquiryDetails from 'pages/operationPortal/InquiryDetails/OperationInquiryDetails';
import OperationLogin from 'pages/operationPortal/Login';
import InquiryList from 'pages/operationPortal/OperationInquiryList/OperationInquiryList';
import RequestPasswordReset from 'pages/RequestPasswordReset';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';

const { operation: operationPaths } = paths;

const OperationRoutes = () => (
  <Switch>
    <Route path={operationPaths.login} component={OperationLogin} />
    <Route path={operationPaths.passwordReset.request} component={RequestPasswordReset} />
    <Route path={operationPaths.passwordReset.change} component={ChangePassword} />
    <Route path={operationPaths.finishRegistration} component={FinishRegistration} />
    <OperationPrivateRoute path={operationPaths.userData} component={UserDataPage} />
    <OperationPrivateRoute exact path={operationPaths.inquiryList} component={InquiryList} />
    <OperationPrivateRoute path={operationPaths.inquiryDetails.root} component={InquiryDetails} />
    <OperationPrivateRoute exact path={operationPaths.companies.root} component={CompaniesList} />
    <OperationPrivateRoute path={operationPaths.companies.details()} component={CompanyDetails} />
    <Route path="*" render={() => <Redirect to={paths.error404} />} />
  </Switch>
);

export default OperationRoutes;
