import * as React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { Condition } from 'new/form/Condition';
import { ProfileIndustrySelect } from 'ui/companyDetails/industry/select';
import { TurnoverClassSelect } from 'ui/companyDetails/turnoverClass/select';

import DisabledFormSection from '../../../../../../components/DisabledFormSection';
import { YesNoRadioGroupWithField } from '../../../../../../components/YesNoRadioGroup';
import { usePartnerConfig } from '../../../../../../config/partner/hooks';
import { translations } from '../../../../../../new/form/common/types';
import { FoundingMonthSelect } from '../../../../../../ui/companyDetails/foundingMonth/select';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import { useShowNameOfContactPerson } from '../../../../conditions';
import { CompanyInformationForm } from './components/CompanyInformationForm';
import { LegalForm } from './components/LegalForm';

const {
  industry,
  turnoverClass,
  foundingYear,
  foundingMonth,
  alreadyCustomer,
  alreadyContactWithBank,
  nameOfContactPerson,
} = mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;

export const CompletingCompanyDetailsSection = () => {
  const t = useTranslations();
  const { title, fallback } =
    translations.inquiryType.mittweida.pages.companyDetails.sections.completingCompanyDetails;
  const { userProfileAlreadyContactWithBank, userProfileContactPerson } =
    translations.pages.userProfile.sections.completingCompanyDetails.fields;

  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  const showContactPersonName = useShowNameOfContactPerson();

  return (
    <Condition
      validation={'companyDetailsSection'}
      fallback={
        <DisabledFormSection sectionNumber={2} placeholder={t(fallback)} title={t(title)} />
      }
    >
      <FormSection title={t(title)} sectionNumber={2} name={'completingCompanyDetails'}>
        <CompanyInformationForm />
        <FormRow>
          <LegalForm />
        </FormRow>

        <FormRow>
          <ProfileIndustrySelect name={industry} />
        </FormRow>

        <FormRow>
          <TurnoverClassSelect name={turnoverClass} />
        </FormRow>

        <FormRow>
          <InputWithField name={foundingYear} type={'number'} separateThousands={false} />
          <FoundingMonthSelect name={foundingMonth} />
        </FormRow>

        <FormRow>
          <YesNoRadioGroupWithField name={alreadyCustomer} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField
            name={alreadyContactWithBank}
            caption={t(userProfileAlreadyContactWithBank.caption, { bankName: partnerName })}
          />
        </FormRow>
        <Condition validation={showContactPersonName}>
          <InputWithField
            name={nameOfContactPerson}
            placeholder={t(userProfileContactPerson.placeholder)}
          />
        </Condition>
      </FormSection>
    </Condition>
  );
};
