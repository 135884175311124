import { IInquiryListOptions } from 'models/InquiryList.model';
import { AppActionThunk } from 'models/types/AppThunk.type';
import { success, failure } from 'utils/actionTypesHelpers';

import { IInquiriesListStateInquiryManager } from './reducer';

export const SET_INQUIRIES_LIST_OPTIONS = 'SET_INQUIRIES_LIST_OPTIONS';

export const CHANGE_INQUIRIES_LIST_OPTIONS = 'CHANGE_INQUIRIES_LIST_OPTIONS';
export const changeInquiriesListOptions =
  (optionChanges: Partial<IInquiryListOptions>): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: CHANGE_INQUIRIES_LIST_OPTIONS,
      payload: optionChanges,
    });

export const FETCH_INQUIRIES_LIST_OPTIONS = 'FETCH_INQUIRIES_LIST_OPTIONS';
export const FETCH_INQUIRIES_LIST_OPTIONS_SUCCESS = success(FETCH_INQUIRIES_LIST_OPTIONS);
export const FETCH_INQUIRIES_LIST_OPTIONS_FAIL = failure(FETCH_INQUIRIES_LIST_OPTIONS);

export const SET_INQUIRIES_LIST_STATUSES = 'SET_INQUIRIES_LIST_STATUSES';
export const setInquiriesListStatusesAction =
  (
    statuses: {
      [key: string]: number | string;
    }[],
  ): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_INQUIRIES_LIST_STATUSES,
      payload: statuses,
    });

export const SET_INQUIRIES_LIST_INQUIRY_MANAGERS = 'SET_INQUIRIES_LIST_INQUIRY_MANAGERS';
export const setInquiriesListInquiryManagersAction =
  (inquiryManagers: Array<IInquiriesListStateInquiryManager>): AppActionThunk =>
  (dispatch) => {
    return dispatch({
      type: SET_INQUIRIES_LIST_INQUIRY_MANAGERS,
      payload: inquiryManagers,
    });
  };
