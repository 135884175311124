import { getFieldValue } from 'modules/Inquiry/Form/formConditions';
import {
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
} from 'modules/Inquiry/Form/formFields';
import { validateFactoringSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/factoringSection.validator';
import { validateMezzanineSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/mezzanineSection.validator';
import { validateOtherProductSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/otherProductSection.validator';
import { validateOverdraftSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/overdraftSection.validator';
import { validateProjectFinancingSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/projectFinancingSection.validator';
import { validateHirePurchaseSection } from 'modules/Inquiry/Form/Validations/dvag/RequestDetails/hirePurchaseSection.validator';
import { validateInvestmentLoanSection } from 'modules/Inquiry/Form/Validations/dvag/RequestDetails/investmentLoanSection.validator';
import { validateLeasingSection } from 'modules/Inquiry/Form/Validations/dvag/RequestDetails/leasingSection.validator';
import { validateLoanSection } from 'modules/Inquiry/Form/Validations/dvag/RequestDetails/loanSection.validator';

export const getDVAGProductSectionValidators = (form) => {
  const selectedProduct = getFieldValue(PRODUCT_KIND, form);
  const productValidatorsMap = {
    [PRODUCT_KIND__FACTORING]: validateFactoringSection,
    [PRODUCT_KIND__HIRE_PURCHASE]: validateHirePurchaseSection,
    [PRODUCT_KIND__INVESTMENT_LOAN]: validateInvestmentLoanSection,
    [PRODUCT_KIND__LOAN]: validateLoanSection,
    [PRODUCT_KIND__LEASING]: validateLeasingSection,
    [PRODUCT_KIND__OVERDRAFT]: validateOverdraftSection,
    [PRODUCT_KIND__OTHER]: validateOtherProductSection,
    [PRODUCT_KIND__PROJECT_FINANCING]: validateProjectFinancingSection,
    [PRODUCT_KIND__MEZZANINE]: validateMezzanineSection,
  };

  return productValidatorsMap[selectedProduct];
};
