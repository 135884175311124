import * as React from 'react';

import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import { isLoggedInSelector } from 'store/user/selectors';

import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from '../../../../../../modules/Inquiry/Form/formFields';
import { translations } from '../../../../../../new/form/common/types';
import { SuggestionOption } from '../../../../../../pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import {
  mittweidaFields,
  mittweidaFieldsWithSectionString,
  MittweidaFieldTypes,
} from '../../../../fields';
import CompanySuggestion from './components/companySuggestion';
import { MittweidaExistingUserCompanySelect } from './components/companySuggestion/MittweidaExistingUserCompanySelect';
import { shouldShowCompanySuggestion } from './shouldShowCompanySuggestion';

const { name, address, city, country, state, street, tradeName, zipCode } =
  mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;
const { selectedCompanyName: selectedCompanyNameFieldName, loggedInUserCompanyName } =
  mittweidaFields.companyDetailPage.companyDetailsSection;

const companyDetails =
  mittweidaFieldsWithSectionString.companyDetailPage.companyDetailsSection.sectionString;
const completingCompanyDetails =
  mittweidaFieldsWithSectionString.companyDetailPage.completingCompanyDetailsSection.company
    .sectionString;

function setFieldsToUndefined(change: Function) {
  change(completingCompanyDetails, {
    name: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    state: undefined,
    street: undefined,
    tradeName: undefined,
    zipCode: undefined,
  });
}

function setFieldValues(change: Function, company: SuggestionOption) {
  change(name, company.value.name);
  change(address, company.value.address);
  change(city, company.value.city);
  change(country, company.value.country);
  change(state, company.value.state);
  change(street, company.value.street);
  change(tradeName, company.value['trade-name']);
  change(zipCode, company.value['zip-code']);
}

export const CompanyDetailsSection = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { values } = useFormState<MittweidaFieldTypes>();
  const { companyDetailsSection } = values.companyDetailPage;
  const shouldShowSuggestion = shouldShowCompanySuggestion(isLoggedIn, companyDetailsSection);
  const { change, batch } = useForm();
  const t = useTranslations();

  const handleSelect = (value: string, companies: SuggestionOption[]) => {
    if (value === COMPANY_DETAILS_COMPANY__NOT_FOUND) {
      batch(() => {
        change(selectedCompanyNameFieldName, value);
        setFieldsToUndefined(change);
      });
    } else {
      const company = companies.filter((company) => company.value.name === value)[0];
      if (company) {
        batch(() => {
          change(selectedCompanyNameFieldName, value);
          setFieldValues(change, company);
        });
      }
    }
  };

  const handleExistingUserSelect = (value: string, companies: SuggestionOption[]) => {
    if (value === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY) {
      batch(() => {
        change(companyDetails, {
          loggedInUserCompanyName: value,
        });
        setFieldsToUndefined(change);
      });
    } else {
      const company = companies.filter((company) => company.value.name === value)[0];
      if (company) {
        batch(() => {
          change(loggedInUserCompanyName, value);
          setFieldValues(change, company);
        });
      }
    }
  };

  /**
   * We cannot just change the single fields hier like "address" or "name" and change them to undefined. Doing it this
   * way would dismount the files from the form and the whole page / section would be removed from the values.
   * We want to keep the structure of the form values and just change the values of the fields to undefined.
   */
  const handleClearCompanyForm = () => {
    batch(() => {
      change(companyDetails, {
        selectedCompanyFieldName: undefined,
        loggedInUserCompanyName:
          values.companyDetailPage.companyDetailsSection.loggedInUserCompanyName,
      });
      setFieldsToUndefined(change);
    });
  };

  const handleClearExistingUserCompanyForm = () => {
    batch(() => {
      change(companyDetails, {
        loggedInUserCompanyName: undefined,
        selectedCompanyNameFieldName:
          values.companyDetailPage.companyDetailsSection.selectedCompanyName,
      });
      setFieldsToUndefined(change);
    });
  };

  return (
    <FormSection
      title={t(
        translations.inquiryType.mittweida.pages.companyDetails.sections.companyDetails.title,
      )}
      name={'companyDetails'}
      sectionNumber={1}
    >
      {isLoggedIn && (
        <FormRow>
          <MittweidaExistingUserCompanySelect
            fieldName={loggedInUserCompanyName}
            onClearCompanyForm={handleClearExistingUserCompanyForm}
            onSelect={handleExistingUserSelect}
          />
        </FormRow>
      )}
      {shouldShowSuggestion && (
        <FormRow>
          <CompanySuggestion
            fieldName={selectedCompanyNameFieldName}
            onClearCompanyForm={handleClearCompanyForm}
            onSelect={handleSelect}
          />
        </FormRow>
      )}
      <CompanyNoteSection />
    </FormSection>
  );
};
