import { mapProductBasedFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapProductBasedFields';

import { COMPEON_PARTNER, PRODUCT_KIND } from '../modules/Inquiry/Form/formFields';
import { mapCompanyDetailsToApi } from '../modules/Inquiry/mapInquiryToApi/companyMappers/mapCompanyDetailsToApi';
import { mapPeopleDetailsToApi } from '../modules/Inquiry/mapInquiryToApi/peopleMapper/peopleDetailsToApi';
import { mapBigPictureUserToApi } from '../modules/Inquiry/mapInquiryToApi/userMappers/mapUserDetailsToApi';
import { mapPurposeToApi } from './purpose/mapPurposeToApi';

export const mapOnlinefactoringToApi = (allFields: any, isLoggedIn: boolean, isLead: boolean) => ({
  compeon_partner: allFields[COMPEON_PARTNER],
  'product-kind': allFields[PRODUCT_KIND],
  ...mapPurposeToApi(allFields),
  ...mapProductBasedFieldsToApi(allFields),
  ...mapCompanyDetailsToApi(allFields),
  ...mapPeopleDetailsToApi(allFields, isLead),
  ...mapBigPictureUserToApi(allFields, isLoggedIn),
});
