import React from 'react';

import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getProfitabilityInvestmentPlanTable } from '../../store/selectors';
import type { MappingProps } from '../../types';
import { getRowStylesMap, useUnitFormatter } from '../../utils';

interface InvestmentPlanItem {
  gak_other: string;
  other_costs: string;
  total_costs: string;
  selling_costs: string;
  interest_costs: string;
  total_gak_costs: string;
  additional_costs: string;
  gak_buying_costs: string;
  gak_selling_costs: string;
  construction_costs: string;
  gak_building_costs: string;
  gak_interest_costs: string;
  gak_additional_costs: string;
  total_building_costs: string;
}

export interface InvestmentPlanProps {
  current: { sqm_costs: InvestmentPlanItem };
  future: { sqm_costs: InvestmentPlanItem };
  base_values: InvestmentPlanItem;
  percent_costs: InvestmentPlanItem;
}

const mapping: MappingProps<InvestmentPlanItem> = [
  { key: 'gak_buying_costs', unit: 'currency', styles: { bold: true } },
  { key: 'gak_additional_costs', unit: 'currency', styles: { bold: true } },
  { key: 'gak_building_costs', unit: 'currency', styles: { bold: true } },
  { key: 'gak_other', unit: 'currency', styles: { bold: true } },
  { key: 'gak_selling_costs', unit: 'currency', styles: { bold: true } },
  { key: 'gak_interest_costs', unit: 'currency', styles: { bold: true } },
  { key: 'total_gak_costs', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'construction_costs', unit: 'currency', styles: { bold: true } },
  { key: 'additional_costs', unit: 'currency', styles: { bold: true } },
  { key: 'other_costs', unit: 'currency', styles: { bold: true } },
  { key: 'selling_costs', unit: 'currency', styles: { bold: true } },
  { key: 'interest_costs', unit: 'currency', styles: { bold: true } },
  { key: 'total_building_costs', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'total_costs', unit: 'currency', styles: { bold: true, highlighted: true } },
];

const useGetInvestmentPlanTable = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.investmentPlan',
  );

  const formatter = useUnitFormatter();

  const { usageType } = useSelector(getMarketDataFilter);

  const investmentPlanTableData = useSelector(getProfitabilityInvestmentPlanTable);

  return usePortalTable({
    data: mapping.map(({ key }) => ({
      id: key,
      position: t(`rows.${key}`),
      value: formatter.currency(investmentPlanTableData.base_values[key]),
      sqmCosts: formatter.currency(investmentPlanTableData[usageType].sqm_costs[key]),
      percentCosts: formatter.percent(investmentPlanTableData.percent_costs[key]),
    })),
    headers: [
      { key: 'position', text: t('headers.position') },
      { key: 'value', text: t('headers.value') },
      { key: 'sqmCosts', text: t('headers.sqmCosts') },
      { key: 'percentCosts', text: t('headers.percentCosts') },
    ],
  });
};

export const InvestmentPlan = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.investmentPlan',
  );
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const investmentPlanTable = useGetInvestmentPlanTable();
  const rowStyles = getRowStylesMap(mapping);
  const { investmentPlan } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: investmentPlan(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[
        MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN
      ](),
  });

  if (!investmentPlanTable) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <Card title={t('title')} onEdit={onEdit} isExpandable isExpanded>
      <PortalTable {...investmentPlanTable} rowStylesMap={{ ...rowStyles }} />
    </Card>
  );
};
