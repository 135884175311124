import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import routePaths from 'constants/paths';
import { inquiryStepService } from 'modules/Inquiry/inquiryNavigation/step.service';
import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { EDIT_INQUIRY_CALLER_COMPONENT_NAME } from 'store/applicationConfig/types';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { isCustomerSelector, isAgent as isAgentSelector } from 'store/user/selectors';
import { retrieveObject } from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG, USER_PROFILE } from 'utils/sessionStorage/keys';

import {
  BENEFICIARY_OWNER_DETAILS,
  CONTACT_PERSON_DETAILS,
  LEGAL_REPRESENTATIVE_DETAILS,
} from '../Form/formFields';
import { InquiryType } from '../Inquiry.type';

const {
  getPathsForUser,
  getPathToCancel,
  getPathToNextStep,
  getPathToPreviousStep,
  getPathToStep,
} = inquiryStepService;

export const useInquiryNavigation = (currentStep: string) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isAgent = useSelector(isAgentSelector);
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const applicationConfig = retrieveObject(APPLICATION_CONFIG);

  const { stepList, stepSegments } = useInquiryStepData();

  const filteredSteps = stepSegments.filter(
    (segment) =>
      !(
        (isProfiInquiry && segment.id === CONTACT_PERSON_DETAILS && !inquiryId) ||
        (isProfiInquiry && segment.id === USER_PROFILE && inquiryId)
      ),
  );

  const filteredStepList = stepList.filter(
    (step: string) =>
      !(
        (isProfiInquiry && step === CONTACT_PERSON_DETAILS && !inquiryId) ||
        (isProfiInquiry && step === USER_PROFILE && inquiryId)
      ),
  );

  const paths: string[] = getPathsForUser(filteredSteps, {
    isEditing: Boolean(inquiryId),
    isCustomer,
    isAgent,
  }).map((path) => path.replace(':id', inquiryId || ''));

  if (
    isProfiInquiry &&
    applicationConfig &&
    applicationConfig[EDIT_INQUIRY_CALLER_COMPONENT_NAME] ===
      PROFITABILITY_RECORD_TYPE.AREA_INFORMATION
  ) {
    paths[paths.length - 1] =
      routePaths.operation.inquiryDetails.planningEvaluation.profitabilityCalculation(inquiryId!);
  }

  const currentStepIndex: number = filteredStepList.indexOf(currentStep);

  const stepsToUse = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiRLL]: filteredStepList,
    default: stepList,
  });

  const inquiryTypeSpecificPaths = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: {
      [LEGAL_REPRESENTATIVE_DETAILS]: routePaths.peopleDetails,
      [BENEFICIARY_OWNER_DETAILS]: routePaths.peopleDetails,
    },
    default: {},
  });

  const getPathToStepFunc = (stepName: string) => {
    const path = getPathToStep(paths, stepsToUse.indexOf(stepName));
    if (path === null && inquiryTypeSpecificPaths[stepName]) {
      return inquiryTypeSpecificPaths[stepName];
    }
    return path;
  };

  return {
    pathToNextStep: getPathToNextStep(paths, currentStepIndex),
    pathToPreviousStep: getPathToPreviousStep(paths, currentStepIndex),
    getPathToStep: getPathToStepFunc,
    cancelPath: getPathToCancel({ isAgent, isCustomer }, inquiryId),
  };
};
