import { ValidationResult, ValidationErrorItem, Schema } from '@hapi/joi';

import {
  beneficiaryOwnerFieldValidations,
  bfsServiceBeneficiaryOwnerFieldValidations,
} from 'modules/Inquiry/inquiryFieldValidation/beneficiaryDetails.fieldValidationMap';
import { companyDetailsFieldValidations } from 'modules/Inquiry/inquiryFieldValidation/companyDetails.fieldValidationMap';
import {
  bfsFinancingNeedFieldValidations,
  bfsServiceFinancingNeedFieldValidations,
  financingNeedFieldValidations,
} from 'modules/Inquiry/inquiryFieldValidation/financingNeed.fieldValidationMap';
import { peopleDetailsFieldValidations } from 'modules/Inquiry/inquiryFieldValidation/peopleDetails.fieldValidationMap';
import {
  bfsServiceRequestDetailsFieldValidations,
  contractAndOfferRequestDetailsFieldValidations,
  requestDetailsFieldValidations,
} from 'modules/Inquiry/inquiryFieldValidation/requestDetails.fieldValidationMap';
import { userProfileFieldValidations } from 'modules/Inquiry/inquiryFieldValidation/userProfile.fieldValidationMap';
import { purposeSectionFieldValidations } from 'onlinefactoring/purpose/fieldValidations';

import { mittweidaCompanyDetailsFieldValidation } from '../../../mittweida/inquiry/steps/company/sections/validations';
import { mittweidaFinancingNeedFieldValidation } from '../../../mittweida/inquiry/steps/financingNeed/mittweidaFinancingNeedFieldValidation';
import { mittweidaFundingDetailsFieldValidation } from '../../../mittweida/inquiry/steps/fundingDetails/sections/validations';
import { mittweidaContactPersonFieldValidation } from '../../../mittweida/inquiry/steps/userProfile/sections/validations';
import { mittweidaEditModeFieldValidation } from '../../../mittweida/portals/operations/components/validations';
import {
  extractNestedFieldName,
  removeBracketsFromArrayFields,
} from '../../../utils/form/getFieldName';
import { InquiryType } from '../Inquiry.type';
import { InquiryLane } from '../InquiryLane';
import {
  bfsCompanyDetailsFieldValidation,
  bfsContactPersonfieldValidation,
} from './bfsContactPerson.fieldValidationMap';
import { energyEfficiencyFieldValidations } from './energyEfficiency.fieldValidationMap';
import { kfinanzFieldValidations } from './kfinanz.fieldValidationMap';
import { profiProjectCostingEditMap } from './profiProjectCostingEdit.fieldValidationMap';

type FieldValidationMapType = Record<string, Schema | Function>;

export const fieldValidationMap: FieldValidationMapType = {
  ...financingNeedFieldValidations,
  ...companyDetailsFieldValidations,
  ...requestDetailsFieldValidations,
  ...userProfileFieldValidations,
  ...peopleDetailsFieldValidations,
  ...energyEfficiencyFieldValidations,
  ...beneficiaryOwnerFieldValidations,
  ...profiProjectCostingEditMap,
};

const contractFieldValidationMap: FieldValidationMapType = {
  ...fieldValidationMap,
  ...contractAndOfferRequestDetailsFieldValidations,
};

const offerFieldValidationMap: FieldValidationMapType = {
  ...fieldValidationMap,
  ...contractAndOfferRequestDetailsFieldValidations,
};

const onlinefactoringFieldValidationMap: FieldValidationMapType = {
  ...purposeSectionFieldValidations,
  ...companyDetailsFieldValidations,
  ...userProfileFieldValidations,
  ...kfinanzFieldValidations,
  ...peopleDetailsFieldValidations,
};

const mittweidaFieldValidationMap: FieldValidationMapType = {
  ...mittweidaFinancingNeedFieldValidation,
  ...mittweidaFundingDetailsFieldValidation,
  ...mittweidaContactPersonFieldValidation,
  ...mittweidaCompanyDetailsFieldValidation,
  ...mittweidaEditModeFieldValidation,
};

const inquiryLaneSpecificValidationMap = {
  [InquiryLane.offer]: offerFieldValidationMap,
  [InquiryLane.contract]: contractFieldValidationMap,
  [InquiryLane.lead]: fieldValidationMap,
  default: fieldValidationMap,
};

const inquiryTypeSpecificMap: any = {
  [InquiryType.onlinefactoring]: onlinefactoringFieldValidationMap,
  [InquiryType.profiMittweida]: mittweidaFieldValidationMap,
  [InquiryType.bfs]: {
    ...fieldValidationMap,
    ...bfsFinancingNeedFieldValidations,
    ...bfsContactPersonfieldValidation,
    ...bfsCompanyDetailsFieldValidation,
  },
  [InquiryType.leaseplan]: {
    ...fieldValidationMap,
  },
  [InquiryType.bfsService]: {
    ...fieldValidationMap,
    ...bfsServiceFinancingNeedFieldValidations,
    ...bfsServiceBeneficiaryOwnerFieldValidations,
    ...bfsServiceRequestDetailsFieldValidations,
  },
};

const getValidationType = (validationResult: ValidationResult): ValidationErrorItem | undefined =>
  validationResult?.error?.details[0];

export const getFieldValidator = (
  name: string,
  inquiryType?: InquiryType,
  inquiryLane?: InquiryLane,
) => {
  /*
   * For the old inquiry flow it is enough to extract the nested field name for field arrays.
   * This is how a nested field name looks like: 'project-financing-object-usages-future[0].type-of-use-future'
   * but the label of the field is just 'type-of-use-future
   */
  const oldNamingScheme = extractNestedFieldName(name);

  /*
   * For the new inquiry flow the fieldName contains the parent of the field name but should not use the indexed
   * bracket notation that is used for array fields. This is how a nest field name looks like in the new flow:
   * 'financingNeedPage.objectInformationSection.futureUsage[0].typeOfUse' but the actual label here is:
   * 'financingNeedPage.objectInformationSection.futureUsage.typeOfUse'
   */

  const newFieldNamingScheme = removeBracketsFromArrayFields(name);

  /*
   * If we do not find a label with the new field name schema we look into the labels map with the old naming schema.
   * One of this should always be successful or else there is another error why a label is not found.
   */
  let validationMap = inquiryLaneSpecificValidationMap.default;
  if (inquiryLane) {
    validationMap = inquiryLaneSpecificValidationMap[inquiryLane];
  }

  if (inquiryType && inquiryTypeSpecificMap[inquiryType]) {
    validationMap = inquiryTypeSpecificMap[inquiryType];
  }

  /*
   * If we do not find a label with the new field name schema we look into the labels map with the old naming schema.
   * One of this should always be successful or else there is another error why a label is not found.
   */
  const fieldValidator: Function | Schema | undefined =
    validationMap[newFieldNamingScheme] || validationMap[oldNamingScheme];

  if (!fieldValidator) {
    return () => undefined;
  }

  if (fieldValidator && typeof fieldValidator === 'function') {
    return (fieldValue: any, fieldValues: any) =>
      getValidationType(fieldValidator(fieldValue, fieldValues).validate(fieldValue));
  }

  // return validation type so it can be translated later in UI
  return (fieldValue: any, fieldValues: any) => {
    return getValidationType(fieldValidator.validate(fieldValue, fieldValues));
  };
};
