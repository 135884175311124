import {
  ICompactReport,
  ITrafficLightReport,
  IShortReport,
  IRatingReport,
  ReportType,
  Report,
  ICompanyBaseData,
  IBlackWhiteReport,
} from 'models/CompanyDetails.model';
import { IState } from 'store';

export const getCompanyDetails = (state: IState): ICompanyBaseData => state.companyDetails.baseData;
export const getCompanyAssociatedPersonsSelector = (state: IState) =>
  state.companyDetails.associatedPersons;
export const getCompanyRiskAnalysis = (state: IState) => state.companyDetails.riskAnalysis;
export const getTrafficLightReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.trafficLight,
  ) as unknown as ITrafficLightReport;

export const getShortReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestScoringShort,
  ) as unknown as IShortReport;

export const getCompactReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestScoringCompact,
  ) as unknown as ICompactReport;

export const getRatingReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestRating,
  ) as unknown as IRatingReport;

export const getBlackAndWhiteReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.blackAndWhite,
  ) as unknown as IBlackWhiteReport;

export const getCompanyInquiries = (state: IState) => state.companyDetails.inquiries;
