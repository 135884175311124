import * as React from 'react';

import { FeatureIcon } from '../../../../../../../components/FeatureIcon/FeatureIcon';
import { SelectOption } from '../../../../../../../components/Selects/Select/StepsDropdown/types';
import { translations } from '../../../../../../../new/form/common/types';
import { FormatMessage } from '../../../../../../../utils/intl';
import { mittweidaFields, RoleOptions } from '../../../../../fields';

type RoleSelectOptions = Array<SelectOption<RoleOptions> & { icon: React.ReactNode }>;

const { stockholder, developer, partition } =
  translations.inquiryType.mittweida.pages.financingNeed.sections.role.fields.projectFinancingRole
    .options;

export const roleSelectOptions: RoleSelectOptions = [
  {
    value: 'stockholder',
    label: stockholder,
    icon: (
      <FeatureIcon
        feature={mittweidaFields.financingNeedPage.roleSection.projectFinancingRole}
        value={'stockholder'}
      />
    ),
  },
  {
    value: 'developer',
    label: developer,
    icon: (
      <FeatureIcon
        feature={mittweidaFields.financingNeedPage.roleSection.projectFinancingRole}
        value={'developer'}
      />
    ),
  },
  {
    value: 'partition',
    label: partition,
    icon: (
      <FeatureIcon
        feature={mittweidaFields.financingNeedPage.roleSection.projectFinancingRole}
        value={'partition'}
      />
    ),
  },
];

export const getMittweidaRoleSelectOptions = (
  formatMessage: FormatMessage,
): SelectOption<RoleOptions>[] => {
  return roleSelectOptions.map(({ value, label }) => {
    return {
      value,
      label: formatMessage({ id: label }),
    };
  });
};
