import { parse } from 'date-fns';
import moment from 'moment';
import 'moment/locale/de';

export const futureMonths = moment.utc().startOf('month').toDate();

const isValidDate = (pseudoDate) => pseudoDate && !window.isNaN(pseudoDate);

export const formatDateBE = (dateObj) => dateObj && moment(dateObj).format('YYYY-MM-DD');

export const formatDateDays = (dateObj) => moment(dateObj).format('DD.MM.YYYY');

export const formatDateMonth = (dateObj) => moment(dateObj).format('MM.YYYY');

export const formatDateTime = (dateObj) => moment(dateObj).format('HH:mm [Uhr]');

export const formatDateWithTime = (dateObj) =>
  `${formatDateDays(dateObj)} ${formatDateTime(dateObj)}`;

export const parseMonthYear = (monthYearString) => parse(monthYearString, 'MM.yyyy', new Date());

export const formatMonthWithYear = (monthAndYearOrIso) => {
  const date = new Date(monthAndYearOrIso);

  if (isValidDate(date)) {
    // is ISO date
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    return `${month}.${date.getUTCFullYear()}`;
  }
  // is formatted month.year
  return monthAndYearOrIso;
};

export const formatTimeToNow = (dateObj) => moment(dateObj).locale('de').toNow(true);

export const formatYearMonthDay = (dateObj) => moment(dateObj).format('YYMMDD');
