import React from 'react';

import { InputWithField } from 'components/Input';
import {
  HIRE_PURCHASE_TERM_IN_MONTHS,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__VEHICLE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import DurationInMonthYearDropDown from '../../shared/DurationInMonthYearDropDown/DurationInMonthYearDropDown';
import { PurposeKindSpecificValidationMap } from '../../shared/types';

const HirePurchaseTermInMonths = () => {
  return <InputWithField name={HIRE_PURCHASE_TERM_IN_MONTHS} type="number" />;
};

export const purposeKindSpecificValidationMap: PurposeKindSpecificValidationMap = {
  [PURPOSE_KIND__VEHICLE]: {
    min: 2 * 12,
    max: 7 * 12,
  },
  [PURPOSE_KIND__MACHINE]: {
    min: 2 * 12,
    max: 8 * 12,
  },
  [PURPOSE_KIND__OTHER]: {
    min: 2 * 12,
    max: 7 * 12,
  },
};

const DvagHirePurchaseTermInMonths = () => {
  return (
    <DurationInMonthYearDropDown
      SpecificValidationMap={purposeKindSpecificValidationMap}
      fieldName={HIRE_PURCHASE_TERM_IN_MONTHS}
      saveValueInFieldName={HIRE_PURCHASE_TERM_IN_MONTHS}
    />
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagHirePurchaseTermInMonths,
  default: HirePurchaseTermInMonths,
});
