import { FONT_WEIGHT_MEDIUM } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const Badge = {
  baseStyle: {
    ...textStyle.sizeM,
    textTransform: 'none',
    fontWeight: FONT_WEIGHT_MEDIUM,
    paddingX: '2',
    paddingY: '1',
    border: '1px',
    borderRadius: 'base',
    color: 'text.secondary',
    backgroundColor: 'background.darkGrey',
  },

  variants: {
    info: {
      backgroundColor: 'status.info_L93',
      borderColor: 'status.info',
    },
    success: {
      backgroundColor: 'status.success_L93',
      borderColor: 'status.success',
    },
    warning: {
      backgroundColor: 'status.warning_L93',
      borderColor: 'status.warning',
    },
    error: {
      backgroundColor: 'status.error_L93',
      borderColor: 'status.error',
    },
  },
};

export default Badge;
