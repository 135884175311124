import { objectInformationSectionValidationMap } from './sections/objectInformationSection/validations';
import { otherProjectInformationSectionValidationMap } from './sections/otherProjectInformationSection/validations';
import { projectCompositionSectionValidationMap } from './sections/projectCompositionSection/validation';
import { roleSectionValidationMap } from './sections/roleSection/validation';

export const mittweidaFinancingNeedFieldValidation = {
  ...roleSectionValidationMap,
  ...projectCompositionSectionValidationMap,
  ...objectInformationSectionValidationMap,
  ...otherProjectInformationSectionValidationMap,
};
