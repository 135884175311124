import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { MACHINE_INTERESTED_IN_INSURANCE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const MachineInterestedInInsurance = () => {
  return <YesNoRadioGroupWithField name={MACHINE_INTERESTED_IN_INSURANCE} />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => null,
  default: MachineInterestedInInsurance,
});
