import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
// If needed in future we can modify steps for partner based on business needs
import {
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  SUMMARY,
  FINAL_PAGE,
  PEOPLE_DETAILS,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  LEASEPLAN_FINAL_PAGE,
  CONTACT_PERSON_DETAILS,
  BENEFICIARY_OWNER_DETAILS,
  LEGAL_REPRESENTATIVE_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import {
  IInquiryPath,
  getStepSegmentByInquiryType,
} from 'modules/Inquiry/inquiryNavigation/stepSegments';

import { mittweidaSteps } from '../../../mittweida/inquiry/steps';
import { InquiryType } from '../Inquiry.type';

const defaultStepsWithForm: string[] = [
  FINANCING_NEED,
  REQUEST_DETAILS,
  USER_PROFILE,
  COMPANY_DETAILS,
];

const defaultInquirySteps: string[] = [...defaultStepsWithForm, SUMMARY, FINAL_PAGE];

const inquiryTypeSpecificStepsWithForms = {
  [InquiryType.bigPicture]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.onlinefactoring]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.profiMittweida]: [
    mittweidaSteps.financingNeedPage,
    mittweidaSteps.companyDetailPage,
    mittweidaSteps.userProfilePage,
    mittweidaSteps.fundingDetailsPage,
  ],

  [InquiryType.default]: defaultStepsWithForm,
  [InquiryType.mmv]: defaultStepsWithForm,

  [InquiryType.profiRLL]: [
    FINANCING_NEED,
    REQUEST_DETAILS,
    USER_PROFILE,
    CONTACT_PERSON_DETAILS,
    COMPANY_DETAILS,
  ],

  [InquiryType.leaseplan]: [CONTRACT_DETAILS, CUSTOMER_DATA],
  [InquiryType.bfs]: [FINANCING_NEED, REQUEST_DETAILS, CONTACT_PERSON_DETAILS, COMPANY_DETAILS],
  [InquiryType.dvag]: [FINANCING_NEED, REQUEST_DETAILS, COMPANY_DETAILS, USER_PROFILE],
  [InquiryType.bfsService]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
};

const inquiryTypeSpecificSummarySteps = {
  ...inquiryTypeSpecificStepsWithForms,
  [InquiryType.onlinefactoring]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.bfsService]: [
    FINANCING_NEED,
    COMPANY_DETAILS,
    REQUEST_DETAILS,
    PEOPLE_DETAILS,
    LEGAL_REPRESENTATIVE_DETAILS,
    BENEFICIARY_OWNER_DETAILS,
  ],
};

const inquiryTypeSpecificSteps = {
  [InquiryType.bigPicture]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.bigPicture],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.onlinefactoring]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.onlinefactoring],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.default]: defaultInquirySteps,
  [InquiryType.profiRLL]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.profiRLL],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.profiMittweida]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.profiMittweida],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.leaseplan]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.leaseplan],
    LEASEPLAN_FINAL_PAGE,
  ],
  [InquiryType.bfs]: [...inquiryTypeSpecificStepsWithForms[InquiryType.bfs], SUMMARY, FINAL_PAGE],
  [InquiryType.dvag]: [
    FINANCING_NEED,
    REQUEST_DETAILS,
    COMPANY_DETAILS,
    USER_PROFILE,
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.bfsService]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.bfsService],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.mmv]: [SUMMARY, FINAL_PAGE],
};

const getStepsForInquiryType = (type: InquiryType, partner: string) => {
  switch (partner) {
    case PARTNERS.DVAG:
      return {
        steps: [
          FINANCING_NEED,
          REQUEST_DETAILS,
          COMPANY_DETAILS,
          USER_PROFILE,
          SUMMARY,
          FINAL_PAGE,
        ],
        stepsWithForms: [FINANCING_NEED, REQUEST_DETAILS, COMPANY_DETAILS, USER_PROFILE],
        stepsForSummary: inquiryTypeSpecificSummarySteps[type] || defaultStepsWithForm,
      };
    default:
      return {
        steps: inquiryTypeSpecificSteps[type]
          ? inquiryTypeSpecificSteps[type]
          : defaultInquirySteps,
        stepsWithForms: inquiryTypeSpecificStepsWithForms[type] || defaultStepsWithForm,
        stepsForSummary: inquiryTypeSpecificSummarySteps[type] || defaultStepsWithForm,
      };
  }
};

export const useInquiryStepData = () => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const { selectedInquiryType } = useFormConfig();
  const stepsForPartner = getStepsForInquiryType(selectedInquiryType, partnerName!);

  const transformPathsToSegments = (paths: string[]): IInquiryPath[] => {
    return paths
      .map((stepName) => getStepSegmentByInquiryType(selectedInquiryType)[stepName])
      .filter(Boolean);
  };

  return {
    stepsWithForm: stepsForPartner.stepsWithForms,
    stepList: stepsForPartner.steps,
    stepsForSummary: stepsForPartner.stepsForSummary,
    stepSegments: transformPathsToSegments(stepsForPartner.steps),
  };
};
