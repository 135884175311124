import React from 'react';

import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import intlShape from 'shared/intlShape';
import { withIntl } from 'utils/intl';

const EndUserSection = ({ intl }) => {
  const sectionTitle = intl.formatMessage({ id: 'pages.inquiryDetails.detailed.endUserDetails' });
  const fields = useInquiryDetailsFields({ sectionId: INQUIRY_SECTIONS.END_USER });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={sectionTitle}>
      <InformationRows fields={fields} />
    </Section>
  );
};

EndUserSection.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(EndUserSection);
