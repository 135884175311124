import React from 'react';

import { useSelector } from 'react-redux';

import CustomerNavigation from 'modules/Header/PortalNavigation/CustomerNavigation';
import OperationNavigation from 'modules/Header/PortalNavigation/OperationNavigation';
import { isAgent as isAgentSelector } from 'store/user/selectors';

const PortalNavigation = () => {
  const isAgent = useSelector(isAgentSelector);
  if (isAgent) {
    return <OperationNavigation />;
  }
  return <CustomerNavigation />;
};

export default PortalNavigation;
