import { useEffect, useState } from 'react';

import { createPersistDecorator } from 'final-form-persist';
import _merge from 'lodash/merge';
import { useSelector } from 'react-redux';

import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import { getNewInquiryDetails } from 'store/newInquiryDetails/selector';

export const FORM_VALUES_STORAGE_KEY = 'newFormValues';

export const { persistDecorator, clear } = createPersistDecorator({
  name: FORM_VALUES_STORAGE_KEY,
  debounceTime: 50,
  storage: sessionStorage,
});

export function useGetInitialValuesForCreateMode(): any | null {
  const values = sessionStorage.getItem(FORM_VALUES_STORAGE_KEY);
  if (values) {
    return JSON.parse(values);
  }
  return null;
}

type GetInitialValuesForEditModeParams = {
  inquiryDetails: any;
  initialValues: any;
};

/**
 * The inquiryDetails we receive from the backend might not contain all the values that we need to start the inquiry
 * process, so we are deepMerging with the initialValues to make sure that the structure of the form values is
 * correct, and we do not run into any errors. We are also cloning the initialValues to not change the initialValues
 * object.
 */
function getInitialValuesForEditMode({
  inquiryDetails,
  initialValues,
}: GetInitialValuesForEditModeParams) {
  return _merge(JSON.parse(JSON.stringify(initialValues)), inquiryDetails);
}

export function useGetInitialValuesForEditMode() {
  const inquiryDetails = useSelector(getNewInquiryDetails);
  const {
    formStateData: { initialFormValues },
  } = useInquiryProcessConfig();
  const [initialValues, setInitialValues] = useState(initialFormValues);

  useEffect(() => {
    const initialValues = getInitialValuesForEditMode({
      inquiryDetails,
      initialValues: initialFormValues,
    });
    setInitialValues(initialValues);
  }, [inquiryDetails, initialFormValues]);

  return initialValues;
}
