import { PROGRESS_STATE_ITEM_KEY } from 'constants/globalConstants';
import {
  COMPANY_DETAILS,
  COMPEON_PARTNER,
  FINANCING_NEED,
  REQUEST_DETAILS,
  USER_PROFILE,
  USER_PROFILE_COMPANY_CREFO_ID,
  CORONA_STEP,
  CONTACT_PERSON_STEP,
  PEOPLE_DETAILS,
  CONTACT_PERSON_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import { PURPOSE_STEP } from 'onlinefactoring/formFields';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { retrieveObject } from 'utils/sessionStorage/helpers';
import {
  FINAL_PAGE_STATE,
  FORM_VALIDITY,
  FORM_VALUES,
  INSTALMENT_PROCESS,
} from 'utils/sessionStorage/keys';

export const SESSION_STORAGE_KEYS = [
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  CORONA_STEP,
  CONTACT_PERSON_STEP,
  PEOPLE_DETAILS,
  PURPOSE_STEP,
  INSTALMENT_PROCESS,
  FORM_VALUES,
  FORM_VALIDITY,
  FINAL_PAGE_STATE,
  CONTACT_PERSON_DETAILS,
];

export const getRawFormDataFromSessionStorage = () =>
  SESSION_STORAGE_KEYS.reduce(
    (result, section) => ({ ...result, [section]: retrieveObject(section) }),
    {},
  );

const getFlatFormData = (initialFormData) => {
  const fieldController = FormFieldController.instance;
  const data = fieldController.formValues;

  return {
    ...initialFormData,
    ...data,
  };
};

export const getFormDataFromStorage = (partnerShortName) => {
  const initialFormData = {
    [COMPEON_PARTNER]: partnerShortName,
    [USER_PROFILE_COMPANY_CREFO_ID]: 'not-found',
  };

  return getFlatFormData(initialFormData);
};

export const clearFormData = () =>
  SESSION_STORAGE_KEYS.map((key) => sessionStorage.removeItem(key));

export const clearFormProgress = () => {
  sessionStorage.removeItem(PROGRESS_STATE_ITEM_KEY);
};
