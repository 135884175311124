import React from 'react';

import { useHistory } from 'react-router-dom';

import Page from 'components/Page/Page';
import PageHeading from 'components/PageHeading';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const CreateInquiryNotAllowed = () => {
  const history = useHistory();
  const t = useTranslations('pages.errorPage');
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = InquiryType.profiRLL === selectedInquiryType;
  const isBfsService = InquiryType.bfsService === selectedInquiryType;

  function redirectToCustomerLoginPage() {
    history.push(paths.customer.login);
  }

  return (
    <Page>
      <PageHeading
        error
        heading={
          isProfiInquiry
            ? t('agentNotAllowedToInquiryCreation.title')
            : t('notAllowedToInquiry.title')
        }
      />
      {!isProfiInquiry && !isBfsService && (
        <ButtonComponent d="flex" m="0 auto" onClick={redirectToCustomerLoginPage}>
          {t('notAllowedToInquiry.logInAsCustomerBtn')}
        </ButtonComponent>
      )}
    </Page>
  );
};

export default CreateInquiryNotAllowed;
