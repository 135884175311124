import _get from 'lodash/get';

import {
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_EXTRA_MARKDOWN,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapLendingValuesFromApi = (response: unknown) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const lendingValues = {
    [PROFITABILITY_SAFETY_MARKDOWN]:
      _get(response, 'profitability_safety_markdown') || initialValue,
    [PROFITABILITY_EXTRA_MARKDOWN]: _get(response, 'profitability_extra_markdown') || initialValue,
  };

  return lendingValues;
};

export default mapLendingValuesFromApi;
