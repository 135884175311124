import { useEffect, useCallback, useState } from 'react';

import { mapKeys } from 'lodash';
import { useSelector } from 'react-redux';

import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  loadCustomerCompaniesAction,
  loadCompaniesAction,
} from 'pages/inquiryFlow/CompanyDetails/service';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { isCallCenterAgent, isInquiryManager } from 'store/user/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const kebabcaseKeys = (dict: object) => mapKeys(dict, (_val, key) => key.replace(/_/g, '-'));

export const useLoadCompanies = () => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const hasCallCenterAgentRole = useSelector(isCallCenterAgent);
  const hasInquiryManagerRole = useSelector(isInquiryManager);
  const isAgent = hasCallCenterAgentRole || hasInquiryManagerRole;
  const { makeCall, isPending } = useDispatchApiCall({
    isPendingInitially: true,
    errorMessage: '',
    showErrorNotification: true,
  });

  const [companies, setCompanies] = useState([]);

  const loadCompanies = useCallback(async () => {
    let request;
    if (isAgent) {
      if (!inquiryDetails || !inquiryDetails.customer || !inquiryDetails.customer.id) {
        return { isFetching: false, companies: [] };
      }

      request = makeCall(loadCustomerCompaniesAction(inquiryDetails.customer.id));
    } else {
      request = makeCall(loadCompaniesAction());
    }
    const response = await request;
    const payload = response.payload.data.data;
    return setCompanies(
      payload.map((company: any) => ({
        ...company,
        attributes: kebabcaseKeys(company.attributes),
      })),
    );
  }, [inquiryDetails, makeCall, isAgent]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return { isFetching: isPending, companies, isAgent };
};
