import React, { useEffect, useState } from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import getProcessLogAction from 'api/CompeonReverseApi/operation/processLog/actions';
import Spinner from 'components/Spinner';
import PERMISSIONS from 'constants/user/permissions';
import { ProcessLog } from 'models/ProcessLog.model';
import mapFromApiInterface from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/mapActivityFromApi';
import ProcessLogItem from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/ProcessLogItem';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledHeading } from '../../styles';

const ProcessLogList = () => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const [processLogEntries, setProcessLogEntries] = useState<ProcessLog[]>([]);
  const { makeCall, isPending: isLoading } = useDispatchApiCall();

  const t = useTranslations('pages.inquiryDetails.dashboard.communication.processLog');

  useEffect(() => {
    function getProcessLogData() {
      makeCall(getProcessLogAction(String(inquiryId)), ({ payload }: any) => {
        setProcessLogEntries(mapFromApiInterface(_get(payload, 'data')));
      });
    }
    getProcessLogData();
  }, [inquiryId, makeCall]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <StyledHeading data-testid={'Comment'}>{t('title')}</StyledHeading>

      {processLogEntries.length
        ? processLogEntries.map((processLog: ProcessLog) => (
            <ProcessLogItem key={processLog.id} log={processLog} />
          ))
        : t('noEntries')}
    </div>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.COMMUNICATION.PROCESS_LOG),
)(ProcessLogList);
