import React from 'react';

import { useSelector } from 'react-redux';

import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { getBeneficiaryOwners } from 'store/inquiryDetails/selectors';
import useCountriesData from 'utils/hooks/useCountriesData/useCountriesData';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../DetailedInformationSection';
import InformationRows, { InformationField } from '../InformationRows/InformationRows';
import { useInquiryValueParserForDisplay } from '../useInquiryDetailsFields';

interface IMappedBeneficiaryOwner {
  [BENEFICIARY_FIRST_NAME]: string;
  [BENEFICIARY_LAST_NAME]: string;
  [BENEFICIARY_GENDER]: string;
  [BENEFICIARY_BIRTHDAY]: string;
  [BENEFICIARY_COMPANY_SHARE]: string;
  [BENEFICIARY_BIRTH_COUNTRY]: string;
  [BENEFICIARY_TAX_ID]?: string;
  [BENEFICIARY_PRIVATE_ADDRESS]: string;
  [BENEFICIARY_PRIVATE_CITY]: string;
  [BENEFICIARY_PRIVATE_COUNTRY]: string;
  [BENEFICIARY_ZIP_CODE]: string;
}

const BeneficiaryOwnerSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');

  const beneficiaryOwners = useSelector(getBeneficiaryOwners);

  const countries = useCountriesData();

  const countryFormatter = (value: string) =>
    countries.find((country) => country.value === value)?.label || value;

  const labelTranslator = useInquiryLabelTranslator();
  const formatValue = useInquiryValueParserForDisplay();

  if (!beneficiaryOwners || beneficiaryOwners.length < 1) {
    return null;
  }

  const fieldsToShow = beneficiaryOwners.map((beneficiaryOwner: IMappedBeneficiaryOwner) => {
    // format country
    beneficiaryOwner[BENEFICIARY_PRIVATE_COUNTRY] = countryFormatter(
      beneficiaryOwner[BENEFICIARY_PRIVATE_COUNTRY],
    );
    beneficiaryOwner[BENEFICIARY_BIRTH_COUNTRY] = countryFormatter(
      beneficiaryOwner[BENEFICIARY_BIRTH_COUNTRY],
    );

    return Object.entries(beneficiaryOwner)
      .filter(([, fieldValue]) => fieldValue != null)
      .map(([fieldKey, fieldValue]) => ({
        label: labelTranslator(fieldKey),
        value: formatValue(fieldValue, fieldKey),
        key: fieldKey,
      }));
  });

  return (
    <Section title={t('beneficiaryOwners')}>
      {fieldsToShow.map((fields: InformationField[]) => (
        <InformationRows fields={fields} />
      ))}
    </Section>
  );
};

export default BeneficiaryOwnerSection;
