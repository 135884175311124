import React from 'react';

import { Heading, Text, useMultiStyleConfig } from '@chakra-ui/react';

import { useTranslations } from 'utils/hooks/useTranslations';

interface ISectionHeader {
  subheadingTranslationKey?: string;
  leadTranslationKey?: string;
  stylesThemeKey: string;
}

const SectionHeader = ({
  subheadingTranslationKey,
  leadTranslationKey,
  stylesThemeKey,
}: ISectionHeader) => {
  const styles = useMultiStyleConfig(stylesThemeKey, {});
  const t = useTranslations();

  return (
    <>
      {subheadingTranslationKey ? (
        <Heading
          as="h3"
          // Using sx instead of __css because of higher priority
          sx={styles.title}
        >
          {t(subheadingTranslationKey)}
        </Heading>
      ) : null}
      {leadTranslationKey ? (
        <Text
          // Using sx instead of __css because of higher priority
          sx={styles.lead}
        >
          {t(leadTranslationKey)}
        </Text>
      ) : null}
    </>
  );
};

export default SectionHeader;
