import React from 'react';

import { Flex, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import paths from '../../constants/paths';
import ButtonComponent from '../../theme/components/Button';
import ArrowRightIcon from '../../theme/components/Icon/ArrowRightIcon';
import { translations } from './common/types';
import { useMode, useInquiryProcessConfig } from './state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from './state/pageStateReducer';

export function useChangeStep() {
  const {
    state: { currentStep, inquiryMode, pageValidations },
    dispatch,
    formStateData: { stepData, routeToPageNameMap, summaryPath: inquirySummaryPath },
  } = useInquiryProcessConfig();
  const history = useHistory();
  const id = useSelector(getInquiryIdSelector);

  const isStepValid = pageValidations[currentStep as any].progress.isSectionValid;
  const hasPreviousStep = !!stepData[currentStep as any].previousStep;

  const navigateToPath = (path: string) => {
    if (id && (inquiryMode === 'editOperation' || inquiryMode === 'editCustomer')) {
      history.push(path.replace(':id', id));
    } else {
      history.push(path);
    }
  };

  const moveToNextStep = () => {
    const nextStep = stepData[currentStep as any].nextStep;

    if (nextStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: nextStep });
      const nextPath = routeToPageNameMap[nextStep as any][inquiryMode];
      navigateToPath(nextPath);
    } else {
      const summaryPath = inquirySummaryPath[inquiryMode];
      navigateToPath(summaryPath);
    }
  };

  const moveToPreviousStep = () => {
    const previousStep = stepData[currentStep as any].previousStep;

    if (previousStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: previousStep });
      const previousPath = routeToPageNameMap[previousStep as any][inquiryMode];
      navigateToPath(previousPath);
    }
  };

  return {
    isStepValid,
    moveToNextStep,
    hasPreviousStep,
    moveToPreviousStep,
  };
}

type StepActionProps = {
  children: React.ReactNode;
};

export const StepActions = ({ children }: StepActionProps) => {
  return (
    <VStack w={'full'} alignItems={'flex-end'} gap={4}>
      {children}
      <CancelEditButton />
    </VStack>
  );
};

const CancelEditButton = () => {
  const mode = useMode();
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();

  if (mode === 'create') {
    return null;
  }

  const path =
    mode === 'editOperation'
      ? paths.operation.inquiryDetails.details
      : paths.customer.inquiryDetails.details;

  return (
    <ButtonComponent
      variant={'secondary'}
      onClick={() => {
        history.push(path.replace(':id', inquiryId!!));
      }}
    >
      {t(translations.buttons.cancelEdit)}
    </ButtonComponent>
  );
};

export const ChangeStep = () => {
  const { isStepValid, moveToNextStep, hasPreviousStep, moveToPreviousStep } = useChangeStep();
  const t = useTranslations();

  return (
    <StepActions>
      <Flex w={'full'} justifyContent={'flex-end'} gap={4} mt={8}>
        {hasPreviousStep && (
          <ButtonComponent
            onClick={moveToPreviousStep}
            leftIcon={<ArrowLeftIcon boxSize={6} />}
            variant={'tertiary'}
          >
            {t(translations.buttons.back)}
          </ButtonComponent>
        )}
        <ButtonComponent
          disabled={!isStepValid}
          onClick={moveToNextStep}
          leftIcon={<ArrowRightIcon boxSize={6} />}
        >
          {t(translations.buttons.continue)}
        </ButtonComponent>
      </Flex>
    </StepActions>
  );
};
