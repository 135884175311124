import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  PROFITABILITY_EXTRA_MARKDOWN,
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_USUAL_SALES_COST_RATE,
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_BUFFER,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
  PROFITABILITY_NUMBER_OF_GARAGES,
  PROFITABILITY_NUMBER_OF_PARKING_LOTS,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
} from 'modules/Inquiry/Form/formFields';

import { PROFITABILITY_RECORD_TYPE } from '../SharedComponent/constants';

const mapLendingValueToEdit = () => {
  const sections = [
    {
      title:
        'pages.editProfitabilityCalculations.lendingValueDetermination.sections.parameterVolume.title',
      fields: [PROFITABILITY_SAFETY_MARKDOWN, PROFITABILITY_EXTRA_MARKDOWN],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.lendingValueDetermination.heading',
      subHeading: 'pages.editProfitabilityCalculations.lendingValueDetermination.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapFinancingParametersToEdit = () => {
  const sections = [
    {
      title: 'pages.editProfitabilityCalculations.financingParameters.sections.asBuilding.title',
      fields: [
        PROFITABILITY_FINANCING_FEE_BUILDING,
        PROFITABILITY_FINANCING_DURATION_BUILDING,
        PROFITABILITY_INTEREST_RATE_BUILDING,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.financingParameters.sections.asUnit.title',
      fields: [
        PROFITABILITY_FINANCING_FEE_UNIT,
        PROFITABILITY_FINANCING_DURATION_UNIT,
        PROFITABILITY_INTEREST_RATE_UNIT,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.financingParameters.sections.asRent.title',
      fields: [
        PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
        PROFITABILITY_FINANCING_DURATION_RENT,
        PROFITABILITY_INTEREST_RATE_RENT,
        PROFITABILITY_INTEREST_RATE_RENT_SHORT,
        PROFITABILITY_REPAYMENT_RATE_RENT,
      ],
    },
  ];
  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.financingParameters.heading',
      subHeading: 'pages.editProfitabilityCalculations.financingParameters.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapSalesCostRateToEdit = () => {
  const sections = [
    {
      title: 'pages.editProfitabilityCalculations.salesCostRate.sections.usualSalesCostRate.title',
      fields: [PROFITABILITY_USUAL_SALES_COST_RATE],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.salesCostRate.heading',
      subHeading: 'pages.editProfitabilityCalculations.salesCostRate.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapInvestmentCostsToEdit = () => {
  const sections = [
    {
      title: 'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.title',
      fields: [
        PROFITABILITY_PURCHASE_PRICE,
        PROFITABILITY_LEGAL_FEES,
        PROFITABILITY_AGENT_FEES,
        PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
        PROFITABILITY_MEASUREMENT_COSTS,
        PROFITABILITY_OTHER_FEES,
        PROFITABILITY_OTHER_PURCHASE_COSTS,
        PROFITABILITY_OTHER_COSTS,
        PROFITABILITY_COMPENSATIONS,
        PROFITABILITY_LAND_DEVELOPMENT_COSTS,
        PROFITABILITY_PUBLIC_DISPOSAL,
        PROFITABILITY_NON_PUBLIC_DISPOSAL,
        PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.investmentCosts.sections.buildingCosts.title',
      fields: [
        PROFITABILITY_CONSTRUCTION_COSTS,
        PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
        PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
      ],
    },
    {
      title:
        'pages.editProfitabilityCalculations.investmentCosts.sections.ancillaryConstructionCosts.title',
      fields: [PROFITABILITY_ARCHITECT_COSTS, PROFITABILITY_ADDITIONAL_COSTS],
    },
    {
      title: 'pages.editProfitabilityCalculations.investmentCosts.sections.financingCosts.title',
      fields: [
        PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
        PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
        PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.investmentCosts.sections.otherCosts.title',
      fields: [PROFITABILITY_BUFFER, PROFITABILITY_MARKETING_SALES_COSTS],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.investmentCosts.heading',
      subHeading: 'pages.editProfitabilityCalculations.investmentCosts.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapPresalesDataToEdit = () => {
  const sections = [
    {
      title: 'pages.editProfitabilityCalculations.presalesData.sections.presaleResidential.title',
      fields: [
        PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
        PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.presalesData.sections.presaleCommercial.title',
      fields: [
        PROFITABILITY_PRESALES_AREA_COMMERCIAL,
        PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.presalesData.sections.presaleParkingLots.title',
      fields: [
        PROFITABILITY_NUMBER_OF_PARKING_LOTS,
        PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
      ],
    },
    {
      title: 'pages.editProfitabilityCalculations.presalesData.sections.presaleGarages.title',
      fields: [PROFITABILITY_NUMBER_OF_GARAGES, PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.presalesData.heading',
      subHeading: 'pages.editProfitabilityCalculations.presalesData.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapRentalParametersToEdit = () => {
  const sections = [
    {
      title:
        'pages.editProfitabilityCalculations.rentalParameters.sections.calculationParameters.title',
      fields: [
        PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
        PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
        PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
        PROFITABILITY_TAX_RATE_IN_PERCENT,
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editProfitabilityCalculations.rentalParameters.heading',
      subHeading: 'pages.editProfitabilityCalculations.rentalParameters.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapProfitabilityToEdit: Record<string, any> = {
  [PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION]: mapLendingValueToEdit,
  [PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS]: mapFinancingParametersToEdit,
  [PROFITABILITY_RECORD_TYPE.SALES_COST_RATE]: mapSalesCostRateToEdit,
  [PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS]: mapInvestmentCostsToEdit,
  [PROFITABILITY_RECORD_TYPE.PRESALES_DATA]: mapPresalesDataToEdit,
  [PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS]: mapRentalParametersToEdit,
  [PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT]: mapSalesCostRateToEdit,
};

export default mapProfitabilityToEdit;
