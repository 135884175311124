import React from 'react';

import { useSelector } from 'react-redux';

import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import { getProfitabilityFinancingDetailsTable } from '../../store/selectors';
import type { MappingProps } from '../../types';
import { getRowStylesMap, useUnitFormatter } from '../../utils';

export interface FinancingDetailsProps {
  fee_rate: string;
  fee_duration: number;
  interest_rate: string;
  repayment_rate: string;
  financing_amount: string;
  financing_duration: number;
  calculated_debt_service: string;
  notes_on_financing_parameters: string;
}

const mapping: MappingProps<FinancingDetailsProps> = [
  { key: 'financing_amount', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'interest_rate', unit: 'percent', styles: { bold: true, indented: true } },
  { key: 'repayment_rate', unit: 'percent', styles: { bold: true, indented: true } },
  { key: 'fee_rate', unit: 'percent', styles: { bold: true, indented: true } },
  { key: 'fee_duration', unit: 'months', styles: { bold: true, indented: true } },
  { key: 'financing_duration', unit: 'months', styles: { bold: true, indented: true } },
  { key: 'calculated_debt_service', unit: 'currency', styles: { bold: true, highlighted: true } },
];

const useGetFinancingDetailsTable = () => {
  const financingDetailsData = useSelector(getProfitabilityFinancingDetailsTable);

  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.financingDetails',
  );

  const formatter = useUnitFormatter();

  return usePortalTable({
    data: mapping.map(({ key, unit }) => ({
      id: key,
      label: t(`rows.${key}`),
      value: formatter[unit](financingDetailsData[key]),
    })),
    headers: [
      {
        key: 'label',
        text: t('headers.label'),
      },
      {
        key: 'value',
        text: t('headers.value'),
      },
    ],
  });
};

export const FinancingDetails = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.financingDetails',
  );
  const { financingDetails } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: financingDetails(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS,
    loadData: loadPlanningEvaluationProfitabilityData,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[
        MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS
      ](),
  });

  const financingDetailsTable = useGetFinancingDetailsTable();
  const rowStyles = getRowStylesMap(mapping);

  if (!financingDetailsTable) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Card title={t('title')} onEdit={onEdit}>
      <PortalTable {...financingDetailsTable} rowStylesMap={{ ...rowStyles }} />
    </Card>
  );
};
