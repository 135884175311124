import React, { useCallback, useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

import FormRow from 'components/FormRow';
import { InputWithField, INPUT_TYPE_DECICMAL_WITH_ZERO } from 'components/Input';
import { useFormConfig } from 'config/formConfig/hooks';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_ZIP_CODE,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_BIRTH_COUNTRY,
  IS_BENEFICIARY_OWNER,
  BENEFICIARY_OWNER,
  BENEFICIARY_GENDER,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS,
  EXISTING_BENEFICIARY,
  BENEFICIARY_TAX_ID,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import { BeneficiaryOwnersContext } from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwners/BeneficiaryOwners.context';
import {
  StyledPeopleSection,
  StyledPeopleInfo,
  StyledSinglePerson,
  StyledSinglePersonFields,
  StyledRemoveButton,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useFormValues } from 'store/hooks/useFormValues';
import { getCompanyData } from 'store/inquiryDetails/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { getUser } from 'store/user/selectors';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { PeopleDetailsMultiSelectOption } from '../../MultiSelectPeopleDetails';
import BeneficiaryBirthdate from '../fields/BeneficiaryBirthdate';
import BeneficiaryFirstName from '../fields/BeneficiaryFirstName';
import BeneficiaryGender from '../fields/BeneficiaryGender';
import BeneficiaryLastName from '../fields/BeneficiaryLastName';
import BeneficiaryTaxId from '../fields/BeneficiaryTaxId';
import CountriesComponent from '../fields/CountriesComponent/CountriesComponent';
import SelfBeneficiaryOwner from './SelfBeneficiaryOwner/SelfBeneficiaryOwner';

export type FieldValue = {
  [BENEFICIARY_GENDER]: string;
  [BENEFICIARY_FIRST_NAME]: string;
  [BENEFICIARY_LAST_NAME]: string;
  [BENEFICIARY_COMPANY_SHARE]: string;
  [BENEFICIARY_TAX_ID]?: string;
  [BENEFICIARY_PRIVATE_ADDRESS]: string;
  [BENEFICIARY_ZIP_CODE]: string;
  [BENEFICIARY_PRIVATE_CITY]: string;
  [BENEFICIARY_PRIVATE_COUNTRY]: string;
  [BENEFICIARY_BIRTH_COUNTRY]: string;
  [BENEFICIARY_BIRTHDAY]: string;
  id: string;
  isAssociatedPerson: boolean;
};

interface BeneficiaryOwnerMultiSelectOption extends PeopleDetailsMultiSelectOption {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: Date;
  address: string;
  taxId?: string;
  birthPlace: string;
  city: string;
  companyShare: string;
  country: string;
  zipCode: string;
  type: 'legal_representatives' | 'beneficiary_owners';
  email: string;
}

type SinglePersonProps = {
  onRemove: () => void;
  hideRemove: boolean;
};

const SinglePerson = ({ onRemove, hideRemove = false }: SinglePersonProps) => {
  const t = useTranslations('pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary');
  const { fieldName, userIndex } = useContext(BeneficiaryOwnersContext);
  const { fields } = useFieldArray<FieldValue>(BENEFICIARY_OWNER);
  const shouldBeDisabled = fields.value[userIndex]?.isAssociatedPerson;

  const handleRemove = () => {
    // This function wrapper ensures not to pass event as an argument to onRemove
    onRemove();
    // This is a workaround, the section fade-in/out animation is not working.
    // when there is a change in layout before scrolling to a specific section.
    // This case is happening in Bfss form (people details).
    // Create multiple beneficiaries goto previous page => came back and scroll till delete button and delete. Password section will not appear after that.
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        {!hideRemove && (
          <StyledRemoveButton onClick={handleRemove} aria-label={t('removePerson')}>
            <DeleteIcon boxSize={6} />
          </StyledRemoveButton>
        )}
        <FormRow>
          <BeneficiaryGender />
        </FormRow>
        <FormRow>
          <BeneficiaryFirstName />
          <BeneficiaryLastName />
        </FormRow>
        <FormRow>
          <InputWithField
            name={`${fieldName}.${BENEFICIARY_COMPANY_SHARE}`}
            type={INPUT_TYPE_DECICMAL_WITH_ZERO}
          />
        </FormRow>
        <BeneficiaryTaxId name={`${fieldName}.${BENEFICIARY_TAX_ID}`} />
        <FormRow>
          <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_ADDRESS}`} />
        </FormRow>
        <FormRow>
          <InputWithField
            name={`${fieldName}.${BENEFICIARY_ZIP_CODE}`}
            type="tel"
            pattern="[0-9]*"
            inputMaxLimit={MAX_ZIP_CODE_LENGTH}
            separateThousands={false}
          />
          <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_CITY}`} />
        </FormRow>
        <FormRow>
          <CountriesComponent name={`${fieldName}.${BENEFICIARY_PRIVATE_COUNTRY}`} />
        </FormRow>
        <FormRow>
          <CountriesComponent
            name={`${fieldName}.${BENEFICIARY_BIRTH_COUNTRY}`}
            disabled={shouldBeDisabled}
          />
          <BeneficiaryBirthdate />
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

const DivOuterAddPerson = styled.div`
  margin-top: 20px;
`;

const BeneficiaryOwners = () => {
  const t = useTranslations('pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary');
  const placeholderTranslations = useTranslations('placeholders');
  const { fields } = useFieldArray<FieldValue>(BENEFICIARY_OWNER);
  const { fields: existingBeneficiaries } = useFieldArray<FieldValue>(EXISTING_BENEFICIARY);
  const selectedCompany = useSelector<any, any>(getStoredValueSelector([COMPANY_DETAILS]));
  const companyData = useSelector(getCompanyData);
  const companyId = selectedCompany?.[COMPANY_DETAILS_EXISTING_USER_COMPANY]?.id || companyData?.id;
  const { data, isLoading } = useFetchAssociatedPersons(companyId);
  const beneficialOwners = data.filter((p: IAssociatedPerson) => p.type === 'beneficiary_owners');
  const formValues = useFormValues();
  const isBeneficiaryOwner = formValues[IS_BENEFICIARY_OWNER] === 'true';
  const { required } = useFieldValidators();
  const [search, setSearch] = React.useState('');

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  const currentUserEmail = useSelector(getUser).email;

  const mapBeneficiariesFromAssociatedPerson = (person: IAssociatedPerson) => ({
    id: person.id,
    key: person.id,
    label: `${person.firstName} ${person.lastName}`,
    value: person.id,
    firstName: person.firstName,
    lastName: person.lastName,
    type: person.type,
    gender: person.salutation,
    address: person.address!!,
    taxId: person.taxId,
    birthDate: person.birthDate,
    birthPlace: person.birthPlace!!,
    city: person.city!!,
    companyShare: person.companyShare!!,
    country: person.country!!,
    zipCode: person.zipCode!!,
    email: person.email,
  });

  /*
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
   */

  const associatedPersons = beneficialOwners
    .map<BeneficiaryOwnerMultiSelectOption>(mapBeneficiariesFromAssociatedPerson)
    .filter((p) => {
      return p.type === 'beneficiary_owners' && !(p.email === currentUserEmail);
    });

  const hasAssociatedPerson = associatedPersons.length > 0;

  const addPerson = useCallback(() => {
    const beneficiary = {
      [BENEFICIARY_GENDER]: '',
      [BENEFICIARY_FIRST_NAME]: '',
      [BENEFICIARY_LAST_NAME]: '',
      [BENEFICIARY_COMPANY_SHARE]: '',
      [BENEFICIARY_TAX_ID]: '',
      [BENEFICIARY_PRIVATE_ADDRESS]: '',
      [BENEFICIARY_ZIP_CODE]: '',
      [BENEFICIARY_PRIVATE_CITY]: '',
      [BENEFICIARY_PRIVATE_COUNTRY]: '',
      [BENEFICIARY_BIRTH_COUNTRY]: '',
      [BENEFICIARY_BIRTHDAY]: '',
      id: uuidv4(),
      isAssociatedPerson: false,
    };
    const index = fields.length;
    FormFieldController.instance.saveValue(`${BENEFICIARY_OWNER}[${index}]`, beneficiary);
    fields.push(beneficiary);
  }, [fields]);

  const options = associatedPersons.filter(({ label }) =>
    label.toLocaleLowerCase().includes(search.toLowerCase()),
  );

  const handleValidate = (value: unknown, fields: any) => {
    const field = fields[BENEFICIARY_OWNER];
    const isAdditionalPerson = field && field?.length > 0;
    return isAdditionalPerson || isBeneficiaryOwner ? undefined : required(value, fields);
  };

  const handleSelect = useCallback(
    (newPerson: BeneficiaryOwnerMultiSelectOption) => {
      const index = fields.length;
      const representative = {
        [BENEFICIARY_GENDER]: newPerson.gender,
        [BENEFICIARY_FIRST_NAME]: newPerson.firstName,
        [BENEFICIARY_LAST_NAME]: newPerson.lastName,
        [BENEFICIARY_COMPANY_SHARE]: newPerson.companyShare,
        [BENEFICIARY_TAX_ID]: newPerson.taxId,
        [BENEFICIARY_PRIVATE_ADDRESS]: newPerson.address,
        [BENEFICIARY_ZIP_CODE]: newPerson.zipCode,
        [BENEFICIARY_PRIVATE_CITY]: newPerson.city,
        [BENEFICIARY_PRIVATE_COUNTRY]: newPerson.country,
        [BENEFICIARY_BIRTH_COUNTRY]: newPerson.birthPlace,
        [BENEFICIARY_BIRTHDAY]: formatDateDays(newPerson.birthDate),
        id: newPerson.value.toString(),
        isAssociatedPerson: true,
        label: newPerson.label,
        key: newPerson.key,
      };
      FormFieldController.instance.saveValue(`${BENEFICIARY_OWNER}[${index}]`, representative);
      fields.push(representative);
    },
    [fields],
  );

  const handelRemovePerson = useCallback(
    (index: number) => () => {
      if (existingBeneficiaries.length) {
        const indexToRemoveInExistingBeneficiaries = existingBeneficiaries.value.findIndex(
          (person) => person.id === fields.value[index].id,
        );
        if (indexToRemoveInExistingBeneficiaries > -1) {
          FormFieldController.instance.deleteValue(
            EXISTING_BENEFICIARY,
            indexToRemoveInExistingBeneficiaries,
          );
          existingBeneficiaries.remove(indexToRemoveInExistingBeneficiaries);
        }
      }
      FormFieldController.instance.deleteValue(BENEFICIARY_OWNER, index);
      fields.remove(index);
    },
    [existingBeneficiaries, fields],
  );

  const handleRemove = useCallback(
    (keyToRemove: string) => {
      const indexToRemove = fields.value.findIndex((person) => person.id === keyToRemove);
      if (indexToRemove > -1) {
        handelRemovePerson(indexToRemove)();
      }
      if (existingBeneficiaries.length) {
        const indexToRemoveInExistingBeneficiaries = existingBeneficiaries.value.findIndex(
          (person) => person.id === keyToRemove,
        );

        if (indexToRemoveInExistingBeneficiaries > -1) {
          FormFieldController.instance.deleteValue(
            EXISTING_BENEFICIARY,
            indexToRemoveInExistingBeneficiaries,
          );
          existingBeneficiaries.remove(indexToRemoveInExistingBeneficiaries);
        }
      }
    },
    [existingBeneficiaries, handelRemovePerson, fields.value],
  );

  const togglePerson = (person: BeneficiaryOwnerMultiSelectOption) => {
    if (fields.value.findIndex(({ id }) => id === person.key) > -1) {
      handleRemove(person.key);
    } else {
      handleSelect(person);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  React.useEffect(() => {
    // when user selects he is not a beneficiary
    // if there is no beneficiary owner from backend and no new person added we need to push a new person
    if (
      !isBeneficiaryOwner &&
      !isLoading &&
      associatedPersons.length === 0 &&
      fields.length === 0
    ) {
      addPerson();
    }
  }, [addPerson, associatedPersons.length, fields.length, isBeneficiaryOwner, isLoading]);

  const selectedOptions =
    associatedPersons.filter((person) => fields.value.find((p) => p.id === person.key)) || [];

  return (
    <StyledPeopleSection>
      <StyledPeopleInfo>{t('heading')}</StyledPeopleInfo>
      {hasAssociatedPerson && (
        <>
          <MultiSelectWithField
            name={EXISTING_BENEFICIARY}
            placeholder={placeholderTranslations('pleaseChoose')}
            options={options}
            validate={handleValidate}
            selectedOptions={selectedOptions}
            isLoading={false}
            onSelect={togglePerson}
            onRemove={handleRemove}
            onInputChange={handleSearch}
            data-testid={'select-field-' + EXISTING_BENEFICIARY}
          />
        </>
      )}
      <Condition condition={fieldHasValue(IS_BENEFICIARY_OWNER, 'true')}>
        <SelfBeneficiaryOwner
          selfBeneficiary={beneficialOwners.find(
            (beneficiary) => beneficiary.email === currentUserEmail,
          )}
          isLoading={isLoading}
        />
      </Condition>
      {fields.length === 0 && (
        <>
          <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
          <ButtonComponent leftIcon={<AddIcon boxSize={6} d="block" />} onClick={addPerson}>
            {t('addPerson')}
          </ButtonComponent>
        </>
      )}
      {fields.map((fieldName, index) => (
        <ParentFieldContext.Provider
          value={{
            parentFieldName: '',
            parentIndex: 0,
          }}
          key={fields.value[index].id}
        >
          <BeneficiaryOwnersContext.Provider value={{ userIndex: index, fieldName }}>
            <SinglePerson
              onRemove={handelRemovePerson(index)}
              hideRemove={fields.length === 1 && !hasAssociatedPerson && !isBeneficiaryOwner}
            />
          </BeneficiaryOwnersContext.Provider>
        </ParentFieldContext.Provider>
      ))}
      {fields.length ? (
        <DivOuterAddPerson>
          {isBfsService ? <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo> : null}
          <ButtonComponent leftIcon={<AddIcon boxSize={6} d="block" />} onClick={addPerson}>
            {t('addPerson')}
          </ButtonComponent>
        </DivOuterAddPerson>
      ) : null}
    </StyledPeopleSection>
  );
};

export default React.memo(BeneficiaryOwners);
