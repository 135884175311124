import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
import PERMISSIONS from 'constants/user/permissions';
import {
  USER_ROLE_CUSTOMER,
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';
import { INQUIRY_STATUSES, INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { rolesSelector } from 'store/user/selectors';
import { doArraysHaveCommonPart } from 'utils/helpers';

const ROLES_EDIT_PERMISSIONS = {
  [USER_ROLE_BANK_ADVISOR]: {
    status: [INQUIRY_STATUSES.PENDING, INQUIRY_STATUSES.NEW, INQUIRY_STATUSES.ASSIGNED],
    substatus: [INQUIRY_SUBSTATUSES.ASSIGNED, INQUIRY_SUBSTATUSES.IN_PROCESS],
  },
  [USER_ROLE_INQUIRY_MANAGER]: {
    status: [INQUIRY_STATUSES.PENDING, INQUIRY_STATUSES.NEW],
    substatus: [],
  },
  [USER_ROLE_CALL_CENTER_AGENT]: {
    status: [INQUIRY_STATUSES.PENDING, INQUIRY_STATUSES.NEW],
    substatus: [],
  },
  [USER_ROLE_CUSTOMER]: {
    status: [
      INQUIRY_STATUSES.PENDING,
      INQUIRY_STATUSES.NEW,
      INQUIRY_STATUSES.INITIATED_EXTERNALLY,
      INQUIRY_SUBSTATUSES.IN_PROCESS,
    ],
    substatus: [INQUIRY_SUBSTATUSES.INITIATED_EXTERNALLY],
  },
  [USER_ROLE_REAL_ESTATE_EXPERT]: {
    status: [INQUIRY_STATUSES.PENDING, INQUIRY_STATUSES.NEW, INQUIRY_STATUSES.ASSIGNED],
    substatus: [INQUIRY_SUBSTATUSES.ASSIGNED, INQUIRY_SUBSTATUSES.IN_PROCESS],
  },
};

const isDraft = (inquiry) => inquiry.status === INQUIRY_STATUSES.DRAFT;

const getPermissionsForRole = (userRole) =>
  ROLES_EDIT_PERMISSIONS[userRole] || { status: [], substatus: [] };
const combineAndDistinctPermissions = (allPermissions, permissionForRole) => ({
  status: _uniq([...allPermissions.status, ...permissionForRole.status]),
  substatus: _uniq([...allPermissions.substatus, ...permissionForRole.substatus]),
});

const getAllowedInquiriesForRole = (userRoles) => {
  return userRoles
    .map(getPermissionsForRole)
    .reduce(combineAndDistinctPermissions, { status: [], substatus: [] });
};

const canRoleEditInquiryWithStatusOrSubstatus = (inquiry, userRoles) => {
  const permissions = getAllowedInquiriesForRole(userRoles);

  if (!permissions.status.length && !permissions.substatus.length) {
    return false;
  }
  return (
    permissions.status.includes(inquiry.status) || permissions.substatus.includes(inquiry.substatus)
  );
};

const doesUserHasRoleToEdit = (userRoles) =>
  doArraysHaveCommonPart(PERMISSIONS.INQUIRY.EDIT, userRoles);

const doesUserHasRoleToEditDraft = (userRoles) =>
  doArraysHaveCommonPart(PERMISSIONS.INQUIRY.EDIT_DRAFT, userRoles);

const hasOnlyCustomerRole = (userRoles) => _isEqual(userRoles, [USER_ROLE_CUSTOMER]);

const hasOperationPortalRole = (userRoles) =>
  doArraysHaveCommonPart(userRoles, [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER]);

const canEditExternalInquiry = (inquiry) => {
  return inquiry.status === INQUIRY_STATUSES.INITIATED_EXTERNALLY;
};

export const canEdit = (inquiry, userRoles, isAllowedForCustomer, isAllowedForOpUser) => {
  if (isDraft(inquiry)) {
    return doesUserHasRoleToEditDraft(userRoles);
  }

  if (!canRoleEditInquiryWithStatusOrSubstatus(inquiry, userRoles)) {
    return false;
  }

  if (canEditExternalInquiry(inquiry)) {
    return true;
  }

  if (!doesUserHasRoleToEdit(userRoles)) {
    return false;
  }

  if (hasOnlyCustomerRole(userRoles)) {
    return isAllowedForCustomer;
  }

  if (hasOperationPortalRole(userRoles)) {
    return isAllowedForOpUser;
  }

  return true;
};

const useCanEdit = () => {
  const inquiry = useSelector(getInquiryDetailsSelector);
  const userRoles = useSelector(rolesSelector);
  const isInquiryEditAllowedForCustomer = useConfig(CONFIG.IS_INQUIRY_EDIT_ALLOWED_FOR_CUSTOMER);

  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const isInquiryEditEnabledForOperationUser = useConfig(CONFIG.IS_INQUIRY_EDITING_ENABLED);

  return (
    canEdit(
      inquiry,
      userRoles,
      isInquiryEditAllowedForCustomer,
      isInquiryEditEnabledForOperationUser,
    ) &&
    partnerName !== PARTNERS.CREFOFACTORING &&
    partnerName !== PARTNERS.BFSS
  );
};

export default useCanEdit;
