import { fieldValidators } from '../../../../../../modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  validateFieldValidationMap,
  ValidateSectionFunction,
} from '../../../../../../new/form/useValidateFields';
import { mittweidaFields } from '../../../../fields';

export const validateOtherFundingSection: ValidateSectionFunction = (form, values) => {
  return validateFieldValidationMap({
    validationMap: otherFundingSectionValidationMap,
    form,
    values,
  });
};

export const otherFundingSectionValidationMap = {
  [mittweidaFields.fundingDetailsPage.otherFundingSection.description]: fieldValidators
    .string()
    .required()
    .max(5000),
};
