import { useMemo, useCallback } from 'react';

import { INQUIRY_ATTRIBUTES } from 'shared/inquiry/inquiryFieldsTranslations/inquiryAttributesTranslations/InquiryAttributes.model';
import { useObjectValueTranslator } from 'utils/hooks/useObjectValueTranslator';

const getAttributeTranslation = (name: string) => `data.inquiryAttributes.${name}`;
const inquiryAttributesLabels = {
  [INQUIRY_ATTRIBUTES.status]: getAttributeTranslation(INQUIRY_ATTRIBUTES.status),
  [INQUIRY_ATTRIBUTES.welcomeCallPerformedAt]: getAttributeTranslation(
    INQUIRY_ATTRIBUTES.welcomeCallPerformedAt,
  ),
  [INQUIRY_ATTRIBUTES.updatedAt]: getAttributeTranslation(INQUIRY_ATTRIBUTES.updatedAt),
  [INQUIRY_ATTRIBUTES.substatus]: getAttributeTranslation(INQUIRY_ATTRIBUTES.substatus),
  [INQUIRY_ATTRIBUTES.sentToCompeonAt]: getAttributeTranslation(INQUIRY_ATTRIBUTES.sentToCompeonAt),
  [INQUIRY_ATTRIBUTES.kfwProgram]: getAttributeTranslation(INQUIRY_ATTRIBUTES.kfwProgram),
  [INQUIRY_ATTRIBUTES.insuranceOfferRequestedAt]: getAttributeTranslation(
    INQUIRY_ATTRIBUTES.insuranceOfferRequestedAt,
  ),
  [INQUIRY_ATTRIBUTES.externalReference]: getAttributeTranslation(
    INQUIRY_ATTRIBUTES.externalReference,
  ),
  [INQUIRY_ATTRIBUTES.inquiryManagerId]: getAttributeTranslation(
    INQUIRY_ATTRIBUTES.inquiryManagerId,
  ),
  [INQUIRY_ATTRIBUTES.compeonId]: getAttributeTranslation(INQUIRY_ATTRIBUTES.compeonId),
  [INQUIRY_ATTRIBUTES.compeonStatus]: getAttributeTranslation(INQUIRY_ATTRIBUTES.compeonStatus),
  [INQUIRY_ATTRIBUTES.customerId]: getAttributeTranslation(INQUIRY_ATTRIBUTES.customerId),
  [INQUIRY_ATTRIBUTES.inquiryId]: getAttributeTranslation(INQUIRY_ATTRIBUTES.inquiryId),
  [INQUIRY_ATTRIBUTES.company]: getAttributeTranslation(INQUIRY_ATTRIBUTES.company),
};

export const useInquiryAttributesTranslations = () => {
  const objTranslator = useObjectValueTranslator();
  const translatedAttributes = useMemo(() => objTranslator(inquiryAttributesLabels), [
    objTranslator,
  ]);
  return useCallback((attrKey: INQUIRY_ATTRIBUTES) => translatedAttributes[attrKey], [
    translatedAttributes,
  ]);
};
