import React from 'react';

import PropTypes from 'prop-types';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import EndUserEmail from 'modules/Inquiry/Form/Steps/UserProfile/EndUserEmail/EndUserEmail';
import EndUserFirstName from 'modules/Inquiry/Form/Steps/UserProfile/EndUserFirstName/EndUserFirstName';
import EndUserGender from 'modules/Inquiry/Form/Steps/UserProfile/EndUserGender/EndUserGender';
import EndUserLastName from 'modules/Inquiry/Form/Steps/UserProfile/EndUserLastName/EndUserLastName';
import EndUserPhone from 'modules/Inquiry/Form/Steps/UserProfile/EndUserPhone/EndUserPhone';
import { useTranslations } from 'utils/hooks/useTranslations';

const EndUserSection = ({ sectionNumber }) => {
  const t = useTranslations('pages.userProfile.sections.endUser');
  return (
    <FormSection sectionNumber={sectionNumber} title={t('title')}>
      <FormRow>
        <EndUserGender />
        <EndUserFirstName />
        <EndUserLastName />
      </FormRow>

      <FormRow>
        <EndUserEmail />
      </FormRow>

      <FormRow>
        <EndUserPhone />
      </FormRow>
    </FormSection>
  );
};

EndUserSection.propTypes = {
  sectionNumber: PropTypes.number.isRequired,
};

export default EndUserSection;
