import React from 'react';

import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';

import StaticField from 'components/StaticField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { USER_PROFILE_COMPANY_ALREADY_CUSTOMER } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const AlreadyCustomer = () => {
  const { formatMessage } = useIntl();
  const tFields = useTranslations('pages.userProfile.sections.completingCompanyDetails.fields');
  const {
    details: { fullName: fullPartnerName },
  } = usePartnerConfig();
  const { selectedInquiryType } = useFormConfig();

  const alreadyCustomer = useGetSelectedCompanyParam(COMPANY_FIELDS.alreadyCustomer);

  /**
   * This flexLeft property is to align the Radio buttons left.
   * This could be updated and used for other partners too
   */
  const flexLeft = selectedInquiryType === InquiryType.bfs;

  if (typeof alreadyCustomer === 'boolean') {
    const translationId = `components.radioGroup.${alreadyCustomer ? 'yes' : 'no'}`;
    return (
      <StaticField
        caption={tFields('userProfileAlreadyCustomer.caption', { legalName: fullPartnerName })}
        text={formatMessage({
          id: translationId,
        })}
      />
    );
  }

  return (
    <YesNoRadioGroupWithField
      name={USER_PROFILE_COMPANY_ALREADY_CUSTOMER}
      caption={tFields('userProfileAlreadyCustomer.caption', { legalName: fullPartnerName })}
      tooltip={
        selectedInquiryType === InquiryType.onlinefactoring &&
        tFields('userProfileAlreadyCustomer.tooltip')
      }
      flexLeft={flexLeft}
    />
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => (
    <Field name={USER_PROFILE_COMPANY_ALREADY_CUSTOMER} render={() => null} />
  ),
  default: AlreadyCustomer,
});
