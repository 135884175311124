import React from 'react';

import { InputWithField } from 'components/Input';
import { VM_NUMBER } from 'modules/Inquiry/Form/formFields';

const VMNumber = () => {
  return <InputWithField name={VM_NUMBER} optional type="number" separateThousands={false} />;
};

export default VMNumber;
