import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';

import { MittweidaFieldTypes } from '../../mittweida/inquiry/fields';
import { ValidationMapType } from './common/types';
import { shouldShowField } from './hooks/useCanShowField';

export interface ValidateSectionFunction {
  (form: FormApi, values: MittweidaFieldTypes, store: any):
    | boolean
    | ((value: any, values: MittweidaFieldTypes) => boolean);
}

type ValidateField = {
  field: string;
  values: MittweidaFieldTypes;
  form: FormApi;
};

type ValidateArrayFields = {
  arrayValues: Array<any>;
  parentName: string;
  values: MittweidaFieldTypes;
  fieldsWithoutParent: string[];
  form: FormApi;
};

export const validateField = ({ field, values, form }: ValidateField) => {
  return validateFields({ fields: [field], values, form });
};

type ValidateFields = {
  fields: string[];
  values: MittweidaFieldTypes;
  form: FormApi;
};

export const validateArrayFields = ({
  arrayValues,
  parentName,
  fieldsWithoutParent,
  form,
  values,
}: ValidateArrayFields) => {
  const fieldNames: Array<string> = [];
  fieldsWithoutParent.forEach((fieldName) => {
    fieldNames.push(...arrayValues.map((_, index) => `${parentName}[${index}].${fieldName}`));
  });

  return validateFields({ fields: fieldNames, values, form });
};

export const validateFields = ({ fields, values, form }: ValidateFields) => {
  const visibleFields = fields.filter((name) => shouldShowField(name, values, form));
  const fieldStates = visibleFields.map((name) => {
    return form.getFieldState(name);
  });
  return fieldStates.every((field) => field?.valid);
};

export const useValidateFields = ({ fields }: { fields: Array<string> }) => {
  const form = useForm();
  const { values } = useFormState<MittweidaFieldTypes>();
  return validateFields({ fields, values, form });
};

export const useValidateArrayFields = ({
  arrayValues,
  parentName,
  fieldsWithoutParent,
}: Pick<ValidateArrayFields, 'arrayValues' | 'parentName' | 'fieldsWithoutParent'>) => {
  const form = useForm();
  const { values } = useFormState<MittweidaFieldTypes>();

  return validateArrayFields({ arrayValues, parentName, fieldsWithoutParent, values, form });
};

export const useValidateField = ({ field }: { field: string }) => {
  const form = useForm();
  const { values } = useFormState<MittweidaFieldTypes>();
  return validateFields({ fields: [field], values, form });
};

type ValidateFieldValidationMap = {
  validationMap: ValidationMapType;
};

export const validateFieldValidationMap = ({
  validationMap,
  form,
  values,
}: { form: FormApi; values: MittweidaFieldTypes } & ValidateFieldValidationMap) => {
  const fieldsToValidate = Object.keys(validationMap).map((key) => key);
  return validateFields({ fields: fieldsToValidate, values, form });
};

export const useValidateFieldValidationMap = ({ validationMap }: ValidateFieldValidationMap) => {
  const fieldsToValidate = Object.keys(validationMap).map((key) => key);
  return useValidateFields({
    fields: fieldsToValidate,
  });
};
