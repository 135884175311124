import _get from 'lodash/get';

import {
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapMarketValueToReeEditApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const marketValueData = {
    current_usage: {
      ree_market_value_per_sqm_building:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING))) ||
        initialValue,
      ree_market_value_parking_lot:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_VALUE))) ||
        initialValue,
      ree_commercial_rent_per_sqm:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) ||
        initialValue,
      ree_rent_parking_lot:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_PARKING_LOT_RENT))) ||
        initialValue,
      ree_market_value_garage:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_VALUE))) ||
        initialValue,
      ree_market_value_per_sqm_unit:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT))) ||
        initialValue,
      ree_residential_rent_per_sqm:
        toFloatPrecision2(
          unknownToString(_get(data, CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM)),
        ) || initialValue,
      ree_rent_garage:
        toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_REE_GARAGE_RENT))) ||
        initialValue,
    },
    future_usage: {
      ree_market_value_per_sqm_building:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING))) ||
        initialValue,
      ree_market_value_parking_lot:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_VALUE))) ||
        initialValue,
      ree_commercial_rent_per_sqm:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM))) ||
        initialValue,
      ree_rent_parking_lot:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_PARKING_LOT_RENT))) ||
        initialValue,
      ree_market_value_garage:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_VALUE))) ||
        initialValue,
      ree_market_value_per_sqm_unit:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT))) ||
        initialValue,
      ree_residential_rent_per_sqm:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM))) ||
        initialValue,
      ree_rent_garage:
        toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_REE_GARAGE_RENT))) ||
        initialValue,
    },
  };

  return marketValueData;
};

export default mapMarketValueToReeEditApi;
