import React from 'react';

import _range from 'lodash/range';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import StepsDropdown from 'components/Selects/Select/StepsDropdown';
import Slider from 'components/Slider';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { simpleValidation } from 'modules/Inquiry/Form/validateFields';
import {
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  SHARE_OF_INSURANCE_CLAIMS,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  INVOICES_PER_YEAR,
  PAYMENT_TERMS,
  ALREADY_ASSIGNED,
} from 'onlinefactoring/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateYourCustomerSection = simpleValidation([
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  SHARE_OF_INSURANCE_CLAIMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  INVOICES_PER_YEAR,
  PAYMENT_TERMS,
  ALREADY_ASSIGNED,
]);

export const YourCustomerSection = () => {
  const t = useTranslations('inquiryType.kfinanz.steps.purpose.sections.yourCustomer');

  return (
    <FormSection title={t('title')} sectionNumber={2}>
      <FormRow>
        <StepsDropdown
          name={SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP}
          chunks={_range(0, 101, 10)}
          caption={t('fields.shareOfSales.caption')}
        />
      </FormRow>
      <FormRow>
        <StepsDropdown
          name={SHARE_OF_INSURANCE_CLAIMS}
          chunks={_range(0, 101, 10)}
          caption={t('fields.shareOfInsurance.caption')}
        />
      </FormRow>
      <FormRow>
        <Slider
          name={PERCENTAGE_OF_INVOICES_FOR_SELL}
          caption={t('fields.invoicesForSell.caption')}
          step={5}
          defaultValue={50}
        />
      </FormRow>
      <FormRow>
        <StepsDropdown
          name={PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE}
          chunks={_range(0, 101, 10)}
          caption={t('fields.servicesPaidByInvoices.caption')}
        />
      </FormRow>
      <FormRow>
        <StepsDropdown
          name={INVOICES_PER_YEAR}
          chunks={_range(0, 501, 50)}
          more
          caption={t('fields.invoicesPerYear.caption')}
        />
      </FormRow>
      <FormRow>
        <StepsDropdown
          name={PAYMENT_TERMS}
          chunks={[0, 14, ..._range(30, 91, 30)]}
          more={true}
          caption={t('fields.customerTerms.caption')}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={ALREADY_ASSIGNED}
          caption={t('fields.alreadyAssigned.caption')}
        />
      </FormRow>
    </FormSection>
  );
};
