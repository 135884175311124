import React from 'react';

import { Divider } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import EditInquiryLink from 'components/EditInquiryLink';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import CancelInquiryInCompeon from 'modules/InquiryDetails/DetailedInformation/CancelInquiryInCompeon';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import EndUserSection from 'modules/InquiryDetails/DetailedInformation/EndUserSection';
import FinancingPurposeSection from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/FinancingPurposeSection';
import ForwardInquiryToInsurance from 'modules/InquiryDetails/DetailedInformation/ForwardInquiryToInsurance/ForwardInquiryToInsurance';
import { useCanForwardToInsurance } from 'modules/InquiryDetails/DetailedInformation/ForwardInquiryToInsurance/useCanForwardToInsurance';
import InquiryDetailsSection from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection';
import { DetailedInformationWrapper as Wrapper } from 'modules/InquiryDetails/DetailedInformation/styles';
import VbProfileSection from 'modules/InquiryDetails/DetailedInformation/VbProfileSection/index';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import InquiryOffers from 'modules/Offers/InquiryOffersSection';
import SummaryCard from 'pages/customerPortal/InquiryDetails/Dashboard/SummaryCard';
import AdditionalFinancingSection from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonAcceptance';
import { useOperationProgressSections } from 'pages/operationPortal/InquiryDetails/Dashboard/useOperationProgressSections';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import {
  hasOffersSelector,
  getInquiryIdSelector,
  getInquiryStatus,
} from 'store/inquiryDetails/selectors';

const DvagInquiryDetailedInformation = ({ pathToEditInquiry }) => {
  const canEdit = useCanEdit();

  const {
    completedValue: sectionsCompletedValue,
    sectionsDone,
    sectionsOpen,
    firstOpenTask,
  } = useOperationProgressSections();
  const canForwardToInsurance = useCanForwardToInsurance();
  const hasOffers = useSelector(hasOffersSelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const isCancelInquiryInCompeonEnabled = useConfig(CONFIG.IS_CANCEL_INQUIRY_IN_COMPEON_ENABLED);
  const status = useSelector(getInquiryStatus);
  const isArchived = status === INQUIRY_STATUSES.ARCHIVED;

  const canShowProgress = sectionsDone + sectionsOpen > 0;

  return (
    <>
      <InquiryDetailsHeader>
        {canEdit && <EditInquiryLink to={pathToEditInquiry.replace(':id', inquiryId)} />}
        {isCancelInquiryInCompeonEnabled && !isArchived && <CancelInquiryInCompeon />}
        {canForwardToInsurance && <ForwardInquiryToInsurance />}
      </InquiryDetailsHeader>

      <Divider mt={4} mb={8} color="border.darkGrey" />
      <SummaryCard
        completedValue={sectionsCompletedValue}
        progressIncrement={sectionsDone}
        progressTotal={sectionsDone + sectionsOpen}
        isCompleted={sectionsOpen === 0}
        openTaskId={firstOpenTask}
        canShowProgress={canShowProgress}
      />

      <Wrapper>
        <AdditionalFinancingSection />
        {hasOffers && <InquiryOffers />}
        <FinancingPurposeSection />
        <InquiryDetailsSection />
        <CompanyDetailsSection />
        <EndUserSection />
        <VbProfileSection />
      </Wrapper>
    </>
  );
};

DvagInquiryDetailedInformation.propTypes = {
  pathToEditInquiry: PropTypes.string.isRequired,
};

export default DvagInquiryDetailedInformation;
