import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import loadMarketValueDetails from 'api/CompeonReverseApi/operation/loadMarketValueDetails/actions';
import PortalTable from 'components/PortalTable/PortalTable';
import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import { isInquiryManager } from 'store/user/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import { ButtonComponent } from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPriceGerman } from 'utils/valueFormats';

import {
  IAppraiserObjEvalTable,
  IObjEvalTable,
  IObjEvalTableHeader,
} from '../MarketComparison/types';
import { PROFITABILITY_RECORD_TYPE } from '../SharedComponent/constants';
import useEditFormConfig from '../useEditFormConfig';
import ProjectCostingBoxes from './ProjectCostingBoxes';
import ProjectCostingFilters from './ProjectCostingFilters';
import ProjectCostingHeader from './ProjectCostingHeader';

interface DetailsProps {
  heading: string;
  subheading: string;
  subheadingDate: string;
  clientDetailBoxes: {
    saleRevenue: number;
    rentalPerYear: number;
    livingSpace: number;
    commercialSpace: number;
  };
  tableHeaders: IObjEvalTableHeader[];
  tableData: IObjEvalTable[] | IAppraiserObjEvalTable[] | undefined;
  hideTable?: boolean;
  pathToEdit: string;
  recordType: string | PROFITABILITY_RECORD_TYPE;
  editFormStructure: EditMarketValue[];
}

const RealStateMarketDetails: React.FC<DetailsProps> = (props: DetailsProps) => {
  const hasInquiryManagerRole = useSelector(isInquiryManager);
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const tUnit = useTranslations();

  const { onEdit } = useEditFormConfig({
    pathToEdit: props.pathToEdit,
    recordType: props.recordType,
    loadData: loadMarketValueDetails,
    editFormStructure: props.editFormStructure,
  });

  return (
    <Box>
      <ProjectCostingHeader
        heading={props.heading}
        subheading={props.subheading}
        subheadingDate={props.subheadingDate}
      />
      <ProjectCostingFilters />
      <ProjectCostingBoxes
        values={[
          {
            name: t('clientBoxes.saleRevenue'),
            value: `${formatPriceGerman(props.clientDetailBoxes.saleRevenue)}`,
          },
          {
            name: t('clientBoxes.rentalPerYear'),
            value: `${formatPriceGerman(props.clientDetailBoxes.rentalPerYear)}`,
          },
          {
            name: t('clientBoxes.livingSpace'),
            value: `${props.clientDetailBoxes.livingSpace}${tUnit('other.m2')}`,
          },
          {
            name: t('clientBoxes.commercialSpace'),
            value: `${props.clientDetailBoxes.commercialSpace}${tUnit('other.m2')}`,
          },
        ]}
      />
      <AccordionComponent headingLevel="h3" mb={8} title={t('crousalHeader')}>
        {props.tableData && (
          <PortalTable
            onRowClick={() => {}}
            onSortChange={() => {}}
            sortBy="id"
            isLoading={false}
            isInitialized={true}
            headers={props.tableHeaders}
            tableData={props.tableData}
          ></PortalTable>
        )}

        {!hasInquiryManagerRole && (
          <EditStepWrapper>
            <ButtonComponent
              leftIcon={<EditIcon boxSize={6} d="block" />}
              onClick={onEdit}
              variant="tertiary"
            >
              {tUnit('buttons.edit')}
            </ButtonComponent>
          </EditStepWrapper>
        )}
      </AccordionComponent>
    </Box>
  );
};

export default RealStateMarketDetails;
