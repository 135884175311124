import * as React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import ButtonComponent from '../Button';
import { HeadingComponent } from '../Heading';
import { TextComponent } from '../Text';

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  size?: SizeType;
  title: string;
  cancelText: string;
  confirmText: string;
  isLoading?: boolean;
  disabled?: boolean;
  testId?: string;
  children: React.ReactNode;
};

export const AlertDialogComponent = ({
  isOpen,
  onClose,
  onConfirm,
  size = 'xl',
  title,
  cancelText,
  confirmText,
  isLoading,
  disabled,
  children,
  testId,
}: Props) => {
  const cancelRef = React.useRef(null);

  const {
    headingComponent,
    alertDialogBody,
    alertDialogContent,
    alertDialogFooter,
    alertDialogHeader,
  } = useMultiStyleConfig('AlertDialog', {});
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      size={size}
      preserveScrollBarGap
    >
      <AlertDialogOverlay />

      <AlertDialogContent data-testid={testId} sx={alertDialogContent}>
        <AlertDialogHeader sx={alertDialogHeader}>
          <HeadingComponent sx={headingComponent}>{title}</HeadingComponent>
        </AlertDialogHeader>

        <AlertDialogBody sx={alertDialogBody}>
          <TextComponent>{children}</TextComponent>
        </AlertDialogBody>

        <AlertDialogFooter sx={alertDialogFooter}>
          <ButtonComponent
            disabled={disabled}
            mr={4}
            onClick={onClose}
            parentRef={cancelRef}
            variant="tertiary"
          >
            {cancelText}
          </ButtonComponent>
          <ButtonComponent
            isLoading={isLoading}
            onClick={handleConfirm}
            data-testid="confirm-modal"
          >
            {confirmText}
          </ButtonComponent>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
