import {
  COMPEON_PARTNER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  VM_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { mapCompanyDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/companyMappers/mapCompanyDetailsToApi';
import { mapEndUserToApi } from 'modules/Inquiry/mapInquiryToApi/endUserMapper/mapEndUserToApi';
import { mapExcludingQuestionsToApi } from 'modules/Inquiry/mapInquiryToApi/excludingQuestionsMappers/mapExcludingQuestionsToApi';
import { mapFinancingNeedPurposeFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapFinancingNeedPurposeFieldsToApi';
import { mapSubsidiesToApi } from 'modules/Inquiry/mapInquiryToApi/mapSubsidiesToApi';
import { mapPeopleDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/peopleMapper/peopleDetailsToApi';
import { mapProductBasedFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapProductBasedFields';
import { mapBigPictureUserToApi } from 'modules/Inquiry/mapInquiryToApi/userMappers/mapUserDetailsToApi';

export const mapBigPictureInquiryToApi = (
  allFields: any,
  isLoggedIn: boolean,
  isLead: boolean,
  isBankAdvisor = false,
) => ({
  compeon_partner: allFields[COMPEON_PARTNER],
  'additional-association-company': allFields[USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY],
  'vm-number': allFields[VM_NUMBER],
  ...mapFinancingNeedPurposeFieldsToApi(allFields),
  ...mapCompanyDetailsToApi(allFields),
  ...mapProductBasedFieldsToApi(allFields),
  ...mapBigPictureUserToApi(allFields, isLoggedIn, isBankAdvisor),
  ...mapEndUserToApi(allFields),
  ...mapSubsidiesToApi(allFields),
  ...mapPeopleDetailsToApi(allFields, isLead),
  ...mapExcludingQuestionsToApi(allFields),
});
