import {
  USER_REPRESENTATION,
  USER_SHARED_REPRESENTATION,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import {
  fieldValidators,
  customValidations,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const peopleDetailsFieldValidations = {
  [USER_REPRESENTATION]: fieldValidators.boolean().required(),
  [USER_SHARED_REPRESENTATION]: fieldValidators.string().required(),
  [REPRESENTATIVE_GENDER]: fieldValidators.string().required(),
  [REPRESENTATIVE_FIRST_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_END_USER_FIRST_NAME),
  [REPRESENTATIVE_LAST_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_END_USER_LAST_NAME),
  [REPRESENTATIVE_EMAIL]: fieldValidators.string().required().custom(customValidations.isEmail),
  [REPRESENTATIVE_BIRTHDATE]: fieldValidators
    .dateFormat()
    .required()
    .isDateFormat()
    .minDate(InquiryConstants.MIN_BIRTHDATE)
    .maxDate(InquiryConstants.YESTERDAY),
  [REPRESENTATIVE_PHONE_NUMBER]: fieldValidators
    .phoneNumber()
    .required()
    .hasPhonePrefix()
    .isPhoneNumber()
    .maxPhoneNumberLength()
    .minPhoneNumberLength(),
};
