import {
  COMPEON_API_CLIENT_NAME,
  AUTH_API_CLIENT_NAME,
  WHITE_LABEL_API_CLIENT_NAME,
  COMPEON_OAUTH_API_CLIENT_NAME,
  MOCK_API_CLIENT_NAME,
} from 'api';
import { AppActionThunk } from 'models/types/AppThunk.type';
import { IState } from 'store';
import { getAccessToken } from 'store/user/service';

export const API_HEADERS = {
  contentType: {
    pdf: { 'Content-Type': 'application/pdf' },
    multiPart: { 'Content-Type': 'multipart/form-data' },
  },
  bearerToken: (token: string) => ({ Authorization: `Bearer ${token}` }),
};

export const getAuthorizationToken = (state: IState) => {
  const token = getAccessToken(state);
  if (!token) {
    return {};
  }
  return API_HEADERS.bearerToken(token);
};

export enum API_METHODS {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

interface CallApiInterface {
  url: string;
  method: API_METHODS;
  data?: any | ((state: IState) => any); // api payload
  actionName?: string;
  headers?: any;
  meta?: any;
  responseType?: string;
}

export const callReverseApi =
  ({
    url,
    method,
    data,
    actionName,
    headers,
    meta,
    responseType,
  }: CallApiInterface): AppActionThunk =>
  (dispatch, getState) => {
    const defaultActionName = `[${method}] ${url}`;

    // you can pass data as a function with current state
    // or pass simple object/var
    // this allows you to use current state as a part of api payload
    const payload = typeof data === 'function' ? data(getState()) : data;

    return dispatch({
      type: actionName || defaultActionName,
      payload: {
        meta,
        client: WHITE_LABEL_API_CLIENT_NAME,
        request: {
          method,
          url,
          data: payload,
          headers: {
            'Content-Type': 'application/vnd.api+json',
            ...getAuthorizationToken(getState()),
            ...headers,
          },
          responseType: responseType,
        },
      },
    });
  };

export const callCompeonApi =
  ({ url, method, data, actionName }: CallApiInterface): AppActionThunk =>
  (dispatch, getState) => {
    const defaultActionName = `[${method}] ${url}`;
    const token = getAuthorizationToken(getState());

    return dispatch({
      type: actionName || defaultActionName,
      payload: {
        client: COMPEON_API_CLIENT_NAME,
        request: {
          headers: {
            ...token,
          },
          method,
          url,
          data,
        },
      },
    });
  };

export const callAuthApi =
  ({ url, method, data, actionName }: CallApiInterface): AppActionThunk =>
  (dispatch, getState) => {
    const defaultActionName = `[${method}] ${url}`;

    // you can pass data as a function with current state
    // or pass simple object/var
    // this allows you to use current state as a part of api payload
    const payload = typeof data === 'function' ? data(getState()) : data;

    return dispatch({
      type: actionName || defaultActionName,
      payload: {
        client: AUTH_API_CLIENT_NAME,
        request: {
          method,
          url,
          data: payload,
        },
      },
    });
  };

export const callCompeonOAuthApi =
  ({ url, method, data, actionName }: CallApiInterface): AppActionThunk =>
  (dispatch, getState) => {
    const defaultActionName = `[${method}] ${url}`;

    // you can pass data as a function with current state
    // or pass simple object/var
    // this allows you to use current state as a part of api payload
    const payload = typeof data === 'function' ? data(getState()) : data;

    return dispatch({
      type: actionName || defaultActionName,
      payload: {
        client: COMPEON_OAUTH_API_CLIENT_NAME,
        request: {
          method,
          url,
          data: payload,
        },
      },
    });
  };

export const callMockApi =
  ({ url, method, data, actionName }: CallApiInterface): AppActionThunk =>
  (dispatch, getState) => {
    const defaultActionName = `[${method}] ${url}`;

    // you can pass data as a function with current state
    // or pass simple object/var
    // this allows you to use current state as a part of api payload
    const payload = typeof data === 'function' ? data(getState()) : data;

    return dispatch({
      type: actionName || defaultActionName,
      payload: {
        client: MOCK_API_CLIENT_NAME,
        request: {
          method,
          url,
          data: payload,
        },
      },
    });
  };
