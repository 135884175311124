import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';

import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { useSummaryStepContext } from 'mittweida/inquiry/steps/summary/Context';
import {
  SummaryType,
  unitFormatters,
  unitTypeSuffixMap,
} from 'mittweida/inquiry/steps/summary/units';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useInquiryValueTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryValueTranslator';
import { StepSequence, SummaryFieldsType } from 'new/form/model';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGeneratePagesWithFieldNames } from './useGeneratePagesWithFieldNames';


export type SummaryPageField = {
  text: string;
  caption: string;
};
export type FieldsForSummaryPage = Record<string, Array<SummaryPageField>>;
/**
 * On the inquiry summary page and the inquiry detail page in the operations / customer portal we need to display the
 * label and the value of the fields - additionally the label should be translated and the value should be formatted
 * according to a unit mapping from fieldName to unitFormatter. This hook generates an object with the pageName as key
 * and an array of SummaryPageField as value.
 */
export function useBuildFieldsForSummaryPage({
  stepSequence,
  summaryFields,
}: {
  summaryFields: SummaryFieldsType<any> | undefined;
  stepSequence: StepSequence<any> | undefined;
}): FieldsForSummaryPage {
  const t = useTranslations();
  const labelTranslator = useInquiryLabelTranslator();
  const valueTranslator = useInquiryValueTranslator();
  const { values } = useSummaryStepContext();
  const pagesWithFieldNames = useGeneratePagesWithFieldNames(stepSequence);

  function translateField(values: MittweidaFieldTypes, field: string, arrayField?: string) {
    let fieldValue;
    let summaryType;

    if (arrayField) {
      fieldValue = _get(values, arrayField);
      summaryType = _get(summaryFields, field) as SummaryType;
    } else {
      fieldValue = _get(values, field);
      summaryType = _get(summaryFields, field) as SummaryType;
    }

    if (summaryType && !_isNil(fieldValue)) {
      const { unit, showOnSummaryPage } = summaryType;
      if (showOnSummaryPage) {
        const unitFormatter = unitFormatters[unit];
        const translatedValue = valueTranslator(fieldValue, field);
        const formattedValue = unitFormatter
          ? unitFormatter(translatedValue, false)
          : translatedValue;
        const suffix = unitTypeSuffixMap[unit](t);
        const label = labelTranslator(field);

        return {
          text: `${formattedValue} ${suffix || ''}`,
          caption: label,
        };
      }
    }
    return undefined;
  }

  if (stepSequence && summaryFields) {
    return Object.entries(pagesWithFieldNames).reduce((accumulator, [pageName, fields]) => {
      const fieldsWithCaptionAndValue: Array<SummaryPageField | undefined> = fields
        .map((field) => {
          if (typeof field === 'string') {
            return translateField(values, field);
          } else if (typeof field === 'object') {
            const nestedValues = _get(values, field.fieldName);

            if (Array.isArray(nestedValues)) {
              const result = nestedValues.map((arrayObject: any, index) => {
                return Object.entries(arrayObject).map(([key, value]) => {
                  const objectField = `${field.fieldName}.${key}`;
                  const arrayField = `${field.fieldName}[${index}].${key}`;
                  return translateField(values, objectField, arrayField);
                });
              });

              return result.flat(2);
            } else if (_isObject(nestedValues)) {
              return Object.entries(nestedValues).map(([key, value]) => {
                const objectField = `${field.fieldName}.${key}`;
                return translateField(values, objectField);
              });
            }

            return undefined;
          }

          return undefined;
        })
        .flat();

      return {
        ...accumulator,
        [pageName]: fieldsWithCaptionAndValue.filter(Boolean),
      };
    }, {});
  }

  return {};
}
