import React from 'react';

import { HiddenInputWithField } from 'components/HiddenInput';
import StaticField from 'components/StaticField';
import { LEASING_AMORTISATION_KIND } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const DvagLeasingAmortisationKind = () => {
  const t = useTranslations('pages.requestDetails.sections.leasing.fields.leasingAmortisationKind');
  return (
    <>
      <StaticField caption={t('caption')} text={t('options.partialAmortisation')} />
      <HiddenInputWithField name={LEASING_AMORTISATION_KIND} />
    </>
  );
};

export default DvagLeasingAmortisationKind;
