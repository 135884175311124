import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { getUser } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const GenderStaticField = () => {
  const user = useSelector(getUser);
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender',
  );
  return <StaticField caption={t('caption')} text={t(`genders.${user.gender}`)} />;
};

export default GenderStaticField;
