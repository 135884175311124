import _get from 'lodash/get';
import omitBy from 'lodash/omitBy';

import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  EXTERNAL_REFERENCE_ID,
  FAVORED_DECISION_DATE,
  GOODS__ESTIMATED_DELIVERY_DATE,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
  VB_NUMBER,
  VB_ZIP_CODE,
  VM_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import {
  getCustomer,
  getInquiry,
  mapCustomer,
  mapUserProfile,
  parseDate,
} from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';
import { mapEndUserToApi } from 'modules/Inquiry/mapInquiryToApi/endUserMapper/mapEndUserToApi';
import { END_USER_ATTRIBUTES_KEY } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { mapFinancingNeedPurposeFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapFinancingNeedPurposeFieldsToApi';
import { mapProductBasedFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapProductBasedFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import {
  COMPANY_DETAILS,
  FINANCING_NEED,
  REQUEST_DETAILS,
  USER_PROFILE,
} from 'utils/sessionStorage/keys';

const removeEmptyFields = (data) =>
  omitBy(
    data,
    (element) => element === undefined || (Array.isArray(element) && element.length === 0),
  );

const mapFinancing = (attributes) => {
  const rawDetails = attributes.details[FINANCING_NEED] || {};
  const details = mapFinancingNeedPurposeFieldsToApi(rawDetails);
  details[GOODS__ESTIMATED_DELIVERY_DATE] = rawDetails[GOODS__ESTIMATED_DELIVERY_DATE];

  return details;
};

const mapDetails = (attributes) => {
  const rawDetails = attributes.details[REQUEST_DETAILS] || {};
  const details = mapProductBasedFieldsToApi(rawDetails);
  details[FAVORED_DECISION_DATE] = rawDetails[FAVORED_DECISION_DATE];

  return details;
};

const isNewCompany = (attributes) => {
  const companyDetails = attributes.details[COMPANY_DETAILS] || {};
  return (
    companyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY] ===
    COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  );
};

const mapCompany = (attributes) => {
  if (isNewCompany(attributes)) {
    const rawCompany = attributes.details[USER_PROFILE];
    return {
      [USER_PROFILE_LEGAL_FORM]: rawCompany[USER_PROFILE_LEGAL_FORM],
      [USER_PROFILE_INDUSTRY]: rawCompany[USER_PROFILE_INDUSTRY],
      [USER_PROFILE_TURNOVER_CLASS]: rawCompany[USER_PROFILE_TURNOVER_CLASS],
      [USER_PROFILE_FOUNDING_YEAR]: rawCompany[USER_PROFILE_FOUNDING_YEAR],
      [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: rawCompany[USER_PROFILE_COMPANY_ALREADY_CUSTOMER],
      [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]:
        attributes[USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY],
      [USER_PROFILE_COMPANY_STREET]: rawCompany[USER_PROFILE_COMPANY_STREET],
      [USER_PROFILE_COMPANY_CITY]: rawCompany[USER_PROFILE_COMPANY_CITY],
      [USER_PROFILE_COMPANY_ZIP_CODE]: rawCompany[USER_PROFILE_COMPANY_ZIP_CODE],
    };
  }
  const rawCompany = attributes.details[COMPANY_DETAILS]
    ? attributes.details[COMPANY_DETAILS][COMPANY_DETAILS_EXISTING_USER_COMPANY]
    : {};
  return {
    [USER_PROFILE_LEGAL_FORM]: _get(rawCompany, 'legal_form'),
    [USER_PROFILE_INDUSTRY]: _get(rawCompany, 'industry'),
    [USER_PROFILE_TURNOVER_CLASS]: _get(rawCompany, 'turnover_class'),
    [USER_PROFILE_FOUNDING_YEAR]: _get(rawCompany, 'founding_year'),
    [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: _get(rawCompany, 'already_customer'),
    [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]: _get(
      attributes,
      'details.additional-association-company',
    ),
    [USER_PROFILE_COMPANY_STREET]: _get(rawCompany, 'street'),
    [USER_PROFILE_COMPANY_CITY]: _get(rawCompany, 'city'),
    [USER_PROFILE_COMPANY_ZIP_CODE]: _get(rawCompany, 'zip_code'),
  };
};

const mapEndCustomer = (attributes) => {
  const rawDetails = attributes.details[END_USER_ATTRIBUTES_KEY] || {};
  return mapEndUserToApi(rawDetails);
};

const mapAdditionalFields = (attributes, normalizedResponse) => {
  const customer = getCustomer(normalizedResponse);

  return {
    [VB_ZIP_CODE]: _get(customer, 'attributes.zip_code'),
    [VB_NUMBER]: _get(customer, 'attributes.vb_number'),
    [VM_NUMBER]: _get(attributes, `details.${COMPANY_DETAILS}.${VM_NUMBER}`),
  };
};

const getCompanyName = (attributes) =>
  isNewCompany(attributes)
    ? _get(attributes, `details.${USER_PROFILE}.${USER_PROFILE_COMPANY_NAME}`)
    : _get(attributes, `details.${COMPANY_DETAILS}.${COMPANY_DETAILS_EXISTING_USER_COMPANY}.name`);

const mapInquiryDraft = (normalizedResponse) => {
  const inquiry = getInquiry(normalizedResponse);
  const { id, attributes } = inquiry;

  return {
    inquiryId: id,
    status: attributes.status,
    substatus: attributes.substatus,
    createdAt: parseDate(inquiry, 'created_at'),
    sentAt: parseDate(inquiry, 'sent_at'),
    welcomeCallPerformedDate: parseDate(inquiry, 'welcome_call_performed_at'),
    wasForwardToCompeon: Boolean(inquiry.attributes.sent_to_compeon_at),
    archivingReason: attributes.details['reason-for-archiving'],
    [EXTERNAL_REFERENCE_ID]: attributes[EXTERNAL_REFERENCE_ID],

    [INQUIRY_SECTIONS.USER]: mapUserProfile(normalizedResponse),
    [INQUIRY_SECTIONS.FINANCING]: removeEmptyFields(mapFinancing(attributes)),
    [INQUIRY_SECTIONS.DETAILS]: removeEmptyFields(mapDetails(attributes)),
    [INQUIRY_SECTIONS.COMPANY]: removeEmptyFields(mapCompany(attributes)),
    [INQUIRY_SECTIONS.END_USER]: removeEmptyFields(mapEndCustomer(attributes)),

    [INQUIRY_SECTIONS.ADDITIONAL_FIELDS]: removeEmptyFields(
      mapAdditionalFields(attributes, normalizedResponse),
    ),

    bankAdvisor: null,
    company: {
      name: getCompanyName(attributes),
    },
    bankOffers: [],
    compeonOffers: [],
    customer: mapCustomer(normalizedResponse),
  };
};

export default mapInquiryDraft;
