import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const submitPlanningEvaluationProfitabilityData = (
  data: Record<string, unknown>,
  inquiryId: string,
) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: data,
      },
    },
  });

export default submitPlanningEvaluationProfitabilityData;
