import * as React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNER_SPECIFIC_IBAN } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

const PartnerSpecificIban = () => {
  const {
    details: { fullName: partnerName },
    meta: { bankCodes },
  } = usePartnerConfig();
  const { isIban, isGermanIban, matchesSpecificBankCodes } = useFieldValidators();

  return (
    <FormRow>
      <InputWithField
        name={PARTNER_SPECIFIC_IBAN}
        validate={combineValidators(
          isIban,
          isGermanIban,
          matchesSpecificBankCodes(bankCodes, partnerName),
        )}
      />
    </FormRow>
  );
};

export default PartnerSpecificIban;
