import React from 'react';

import { useSelector } from 'react-redux';

import { InputWithField } from 'components/Input';
import {
  CORONA_KFW_QUICK_CREDIT_MAX_LOAN_DURATION,
  CORONA_KFW_PROGRAM_LOWER_FINANCING_LIMIT,
  CORONA_KFW_PROGRAM_LARGE_FINANCING_MAX_LOAN_DURATION,
  CORONA_KFW_PROGRAM_SMALL_FINANCING_MAX_LOAN_DURATION,
  CORONA_KFW_QUICK_CREDIT_MIN_LOAN_DURATION,
} from 'modules/Inquiry/corona.constants';
import {
  LOAN_TERM,
  FINANCING_NEED,
  FINANCING_AMOUNT,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getStoredValueSelector } from 'store/progress/selectors';
import { combineValidators } from 'utils/validators';

import DvagLoanDuration from './DvagLoanDuration';

const DefaultLoanDuration = () => {
  return <InputWithField name={LOAN_TERM} type="number" />;
};

const CoronaQuickCreditLoanDuration = () => {
  const { required, isPositive, isInteger, maxMonths, minMonths } = useFieldValidators();

  return (
    <InputWithField
      name={LOAN_TERM}
      type="number"
      validate={combineValidators(
        required,
        isPositive,
        isInteger,
        minMonths(CORONA_KFW_QUICK_CREDIT_MIN_LOAN_DURATION),
        maxMonths(CORONA_KFW_QUICK_CREDIT_MAX_LOAN_DURATION),
      )}
    />
  );
};

const CoronaOtherProgramLoanDuration = () => {
  const { required, isPositive, isInteger, maxMonths } = useFieldValidators();
  const financingAmount =
    useSelector(getStoredValueSelector([FINANCING_NEED, FINANCING_AMOUNT])) || 0;
  const isLargeFinancingAmount = financingAmount > CORONA_KFW_PROGRAM_LOWER_FINANCING_LIMIT;
  const maxLoanDuration = isLargeFinancingAmount
    ? CORONA_KFW_PROGRAM_LARGE_FINANCING_MAX_LOAN_DURATION
    : CORONA_KFW_PROGRAM_SMALL_FINANCING_MAX_LOAN_DURATION;

  return (
    <InputWithField
      name={LOAN_TERM}
      validate={combineValidators(required, isPositive, isInteger, maxMonths(maxLoanDuration))}
      type="number"
    />
  );
};

const DefaultPartner = chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: CORONA_FINANCING_OPTION,
      value: CORONA_FINANCING_OPTION__QUICK_CREDIT,
      component: CoronaQuickCreditLoanDuration,
    },
    {
      step: FINANCING_NEED,
      field: CORONA_FINANCING_OPTION,
      value: CORONA_FINANCING_OPTION__KFW_PROGRAM,
      component: CoronaOtherProgramLoanDuration,
    },
  ],
  DefaultLoanDuration,
);

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagLoanDuration,
  default: DefaultPartner,
});
