import _get from 'lodash/get';

import {
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapRentalParametersFromApi = (response: unknown) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const rentalParametersValues = {
    [PROFITABILITY_OPERATING_COSTS_RESIDENTIAL]:
      _get(response, 'residential_operating_costs') || initialValue,
    [PROFITABILITY_OPERATING_COSTS_COMMERCIAL]:
      _get(response, 'commercial_operating_costs') || initialValue,
    [PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO]: _get(response, 'depreciation') || initialValue,
    [PROFITABILITY_TAX_RATE_IN_PERCENT]: _get(response, 'tax_rate') || initialValue,
  };

  return rentalParametersValues;
};

export default mapRentalParametersFromApi;
