import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import mapProfitabilityFromApi from 'modules/EditMarketValuesReeProfitability/mapProfitabilityFromApi';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { flattenObject } from 'utils/helpers';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

const getEditProfitabilitySectionAction = (inquiryId: string, recordType: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.PREFILL_PROFITABILITY_DETAILS.compose({
      params: { inquiryId },
      query: { profitability_section_type: recordType },
    }),
    method: API_METHODS.GET,
  });

const loadEditProfitabilitySection = async (
  makeCall: IMakeCall,
  inquiryId: string,
  recordType: PROFITABILITY_RECORD_TYPE,
) => {
  // To Do: Remove this logic if this get's handled on backend
  if (
    [PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT, PROFITABILITY_RECORD_TYPE.SALES_COST_RATE].includes(
      recordType,
    )
  )
    recordType = PROFITABILITY_RECORD_TYPE.SALES_COST_RATE;
  const { payload, error } = await makeCall(
    getEditProfitabilitySectionAction(inquiryId, recordType),
  );
  const data: unknown = _get(payload, 'data');
  let editFormValues;
  editFormValues = mapProfitabilityFromApi(data)[recordType];
  FormFieldController.instance.saveBulk(flattenObject(editFormValues), { emit: false });
  return { error };
};

export default loadEditProfitabilitySection;
