import { useSelector } from 'react-redux';

import useLanguagePreferences from 'components/App/LanguageProvider/useLanguagePreferences';
import { getInquiryFilesAction } from 'store/inquiryProcess/actions';
import { getCreatedInquiryId } from 'store/progress/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export interface IInquiryFile {
  attributes: {
    description: string;
    file_key: string;
    predefined: boolean;
    title: string;
  };
  id: string;
  relationships: {
    uploaded_file: { data: any };
  };
  type: string;
}

export const useGetInquiryFiles = () => {
  const inquiryId = useSelector(getCreatedInquiryId);
  const { makeCall } = useDispatchApiCall();
  const { selectedLanguage } = useLanguagePreferences();

  return async () => {
    const { error, payload } = await makeCall(getInquiryFilesAction(inquiryId, selectedLanguage));
    return { error, payload };
  };
};
