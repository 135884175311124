import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import {
  FILTERS_SALE_TYPES,
  PROFITABILITY_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';

import ProfitabilitySale, { SaleType } from '../../ProfitabilitySale';
import LendingValuesDetermination, { LendingValueType } from './LendingValuesDetermination';

interface ISaleAsBuilding {
  data: {
    lending_value_determination_data: LendingValueType;
    sale_as_building_data: SaleType;
  };
}

type Props = ISaleAsBuilding;

const SaleAsBuilding: React.FC<Props> = ({ data }) => {
  const { saleType } = useSelector(getMarketDataFilter);

  return (
    <Condition condition={saleType === FILTERS_SALE_TYPES.BUILDING}>
      <ProfitabilitySale
        data={data?.sale_as_building_data}
        profitabilityFormType={PROFITABILITY_RECORD_TYPE.SALES_COST_RATE}
      />
      <LendingValuesDetermination data={data?.lending_value_determination_data} />
    </Condition>
  );
};

export default SaleAsBuilding;
