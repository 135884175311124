import React from 'react';

import {
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import BuildingSections from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingSections';
import CommercialVehicleSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CommercialVehicle/CommercialVehicleSection';
import CoronaSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/CoronaSection';
import DebtReschedulingSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/DebtReschedulingSection';
import GoodsSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/GoodsSection';
import Guaranty from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/GuarantySection/Guaranty';
import MachineSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/MachineSection';
import OfficeEquipmentSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/OfficeEquipmentSection';
import OtherSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/OtherSection';
import ProjectFinancingDetails from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ProjectFinancingDetails';
import RealEstateSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/RealEstateSection';
import { OnlineFactoringSections } from 'onlinefactoring/purpose/sections/OnlineFactoringSections';
import { useFieldChangedValue } from 'store/hooks/useFormValues';

import BfsServiceDetailSection from './BfsServiceSections/BfsServiceDetailSection';
import { EnergyEfficiencySections } from './EnergyEfficiencySections/EnergyEfficiencySections';
import { KfinanzSections } from './KfinanzSections/KfinanzSections';
import LiquiditySection from './LiquiditySection';

const purposeKindMap = {
  [PURPOSE_KIND__CORONA]: <CoronaSection />,
  [PURPOSE_KIND__REAL_ESTATE]: <RealEstateSection />,
  [PURPOSE_KIND__GOODS]: <GoodsSection />,
  [PURPOSE_KIND__MACHINE]: <MachineSection />,
  [PURPOSE_KIND__OFFICE_EQUIPMENT]: <OfficeEquipmentSection />,
  [PURPOSE_KIND__DEBT_RESCHEDULING]: <DebtReschedulingSection />,
  [PURPOSE_KIND__VEHICLE]: <CommercialVehicleSection />,
  [PURPOSE_KIND__OTHER]: <OtherSection />,
  [PURPOSE_KIND__PROJECT_FINANCING]: <ProjectFinancingDetails />,
  [PURPOSE_KIND__BUILDING]: <BuildingSections />,
  [PURPOSE_KIND__GUARANTY]: <Guaranty />,
  [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: <LiquiditySection />,
  [PURPOSE_KIND__KFINANZ]: <KfinanzSections />,
  [PURPOSE_KIND__CREFO_FACTORING]: <OnlineFactoringSections />,
  [PURPOSE_KIND__ENERGY_EFFICIENCY]: <EnergyEfficiencySections />,
  [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: <BfsServiceDetailSection />,
  [PURPOSE_KIND__FACTOMAT]: <BfsServiceDetailSection />,
};

const PurposeDetailsSection = () => {
  const selectedPurposeKind = useFieldChangedValue(PURPOSE_KIND, null);
  const purposeDetailsSection = purposeKindMap[selectedPurposeKind];

  if (!purposeDetailsSection) {
    return null;
  }
  return purposeDetailsSection;
};

export default PurposeDetailsSection;
