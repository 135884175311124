import React from 'react';

import { DrawerFooter } from '@chakra-ui/react';
import { useForm } from 'react-final-form';

import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { initialState } from 'store/inquiryList/reducer';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  handleReset: () => void;
  handleSubmit: (event: any) => void;
  onClose: () => void;
};

export const FiltersDrawerFooter = ({ handleReset, handleSubmit, onClose }: Props) => {
  const t = useTranslations();
  const form = useForm();
  const fieldController = FormFieldController.instance;

  const resetAllFields = () => {
    /*
    We cannot use reset here due to some timing problem with Redux.
    Reset is called before our values are reset to the initialValues, thus
    we use change here to explicitly change the values to their initial state.
     */

    const initialValues = initialState.options.filterBy;
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((field) => {
      form.change(
        field,
        field === INQUIRY_FILTERS.STATUS || INQUIRY_FILTERS.INQUIRY_MANAGER
          ? undefined
          : initialValues[field],
      );
      fieldController.saveValue(
        field,
        field === INQUIRY_FILTERS.STATUS || INQUIRY_FILTERS.INQUIRY_MANAGER
          ? undefined
          : initialValues[field],
      );
    });

    handleReset();
  };

  return (
    <DrawerFooter>
      <ButtonComponent
        data-testid="filters-drawer-reset-button"
        mr={4}
        onClick={() => {
          handleReset();
          resetAllFields();
        }}
        variant="tertiary"
      >
        {t('pages.inquiryList.filtersDrawer.buttons.reset')}
      </ButtonComponent>

      <ButtonComponent
        data-testid="filters-drawer-submit-button"
        type="submit"
        onClick={(event) => {
          handleSubmit(event);
          onClose();
        }}
      >
        {t('pages.inquiryList.filtersDrawer.buttons.filter')}
      </ButtonComponent>
    </DrawerFooter>
  );
};
