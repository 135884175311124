import React from 'react';

import { useIntl } from 'react-intl';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import SMSVerification from 'components/SMSVerification';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  END_USER_CONDITION_INTEREST_RATE,
  END_USER_CONDITION_AMOUNT,
  INTERESTED_IN_INSURANCE,
  LOAN_TERM,
  USER_PROFILE_PHONE_NUMBER,
  BANK_DETAILS_IBAN,
} from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';

import { IbanField } from './IbanField';
import LoanDuration from './LoanDuration/LoanDuration';
import { AnnuityLoanType } from './LoanType/LoanType';

export const fixedAnnuityLoanFields = [
  LOAN_TERM,
  INTERESTED_IN_INSURANCE,
  USER_PROFILE_PHONE_NUMBER,
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
  BANK_DETAILS_IBAN,
];

export const fixedAnnuityLoanSectionValidationCondition = ({ form }: any) =>
  fieldsAreValid(fixedAnnuityLoanFields)({ form });

export const fixedAnnuityLoanSectionValidation = (form: any) =>
  fieldsAreValid(fixedAnnuityLoanFields)({ form });

const FixedAnnuityLoan = () => {
  const { formatMessage } = useIntl();

  return (
    <FormSection
      name={FormSections.fixedAmortisableLoan}
      title={formatMessage({ id: 'pages.requestDetails.sections.loan.title' })}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<LoanIcon />} />
      </FormRow>
      <FormRow>
        <AnnuityLoanType />
        <LoanDuration />
      </FormRow>
      <SMSVerification />
      <FormRow>
        <YesNoRadioGroupWithField name={INTERESTED_IN_INSURANCE} />
      </FormRow>
      <IbanField />
    </FormSection>
  );
};

export default FixedAnnuityLoan;
