import { InquiryRevisionListElement } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';

interface RevisionState {
  revisionList: InquiryRevisionListElement[];
  selectedRevisionId: string;
  canGetPrevious: boolean;
  canGetNext: boolean;
}

export const getNextRevisionIdSelector = (state: RevisionState) => {
  const currentSelectionIndex = state.revisionList.findIndex(
    (rev) => rev.id === state.selectedRevisionId,
  );
  return state.revisionList[currentSelectionIndex + 1].id;
};

export const getPreviousRevisionIdSelector = (state: RevisionState) => {
  const currentSelectionIndex = state.revisionList.findIndex(
    (rev) => rev.id === state.selectedRevisionId,
  );
  return state.revisionList[currentSelectionIndex - 1].id;
};
