import _get from 'lodash/get';

import {
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_BUFFER,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapInvestmentCostsFromApi = (response: unknown) => {
  const plotCosts = _get(response, 'plot_costs');
  const otherCosts = _get(response, 'other_costs');
  const buildingCosts = _get(response, 'building_costs');
  const financingCosts = _get(response, 'financing_costs');
  const ancillaryConstructionCosts = _get(response, 'ancillary_construction_costs');
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const financingParameterValues = {
    [PROFITABILITY_AGENT_FEES]: _get(plotCosts, 'agent_fee') || initialValue,
    [PROFITABILITY_LEGAL_FEES]: _get(plotCosts, 'legal_fee') || initialValue,
    [PROFITABILITY_OTHER_FEES]: _get(plotCosts, 'other_fees') || initialValue,
    [PROFITABILITY_OTHER_COSTS]: _get(plotCosts, 'other_costs') || initialValue,
    [PROFITABILITY_COMPENSATIONS]: _get(plotCosts, 'compensations') || initialValue,
    [PROFITABILITY_PURCHASE_PRICE]: _get(plotCosts, 'purchase_price') || initialValue,
    [PROFITABILITY_PUBLIC_DISPOSAL]: _get(plotCosts, 'public_disposal') || initialValue,
    [PROFITABILITY_MEASUREMENT_COSTS]: _get(plotCosts, 'measurement_costs') || initialValue,
    [PROFITABILITY_NON_PUBLIC_DISPOSAL]: _get(plotCosts, 'non_public_disposal') || initialValue,
    [PROFITABILITY_OTHER_PURCHASE_COSTS]: _get(plotCosts, 'other_purchase_costs') || initialValue,
    [PROFITABILITY_LAND_DEVELOPMENT_COSTS]:
      _get(plotCosts, 'land_development_costs') || initialValue,
    [PROFITABILITY_OTHER_DEVELOPMENT_COSTS]:
      _get(plotCosts, 'other_development_costs') || initialValue,
    [PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]:
      _get(plotCosts, 'real_estate_transfer_tax') || initialValue,
    [PROFITABILITY_BUFFER]: _get(otherCosts, 'buffer') || initialValue,
    [PROFITABILITY_MARKETING_SALES_COSTS]:
      _get(otherCosts, 'marketing_sales_costs') || initialValue,
    [PROFITABILITY_CONSTRUCTION_COSTS]: _get(buildingCosts, 'construction_costs') || initialValue,
    [PROFITABILITY_OTHER_CONSTRUCTION_COSTS]:
      _get(buildingCosts, 'other_construction_costs') || initialValue,
    [PROFITABILITY_OUTDOOR_FACILITIES_COSTS]:
      _get(buildingCosts, 'outdoor_facilities_costs') || initialValue,
    [PROFITABILITY_FINANCING_COSTS_FEES_MANUAL]:
      _get(financingCosts, 'financing_costs_fees_manual') || initialValue,
    [PROFITABILITY_THIRD_PARTY_FINANCING_COSTS]:
      _get(financingCosts, 'third_party_financing_costs') || initialValue,
    [PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL]:
      _get(financingCosts, 'financing_costs_interests_manual') || initialValue,
    [PROFITABILITY_ARCHITECT_COSTS]:
      _get(ancillaryConstructionCosts, 'architect_costs') || initialValue,
    [PROFITABILITY_ADDITIONAL_COSTS]:
      _get(ancillaryConstructionCosts, 'additional_costs') || initialValue,
  };

  return financingParameterValues;
};

export default mapInvestmentCostsFromApi;
