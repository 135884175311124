import React from 'react';

import {
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_AMORTISATION_KIND,
  FAVORED_DECISION_DATE,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LEASING_TAKEOVER,
} from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import Collaterals from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection/CollateralsInformation';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useTranslations } from 'utils/hooks/useTranslations';

const EXCLUDED_FIELDS = [
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_AMORTISATION_KIND,
  FAVORED_DECISION_DATE,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LEASING_TAKEOVER,
];

const DvagInquiryDetailsSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.DETAILS,
    excludedFields: EXCLUDED_FIELDS,
  });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('inquiryDetails')}>
      <InformationRows fields={fields} />
      <Collaterals />
    </Section>
  );
};

export default DvagInquiryDetailsSection;
