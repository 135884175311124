import React from 'react';

import { useDownloadFile } from 'components/DownloadBlock/useDownloadFile';
import { IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IDownloadFileInRequest {
  file: IFile | CompeonDocument;
}

const DownloadFileInRequest = ({ file }: IDownloadFileInRequest) => {
  const t = useTranslations('components.upload');
  const downloadFile = useDownloadFile({ fileId: file.id, fileName: file.fileName });

  return (
    <ButtonComponent
      data-testid="download-file-btn"
      leftIcon={<DownloadIcon boxSize={6} d="block" />}
      onClick={downloadFile}
    >
      {t('download')}
    </ButtonComponent>
  );
};

export default DownloadFileInRequest;
