import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import Condition from 'modules/ConditionalSubform/Condition';
import { not } from 'modules/Inquiry/Form/formConditions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import HirePurchaseIcon from 'theme/components/Icon/ProductKind/HirePurchaseIcon';

import HirePurchaseAdvancePayment from './fields/HirePurchaseAdvancePayment';
import HirePurchaseTermInMonths from './fields/HirePurchaseTermInMonths';

const HirePurchaseSummary = () => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<HirePurchaseIcon />} />
      </FormRow>
      <FormRow separator>
        <HirePurchaseAdvancePayment />
        <HirePurchaseTermInMonths />
      </FormRow>
    </>
  );
};

const DvagHirePurchaseSummary = () => {
  return (
    <>
      <Condition
        condition={not(fieldBusinessConditions.hasCalculatedAmountField)}
        isBusinessCondition
      >
        <HirePurchaseSummary />
      </Condition>
      <Condition condition={fieldBusinessConditions.hasCalculatedAmountField} isBusinessCondition>
        <FormRow separator>
          <HirePurchaseTermInMonths />
        </FormRow>
      </Condition>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagHirePurchaseSummary,
  default: HirePurchaseSummary,
});
