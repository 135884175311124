import * as React from 'react';

import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { useCanShowField } from 'new/form/hooks/useCanShowField';
import { useTranslations } from 'utils/hooks/useTranslations';

export const FinanceWholeProjectRadio = () => {
  const t = useTranslations(
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .financingWholeProject.options.sectionString,
  );

  const financeWholeProjectOptions: Array<{ caption: string; value: 'true' | 'false' }> = [
    { caption: t('buyingPhase'), value: 'false' },
    { caption: t('wholeProject'), value: 'true' },
  ];

  const canShowFinanceWholeProject = useCanShowField(
    mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject,
  );

  return (
    <Condition validation={canShowFinanceWholeProject}>
      <FormRow>
        <YesNoRadioGroupWithField
          name={mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject}
          options={financeWholeProjectOptions}
        />
      </FormRow>
    </Condition>
  );
};
