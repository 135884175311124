import _get from 'lodash/get';
import snakecaseKeys from 'snakecase-keys';

import { API_METHODS, callReverseApi } from 'api/apiService';
import { LOAD_USER_ATTRIBUTES } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const getUserDataAction = () =>
  callReverseApi({
    url: endpoints.OP_USERS.PROFILE.compose(),
    method: API_METHODS.GET,
  });

export const getUserAttributesAction = () =>
  callReverseApi({
    url: endpoints.USERS.ME.compose(),
    method: API_METHODS.GET,
    actionName: LOAD_USER_ATTRIBUTES,
  });

export const saveUserDataAction = (formData: any) =>
  callReverseApi({
    url: endpoints.OP_USERS.LIST.compose(),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: snakecaseKeys(formData),
      },
    },
  });
export const getLoggedInInquiryManager = async (makeCall: any) => {
  const { payload } = await makeCall(getUserDataAction());
  return {
    id: _get(payload, 'data.data.id'),
    hasAssignedZipCodes: _get(payload, 'data.data.attributes.related_companies_zip_code_ranges')
      .length,
  };
};
