import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { MARKET_DATA_ADDRESS_SELECTION } from 'modules/Inquiry/Form/formFields';
import { mapMarketInformationToApi } from 'modules/Inquiry/mapInquiryToApi/MarketInformationMapper/mapMarketInformationToApi';
import { useRequestMarketData } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import { useToasts } from 'shared/hooks/useToasts';
import { marketDataSentAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import MittweidaMarketInfoAddressSelectionPage from '../../../../mittweida/portals/operations/steps/ReceiveMarketValues/MarketInfoAddressSelectionPage';
import { InquiryType } from '../../../../modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import MarketInfoAddressSelectionHeader from './MarketInfoAddressSelectionHeader';

type IAddressList = {
  value: string;
  label: string;
};

const MarketInfoAddressSelectionPage: React.FC = () => {
  const [addressList, setAddressList] = useState<IAddressList[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const t = useTranslations('pages.marketInformationInquiryPage.marketInfoAddressPage');
  const marketAddressValues = {};
  const inquiryId = useSelector(getCreatedInquiryId);
  const tL = useTranslations();
  const submitLabel = tL('pages.finishRegistration.confirmBtn');
  const cancelLabel = tL('buttons.cancel');
  const suceessToast = tL('pages.inquiryDetails.dashboard.actions.triggerMarketResearch.success');

  const { financingPurposeFields } = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const { isPending, requestMarketData } = useRequestMarketData(inquiryId!);

  const marketData = mapMarketInformationToApi(financingPurposeFields);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await requestMarketData(marketData);
      if (res && res.error) {
        if (res.error.response.status === 409) {
          const dropDownAddress = res.error.response.data?.addresses.map(
            (address: IAddressList) => {
              return {
                value: address.value,
                label: address.label,
              };
            },
          );
          setAddressList(dropDownAddress);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();
  const path = paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId!);
  const redirectToDashboard = () => history.push(path);

  const { success } = useToasts();

  const handleSubmit = async () => {
    if (selectedAddress) {
      const addressZoneArr = selectedAddress.split(',').map((item: string) => item.trim());
      const res = await requestMarketData({
        ...marketData,
        control_object_data: {
          ...marketData.control_object_data,
          address: addressZoneArr[0],
          zip_code: addressZoneArr[1],
        },
      });
      if (res) {
        dispatch(marketDataSentAction());
        success({ description: suceessToast });
        redirectToDashboard();
      }
    }
  };

  return (
    <>
      <Form
        initialValues={marketAddressValues}
        onSubmit={() => {}}
        render={() => (
          <form>
            <MarketInfoAddressSelectionHeader />
            <FormSection sectionNumber={1} title={t('addressInput.label')}>
              <SelectWithField
                name={MARKET_DATA_ADDRESS_SELECTION}
                options={addressList}
                onChange={(value: string) => {
                  setSelectedAddress(value);
                }}
                data-testid={'select-field-' + MARKET_DATA_ADDRESS_SELECTION}
              />
            </FormSection>
          </form>
        )}
      />
      <StyledActions>
        <ButtonComponent mr={4} onClick={redirectToDashboard} variant="tertiary">
          {cancelLabel}
        </ButtonComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
          type="submit"
          onClick={handleSubmit}
          disabled={isPending}
        >
          {submitLabel}
        </ButtonComponent>
      </StyledActions>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: MarketInfoAddressSelectionPage,
  [InquiryType.profiMittweida]: MittweidaMarketInfoAddressSelectionPage,
});
