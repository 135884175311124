import * as React from 'react';

import { ProgressCalculationContext } from '../../modules/FormPage';
import { FormSections } from '../../schema/inquirySchema.models';
import CallOnMount from './CallOnMount';
import { FormSectionContext } from './FormSection.context';
import FormSectionLayout from './FormSectionLayout';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  sectionNumber?: number;
  title: string;
  name?: FormSections | string;
  showSectionTitle?: boolean;
  hideBottomBorder?: boolean;
  hideTopBorder?: boolean;
};

const FormSection = ({
  children,
  disabled,
  sectionNumber = 1,
  title,
  name,
  showSectionTitle = true,
  hideBottomBorder,
  hideTopBorder,
}: Props) => {
  return (
    <FormSectionContext.Provider value={name ?? FormSections.root}>
      <ProgressCalculationContext.Consumer>
        {(recalculate) => (
          <CallOnMount callback={recalculate}>
            <FormSectionLayout
              title={title}
              sectionNumber={sectionNumber}
              showSectionTitle={showSectionTitle}
              hideBottomBorder={hideBottomBorder}
              hideTopBorder={hideTopBorder}
              hideSectionNumber={hideTopBorder}
              disabled={disabled}
            >
              {children}
            </FormSectionLayout>
          </CallOnMount>
        )}
      </ProgressCalculationContext.Consumer>
    </FormSectionContext.Provider>
  );
};

export default FormSection;
