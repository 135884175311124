import React from 'react';

import { Field } from 'react-final-form';

import { InputWithField } from 'components/Input';
import {
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_TERM,
  FINANCING_NEED,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
} from 'modules/Inquiry/Form/formFields';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useFieldValue } from 'store/hooks/useFormValues';

const fieldController = FormFieldController.instance;

const LoanInterestPeriodInMonths = () => {
  return <InputWithField name={LOAN_INTEREST_PERIOD_IN_MONTHS} type="number" />;
};

const CoronaLoanInterestPeriodInMonths = () => {
  const loanTerm = useFieldValue(LOAN_TERM, '');

  return (
    <Field
      name={LOAN_INTEREST_PERIOD_IN_MONTHS}
      render={({ input }) => {
        input.onChange(loanTerm);
        fieldController.saveValue(input.name, loanTerm, { emit: false });
        return null;
      }}
    />
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaLoanInterestPeriodInMonths,
    },
  ],
  LoanInterestPeriodInMonths,
);
