import { useState, useEffect } from 'react';

import _get from 'lodash/get';

import { END_USER_FIRST_NAME, END_USER_LAST_NAME } from 'modules/Inquiry/Form/formFields';
import { fetchCompanySuggestions } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/service';
import { CompanySuggestion } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/CompanySuggestion.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const mapApiCompanySuggestions = (apiCompany: any): CompanySuggestion => ({
  address: apiCompany.attributes.address,
  city: apiCompany.attributes.city,
  country: apiCompany.attributes.country,
  'crefo-id': apiCompany.attributes['crefo-id'],
  name: apiCompany.attributes.name,
  state: apiCompany.attributes.state,
  street: apiCompany.attributes.street,
  'trade-name': apiCompany.attributes['trade-name'],
  'zip-code': apiCompany.attributes['zip-code'],
});

export const useFetchCompanySuggestions = (companyQuery: string) => {
  const [companies, setCompanies] = useState<CompanySuggestion[]>([]);
  const { makeCall, isPending } = useDispatchApiCall({
    isPendingInitially: true,
    showErrorNotification: true,
    errorMessage: '',
  });
  useEffect(() => {
    if (companyQuery) {
      makeCall(fetchCompanySuggestions(companyQuery), ({ payload }: any) => {
        const mappedData = _get(payload, 'data.data').map(mapApiCompanySuggestions) || [];
        setCompanies(mappedData);
      });
    }
  }, [companyQuery, makeCall]);

  return { isPending, companies };
};

export const printCompanyNameFromSso = (ssoData: any) => {
  if (!ssoData) {
    return '';
  }

  const firstName = ssoData[END_USER_FIRST_NAME] || '';
  const lastName = ssoData[END_USER_LAST_NAME] || '';

  if (!firstName || !lastName) {
    return '';
  }

  return `${firstName} ${lastName}`;
};
