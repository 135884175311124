import * as React from 'react';
import { useState } from 'react';

import { AddIcon, CloseIcon } from '@chakra-ui/icons';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import paths from 'constants/paths';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_CONSENT,
  USER_PROFILE_FOUNDING_YEAR,
  COMPANY_DETAILS_DATA_SECURITY,
  COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import ButtonComponent from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isValidInstitutionNumber, mustBeTrue } from 'utils/validators';

import FoundingMonth from './FoundingMonth';
import FoundingYear from './FoundingYear';
import LegalForm from './LegalForm/LegalForm';

export const BfsServiceCompletingCompanyDetails: React.FC = () => {
  const tCheckBox = useTranslations(
    'pages.companyDetails.sections.termsAndConditions.bfsService.fields',
  );
  const tBFSs = useTranslations('inquiryType.bfsService.steps.companyDetails.sections.fields');
  const t = useTranslations();
  const fieldController = FormFieldController.instance;
  const institutionalFormValues = fieldController.formValues[COMPANY_DETAILS_INSTITUTIONAL_NUMBERS];
  let institureInitializer: {} = { field0: '' };

  if (typeof institutionalFormValues === 'object' && institutionalFormValues) {
    institureInitializer = institutionalFormValues;
  }
  const [insitutionalFields, setInsitutionalFields] =
    useState<{ [key: string]: string }>(institureInitializer);

  React.useEffect(() => {
    fieldController.saveValue(COMPANY_DETAILS_INSTITUTIONAL_NUMBERS, null);
    let noWhiteSpaceObj: { [key: string]: string } = {};
    Object.keys(insitutionalFields).forEach((key) => {
      const value = insitutionalFields[key].replace(/\s/g, '');
      noWhiteSpaceObj[key] = value;
    });

    fieldController.saveValue(COMPANY_DETAILS_INSTITUTIONAL_NUMBERS, noWhiteSpaceObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insitutionalFields]);

  const addMoreInstitutionalIdentifiers = () => {
    const key = `field-${Math.random().toString(36).slice(2, 7)}`;
    setInsitutionalFields({ ...insitutionalFields, [key]: '' });
  };

  const setCurrentField = (value: string, key: string) => {
    setInsitutionalFields({ ...insitutionalFields, [key]: value });
  };

  const clearCurrentField = (index: string) => {
    const temp = Object.fromEntries(
      Object.entries(insitutionalFields).filter(([key]) => !key.includes(index)),
    );
    setInsitutionalFields({ ...temp });
    fieldController.deleteValue(index);
  };

  return (
    <React.Fragment>
      <FormSection
        name={FormSections.companyDetails}
        sectionNumber={2}
        title={t('pages.userProfile.sections.completingCompanyDetails.bfsService.section2')}
      >
        <FormRow>
          <LegalForm tooltip={tBFSs('userProfileLegalForm.tooltip')} />
        </FormRow>
        <FormRow>
          <FoundingYear />
          <Condition condition={fieldIsValid(USER_PROFILE_FOUNDING_YEAR)}>
            <FoundingMonth />
          </Condition>
        </FormRow>
        <FormRow>
          <InputWithField
            caption={tBFSs('companyTaxNumber.caption')}
            name={COMPANY_DETAILS_COMPANY_TAX_NUMBER}
            captionTooltip={tBFSs('companyTaxNumber.tooltip')}
            optional
            noWhiteSpaces={true}
          />
        </FormRow>

        {Object.entries(insitutionalFields).map(([key, value]) => (
          <FormRow>
            <InputWithField
              caption={tBFSs('institutionalIdentifier.caption')}
              name={key}
              value={value}
              validate={isValidInstitutionNumber(t('errors.isValidInstitutionNumber'))}
              captionTooltip={tBFSs('institutionalIdentifier.tooltip')}
              onChange={(e: string) => setCurrentField(e, key)}
              sideSymbol={() => <CloseIcon w={6} h={4} onClick={() => clearCurrentField(key)} />}
              noWhiteSpaces={true}
              optional
            />
          </FormRow>
        ))}
        <FormRow>
          <ButtonComponent
            as="a"
            data-testid="edit-inquiry-btn"
            leftIcon={<AddIcon w={6} h={6} />}
            variant="secondary"
            onClick={addMoreInstitutionalIdentifiers}
          >
            {tBFSs('addMoreInstitutionalIdentifier')}
          </ButtonComponent>
        </FormRow>
      </FormSection>
      <FormSection
        name={FormSections.companyDetails}
        sectionNumber={3}
        title={t('pages.userProfile.sections.completingCompanyDetails.bfsService.section3')}
      >
        <FormRow>
          <CheckboxWithField
            name={COMPANY_DETAILS_CONSENT}
            validate={mustBeTrue(t('errors.mustBeTrue'))}
            text={
              <span>
                {tCheckBox('contactPersonSecurityConsent.label', {
                  termsAndConditions: (
                    <a href={paths.termsAndConditions}>
                      {tCheckBox('contactPersonSecurityConsent.links.termsAndConditions')}
                    </a>
                  ),
                  AGB: <a href={paths.privacyPolicy}>AGB</a>,
                })}
              </span>
            }
          />
        </FormRow>
        <FormRow>
          <CheckboxWithField
            name={COMPANY_DETAILS_DATA_SECURITY}
            validate={mustBeTrue(t('errors.mustBeTrue'))}
            text={
              <span>
                {tCheckBox('legalNotice.label', {
                  privacyPolicy: (
                    <a href={paths.privacyPolicy}>{tCheckBox('legalNotice.links.privacyPolicy')}</a>
                  ),
                })}
              </span>
            }
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};
