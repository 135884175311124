import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PrivateRoute from 'components/PrivateRoute';
import paths from 'constants/paths';
import { isAgent } from 'store/user/selectors';

const { operation: operationPaths } = paths;

const OperationPrivateRoute = ({ isAuthorized, ...rest }) => (
  <PrivateRoute isAuthorized={isAuthorized} authPath={operationPaths.login} {...rest} />
);

OperationPrivateRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  isAuthorized: isAgent(store),
});

export default connect(mapStateToProps)(OperationPrivateRoute);
