import _range from 'lodash/range';

const TRANSLATION_ID = 'pages.requestDetails.sections.loan.fields.loanTerm.options';
const YEAR_STEP = 12;

const getMonths = (translate) =>
  _range(1, 13).map((idx) => ({
    value: idx.toString(),
    label: translate(`${TRANSLATION_ID}.month${idx}`),
  }));

const getYearsAsMonths = (
  translate,
  yearStartDuration = 24,
  yearEndDuration,
  yearDifference = YEAR_STEP,
) =>
  _range(yearStartDuration, yearEndDuration + YEAR_STEP, yearDifference).map((idx) => ({
    value: idx.toString(),
    label: translate(`${TRANSLATION_ID}.month${idx}`),
  }));

export const getLoanDurationSelectValues = (translate, yearDuration = 84) => [
  ...getMonths(translate),
  ...getYearsAsMonths(translate, 24, yearDuration),
];

export const getDvagRealEstateLoanDurationSelectValues = (translate, yearDuration = 84) => [
  ...getMonths(translate),
  ...getYearsAsMonths(translate, 24, yearDuration),
  ...getYearsAsMonths(translate, 15 * YEAR_STEP, 35 * YEAR_STEP, 5 * YEAR_STEP),
];
