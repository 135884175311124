import { COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY } from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

import {
  validateFields,
  ValidateSectionFunction,
} from '../../../../../../new/form/useValidateFields';
import { isLoggedInSelector } from '../../../../../../store/user/selectors';
import { mittweidaFields } from '../../../../fields';

export const validateCompanyDetailsSection: ValidateSectionFunction = (form, values, store) => {
  const isLoggedIn = isLoggedInSelector(store.getState());
  const { loggedInUserCompanyName } = values.companyDetailPage.companyDetailsSection;

  if (!isLoggedIn) {
    return validateFields({ fields: [selectedCompanyName], values, form });
  } else if (
    isLoggedIn &&
    loggedInUserCompanyName !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    return validateFields({ fields: [loggedInUserCompanyFieldName], values, form });
  } else {
    return validateFields({
      fields: [loggedInUserCompanyFieldName, selectedCompanyName],
      values,
      form,
    });
  }
};

const { selectedCompanyName, loggedInUserCompanyName: loggedInUserCompanyFieldName } =
  mittweidaFields.companyDetailPage.companyDetailsSection;

export const companyDetailsSectionValidationMap = {
  [selectedCompanyName]: fieldValidators.string().required(),
  [loggedInUserCompanyFieldName]: fieldValidators.string().required(),
};
