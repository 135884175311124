import React from 'react';

import { PortalConfigContext } from 'new/portals/state/portalConfigContext/index';

export function usePortalConfig() {
  const context = React.useContext(PortalConfigContext);

  if (context === undefined) {
    throw new Error('usePortalConfig must be used within a PortalConfigProvider');
  }

  return context;
}
