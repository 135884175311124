import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const InvestmentLoanInterestedInOtherProducts: React.FC = () => {
  return <YesNoRadioGroupWithField name={INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS} />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => null,
  default: InvestmentLoanInterestedInOtherProducts,
});
