import React, { useEffect } from 'react';

import { Box, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import FormSection from 'components/FormSection';
import SMSVerification from 'components/SMSVerification';
import { REQUEST_DETAILS } from 'modules/Inquiry/Form/formFields';
import { IbanField } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/IbanField';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import BfsServiceFurtherNeedsSection from './BfsServiceFurtherNeedsSection';
import BfsServiceNotesSection from './BfsServiceNotesSection';
import { FinancingDetailsHeader } from './FinancingDetailsLeadHeader';
import {
  useRequestDetailsContractValidators,
  useRequestDetailsLeadValidators,
} from './sectionValidators';

const BFSServiceFinancingDetailsLeadStep = () => {
  const t = useTranslations();
  const lane = useSelector(getProcessLane);
  const isContractLane = lane === InquiryLane.contract;
  const contractLaneValidators = useRequestDetailsContractValidators();
  const leadLaneValidators = useRequestDetailsLeadValidators();
  const validators = isContractLane ? contractLaneValidators : leadLaneValidators;
  const [sectionValidators, setSectionValidators] = React.useState<any>(() => validators);

  useEffect(() => {
    const isContract = lane === InquiryLane.contract;
    if (isContract) {
      setSectionValidators(() => contractLaneValidators);
    } else {
      setSectionValidators(() => leadLaneValidators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lane]);

  const phoneNumberCaption = 'phoneNumberCaption';

  return (
    <InquiryFormNew
      headerComp={<FinancingDetailsHeader />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={sectionValidators}
    >
      {() =>
        isContractLane ? (
          <>
            <FormSection
              name={FormSections.offer}
              sectionNumber={1}
              title={t(
                'inquiryType.onlinefactoring.steps.financingDetails.section.conditions.title',
              )}
            >
              <SMSVerification phoneNumberCaption={phoneNumberCaption} />
            </FormSection>
            <FormSection
              name={FormSections.bankDetails}
              sectionNumber={2}
              title={t(
                'inquiryType.onlinefactoring.steps.financingDetails.section.bankDetails.title',
              )}
            >
              <IbanField />
            </FormSection>
            <BfsServiceFurtherNeedsSection />
            <BfsServiceNotesSection />
          </>
        ) : (
          <FinancingDetailsText />
        )
      }
    </InquiryFormNew>
  );
};

const FinancingDetailsLeadStep = () => {
  const sectionValidators = useRequestDetailsLeadValidators();
  const t = useTranslations();
  const lane = useSelector(getProcessLane);
  const isContract = lane === InquiryLane.contract;

  return (
    <InquiryFormNew
      headerComp={<FinancingDetailsHeader />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={sectionValidators}
    >
      {() =>
        isContract ? (
          <FormSection
            name={FormSections.offer}
            sectionNumber={1}
            title={t('inquiryType.onlinefactoring.steps.financingDetails.section.offer.title')}
          >
            <SMSVerification />
            <IbanField />
          </FormSection>
        ) : (
          <FinancingDetailsText />
        )
      }
    </InquiryFormNew>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BFSServiceFinancingDetailsLeadStep,
  default: FinancingDetailsLeadStep,
});

export const FinancingDetailsText = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.financingDetails.lead');

  return (
    <VStack spacing="24px" w="60%" m="auto">
      <Box boxSize="300px">
        <Asset type="inquiry" value={InquiryAssetKeys.OFFER} />
      </Box>
      <TextComponent color="brand.default" textAlign="center">
        {t('explanationTitle')}
      </TextComponent>
      <TextComponent color="text.tertiary" textAlign="center">
        {t('explanationSubtitle')}
      </TextComponent>
    </VStack>
  );
};
