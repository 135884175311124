import React from 'react';

import { NavLink } from 'react-router-dom';

import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import { StyledCompanyNav } from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  inquiryId: string;
  companyId: string;
}

const CompanySubnavigation: React.FC<Props> = ({ inquiryId, companyId }: Props) => {
  const t = useTranslations();

  return (
    <StyledCompanyNav subNav aria-labelledby={ELEMENT_IDS.COMPANY_NAVIGATION}>
      <ul>
        <li>
          <NavLink
            activeClassName="active"
            to={paths.operation.inquiryDetails.companyDetails.overview(inquiryId, companyId)}
          >
            {t('pages.companiesDetails.overview.title')}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            to={paths.operation.inquiryDetails.companyDetails.associatedPersons(
              inquiryId,
              companyId,
            )}
          >
            {t('pages.companiesDetails.associatedPerson.title')}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            to={paths.operation.inquiryDetails.companyDetails.riskAnalysis(inquiryId, companyId)}
          >
            {t('pages.companiesDetails.riskAnalysis.title')}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            to={paths.operation.inquiryDetails.companyDetails.financialInformation(
              inquiryId,
              companyId,
            )}
          >
            {t('pages.companiesDetails.financialInformation.title')}
          </NavLink>
        </li>
      </ul>
    </StyledCompanyNav>
  );
};

export default CompanySubnavigation;
