import React from 'react';

import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { END_USER_GENDER } from 'modules/Inquiry/Form/formFields';

const EndUserGender = () => {
  return <GenderWithField name={END_USER_GENDER} />;
};

export default EndUserGender;
