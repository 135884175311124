import styled from 'styled-components/macro';

export const StyledFieldWrapper = styled.div`
  display: flex;
  width: 60rem;
  margin-bottom: 3.5rem;

  > div {
    flex: 1 1 0%;
  }

  > div:first-child {
    margin-right: 4rem;
  }
`;
