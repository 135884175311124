import { textStyle } from '../../../themeConstants';

const Table = {
  parts: ['th', 'tr', 'td'],
  baseStyle: {
    th: {
      p: '4',
      borderColor: 'border.darkGrey',
      borderBottomWidth: '2px',
      ...textStyle.sizeM,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      letterSpacing: 'inherit',
      textTransform: 'inherit',
      color: 'text.tertiary',
    },
    tr: {
      _hover: {
        backgroundColor: 'brand.default_L93',
      },
    },
    td: {
      borderColor: 'border.lightGrey',
      borderBottomWidth: '1px',
      px: 4,
      ...textStyle.sizeM,
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      color: 'text.secondary',
    },
  },
};

export default Table;
