import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';

import { FormRowProps } from '.';

export const FormColumn = styled.div`
  ${mqMedium`
    border-right: 1px solid ${(props) => props.theme.colors.border.lightGrey};
    padding: 0 3.5rem;
  `}
`;

const FormRowWrapper = styled.div<Pick<FormRowProps, 'space' | 'alignItems'>>`
  :not(:last-child) {
    margin-bottom: ${({ space }) => space || '3.5rem'};
  }

  > *:not(:last-child) {
    margin-bottom: ${({ space }) => space || '3.5rem'};
  }

  ${mqMedium`
    display: flex;
    flex-direction: row;
    ${(props) => props.alignItems && 'align-items: '.concat(props.alignItems)};

    > * {
      flex: 1 1 0%;
    }

    > *:not(:last-child) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  `};

  ${FormColumn} {
    &:first-child {
      margin-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
`;

export default FormRowWrapper;
