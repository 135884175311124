import React from 'react';

import { Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import CompanyDetails from 'pages/inquiryFlow/CompanyDetails';
import FinancingNeed from 'pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import PeopleDetails from 'pages/inquiryFlow/PeopleDetails';
import RequestDetailsBigPicture from 'pages/inquiryFlow/RequestDetails/RequestDetailsBigPicture';
import SummaryPage from 'pages/inquiryFlow/SummaryPage/SummaryPageStep';
import UserProfile from 'pages/inquiryFlow/UserProfile';

import { SpecializedFinalPage } from './FinalPage/SpecializedFinalPage';

export const BigPictureForm: React.FC = () => {
  return (
    <Switch>
      <Route exact path={paths.financingNeed} component={FinancingNeed} />
      <Route exact path={paths.companyDetails} component={CompanyDetails} />
      <Route exact path={paths.requestDetails} component={RequestDetailsBigPicture} />
      <Route exact path={paths.summaryPage} component={SummaryPage} />
      <Route exact path={paths.userProfile} component={UserProfile} />
      <Route exact path={paths.peopleDetails} component={PeopleDetails} />
      <Route exact path={paths.finalPage} component={SpecializedFinalPage} />
    </Switch>
  );
};
