import _get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  IIndicativeCondition,
  IIndicativeOffer,
  IInquiryDetails,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ITransparencyRegister } from 'models/types/TransparencyRegister.types';
import {
  EXTERNAL_REFERENCE_ID,
  PRODUCT_KIND__PROPERTY_LOAN,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROPERTY_LOAN_KIND__CLASSIC,
} from 'modules/Inquiry/Form/formFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { CustomerConfirmationStatus } from 'pages/customerPortal/InquiryDetails/Dashboard/ConfirmationStatus/constants';

import { OFFER_STATE, OFFER_STATUS } from '../../modules/Offers/InquiryOffersSection/constants';
import { IState } from '../index';

export const getInquiryDetailsSelector = (state: IState) => state.inquiryDetails?.details;
export const getInquiryDetailsEditSelector = (state: IState) => state?.inquiryDetails?.editDetails;
export const getCompeonOffersSelector = (state: IState) => state.inquiryDetails?.compeonOffers;
export const getBankOffersSelector = (state: IState) => state.inquiryDetails?.bankOffers;
export const getVideIdentificationsDetails = (state: IState) => state.inquiryDetails?.details;

export const getInquiryFormTypeSelector = (state: IState) =>
  state.inquiryDetails?.details?.formType;

export const getInquiryIdSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && details.inquiryId,
);

export const getAllowDigitalProcessingSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).allowDigitalProcessing,
);

export const getLegalRepresentativesRequireManualMerging = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).legalRepresentativesRequireManualMerging,
);

export const getCustomerLegalRepresentativeId = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).customerLegalRepresentativeId,
);

export const getlegalRepresentativesAccepted = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).legalRepresentativesAccepted,
);

export const getKycCompanies = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).kycCompanies,
);

export const getCustomersSoleSignatureAuthorized = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).customersSoleSignatureAuthorized,
);

export const getVideoIdentificationFile = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).videoIdentification,
);

export const getVideoIdentificationsDetails = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).videoIdentificationsDetail,
);

export const getInquiryStatusSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && details.status,
);

export const getInquiryCreatedAtSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && details.createdAt,
);

export const getInquiryCollateralsSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).collaterals,
);

export const getInquiryProjectFinancingTypeOfUse = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, [INQUIRY_SECTIONS.FINANCING, PROJECT_FINANCING__OBJECT_USAGE], []),
);

export const getInquiryPurposeKind = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'financingPurposeFields.purpose-kind'),
);
export const getInquiryProductKind = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'inquiryDetailFields.product-kind'),
);
export const getInquiryVehicleKind = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'financingPurposeFields.vehicle-kind'),
);
export const getInquiryProjectFinancingObjectAddress = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, 'financingPurposeFields.project-financing-object-address'),
);
export const getInquiryWasForwardedToInsurance = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, 'wasForwardedToInsurance'),
);
export const getInquiryWasMovedToNonFastlane = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, 'fastlaneCancelledDate'),
);
export const getInquiryAdditionalFields = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'additionalFields'),
);

export const getInquiryCustomerName = createSelector(getInquiryDetailsSelector, (details) => {
  const customerFirstName = _get(details, 'userProfileFields.company-details-first-name');
  const customerLastName = _get(details, 'userProfileFields.company-details-last-name');
  const customerFullName = `${customerFirstName} ${customerLastName}`;
  return { customerFirstName, customerLastName, customerFullName };
});

export const getCustomerConfirmationStatus = createSelector(
  getInquiryDetailsSelector,
  (details) => {
    const isCustomerStatusConfirmed = _get(details, 'dashboardActions.customerInvitationAccepted');
    return isCustomerStatusConfirmed
      ? CustomerConfirmationStatus.CONFIRMED
      : CustomerConfirmationStatus.CONFIRMATION_PENDING;
  },
);

export const getInquiryLane = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'lane'),
);

export const getInquiryAllRequiredFilesUploaded = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).allRequiredFilesUploaded,
);

export const getCompanyId = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'company.id'),
);

export const hasAcceptedCompeonOfferSelector = createSelector(getCompeonOffersSelector, (offers) =>
  offers.some(
    (offer) => offer.status === OFFER_STATUS.ACCEPTED && offer.state !== OFFER_STATE.UNAVAILABLE,
  ),
);
export const hasShownInterestInCompeonOfferSelector = createSelector(
  getCompeonOffersSelector,
  (offers) =>
    offers.some((offer) => offer.hasShownInterest && offer.state !== OFFER_STATE.UNAVAILABLE),
);
export const hasAcceptedBankOfferSelector = createSelector(getBankOffersSelector, (offers) =>
  offers.some((offer) => offer.status === OFFER_STATUS.ACCEPTED),
);

export const hasOffersSelector = createSelector(
  [getCompeonOffersSelector, getBankOffersSelector],
  (compeonOffers, bankOffers) => !!compeonOffers.length || !!bankOffers.length,
);

export const inquiryDetailsCustomerSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).customer,
);

export const inquiryDetailsCustomerIdSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, 'customer.id'),
);

export const inquiryDetailsBankAdvisorSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails).bankAdvisor,
);

export const isPropertyLoanClassic = createSelector(
  getInquiryDetailsSelector,
  (details) =>
    _get(details, 'inquiryDetailFields.property-loan-kind') === PROPERTY_LOAN_KIND__CLASSIC,
);

export const isProductPropertyLoanKind = createSelector(
  getInquiryDetailsSelector,
  (details) => _get(details, 'inquiryDetailFields.product-kind') === PRODUCT_KIND__PROPERTY_LOAN,
);

export const getInquiryFavoredDecisionDate = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'inquiryDetailFields.favored-decision-date'),
);

export const loanTermsInMonthExists = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'inquiryDetailFields.property-loan-term-in-months'),
);

export const isValidForCompeon = createSelector(
  [isPropertyLoanClassic, getInquiryFavoredDecisionDate, loanTermsInMonthExists],
  (possibleLoanType, hasFavoredDate, hasLoanMonth) =>
    possibleLoanType && hasFavoredDate && hasLoanMonth,
);

export const getInquiryCustomerSelector = createSelector(
  getInquiryDetailsSelector,
  (details) => (details as IInquiryDetails)?.customer,
);

export const getKfwProgram = (state: IState) => state.inquiryDetails.kfwProgram;
export const getFastLane = (state: IState) => state.inquiryDetails.fastLane;
export const getRevision = (state: IState) => state.inquiryDetails.revision;
export const getInconsistencyReportUrl = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.inconsistencyReportUrl;
export const getIndicativeConditionCompleted = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.indicativeConditionCompleted;
export const getIndicativeOffer = (state: IState): Partial<IIndicativeOffer> | undefined =>
  state.inquiryDetails.details?.dashboardActions?.indicativeOffer;
export const getIndicativeCondition = (state: IState): Partial<IIndicativeCondition> | undefined =>
  state.inquiryDetails.details?.dashboardActions?.indicativeCondition;

export const getTransparencyResults = (state: IState): Partial<ITransparencyRegister> | undefined =>
  state.inquiryDetails.details?.dashboardActions?.transparencyRegister;

export const getCompeonInquiryId = (state: IState) => state.inquiryDetails.externalId;
export const getExternalReference = createSelector(
  getInquiryDetailsSelector,
  (details) => details && (details as IInquiryDetails)[EXTERNAL_REFERENCE_ID],
);
export const getInquiryCompeonStatus = (state: IState) => state.inquiryDetails.compeonStatus;
export const getInquiryStatus = (state: IState) =>
  (state.inquiryDetails.details as IInquiryDetails).status;
export const isKycTriggered = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.kycTriggered;
export const isKycCompleted = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.isKycProcessCompleted;
export const isCustomerInvited = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.customerInvitationSend;
export const isCustomerInvitationAccepted = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.customerInvitationAccepted;

export const isCrefoTriggered = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.crefoTriggered;

export const getIndicativeOfferCalculations = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.indicativeOfferCalculations;

export const getLegalRepresentatives = (state: IState) =>
  state.inquiryDetails.details ? state.inquiryDetails.details.legalRepresentatives : [];

export const getContractDetails = (store: IState) =>
  (store?.inquiryDetails?.details as IInquiryDetails).contractDetails;

export const getCustomerData = (store: IState) =>
  (store?.inquiryDetails?.details as IInquiryDetails).customerData;

export const getCompanyData = (store: IState) =>
  (store?.inquiryDetails?.details as IInquiryDetails)?.company;

export const getCompeonDocument = (store: IState) =>
  (store?.inquiryDetails?.details as IInquiryDetails)?.compeonDocument;

export const getCompeonInquiryState = (store: IState) =>
  (store?.inquiryDetails?.details as IInquiryDetails)?.compeonState;

export const isMarketDataSent = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.marketDataSent;

export const assignedRealEstateExpert = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.assignedRealEstateExpert;

export const getIsQuickCheckStarted = (state: IState) =>
  state.inquiryDetails.details?.dashboardActions?.isQuickCheckStarted;

export const getProjectFinancingRole = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'financingPurposeFields.project-financing-role'),
);

export const hasOnlyFutureUsage = createSelector(getInquiryDetailsSelector, (details) => {
  return !_get(details, 'financingPurposeFields.project-financing-usage-deviation');
});

export const getPayoutTime = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'inquiryDetailFields.purchased-invoices-paid-out-time'),
);

export const getPrefinancingPeriod = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'inquiryDetailFields.pre-financing-period'),
);

export const getBfssLane = createSelector(getInquiryDetailsSelector, (details) =>
  _get(details, 'bfssLane'),
);

export const getBeneficiaryOwners = (state: IState) =>
  state.inquiryDetails.details ? (state.inquiryDetails.details as any).beneficiaryOwners : [];

export const getFinancingPurposeIndustryType = createSelector(
  getInquiryDetailsSelector,
  (details) => {
    return _get(details, 'financingPurposeFields.industry');
  },
);
