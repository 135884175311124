import React from 'react';

import { useSelector } from 'react-redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import {
  COMPANY_DETAILS,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_COMPANY_NAME,
  END_USER_PHONE_NUMBER,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  COMPANY_DETAILS_COMPANY,
  END_USER_FIRST_NAME,
} from 'modules/Inquiry/Form/formFields';
import { useCompanyDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useCompanyDetailsSectionValidators';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import CompanyDetailsHeader from 'pages/inquiryFlow/CompanyDetails/CompanyDetailsHeader/CompanyDetailsHeader';
import Company from 'pages/inquiryFlow/CompanyDetails/sections/Company';
import { printCompanyNameFromSso } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/SsoCompany.service';
import { useFetchCompanySuggestions } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/SsoCompany.service';
import CompletingCompanyDetails from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails';
import EndUserSection from 'pages/inquiryFlow/UserProfile/sections/VbSection/EndUserSection';
import { usePrefillCompanyLastEndCustomer } from 'pages/inquiryFlow/UserProfile/service';
import { endUserFromSsoPersist } from 'shared/hooks/useSsoLinkHandler';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { isLoggedInSelector } from 'store/user/selectors';
const VALUES_TO_STORE_LOGGED_IN = [USER_PROFILE_INDUSTRY];
const VALUES_TO_STORE_NOT_LOGGED_IN = [USER_PROFILE_PHONE_NUMBER, USER_PROFILE_INDUSTRY];

const initialValues = {
  [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: true,
  [COMPANY_DETAILS_EXISTING_USER_COMPANY]: COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
};

const DvagCompanyDetails = () => {
  const fieldController = FormFieldController.instance;
  fieldController.saveValue(
    COMPANY_DETAILS_EXISTING_USER_COMPANY,
    COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  );
  const getSectionValidators = useCompanyDetailsSectionValidators();
  const { isPending: isCompanyLastEndCustomerPending, companyLastEndCustomer } =
    usePrefillCompanyLastEndCustomer();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const valuesToStore = isLoggedIn ? VALUES_TO_STORE_LOGGED_IN : VALUES_TO_STORE_NOT_LOGGED_IN;

  const ssoData = endUserFromSsoPersist.sessionStorage;
  const { isPending, companies } = useFetchCompanySuggestions(
    ssoData && ssoData[END_USER_FIRST_NAME],
  );

  // values that may have been passed through SSO link. More under CRV-1038
  const endUserValuesFromSSO = endUserFromSsoPersist.sessionStorage || {};
  const endUserSsoInitialValues = {
    [END_USER_PHONE_NUMBER]: endUserValuesFromSSO[END_USER_PHONE_NUMBER],
    [USER_PROFILE_LEGAL_FORM]: endUserValuesFromSSO[USER_PROFILE_LEGAL_FORM],
    [USER_PROFILE_INDUSTRY]: endUserValuesFromSSO[USER_PROFILE_INDUSTRY],
    [USER_PROFILE_COMPANY_STREET]: endUserValuesFromSSO[USER_PROFILE_COMPANY_STREET],
    [USER_PROFILE_COMPANY_ZIP_CODE]: endUserValuesFromSSO[USER_PROFILE_COMPANY_ZIP_CODE],
    [USER_PROFILE_COMPANY_CITY]: endUserValuesFromSSO[USER_PROFILE_COMPANY_CITY],
    [USER_PROFILE_COMPANY_NAME]: printCompanyNameFromSso(endUserValuesFromSSO),
    ...(!isPending &&
      companies &&
      companies.length === 1 && { [COMPANY_DETAILS_COMPANY]: companies[0] }),
  };

  // order is important
  const combinedInitialValues = {
    ...companyLastEndCustomer,
    ...endUserSsoInitialValues,
    ...initialValues,
  };

  fieldController.saveInitial(combinedInitialValues);

  if (isCompanyLastEndCustomerPending) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <InquiryFormNew
      headerComp={<CompanyDetailsHeader />}
      currentStepName={COMPANY_DETAILS}
      valuesSavedToStore={valuesToStore}
      getSectionValidators={getSectionValidators}
      initialValues={combinedInitialValues}
    >
      {() => (
        <>
          <Company sectionNumber={1} />
          <CompletingCompanyDetails sectionNumber={2} />
          <EndUserSection sectionNumber={3} />
        </>
      )}
    </InquiryFormNew>
  );
};

export default DvagCompanyDetails;
