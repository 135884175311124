import React, { useEffect } from 'react';

import { useField, useForm } from 'react-final-form';

import FormRow from 'components/FormRow';
import { StaticFieldWithField } from 'components/StaticField';
import { Text } from 'components/Text';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  FINANCING_AMOUNT,
  PURPOSE_KIND,
  PRODUCT_KIND,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__LEASING,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFieldCaption } from 'modules/Inquiry/useFieldCaption';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { useFormValueSpecificValue } from 'shared/chooseFormValueSpecific';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useFieldChangedValue } from 'store/hooks/useFormValues';
import { getErrorMessageForStaticField } from 'utils/form/withForm.helpers';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';
import { formatPriceAsThousands } from 'utils/valueFormats';

import {
  productKindData,
  purposeKindData,
  selectedProductKindMap,
  selectedPurposeKindMap,
} from './ProductAndPurposeKindMap';
import {
  StyledCenteredField,
  ArithmeticSign,
  FinancedAmount,
  StyledSmallTile,
  StyledSmallTileWrapper,
} from './styles';

const productKindMinfinancingAmountMap = {
  [PRODUCT_KIND__LEASING]: 2000,
  [PRODUCT_KIND__HIRE_PURCHASE]: 2000,
  default: 1000,
};

const AdvancedDeposit = () => {
  const t = useTranslations();

  const { change } = useForm();
  const selectedPurposeKind = useFieldChangedValue(PURPOSE_KIND, '');
  const selectedProductKind = useFieldChangedValue(PRODUCT_KIND, '');
  const selectedPurposeKindData: purposeKindData = selectedPurposeKindMap[selectedPurposeKind];
  const selectedProductKindData: productKindData = selectedProductKindMap[selectedProductKind];
  const selectedProductKindFieldCaption = useFieldCaption(
    selectedProductKindData.advancePaymentField,
  );

  const minFinancingAmount: number = useFormValueSpecificValue(
    PRODUCT_KIND,
    productKindMinfinancingAmountMap,
  );

  const { optional, required, lessThanPurchasePrice, minPrice } = useFieldValidators();

  const purchasePrice = useGetFieldNumericValue(selectedPurposeKindData.purchaseField);
  const advancePayment = useGetFieldNumericValue(selectedProductKindData.advancePaymentField);

  const fieldController = FormFieldController.instance;

  const { meta: financialAmountInputMeta } = useField(FINANCING_AMOUNT);

  useEffect(() => {
    const changeValue = purchasePrice - advancePayment;

    change(FINANCING_AMOUNT, changeValue);
    fieldController.saveValue(FINANCING_AMOUNT, changeValue);
    if (!advancePayment) {
      change(selectedProductKindData.advancePaymentField, 0); // default to zero
      fieldController.saveValue(selectedProductKindData.advancePaymentField, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldController, purchasePrice, change, advancePayment]);

  return (
    <>
      <FormRow>
        <StyledSmallTileWrapper>
          <StyledSmallTile>{selectedPurposeKindData.icon}</StyledSmallTile>
          <Text>
            {t(
              `pages.financingNeed.sections.purpose.fields.purposeKind.options.${selectedPurposeKind}`,
            )}
          </Text>
        </StyledSmallTileWrapper>
        <StyledCenteredField>
          <StaticFieldWithField
            caption={t(selectedPurposeKindData.caption)}
            name={selectedPurposeKindData.purchaseField}
            sideSymbol={null}
            validate={optional}
            parseValue={formatPriceAsThousands}
          />
        </StyledCenteredField>
      </FormRow>
      <FormRow>
        <StyledSmallTileWrapper>
          <StyledSmallTile className="white">{selectedProductKindData.icon}</StyledSmallTile>
          <Text>{t(selectedProductKindData.caption)}</Text>
          <ArithmeticSign>-</ArithmeticSign>
        </StyledSmallTileWrapper>
        <AmountWithField
          name={selectedProductKindData.advancePaymentField}
          validate={combineValidators(
            required,
            lessThanPurchasePrice(purchasePrice, selectedProductKindFieldCaption),
          )}
        />
      </FormRow>
      <FormRow separator>
        <ArithmeticSign>=</ArithmeticSign>
        <FinancedAmount>
          <StaticFieldWithField
            caption={t('pages.requestDetails.sections.loan.fields.amountFinanced.caption')}
            name={FINANCING_AMOUNT}
            sideSymbol={null}
            parseValue={(value: string) => {
              return value ? formatPriceAsThousands(parseFloat(value)) : '0,00€';
            }}
            validate={combineValidators(minPrice(minFinancingAmount))}
            errorMessage={getErrorMessageForStaticField(financialAmountInputMeta)}
          />
        </FinancedAmount>
      </FormRow>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: () => {
    return (
      <Condition condition={fieldBusinessConditions.hasCalculatedAmountField} isBusinessCondition>
        <AdvancedDeposit />
      </Condition>
    );
  },
  default: () => null,
});
