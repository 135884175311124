import * as React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import FormSection from 'components/FormSection';
import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import {
  SummaryPageField,
  useBuildFieldsForSummaryPage,
} from 'new/summary/hooks/useBuildFieldsForSummary';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SummaryField } from './SummaryField';

export const SummarySections = () => {
  const t = useTranslations();
  const {
    formStateData: { stepData, stepSequence, summaryFields },
  } = useInquiryProcessConfig();
  const pagesWithFieldCaptionAndValue = useBuildFieldsForSummaryPage({
    stepSequence,
    summaryFields,
  });

  return (
    <>
      {Object.keys(pagesWithFieldCaptionAndValue).map((pageName, index) => {
        const fields: Array<SummaryPageField> = pagesWithFieldCaptionAndValue[pageName];
        return (
          <FormSection
            key={pageName}
            sectionNumber={index + 1}
            title={t((stepData as any)[pageName].progressBarTitle)}
          >
            <SimpleGrid columns={2} flexGrow={1} gap={12}>
              {fields.map((summaryPageField) => {
                return (
                  <SummaryField
                    summaryPageField={summaryPageField}
                    key={summaryPageField.caption}
                  />
                );
              })}
            </SimpleGrid>
          </FormSection>
        );
      })}
    </>
  );
};
