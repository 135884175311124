// Border-Radii
export const borderRadius = {
  small: '2px',
  default: '4px',
  large: '8px',
  circle: '50%',
};

// Box-Shadows
export const boxShadow = {
  default: '0 2px 10px 0 rgba(32, 32, 32, 0.1)',
};

// Spacings
export const spacing = {
  xxxs: '0.25rem',
  xxs: '0.5rem',
  xs: '0.75rem',
  s: '1rem',
  m: '1.5rem',
  l: '2rem',
  xl: '3rem',
  xxl: '4rem',
  xxxl: '5rem',
};

// Text Styles
// notation […, null, …] is needed to overwrite responsive chakra defaultProps
export const textStyle = {
  sizeXs: {
    fontSize: ['12px', null, '12px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeXsTight: {
    fontSize: ['12px', null, '12px'],
    lineHeight: ['1.33333333', null, '1.33333333'],
  },
  sizeS: {
    fontSize: ['14px', null, '14px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeSTight: {
    fontSize: ['14px', null, '14px'],
    lineHeight: ['1.28571428', null, '1.28571428'],
  },
  sizeM: {
    fontSize: ['16px', null, '16px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeMTight: {
    fontSize: ['16px', null, '16px'],
    lineHeight: ['1.3125', null, '1.3125'],
  },
  sizeL: {
    fontSize: ['20px', null, '20px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeLTight: {
    fontSize: ['20px', null, '20px'],
    lineHeight: ['1.3', null, '1.3'],
  },
  sizeXl: {
    fontSize: ['24px', null, '24px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeXlTight: {
    fontSize: ['24px', null, '24px'],
    lineHeight: ['1.25', null, '1.25'],
  },
  sizeXxl: {
    fontSize: ['28px', null, '28px'],
    lineHeight: ['1.5', null, '1.5'],
  },
  sizeXxlTight: {
    fontSize: ['28px', null, '28px'],
    lineHeight: ['1.28571428', null, '1.28571428'],
  },
};

// Transitions
export const transition = {
  default: '0.2s ease-out',
};

// z-Indices
export const zIndex = {
  modal: 100,
  sideBar: {
    drawer: 71,
    base: 70,
  },
  header: 50,
  horizontalProgressBar: 40,
  verticalProgressBar: {
    counter: 32,
    bar: 31,
    base: 30,
  },
  select: 20,
  confirmationButton: {
    loading: 5,
    confirmation: 4,
    base: 3,
  },
  above: 2,
  default: 1,
  below: -1,
};
