import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { getUserAttributesAction } from 'api/CompeonReverseApi/operation/userData/actions';
import {
  END_USER_FIRST_NAME,
  END_USER_LAST_NAME,
  END_USER_PHONE_NUMBER,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
} from 'modules/Inquiry/Form/formFields';
import { END_USER_ATTRIBUTES_KEY } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { LOG_IN_SUCCESS } from 'shared/auth/loginLogout.actionTypes';
import { getLoggedInUserDataSelector, isLoggedInSelector } from 'store/user/selectors';
import { getUserDetails } from 'store/user/service';
import { filterObjectEmptyKeys, objHasKeys } from 'utils/helpers';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useTranslations } from 'utils/hooks/useTranslations';
import { removeWhitespaces } from 'utils/removeWhitespaceFromString';
import { setStorageExpiration } from 'utils/sessionStorage/expiration';

import { useToasts } from './useToasts';

const SSO_API_PARAMS = {
  exchangeToken: 'token',
  accessToken: 'access_token',
  endCustomerPhoneNumber: 'customer_phone',
  endCustomerName: 'customer_name',
  endCustomerGivenName: 'customer_given_name',
  endCustomerLegal: 'customer_legal',
  endCustomerIndustry: 'customer_industry',
  endCustomerAddress: 'customer_adress',
  endCustomerData: 'end_user_data',
};

export const endUserFromSsoPersist = {
  set sessionStorage(endUser) {
    sessionStorage.setItem(END_USER_ATTRIBUTES_KEY, JSON.stringify(endUser));
  },
  get sessionStorage() {
    return JSON.parse(sessionStorage.getItem(END_USER_ATTRIBUTES_KEY));
  },
};

const loginUserAction = (loginData) => ({ type: LOG_IN_SUCCESS, payload: { data: loginData } });

const exchangeSSOTokenAction = (token) =>
  callReverseApi({
    url: endpoints.USERS.EXCHANGE_SSO.compose(),
    method: API_METHODS.POST,
    data: { token },
  });

const DVAG_ADDRESS_FORMAT = /(.+)\n(\d+)\s+(.+)/;
const parseAddressFromDvagFormat = (value = '') => {
  // DVAG will send addres in following format
  // {address}\n{postal} {city}
  // ex. Lindenstr. 100\n60123 Frankfurt am Main
  // More in CRV-1612
  const matches = new RegExp(DVAG_ADDRESS_FORMAT).exec(value);
  if (!matches) {
    return {};
  }
  const [, address, zipCode, city] = matches;
  return { address, zipCode: zipCode, city };
};

const saveEndUserDataToSessionStorage = (endUserData) => {
  const endUser = filterObjectEmptyKeys(endUserData);

  if (objHasKeys(endUser)) {
    const { zipCode, address, city } = parseAddressFromDvagFormat(
      endUser[SSO_API_PARAMS.endCustomerAddress],
    );
    // setting data through get/set accessors of endUserFromSsoPersist
    endUserFromSsoPersist.sessionStorage = {
      [END_USER_PHONE_NUMBER]: removeWhitespaces(endUser[SSO_API_PARAMS.endCustomerPhoneNumber]),
      [END_USER_LAST_NAME]: endUser[SSO_API_PARAMS.endCustomerGivenName],
      [END_USER_FIRST_NAME]: endUser[SSO_API_PARAMS.endCustomerName],
      [USER_PROFILE_LEGAL_FORM]: endUser[SSO_API_PARAMS.endCustomerLegal],
      [USER_PROFILE_INDUSTRY]: endUser[SSO_API_PARAMS.endCustomerIndustry],
      [USER_PROFILE_COMPANY_STREET]: address,
      [USER_PROFILE_COMPANY_ZIP_CODE]: zipCode,
      [USER_PROFILE_COMPANY_CITY]: city,
    };
  }
};

const useLoginUserBySsoResponse = () => {
  const t = useTranslations('pages.loginPage');
  const dispatch = useDispatch();
  const { success } = useToasts();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userAttributes = useSelector(getLoggedInUserDataSelector);
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  return useCallback(
    async (response) => {
      const shouldLogin = response[SSO_API_PARAMS.accessToken] && !isLoggedIn;

      if (shouldLogin) {
        dispatch(loginUserAction(response));
        await makeCall(getUserAttributesAction(), (response) => {
          success({
            description: t('loggedInAs', {
              ...userAttributes,
              ...getUserDetails(response.payload.data.data.attributes),
            }),
          });
        });
      }
    },
    [dispatch, isLoggedIn, success, t, userAttributes, makeCall],
  );
};

export const useSsoLinkHandler = () => {
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });
  const t = useTranslations('pages.loginPage');
  const { error: errorMessage } = useToasts();
  const tokenToExchange = useQueryParams(SSO_API_PARAMS.exchangeToken);
  const loginSsoUser = useLoginUserBySsoResponse();

  useEffect(() => {
    async function exchangeSSOToken() {
      const { payload, error } = await makeCall(exchangeSSOTokenAction(tokenToExchange));

      if (!error) {
        const exchangedData = payload.data.data;

        await loginSsoUser(exchangedData);
        saveEndUserDataToSessionStorage(exchangedData[SSO_API_PARAMS.endCustomerData]);
      }

      if (error) {
        errorMessage({
          description: t('sessionExpired'),
        });
      }
    }

    if (tokenToExchange) {
      exchangeSSOToken();
      setStorageExpiration();
    }
  }, []); // eslint-disable-line
};
