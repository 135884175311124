import React from 'react';

import paths from 'constants/paths';
import DvagInquiryDetailedInformation from 'modules/InquiryDetails/DetailedInformation/DvagDetailedInquiryInformation';

const DvagCustomerDetailedInformation = () => (
  <DvagInquiryDetailedInformation
    pathToEditInquiry={paths.customer.inquiryDetails.edit.root}
    isCustomer
  />
);

export default DvagCustomerDetailedInformation;
