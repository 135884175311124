import React from 'react';

import { useSelector } from 'react-redux';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from 'utils/hooks/useTranslations';

import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import { getFurtherObjectData, getProfitabilityLendingValueTable } from '../../../store/selectors';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { LendingValueItem } from '.';

const PRESALES_RATE_THRESHOLD = 0.5;

const partitionMapping: MappingProps<LendingValueItem> = [
  { key: 'base_value', unit: 'currency', styles: { bold: true } },
  { key: 'cost_transfer', unit: 'currency', styles: { bold: true } },
  { key: 'adjusted_base_value', unit: 'currency', styles: { bold: true } },
  { key: 'risk_deduction', unit: 'percent', styles: { italic: true } },
  { key: 'lending_value', unit: 'currency', styles: { bold: true } },
  { key: 'lending_limit_percent', unit: 'percent', styles: { italic: true } },
  { key: 'lending_limit_euro', unit: 'currency', styles: { bold: true } },
  { key: 'unsecured_portion', unit: 'currency', styles: { bold: true } },
  { key: 'loan_value_ratio', unit: 'percent', styles: { bold: true } },
];

export const LendingValuePartition = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.lendingValue.partition',
  );
  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilter);
  const bankValuesData = useSelector(getFurtherObjectData);
  const isPresalesRateBelowThreshold =
    Number(bankValuesData[usageType].presales_rate) <= PRESALES_RATE_THRESHOLD;
  const lendingValuePartitionTableData = useSelector(getProfitabilityLendingValueTable);
  const lendingValuePartitionTable = usePortalTable({
    data: partitionMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`rows.${key}`),
      buyingPhase: formatter[unit](
        isPresalesRateBelowThreshold
          ? lendingValuePartitionTableData.buying_phase[key]
          : lendingValuePartitionTableData[usageType].whole_project[key],
      ),
      indication: formatter[unit](lendingValuePartitionTableData[usageType].indication[key]),
    })),
    headers: [
      { key: 'position', text: t('headers.position') },
      { key: 'buyingPhase', text: t('headers.buyingPhase') },
      { key: 'indication', text: t('headers.indication') },
    ],
  });
  const rowStyles = getRowStylesMap(partitionMapping);
  if (!lendingValuePartitionTable) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return <PortalTable {...lendingValuePartitionTable} rowStylesMap={{ ...rowStyles }} />;
};
