import _get from 'lodash/get';

import {
  PROFITABILITY_NUMBER_OF_GARAGES,
  PROFITABILITY_NUMBER_OF_PARKING_LOTS,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapPresalesValuesFromApi = (response: unknown) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const presalesValues = {
    [PROFITABILITY_PRESALES_AREA_RESIDENTIAL]:
      _get(response, 'presales_area_residential') || initialValue,
    [PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM]:
      _get(response, 'presales_price_residential_per_sqm') || initialValue,
    [PROFITABILITY_PRESALES_AREA_COMMERCIAL]:
      _get(response, 'presales_area_commercial') || initialValue,
    [PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM]:
      _get(response, 'presales_price_commercial_per_sqm') || initialValue,
    [PROFITABILITY_NUMBER_OF_PARKING_LOTS]: _get(response, 'presales_parking_lots') || initialValue,
    [PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE]:
      _get(response, 'presales_price_parking_lots') || initialValue,
    [PROFITABILITY_NUMBER_OF_GARAGES]: _get(response, 'presales_garage') || initialValue,
    [PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE]:
      _get(response, 'presales_price_garage') || initialValue,
  };

  return presalesValues;
};

export default mapPresalesValuesFromApi;
