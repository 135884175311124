import _get from 'lodash/get';

import {
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
} from 'modules/Inquiry/Form/formFields';

const mapProfitabilitySalesFromApi = (response: unknown) => {
  const data = _get(response, 'data.attributes.details.profitability_sales');

  const profitabilitySales = {
    [PROFITABILITY_SALES_BUYING_COSTS_CHECKED]: _get(data, 'buying_costs_checked', ''),
    [PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED]: _get(data, 'construction_costs_checked', ''),
    [PROFITABILITY_SALES_SALES_PRICES_CHECKS]: _get(data, 'sales_prices_checks', ''),
  };

  return profitabilitySales;
};

export default mapProfitabilitySalesFromApi;
