import styled from 'styled-components/macro';

export const Animation = styled.div.attrs(({ type, delay, onlyOnce }) => ({
  'data-aos': type,
  'data-aos-delay': delay,
  'data-aos-once': onlyOnce,
}))``;

export const ZoomAnimation = styled.div.attrs(({ delay, onlyOnce }) => ({
  'data-aos': 'zoom-in-up',
  'data-aos-delay': delay,
  'data-aos-once': onlyOnce,
}))``;
