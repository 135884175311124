import React from 'react';

import FormRow from 'components/FormRow';
import FormRowWrapper from 'components/FormRow/FormRowWrapper';
import { SelectWithField } from 'components/Selects/Select';
import { useFormConfig } from 'config/formConfig/hooks';
import { USER_PROFILE_INDUSTRY, BFS_USER_PROFILE_INDUSTRY } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useTranslations } from 'utils/hooks/useTranslations';
import { retrieveObject, saveObjectToSessionStorage } from 'utils/sessionStorage/helpers';
import { COMPANY_DETAILS } from 'utils/sessionStorage/keys';

import { useGetMappedPartnerOptions } from '../../../../../../utils/hooks/useGetMappedOptions';

export const INDUSTRY_OPTIONS = 'industry-options';

const Select = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const defaultLegalOptionsMap = {
    [InquiryType.bfs]: useInquirySelectOptions(BFS_USER_PROFILE_INDUSTRY),
    default: useInquirySelectOptions(USER_PROFILE_INDUSTRY),
  };

  const partnerIndustryOptions = useGetMappedPartnerOptions('industry');

  const industryOptions =
    partnerIndustryOptions.length !== 0
      ? partnerIndustryOptions
      : defaultLegalOptionsMap[selectedInquiryType] || defaultLegalOptionsMap.default;

  const companyDetails = retrieveObject(COMPANY_DETAILS);
  saveObjectToSessionStorage(COMPANY_DETAILS, {
    ...companyDetails,
    [INDUSTRY_OPTIONS]: industryOptions,
  });

  return (
    <FormRowWrapper>
      <FormRow>
        <SelectWithField
          name={USER_PROFILE_INDUSTRY}
          placeholder={t('placeholders.pleaseChoose')}
          options={industryOptions}
          data-testid="industry-select-field"
        />
      </FormRow>
    </FormRowWrapper>
  );
};

export default Select;
