import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const getInternalCommentsAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.COMMENTS.compose({ params: { id: inquiryId } }),
    method: API_METHODS.GET,
  });

export const addInternalCommentAction = (inquiryId: string, content: any) =>
  callReverseApi({
    url: endpoints.INQUIRIES.COMMENTS.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    data: {
      content,
    },
  });
