import { useCallback, useState } from 'react';

import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { MAX_FILE_SIZE } from 'constants/file';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { PropertyProfileValues } from '../pages/PropertyProfile';
import type { ObjectImage } from '../pages/PropertyProfile/UploadObjectImages';
import { setObjectImages } from '../store/action';
import { getPropertyProfileObjectImages } from '../store/selectors';
import type { FinancingRole, UsageType } from '../types';
import { mapObjectImageFromApi } from '../utils';

const useDownloadPropertyProfilePdf = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile.download');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  return useCallback(
    async (financingRole: FinancingRole, usageType: UsageType) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoints.INQUIRIES.SPECIALIZED.DOWNLOAD_PROPERTY_PROFILE_PDF.compose({
            params: { inquiryId },
          }),
          method: API_METHODS.POST,
          responseType: 'blob',
          data: {
            data: {
              attributes: {
                role: financingRole,
                variant: usageType,
              },
            },
          },
        }),
      );
      if (res.error) return;
      const blob = new Blob([res.payload.data], {
        type: res.payload.headers['content-type'],
      });
      saveAs(blob, `${t('fileName')}.pdf`);
    },
    [inquiryId, makeCall, t],
  );
};

const useUpdatePropertyProfile = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile.update');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  return useCallback(
    async (values: PropertyProfileValues) =>
      await makeCall(
        callReverseApi({
          url: endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
            params: { inquiryId },
          }),
          method: API_METHODS.PATCH,
          data: {
            data: {
              attributes: {
                source_of_funds: {
                  notes_on_financing_plan: values.notesOnFinancingPlan,
                },
                financing_details: {
                  notes_on_financing_parameters: values.notesOnFinancingParameters,
                },
                rent: {
                  notes_on_debt_service: values.notesOnDebtService,
                },
                financing_criteria: {
                  notes_on_financing_criteria: values.notesOnFinancingCriteria,
                },
                lending_value: {
                  notes_on_remaining_risk: values.notesOnLendingValueRemainingRisk,
                },
                additional_notes: {
                  notes_on_customer: values.notesOnCustomer,
                  description_of_financing_project: values.descriptionOfFinancingProject,
                  notes_on_object: values.notesOnObject,
                },
              },
            },
          },
        }),
      ),
    [inquiryId, makeCall],
  );
};

const useDeleteObjectImage = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile.delete');
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: true,
    errorMessage: t('error'),
  });
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const objectImages = useSelector(getPropertyProfileObjectImages);
  return useCallback(
    async (fileId: string) => {
      const res = await makeCall(
        callReverseApi({
          url: endpoints.INQUIRIES.SPECIALIZED.DELETE_OBJECT_IMAGES.compose({
            params: { inquiryId, fileId },
          }),
          method: API_METHODS.DELETE,
        }),
      );
      if (res.error) return;
      dispatch(setObjectImages(objectImages?.filter((image) => image.id !== fileId) ?? []));
    },
    [makeCall, inquiryId, dispatch, objectImages],
  );
};
const useDownloadObjectImage = (image?: ObjectImage) =>
  useCallback(() => {
    if (!image) return;
    saveAs(image.url);
  }, [image]);

export type ObjectImageType = 'object_photo_current' | 'object_visualization';
const ACCEPT_OBJECT_IMAGES = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

const MAX_IMAGE_WIDTH = 500;
const MAX_IMAGE_HEIGHT = 500;

const isImageDimensionsValid = (file: File) =>
  new Promise<boolean>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () =>
      resolve(image.width <= MAX_IMAGE_WIDTH && image.height <= MAX_IMAGE_HEIGHT);
    image.onerror = () => resolve(false);
  });

export const usePropertyProfileObjectImage = (imageType: ObjectImageType) => {
  const [isUploading, setIsUploading] = useState(false);
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const objectImages = useSelector(getPropertyProfileObjectImages) ?? [];
  const image = objectImages.find((image) => image.imageType === imageType);
  const { error } = useToasts();
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.propertyProfile.sections.upload',
  );

  const { startUpload } = useNewUploadFile(
    endpoints.INQUIRIES.SPECIALIZED.UPLOAD_OBJECT_IMAGES.compose({ params: { inquiryId } }),
  );
  const dispatch = useDispatch();
  const deleteImage = useDeleteObjectImage();
  const downloadImage = useDownloadObjectImage(image);
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    accept: ACCEPT_OBJECT_IMAGES,
    maxSize: MAX_FILE_SIZE,
    onDrop: async (acceptedFiles) => {
      if (!acceptedFiles.length) return;
      setIsUploading(true);
      try {
        const [file] = acceptedFiles;
        // unfortunately, we can't use the vaildator prop from useDropzone here because it doesn't work with async functions
        if (!(await isImageDimensionsValid(file))) {
          error({ description: t('error') });
          return;
        }
        const res = await startUpload(file, { image_type: imageType });
        if (res.error) {
          error({ description: t('error') });
          return;
        }
        dispatch(setObjectImages(res.map(mapObjectImageFromApi)));
      } catch {
        error({ description: t('error') });
      } finally {
        setIsUploading(false);
      }
    },
  });

  return { getRootProps, getInputProps, open, isUploading, deleteImage, downloadImage, image };
};

export const usePropertyProfile = () => {
  const updateValues = useUpdatePropertyProfile();
  const downloadPdf = useDownloadPropertyProfilePdf();
  return { updateValues, downloadPdf };
};
