import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { UppercaseSectionHeading } from 'components/Text/SectionHeading';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useTranslations } from 'utils/hooks/useTranslations';

import {
  VOLUME_OF_SALES,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
} from '../../formFields';

export const validateFactoringPurposeSection = validateSectionFields([
  VOLUME_OF_SALES,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
]);

export const FactoringPurposeSection = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose');
  const tooltips = useTranslations(
    'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.fields',
  );

  return (
    <FormSection title={t('title')} sectionNumber={1}>
      <FormRow>
        <AmountWithField name={VOLUME_OF_SALES} tooltip={tooltips('volumeOfSales.tooltip')} />
      </FormRow>
      <UppercaseSectionHeading>{t('sectionHeading')}</UppercaseSectionHeading>
      <FormRow>
        <AmountWithField
          name={SHARE_OF_SALES_BUSINESS_CUSTOMERS}
          tooltip={tooltips('shareOfSalesBusinessCustomers.tooltip')}
        />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={SHARE_OF_SALES_MAIN_CUSTOMERS}
          tooltip={tooltips('shareOfSalesMainCustomers.tooltip')}
        />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={SHARE_OF_SALES_FOREIGN_CUSTOMERS}
          tooltip={tooltips('shareOfSalesForeignCustomers.tooltip')}
        />
      </FormRow>
    </FormSection>
  );
};
