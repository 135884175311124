const HorizontalSection = {
  baseStyle: {
    gridTemplateColumns: {
      md: 'repeat(1, 1fr)',
      lg: '1fr 3fr',
    },
    gap: 6,
    p: {
      base: '1.5rem 0',
      md: '3rem 0',
    },
    _notLast: {
      borderBottom: '1px solid',
      borderBottomColor: 'border.lightGrey',
    },
  },
};

export default HorizontalSection;
