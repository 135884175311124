import { useEffect } from 'react';

import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MittweidaFieldTypes } from '../../../mittweida/inquiry/fields';
import {
  useFormValidations,
  useMode,
  useInquiryProcessConfig,
} from '../state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from '../state/pageStateReducer';
import { ValidateSectionFunction } from '../useValidateFields';

export function getSectionValidations(
  validationsForCurrentStep: Record<any, ValidateSectionFunction>,
  form: FormApi,
  values: MittweidaFieldTypes,
  store: any,
) {
  const numberOfSections = Object.keys(validationsForCurrentStep).length;
  const sectionValidations = Object.keys(validationsForCurrentStep).reduce((acc, key) => {
    const currentSectionValidation = validationsForCurrentStep[key];
    const isCurrentSectionValid = currentSectionValidation(form, values, store);
    return {
      ...acc,
      [key]: isCurrentSectionValid,
    };
  }, {});

  const numberOfValidSections = Object.values(sectionValidations).filter(Boolean).length;

  return {
    progress: {
      numberOfSections,
      numberOfValidSections,
      isSectionValid: numberOfSections === numberOfValidSections,
    },
    validations: sectionValidations,
  };
}

function useIsCalculationStep() {
  const {
    formStateData: { routeToPageNameMap },
  } = useInquiryProcessConfig();

  const {
    location: { pathname },
  } = useHistory();
  const mode = useMode();
  const pageRoutes = Object.values(routeToPageNameMap).map((record) => record[mode]);

  if (mode === 'editCustomer' || mode === 'editOperation') {
    return (
      pageRoutes.filter((pageRoute) => {
        const compare = pageRoute.split('/').pop();
        return compare === pathname.split('/').pop();
      }).length > 0
    );
  }

  return pageRoutes.includes(pathname);
}

export function useCalculateProgress() {
  const { state, dispatch } = useInquiryProcessConfig();
  const validations = useFormValidations();
  const validationsForCurrentStep = validations[state.currentStep as string];
  const { values } = useFormState<MittweidaFieldTypes>();
  const form = useForm();
  const store = useStore();
  const isCalculationStep = useIsCalculationStep();
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (isCalculationStep) {
      const sectionValidations = getSectionValidations(
        validationsForCurrentStep,
        form,
        values,
        store,
      );

      dispatch({
        type: PageStateActionTypes.SET_PROGRESS,
        payload: {
          ...state.pageValidations,
          [state.currentStep]: {
            ...sectionValidations,
          },
        },
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dispatch, pathname]);
}
