// API names
export enum InquiryType {
  default = 'default',
  bigPicture = 'big_picture',
  onlinefactoring = 'onlinefactoring',
  leaseplan = 'leaseplan',
  bfs = 'bfs',
  profiRLL = 'profi',
  profiMittweida = 'profi_mittweida',
  dvag = 'dvag',
  bfsService = 'bfs_service',
  mmv = 'mmv',
}
