import React from 'react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const FormDownloadLink: React.FC = () => {
  const t = useTranslations('pages.inquiryDetails.offers');

  return (
    <ButtonComponent
      as="a"
      href={t('downloadFormLinkURL')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('downloadFormLink')}
    </ButtonComponent>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: FormDownloadLink,
  default: () => null,
});
