import {
  USER_ROLE_CUSTOMER,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';

const PERMISSIONS = {
  ARCHIVE_USER: [USER_ROLE_INQUIRY_MANAGER],
  COMPANY_DETAILS: {
    DISPLAY: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
  },
  INQUIRY: {
    DETAILS: {
      DASHBOARD: {
        SUMMARY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
        ACTIONS: {
          DISPLAY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR],
        },
        CLOSE_INQUIRY: {
          DISPLAY: [USER_ROLE_INQUIRY_MANAGER],
        },
        FORWARD_INQUIRY: {
          DISPLAY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
        },
        COMMUNICATION: {
          DISPLAY: [
            USER_ROLE_CALL_CENTER_AGENT,
            USER_ROLE_BANK_ADVISOR,
            USER_ROLE_INQUIRY_MANAGER,
            USER_ROLE_REAL_ESTATE_EXPERT,
          ],
          PROCESS_LOG: [
            USER_ROLE_CALL_CENTER_AGENT,
            USER_ROLE_BANK_ADVISOR,
            USER_ROLE_INQUIRY_MANAGER,
            USER_ROLE_REAL_ESTATE_EXPERT,
          ],
          INTERNAL_COMMENTS: [
            USER_ROLE_CALL_CENTER_AGENT,
            USER_ROLE_BANK_ADVISOR,
            USER_ROLE_INQUIRY_MANAGER,
            USER_ROLE_REAL_ESTATE_EXPERT,
          ],
        },
      },
      DOCUMENT_EXCHANGE: {
        CREATE_DOCUMENT_REQUEST: [USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR],
      },
      OFFERS: [USER_ROLE_CUSTOMER],
      CHANGELOG: [USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR],
    },
    EDIT: [
      USER_ROLE_CUSTOMER,
      USER_ROLE_INQUIRY_MANAGER,
      USER_ROLE_BANK_ADVISOR,
      USER_ROLE_REAL_ESTATE_EXPERT,
    ],
    EDIT_DRAFT: [USER_ROLE_CUSTOMER],
    PERFORM_WELCOME_CALL: [USER_ROLE_CALL_CENTER_AGENT],
    FORWARD_TO_INQUIRY_MANAGER: [USER_ROLE_CALL_CENTER_AGENT],
    FORWARD_TO_BANK_ADVISOR: [USER_ROLE_INQUIRY_MANAGER],
    SUBMIT_OFFER: [USER_ROLE_BANK_ADVISOR],
    FORWARD_TO_CUSTOMER: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
    ARCHIVE: [USER_ROLE_INQUIRY_MANAGER],
    SAVE_DRAFT: [USER_ROLE_CUSTOMER],
    REMOVE_DRAFT: [USER_ROLE_CUSTOMER],
  },
};
export default PERMISSIONS;
