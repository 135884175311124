import _invert from 'lodash/invert';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { ICompaniesTableOptions } from 'pages/operationPortal/CompaniesList/CompaniesTable.model';

import { COMPANIES_API_HEADERS_TO_APP } from '../helpers/companies.service';
import { ACTION } from './actionTypes';

const tableOptionsToQueryParams = (tableOptions: ICompaniesTableOptions) => ({
  data: {
    attributes: {
      page: {
        size: tableOptions.pageSize,
        number: tableOptions.page,
      },
      sort_by: _invert(COMPANIES_API_HEADERS_TO_APP)[tableOptions.sortBy],
      sort_direction: tableOptions.sortDirection,
    },
  },
});

export const getCompaniesListAction = (options: ICompaniesTableOptions) =>
  callReverseApi({
    url: endpoints.COMPANIES.SPECIALIZED.LIST.compose({
      query: tableOptionsToQueryParams(options),
    }),
    method: API_METHODS.GET,
    actionName: ACTION.GET_COMPANIES_LIST,
  });
