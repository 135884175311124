import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import StaticField, { StaticFieldWithField } from 'components/StaticField';
import {
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
} from 'modules/Inquiry/Form/formFields';
import { getIndicativeOfferCalculations } from 'store/inquiryDetails/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import ConditionDescription from './ConditionDescription';
import { CardHeader, ConditionsWrapper, Rate, Unit, ValueBox } from './styles';

/* stylelint-disable */
const IconPopoverStyleWrapper = styled.div`
  .css-1bjdup8 {
    background-color: ${({ theme }) => theme.colors.background.blackGrey} !important;
    padding: 1px !important;
  }
`;
/* stylelint-enable */

export const ShowCondition = () => {
  const t = useTranslations();
  const processLane = useSelector(getProcessLane);
  const isOffer = processLane === 'offer';
  const calculations = useSelector(getIndicativeOfferCalculations);

  return (
    <>
      <IconPopoverStyleWrapper>
        <CardHeader>
          {isOffer
            ? t('components.smsVerification.header3Indicative')
            : t('components.smsVerification.header3')}
        </CardHeader>
        {!calculations && <MarkdownWrapper text={t('components.smsVerification.info3')} />}
        <ConditionsWrapper>
          {calculations ? (
            <>
              <ValueBox>
                <Rate>
                  <PopoverComponent
                    ml={1}
                    transform="translateY(-1px)"
                    text={t(`components.indicativeConditions.nonBindingOffer.factoringFee.tooltip`)}
                    trigger={
                      <IconButtonComponent
                        icon={<InfoIcon boxSize={5} d="block" />}
                        label={t('buttons.moreInfos')}
                      />
                    }
                  />
                  <StaticField
                    caption={t(
                      `pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringFee`,
                    )}
                    text={calculations.factoringFee}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <Rate>
                  <PopoverComponent
                    ml={1}
                    transform="translateY(-1px)"
                    text={t(
                      `components.indicativeConditions.nonBindingOffer.factoringLine.tooltip`,
                    )}
                    trigger={
                      <IconButtonComponent
                        icon={<InfoIcon boxSize={5} d="block" />}
                        label={t('buttons.moreInfos')}
                      />
                    }
                  />
                  <StaticField
                    caption={t(
                      `pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine`,
                    )}
                    text={calculations.factoringLine}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <PopoverComponent
                  ml={1}
                  transform="translateY(-1px)"
                  text={t(`components.indicativeConditions.nonBindingOffer.payoutRate.tooltip`)}
                  trigger={
                    <IconButtonComponent
                      icon={<InfoIcon boxSize={5} d="block" />}
                      label={t('buttons.moreInfos')}
                    />
                  }
                />
                <Rate>
                  <StaticField
                    caption={t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.payoutRate`)}
                    text={calculations.payoutRate}
                  />
                </Rate>
              </ValueBox>
            </>
          ) : (
            <>
              <ValueBox>
                <Rate>
                  <StaticFieldWithField
                    caption={t('components.smsVerification.amountUnitDesc')}
                    name={END_USER_CONDITION_AMOUNT}
                    parseValue={(value: number) => formatPrice(value, false)}
                    sideSymbol={() => <Unit>{t('components.smsVerification.amountUnit')}</Unit>}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <Rate>
                  <StaticFieldWithField
                    caption={t('components.smsVerification.rateUnitDesc')}
                    name={END_USER_CONDITION_INTEREST_RATE}
                    parseValue={(value: number) => formatPrice(value, false)}
                    sideSymbol={() => <Unit>{t('components.smsVerification.rateUnit')}</Unit>}
                  />
                </Rate>
              </ValueBox>
            </>
          )}
        </ConditionsWrapper>
        <ConditionDescription />
      </IconPopoverStyleWrapper>
    </>
  );
};
