import React, { ReactNode } from 'react';

import {
  UnuploadedIcon as FileIcon,
  StyledIconWrapper as IconWrapper,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import {
  StyledFileContent as Content,
  StyledTitleWrapper as TitleWrapper,
  StyledFileTitle as Title,
  StyledRequestActionList as ActionList,
  StyledRequestActionListItem as ActionListItem,
  StyledFileBlockWrapper as Wrapper,
} from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import DownloadSignedContractInRequest from '../components/DownloadSignedContractInRequest';

interface ISignedContractFileBlock {
  signedContractUrl: string;
  canRemove?: boolean;
  label?: ReactNode;
  onRemove?: Function;
}

const UploadedSignedContractBlock = ({ signedContractUrl, label }: ISignedContractFileBlock) => {
  const t = useTranslations('pages.inquiryDetails.documentExchange');
  return (
    <Wrapper data-testid="file-block">
      <IconWrapper>
        <FileIcon />
      </IconWrapper>

      <Content>
        <TitleWrapper>
          <Title>{t('signedContractFileName')}</Title>
          {label}
        </TitleWrapper>
      </Content>

      <ActionList>
        <ActionListItem>
          <DownloadSignedContractInRequest actionUrl={signedContractUrl} />
        </ActionListItem>
      </ActionList>
    </Wrapper>
  );
};

export default UploadedSignedContractBlock;
