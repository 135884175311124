import _chunk from 'lodash/chunk';

export const OFFERS_PER_ROW = 1;
const FIRST_COLUMN_WIDTH = 80;
const OFFER_COLUMN_WIDTH = 205;
const getColumnWidths = (offers) =>
  [FIRST_COLUMN_WIDTH, ...Array(offers.length)].map(() => OFFER_COLUMN_WIDTH);

const PAGE_MARGIN = 80;

const getHeaderLayout = (title, logo, theme, additionalHeadline) => ({
  columns: [
    [
      {
        text: title,
        fontSize: 14,
        color: theme.colors.primary,
        fontWeight: 700,
      },
      { text: additionalHeadline, style: 'additionalHeadline' },
    ],
    {
      image: logo,
      width: 50,
    },
  ],
  margin: [PAGE_MARGIN, PAGE_MARGIN, PAGE_MARGIN, 0],
});

const emptyBankLabel = {
  text: '',
  alignment: 'center',
  style: 'heading',
};
const getBankNames = ({ bankName }) => ({
  text: bankName,
  alignment: 'center',
  marginLeft: -230,
  style: 'heading',
});

const getProductLabel = ({ productName }) => ({
  text: productName.label,
  style: 'propertyHeading',
});
const getProductValue = ({ productName }) => ({
  text: productName.value,
  style: 'propertyValue',
  marginLeft: -80,
});

const getPropertyValues = (label) => (offer) => {
  const property = offer.properties.find((prop) => prop.label === label);
  return {
    text: property.value,
    style: 'propertyValue',
    marginLeft: -80,
  };
};

const getOfferPartnerType = ({ partnerType }) => ({
  text: partnerType.value,
  style: partnerType.highlightLabel ? 'additionalHeadline' : 'propertyHeading',
  margin: [0, 15, 0, 20],
  alignment: 'left',
  fontSize: 8,
});

const getRequiredFilesName = ({ requiredFiles }) => {
  if (!requiredFiles.value.length) {
    return { text: '–', style: 'propertyValue' };
  }

  return {
    ul: requiredFiles.value.map(({ name }) => ({
      text: name,
      style: 'propertyValue',
    })),
    fontSize: 8,
    marginLeft: -80,
  };
};
const getRequiredFilesLabel = (offers) => ({
  text: offers[0].requiredFiles.label,
  style: 'propertyHeading',
});

const printBankNames = (offers) => [emptyBankLabel, ...offers.map(getBankNames)];
const printPartnerType = (offers) => [...offers.map(getOfferPartnerType), emptyBankLabel];
const printProducts = (offers) => [getProductLabel(offers[0]), ...offers.map(getProductValue)];
const printProperties = (offers) => {
  const offerProperties = offers[0].properties;
  return offerProperties.map(({ label }) => {
    const propertyLabel = {
      text: label,
      style: 'propertyHeading',
    };
    const propValues = offers.map(getPropertyValues(label));

    return [propertyLabel, ...propValues];
  });
};
const printRequiredDocs = (offers) => {
  const label = getRequiredFilesLabel(offers);
  const docs = offers.map(getRequiredFilesName).filter((el) => !!el);

  return [label, ...docs];
};

const buildOffersTableRows = (offers) => [
  printBankNames(offers),
  printPartnerType(offers),
  printProducts(offers),
  ...printProperties(offers),
  printRequiredDocs(offers),
];

const noBreakForLastEl = (arr, index) => (index + 1 === arr.length ? null : 'after');

const buildOffersTable = (offers, index, allTables) => ({
  pageBreak: noBreakForLastEl(allTables, index),
  table: {
    layout: 'noBorders',
    headerRows: 1,
    widths: getColumnWidths(offers),
    body: buildOffersTableRows(offers),
  },
  layout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    vLineColor: '#ffffff',
    hLineColor: '#ffffff',
    fillColor: 'white',
    paddingLeft: () => 10,
    paddingRight: () => 10,
    paddingTop: () => 5,
    paddingBottom: () => 5,
  },
});

// put fake empty space below header as margin on table doesn't work
const createTables = (offers) => {
  return _chunk(offers, OFFERS_PER_ROW).map((offer, index, allOffers) => [
    buildOffersTable(offer, index, allOffers),
  ]);
};

const generateFooter = (footer, currentPage, pageCount) => {
  const sections = [...footer];
  const copyright = sections.pop();

  return [
    ...sections.map((content) => ({ text: content, style: 'footer' })),
    {
      table: {
        widths: ['*'],
        body: [[{ text: copyright, style: 'copyrights' }]],
      },
      margin: [PAGE_MARGIN, 0, PAGE_MARGIN, 0],
      layout: {
        vLineWidth: () => 0,
        hLineWidth: (i) => (i === 0 ? 1 : 0),
      },
    },
    {
      text: `${currentPage}/${pageCount}`,
      margin: [0, 20, PAGE_MARGIN, PAGE_MARGIN],
      alignment: 'right',
    },
  ];
};

export const generatePdfContent = ({ offers, title, logo, colorTheme, headline, footer }) => ({
  header: getHeaderLayout(title, logo, colorTheme, headline),
  content: createTables(offers),
  footer: (currentPage, pageCount) => generateFooter(footer, currentPage, pageCount),
  styles: {
    heading: {
      fontSize: 11,
      bold: true,
      margin: [0, 0, 0, 10],
    },
    additionalHeadline: {
      color: colorTheme.colors.primary,
      margin: [0, 15, 0, 20],
    },
    productHeading: {
      margin: [0, 0, 0, 0],
      color: '#808080',
      fontSize: 10,
    },
    productValue: {
      margin: [0, 0, 0, 0],
      fontSize: 8,
    },
    propertyHeading: {
      color: '#808080',
      margin: [0, 0, 0, 0],
      fontSize: 8,
    },
    propertyValue: {
      margin: [0, 0, 0, 0],
      fontSize: 8,
    },
    requiredFile: {
      margin: [0, 0, 0, 10],
      fontSize: 11,
    },
    footer: {
      fontSize: 11,
      alignment: 'center',
      margin: [PAGE_MARGIN, 0, PAGE_MARGIN, 10],
    },
    copyrights: {
      fontSize: 8,
      alignment: 'center',
      margin: [0, 5, 0, 0],
    },
  },
  pageOrientation: 'portrait',
  pageMargins: [PAGE_MARGIN, 150, PAGE_MARGIN, 150], // top and bottom page margin bigger for header/footer
  defaultStyle: {
    font: 'Ubuntu',
  },
});
