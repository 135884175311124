import * as React from 'react';

import { Box, Grid, GridItem, VisuallyHidden, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { getCompanyId, getExternalReference } from 'store/inquiryDetails/selectors';
import { isCustomerSelector } from 'store/user/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { CircularProgress } from 'theme/components/CircularProgress';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import { LinkComponent } from 'theme/components/Link';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useSummaryCardData } from './useSummaryCardData';

type SummaryCardProps = {
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  openTaskId?: string;
  canShowProgress?: boolean;
};

export const OdenwaldSummaryCard = ({
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  openTaskId,
  canShowProgress = true,
}: SummaryCardProps) => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const isContract = data.lane === InquiryLane.contract;
  const isDefault = data.formType === InquiryType.default;
  const isDvag = data.formType === InquiryType.dvag;
  const isCustomer = useSelector(isCustomerSelector);
  const companyId = useSelector(getCompanyId);
  const externalRef = useSelector(getExternalReference);

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)']}
      gap={12}
      as="section"
      border="1px"
      borderColor="border.lightGrey"
      p={8}
      boxShadow="default"
      bgColor="background.white"
    >
      <GridItem>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          paddingBottom={3}
        >
          <HeadingComponent as="h4" variant="hidden">
            {t('pages.inquiryDetails.detailed.summary.title')}
          </HeadingComponent>

          <DescriptionList mb={1}>
            <DescriptionListTerm isHidden>
              {t('pages.inquiryDetails.dashboard.summary.financingProduct')}
            </DescriptionListTerm>
            <DescriptionListDescription>
              {data.product} {!isCustomer && `- ${data.purpose}`}
            </DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="large" mb={8}>
            <DescriptionListTerm isHidden>
              {t('pages.inquiryDetails.detailed.summary.financingRequirement')}
            </DescriptionListTerm>
            <DescriptionListDescription>{data.amount}</DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="small" mb={8}>
            <DescriptionListTerm>
              <VisuallyHidden>
                {t('pages.inquiryDetails.detailed.summary.creationDate')}
              </VisuallyHidden>
              <TimeIcon boxSize={6} d="block" mr={-1} />
            </DescriptionListTerm>
            <DescriptionListDescription>
              <time dateTime="2020-09-19T11:25:00">
                <TextComponent as="span" display="block">
                  {data.createdAtSince} {t('pages.inquiryDetails.dashboard.summary.sinceCreation')}
                </TextComponent>
                <TextComponent as="span" display="block" color="text.tertiary">
                  ({data.createdAt})
                </TextComponent>
              </time>
            </DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="small">
            <DescriptionListTerm mr={3}>
              {t('pages.inquiryDetails.dashboard.summary.id')}
            </DescriptionListTerm>
            <DescriptionListDescription>{data.id}</DescriptionListDescription>
          </DescriptionList>
          {!isCustomer && externalRef && (
            <DescriptionList size="small">
              <DescriptionListTerm mr={3}>
                {t('pages.inquiryDetails.dashboard.summary.externalReference')}
              </DescriptionListTerm>
              <DescriptionListDescription>{externalRef}</DescriptionListDescription>
            </DescriptionList>
          )}
        </Box>
      </GridItem>

      <GridItem>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          position="relative"
          height="100%"
          paddingBottom={3}
          _before={{
            content: "''",
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '-32px',
            width: '1px',
            backgroundColor: 'background.darkGrey',
          }}
        >
          <VStack spacing="1rem" align="flex-start">
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.companyName')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {!isCustomer && !isDefault && !isDvag ? (
                  <LinkComponent
                    variant="secondary"
                    href={paths.operation.companies.overview(companyId)}
                  >
                    {data.companyName}
                  </LinkComponent>
                ) : (
                  data.companyName
                )}
              </DescriptionListDescription>
            </DescriptionList>

            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.status')}
              </DescriptionListTerm>
              <DescriptionListDescription>{data.status}</DescriptionListDescription>
            </DescriptionList>

            {!isDefault && !isDvag && !isCustomer && (
              <DescriptionList>
                <DescriptionListTerm>
                  {t('pages.inquiryDetails.dashboard.summary.inquiryType')}
                </DescriptionListTerm>
                <DescriptionListDescription>
                  {isContract
                    ? t(`pages.inquiryDetails.dashboard.summary.contractLane`)
                    : t('pages.inquiryDetails.dashboard.summary.leadLane')}
                </DescriptionListDescription>
              </DescriptionList>
            )}
          </VStack>
        </Box>
      </GridItem>

      <GridItem>
        {canShowProgress && (
          <Box
            display="flex"
            alignItems={['flex-start', null, null, 'center']}
            flexDirection="column"
            justifyContent="flex-end"
            position="relative"
            height="100%"
            _before={{
              content: "''",
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '-24px',
              width: '1px',
              backgroundColor: 'background.darkGrey',
            }}
          >
            <CircularProgress
              progressIncrement={progressIncrement!}
              progressTotal={progressTotal!}
              progressType={t('other.tasks')}
              isCompleted={isCompleted}
              completedValue={isCompleted ? 100 : completedValue}
              size="large"
              mb={4}
            />

            {!isCompleted && (
              <ButtonComponent
                as="a"
                href={`#${openTaskId}`}
                leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
                variant="tertiary"
              >
                {t('other.openTasks')}
              </ButtonComponent>
            )}
          </Box>
        )}
      </GridItem>
    </Grid>
  );
};
