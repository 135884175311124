import React from 'react';

import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormSection from 'components/FormSection';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  CONTROL_OBJECT_DATA_STEP,
  CURRENT_USAGE_STEP,
  FUTURE_USAGE_STEP,
} from 'modules/Inquiry/Form/formFields';
import { mapMarketInformationToApi } from 'modules/Inquiry/mapInquiryToApi/MarketInformationMapper/mapMarketInformationToApi';
import { useRequestMarketData } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import { useToasts } from 'shared/hooks/useToasts';
import { marketDataSentAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector, getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { MittweidaMarketInformationInquiryTable } from '../../../mittweida/portals/operations/steps/ReceiveMarketValues/MarketInformationInquiryTable';
import { InquiryType } from '../../../modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import MarketInformationHeading from './MarketInformationHeading';
import MarketInformationTableStep from './MarketInformationTableStep';

const MarketInformationInquiryTable: React.FC = () => {
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { financingPurposeFields } = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const { isPending, requestMarketData } = useRequestMarketData(inquiryId!);

  const marketData = mapMarketInformationToApi(financingPurposeFields);

  const InquiryTableSteps = [CONTROL_OBJECT_DATA_STEP, FUTURE_USAGE_STEP, CURRENT_USAGE_STEP];
  const history = useHistory();
  const t = useTranslations();

  const submitLabel = t('pages.finishRegistration.confirmBtn');
  const cancelLabel = t('buttons.cancel');
  const suceessToast = t('pages.inquiryDetails.dashboard.actions.triggerMarketResearch.success');

  const stepTitle = (stepName: string) => {
    const camelStepName = _.camelCase(stepName);
    return t(`pages.marketInformationInquiryPage.steps.${camelStepName}.title`);
  };

  const path = paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId!);
  const redirectToDashboard = () => history.push(path);
  const pathMarketAddress = paths.operation.inquiryDetails.marketInformationAddress.replace(
    ':id',
    inquiryId!,
  );
  const { error, success } = useToasts();

  const handleSubmit = async () => {
    const res = await requestMarketData(marketData);
    if (res) {
      if (res.error) {
        if (res.error.response.status === 409) {
          history.push(pathMarketAddress);
        } else if (res.error.response.status === 422) {
          error({ description: res.error.response.data.error });
        }
      } else {
        if (res.status === 200) {
          dispatch(marketDataSentAction());
          success({ description: suceessToast });
          redirectToDashboard();
        }
      }
    }
  };

  return (
    <div>
      <MarketInformationHeading />
      {InquiryTableSteps?.map((stepName, index) => (
        <FormSection key={stepName} sectionNumber={index + 1} title={stepTitle(stepName)}>
          <MarketInformationTableStep stepName={stepName} />
        </FormSection>
      ))}
      <StyledActions>
        <ButtonComponent mr={4} onClick={redirectToDashboard} variant="tertiary">
          {cancelLabel}
        </ButtonComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
          type="submit"
          onClick={handleSubmit}
          disabled={isPending}
        >
          {submitLabel}
        </ButtonComponent>
      </StyledActions>
    </div>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: MarketInformationInquiryTable,
  [InquiryType.profiMittweida]: MittweidaMarketInformationInquiryTable,
});
