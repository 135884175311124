import * as React from 'react';

import { Box } from '@chakra-ui/react';

import ProgressStage from 'components/ProgressStage';
import { transition, zIndex } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';

import useIsScrolledToTop from '../../../utils/hooks/useIsScrolledToTop';
import { useInquiryProcessConfig } from '../state/inquiryProcessConfig/hooks';
import { useCalculateProgress } from './useCalculateProgress';

export const NewProgressBar = () => {
  const t = useTranslations();
  useCalculateProgress();
  const {
    state: { pageValidations, currentStep },
    formStateData: { stepSequence, stepData },
  } = useInquiryProcessConfig();
  const contentIsScrolledToTop = useIsScrolledToTop();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      position={'fixed'}
      zIndex={zIndex.horizontalProgressBar}
      top={0}
      width={'full'}
      transform={`translateY(${contentIsScrolledToTop ? 9 : 6}rem)`}
      transition={`transform ${transition.default}`}
      willChange={'transform'}
    >
      {stepSequence.map((page, index) => {
        const { progress } = pageValidations[page as any];
        return (
          <ProgressStage
            key={index}
            stepName={t(stepData[page as any].progressBarTitle)}
            validSections={progress.numberOfValidSections}
            numberOfSections={progress.numberOfSections}
            active={index <= stepSequence.indexOf(currentStep)}
            isCurrentStage={currentStep === page}
          />
        );
      })}
    </Box>
  );
};
