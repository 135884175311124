import { editBankValuesLabelsMap } from '../../../modules/EditMarketValuesReeProfitability/editBankValuesLabel';
import { editMarketValueReeProfitabillityLabelsMap } from '../../../modules/EditMarketValuesReeProfitability/editMarketValueReeProfitabillityLabel';
import { LabelsMap } from '../../../new/form/common/types';
import { mittweidaMarketInformationTableLabelsMap } from './mittweidaMarketInformationTableLabels';

export const mittweidaOperationLabelsMap: LabelsMap = {
  ...editBankValuesLabelsMap,
  ...editMarketValueReeProfitabillityLabelsMap,
  ...mittweidaMarketInformationTableLabelsMap,
};
