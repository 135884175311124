import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { fetchInquiryAction } from 'modules/InquiryDetails/InquiryDetails.service';
import { SESSION_STORAGE_KEYS } from 'shared/inquiry/inquirySessionStorage.helpers';
import { setInquiryDraftId } from 'store/inquiryProcess/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import saveFormStateToSessionStorage from 'utils/sessionStorage/saveFormStateToSessionStorage';

const useInitInquiryFormByDraft = ({ inquiryDraftId }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();

  const initialize = useCallback(async () => {
    const { payload, error } = await makeCall(fetchInquiryAction(inquiryDraftId));
    if (error) {
      throw new Error('Fetching inquiry failed.');
    }
    SESSION_STORAGE_KEYS.forEach((sectionId) => {
      const data = payload.data.data.attributes.details[sectionId];
      if (data) {
        saveFormStateToSessionStorage(sectionId)({
          values: data,
        });
      }
    });
    dispatch(setInquiryDraftId(inquiryDraftId));
    setIsInitialized(true);
  }, [dispatch, inquiryDraftId, makeCall]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return { isInitialized };
};

export default useInitInquiryFormByDraft;
