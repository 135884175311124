import {
  BFS_SERVICE__PURPOSE_KIND_OCCUPATIONAL_THERAPIST,
  BFS_SERVICE__PURPOSE_KIND_MIDWIVES,
  BFS_SERVICE__PURPOSE_KIND_HEARING_AID_ACOUSTICS,
  BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT,
  BFS_SERVICE__PURPOSE_KIND_SPEECH_THERAPY,
  BFS_SERVICE__PURPOSE_KIND_HOMECARE_SERVICE,
  BFS_SERVICE__PURPOSE_KIND_MEDICAL_TECHNOLOGY,
  BFS_SERVICE__PURPOSE_KIND_ORTHOPEDICS,
  BFS_SERVICE__PURPOSE_KIND_PODOLOGY,
  BFS_SERVICE__PURPOSE_KIND_PHYSIOTHERAPY,
  BFS_SERVICE__PURPOSE_KIND_REHABILITATION_PHYSIOTHERAPY,
  BFS_SERVICE__PURPOSE_KIND_MEDICAL_SUPPLY_STORES,
  BFS_SERVICE__PURPOSE_KIND_OUTPATIENT_CARE_SERVICES,
  BFS_SERVICE__PURPOSE_KIND_CARE_AND_RESPITE_SERVICES,
  BFS_SERVICE__PURPOSE_KIND_INTENSIVE_CARE,
  BFS_SERVICE__PURPOSE_KIND_YOUTH_AID,
  BFS_SERVICE__PURPOSE_KIND_HOSPITALS,
  BFS_SERVICE__PURPOSE_KIND_REHABILITATION_CLINICS_AND_CENTERS,
  BFS_SERVICE__PURPOSE_KIND_RESCUE_SERVICES,
  BFS_SERVICE__PURPOSE_KIND_DAY_CARE,
  BFS_SERVICE__PURPOSE_KIND_RESIDENTIAL_COMMUNITIES,
  BFS_SERVICE__PURPOSE_KIND_OTHER,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PURPOSE_KIND__FACTOMAT,
} from 'modules/Inquiry/Form/formFields';

// this is also being used in value translator
export const bfsServiceKindOptions = [
  {
    id: 'pages.bfsService.sections.industry.options.outpatientCareServices.caption',
    value: BFS_SERVICE__PURPOSE_KIND_OUTPATIENT_CARE_SERVICES,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.careAndRespiteServices.caption',
    value: BFS_SERVICE__PURPOSE_KIND_CARE_AND_RESPITE_SERVICES,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.occupationalTherapists.caption',
    value: BFS_SERVICE__PURPOSE_KIND_OCCUPATIONAL_THERAPIST,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.midwives.caption',
    value: BFS_SERVICE__PURPOSE_KIND_MIDWIVES,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.homecareService.caption',
    value: BFS_SERVICE__PURPOSE_KIND_HOMECARE_SERVICE,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.hearingAidAcoustics.caption',
    value: BFS_SERVICE__PURPOSE_KIND_HEARING_AID_ACOUSTICS,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.intensiveCare.caption',
    value: BFS_SERVICE__PURPOSE_KIND_INTENSIVE_CARE,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.youthAid.caption',
    value: BFS_SERVICE__PURPOSE_KIND_YOUTH_AID,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.hospitals.caption',
    value: BFS_SERVICE__PURPOSE_KIND_HOSPITALS,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.patientTransport.caption',
    value: BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.speechTherapy.caption',
    value: BFS_SERVICE__PURPOSE_KIND_SPEECH_THERAPY,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.medicalTechnology.caption',
    value: BFS_SERVICE__PURPOSE_KIND_MEDICAL_TECHNOLOGY,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.orthopedics.caption',
    value: BFS_SERVICE__PURPOSE_KIND_ORTHOPEDICS,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.physiotherapy.caption',
    value: BFS_SERVICE__PURPOSE_KIND_PHYSIOTHERAPY,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.podology.caption',
    value: BFS_SERVICE__PURPOSE_KIND_PODOLOGY,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.rehabilitationClinicsAndCenters.caption',
    value: BFS_SERVICE__PURPOSE_KIND_REHABILITATION_CLINICS_AND_CENTERS,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.rehabilitationPhysiotherapy.caption',
    value: BFS_SERVICE__PURPOSE_KIND_REHABILITATION_PHYSIOTHERAPY,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.rescueServices.caption',
    value: BFS_SERVICE__PURPOSE_KIND_RESCUE_SERVICES,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.medicalSupplyStores.caption',
    value: BFS_SERVICE__PURPOSE_KIND_MEDICAL_SUPPLY_STORES,
    purposeKind: PURPOSE_KIND__FACTOMAT,
  },
  {
    id: 'pages.bfsService.sections.industry.options.dayCare.caption',
    value: BFS_SERVICE__PURPOSE_KIND_DAY_CARE,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.residentialCommunities.caption',
    value: BFS_SERVICE__PURPOSE_KIND_RESIDENTIAL_COMMUNITIES,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
  {
    id: 'pages.bfsService.sections.industry.options.other.caption',
    value: BFS_SERVICE__PURPOSE_KIND_OTHER,
    purposeKind: PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  },
];

const getBfsServicePurposeKindOptions = (formatMessage: any) =>
  bfsServiceKindOptions.map(({ value, id, purposeKind }) => ({
    value,
    label: formatMessage({ id }),
    purposeKind,
  }));

export default getBfsServicePurposeKindOptions;
