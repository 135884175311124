import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import mapMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueFromApi';
import mapRealEstateMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapRealEstateMarketValueFromApi';
import { PROFI_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { flattenObject } from 'utils/helpers';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

const getMarketValueDetailsAction = (inquiryId: string, recordType: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_VALUE_DETAILS.compose({
      params: { inquiryId },
      query: { record_type: recordType },
    }),
    method: API_METHODS.GET,
  });

const loadMarketValueDetails = async (
  makeCall: IMakeCall,
  inquiryId: string,
  recordType: string,
) => {
  const { payload, error } = await makeCall(getMarketValueDetailsAction(inquiryId, recordType));
  const data = _get(payload, 'data');
  let editFormValues;
  if (recordType === PROFI_RECORD_TYPE.VALUE_API) {
    editFormValues = mapMarketValueFromApi(data);
  } else if (recordType === PROFI_RECORD_TYPE.REE) {
    editFormValues = mapRealEstateMarketValueFromApi(data);
  }
  FormFieldController.instance.saveBulk(flattenObject(editFormValues), { emit: false });
  return { error };
};

export default loadMarketValueDetails;
