import React, { useState } from 'react';

import { chakra, Heading } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import PortalPage from 'components/PortalPage';
import { IUserData } from 'models/UserData.model';
import NewUserDataForm from 'modules/UserData/components/NewUserDataForm';
import StaticUserDataForm from 'modules/UserData/components/StaticUserDataForm';
import { useUpdateUserData } from 'modules/UserData/helpers/useUpdateUserData';
import { StyledHr } from 'modules/UserData/styles';
import { getLoggedInUserDataSelector } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import ResetPasswordSection from './ResetPasswordSection';

const UserDataPage = () => {
  const userFormValues = useSelector(getLoggedInUserDataSelector);

  const [isSending, setIsSending] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const t = useTranslations('pages.userData');

  const updateUserData = useUpdateUserData();
  const sendUpdateToApi = async (values: IUserData) => {
    setIsSending(true);
    await updateUserData(values);
    setIsEditMode((mode) => !mode);
    setIsSending(false);
  };

  return (
    <PortalPage title={t('heading')} pageTitle={t('heading')}>
      <FinalForm
        onSubmit={sendUpdateToApi}
        initialValues={userFormValues}
        render={({ handleSubmit }) => (
          <chakra.section marginBottom={8}>
            <Heading as="h3" marginBottom={7} variant="secondary" textTransform="uppercase">
              {t('subheading')}
            </Heading>
            <form onSubmit={handleSubmit}>
              {isEditMode ? (
                <NewUserDataForm isLoading={isSending} isEditMode={isEditMode} />
              ) : (
                <StaticUserDataForm userData={userFormValues} onEdit={() => setIsEditMode(true)} />
              )}
            </form>
          </chakra.section>
        )}
      />
      <StyledHr />
      <ResetPasswordSection email={userFormValues.email} />
    </PortalPage>
  );
};

export default UserDataPage;
