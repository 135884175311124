import * as React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  Flex,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';

import { textStyle } from '../../themeConstants';

type Props = {
  headingLevel: any;
  title: string;
  text?: string;
  action?: React.ReactNode;
  children: React.ReactNode;
} & AccordionProps;

export const AccordionComponent = ({
  headingLevel,
  title,
  text,
  action,
  children,
  ...props
}: Props) => {
  const accordionStyles = useMultiStyleConfig('Accordion', {});

  return (
    <Accordion sx={accordionStyles.accordion} as={'section'} allowMultiple {...props}>
      <AccordionItem sx={accordionStyles.item}>
        {({ isExpanded }) => (
          <>
            <Flex alignItems="flex-start">
              <Box flex="1 0 auto" pr={action ? '2rem' : undefined}>
                <Box as={headingLevel}>
                  <AccordionButton sx={accordionStyles.button}>
                    {isExpanded ? (
                      <CaretDownIcon boxSize={6} mr={2} />
                    ) : (
                      <CaretDownIcon boxSize={6} mr={2} transform="rotate(-90deg)" />
                    )}
                    <Box as="span">{title}</Box>
                  </AccordionButton>
                </Box>
                {text && (
                  <Box
                    as="span"
                    display="block"
                    mt={3}
                    pl={8}
                    {...textStyle.sizeM}
                    color="text.tertiary"
                  >
                    {text}
                  </Box>
                )}
              </Box>
              {action && action}
            </Flex>
            <AccordionPanel sx={accordionStyles.panel}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
