import React from 'react';

import PropTypes from 'prop-types';

import FormRow from 'components/FormRow';
import StaticField from 'components/StaticField';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  EXISTING_USER_COMPANY_CITY,
  EXISTING_USER_COMPANY_NAME,
  EXISTING_USER_COMPANY_STREET,
  EXISTING_USER_COMPANY_ZIP_CODE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useTranslations } from 'utils/hooks/useTranslations';

const StaticCompanyInformation = ({ company }: any) => {
  const t = useTranslations('pages.userProfile.sections.completingCompanyDetails.fields');

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  const {
    [EXISTING_USER_COMPANY_CITY]: city,
    [EXISTING_USER_COMPANY_NAME]: name,
    [EXISTING_USER_COMPANY_STREET]: street,
    [EXISTING_USER_COMPANY_ZIP_CODE]: zipCode,
  } = company;

  return isBfsService ? (
    <FormRow alignItems="flex-start">
      <StaticField caption={t('userProfileCompanyName.caption')} text={name} />
      <StaticField
        caption={t('userProfileCompanyStreet.caption')}
        text={`${street}\n${zipCode} ${city}`}
      />
    </FormRow>
  ) : (
    <FormRow separator alignItems="flex-start">
      <StaticField caption={t('userProfileCompanyName.caption')} text={name} />
      <StaticField
        caption={t('userProfileCompanyStreet.caption')}
        text={`${street}\n${zipCode} ${city}`}
      />
    </FormRow>
  );
};

StaticCompanyInformation.propTypes = {
  company: PropTypes.shape({
    [EXISTING_USER_COMPANY_CITY]: PropTypes.string,
    [EXISTING_USER_COMPANY_NAME]: PropTypes.string,
    [EXISTING_USER_COMPANY_STREET]: PropTypes.string,
    [EXISTING_USER_COMPANY_ZIP_CODE]: PropTypes.string,
  }).isRequired,
};

export default StaticCompanyInformation;
