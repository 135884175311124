import {
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING_ADDITIONAL_BATHROOM,
  PROJECT_FINANCING_BALCONY,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_QUALITY_CONDITION,
  PROJECT_FINANCING_QUALITY_FURNISHING,
  PROJECT_FINANCING_YEAR_OF_CONSTRUCTIONS,
  PROJECT_FINANCING_YEAR_OF_MODERNIZATION,
  PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
  PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
} from '../../../modules/Inquiry/Form/formFields';
import { LabelsMap, translations } from '../../../new/form/common/types';

export const mittweidaMarketInformationTableLabelsMap: LabelsMap = {
  [PROJECT_FINANCING_OBJECT_ADDRESS]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.address
      .caption,
  [PROJECT_FINANCING_OBJECT_ZIP_CODE]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.zipCode
      .caption,
  [PROJECT_FINANCING_OBJECT_CITY]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.city
      .caption,
  [PROJECT_FINANCING_LOT_SIZE]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.lotSize
      .caption,
  [PROJECT_FINANCING_QUALITY_FURNISHING]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .furnishingQuality.caption,
  [PROJECT_FINANCING_QUALITY_CONDITION]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .conditionQuality.caption,
  [PROJECT_FINANCING_YEAR_OF_MODERNIZATION]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .yearOfModernization.caption,
  [PROJECT_FINANCING_YEAR_OF_CONSTRUCTIONS]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.year
      .caption,
  [PROJECT_FINANCING__LIFT]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.lift
      .caption,
  [PROJECT_FINANCING_BALCONY]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.balcony
      .caption,
  [PROJECT_FINANCING_ADDITIONAL_BATHROOM]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .additionalBathroom.caption,
  [PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .generalParking.caption,
  [PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .commercialSpasce.caption,
  [PROJECT_USAGE_SPACE_SUM_RESIDENTIAL]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .residentialSpace.caption,
};
