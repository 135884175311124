import { applyMiddleware, combineReducers, createStore } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import {
  COMPEON_API_CLIENT_NAME,
  AUTH_API_CLIENT_NAME,
  COMPEON_OAUTH_API_CLIENT_NAME,
  createAuthClient,
  createCompeonOAuthClient,
  createCompeonClient,
  createCompeonReverseClient,
  WHITE_LABEL_API_CLIENT_NAME,
  MOCK_API_CLIENT_NAME,
  createMockApiClient,
} from 'api';
import refreshTokenMiddleware from 'middlewares/refreshToken/refreshToken';
import { ICompanyDetailsState } from 'models/CompanyDetails.model';
import companyDetails from 'pages/operationPortal/CompaniesDetails/store/reducer';
import companiesList, { ICompaniesList } from 'pages/operationPortal/CompaniesList/store/reducer';
import filterManagementReducer, {
  IMarketDataFilterState,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import banks from 'store/banks/reducer';
import config from 'store/config/reducer';
import { documentExchangeReducer } from 'store/documentExchange/documentExchange.reducer';
import emailAvailable from 'store/emailAvailable/reducer';
import inquiries from 'store/inquiries/reducer';
import { inquiryListReducer, IInquiriesListState } from 'store/inquiryList/reducer';
import inquiryProcess from 'store/inquiryProcess/reducer';
import { layoutReducer } from 'store/layout/reducer';
import partner from 'store/partner/reducer';
import progress from 'store/progress/reducer';
import user from 'store/user/reducer';
import { isDevelopment } from 'utils/environment';

import {
  IProfitabilityEvaluationState,
  profitabilityEvaluationReducer,
} from '../mittweida/portals/operations/store/reducer';
import { inquiryDetailsReducer, IInquiryDetailsState } from './inquiryDetails/reducer';
import { kpiReducer as kpisList } from './kpi/kpis.reducer';
import { newInquiryDetailsReducer } from './newInquiryDetails/reducer';

const applyCommonMiddleware = applyMiddleware(
  thunk,
  refreshTokenMiddleware,
  multiClientMiddleware({
    [WHITE_LABEL_API_CLIENT_NAME]: {
      client: createCompeonReverseClient(),
    },
    [COMPEON_API_CLIENT_NAME]: {
      client: createCompeonClient(),
    },
    [AUTH_API_CLIENT_NAME]: {
      client: createAuthClient(),
    },
    [COMPEON_OAUTH_API_CLIENT_NAME]: {
      client: createCompeonOAuthClient(),
    },
    [MOCK_API_CLIENT_NAME]: {
      client: createMockApiClient(),
    },
    default: {
      client: createCompeonReverseClient(),
    },
  }),
);

const middleware = isDevelopment()
  ? composeWithDevTools(applyCommonMiddleware)
  : applyCommonMiddleware;

const store = createStore(
  combineReducers({
    partner,
    user,
    progress,
    banks,
    emailAvailable,
    inquiries,
    inquiryProcess,
    config,
    companiesList,
    kpisList,
    companyDetails,
    layout: layoutReducer,
    documentExchange: documentExchangeReducer,
    inquiryDetails: inquiryDetailsReducer,
    newInquiryDetails: newInquiryDetailsReducer,
    inquiriesList: inquiryListReducer,
    marketDataFilter: filterManagementReducer,
    profitability: profitabilityEvaluationReducer,
  }),
  middleware,
);

const persistor = persistStore(store);

if ('Cypress' in window) {
  const w = window as any;
  w.store = store;
}
export interface IState {
  partner: any;
  user: any;
  progress: any;
  banks: any;
  emailAvailable: any;
  dictionary: any;
  inquiries: any;
  inquiryProcess: any;
  config: any;
  layout: any;
  documentExchange: any;
  companiesList: ICompaniesList;
  companyDetails: ICompanyDetailsState;
  inquiryDetails: IInquiryDetailsState;
  inquiriesList: IInquiriesListState;
  marketDataFilter: IMarketDataFilterState;
  profitability: IProfitabilityEvaluationState;
}

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
