import React from 'react';

import { ThemingProps, useProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getUserAttributesAction } from 'api/CompeonReverseApi/operation/userData/actions';
import PortalPage from 'components/PortalPage';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { USER_ROLE_CUSTOMER } from 'constants/user/userRoles';
import Form, { ILoginFormValues } from 'modules/Login/LoginForm';
import { useLoginRedirect } from 'modules/Login/useLoginRedirect';
import SectionHeader from 'modules/SectionHeader/SectionHeader';
import { useHandleAccountUnlock } from 'pages/customerPortal/AccountUnlock/useHandleAccountUnlock';
import RegistrationLink from 'pages/customerPortal/Login/RegistrationLink';
import { useLoginErrorHandler } from 'pages/customerPortal/Login/useLoginErrorHandler';
import { logInCustomer } from 'shared/auth/loginLogout.actions';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { isLoggedInSelector } from 'store/user/selectors';
import { getUserAttributes } from 'store/user/service';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { setStorageExpiration } from 'utils/sessionStorage/expiration';

interface CustomerLoginProps extends ThemingProps {
  showRegisterLink?: boolean;
}

const CustomerLogin = () => {
  const {
    props: { showRegisterLink },
  } = useProps<CustomerLoginProps>('CustomerLogin', {}, true);
  useHandleAccountUnlock();
  const t = useTranslations();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  const afterLoginUrl = useLoginRedirect(paths.customer.inquiryList);
  const history = useHistory();
  const handleLoginError = useLoginErrorHandler();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const ssoEnabled = useConfig(CONFIG.IS_SSO_ENABLED);

  if (isLoggedIn) {
    // goto inquiry caz dvag after login should display inquiries.
    // After login as a customer should move to inquirylist
    if (ssoEnabled) {
      history.push(paths.customer.inquiryList);
    } else {
      history.push(afterLoginUrl);
    }
  }

  const login = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  const attrs = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  async function handleLogin({ email, password }: ILoginFormValues) {
    setStorageExpiration();
    const response = await login.makeCall(logInCustomer({ email, password }));
    if (response?.payload?.data) {
      const accessToken = response.payload.data.access_token;
      const { roles } = getUserAttributes(accessToken);
      await attrs.makeCall(getUserAttributesAction(), () => {
        if (roles?.includes(USER_ROLE_CUSTOMER)) {
          history.push(afterLoginUrl);
        }
      });
    }
    handleLoginError(response?.error);
  }

  return (
    <PortalPage
      stylesThemeKey="CustomerLoginPortalPage"
      biggerHeading
      pageTitle={t('pages.loginPage.customerHeading', { partnerName })}
    >
      <SectionHeader
        subheadingTranslationKey="pages.loginPage.subheading"
        leadTranslationKey="pages.loginPage.lead"
        stylesThemeKey="CustomerLoginHeading"
      />
      <Form
        onSubmit={handleLogin}
        isLoading={!!login.isPending || !!attrs.isPending}
        forgotPasswordLink={paths.customer.passwordReset.request}
      />

      {showRegisterLink ? <RegistrationLink /> : null}
    </PortalPage>
  );
};

export default CustomerLogin;
