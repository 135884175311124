import styled from 'styled-components/macro';

import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';

export const StyledHeading = styled.h5`
  margin-bottom: 1rem;
  padding-top: 0.25em;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
