import { shouldShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { FormFieldController } from 'store/FormFieldController/FieldController';

import {
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_TAKEOVER,
  MACHINE_INTERESTED_IN_INSURANCE,
  VEHICLE_INTERESTED_IN_INSURANCE,
} from './formFields';

const HIDDEN_FIELDS = [
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_TAKEOVER,
];
const fieldController = FormFieldController.instance;

export const simpleValidation =
  (fieldNames: string[]): (() => boolean) =>
  () => {
    const visibleFields = fieldNames.filter((fieldName) =>
      shouldShowField(fieldName, {
        formValues: fieldController.formValuesStore.formValues,
      }),
    );
    return visibleFields.every((fieldName) => {
      if (HIDDEN_FIELDS.includes(fieldName)) {
        return true;
      }
      const { isValid: valid, value } = fieldController.getValue(fieldName);

      // editMode: validation not exists for field
      if (valid === undefined) {
        // trigger validation and persist in FormValidationStore
        fieldController.saveValue(fieldName, value);
      }
      return valid;
    });
  };
