const CustomerLoginHeading = {
  parts: ['title', 'lead'],
  baseStyle: {},
  variants: {
    primary: {
      title: {
        color: 'brand.default',
        fontSize: 'xl',
        mb: 4,
      },
      lead: {
        color: 'text.tertiary',
        mb: 16,
      },
    },
    caption: {
      title: {
        fontSize: 'md',
        textTransform: 'uppercase',
        color: 'brand.default',
        mb: 8,
        fontWeight: 400,
      },
      lead: {
        display: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'caption',
  },
};

export default CustomerLoginHeading;
