import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';

import { PARTNERS } from 'constants/partner';
import paths from 'constants/paths';
import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const NewInquiryButton = () => {
  const [shouldRedirect, redirectUser] = useState(false);
  const t = useTranslations('pages.inquiryList');

  const handleButtonClick = () => redirectUser(true);

  if (shouldRedirect) {
    return <Redirect to={paths.inquiry.new.root} push />;
  }

  return (
    <ButtonComponent
      data-testid="inquiry-list-new-inquiry"
      leftIcon={<AddIcon boxSize={6} d="block" />}
      onClick={handleButtonClick}
    >
      {t('newInquiry')}
    </ButtonComponent>
  );
};

export default choosePartnerSpecificComponent({
  [PARTNERS.RENTENBANK]: null,
  [PARTNERS.AGRICULTURAL]: null,
  default: NewInquiryButton,
});
