import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { mittweidaFeToBeMapping } from 'mittweida/inquiry/state/feToBeMappingKeys';
import { mittweidaStepData } from 'mittweida/inquiry/state/stepData';
import { mittweidaStepSequence } from 'mittweida/inquiry/state/stepSequence';
import { mittweidaSummaryFields } from 'mittweida/inquiry/state/summaryFields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

export const mittweidaPortalConfig: PortalConfig<MittweidaFieldTypes> = {
  stepSequence: mittweidaStepSequence,
  stepData: mittweidaStepData,
  summaryFields: mittweidaSummaryFields,
  feToBeMappingKeys: mittweidaFeToBeMapping,
};
