import React from 'react';

import { HStack, Icon, VStack } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';

import OtherIcon from 'theme/components/Icon/ProductKind/OtherIcon';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';
import { formatPrice } from 'utils/valueFormats';

import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import { TextAreaWithField } from '../../../../../../components/TextArea';
import { translations } from '../../../../../../new/form/common/types';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import { mittweidaFields, MittweidaFieldTypes } from '../../../../fields';

export const OtherFundingSection = () => {
  const { values } = useFormState<MittweidaFieldTypes>();
  const { financingAmount } = values.financingNeedPage.projectCompositionSection;
  const { description } = mittweidaFields.fundingDetailsPage.otherFundingSection;
  const t = useTranslations();

  return (
    <FormSection
      title={t(translations.inquiryType.mittweida.pages.fundingDetails.sections.other.title)}
      name={'otherFunding'}
      sectionNumber={1}
    >
      <FormRow separator>
        <VStack gap={8} alignItems={'start'}>
          <TextComponent color={'text.tertiary'}>
            {t(
              translations.inquiryType.mittweida.pages.fundingDetails.sections.other
                .financingAmountSummary,
            )}
          </TextComponent>
          <HStack gap={8}>
            <Icon as={OtherIcon} boxSize={20} />
            <TextComponent {...textStyle.sizeXl}>{formatPrice(financingAmount)}</TextComponent>
          </HStack>
        </VStack>
      </FormRow>
      <FormRow>
        <TextAreaWithField
          name={description}
          captionTooltip={t(
            translations.inquiryType.mittweida.pages.fundingDetails.sections.other
              .productDescription.tooltip,
          )}
        />
      </FormRow>
    </FormSection>
  );
};
