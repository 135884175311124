import React from 'react';

import { Box } from '@chakra-ui/react';

import { StyledSummaryList as Blocks } from 'modules/InquiryDetails/DetailedInformation/SummarySection/styles';
import SummaryBlock from 'modules/InquiryDetails/DetailedInformation/SummarySection/SummaryBlock';

interface Props {
  values: {
    name: string;
    value: string;
  }[];
}

const ProjectCostingBoxes: React.FC<Props> = ({ values }: Props) => {
  return (
    <Box mb="12">
      <Blocks>
        {values &&
          values.map((boxValue) => (
            <SummaryBlock key={boxValue.name} name={boxValue.name} value={boxValue.value} />
          ))}
      </Blocks>
    </Box>
  );
};

export default ProjectCostingBoxes;
