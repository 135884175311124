import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import DetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/DetailedInquiryInformation';
import OnlineFactoringDetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/OnlineFactoringDetailedInquiryInformation';
import { MittweidaDetailedInquiryInformation } from 'pages/customerPortal/InquiryDetails/DetailedInformation/mittweida';
import DvagOperationDetailedInformation from 'pages/operationPortal/InquiryDetails/DetailedInformation/DvagOperationDetailedInformation';

const InquiryTypeSpecificDisplayCondition = () => {
  return (
    <InquiryTypeCondition
      portal
      cases={{
        onlinefactoring: <OnlineFactoringDetailedInquiryInformation />,
        [InquiryType.profiMittweida]: (
          <MittweidaDetailedInquiryInformation
            pathToEditInquiry={paths.operation.inquiryDetails.edit.root}
          />
        ),
        [InquiryType.dvag]: <DvagOperationDetailedInformation />,
        default: (
          <DetailedInquiryInformation
            pathToEditInquiry={paths.operation.inquiryDetails.edit.root}
          />
        ),
      }}
    />
  );
};

export default InquiryTypeSpecificDisplayCondition;
