import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { InquiryRevisionListElement } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import { StyledRevisionDateList } from 'modules/InquiryDetails/InquiryRevision/styles';
import { getRevision } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { formatDateWithTime } from 'utils/date';

import { REVISION_ACTIONS } from './actions';

const RevisionDates: React.FC = () => {
  const state = useSelector(getRevision);
  const dispatch = useDispatch();

  return (
    <StyledRevisionDateList>
      {state.revisionList.map((revision: InquiryRevisionListElement) => (
        <li key={revision.id} data-testid="revision-date">
          <ButtonComponent
            data-testid={state.selectedRevisionId === revision.id ? 'active' : null}
            onClick={() => dispatch({ type: REVISION_ACTIONS.SELECT_REVISION, data: revision.id })}
            variant={state.selectedRevisionId === revision.id ? 'primary' : 'secondary'}
          >
            {formatDateWithTime(revision.createdAt)}
          </ButtonComponent>
        </li>
      ))}
    </StyledRevisionDateList>
  );
};

export default RevisionDates;
