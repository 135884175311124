import React from 'react';

import { InputWithField } from 'components/Input';
import { END_USER_PHONE_NUMBER } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

const EndUserPhone = () => {
  const { minPhoneLength, isPhoneNumber, hasPhonePrefix, maxPhoneLength } = useFieldValidators();

  return (
    <InputWithField
      name={END_USER_PHONE_NUMBER}
      validate={combineValidators(hasPhonePrefix, isPhoneNumber, minPhoneLength, maxPhoneLength)}
      optional
    />
  );
};

export default EndUserPhone;
