import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const getProcessLogAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.PROCESS_LOG.compose({ params: { id: inquiryId } }),
    method: API_METHODS.GET,
  });

export default getProcessLogAction;
