import React from 'react';

import FormRow from 'components/FormRow';
import LoanDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanDescription';

const DvagAnnuityLoan = () => (
  <FormRow>
    <LoanDescription />
  </FormRow>
);

export default DvagAnnuityLoan;
