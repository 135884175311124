import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqLarge } from 'styles/breakpoints';

const Title = styled.h3`
  display: flex;
  flex-grow: 0;
  padding: 0 1rem 1rem 0;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-transform: uppercase;

  ${mqLarge`
    width: 20rem;
  `};
`;

export default Title;
