import {
  USER_REPRESENTATION,
  USER_SHARED_REPRESENTATION,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';

// The order of these labels is relevant for people details page
export const peopleDetailsLabelsMap: { [key: string]: string } = {
  [USER_REPRESENTATION]:
    'pages.peopleDetails.sections.legalRepresentation.fields.userRepresentation.caption',
  [USER_SHARED_REPRESENTATION]:
    'pages.peopleDetails.sections.legalRepresentation.fields.userSharedRepresentation.caption',
  [REPRESENTATIVE_GENDER]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.gender.caption',
  [REPRESENTATIVE_FIRST_NAME]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.firstName.caption',
  [REPRESENTATIVE_LAST_NAME]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.lastName.caption',
  [REPRESENTATIVE_EMAIL]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.email.caption',
  [REPRESENTATIVE_BIRTHDATE]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.birthdate.caption',
  [REPRESENTATIVE_PHONE_NUMBER]:
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives.phoneNumber.caption',
};

export const bfsSPeopleDetailsLabelsMap: { [key: string]: string } = {
  [USER_REPRESENTATION]: '',
};
