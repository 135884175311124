import { useSelector } from 'react-redux';

import { IInquiryListOptions } from 'models/InquiryList.model';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
import {
  getInquiryListTotalPages,
  getInquiryListPageNum,
  getInquiryListPageSize,
  getInquiryListOptionsSelector,
  getInquiryListIsLoadingSelector,
  getInquiryListIsInitializedSelector,
  getInquiryListSortBy,
  getInquiryListSortDirection,
} from 'store/inquiryList/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const getSortDirection = (currentOptions: IInquiryListOptions, columnId: string) => {
  const { DESC, ASC } = SORT_DIRECTION;
  if (currentOptions.sortBy !== columnId) {
    return DESC;
  }
  return currentOptions.sortDirection === DESC ? ASC : DESC;
};

export const useInquiryTable = () => {
  const isLoading = useSelector(getInquiryListIsLoadingSelector);
  const isInitialized = useSelector(getInquiryListIsInitializedSelector);

  const totalPages = useSelector(getInquiryListTotalPages);
  const currentPage = useSelector(getInquiryListPageNum);
  const pageSize = useSelector(getInquiryListPageSize);

  const { makeCall } = useDispatchApiCall();
  const tableOptions = useSelector(getInquiryListOptionsSelector);
  const onPageSizeChange = (newPageSize: number) =>
    makeCall(
      getInquiriesAction({
        ...tableOptions,
        pageSize: newPageSize,
        currentPage: 1,
      }),
    );

  const onPaginationChange = (newPage: number) =>
    makeCall(getInquiriesAction({ ...tableOptions, currentPage: newPage }));

  const sortBy = useSelector(getInquiryListSortBy);
  const sortDirection = useSelector(getInquiryListSortDirection);

  const onSortChange = (columnId: string) =>
    makeCall(
      getInquiriesAction({
        ...tableOptions,
        sortBy: columnId,
        sortDirection: getSortDirection(tableOptions, columnId),
      }),
    );

  return {
    totalPages,
    currentPage,
    pageSize,
    onPageSizeChange,
    onPaginationChange,
    isLoading,
    isInitialized,
    sortBy,
    sortDirection,
    onSortChange,
  };
};
