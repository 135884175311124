import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';

export const NavBar = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};
`;

export const NavBarHeading = styled.h3`
  overflow: hidden;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${mqMedium`
    flex-direction: row;
  `}
`;

export const MainNavigation = styled.div`
  ${mqMedium`
    display: flex;
    flex: 1 1 0%;
  `}
`;

export const AdditionalNavigation = styled.div`
  display: flex;
`;

export const NavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  font-size: 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.tertiary};

  ${mqMedium`
    margin-right: 4rem;
  `}

  &:last-of-type {
    margin-right: 0;
  }

  &.active {
    box-shadow: inset 0 -4px 0 ${({ theme }) => theme.colors.primary};
  }
`;
