import * as React from 'react';

import { Grid, GridItem, ModalBody, useDisclosure } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormRow from 'components/FormRow';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { SelectWithField } from 'components/Selects/Select';
import { ITRSearchResult } from 'models/types/TransparencyRegister.types';
import { COMPANY_DETAILS } from 'modules/Inquiry/Form/formFields';
import { transparencyRegisterPickOrderAction } from 'store/inquiryDetails/actions';
import { getTransparencyResults } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

type TransparencyCompanySelectionStepProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
};

export const TransparencyCompanySelectionStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: TransparencyCompanySelectionStepProps) => {
  const { id: inquiryId } = useParams<{ id: string }>();
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.transparencyCompanySelection');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const transparencyResults = useSelector(getTransparencyResults);
  const results = transparencyResults?.searchResults?.map((result: ITRSearchResult) => ({
    label: result.info,
    value: String(result.orderId),
  }));
  const noMatchOption = {
    label: t('modal.noMatch'),
    value: null,
  };
  const options = results && [noMatchOption, ...results];
  const { isPending, makeCall } = useDispatchApiCall();

  const handleSubmitForm = async (formValues: any) => {
    const response = await makeCall(
      transparencyRegisterPickOrderAction(inquiryId, formValues[COMPANY_DETAILS]),
    );
    if (!response.error) {
      onClose();
    }
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>
          <ModalComponent
            testId="transparency-company-selection-modal"
            isOpen={isOpen}
            onClose={onClose}
            title={t('modal.title')}
            footer={
              <>
                <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
                  {t('modal.cancel')}
                </ButtonComponent>
                <ButtonComponent
                  type="submit"
                  form="form-transparency-register-select-company"
                  isLoading={isPending}
                >
                  {t('action')}
                </ButtonComponent>
              </>
            }
          >
            <ModalBody>
              <Form
                onSubmit={handleSubmitForm}
                render={({ handleSubmit }) => (
                  <form id="form-transparency-register-select-company" onSubmit={handleSubmit}>
                    <FormRow>{t('modal.description')}</FormRow>
                    <FormRow>
                      <SelectWithField
                        name={COMPANY_DETAILS}
                        options={options}
                        caption={t('modal.company')}
                        data-testid={'select-field-' + COMPANY_DETAILS}
                      />
                    </FormRow>
                  </form>
                )}
              />
            </ModalBody>
          </ModalComponent>
          {!isCompleted && (
            <ButtonComponent
              onClick={onOpen}
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              variant="primary"
            >
              {t('action')}
            </ButtonComponent>
          )}
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
