import { FieldMetaState } from 'react-final-form';

const getErrorMessage = (visited: boolean, error: string | object) => {
  if (visited && error) {
    return error;
  }
  return null;
};

export const getErrorMessageForField = (meta: FieldMetaState<any>) => {
  const shouldShow = Boolean((meta.visited && meta.touched) || meta.submitFailed);

  return getErrorMessage(shouldShow, meta.error);
};

export const getErrorMessageForStaticField = (meta: FieldMetaState<any>) => {
  // static fields should always show error messages as
  // the fields can be touched
  return getErrorMessage(true, meta.error);
};
