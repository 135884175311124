import { FONT_WEIGHT_BOLD } from '../../../../constants/globalConstants';
import { borderRadius } from '../../../themeConstants';

const Checkbox = {
  parts: ['container', 'control', 'label', 'icon'],
  baseStyle: {
    control: {
      w: 'none',
      borderColor: 'border.lightGrey',
      borderStyle: 'solid',
      borderRadius: borderRadius.large,
      _checked: {
        bg: 'brand.default',
        borderColor: 'brand.default',
        _hover: {
          backgroundColor: 'brand.default',
        },
        _focus: {
          borderColor: 'border.lightGrey',
        },
      },
      _hover: {
        borderColor: 'brand.default',
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'brand.default',
      },
    },
    label: {
      color: 'text.tertiary',
      a: {
        fontWeight: FONT_WEIGHT_BOLD,
        textDecoration: 'none',
        color: 'brand.default',
        _focus: {
          textDecoration: 'underline',
        },
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        boxSize: 8,
      },
      icon: {
        boxSize: 8,
      },
      label: {
        paddingLeft: '0.5rem',
      },
    },
    lg: {
      control: {
        boxSize: 12,
      },
      icon: {
        boxSize: 12,
      },
      label: {
        paddingLeft: '1rem',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export default Checkbox;
