import {
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PASSWORD_REPEATED,
} from 'modules/Inquiry/Form/formFields';

import { retrieveObject } from './helpers';

/*
 * Fields that should never be loaded
 */
const EXCLUDED_FIELDS = [USER_PROFILE_PASSWORD, USER_PROFILE_PASSWORD_REPEATED];

const loadFormState = ([formId], state) => {
  const values = retrieveObject(formId) || {};
  EXCLUDED_FIELDS.forEach((field) => delete values[field]);
  // mutability needed to account for yet unavailable fields
  const stateRef = state;
  stateRef.formState.values = {
    ...stateRef.formState.values,
    ...values,
  };
};

export default loadFormState;

export const loadFormStepStateFromStorage = (stageName) => {
  const values = retrieveObject(stageName) || {};
  EXCLUDED_FIELDS.forEach((field) => delete values[field]);
  return values;
};

export const loadFormStateFromStorage = (stepList) =>
  stepList.reduce((acc, stepName) => ({ ...acc, [stepName]: retrieveObject(stepName) }), {});
