import { boxShadow, textStyle } from '../../../themeConstants';

const KpiCard = {
  parts: [
    'container',
    'bulletsContainer',
    'bullets',
    'bullet',
    'content',
    'caption',
    'contentContainer',
    'selectedYear',
    'previousYear',
    'year',
    'value',
  ],
  baseStyle: {
    container: {
      border: 'solid 1px',
      borderColor: 'border.lightGrey',
      boxShadow: boxShadow.default,
    },
    bulletsContainer: {
      justifyContent: 'flex-end',
      paddingRight: '15px',
      paddingTop: '15px',
    },
    bullets: {
      spacing: '7px',
    },
    bullet: {
      width: '7px',
      height: '7px',
      borderRadius: '100%',
    },
    contentContainer: {
      paddingX: '30px',
      paddingBottom: '30px',
    },
    caption: {
      ...textStyle.sizeL,
      fontWeight: 'normal',
      paddingBottom: '5px',
      whiteSpace: 'nowrap',
    },
    content: {
      width: '100%',
      flexDirection: 'row',
    },
    selectedYear: {
      flexDirection: 'column',
      width: '50%',
      alignItems: 'flex-start',
      color: 'gray.800',
    },
    previousYear: {
      flexDirection: 'column',
      width: '50%',
      alignItems: 'flex-start',
      borderLeft: 'solid 1px',
      borderColor: 'border.darkGrey',
      paddingLeft: '10px',
      color: 'gray.600',
    },
    year: {
      ...textStyle.sizeXs,
    },
    value: {
      ...textStyle.sizeXl,
    },
  },
};

export default KpiCard;
