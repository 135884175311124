import { useState, useEffect } from 'react';

import jsonData from './countries.json';

interface ICountryType {
  de: string;
  en: string;
}

interface ICountryListType {
  value: string;
  label: string;
}

const useCountriesData = () => {
  const [countryArr, setCountryArr] = useState<ICountryListType[]>([]);
  useEffect(() => {
    const countries: ICountryListType[] = jsonData.map((c: ICountryType) => ({
      value: c.en,
      label: c.de,
    }));
    setCountryArr(countries);
    return () => {
      setCountryArr([]);
    };
  }, []);

  return countryArr;
};
export default useCountriesData;
