import _get from 'lodash/get';

import {
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';

const mapRealEstateMarketValueFromApi = (response: unknown) => {
  const currentUsage = _get(response, 'current_usage');
  const futureUsage = _get(response, 'future_usage');

  const marketValue = {
    [CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING]:
      _get(currentUsage, 'ree_market_value_per_sqm_building') || '0,00',
    [CURRENT_USAGE_REE_PARKING_LOT_VALUE]:
      _get(currentUsage, 'ree_market_value_parking_lot') || '0,00',
    [CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM]:
      _get(currentUsage, 'ree_commercial_rent_per_sqm') || '0,00',
    [CURRENT_USAGE_REE_PARKING_LOT_RENT]: _get(currentUsage, 'ree_rent_parking_lot') || '0,00',
    [CURRENT_USAGE_REE_GARAGE_VALUE]: _get(currentUsage, 'ree_market_value_garage') || '0,00',
    [CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT]:
      _get(currentUsage, 'ree_market_value_per_sqm_unit') || '0,00',
    [CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]:
      _get(currentUsage, 'ree_residential_rent_per_sqm') || '0,00',
    [CURRENT_USAGE_REE_GARAGE_RENT]: _get(currentUsage, 'ree_rent_garage') || '0,00',
    [FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING]:
      _get(futureUsage, 'ree_market_value_per_sqm_building') || '0,00',
    [FUTURE_USAGE_REE_PARKING_LOT_VALUE]:
      _get(futureUsage, 'ree_market_value_parking_lot') || '0,00',
    [FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM]:
      _get(futureUsage, 'ree_commercial_rent_per_sqm') || '0,00',
    [FUTURE_USAGE_REE_PARKING_LOT_RENT]: _get(futureUsage, 'ree_rent_parking_lot') || '0,00',
    [FUTURE_USAGE_REE_GARAGE_VALUE]: _get(futureUsage, 'ree_market_value_garage') || '0,00',
    [FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT]:
      _get(futureUsage, 'ree_market_value_per_sqm_unit') || '0,00',
    [FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]:
      _get(futureUsage, 'ree_residential_rent_per_sqm') || '0,00',
    [FUTURE_USAGE_REE_GARAGE_RENT]: _get(futureUsage, 'ree_rent_garage') || '0,00',
  };

  return marketValue;
};

export default mapRealEstateMarketValueFromApi;
