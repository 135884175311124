import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  END_USER_EMAIL,
  END_USER_FIRST_NAME,
  END_USER_LAST_NAME,
  END_USER_GENDER,
  END_USER_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';

export const validateVBSection = (form) => {
  return fieldsAreValid([
    END_USER_EMAIL,
    END_USER_FIRST_NAME,
    END_USER_LAST_NAME,
    END_USER_GENDER,
    END_USER_PHONE_NUMBER,
  ])({ form });
};
