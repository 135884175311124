import _get from 'lodash/get';

import {
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapFinancingParametersToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const financingParametersData = {
    building: {
      profitability_financing_fee: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_FINANCING_FEE_BUILDING)),
      ),
      profitability_interest_rate: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_INTEREST_RATE_BUILDING)),
      ),
      profitability_financing_duration: Number(
        unknownToString(_get(data, PROFITABILITY_FINANCING_DURATION_BUILDING)),
      ),
    },
    unit: {
      profitability_financing_fee: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_FINANCING_FEE_UNIT)),
      ),
      profitability_interest_rate: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_INTEREST_RATE_UNIT)),
      ),
      profitability_financing_duration: Number(
        unknownToString(_get(data, PROFITABILITY_FINANCING_DURATION_UNIT)),
      ),
    },
    rent: {
      profitability_financing_fee: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT)),
      ),
      profitability_interest_rate: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_INTEREST_RATE_RENT)),
      ),
      profitability_financing_duration: Number(
        unknownToString(_get(data, PROFITABILITY_FINANCING_DURATION_RENT)),
      ),
      profitability_repayment_rate: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_REPAYMENT_RATE_RENT)),
      ),
      profitability_interest_rate_rent_short: toFloatPrecision2(
        unknownToString(_get(data, PROFITABILITY_INTEREST_RATE_RENT_SHORT)),
      ),
    },
  };

  return financingParametersData;
};

export default mapFinancingParametersToApi;
