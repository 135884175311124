import React from 'react';

import { chakra, Table, useMultiStyleConfig } from '@chakra-ui/react';
import { useFlexLayout, useTable } from 'react-table';

import { FallbackComponent } from '../../../../../../pages/operationPortal/CompaniesDetails/FallbackComponent';
import { numberWithCommas } from '../../../../../../utils/valueFormats';
import { useDatePickerContext } from '../../../context/DatePickerContext';
import { useBalanceSheetData } from '../../../hooks/useBalanceSheetData';
import { Kpi } from '../../../hooks/useKpiFilter';
import { BalanceSheetRow } from '../BalanceSheetRow';

interface TableDataProps {
  aktivaData: {
    currentYear: Kpi[];
    previousYear: Kpi[];
  };
  passivaData: {
    currentYear: Kpi[];
    previousYear: Kpi[];
  };
}

const getTableData = ({ aktivaData, passivaData }: TableDataProps) => {
  const data: any[] = [];

  const longestDataArray =
    Object.keys(aktivaData.currentYear).length > Object.keys(aktivaData.previousYear).length
      ? 'currentYear'
      : 'previousYear';

  if (Object.keys(aktivaData).length !== 0 || Object.keys(passivaData).length !== 0) {
    aktivaData[longestDataArray].forEach((_, index) => {
      switch (index) {
        case passivaData[longestDataArray].length - 1: {
          data.push({
            col1: aktivaData[longestDataArray][index].description,
            col2:
              aktivaData.currentYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(aktivaData.currentYear[index].value / 1000))
                : 'N/A',
            col3:
              aktivaData.previousYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(aktivaData?.previousYear?.[index]?.value / 1000))
                : 'N/A',
            col4: '',
            col5: '',
            col6: '',
          });
          break;
        }
        case aktivaData[longestDataArray].length - 1: {
          data.push({
            col1: aktivaData[longestDataArray][index].description,
            col2:
              aktivaData.currentYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(aktivaData.currentYear[index].value / 1000))
                : 'N/A',
            col3:
              aktivaData.previousYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(aktivaData?.previousYear?.[index]?.value / 1000))
                : 'N/A',
            col4: passivaData?.[longestDataArray]?.[index - 1]?.description,
            col5:
              passivaData.currentYear?.[index - 1]?.value !== undefined
                ? numberWithCommas(Math.round(passivaData.currentYear[index - 1].value / 1000))
                : 'N/A',
            col6:
              passivaData.previousYear?.[index - 1]?.value !== undefined
                ? numberWithCommas(Math.round(passivaData?.previousYear?.[index - 1]?.value / 1000))
                : 'N/A',
          });
          break;
        }
        default: {
          data.push({
            col1: aktivaData[longestDataArray][index].description,
            col2: aktivaData.currentYear?.[index]?.value
              ? numberWithCommas(Math.round(aktivaData.currentYear[index].value / 1000))
              : 'N/A',
            col3:
              aktivaData.previousYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(aktivaData?.previousYear?.[index]?.value / 1000))
                : 'N/A',
            col4: passivaData?.[longestDataArray]?.[index]?.description,
            col5:
              passivaData.currentYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(passivaData.currentYear[index].value / 1000))
                : 'N/A',
            col6:
              passivaData.previousYear?.[index]?.value !== undefined
                ? numberWithCommas(Math.round(passivaData?.previousYear?.[index]?.value / 1000))
                : 'N/A',
          });
          break;
        }
      }
    });
  }

  return data;
};

const BalanceSheetTable = () => {
  const { date } = useDatePickerContext();
  const selectedYear = date.getFullYear().toString();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Aktiva',
        accessor: 'col1',
      },
      {
        Header: selectedYear,
        accessor: 'col2',
        width: 70,
      },
      {
        Header: (parseInt(selectedYear) - 1).toString(),
        accessor: 'col3',
        width: 70,
      },
      {
        Header: 'Passiva',
        accessor: 'col4',
      },
      {
        Header: selectedYear,
        accessor: 'col5',
        width: 70,
      },
      {
        Header: (parseInt(selectedYear) - 1).toString(),
        accessor: 'col6',
        width: 70,
      },
    ],
    [selectedYear],
  );

  const balanceSheetData = useBalanceSheetData();
  let data = [];

  if (balanceSheetData) {
    data = getTableData({
      aktivaData: {
        currentYear: balanceSheetData.aktivaCurrentYearData,
        previousYear: balanceSheetData.aktivaPreviousYearData,
      },
      passivaData: {
        currentYear: balanceSheetData.passivaCurrentYearData,
        previousYear: balanceSheetData.passivaPreviousYearData,
      },
    });
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<any>(
    { columns, data },
    useFlexLayout,
  );

  const styles = useMultiStyleConfig('FinancialBalanceSheet', {});
  return (
    <>
      {data.length === 0 ? (
        <FallbackComponent />
      ) : (
        <Table variant={'unstyled'} {...getTableProps()} sx={styles.table}>
          <chakra.thead sx={styles.thead}>
            {headerGroups.map((headerGroup) => (
              <chakra.tr sx={styles.tr} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <chakra.th
                    sx={{
                      ...styles.th,
                    }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </chakra.th>
                ))}
              </chakra.tr>
            ))}
          </chakra.thead>
          <chakra.tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return <BalanceSheetRow row={row} index={index} />;
            })}
          </chakra.tbody>
        </Table>
      )}
    </>
  );
};

export default BalanceSheetTable;
