import styled from 'styled-components/macro';

import Text from 'components/Text/Text';
import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';

const SectionHeading = styled(Text)`
  margin-bottom: 3rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_LIGHT};
  line-height: 1.25;
  color: ${(props) => props.theme.colors.text.tertiary};
`;

export const UppercaseSectionHeading = styled(SectionHeading)`
  text-transform: uppercase;
`;

export default SectionHeading;
