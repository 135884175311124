import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import loadEditProfitabilitySection from 'api/CompeonReverseApi/operation/loadEditProfitabilitySections/actions';
import loadMarketValueDetails from 'api/CompeonReverseApi/operation/loadMarketValueDetails/actions';
import loadMwMarketData from 'api/CompeonReverseApi/operation/loadMwMarketData/actions';
import loadPlanningEvaluationProfitabilityData from 'api/CompeonReverseApi/operation/loadPlanningEvaluationProfitabilityData/actions';
import EditPage from 'components/EditPage';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import UsageTypes from 'models/types/UsageTypes.type';
import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  PROJECT_FINANCING_FUTURE_USAGE_TYPE,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K,
} from 'modules/Inquiry/Form/formFields';
import {
  PROFITABILITY_RECORD_TYPE,
  PROFI_RECORD_TYPE,
  BANK_VALUES_RECORD_TYPE,
  FINANCING_CRITERIA_RECORD_TYPE,
  MW_MARKET_DATA_RECORD_TYPE,
  MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getInquiryDetailsSelector, getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import getProfitabilityFieldsType from './fieldsTypeMap';
import { useProjectCostingEditSubmit } from './useProjectCostingEditSubmit';

export type LocationState = {
  editFormStructure: Array<EditMarketValue>;
  recordType: string;
};

export const EditProfitabilityRealStateMarketData: React.FC = () => {
  const location = useLocation();
  const { editFormStructure, recordType } = location.state as LocationState;
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const history = useHistory();

  const { handleEditFormSubmit } = useProjectCostingEditSubmit();

  if (!editFormStructure.length) {
    switch (recordType) {
      case PROFI_RECORD_TYPE.VALUE_API:
        history.push(paths.operation.inquiryDetails.planningEvaluation.marketComparison(inquiryId));
        break;
      case PROFI_RECORD_TYPE.REE:
        history.push(paths.operation.inquiryDetails.planningEvaluation.appraiser(inquiryId));
        break;
      case BANK_VALUES_RECORD_TYPE.OBJECT_DATA:
      case BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION:
        history.push(paths.operation.inquiryDetails.planningEvaluation.bankValues(inquiryId));
        break;
      case FINANCING_CRITERIA_RECORD_TYPE:
        history.push(
          paths.operation.inquiryDetails.planningEvaluation.financingCriteria(inquiryId),
        );
        break;
      case PROFITABILITY_RECORD_TYPE.AREA_INFORMATION:
      case PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS:
      case PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS:
      case PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION:
      case PROFITABILITY_RECORD_TYPE.PRESALES_DATA:
      case PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS:
      case PROFITABILITY_RECORD_TYPE.SALES_COST_RATE:
      case PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT:
        history.push(
          paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculation(inquiryId),
        );
        break;
    }
  }

  const loadData = (recordType: string) => {
    switch (recordType) {
      case PROFI_RECORD_TYPE.VALUE_API:
      case PROFI_RECORD_TYPE.REE:
        return loadMarketValueDetails;
      case MW_MARKET_DATA_RECORD_TYPE:
        return loadMwMarketData;
      case BANK_VALUES_RECORD_TYPE.OBJECT_DATA:
      case BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT:
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS:
      case FINANCING_CRITERIA_RECORD_TYPE:
        return loadPlanningEvaluationProfitabilityData;
      case PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION:
      case PROFITABILITY_RECORD_TYPE.AREA_INFORMATION:
      case PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS:
      case PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS:
      case PROFITABILITY_RECORD_TYPE.PRESALES_DATA:
      case PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS:
      case PROFITABILITY_RECORD_TYPE.SALES_COST_RATE:
      case PROFITABILITY_RECORD_TYPE.SALE_AS_UNIT:
        return loadEditProfitabilitySection;
    }
  };

  const condition = (field: string): boolean =>
    recordType === PROFI_RECORD_TYPE.VALUE_API &&
    field === FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT &&
    inquiryDetails.financingPurposeFields[PROJECT_FINANCING_FUTURE_USAGE_TYPE] === UsageTypes.MIXED;

  return (
    <EditPage
      condition={condition}
      editFormStructure={editFormStructure}
      handleEditFormSubmit={handleEditFormSubmit}
      getFieldsType={getProfitabilityFieldsType}
      additionalNote="pages.planningEvaluation.marketComparison.additionalNote"
      additionalNoteValue={FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K}
      recordType={recordType}
      loadData={loadData(recordType)}
    />
  );
};
