import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mapInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapInquiryToApi';
import FastlaneService from 'pages/inquiryFlow/SummaryPage/Fastlane/fastlane.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getFormDataFromStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import { getFastLane, getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { isAgent as isAgentSelector } from 'store/user/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { ACTIONS } from './actions';

const useGetInquiryDataForFastlane = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const fastlaneState = useSelector(getFastLane);

  return useCallback(
    () => {
      const inquiryData = mapInquiryToApi(getFormDataFromStorage(partnerId));
      inquiryData.data['inquiry-id'] = inquiryId;
      inquiryData.data.attributes['file-upload-request-ids'] =
        fastlaneState.fastlaneInquiryFileRequests.map((req) => req.id) || [];
      return inquiryData;
    },
    // need to disabled to not reload on fastlane docs change
    [inquiryId, partnerId], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const useInquiryFastlineCheck = () => {
  const isFastlaneEnabled = useConfig(CONFIG.IS_FASTLANE_ENABLED);
  const fastlaneState = useSelector(getFastLane);
  const getInquiryData = useGetInquiryDataForFastlane();
  const { makeCall, isPending: isLoading } = useDispatchApiCall({ isPendingInitially: true });
  const dispatch = useDispatch();

  const checkIsInquiryForFastlane = useCallback(async () => {
    const inquiryData = getInquiryData();

    const { error, payload } = await makeCall(
      FastlaneService.fastlaneCheckApiAction({ ...inquiryData }),
    );
    if (error) {
      dispatch({ type: ACTIONS.SET_ERROR_CHECKING_FASTLANE, data: true });
    } else {
      const { fileUploadRequests, fastlaneMode, fastlaneErrors } =
        FastlaneService.mapFromApiInterface(payload);
      dispatch({ type: ACTIONS.SET_FASTLANE_INQUIRY_FILE_REQUESTS, data: fileUploadRequests });
      dispatch({ type: ACTIONS.SET_FASTLANE_ERRORS, data: fastlaneErrors });
      dispatch({ type: ACTIONS.SET_IS_QUALIFIED_FOR_FASTLANE, data: fastlaneMode });
    }
  }, [dispatch, getInquiryData, makeCall]);

  useEffect(() => {
    if (isFastlaneEnabled) {
      checkIsInquiryForFastlane();
    }
  }, [checkIsInquiryForFastlane, isFastlaneEnabled]);

  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const { selectedInquiryType } = useFormConfig();
  const isDocumentsUploadMandatoryEnabled =
    partnerId === PARTNERS.DVAG || selectedInquiryType === InquiryType.dvag;
  const isAgent = useSelector(isAgentSelector);

  useEffect(() => {
    const isInquiryQualifiedToFastlane = !isLoading && fastlaneState.isQualifiedForFastlane;

    dispatch({
      type: ACTIONS.SET_ARE_DOCUMENTS_MANDATORY,
      data: !isAgent && (isDocumentsUploadMandatoryEnabled || isInquiryQualifiedToFastlane),
    });
  }, [
    dispatch,
    fastlaneState.isQualifiedForFastlane,
    isAgent,
    isDocumentsUploadMandatoryEnabled,
    isLoading,
  ]);

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_FASTLANE_LOADING, data: isLoading });
  }, [dispatch, isLoading]);

  useEffect(() => {}, []);
};
export default useInquiryFastlineCheck;
