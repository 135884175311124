import {
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__VEHICLE,
  REAL_ESTATE_KIND__BUSINESS,
  REAL_ESTATE_KIND__FAMILY_HOUSE,
  REAL_ESTATE_KIND__HOTEL,
  REAL_ESTATE_KIND__INDUSTRIAL,
  REAL_ESTATE_KIND__LAND_ONLY,
  REAL_ESTATE_KIND__OFFICE,
  REAL_ESTATE_KIND__OTHER,
  REAL_ESTATE_KIND__PROPERTY_DEVELOPMENT,
  REAL_ESTATE_KIND__RESIDENCE,
  REAL_ESTATE_KIND__SHOPPING_CENTER,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_TAXATION__GROSS,
  REAL_ESTATE_TAXATION__NET,
  VEHICLE_KIND__CAR,
  VEHICLE_KIND__MOTORCYCLE,
  VEHICLE_KIND__OTHER,
  VEHICLE_KIND__TRAILER,
  VEHICLE_KIND__UTILITY,
  PURPOSE_KIND,
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_KIND,
  VEHICLE_KIND,
  MACHINE_CATEGORY,
  REAL_ESTATE_USAGE_KIND,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__PROJECT_FINANCING,
  PROJECT_FINANCING_ROLE,
  ROLES__FILE_HALTER,
  ROLES__PROPERTY_DEVELOPER,
  ROLES__PARTITION,
  ROLES__PROPERTY_BUYER,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL,
  PROJECT_FINANCING_TYPE_OF_USAGE__NURSING,
  PROJECT_FINANCING_TYPE_OF_USAGE__PARKING,
  PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  BUILDING_PROJECT,
  BUILDING_PROJECT__NEW,
  BUILDING_PROJECT__PURCHASE,
  BUILDING_PROJECT__RESCHEDULE,
  BUILDING_PROJECT__RECONSTRUCTION,
  BUILDING_TYPE,
  BUILDING_TYPE__DETACHED,
  BUILDING_TYPE__DUPLEX,
  BUILDING_TYPE__CONDOMINIUM,
  BUILDING_TYPE__APARTMENT,
  BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  BUILDING_TYPE__OFFICE,
  BUILDING_TYPE__PRODUCTION_HALL,
  BUILDING_TYPE__STORAGE_HALL,
  BUILDING_TYPE__DEVELOPER_MEASURE,
  BUILDING_TYPE__COMMERCIAL,
  BUILDING_TYPE__RETAIL_PARK,
  BUILDING_TYPE__HOTEL,
  BUILDING_TYPE__OTHER,
  BUILDING_TYPE_OF_USE,
  BUILDING_TYPE_OF_USE__OWN_USE_LETTING_THIRD_PARTY,
  BUILDING_TYPE_OF_USE__PERSONAL_USE,
  BUILDING_TYPE_OF_USE__THIRD_PARTY_RENTAL,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__CREFO_FACTORING,
  CLIENT_ROLE,
  CLIENT_ROLE__CONTRACTOR,
  CLIENT_ROLE__COMPANY,
  NUMBER_OF_TECHNOLOGIES,
  NUMBER_OF_TECHNOLOGIES__ONE,
  NUMBER_OF_TECHNOLOGIES__MORE,
  MACHINE_CUSTOM_CATEGORY,
  MACHINE_CUSTOM_CATEGORY__FACILITY,
  MACHINE_CUSTOM_CATEGORY__RENEWABLE_ENERGY,
  MACHINE_CUSTOM_CATEGORY__HEAT_OR_COLD,
  MACHINE_CUSTOM_CATEGORY__STORAGE,
  MACHINE_CUSTOM_CATEGORY__ELECTROMOBILITY,
  MACHINE_CUSTOM_CATEGORY__OTHER_SYSTEMS,
  PURPOSE_KIND__KFINANZ,
  GUARANTY_PERFORMANCE_OF_CONTRACT,
  GUARANTY_RENTAL_DEPOSIT,
  GUARANTY_TYPE,
  GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
  GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
  GUARANTY_WARRANTY,
  GUARANTY_LITIGATION_BOND,
  GUARANTY_RENTAL_DESPOSIT,
  PURPOSE_KIND_BFS,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import { bfsServiceKindOptions } from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/BfsServicePurposeSection/bfsServicePurposeKindOptions';
import { getKindOfUsageOptions } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateUsageKind';
import {
  getIdFromObject,
  optionsToTranslationMap,
} from 'modules/Inquiry/inquiryFieldsTranslations/helpers';
import machineCategories from 'store/partner/config/machineCategories';

const realEstateKindOfUsage = optionsToTranslationMap(getKindOfUsageOptions(getIdFromObject));
const machineCategoryOptions = optionsToTranslationMap(
  machineCategories.map((cat) => ({ value: cat.value, label: cat.id })),
);

const getTranslationPath = (sectionName, fieldName, optionName) =>
  `pages.financingNeed.sections.${sectionName}.fields.${fieldName}.options.${optionName}`;

const typeOfUsageTranslations = {
  [PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY]: getTranslationPath(
    'objectInformation',
    'typeOfUsage',
    'housingIndustry',
  ),
  [PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL]: getTranslationPath(
    'objectInformation',
    'typeOfUsage',
    'commercial',
  ),
  [PROJECT_FINANCING_TYPE_OF_USAGE__NURSING]: getTranslationPath(
    'objectInformation',
    'typeOfUsage',
    'nursing',
  ),
  [PROJECT_FINANCING_TYPE_OF_USAGE__PARKING]: getTranslationPath(
    'objectInformation',
    'typeOfUsage',
    'parking',
  ),
  [PROJECT_FINANCING_TYPE_OF_USAGE__OTHER]: getTranslationPath(
    'objectInformation',
    'typeOfUsage',
    'other',
  ),
};

export const financingNeedValues = {
  [PURPOSE_KIND]: {
    [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: getTranslationPath(
      'purpose',
      'purposeKind',
      'overdraftLiquidity',
    ),
    [PURPOSE_KIND__GUARANTY]: getTranslationPath('purpose', 'purposeKind', 'guaranty'),
    [PURPOSE_KIND__DEBT_RESCHEDULING]: getTranslationPath(
      'purpose',
      'purposeKind',
      'debtRescheduling',
    ),
    [PURPOSE_KIND__VEHICLE]: getTranslationPath('purpose', 'purposeKind', 'vehicle'),
    [PURPOSE_KIND__GOODS]: getTranslationPath('purpose', 'purposeKind', 'goods'),
    [PURPOSE_KIND__REAL_ESTATE]: getTranslationPath('purpose', 'purposeKind', 'realEstate'),
    [PURPOSE_KIND__OTHER]: getTranslationPath('purpose', 'purposeKind', 'other'),
    [PURPOSE_KIND__MACHINE]: getTranslationPath('purpose', 'purposeKind', 'machine'),
    [PURPOSE_KIND__OFFICE_EQUIPMENT]: getTranslationPath(
      'purpose',
      'purposeKind',
      'officeEquipment',
    ),
    [PURPOSE_KIND__CORONA]: getTranslationPath('purpose', 'purposeKind', 'corona'),
    [PURPOSE_KIND__PROJECT_FINANCING]: getTranslationPath(
      'purpose',
      'purposeKind',
      'project_financing',
    ),
    [PURPOSE_KIND__BUILDING]: getTranslationPath('purpose', 'purposeKind', 'building'),
    [PURPOSE_KIND__ENERGY_EFFICIENCY]: getTranslationPath(
      'purpose',
      'purposeKind',
      'energy_efficiency',
    ),
    [PURPOSE_KIND__CREFO_FACTORING]: getTranslationPath(
      'purpose',
      'purposeKind',
      'crefo_factoring',
    ),
    [PURPOSE_KIND__KFINANZ]: getTranslationPath('purpose', 'purposeKind', 'kfinanz'),
    [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: getTranslationPath(
      'purpose',
      'purposeKind',
      'onlineFactoring',
    ),
  },
  [REAL_ESTATE_TAXATION]: {
    [REAL_ESTATE_TAXATION__GROSS]: getTranslationPath(
      'detailsOfBuilding',
      'realEstateTaxation',
      'brutto',
    ),
    [REAL_ESTATE_TAXATION__NET]: getTranslationPath(
      'detailsOfBuilding',
      'realEstateTaxation',
      'netto',
    ),
  },
  [REAL_ESTATE_PROJECT]: {
    [REAL_ESTATE_PROJECT__NEW_BUILDING]: getTranslationPath(
      'realEstate',
      'realEstateProject',
      'newBuilding',
    ),
    [REAL_ESTATE_PROJECT__PURCHASE]: getTranslationPath(
      'realEstate',
      'realEstateProject',
      'purchase',
    ),
    [REAL_ESTATE_PROJECT__FOLLOW_UP]: getTranslationPath(
      'realEstate',
      'realEstateProject',
      'followUp',
    ),
    [REAL_ESTATE_PROJECT__RECONSTRUCTION]: getTranslationPath(
      'realEstate',
      'realEstateProject',
      'reconstruction',
    ),
    [REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT]: getTranslationPath(
      'realEstate',
      'realEstateProject',
      'developmentProject',
    ),
  },
  [REAL_ESTATE_KIND]: {
    [REAL_ESTATE_KIND__LAND_ONLY]: getTranslationPath('newBuilding', 'realEstateKind', 'landOnly'),
    [REAL_ESTATE_KIND__OFFICE]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'officeBuilding',
    ),
    [REAL_ESTATE_KIND__INDUSTRIAL]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'warehouseOrProductionHall',
    ),
    [REAL_ESTATE_KIND__RESIDENCE]: getTranslationPath('newBuilding', 'realEstateKind', 'residence'),
    [REAL_ESTATE_KIND__FAMILY_HOUSE]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'singleOrMultiFamilyHouse',
    ),
    [REAL_ESTATE_KIND__PROPERTY_DEVELOPMENT]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'propertyDevelopment',
    ),
    [REAL_ESTATE_KIND__BUSINESS]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'businessBuilding',
    ),
    [REAL_ESTATE_KIND__SHOPPING_CENTER]: getTranslationPath(
      'newBuilding',
      'realEstateKind',
      'specialtyShoppingCenter',
    ),
    [REAL_ESTATE_KIND__HOTEL]: getTranslationPath('newBuilding', 'realEstateKind', 'hotel'),
    [REAL_ESTATE_KIND__OTHER]: getTranslationPath('newBuilding', 'realEstateKind', 'other'),
  },
  [VEHICLE_KIND]: {
    [VEHICLE_KIND__CAR]: getTranslationPath('commercialVehicle', 'vehicleKind', 'car'),
    [VEHICLE_KIND__UTILITY]: getTranslationPath(
      'commercialVehicle',
      'vehicleKind',
      'utilityVehicle',
    ),
    [VEHICLE_KIND__TRAILER]: getTranslationPath('commercialVehicle', 'vehicleKind', 'trailer'),
    [VEHICLE_KIND__MOTORCYCLE]: getTranslationPath(
      'commercialVehicle',
      'vehicleKind',
      'motorcycle',
    ),
    [VEHICLE_KIND__OTHER]: getTranslationPath('commercialVehicle', 'vehicleKind', 'other'),
  },
  [MACHINE_CATEGORY]: {
    ...machineCategoryOptions,
  },
  [REAL_ESTATE_USAGE_KIND]: {
    ...realEstateKindOfUsage,
  },
  [PROJECT_FINANCING_ROLE]: {
    [ROLES__FILE_HALTER]: getTranslationPath('role', 'role', 'fileHalter'),
    [ROLES__PROPERTY_DEVELOPER]: getTranslationPath('role', 'role', 'propertyDeveloper'),
    [ROLES__PARTITION]: getTranslationPath('role', 'role', 'allocationTable'),
    [ROLES__PROPERTY_BUYER]: getTranslationPath('role', 'role', 'propertyBuyer'),
  },
  [PROJECT_FINANCING_TYPE_OF_USAGE]: typeOfUsageTranslations,
  [PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE]: typeOfUsageTranslations,
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]: typeOfUsageTranslations,
  [BUILDING_PROJECT]: {
    [BUILDING_PROJECT__NEW]:
      'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.newBuilding',
    [BUILDING_PROJECT__PURCHASE]:
      'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.purchase',
    [BUILDING_PROJECT__RESCHEDULE]:
      'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.rescheduling',
    [BUILDING_PROJECT__RECONSTRUCTION]:
      'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.reconstruction',
  },
  [BUILDING_TYPE]: {
    [BUILDING_TYPE__DETACHED]:
      'pages.financingNeed.sections.building.fields.buildingType.options.detached',
    [BUILDING_TYPE__DUPLEX]:
      'pages.financingNeed.sections.building.fields.buildingType.options.duplex',
    [BUILDING_TYPE__CONDOMINIUM]:
      'pages.financingNeed.sections.building.fields.buildingType.options.condominium',
    [BUILDING_TYPE__APARTMENT]:
      'pages.financingNeed.sections.building.fields.buildingType.options.apartment',
    [BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL]:
      'pages.financingNeed.sections.building.fields.buildingType.options.residential_and_commercial',
    [BUILDING_TYPE__OFFICE]:
      'pages.financingNeed.sections.building.fields.buildingType.options.office',
    [BUILDING_TYPE__PRODUCTION_HALL]:
      'pages.financingNeed.sections.building.fields.buildingType.options.production_hall',
    [BUILDING_TYPE__STORAGE_HALL]:
      'pages.financingNeed.sections.building.fields.buildingType.options.storage_hall',
    [BUILDING_TYPE__DEVELOPER_MEASURE]:
      'pages.financingNeed.sections.building.fields.buildingType.options.developer_measure',
    [BUILDING_TYPE__COMMERCIAL]:
      'pages.financingNeed.sections.building.fields.buildingType.options.commercial',
    [BUILDING_TYPE__RETAIL_PARK]:
      'pages.financingNeed.sections.building.fields.buildingType.options.retail_park',
    [BUILDING_TYPE__HOTEL]:
      'pages.financingNeed.sections.building.fields.buildingType.options.hotel',
    [BUILDING_TYPE__OTHER]:
      'pages.financingNeed.sections.building.fields.buildingType.options.other',
  },
  [BUILDING_TYPE_OF_USE]: {
    [BUILDING_TYPE_OF_USE__OWN_USE_LETTING_THIRD_PARTY]:
      'pages.financingNeed.sections.building.fields.buildingTypeOfUse.options.ownUseAndThirdParty',
    [BUILDING_TYPE_OF_USE__PERSONAL_USE]:
      'pages.financingNeed.sections.building.fields.buildingTypeOfUse.options.personal',
    [BUILDING_TYPE_OF_USE__THIRD_PARTY_RENTAL]:
      'pages.financingNeed.sections.building.fields.buildingTypeOfUse.options.rental',
  },
  [CLIENT_ROLE]: {
    [CLIENT_ROLE__CONTRACTOR]:
      'inquiryType.energyEfficiency.steps.purpose.sections.clientRole.contractorShort',
    [CLIENT_ROLE__COMPANY]:
      'inquiryType.energyEfficiency.steps.purpose.sections.clientRole.companyShort',
  },
  [NUMBER_OF_TECHNOLOGIES]: {
    [NUMBER_OF_TECHNOLOGIES__ONE]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technology.fields.technologies.options.one',
    [NUMBER_OF_TECHNOLOGIES__MORE]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technology.fields.technologies.options.more',
  },
  [MACHINE_CUSTOM_CATEGORY]: {
    [MACHINE_CUSTOM_CATEGORY__FACILITY]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.facility',
    [MACHINE_CUSTOM_CATEGORY__RENEWABLE_ENERGY]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.renewableEnergy',
    [MACHINE_CUSTOM_CATEGORY__HEAT_OR_COLD]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.heatOrCold',
    [MACHINE_CUSTOM_CATEGORY__STORAGE]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.storage',
    [MACHINE_CUSTOM_CATEGORY__ELECTROMOBILITY]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.electromobility',
    [MACHINE_CUSTOM_CATEGORY__OTHER_SYSTEMS]:
      'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.options.other',
  },
  [GUARANTY_TYPE]: {
    [GUARANTY_RENTAL_DEPOSIT]: 'pages.financingNeed.sections.guaranty.fields.rentalDeposit.caption',
    [GUARANTY_RENTAL_DESPOSIT]:
      'pages.financingNeed.sections.guaranty.fields.rentalDeposit.caption',

    [GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS]:
      'pages.financingNeed.sections.guaranty.fields.partialRetirementAccounts.caption',
    [GUARANTY_PERFORMANCE_OF_CONTRACT]:
      'pages.financingNeed.sections.guaranty.fields.performanceOfContract.caption',
    [GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS]:
      'pages.financingNeed.sections.guaranty.fields.depositAndAdvancePayments.caption',
    [GUARANTY_WARRANTY]: 'pages.financingNeed.sections.guaranty.fields.warranty.caption',
    [GUARANTY_LITIGATION_BOND]:
      'pages.financingNeed.sections.guaranty.fields.litigationBond.caption',
  },
  [PURPOSE_KIND_BFS]: bfsServiceKindOptions.reduce((prev, curr) => {
    prev[curr.value] = curr.id;
    return prev;
  }, {}),
};
