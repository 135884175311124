import React, { ReactNode } from 'react';

import DownloadFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/DownloadFileInRequest';
import PreviewFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/PreviewFileInRequest';
import RemoveFileInRequestButton from 'components/UploadBlock/NewFileRequestBlock/components/RemoveFileInRequestButton';
import {
  UnuploadedIcon as FileIcon,
  StyledIconWrapper as IconWrapper,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import {
  StyledUploadedFileInfo as FileInfo,
  StyledFileContent as Content,
  StyledTitleWrapper as TitleWrapper,
  StyledFileTitle as Title,
  StyledRequestActionList as ActionList,
  StyledRequestActionListItem as ActionListItem,
  StyledFileBlockWrapper as Wrapper,
} from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import { IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { formatDateWithTime } from 'utils/date';

import DownloadCompeonFile from '../components/DownloadCompeonFile';

interface IFileBlock {
  file: IFile | CompeonDocument;
  canRemove?: boolean;
  label?: ReactNode;
  onRemove?: Function;
  isCompeonFile?: boolean;
  isReeEnabled?: boolean;
  isReePage?: boolean;
}

const UploadedFileBlock = ({
  file,
  canRemove,
  label,
  onRemove,
  isCompeonFile,
  isReeEnabled,
  isReePage,
}: IFileBlock) => {
  const isAllowedToDownload = !isReePage || (isReePage && isReeEnabled);
  const isAllowedToDelete = canRemove && isAllowedToDownload;
  return (
    <Wrapper data-testid="file-block">
      <IconWrapper>
        <FileIcon />
      </IconWrapper>

      <Content>
        <TitleWrapper>
          <Title>{file.fileName}</Title>
          {label}
        </TitleWrapper>
        <FileInfo>{formatDateWithTime(file.createdAt)}</FileInfo>
      </Content>

      <ActionList>
        {isCompeonFile ? (
          <ActionListItem>
            <DownloadCompeonFile file={file} />
          </ActionListItem>
        ) : (
          <>
            <ActionListItem>
              <PreviewFileInRequest file={file} />
            </ActionListItem>
            {isAllowedToDownload ? (
              <ActionListItem>
                <DownloadFileInRequest file={file} />
              </ActionListItem>
            ) : null}
          </>
        )}
        {isAllowedToDelete ? (
          <ActionListItem>
            <RemoveFileInRequestButton onRemove={onRemove} file={file} />
          </ActionListItem>
        ) : null}
      </ActionList>
    </Wrapper>
  );
};

export default UploadedFileBlock;
