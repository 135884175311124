import React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import { useFormConfig } from 'config/formConfig/hooks';
import { USER_REPRESENTATION } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import LeasePlanUserRepresentation from './LeasePlanUserRepresentation';

const UserRepresentation = () => {
  const { required, mustBeTrue } = useFieldValidators();
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.userRepresentation',
  );

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  return (
    <CheckboxWithField
      name={USER_REPRESENTATION}
      validate={combineValidators(required, mustBeTrue)}
      text={t('label')}
      tooltip={isBfsService ? t('tooltip') : ''}
    />
  );
};

const InquiryTypeSpecificUserRepresentation = chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: LeasePlanUserRepresentation,
  default: UserRepresentation,
});

export default InquiryTypeSpecificUserRepresentation;
