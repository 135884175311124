import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import Condition from 'modules/ConditionalSubform/Condition';
import { canShowSection } from 'modules/Inquiry/Form/formConditions';
import { BFS_SERVICE__FACTORING_OFFER, BFS_SERVICE__SOURCE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const BfsServiceNotesSection = () => {
  const factoringOfferOptions = useInquirySelectOptions(BFS_SERVICE__FACTORING_OFFER);

  const t = useTranslations();
  const tFields = useTranslations('pages.bfsService.sections.notes');

  const { required } = useFieldValidators();

  return (
    <FormSection title={tFields('title')} sectionNumber={4}>
      <FormRow>
        <SelectWithField
          name={BFS_SERVICE__FACTORING_OFFER}
          placeholder={t('placeholders.pleaseChoose')}
          options={factoringOfferOptions}
          tooltip={''}
          validate={required}
        />
      </FormRow>
      <Condition condition={canShowSection(BFS_SERVICE__SOURCE)} isBusinessCondition>
        <FormRow>
          <InputWithField
            name={BFS_SERVICE__SOURCE}
            placeholder={tFields('fields.bfsServiceSource.placeholder')}
            captionTooltip={''}
            validate={required}
          />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BfsServiceNotesSection,
  default: () => null,
});
