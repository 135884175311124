import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import SpinnerWrapper from '../../../../../components/App/PartnerProvider/SpinnerWrapper';
import Spinner from '../../../../../components/Spinner';
import { getMarketDataFilter } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import ProjectCostingHeader from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import UsageTypeRadio from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import { formatDateWithTime } from '../../../../../utils/date';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { formatPrice } from '../../../../../utils/valueFormats';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { getBankValuesTopBoxes, getUpdatedDate } from '../../store/selectors';
import FurtherObjectDetails from './FurtherObjectDetails';
import ObjectEvaluation from './ObjectEvaluation';

interface BankValuesTopBoxes {
  commercial_space: number;
  residential_space: number;
  bank_rental_income: string;
  bank_total_sales_revenue: string;
}

export interface BankValuesTopBoxValues {
  current: BankValuesTopBoxes;
  future: BankValuesTopBoxes;
}

const BankValues = () => {
  const filterData = useSelector(getMarketDataFilter);

  const t = useTranslations('pages.planningEvaluation.mittweida.pages.bankValues');
  const values: BankValuesTopBoxValues = useSelector(getBankValuesTopBoxes);
  const topBoxValues = values?.[filterData?.usageType];
  const lastUpdatedAt = useSelector(getUpdatedDate);

  if (values === undefined) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Box>
      <ProjectCostingHeader
        heading={t('title')}
        subheading={lastUpdatedAt ? `${t('updatedAt')} ${formatDateWithTime(lastUpdatedAt)}` : ''}
      />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
      </Flex>
      <SummaryCards>
        <SummaryCard
          caption={t('summaryCards.sustainableSalesRevenue')}
          value={formatPrice(topBoxValues?.bank_total_sales_revenue, true)}
        />
        <SummaryCard
          caption={t('summaryCards.sustainableYearlyRent')}
          value={formatPrice(topBoxValues?.bank_rental_income, true)}
        />
        <SummaryCard
          caption={t('summaryCards.livingSpace')}
          value={topBoxValues?.residential_space ? `${topBoxValues?.residential_space} m²` : '0 m²'}
        />
        <SummaryCard
          caption={t('summaryCards.commercialSpace')}
          value={topBoxValues?.commercial_space ? `${topBoxValues?.commercial_space} m²` : '0 m²'}
        />
      </SummaryCards>
      <ObjectEvaluation />
      <FurtherObjectDetails />
    </Box>
  );
};

export default BankValues;
