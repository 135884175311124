import { COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY } from '../../../../../../modules/Inquiry/Form/formFields';
import { CompanySelection } from '../../../../fields';

export function shouldShowCompanySuggestion(
  isLoggedIn: boolean,
  { loggedInUserCompanyName }: CompanySelection,
) {
  if (!isLoggedIn) {
    return true;
  }

  return loggedInUserCompanyName === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY;
}
