import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { borderRadius } from 'theme/themeConstants';

export const StyledSmallTileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSmallTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 8rem;
  border-radius: ${borderRadius.default};
  padding: 0 1.75rem;
  margin-right: 1.5rem;
  background-color: ${({ theme }) => theme.colors.primary};

  svg {
    display: block;
    width: 100%;
    fill: ${({ theme }) => theme.colors.background.darkGrey};

    [fill] {
      fill: ${({ theme }) => theme.colors.background.darkGrey};
      stroke: none;
    }

    [stroke] {
      fill: none;
      stroke: ${({ theme }) => theme.colors.border.white};
    }
  }
`;

export const StyledCenteredField = styled.div`
  display: flex;
  align-items: center;
`;

const FONT_SIZE = '1.5rem';
export const FinancedAmount = styled.div`
  font-weight: ${FONT_WEIGHT_MEDIUM};

  .caption {
    display: block;
    font-size: ${FONT_SIZE};
    font-weight: ${FONT_WEIGHT_MEDIUM};
    color: ${(props) => props.theme.colors.text.tertiary};
  }

  .value {
    font-size: ${FONT_SIZE};
    color: ${(props) => props.theme.colors.text.tertiary};
  }
`;

export const ArithmeticSign = styled.div`
  font-weight: ${FONT_WEIGHT_MEDIUM};
  font-size: 2rem;
  color: ${(props) => props.theme.colors.text.tertiary};

  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
`;
