import React, { createContext, Dispatch, useEffect, useReducer } from 'react';

import { useHistory } from 'react-router-dom';

import { mittweidaInquiryProcessConfig } from 'mittweida/inquiry/state/data';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import { ValidationRecord } from '../../common/types';
import { InitialFormStateType } from '../../model';
import { PageStateActionTypes, pageStateReducer } from '../pageStateReducer';
import {
  AnyInquiryProcessConfig,
  AnyInitialStateType,
  AnyPageStateActions,
  InquiryMode,
} from '../types';

export const InquiryConfigContext =
  createContext<
    | {
        state: AnyInitialStateType;
        dispatch: Dispatch<AnyPageStateActions>;
        formStateData: AnyInquiryProcessConfig;
      }
    | undefined
  >(undefined);

type InquiryProcessConfigProps = {
  children: React.ReactNode;
};

export function InquiryProcessConfigProvider({ children }: InquiryProcessConfigProps) {
  const inquiryProcessConfig: AnyInquiryProcessConfig = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: mittweidaInquiryProcessConfig,
  });

  const { validations, routeToPageNameMap, initialFormState } = inquiryProcessConfig;
  const initialStateWithSections = useInitialState(
    validations[initialFormState.inquiryMode],
    initialFormState,
  );
  const [state, dispatch] = useReducer(pageStateReducer, initialStateWithSections);

  const { location } = useHistory();

  useEffect(() => {
    const path = location.pathname;

    let mode: InquiryMode = 'create';

    if (path.includes('operation') && path.includes('edit')) {
      mode = 'editOperation';
    } else if (path.includes('customer') && path.includes('edit')) {
      mode = 'editCustomer';
    }

    dispatch({
      type: PageStateActionTypes.SET_INQUIRY_MODE,
      payload: mode,
    });

    const currentStep = Object.keys(routeToPageNameMap).find((key) => {
      const route = routeToPageNameMap[key];
      if (mode === 'editCustomer' || mode === 'editOperation') {
        const compare = path.split('/').pop();
        if (compare) {
          return (
            route.editCustomer.indexOf(compare) > -1 || route.editOperation.indexOf(compare) > -1
          );
        }
      }

      return route.create === path;
    });

    if (currentStep) {
      dispatch({
        type: PageStateActionTypes.NEXT_STEP,
        payload: currentStep,
      });
    }
  }, [location, dispatch, routeToPageNameMap]);

  useEffect(() => {
    window.sessionStorage.setItem('newInquiryProgress', JSON.stringify(state));
  }, [state]);

  return (
    <InquiryConfigContext.Provider value={{ state, dispatch, formStateData: inquiryProcessConfig }}>
      {children}
    </InquiryConfigContext.Provider>
  );
}

function useInitialState<T>(
  validations: ValidationRecord<T>,
  initialState: InitialFormStateType<T>,
) {
  const stateFromStorage = window.sessionStorage.getItem('newInquiryProgress');
  const currentPageValidations = initialState.pageValidations;

  if (stateFromStorage) {
    return JSON.parse(stateFromStorage);
  }

  const initialNumberOfSections = Object.keys(validations).reduce((acc, key) => {
    const numberOfSections = Object.keys((validations as any)[key]).length;
    const currentValidations = (currentPageValidations as any)[key].validations;

    return {
      ...acc,
      [key]: {
        progress: {
          ...(currentPageValidations as any)[key].progress,
          numberOfSections,
        },
        validations: currentValidations,
      },
    };
  }, {});

  return {
    inquiryMode: initialState.inquiryMode,
    currentStep: initialState.currentStep,
    pageValidations: initialNumberOfSections,
  };
}
