import { all, fieldsAreValid, getFieldValidatorIfExist } from 'modules/Inquiry/Form/formConditions';
import {
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_TERM,
  LOAN_TYPE,
} from 'modules/Inquiry/Form/formFields';
import { loanConditions } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanSection';

export const validateLoanSection = (form) => {
  const { hasSelectedAmmortisable, hasSelectedFixedRate } = loanConditions;

  const alwaysAvailableFields = fieldsAreValid([LOAN_TYPE, LOAN_TERM]);

  const subsidies = getFieldValidatorIfExist(LOAN_CONSIDER_SUBSIDIES, hasSelectedAmmortisable);
  const hasFixedInterestRate = getFieldValidatorIfExist(
    LOAN_FIXED_INTEREST_RATE,
    hasSelectedAmmortisable,
  );
  const interestPeriodInMonths = getFieldValidatorIfExist(
    LOAN_INTEREST_PERIOD_IN_MONTHS,
    all(hasSelectedAmmortisable, hasSelectedFixedRate),
  );

  return all(
    alwaysAvailableFields,
    subsidies,
    hasFixedInterestRate,
    interestPeriodInMonths,
  )({
    form,
  });
};
