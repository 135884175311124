import env from '@beam-australia/react-env';

const environmentTypes = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const isDevelopment = () => env('ENVIRONMENT') === environmentTypes.DEVELOPMENT;

export const isProduction = () => env('ENVIRONMENT') === environmentTypes.PRODUCTION;

// If any env is added, please update this list for easier development
export enum EnvironmentVariable {
  COMPANIES_LIST = 'COMPANIES_LIST',
  PORTAL_INQUIRY = 'PORTAL_INQUIRY',
  WS_URL = 'WS_URL',
}

export const getEnvironmentFeatureFlag = (flagName: EnvironmentVariable) =>
  env(flagName.toString());
