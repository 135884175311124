import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PortalPage from 'components/PortalPage';
import ElementsPerPage from 'components/PortalTable/ElementsPerPage/ElementsPerPage';
import Pagination from 'components/PortalTable/Pagination/Pagination';
import PortalTable from 'components/PortalTable/PortalTable';
import paths from 'constants/paths';
import { IInquiryListElement } from 'models/InquiryList.model';
import ErrorBoundary from 'modules/ErrorBoundary';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import { mapInquiriesToTableData } from 'modules/InquiryTable/mapFromApi';
import { useInquiryTable } from 'modules/InquiryTable/useInquiryTable';
import InquiryStateFilter from 'pages/operationPortal/OperationInquiryList/InquiryStateFilter/InquiryStateFilter';
import { useOperationInquiryTableHeaders } from 'pages/operationPortal/OperationInquiryList/useOperationInquiryTableHeaders';
import { AppDispatch } from 'store';
import { getInquiryListSelector } from 'store/inquiryList/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RESET_MARKETDATA_FILTER } from '../PlanningEvaluation/SharedComponent/filterManagementReducer';
import useLoggedInInquiryManagerFilter from './useLoggedInInquiryManagerFilter';

/*
 * Note: Inquiries data is fetched under SelectState
 */
const OperationInquiryList = () => {
  const t = useTranslations();
  const history = useHistory();
  useLoggedInInquiryManagerFilter()();
  const dispatch: AppDispatch = useDispatch();

  const handleRowClick = (row: IInquiryListElement) => {
    dispatch({ type: RESET_MARKETDATA_FILTER });
    history.push(paths.operation.inquiryDetails.root.replace(':id', row.id));
  };

  const inquiries = useSelector(getInquiryListSelector);
  const tableData = mapInquiriesToTableData(inquiries).map((inquiry: IInquiryListElement) => ({
    ...inquiry,
    status: inquiry.substatus,
  }));

  const tableHeaders = useOperationInquiryTableHeaders();
  const {
    onPaginationChange,
    onPageSizeChange,
    pageSize,
    currentPage,
    totalPages,
    isLoading,
    isInitialized,
    onSortChange,
    sortDirection,
    sortBy,
  } = useInquiryTable();

  return (
    <InquirySettingsProvider>
      <PortalPage pageTitle={t('pages.inquiryList.pageTitle')}>
        <InquiryStateFilter />
        <ErrorBoundary>
          <PortalTable
            headers={tableHeaders}
            tableData={tableData}
            sortBy={sortBy}
            sortDirection={sortDirection}
            isInitialized={isInitialized}
            isLoading={isLoading}
            onSortChange={onSortChange}
            onRowClick={handleRowClick}
          >
            <ElementsPerPage onPageSizeChange={onPageSizeChange} pageSize={pageSize} />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationChange={onPaginationChange}
            />
          </PortalTable>
        </ErrorBoundary>
      </PortalPage>
    </InquirySettingsProvider>
  );
};

export default OperationInquiryList;
