import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { INPUT_TYPE_NUMBER } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  MACHINE_CUSTOM_CATEGORY__ELECTROMOBILITY,
  MACHINE_CUSTOM_CATEGORY__FACILITY,
  MACHINE_CUSTOM_CATEGORY__HEAT_OR_COLD,
  MACHINE_CUSTOM_CATEGORY__OTHER_SYSTEMS,
  MACHINE_CUSTOM_CATEGORY__RENEWABLE_ENERGY,
  MACHINE_CUSTOM_CATEGORY__STORAGE,
  MACHINE_CATEGORY,
  MACHINE_CUSTOM_CATEGORY,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_MANUFACTURER,
  MACHINE_KIND,
  MACHINE_NEW,
  MACHINE_AGE,
  MACHINE_IS_ORDERED,
  MACHINE_DELIVERY_DATE,
  MACHINE_ORIGINAL_PRICE,
} from 'modules/Inquiry/Form/formFields';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AmountWithField } from '../../../Amount/Amount';

export const validateOneTechnologySection = validateSectionFields([
  MACHINE_CUSTOM_CATEGORY,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_MANUFACTURER,
  MACHINE_KIND,
  MACHINE_NEW,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_IS_ORDERED,
  MACHINE_DELIVERY_DATE,
]);

export const OneTechnologySection = () => {
  const t = useTranslations(
    'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails',
  );
  const { change } = useForm();
  const fieldController = FormFieldController.instance;

  useEffect(() => {
    // This is hidden input value
    // We are sending the data under the machine purpose to compeon, we need a "machine-category" from their API. This will be preselected as "renewable_energy" (energy technology for renewable energy)
    change(MACHINE_CATEGORY, 'renewable_energy');
    fieldController.saveValue(MACHINE_CATEGORY, 'renewable_energy');
  }, [change, fieldController]);

  const options = [
    {
      label: t('fields.categories.options.facility'),
      value: MACHINE_CUSTOM_CATEGORY__FACILITY,
    },
    {
      label: t('fields.categories.options.renewableEnergy'),
      value: MACHINE_CUSTOM_CATEGORY__RENEWABLE_ENERGY,
    },
    {
      label: t('fields.categories.options.heatOrCold'),
      value: MACHINE_CUSTOM_CATEGORY__HEAT_OR_COLD,
    },
    {
      label: t('fields.categories.options.storage'),
      value: MACHINE_CUSTOM_CATEGORY__STORAGE,
    },
    {
      label: t('fields.categories.options.electromobility'),
      value: MACHINE_CUSTOM_CATEGORY__ELECTROMOBILITY,
    },
    {
      label: t('fields.categories.options.other'),
      value: MACHINE_CUSTOM_CATEGORY__OTHER_SYSTEMS,
    },
  ];

  return (
    <FormSection title={t('title')} sectionNumber={3}>
      <FormRow>
        <SelectWithField
          name={MACHINE_CUSTOM_CATEGORY}
          options={options}
          tooltip={t('categories.caption')}
          data-testid={'select-field-' + MACHINE_CUSTOM_CATEGORY}
        />
      </FormRow>
      <FormRow>
        <InputWithField name={MACHINE_PRICE} type={INPUT_TYPE_NUMBER} />
        <InputWithField name={MACHINE_PERIOD_OF_USE} type={INPUT_TYPE_NUMBER} />
      </FormRow>
      <FormRow separator>
        <InputWithField name={MACHINE_MANUFACTURER} />
        <InputWithField name={MACHINE_KIND} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={MACHINE_NEW} />
      </FormRow>
      <Condition isBusinessCondition condition={fieldBusinessConditions.machineIsNotNew}>
        <FormRow>
          <InputWithField name={MACHINE_AGE} type={INPUT_TYPE_NUMBER} />
          <AmountWithField name={MACHINE_ORIGINAL_PRICE} />
        </FormRow>
      </Condition>
      <FormRow>
        <YesNoRadioGroupWithField name={MACHINE_IS_ORDERED} />
      </FormRow>
      <Condition isBusinessCondition condition={fieldBusinessConditions.machineIsOrdered}>
        <FormRow>
          <DayPickerWithField name={MACHINE_DELIVERY_DATE} />
        </FormRow>
      </Condition>
    </FormSection>
  );
};
