import React from 'react';

import Condition from 'components/Condition';
import { usePartnerConfig } from 'config/partner/hooks';
import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  SELF_BENEFICIARY_OWNER,
} from 'modules/Inquiry/Form/formFields';
import SummaryField from 'modules/SummaryTable/SummaryField';
import { IBeneficiaryOwnersTypes } from 'pages/operationPortal/InquiryDetails/Dashboard/LegalRepresentativeCorrection/types';
import { useSalutationTranslator } from 'shared/hooks/useSalutationTranslator';
import { getFormDataFromStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import useCountriesData from 'utils/hooks/useCountriesData/useCountriesData';

import { StyledSummaryRows } from './NewSummaryPage/styles';

interface IMappedBeneficiaryOwner {
  salutation: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  company_share: string;
  private_address: string;
  zip_code: string;
  private_city: string;
  private_country: string;
  birth_country: string;
  tax_id: string;
}

const useMapBeneficiaries = () => {
  const countries = useCountriesData();

  const countryFormatter = (value: string) =>
    countries.find((country) => country.value === value)?.label || value;

  const mapBeneficiaries = (people: IBeneficiaryOwnersTypes) => ({
    salutation: people[BENEFICIARY_GENDER],
    first_name: people[BENEFICIARY_FIRST_NAME],
    last_name: people[BENEFICIARY_LAST_NAME],
    birth_date: people[BENEFICIARY_BIRTHDAY],
    company_share: people[BENEFICIARY_COMPANY_SHARE],
    private_address: people[BENEFICIARY_PRIVATE_ADDRESS],
    zip_code: people[BENEFICIARY_ZIP_CODE],
    private_city: people[BENEFICIARY_PRIVATE_CITY],
    private_country: countryFormatter(people[BENEFICIARY_PRIVATE_COUNTRY]),
    birth_country: countryFormatter(people[BENEFICIARY_BIRTH_COUNTRY]),
    tax_id: people[BENEFICIARY_TAX_ID],
  });

  return mapBeneficiaries;
};

export const useBeneficiariesForSummaryStep = () => {
  const {
    details: { id: partnerShortName },
  } = usePartnerConfig();
  const formValues = getFormDataFromStorage(partnerShortName);
  const mapBeneficiaries = useMapBeneficiaries();

  let beneficiaryOwners: IMappedBeneficiaryOwner[] = [];
  const hasBeneficiaryOwners = formValues[HAS_BENEFICIARY_OWNER];

  if (hasBeneficiaryOwners === 'true') {
    if (formValues[IS_BENEFICIARY_OWNER] === 'true') {
      beneficiaryOwners = formValues[SELF_BENEFICIARY_OWNER].map(
        (beneficiary: IBeneficiaryOwnersTypes) => ({
          ...beneficiary,
          [BENEFICIARY_GENDER]: formValues[COMPANY_DETAILS_GENDER],
          [BENEFICIARY_FIRST_NAME]: formValues[COMPANY_DETAILS_FIRST_NAME],
          [BENEFICIARY_LAST_NAME]: formValues[COMPANY_DETAILS_LAST_NAME],
        }),
      ).map(mapBeneficiaries);
    }

    if (formValues[BENEFICIARY_OWNER]) {
      beneficiaryOwners = [
        ...beneficiaryOwners,
        ...formValues[BENEFICIARY_OWNER].map(mapBeneficiaries),
      ];
    }
  }

  return {
    beneficiaryOwners,
    hasBeneficiaries: !!beneficiaryOwners.length,
  };
};

const BeneficiaryOwnerSummaryStep = () => {
  const {
    details: { id: partnerShortName },
  } = usePartnerConfig();
  const formValues = getFormDataFromStorage(partnerShortName);

  const translateSalutation = useSalutationTranslator();

  const { beneficiaryOwners, hasBeneficiaries } = useBeneficiariesForSummaryStep();

  return (
    <Condition condition={hasBeneficiaries} fallback={null}>
      <StyledSummaryRows>
        <SummaryField
          fieldName={IS_BENEFICIARY_OWNER}
          fieldValue={formValues[IS_BENEFICIARY_OWNER]}
        />
      </StyledSummaryRows>
      {beneficiaryOwners.map((person: any) => (
        <StyledSummaryRows>
          {[
            {
              name: BENEFICIARY_GENDER,
              value: translateSalutation(person.salutation),
            },
            {
              name: BENEFICIARY_FIRST_NAME,
              value: person.first_name,
            },
            {
              name: BENEFICIARY_LAST_NAME,
              value: person.last_name,
            },
            {
              name: BENEFICIARY_COMPANY_SHARE,
              value: person.company_share,
            },
            {
              name: BENEFICIARY_TAX_ID,
              value: person.tax_id,
            },
            {
              name: BENEFICIARY_PRIVATE_ADDRESS,
              value: person.private_address,
            },
            {
              name: BENEFICIARY_ZIP_CODE,
              value: person.zip_code,
            },
            {
              name: BENEFICIARY_PRIVATE_CITY,
              value: person.private_city,
            },
            {
              name: BENEFICIARY_PRIVATE_COUNTRY,
              value: person.private_country,
            },
            {
              name: BENEFICIARY_BIRTH_COUNTRY,
              value: person.birth_country,
            },
            {
              name: BENEFICIARY_BIRTHDAY,
              value: person.birth_date,
            },
          ].map((field) => (
            <SummaryField fieldName={field.name} fieldValue={field.value} />
          ))}
        </StyledSummaryRows>
      ))}
    </Condition>
  );
};

export default BeneficiaryOwnerSummaryStep;
