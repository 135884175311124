import * as React from 'react';
import { useState } from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import SectionHeading from 'components/Text/SectionHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import { BANK_DETAILS_IBAN } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { FormFieldController } from 'store/FormFieldController/FieldController';
import { useTranslations } from 'utils/hooks/useTranslations';

export const IbanField = () => {
  const t = useTranslations('pages.companyDetails.sections.bankDetails');
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const fieldController = FormFieldController.instance;

  const ibanForm = fieldController.getValue(BANK_DETAILS_IBAN).value
    ? `${fieldController.getValue(BANK_DETAILS_IBAN).value}`
    : '';

  const [ibanValue, setIban] = useState(ibanForm);

  function handleIbanChange(value: string) {
    setIban(value.toUpperCase());
  }

  const optional = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: true,
    default: false,
  });

  return (
    <>
      <SectionHeading>{t('fields.iban.info', { partnerName })}</SectionHeading>
      <FormRow>
        <InputWithField
          name={BANK_DETAILS_IBAN}
          onChange={handleIbanChange}
          value={ibanValue}
          placeholder={t('fields.iban.placeholder')}
          optional={optional}
          noWhiteSpaces={true}
        />
      </FormRow>
    </>
  );
};
