import React from 'react';

import { useSelector } from 'react-redux';

import { forwardToInsuranceAction } from 'modules/InquiryDetails/DetailedInformation/ForwardInquiryToInsurance/forwardToInquiryInsurance.service';
import { useToasts } from 'shared/hooks/useToasts';
import {
  getInquiryIdSelector,
  getInquiryWasForwardedToInsurance,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const ForwardInquiryToInsurance = () => {
  const wasForwarded = useSelector(getInquiryWasForwardedToInsurance);
  const { makeCall, isPending } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { success } = useToasts();
  const t = useTranslations('pages.inquiryDetails.detailed.forwardToInsurance');

  const forwardInquiry = () =>
    makeCall(forwardToInsuranceAction(inquiryId), () => {
      success({ description: t('forwarded') });
    });

  return (
    <ButtonComponent disabled={wasForwarded} isLoading={isPending} onClick={forwardInquiry} ml="4">
      {wasForwarded ? t('forwarded') : t('caption')}
    </ButtonComponent>
  );
};

export default ForwardInquiryToInsurance;
