import React from 'react';

import { useSelector } from 'react-redux';

import EditInquiryLink from 'components/EditInquiryLink';
import paths from 'constants/paths';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import { DetailedInformationWrapper as Wrapper } from 'modules/InquiryDetails/DetailedInformation/styles';
import UserProfileSection from 'modules/InquiryDetails/DetailedInformation/UserProfileSection/UserProfileSection';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import PurposeSection from './PurposeSection/PurposeSection';

const OnlineFactoringDetailedInquiryInformation = () => {
  const inquiryId = useSelector(getInquiryIdSelector)!;
  const canEdit = useCanEdit();
  const pathToEditInquiry = paths.operation.inquiryDetails.edit.root;

  return (
    <article>
      <InquiryDetailsHeader>
        {canEdit && <EditInquiryLink to={pathToEditInquiry.replace(':id', inquiryId)} />}
      </InquiryDetailsHeader>

      <Wrapper>
        <PurposeSection />
        <CompanyDetailsSection />
        <UserProfileSection />
      </Wrapper>
    </article>
  );
};

export default OnlineFactoringDetailedInquiryInformation;
