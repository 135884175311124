import { useSelector } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompanyAssociatedPersonsSelector } from '../store/selectors';
import { SourceType } from './AssociatedPersonMergeSections';

const mergePerson = (
  personId: string,
  companyId: string,
  kycId: string | null,
  crefoId: string | null,
) => {
  const kyc = kycId && { merge_from_kyc_id: kycId };
  const crefo = crefoId && { merge_from_crefo_id: crefoId };

  return callReverseApi({
    url: endpoints.COMPANIES.SPECIALIZED.ASSOCIATES_MERGE.compose({ params: { id: companyId } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          merge_to_record_id: personId,
          ...kyc,
          ...crefo,
        },
      },
    },
  });
};

export const useMergePerson = () => {
  const { makeCall } = useDispatchApiCall();

  return async (
    personId: string,
    kycId: string | null,
    crefoId: string | null,
    companyId: string,
  ) => makeCall(mergePerson(personId, companyId, kycId, crefoId));
};

const getPersonsFromApi = (data: IAssociatedPerson[], personId: string) =>
  data.filter((p: IAssociatedPerson) => p.id === personId);

const getPeopleToMergeFromApi = (data: IAssociatedPerson[]) =>
  data.filter((p: IAssociatedPerson) => [SourceType.CREFO, SourceType.KYC].includes(p.source));

export const usePeopleToMergeSelectOptions = (peopleToMerge: IAssociatedPerson[]) =>
  peopleToMerge
    .filter((people: IAssociatedPerson) => people.type === 'legal_representatives')
    .map((people: IAssociatedPerson) => ({
      value: { id: people.id, source: people.source, type: people.type },
      label: `${people.lastName}, ${people.firstName} geb. ${people.birthDate ?? '-'}`,
    }));

export const useAssociatedPersons = (personId: string) => {
  const associatedPersons = useSelector(getCompanyAssociatedPersonsSelector);
  const persons = getPersonsFromApi(associatedPersons, personId);
  const peopleToMerge = getPeopleToMergeFromApi(associatedPersons);

  return { persons, peopleToMerge };
};
