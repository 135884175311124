import React from 'react';

import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import PageTitle from 'components/PageTitle/PageTitle';
import PortalPage from 'components/PortalPage';
import paths from 'constants/paths';
import ErrorBoundary from 'modules/ErrorBoundary';
import DataProvider from 'modules/InquiryDetails/FetchInquiryDetails';
import ValueApiInquiryTable from 'pages/customerPortal/MarketInformationPage';
import MarketInfoAddressSelectionPage from 'pages/customerPortal/MarketInformationPage/MarketInfoAddressSelectionPage';
import NonBindingOffer from 'pages/operationPortal/InquiryDetails/Dashboard/NonBindingOffer/NonBindingOffer';
import OperationNavigationBar from 'pages/operationPortal/InquiryDetails/NavigationBar/OperationNavigationBar';
import { useOperationDetailsRoutes } from 'pages/operationPortal/InquiryDetails/useOperationDetailsRoutes';
import InquiryEdit from 'pages/operationPortal/InquiryEdit/OperationInquiryEdit';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';
import { useTranslations } from 'utils/hooks/useTranslations';

import { LegalRepresentativeCorrection } from '../InquiryDetails/Dashboard/LegalRepresentativeCorrection';
import { KycDataCorrection } from './Dashboard/KycDataCorrection';

const InquiryDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const routes = useOperationDetailsRoutes();
  const t = useTranslations();

  return (
    <ErrorBoundary>
      <PageTitle title={t('pages.inquiryDetails.heading')} />
      <DataProvider inquiryId={id}>
        <Switch>
          <OperationPrivateRoute
            path={paths.operation.inquiryDetails.edit.root}
            component={InquiryEdit}
          />

          <OperationPrivateRoute
            path="*"
            render={() => (
              <PortalPage heading={<OperationNavigationBar id={id} />}>
                <Switch>
                  <OperationPrivateRoute
                    exact
                    path={paths.operation.inquiryDetails.nonBindingOffer()}
                    component={NonBindingOffer}
                  />
                  <OperationPrivateRoute
                    exact
                    path={paths.operation.inquiryDetails.legalRepresentationCorrection()}
                    component={LegalRepresentativeCorrection}
                  />
                  <OperationPrivateRoute
                    exact
                    path={paths.operation.inquiryDetails.kycDataBankResolution()}
                    component={KycDataCorrection}
                  />
                  <OperationPrivateRoute
                    exact
                    path={paths.operation.inquiryDetails.marketInformationDetails}
                    component={ValueApiInquiryTable}
                  />
                  <OperationPrivateRoute
                    exact
                    path={paths.operation.inquiryDetails.marketInformationAddress}
                    component={MarketInfoAddressSelectionPage}
                  />
                  {routes.map((route) => (
                    <OperationPrivateRoute
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  <Route
                    path="*"
                    render={() => (
                      <Redirect to={paths.operation.inquiryDetails.dashboard.replace(':id', id)} />
                    )}
                  />
                </Switch>
              </PortalPage>
            )}
          />
        </Switch>
      </DataProvider>
    </ErrorBoundary>
  );
};

export default InquiryDetails;
