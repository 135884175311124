import React from 'react';

import { useDispatch } from 'react-redux';

import StyledLogoutButton from 'components/PortalPage/StyledLogoutButton';
import { logOutAction } from 'shared/auth/loginLogout.actions';
import { useToasts } from 'shared/hooks/useToasts';
import { resetInquiryDetailsAction } from 'store/inquiryDetails/actions';
import { showRwdMenu } from 'store/layout/actions';
import { resetForm as resetFormAction } from 'store/progress/actions';
import { useTranslations } from 'utils/hooks/useTranslations';

const PortalLogoutBtn = () => {
  const t = useTranslations();
  const { success } = useToasts();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(showRwdMenu(false));
    dispatch(resetInquiryDetailsAction());
    success({ description: t('pages.companyDetails.sections.contactPerson.buttons.loggedOut') });
    dispatch(logOutAction());
    dispatch(resetFormAction(true));
  };

  return (
    <StyledLogoutButton type="button" onClick={handleLogout} data-testid="logout-button">
      {t('buttons.logout')}
    </StyledLogoutButton>
  );
};

export default PortalLogoutBtn;
