import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const submitReeEditMarketValues = (data: Record<string, unknown>, inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.UPDATE_MARKET_VALUE_REE.compose({ params: { inquiryId } }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: data,
      },
    },
  });

export default submitReeEditMarketValues;
