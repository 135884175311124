import React, { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import {
  PEOPLE_DETAILS,
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATIVE,
  USER_SHARED_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import SummaryField from 'modules/SummaryTable/SummaryField';
import {
  PURPOSE_STEP,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
} from 'onlinefactoring/formFields';
import LegalRepresentativesSummaryStep from 'pages/inquiryFlow/SummaryPage/LegalRepresentativesSummaryStep';
import { StyledSummaryRows } from 'pages/inquiryFlow/SummaryPage/NewSummaryPage/styles';
import { isLeadProcessLane } from 'store/inquiryProcess/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { TextComponent } from '../../theme/components/Text';

export const legalRepresentativesDataParser: {
  [stepName: string]: (formValues: any) => ReactNode;
} = {
  [PEOPLE_DETAILS]: (formValues: any) => {
    const t = useTranslations();
    const isLead = useSelector(isLeadProcessLane);
    const { toBoolean } = fieldParsers;
    const userLegalRepresentation = toBoolean(formValues[USER_SHARED_REPRESENTATION]);
    if (
      !isLead &&
      (formValues[USER_REPRESENTATIVE] || formValues[USER_EXISTING_REPRESENTATIVE]) &&
      !userLegalRepresentation
    ) {
      return (
        <LegalRepresentativesSummaryStep
          headerText={t('pages.peopleDetails.sections.legalRepresentation.title')}
        />
      );
    }
    return null;
  },
  [PURPOSE_STEP]: (formValues) => {
    const t = useTranslations(
      'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose',
    );

    return (
      <>
        <TextComponent as="h4" mb={4} color="text.tertiary">
          {t('sectionHeading')}
        </TextComponent>
        <StyledSummaryRows>
          {[
            {
              name: SHARE_OF_SALES_BUSINESS_CUSTOMERS,
              value: formValues[SHARE_OF_SALES_BUSINESS_CUSTOMERS],
            },
            {
              name: SHARE_OF_SALES_MAIN_CUSTOMERS,
              value: formValues[SHARE_OF_SALES_MAIN_CUSTOMERS],
            },
            {
              name: SHARE_OF_SALES_FOREIGN_CUSTOMERS,
              value: formValues[SHARE_OF_SALES_FOREIGN_CUSTOMERS],
            },
          ].map(({ name, value }) => (
            <SummaryField fieldName={name} fieldValue={value} />
          ))}
        </StyledSummaryRows>
      </>
    );
  },
};
