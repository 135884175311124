import { Schema } from '@hapi/joi';

import { UserRole } from 'constants/user/userRoles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';

export interface BusinessDataPopulators {
  formType?: InquiryType;
  partnerId?: any;
  formValues: any;
  sectionValidations?: any;
  userRoles?: UserRole[];
  config?: { [key: string]: boolean };
}
export type BusinessCondition = (data: BusinessDataPopulators) => boolean;
export type BusinessConditionMap = { [key: string]: BusinessCondition | boolean };

export enum FormSections {
  root = 'root',
  purposeKind = 'purposeKind',
  bankDetails = 'bankDetails',
  amount = 'amount',
  otherPurpose = 'otherPurpose',
  goods = 'goods',
  machine = 'machine',
  debtRescheduling = 'debtRescheduling',
  vehicle = 'vehicle',
  realEstateProject = 'realEstateProject',
  realEstateNewBuilding = 'realEstateNewBuilding',
  realEstatePurchase = 'realEstatePurchase',
  realEstateFollowUpFinancing = 'realEstateFollowUpFinancing',
  realEstateReconstruction = 'realEstateReconstruction',
  realEstateProjectDevelopment = 'realEstateProjectDevelopment',
  realEstateBuildingDetails = 'realEstateBuildingDetails',
  projectFinancingRole = 'projectFinancingRole',
  projectComposition = 'projectComposition',
  projectFinancingObjectInformation = 'projectFinancingObjectInformation',
  projectPropertyDocuments = 'projectPropertyDocuments',
  buildingProject = 'buildingProject',
  buildingInformation = 'buildingInformation',
  buildingFinancingPlan = 'buildingFinancingPlan',
  agriculturalMachine = 'agriculturalMachine',
  agriculturalRealestate = 'agriculturalRealestate',
  agriculturalItEquipment = 'agriculturalItEquipment',
  agriculturalConsulting = 'agriculturalConsulting',
  quickCredit = 'quickCredit',
  otherKfwProgram = 'otherKfwProgram',
  coronaDescription = 'coronaDescription',
  loan = 'loan',
  fixedAmortisableLoan = 'fixedAmortisableLoan',
  leasing = 'leasing',
  hirePurchase = 'hirePurchase',
  factoring = 'factoring',
  investmentLoan = 'investmentLoan',
  overdraft = 'overdraft',
  otherProduct = 'otherProduct',
  mezzanine = 'mezzanine',
  projectFinancing = 'projectFinancing',
  propertyLoan = 'propertyLoan',
  securities = 'securities',
  favoredDecisionDate = 'favoredDecisionDate',
  excludedBanks = 'excludedBanks',
  companyContact = 'companyContact',
  contactPerson = 'contactPerson',
  password = 'password',
  termsOfUsage = 'termsOfUsage',
  companySelection = 'companySelection',
  companyDetails = 'companyDetails',
  peopleDetails = 'peopleDetails',
  legalRepresentation = 'legalRepresentation',
  offer = 'offer',
  contractDetails = 'contractDetails',
  customerData = 'customerData',
  businessDetails = 'businessDetails',
  beneficiaryOwners = 'beneficiaryOwners',
  furtherNeed = 'furtherNeed',
  fundingClosingDate = 'fundingClosingDate',
}

export interface FormSchemaField {
  caption: string;
  validation: Schema;
  canShow?: BusinessCondition | boolean;
}

type ShouldShowSectionFunction = (param: {
  formValues: any;
  sectionValidations: { [key: string]: boolean };
  purposes: object[];
}) => boolean;

export interface FormSchemaSection {
  shouldShow?: ShouldShowSectionFunction | boolean;
  fields: {
    [key: string]: FormSchemaField;
  };
}

export type FormSubSchema = { [key in FormSections]?: FormSchemaSection };
