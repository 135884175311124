import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import {
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT,
  PRE_FINANCING_PERIOD,
  PURPOSE_KIND_BFS,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import getPurchasedInvoicesPaidOutOnOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/purchasedInvoicesPaidOutOnOptions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { reduceToTranslatedMap } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/useValueTranslationMap';
import { usePurposeKindSpecificValue } from 'shared/choosePurposeKindSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFormValues } from 'store/hooks/useFormValues';
import { getIndicativeOfferCalculations } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Description } from './styles';

export const ConditionDescription = () => {
  const t = useTranslations();
  const calculations = useSelector(getIndicativeOfferCalculations);
  const formValues = useFormValues();
  const { formatMessage } = useIntl();

  const payout = formValues[BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON] as string;
  let preFinancingPeriod = usePurposeKindSpecificValue({
    [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: '60',
    default: formValues[PRE_FINANCING_PERIOD],
  });

  if (formValues[PURPOSE_KIND_BFS] === BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT) {
    preFinancingPeriod = 60;
  }

  const description = t('pages.inquiryDetails.dashboard.actions.factoringOffer.description', {
    payout: getPurchasedInvoicesPaidOutOnOptions(formatMessage, {}).reduce(
      reduceToTranslatedMap,
      {},
    )[payout],
    preFinancingPeriod: `${preFinancingPeriod} ${t('other.days')}`,
  });
  return (
    <Condition condition={!!calculations}>
      <Description>{description}</Description>
    </Condition>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: ConditionDescription,
  default: () => null,
});
