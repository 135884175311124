import styled from 'styled-components/macro';

import Text from 'components/Text/Text';

const ErrorText = styled(Text)`
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.status.error};

  :not(:empty) {
    margin-top: 0.5rem;
  }
`;

export default ErrorText;
