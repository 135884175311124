import React from 'react';

import { Box, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import Condition from 'components/Condition';
import FileRequestBlock from 'components/UploadBlock/NewFileRequestBlock/FileRequestBlock';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import { useFormConfig } from 'config/formConfig/hooks';
import type { IFileRequest } from 'models/FileRequest.model';
import { AppActionThunk } from 'models/types/AppThunk.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import FileSectionSubtitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionSubtitle';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import AddNewRequest from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/AddNewRequest';
import type { ApiFile } from 'shared/documentExchange/documentExchange.service';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { uploadFileToFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getFileRequestsSelector } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const RequestsForCustomer: React.FC = () => {
  const fileRequests: IFileRequest[] = useSelector(getFileRequestsSelector);
  const { selectedInquiryType } = useFormConfig();

  const dispatch = useDispatch();
  const handleFileUploaded =
    (requestId: string) =>
    (file: ApiFile): AppActionThunk<void> =>
      dispatch(uploadFileToFileRequestAction(requestId, mapApiFile(file)));

  const inquiryId = useSelector(getInquiryIdSelector);
  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });
  const t = useTranslations('pages.inquiryDetails.documentExchange');

  let requestDescriptionLength = 100;

  if (selectedInquiryType === InquiryType.bfsService) {
    requestDescriptionLength = 0;
  }

  const requiredFiles = fileRequests.filter((file) => file.required);

  const optionalFiles = fileRequests.filter((file) => !file.required);

  const isMultipleFilesPerUploadRequestEnabled = useConfig(
    CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST,
  );

  return (
    <section>
      <Box display="flex" alignItems="center" justifyContent="space-between" w="100%" mb="2rem">
        <FileSectionTitle mb={0}>{t('filesRequestedFromBank')}</FileSectionTitle>
        <AddNewRequest />
      </Box>
      <FileSectionSubtitle>{t('requiredFiles')}</FileSectionSubtitle>
      <VStack>
        {requiredFiles.map((request) =>
          isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
            <MultipleFilesBlock
              key={request.id}
              fileRequestId={request.id}
              isFileUploadingEnabled
              {...request}
            />
          ) : (
            <FileRequestBlock
              key={request.id}
              fileRequest={request}
              url={uploadUrl}
              onUpload={handleFileUploaded(request.id)}
              requestDescriptionLength={requestDescriptionLength}
            />
          ),
        )}
      </VStack>

      <Condition condition={!!optionalFiles.length}>
        <FileSectionSubtitle>{t('optionalFiles')}</FileSectionSubtitle>
        <VStack>
          {optionalFiles.map((request) =>
            isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
              <MultipleFilesBlock
                key={request.id}
                fileRequestId={request.id}
                isFileUploadingEnabled
                {...request}
              />
            ) : (
              <FileRequestBlock
                key={request.id}
                fileRequest={request}
                url={uploadUrl}
                onUpload={handleFileUploaded(request.id)}
                requestDescriptionLength={requestDescriptionLength}
              />
            ),
          )}
        </VStack>
      </Condition>
    </section>
  );
};

export default RequestsForCustomer;
