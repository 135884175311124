import React, { useState } from 'react';

import { Box, Center, Divider, Flex } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import endpoints from 'api/CompeonReverseApi/endpoints';
import submitBankOfferAction from 'api/CompeonReverseApi/operation/submitBankOffer/actions';
import FormSection from 'components/FormSection';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { Container } from 'components/PortalPage/styles';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { useSelectFile } from 'components/UploadBlock/hooks/useSelectFile';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import UploadDropzone from 'components/UploadBlock/UploadIdleState/UploadDropzone';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { UploadedFile } from 'models/File.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useToasts } from 'shared/hooks/useToasts';
import {
  generateContractAction,
  mapInquiryDetailsApiResponseAction,
  startSigningProcess,
} from 'store/inquiryDetails/actions';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { TableComponent } from 'theme/components/Table';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { UploadOfferTableActions } from './UploadOfferTableActions';

interface UploadedFileRow {
  id: string;
  cols: React.ReactNode[];
}

interface GeneratedContractFileRow {
  id: string;
}

export const UploadOffer = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<UploadedFileRow[]>([]);
  const [lastGeneratedcontract, setLastGeneratedcontract] = useState<GeneratedContractFileRow[]>(
    [],
  );

  const lane = useSelector(getInquiryLane);
  const { selectedInquiryType } = useFormConfig();
  const { error: notifyError, success } = useToasts();
  const isContractCreationInLead =
    useConfig(CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_LEAD) && lane === InquiryLane.lead;
  const isContractCreationInOffer =
    useConfig(CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_OFFER) && lane === InquiryLane.offer;

  const createContract = isContractCreationInLead || isContractCreationInOffer;

  const { uploadProgress } = useNewUploadFile(endpoints.FILES.LIST.compose());

  const handleRemove = (id: string) => setFiles((files) => files.filter((file) => file.id !== id));

  const handleSetFiles = (uploadedFile: UploadedFile, selectedFile: File) =>
    setFiles((files) => [
      ...files,
      {
        id: uploadedFile.id,
        cols: [
          selectedFile.name,
          <UploadOfferTableActions
            file={{ id: uploadedFile.id, fileName: selectedFile.name }}
            onRemove={handleRemove}
          />,
        ],
      },
    ]);

  const handleSetGeneratedContractFiles = (uploadedFile: UploadedFile) => {
    if (lastGeneratedcontract.length) {
      setFiles((files) => files.filter((file) => file.id !== lastGeneratedcontract[0].id));
    }

    setFiles((files) => [
      ...files,
      {
        id: uploadedFile.id,
        cols: [
          uploadedFile.attributes.filename,
          <UploadOfferTableActions
            file={{ id: uploadedFile.id, fileName: uploadedFile.attributes.filename }}
            onRemove={handleRemove}
          />,
        ],
      },
    ]);

    setLastGeneratedcontract([{ id: uploadedFile.id }]);
  };

  const { onFileSelected, errorMsg, isDropping, uploadingState, setIsDropping } = useSelectFile(
    handleSetFiles,
    endpoints.FILES.LIST.compose(),
  );

  const goBackToDashboard = () =>
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id));

  const submitDocuments = async () => {
    const fileIds = files.map((file) => file.id);

    await makeCall(
      submitBankOfferAction({ inquiryId: id, fileIds, type: 'multiple' }),
      ({ payload }: { payload: AxiosResponse }) => {
        dispatch(mapInquiryDetailsApiResponseAction(payload.data));
        const createdOffer = payload.data.included.find(
          (item: unknown & { type: string }) => item.type === 'offers',
        );

        if (selectedInquiryType !== InquiryType.bfs) {
          dispatch(startSigningProcess(createdOffer.id, fileIds));
        }
        success({ description: t('success') });
        goBackToDashboard();
      },
    );
  };

  const colHeaders = [t('table.uploadedDocuments'), t('table.actions')];

  const handleGenerateContract = async () => {
    const { error, payload } = await makeCall(generateContractAction(id));

    if (error) {
      notifyError({
        description: t('error'),
      });
    } else {
      success({ description: t('success') });
      handleSetGeneratedContractFiles(payload.data.data);
    }
  };

  return (
    <Container>
      <Box pt="12" pb="20">
        <Box maxWidth="800px" pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t('title')}
          </HeadingComponent>
          <TextComponent color="text.tertiary">{t('description')}</TextComponent>
        </Box>

        <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />

        <Form
          onSubmit={submitDocuments}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {createContract && (
                <FormSection title={t('generateContract.title')} sectionNumber={1}>
                  <TextComponent color="text.tertiary">
                    {t('generateContract.description')}
                  </TextComponent>
                  <Flex justify="flex-start" mt={12}>
                    <ButtonComponent onClick={handleGenerateContract}>
                      {t('generateContract.submit')}
                    </ButtonComponent>
                  </Flex>
                </FormSection>
              )}

              <FormSectionLayout sectionNumber={1} title={t('sectionTitle')}>
                <Box mb={12}>
                  <UploadDropzone
                    onFileDropping={setIsDropping}
                    onFileDropped={onFileSelected}
                    disabled={uploadingState !== UPLOADING_STATE.IDLE}
                  >
                    <Center
                      flexDirection="column"
                      minHeight="258px"
                      p={8}
                      border="2px dashed"
                      borderColor={isDropping ? 'brand.default' : 'border.darkGrey'}
                      borderRadius="default"
                      bgColor={isDropping ? 'brand.default_L93' : 'background.white'}
                    >
                      {uploadingState === UPLOADING_STATE.SCANNING && <Box>{t('scanning')}</Box>}

                      {uploadingState === UPLOADING_STATE.UPLOADING && <Box>{uploadProgress}%</Box>}

                      {uploadingState === UPLOADING_STATE.FINISHED && (
                        <Box>
                          <CheckmarkIcon boxSize={16} color="icon.ok" />
                        </Box>
                      )}
                      {uploadingState === UPLOADING_STATE.ERROR && <Box>{errorMsg}</Box>}

                      {uploadingState === UPLOADING_STATE.IDLE && (
                        <>
                          <UploadIcon boxSize={12} mb={4} color="brand.default" />
                          <TextComponent as="span" mb={3}>
                            {t('uploadRequest')}
                          </TextComponent>
                          <TextComponent as="span" mb={4} color="text.tertiary">
                            {t('uploadAlternative')}
                          </TextComponent>
                          <ButtonComponent>{t('searchDocuments')}</ButtonComponent>
                        </>
                      )}
                    </Center>
                  </UploadDropzone>
                </Box>

                <Box>
                  <TableComponent colHeaders={colHeaders} tbodyRows={files} hasActions />
                </Box>
              </FormSectionLayout>

              <Flex justify="flex-end" mt={12}>
                <ButtonComponent
                  leftIcon={<CloseIcon boxSize={6} d="block" />}
                  mr={4}
                  onClick={goBackToDashboard}
                  variant="tertiary"
                  data-testid={'After-Contract-Upload-Buttons-Cancel'}
                >
                  {t('cancel')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
                  type="submit"
                  disabled={!files.length}
                  data-testid={'After-Contract-Upload-Buttons-Submit'}
                >
                  {t('submit')}
                </ButtonComponent>
              </Flex>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};
