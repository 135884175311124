import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from '@chakra-ui/react';

import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';

import { textStyle } from '../../theme/themeConstants';

interface SeperatorProps {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  divider?: boolean;
}

export const Seperator = ({ title, children, divider }: SeperatorProps) => {
  return (
    <>
      <Accordion as="section" position="relative" allowMultiple defaultIndex={[0]}>
        <AccordionItem border="0">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                p={0}
                {...textStyle.sizeLTight}
                textAlign="left"
                color="brand.default"
                _focus={{
                  boxShadow: 'none',
                  color: 'text.primary',
                }}
                _hover={{
                  color: 'text.primary',
                  backgroundColor: 'inherit',
                }}
              >
                {isExpanded ? (
                  <CaretDownIcon boxSize={6} mr={2} />
                ) : (
                  <CaretDownIcon boxSize={6} mr={2} transform="rotate(-90deg)" />
                )}
                <Box as="span">{title}</Box>
              </AccordionButton>
              <AccordionPanel position="relative" pt={8} px={2} pb={4}>
                {children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      {divider && <Divider mt={4} mb={4} />}
    </>
  );
};
