import React from 'react';

import PropTypes from 'prop-types';

import { useDownloadFile } from 'components/DownloadBlock/useDownloadFile';
import { formatDateDays, formatDateTime } from 'utils/date';

import { StyledLinkButton } from './styles';

const DownloadLink = ({ fileId, fileName, date }) => {
  const downloadFile = useDownloadFile({ fileId, fileName });

  return (
    <StyledLinkButton type="button" onClick={downloadFile}>
      {fileName} {formatDateDays(date)}; {formatDateTime(date)}
    </StyledLinkButton>
  );
};

DownloadLink.propTypes = {
  fileId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default DownloadLink;
