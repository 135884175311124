import { PropertiesToNumber } from './types';

function recursiveHelper<T>(obj: T): T {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === undefined) {
        (obj as any)[key] = 0;
      } else if (typeof obj[key] === 'object') {
        obj[key] = recursiveHelper(obj[key]);
      }
    }
  }
  return obj;
}

export function replaceUndefinedWithZero<T>(obj: T): PropertiesToNumber<T> {
  const replaced = recursiveHelper(obj) as unknown;
  return replaced as PropertiesToNumber<T>;
}
