import {
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
  CORONA_VIABILTIY,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_FINANCING_OPTION,
  CORONA_LIQUIDITY_NEED,
  CORONA_VIABILTIY_2020,
  CORONA_TOTAL_ASSETS,
  PURPOSE_KIND,
  FINANCING_AMOUNT,
  OTHER_PURPOSE_DESCRIPTION,
  DEBT_RESCHEDULING_DESCRIPTION,
  GOODS__PURCHASE_PRICE,
  GOODS__DESCRIPTION,
  GOODS__SUPPLIER,
  MACHINE_CATEGORY,
  GOODS__ESTIMATED_DELIVERY_DATE,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_KIND,
  MACHINE_NEW,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_IS_ORDERED,
  MACHINE_DELIVERY_DATE,
  MACHINE_MANUFACTURER,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_COUNT,
  VEHICLE_NEW,
  VEHICLE_KIND,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  VEHICLE_YEAR,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_SCHEDULED_DATE,
  REAL_ESTATE_KIND,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_FOLLOW_UP_AMOUNT,
  REAL_ESTATE_RECONSTRUCTION_COST,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_ZIP_CODE,
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_RENTED_OUT_AREA,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  BUILDING_PROJECT,
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_USE_OF_FUNDS_SUM,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_SOURCE_OF_FUNDS_SUM,
  BUILDING_COOWNERSHIP,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  BANK_DETAILS_IBAN,
  CLIENT_ROLE,
  MACHINE_CUSTOM_CATEGORY,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
  LIQUIDITY_DESCRIPTION,
  VEHICLE_DESCRIPTION,
  REAL_ESTATE_TOTAL_COST,
  REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION,
  REAL_ESTATE_FINANCING_OBJECT_DESCRIPTION,
  REAL_ESTATE_COST_OF_MODERNIZATION,
  GUARANTY_DESCRIPTION,
  GUARANTY_TYPE,
  GUARANTY_OTHER_TYPE,
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
  PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__MONTHLY_TURNOVER,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__PURPOSE_KIND,
  PRE_FINANCING_PERIOD,
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
  PURPOSE_KIND_BFS,
  BFS_SERVICE__NEED_FURTHER_ADVICE,
  BFS_SERVICE__EXPLICIT_NEED_FURTHER_ADVICE,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
  SPECIAL_FEATURES,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
} from 'modules/Inquiry/Form/formFields';
import { BUSINESS_SPECIFICS, PAYMENT_TERM } from 'onlinefactoring/formFields';

// The order of these labels is relevant for inquiry details page

export const financingNeedLabelsMap: { [key: string]: string } = {
  [PURPOSE_KIND]: 'pages.financingNeed.sections.purpose.fields.purposeKind.caption',
  [FINANCING_AMOUNT]: 'pages.financingNeed.sections.amount.fields.financingAmount.caption',
  [OTHER_PURPOSE_DESCRIPTION]:
    'pages.financingNeed.sections.other.fields.otherPurposeDescription.caption',
  [DEBT_RESCHEDULING_DESCRIPTION]:
    'pages.financingNeed.sections.other.fields.otherPurposeDescription.caption',
  [CORONA_NUMBER_OF_EMPLOYEES]: 'pages.coronaStep.fields.countOfEmployees.caption',
  [CORONA_REVENUE_OF_2019]: 'pages.coronaStep.fields.revenue.caption',
  [CORONA_VIABILTIY]: 'pages.coronaStep.fields.viability.caption',
  [CORONA_EXPENDITURE_OF_EMPLOYEES]: 'pages.coronaStep.fields.expenditureOfEmployees.caption',
  [CORONA_FINANCING_OPTION]: 'pages.coronaStep.fields.financingOption.caption',
  [CORONA_LIQUIDITY_NEED]: 'pages.coronaStep.fields.liquidityNeed.caption',
  [CORONA_VIABILTIY_2020]: 'pages.coronaStep.fields.viability2020.caption',
  [CORONA_TOTAL_ASSETS]: 'pages.coronaStep.fields.totalAssets.caption',
  [GOODS__PURCHASE_PRICE]: 'pages.financingNeed.sections.goods.fields.goodsPurchasePrice.caption',
  [GOODS__DESCRIPTION]: 'pages.financingNeed.sections.goods.fields.goodsDescription.caption',
  [GOODS__SUPPLIER]: 'pages.financingNeed.sections.goods.fields.goodsSupplier.caption',
  [GOODS__ESTIMATED_DELIVERY_DATE]:
    'pages.financingNeed.sections.goods.fields.goodsEstimatedDeliveryDate.caption',
  [MACHINE_CATEGORY]: 'pages.financingNeed.sections.machine.fields.machineCategory.caption',
  [MACHINE_PRICE]: 'pages.financingNeed.sections.machine.fields.machinePrice.caption',
  [MACHINE_COUNT]: 'pages.financingNeed.sections.machine.fields.machineCount.caption',
  [MACHINE_DESCRIPTION]: 'pages.financingNeed.sections.machine.fields.machineDescription.caption',
  [MACHINE_PERIOD_OF_USE]: 'pages.financingNeed.sections.machine.fields.machinePeriodOfUse.caption',
  [MACHINE_MANUFACTURER]: 'pages.financingNeed.sections.machine.fields.machineManufacturer.caption',
  [MACHINE_KIND]: 'pages.financingNeed.sections.machine.fields.machineKind.caption',
  [MACHINE_NEW]: 'pages.financingNeed.sections.machine.fields.machineIsNew.caption',
  [MACHINE_AGE]: 'pages.financingNeed.sections.machine.fields.machineAge.caption',
  [MACHINE_ORIGINAL_PRICE]:
    'pages.financingNeed.sections.machine.fields.machineOriginalPrice.caption',
  [MACHINE_IS_ORDERED]: 'pages.financingNeed.sections.machine.fields.machineIsOrdered.caption',
  [MACHINE_DELIVERY_DATE]:
    'pages.financingNeed.sections.machine.fields.machineDeliveryDate.caption',
  [LIQUIDITY_DESCRIPTION]:
    'pages.financingNeed.sections.liquidity.fields.liquidityDescription.caption',
  [VEHICLE_PURCHASE_PRICE]:
    'pages.financingNeed.sections.commercialVehicle.fields.vehiclePrice.caption',
  [VEHICLE_DESCRIPTION]:
    'pages.financingNeed.sections.commercialVehicle.fields.vehicleDescription.caption',
  [VEHICLE_COUNT]: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleCount.caption',
  [VEHICLE_NEW]: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleNew.caption',
  [VEHICLE_YEAR]: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleYear.caption',
  [VEHICLE_KIND]: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.caption',
  [VEHICLE_OTHER_DESCRIPTION]:
    'pages.financingNeed.sections.commercialVehicle.fields.vehicleOtherDescription.caption',
  [VEHICLE_MANUFACTURER]:
    'pages.financingNeed.sections.commercialVehicle.fields.vehicleManufacturer.caption',
  [VEHICLE_MODEL]: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleModel.caption',
  [VEHICLE_YEARLY_MILEAGE]:
    'pages.financingNeed.sections.commercialVehicle.fields.vehicleYearlyMileage.caption',
  [REAL_ESTATE_PROJECT]: 'pages.financingNeed.sections.realEstate.title',
  [REAL_ESTATE_SCHEDULED_DATE]:
    'pages.financingNeed.sections.newBuilding.fields.realEstateScheduledDate.caption',
  [REAL_ESTATE_KIND]: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.caption',
  [REAL_ESTATE_PURCHASE_PRICE]:
    'pages.financingNeed.sections.purchase.fields.realEstatePurchasePrice.caption',
  [REAL_ESTATE_FOLLOW_UP_AMOUNT]:
    'pages.financingNeed.sections.followUpFinancing.fields.realEstateFollowUpAmount.caption',
  [REAL_ESTATE_RECONSTRUCTION_COST]:
    'pages.financingNeed.sections.reconstruction.fields.realEstateReconstructionCost.caption',
  [REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT]:
    'pages.financingNeed.sections.realEstate.fields.realEstateDevelopmentProjectAmount.caption',
  [REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION]:
    'pages.financingNeed.sections.realEstate.fields.realEstateDevelopmentProjectDescription.caption',
  [REAL_ESTATE_CONSTRUCTION_DATE]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateConstructionDate.caption',
  [REAL_ESTATE_LAND_SIZE]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateLandSize.caption',
  [REAL_ESTATE_TAXATION]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateTaxation.caption',
  [REAL_ESTATE_COLD_RENT]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateColdRent.caption',
  [REAL_ESTATE_ZIP_CODE]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateZipCode.caption',
  [REAL_ESTATE_PROJECT_DESCRIPTION]:
    'pages.financingNeed.sections.purchase.fields.projectDescription.caption',
  [REAL_ESTATE_BUILDING_SIZE]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateBuildingSize.caption',
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateHeritableBuildingRight.caption',
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateHeritableBuildingRightObligation.caption',
  [REAL_ESTATE_COST_OF_MODERNIZATION]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.costOfModernization.caption',
  [REAL_ESTATE_USAGE_KIND]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateUsageKind.caption',
  [REAL_ESTATE_FINANCING_OBJECT_DESCRIPTION]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.financialObjectDescription.caption',
  [REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.plannedStartOfConstruction.caption',
  [REAL_ESTATE_TOTAL_COST]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.totalCost.caption',
  [REAL_ESTATE_RENTED_OUT_AREA]:
    'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateRentedOutArea.caption',
  [PROJECT_FINANCING_ROLE]: 'pages.financingNeed.sections.role.title',
  [PROJECT_FINANCING_INVESTMENT_AMOUNT]:
    'pages.financingNeed.sections.projectComposition.fields.investmentAmount.caption',
  [PROJECT_FINANCING_OWN_FUNDS]:
    'pages.financingNeed.sections.projectComposition.fields.ownFunds.caption',
  [PROJECT_FINANCING_MEZZANINE]:
    'pages.financingNeed.sections.projectComposition.fields.mezzanine.caption',
  [PROJECT_FINANCING_OWN_WORK]:
    'pages.financingNeed.sections.projectComposition.fields.ownWork.caption',
  [PROJECT_FINANCING_SUBORDINATED_CAPITAL]:
    'pages.financingNeed.sections.projectComposition.fields.subordinatedCapital.caption',
  [PROJECT_FINANCING_OBJECT_ADDRESS]:
    'pages.financingNeed.sections.objectInformation.fields.objectAddress.caption',
  [PROJECT_FINANCING_OBJECT_ZIP_CODE]:
    'pages.financingNeed.sections.objectInformation.fields.objectZipCode.caption',
  [PROJECT_FINANCING_OBJECT_CITY]:
    'pages.financingNeed.sections.objectInformation.fields.objectCity.caption',
  [PROJECT_FINANCING_OBJECT_DESCRIPTION]:
    'pages.financingNeed.sections.objectInformation.fields.objectDescription.caption',
  [PROJECT_FINANCING_LOT_SIZE]:
    'pages.financingNeed.sections.objectInformation.fields.objectLotSize.caption',
  [PROJECT_FINANCING__BUILDING_YEAR]:
    'pages.financingNeed.sections.objectInformation.fields.buildingYear.caption',
  [PROJECT_FINANCING__MODERNIZATION_YEAR]:
    'pages.financingNeed.sections.objectInformation.fields.modernizationYear.caption',
  [PROJECT_FINANCING__FURNISHING_QUALITY]:
    'pages.financingNeed.sections.objectInformation.fields.furnishingsQuality.caption',
  [PROJECT_FINANCING__QUALITY_CONDITION]:
    'pages.financingNeed.sections.objectInformation.fields.conditionQuality.caption',
  [PROJECT_FINANCING__GUEST_BATHROOM]:
    'pages.financingNeed.sections.objectInformation.fields.guestBathroom.caption',
  [PROJECT_FINANCING__BALKONY]:
    'pages.financingNeed.sections.objectInformation.fields.balkony.caption',
  [PROJECT_FINANCING__LIFT]: 'pages.financingNeed.sections.objectInformation.fields.lift.caption',
  [PROJECT_FINANCING__CELLAR]:
    'pages.financingNeed.sections.objectInformation.fields.cellar.caption',
  [PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS]:
    'pages.financingNeed.sections.objectInformation.fields.parkingLots.numberOfParkingLots.caption',
  [PROJECT_FINANCING__NUMBER_OF_GARAGES]:
    'pages.financingNeed.sections.objectInformation.fields.garages.numberOfGarages.caption',
  [PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT]:
    'pages.financingNeed.sections.objectInformation.fields.parkingLots.plannedSalesPricePerUnit.caption',
  [PROJECT_FINANCING__PARKING_LOTS_RENT_NOW]:
    'pages.financingNeed.sections.objectInformation.fields.parkingLots.rentNow.caption',
  [PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE]:
    'pages.financingNeed.sections.objectInformation.fields.parkingLots.rentFuture.caption',
  [PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT]:
    'pages.financingNeed.sections.objectInformation.fields.garages.plannedSalesPricePerUnit.caption',
  [PROJECT_FINANCING__GARAGES_RENT_NOW]:
    'pages.financingNeed.sections.objectInformation.fields.garages.rentNow.caption',
  [PROJECT_FINANCING__GARAGES_RENT_FUTURE]:
    'pages.financingNeed.sections.objectInformation.fields.garages.rentFuture.caption',
  [PROJECT_FINANCING__NUMBER_OF_UNITS]:
    'pages.financingNeed.sections.objectInformation.fields.numberOfUnits.caption',
  [PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT]:
    'pages.financingNeed.sections.objectInformation.fields.futureUsageType.plannedSalesPrice.caption',
  [PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE]:
    'pages.financingNeed.sections.objectInformation.fields.futureUsageType.caption',
  [PROJECT_FINANCING_LIST_OF_RENTERS]:
    'pages.financingNeed.sections.objectInformation.fields.listOfRenters.caption',
  [PROJECT_FINANCING_ANCHOR_TENANT]:
    'pages.financingNeed.sections.objectInformation.fields.anchorTenant.caption',
  [PROJECT_FINANCING_RENTAL_CONTRACTS]:
    'pages.financingNeed.sections.objectInformation.fields.rentalContracts.caption',
  [PROJECT_FINANCING_RENT_NOW]:
    'pages.financingNeed.sections.objectInformation.fields.rentNow.caption',
  [PROJECT_FINANCING_RENT_FUTURE]:
    'pages.financingNeed.sections.objectInformation.fields.rentFuture.caption',
  [PROJECT_FINANCING_RENOVATION_PLANNED]:
    'pages.financingNeed.sections.objectInformation.fields.renovationPlanned.caption',
  [PROJECT_FINANCING_RENOVATION_DESCRIPTION]:
    'pages.financingNeed.sections.objectInformation.fields.renovationDescription.caption',
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]:
    'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.current',
  [PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE]:
    'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.future',
  [PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS]:
    'pages.financingNeed.sections.objectInformation.fields.typeOfUsageDetails.caption',
  [PROJECT_FINANCING_TYPE_OF_USAGE]:
    'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.caption',
  [PROJECT_FINANCING_USAGE_KIND_TYPE]:
    'pages.financingNeed.sections.objectInformation.fields.kindOfUsageDetails.caption',
  [PROJECT_FINANCING_USAGE_SPACE]:
    'pages.financingNeed.sections.objectInformation.fields.usageSpace.caption',
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]:
    'pages.financingNeed.sections.objectInformation.fields.percentageOfRent.caption',
  [PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE]:
    'pages.financingNeed.sections.propertyDocuments.fields.areDocumentsAvailable.caption',
  [PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST]:
    'pages.financingNeed.sections.propertyDocuments.fields.hasContaminatedSites.caption',
  [PROJECT_FINANCING_HAS_BUILD_PERMIT]:
    'pages.financingNeed.sections.propertyDocuments.fields.hasBuildingPermit.caption',
  [PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES]:
    'pages.financingNeed.sections.propertyDocuments.fields.hasListOfBuildingEncumbrances.caption',
  [PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE]:
    'pages.financingNeed.sections.propertyDocuments.fields.hasEnergyCertificate.caption',
  [BUILDING_PROJECT]: 'pages.financingNeed.sections.buildingProject.fields.buildingProject.caption',
  [BUILDING_TYPE]: 'pages.financingNeed.sections.building.fields.buildingType.caption',
  [BUILDING_TYPE_DESCRIPTION]:
    'pages.financingNeed.sections.building.fields.buildingTypeDescription.caption',
  [BUILDING_COOWNERSHIP]:
    'pages.financingNeed.sections.building.fields.buildingCoownership.caption',
  [BUILDING_CONDOMINIUM_NUM]:
    'pages.financingNeed.sections.building.fields.buildingCondominiumNum.caption',
  [BUILDING_NUMBER_OF_RESIDENTIAL_UNITS]:
    'pages.financingNeed.sections.building.fields.buildingNumberOfResidentialUnits.caption',
  [BUILDING_TOTAL_USABLE_SPACE]:
    'pages.financingNeed.sections.building.fields.buildingTotalUsableSpace.caption',
  [BUILDING_LIVING_SPACE]:
    'pages.financingNeed.sections.building.fields.buildingLivingSpace.caption',
  [BUILDING_COMMERCIAL_SPACE]:
    'pages.financingNeed.sections.building.fields.buildingCommercialSpace.caption',
  [BUILDING_SHARE_OF_COMMERCIAL_SPACE]:
    'pages.financingNeed.sections.building.fields.buildingShareOfCommercialSpace.caption',
  [BUILDING_TYPE_OF_USE]: 'pages.financingNeed.sections.building.fields.buildingTypeOfUse.caption',
  [BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL]:
    'pages.financingNeed.sections.building.fields.buildingColdRentPerYearCommercial.caption',
  [BUILDING_RENTED_SPACE_COMMERCIAL]:
    'pages.financingNeed.sections.building.fields.rentedSpaceCommercial.caption',
  [BUILDING_COLD_RENT_PER_YEAR_LIVING]:
    'pages.financingNeed.sections.building.fields.buildingColdRentPerYearLiving.caption',
  [BUILDING_RENTED_SPACE_LIVING]:
    'pages.financingNeed.sections.building.fields.rentedSpaceLiving.caption',
  [BUILDING_CONSTRUCTION_YEAR]:
    'pages.financingNeed.sections.building.fields.constructionYear.caption',
  [BUILDING_LATEST_SUBSTENCIAL_MODERNISATION]:
    'pages.financingNeed.sections.building.fields.latestSubstencialModernisation.caption',
  [BUILDING_STREET_AND_HOUSE_NUMBER]:
    'pages.financingNeed.sections.building.fields.buildingStreetAndHouse.caption',
  [BUILDING_POSTAL_CODE]: 'pages.financingNeed.sections.building.fields.postalCode.caption',
  [BUILDING_PLACE]: 'pages.financingNeed.sections.building.fields.place.caption',
  [BUILDING_PURCHASE_PRICE]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.purchasePrice.caption',
  [BUILDING_PLOT_PRICE]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.plotPrice.caption',
  [BUILDING_ADDITIONAL_COSTS]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.additionalCosts.caption',
  [BUILDING_CONSTRUCTION_MANUFACTURE_COST]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.constructionManufactureCost.caption',
  [BUILDING_MODERNISATION_COSTS]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.modernisationCosts.caption',
  [BUILDING_DEBT_REDEMPTION]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.debtRedemption.caption',
  [BUILDING_USE_OF_FUNDS_SUM]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.useOfFundsSum.caption',
  [BUILDING_EQUITY]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.buildingEquity.caption',
  [BUILDING_PERSONAL_CONTRIBUTION]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.personalContribution.caption',
  [BUILDING_PROPERTY]: 'pages.financingNeed.sections.buildingFinancingPlan.fields.property.caption',
  [BUILDING_BORROWED_CAPITAL]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.borrowedCaptial.caption',
  [BUILDING_DEBT_CAPITAL_OTHER]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.debtCapitalOther.caption',
  [BUILDING_SOURCE_OF_FUNDS_SUM]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.sourceOfFundsSum.caption',
  [BANK_DETAILS_IBAN]: 'pages.companyDetails.sections.bankDetails.fields.iban.caption',
  [CLIENT_ROLE]: 'inquiryType.energyEfficiency.steps.purpose.sections.clientRole.caption',
  [MACHINE_CUSTOM_CATEGORY]:
    'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails.fields.categories.caption',
  [GUARANTY_DESCRIPTION]:
    'pages.financingNeed.sections.other.fields.otherPurposeDescription.caption',
  [GUARANTY_TYPE]: 'pages.financingNeed.sections.guaranty.sections.guarantyTypeLabel',
  [GUARANTY_OTHER_TYPE]: 'pages.financingNeed.sections.guaranty.sections.otherType',
  [OFFICE_EQUIPMENT_PRICE]:
    'pages.financingNeed.sections.officeEqiupment.fields.purchasePrice.caption',
  [OFFICE_EQUIPMENT_DESCRIPTION]:
    'pages.financingNeed.sections.purpose.fields.purposeKind.options.officeEquipmentDescription',
  [PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.usageSpaceNotResidentialSum.caption',
  [PROJECT_USAGE_SPACE_SUM_RESIDENTIAL]:
    'pages.financingNeed.sections.buildingFinancingPlan.fields.usageSpaceResidentialSum.caption',
};

export const bfsServiceFinancingNeedLabelsMap: { [key: string]: string } = {
  [BFS_SERVICE__PURPOSE_KIND]: 'pages.bfsService.sections.industry.title',
  [BFS_SERVICE__COMPANY_OTHER_INDUSTRY]:
    'pages.bfsService.sections.factoringNeeds.fields.companyIndustry.caption',
  [BFS_SERVICE__MONTHLY_TURNOVER]:
    'pages.bfsService.sections.factoringAndFactomatNeeds.fields.monthlyTurnover.caption',
  [BFS_SERVICE__FIRST_INVOICE_PURCHASE_DATE]:
    'pages.bfsService.sections.factoringAndFactomatNeeds.fields.firstInvoicePurchaseDate.caption',
  [BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING]:
    'pages.bfsService.sections.factoringAndFactomatNeeds.fields.softwareBillingIndustry.caption',
  [BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING]:
    'pages.bfsService.sections.factoringAndFactomatNeeds.fields.otherSoftwareBillingIndustry.caption',
  [BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON]:
    'pages.bfsService.sections.factoringAndFactomatNeeds.fields.purchasedInvoicesPaidOn.caption',
  [PRE_FINANCING_PERIOD]:
    'pages.bfsService.sections.factomatNeeds.fields.preFinancingPeriod.caption',
  [PAYMENT_TERM]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.paymentTerm.caption',
  [BFS_SERVICE__USE_FACTORING_AS_FINANCING]:
    'pages.bfsService.sections.factomatBusinessSection.fields.useFactoringAsFinancing.caption',
  [BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER]:
    'pages.bfsService.sections.factomatBusinessSection.fields.factoringProviderCustomer.caption',
  [BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES]:
    'pages.bfsService.sections.factomatBusinessSection.fields.alreadyAssignedReceivables.caption',
  [PURPOSE_KIND_BFS]: 'pages.bfsService.sections.industry.title',
  [BUSINESS_SPECIFICS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.other.fields.businessSpecifics.caption',
  [BFS_SERVICE__NEED_FURTHER_ADVICE]:
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.needFurtherAdvice.caption',
  [BFS_SERVICE__EXPLICIT_NEED_FURTHER_ADVICE]:
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.explicitNeedFurtherAdvice.caption',
  [BFS_SERVICE__FACTORING_OFFER]: 'pages.bfsService.sections.notes.fields.factoringOffer.caption',
  [BFS_SERVICE__SOURCE]: 'pages.bfsService.sections.notes.fields.bfsServiceSource.caption',
  [SPECIAL_FEATURES]: 'pages.bfsService.sections.factoringNeeds.fields.specialFeatures.caption',
};
