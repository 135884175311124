import _get from 'lodash/get';

import { PartnerCopy, PartnerDetails, PartnerMeta } from './types';

export class PartnerMapper {
  constructor(public payload: any) {}

  public resolveDetails(): Required<PartnerDetails> {
    return {
      id: _get(this.payload, 'id'),
      fullName: _get(this.payload, ['attributes', 'full-name']),
      contactPerson: _get(this.payload, ['attributes', 'contact-person']),
      phoneNumber: _get(this.payload, ['attributes', 'phone-number']),
      city: _get(this.payload, ['attributes', 'city']),
      postalCode: _get(this.payload, ['attributes', 'postal-code']),
      street: _get(this.payload, ['attributes', 'street']),
      email: _get(this.payload, ['attributes', 'email']),
    };
  }

  public resolveMeta(): Required<PartnerMeta> {
    return {
      bankCodes: _get(this.payload, ['attributes', 'bank-codes']),
      chatToken: _get(this.payload, ['attributes', 'chat-token']),
      widgetId: _get(this.payload, ['attributes', 'widget-id']),
      gtmId: _get(this.payload, ['attributes', 'gtm-id']),
      webtrekkId: _get(this.payload, ['attributes', 'webtrekk-id']),
      etrackerId: _get(this.payload, ['attributes', 'etracker-id']),
      webtrekkDomain: _get(this.payload, ['attributes', 'webtrekk-domain']),
      internalRatings: _get(this.payload, ['attributes', 'internal-ratings']),
      pageTitle: _get(this.payload, ['attributes', 'page-title']),
      logoUrl: _get(this.payload, ['attributes', 'logo-url']),
      faviconUrl: _get(this.payload, ['attributes', 'favicon-url']),
      excludingQuestionCount: _get(this.payload, ['attributes', 'excluding-questions-count']),
      color: _get(this.payload, ['attributes', 'base-color']),
      language: _get(this.payload, ['attributes', 'language']),
      translation: _get(this.payload, ['attributes', 'translation']),
      enableTranslation: _get(this.payload, ['attributes', 'enable-translation']),
      creditReformProducts: _get(this.payload, ['attributes', 'creditreform-products']),
    };
  }

  public resolveCopy(): Required<PartnerCopy> {
    return {
      copyright: _get(this.payload, ['attributes', 'copy-copyright']),
      dataProtection: _get(this.payload, ['attributes', 'copy-data-protection']),
      imprint: _get(this.payload, ['attributes', 'copy-imprint']),
      termsAndConditions: _get(this.payload, ['attributes', 'copy-terms-and-conditions']),
    };
  }
}
