import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { canShowSection, validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  VEHICLE_COUNT,
  VEHICLE_DESCRIPTION,
  VEHICLE_KIND,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_NEW,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_YEAR,
  VEHICLE_YEARLY_MILEAGE,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import VehicleKind from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CommercialVehicle/VehicleKind/VehicleKind';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateVehicleSection = validateSectionFields([
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_COUNT,
  VEHICLE_DESCRIPTION,
  VEHICLE_NEW,
  VEHICLE_KIND,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_YEAR,
]);

const CommercialVehicle = () => {
  const t = useTranslations();
  const sectionNumber = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dvag]: 2,
    default: 3,
  });

  return (
    <FormSection
      name={FormSections.vehicle}
      sectionNumber={sectionNumber}
      title={t('pages.financingNeed.sections.purpose.fields.purposeKind.options.vehicle')}
    >
      <Condition
        condition={canShowSection(VEHICLE_PURCHASE_PRICE, VEHICLE_COUNT)}
        isBusinessCondition
      >
        <FormRow separator>
          <AmountWithField name={VEHICLE_PURCHASE_PRICE} />
          <InputWithField name={VEHICLE_COUNT} type="number" separateThousands />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(VEHICLE_DESCRIPTION)} isBusinessCondition>
        <FormRow>
          <TextAreaWithField
            name={VEHICLE_DESCRIPTION}
            placeholder={t(
              'pages.financingNeed.sections.commercialVehicle.fields.vehicleDescription.placeholder',
            )}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(VEHICLE_NEW, VEHICLE_YEAR)} isBusinessCondition>
        <FormRow separator>
          <YesNoRadioGroupWithField name={VEHICLE_NEW} />
          <InputWithField type="number" separateThousands={false} name={VEHICLE_YEAR} />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(VEHICLE_KIND)} isBusinessCondition>
        <FormRow separator>
          <VehicleKind />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(VEHICLE_OTHER_DESCRIPTION)} isBusinessCondition>
        <FormRow separator>
          <TextAreaWithField name={VEHICLE_OTHER_DESCRIPTION} />
        </FormRow>
      </Condition>
      <Condition
        condition={canShowSection(VEHICLE_MANUFACTURER, VEHICLE_MODEL)}
        isBusinessCondition
      >
        <FormRow separator>
          <InputWithField
            name={VEHICLE_MANUFACTURER}
            placeholder={t(
              'pages.financingNeed.sections.commercialVehicle.fields.vehicleManufacturer.placeholder',
            )}
          />
          <InputWithField
            name={VEHICLE_MODEL}
            placeholder={t(
              'pages.financingNeed.sections.commercialVehicle.fields.vehicleModel.placeholder',
            )}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(VEHICLE_YEARLY_MILEAGE)} isBusinessCondition>
        <FormRow>
          <InputWithField name={VEHICLE_YEARLY_MILEAGE} separateThousands type="number" />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default CommercialVehicle;
