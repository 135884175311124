import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import Condition from 'modules/ConditionalSubform/Condition';
import { not } from 'modules/Inquiry/Form/formConditions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import LeasingIcon from 'theme/components/Icon/ProductKind/LeasingIcon';

import LeasingAdvancePayment from './fields/LeasingAdvancePayment';
import LeasingTermInMonths from './fields/LeasingTermInMonths';

const LeasingSummary = ({ hideAdvancePayment = false }) => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<LeasingIcon />} />
      </FormRow>
      <FormRow separator>
        <LeasingAdvancePayment />
        <LeasingTermInMonths />
      </FormRow>
    </>
  );
};

const DvagLeasingSummary = () => {
  return (
    <>
      <Condition
        condition={not(fieldBusinessConditions.hasCalculatedAmountField)}
        isBusinessCondition
      >
        <LeasingSummary />
      </Condition>
      <Condition condition={fieldBusinessConditions.hasCalculatedAmountField} isBusinessCondition>
        <FormRow separator>
          <LeasingTermInMonths />
        </FormRow>
      </Condition>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagLeasingSummary,
  default: LeasingSummary,
});
