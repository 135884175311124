import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import forwardToCustomerAction from 'api/CompeonReverseApi/operation/forwardToCustomer/actions';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../Section';
import Button from '../Section/Button';

const ForwardToCompeonWithConfirmation = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.forwardToCompeon');
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const dispatch = useDispatch();

  const forward = async () => {
    makeCall(forwardToCustomerAction(inquiryId), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t('messageAfterAction') });
    });
  };

  const button = <Button isLoading={isPending} onClick={onOpen} label={t('buttonLabel')} />;

  return (
    <>
      <Section
        title={t('title')}
        hasError={Boolean(error)}
        button={button}
        description={t('description')}
      />

      <AlertDialogComponent
        cancelText={t('modal.cancel')}
        confirmText={t('modal.confirm')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={forward}
        title={t('modal.header')}
      >
        {t('modal.description')}
      </AlertDialogComponent>
    </>
  );
};

export default ForwardToCompeonWithConfirmation;
