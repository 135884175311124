import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  INQUIRY_STATUSES,
  INQUIRY_SUBSTATUSES,
  useInquiryStatusTranslator,
} from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { formatDateDays, formatDateTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice, formatPriceForFactoringLine } from 'utils/valueFormats';

import { translations } from '../../../new/form/common/types';

export const useInquiryTableHeaders = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const translateHeader = useTranslations('pages.inquiryList.table.headers');
  const translateProduct = useTranslations('data.financingProduct');
  const translateRole = useTranslations('data.financingRole');
  const translateMittweidaRole = useTranslations(
    translations.inquiryType.mittweida.pages.financingNeed.sections.role.fields.projectFinancingRole
      .options.sectionString,
  );
  const translatePurpose = useTranslations('data.purposeKind');
  const translateStatus = useInquiryStatusTranslator();

  const notAvailable = t('other.notAvailable');
  const printProcessorData = (processor: { firstName: string; lastName: string }) =>
    `${processor.firstName} ${processor.lastName}`;

  const defaultHeaders = {
    [INQUIRY_TABLE_HEADERS.ID]: {
      key: INQUIRY_TABLE_HEADERS.ID,
      text: translateHeader('id'),
      renderRow: (id: string) => (
        <span title={id} data-testid="operation-inquiry-row-id">
          {id.split('-')[0]}
        </span>
      ),
    },
    [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
      key: INQUIRY_TABLE_HEADERS.CREATED_AT,
      text: translateHeader('createdAt'),
      renderRow: (createdDate: Date) => (
        <p>
          {formatDateDays(createdDate)}
          <br />
          {formatDateTime(createdDate)}
        </p>
      ),
    },
    [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
      key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
      text: translateHeader('companySpecialized'),
      renderRow: (companyName: string) => companyName || notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER]: {
      key: INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER,
      text:
        selectedInquiryType === InquiryType.bfsService
          ? translateHeader('processor')
          : t('pages.inquiryList.table.headers.inquiryManager'),
      renderRow: (inquiryManagerData: { firstName: string; lastName: string }) =>
        inquiryManagerData
          ? `${inquiryManagerData.firstName} ${inquiryManagerData.lastName}`
          : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.BANK_ADVISOR]: {
      key: INQUIRY_TABLE_HEADERS.BANK_ADVISOR,
      text: translateHeader('processor'),
      renderRow: (bankAdvisorData: { firstName: string; lastName: string }) =>
        bankAdvisorData ? `${bankAdvisorData.firstName} ${bankAdvisorData.lastName}` : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: {
      key: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
      text: translateHeader('usage'),
      renderRow: (purpose: { firstName: string; lastName: string }) =>
        purpose ? translatePurpose(purpose) : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: {
      key: INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
      text: translateHeader('financingProduct'),
      renderRow: (product: string) => (product ? translateProduct(product) : notAvailable),
    },
    [INQUIRY_TABLE_HEADERS.FACTORING_LINIE]: {
      key: INQUIRY_TABLE_HEADERS.FACTORING_LINIE,
      text: translateHeader('factoringLinie'),
      renderRow: (res: { line: string; factoringLine: string }) =>
        res.line === InquiryLane.contract
          ? formatPriceForFactoringLine(res.factoringLine, true) ?? notAvailable
          : res.factoringLine
          ? formatPriceForFactoringLine(res.factoringLine, true)
          : t('data.financingProduct.factoringLinie.lead'),
    },
    [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: {
      key: INQUIRY_TABLE_HEADERS.FINANCING_NEED,
      text: translateHeader('financingNeed'),
      renderRow: (amount: number) =>
        amount ? <strong>{formatPrice(amount)}</strong> : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
      key: INQUIRY_TABLE_HEADERS.PROCESSOR,
      text: translateHeader('processor'),
      renderRow: (processor: { firstName: string; lastName: string }) =>
        processor ? printProcessorData(processor) : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.STATUS]:
      selectedInquiryType === InquiryType.bfsService
        ? {
            key: INQUIRY_TABLE_HEADERS.STATUS,
            text: translateHeader('status'),
            renderRow: (status: INQUIRY_STATUSES) => translateStatus(status),
          }
        : {
            key: INQUIRY_TABLE_HEADERS.STATUS,
            text: translateHeader('status'),
            renderRow: (status: INQUIRY_STATUSES) => translateStatus(status),
          },
    [INQUIRY_TABLE_HEADERS.SUB_STATUS]: {
      key: INQUIRY_TABLE_HEADERS.SUB_STATUS,
      text: translateHeader('status'),
      renderRow: (status: INQUIRY_SUBSTATUSES) => translateStatus(status),
    },
    [INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT]: {
      key: INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT,
      text: translateHeader('expectedGrantAmount'),
      renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS]: {
      key: INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS,
      text: translateHeader('expectedEligibleCosts'),
      renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE]:
      selectedInquiryType === InquiryType.leaseplan
        ? {
            key: INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
            text: translateHeader('externalReference'),
            renderRow: (externalReference: string) => externalReference ?? notAvailable,
          }
        : {
            key: INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
            text: translateHeader('externalReference'),
            renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
          },

    [INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER,
      text: translateHeader('vehicleManufacturer'),
      renderRow: (vehicleManufacturer: string) => vehicleManufacturer ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.VEHICLE_KIND]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_KIND,
      text: translateHeader('vehicleKind'),
      renderRow: (vehicleKind: string) => vehicleKind ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE,
      text: translateHeader('vehicleDriveType'),
      renderRow: (vehicleDriveType: string) => vehicleDriveType ?? notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER]: {
      key: INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER,
      text: translateHeader('contractNumber'),
      renderRow: (contractNumber: string) => <b>{contractNumber}</b>,
    },
    [INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION]: {
      key: INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION,
      text: translateHeader('objectDescription'),
      renderRow: (objectDescription: string) => objectDescription,
    },
    [INQUIRY_TABLE_HEADERS.OBJECT_VALUE]: {
      key: INQUIRY_TABLE_HEADERS.OBJECT_VALUE,
      text: translateHeader('objectValue'),
      renderRow: (objectValue: number) => formatPrice(objectValue),
    },
    [INQUIRY_TABLE_HEADERS.SUPPLIER]: {
      key: INQUIRY_TABLE_HEADERS.SUPPLIER,
      text: translateHeader('supplier'),
      renderRow: (supplier: string) => supplier,
    },
    [INQUIRY_TABLE_HEADERS.MEDIATOR]: {
      key: INQUIRY_TABLE_HEADERS.MEDIATOR,
      text: translateHeader('mediator'),
      renderRow: (mediator: string) => mediator,
    },
    [INQUIRY_TABLE_HEADERS.POSTAL_CODE_AREA]: {
      key: INQUIRY_TABLE_HEADERS.POSTAL_CODE_AREA,
      text: translateHeader('postalCodeArea'),
      renderRow: (postalCodeArea: string) => postalCodeArea,
    },
  };
  const headersByType: Record<string, any> = {
    [InquiryType.leaseplan]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('onboardingId'),
        renderRow: (id: string) => (
          <span title={id} data-testid="operation-inquiry-row-id">
            {id.split('-')[0]}
          </span>
        ),
      },
    },
    [InquiryType.profiRLL]: {
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('id'),
        renderRow: (id: string) => (
          <span title={id} data-testid="operation-inquiry-row-id">
            {id.split('-')[0]}
          </span>
        ),
      },
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
        key: INQUIRY_TABLE_HEADERS.CREATED_AT,
        text: translateHeader('createdAt'),
        renderRow: (createdDate: Date) => (
          <p>
            {formatDateDays(createdDate)}
            <br />
            {formatDateTime(createdDate)}
          </p>
        ),
      },
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
        key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
        text: translateHeader('companySpecialized'),
        renderRow: (companyName: string) => companyName || notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: {
        key: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
        text: translateHeader('usage'),
        renderRow: (purpose: { firstName: string; lastName: string }) =>
          purpose ? translatePurpose(purpose) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: {
        key: INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
        text: translateHeader('financingRole'),
        renderRow: (role: string) => (role ? translateRole(role) : notAvailable),
      },
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
        key: INQUIRY_TABLE_HEADERS.PROCESSOR,
        text: translateHeader('processor'),
        renderRow: (processor: { firstName: string; lastName: string }) =>
          processor ? printProcessorData(processor) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: {
        key: INQUIRY_TABLE_HEADERS.SUB_STATUS,
        text: translateHeader('status'),
        renderRow: (status: INQUIRY_SUBSTATUSES) => translateStatus(status),
      },
    },
    [InquiryType.profiMittweida]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: {
        key: INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
        text: translateHeader('financingRole'),
        renderRow: (role: string) => {
          return role ? translateMittweidaRole(role) : notAvailable;
        },
      },
    },
    [InquiryType.dvag]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.VB_NAME]: {
        key: INQUIRY_TABLE_HEADERS.VB_NAME,
        text: translateHeader('vbName'),
        renderRow: (vb: { firstName: string; lastName: string }) =>
          vb ? `${vb.firstName} ${vb.lastName}` : notAvailable,
      },
    },
    [InquiryType.mmv]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.ID]: {
        ...defaultHeaders[INQUIRY_TABLE_HEADERS.ID],
        text: translateHeader('process'),
      },
    },
    default: defaultHeaders,
  };

  return headersByType[selectedInquiryType] || headersByType.default;
};
