import _get from 'lodash/get';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import mapQuickCheckFromApi from 'modules/QuickCheck/mapQuickCheckFromApi';
import { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

const getQuickCheckFormValuesAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.PREFILL_QUICK_CHECK_DETAILS.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.GET,
  });

const loadQuickCheckFormValues = async (makeCall: IMakeCall, inquiryId: string) => {
  const { payload, error } = await makeCall(getQuickCheckFormValuesAction(inquiryId));
  const data = _get(payload, 'data');
  const formValues = mapQuickCheckFromApi(data);
  return { error, formValues };
};

export default loadQuickCheckFormValues;
