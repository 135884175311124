import { textStyle } from '../../../themeConstants';

const Alert = {
  parts: ['container', 'title', 'description'],

  baseStyle: {
    container: {
      alignItems: 'top',
      border: '1px',
      borderRadius: 'default',
      p: 4,
    },
    title: {
      marginRight: 0,
      ...textStyle.sizeM,
    },
    description: {
      display: 'block',
      ...textStyle.sizeM,
    },
  },
};

export default Alert;
