import styled from 'styled-components/macro';

import Text from 'components/Text/Text';

const Optional = styled(Text)`
  margin-bottom: 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

export default Optional;
