import React from 'react';

import { useSelector } from 'react-redux';

import { getMarketDataFilter } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { HeadingComponent } from 'theme/components/Heading';
import { useTranslations } from 'utils/hooks/useTranslations';

import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { getProfitabilityDataRiskBoxes } from '../../store/selectors';
import { useUnitFormatter } from '../../utils';

interface RiskBoxItem {
  remaining_risk_sqm: string;
  remaining_risk_percent: string;
  profit_risk_ratio_after_operating_costs: string;
  profit_risk_ratio_before_operating_costs: string;
}

export interface RiskBoxes {
  current: RiskBoxItem;
  future: RiskBoxItem;
}

export const Risk = () => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.boxes.risk',
  );
  const formatter = useUnitFormatter();
  const riskBoxes = useSelector(getProfitabilityDataRiskBoxes);
  const { usageType } = useSelector(getMarketDataFilter);

  return (
    <>
      <HeadingComponent size="sm" color="text.tertiary">
        {t('title')}
      </HeadingComponent>
      <SummaryCards>
        <SummaryCard
          caption={t('remainingRiskSqm')}
          value={formatter.currency(riskBoxes[usageType]?.remaining_risk_sqm)}
        />
        <SummaryCard
          caption={t('remainingRiskPercent')}
          value={formatter.percent(riskBoxes[usageType]?.remaining_risk_percent)}
        />
        <SummaryCard
          caption={t('profitRiskRatioBeforeOperatingCosts')}
          value={formatter.percent(riskBoxes[usageType]?.profit_risk_ratio_before_operating_costs)}
        />
        <SummaryCard
          caption={t('profitRiskRatioAfterOperatingCosts')}
          value={formatter.percent(riskBoxes[usageType]?.profit_risk_ratio_after_operating_costs)}
        />
      </SummaryCards>
    </>
  );
};
