import _get from 'lodash/get';

import {
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const mapFinancingParametersFromApi = (response: unknown) => {
  const rentSection = _get(response, 'rent');
  const unitSection = _get(response, 'unit');
  const buildingSection = _get(response, 'building');
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const financingParameterValues = {
    [PROFITABILITY_FINANCING_DURATION_RENT]:
      _get(rentSection, 'profitability_financing_duration') || initialValue,
    [PROFITABILITY_INTEREST_RATE_RENT]:
      _get(rentSection, 'profitability_interest_rate') || initialValue,
    [PROFITABILITY_INTEREST_RATE_RENT_SHORT]:
      _get(rentSection, 'profitability_interest_rate_rent_short') || initialValue,
    [PROFITABILITY_REPAYMENT_RATE_RENT]:
      _get(rentSection, 'profitability_repayment_rate') || initialValue,
    [PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT]:
      _get(rentSection, 'profitability_financing_fee') || initialValue,
    [PROFITABILITY_FINANCING_FEE_UNIT]:
      _get(unitSection, 'profitability_financing_fee') || initialValue,
    [PROFITABILITY_INTEREST_RATE_UNIT]:
      _get(unitSection, 'profitability_interest_rate') || initialValue,
    [PROFITABILITY_FINANCING_DURATION_UNIT]:
      _get(unitSection, 'profitability_financing_duration') || initialValue,
    [PROFITABILITY_INTEREST_RATE_BUILDING]:
      _get(buildingSection, 'profitability_interest_rate') || initialValue,
    [PROFITABILITY_FINANCING_FEE_BUILDING]:
      _get(buildingSection, 'profitability_financing_fee') || initialValue,
    [PROFITABILITY_FINANCING_DURATION_BUILDING]:
      _get(buildingSection, 'profitability_financing_duration') || initialValue,
  };

  return financingParameterValues;
};

export default mapFinancingParametersFromApi;
