import { useCallback } from 'react';

import { ConfigController } from 'config/ConfigController';
import { usePartnerConfig } from 'config/partner/hooks';
import { LANGUAGE_TYPE } from 'config/partner/types';
import { saveObjectToSessionStorage } from 'utils/sessionStorage/helpers';
import { SELECTED_LANGUAGE } from 'utils/sessionStorage/keys';

export const useHasMultipleTranslations = () => {
  const {
    meta: { translation = {}, enableTranslation },
  } = usePartnerConfig();

  return enableTranslation ? Object.keys(translation as object).length > 1 : enableTranslation;
};

const useLanguagePreferences = () => {
  const {
    meta: { language },
  } = usePartnerConfig();

  const hasMultipleTranslations = useHasMultipleTranslations();

  let browserDefaultLanguage = window.navigator.language.toLowerCase();
  if (language) {
    browserDefaultLanguage = language;
  }
  const isLanguageEnglish =
    hasMultipleTranslations && browserDefaultLanguage !== LANGUAGE_TYPE.GERMAN;

  const selectedLanguage: LANGUAGE_TYPE = isLanguageEnglish
    ? LANGUAGE_TYPE.ENGLISH
    : LANGUAGE_TYPE.GERMAN;

  const saveLanguagePreference = useCallback(
    (language: string) => {
      if (hasMultipleTranslations) {
        ConfigController.partner.save({
          details: {},
          meta: { language },
          copy: {},
        });
        saveObjectToSessionStorage(SELECTED_LANGUAGE, language);
      }
    },
    [hasMultipleTranslations],
  );

  return { selectedLanguage, saveLanguagePreference };
};

export default useLanguagePreferences;
