import useLanguagePreferences from '../../components/App/LanguageProvider/useLanguagePreferences';
import { useFetchDataListItems } from '../../pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/helper/useFetchDataListItems';

export const useGetMappedPartnerOptions = (dataListName: string) => {
  const { items } = useFetchDataListItems(dataListName);
  const { selectedLanguage } = useLanguagePreferences();

  return items.map((obj) => ({
    value: obj.value.lrnr,
    label: selectedLanguage === 'de' ? obj.value.labelDe : obj.value.labelEn,
  }));
};
