import styled from 'styled-components/macro';

import { transition } from 'theme/themeConstants';

const LinkButton = styled.button`
  text-transform: uppercase;
  color: ${(props) =>
    props.highlighted ? props.theme.colors.primary : props.theme.colors.text.tertiary};
  transition: color ${transition.default};

  :focus,
  :hover {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default LinkButton;
