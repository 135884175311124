import React from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { useLocation, Link } from 'react-router-dom';

import { InputWithField } from 'components/Input';
import { PasswordInputWithField } from 'components/PasswordInput';
import { ForgotPasswordLink } from 'modules/Login/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import ThemeLoginForm from 'theme/components/LoginForm';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { EMAIL_FIELD, PASSWORD_FIELD } from '../Inquiry/Form/formFields';

export interface ILoginFormValues {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

interface ILoginForm {
  onSubmit: (formValues: ILoginFormValues) => void;
  isLoading: boolean;
  forgotPasswordLink: string;
}

interface ILocationState {
  email?: string;
}

const LoginForm = ({ onSubmit, isLoading, forgotPasswordLink }: ILoginForm) => {
  const { required, isEmail } = useFieldValidators();
  const { state } = useLocation<ILocationState>();
  const initialValues: ILoginFormValues = { email: state?.email || '', password: '' };
  const t = useTranslations();

  function handleFormSubmit(values: ILoginFormValues) {
    onSubmit(values);
  }

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ThemeLoginForm
            login={
              <InputWithField
                name={EMAIL_FIELD}
                preventEnterSubmit={false}
                validate={combineValidators(required, isEmail)}
                caption={t('pages.loginPage.fields.email.caption')}
              />
            }
            password={
              <PasswordInputWithField
                name={PASSWORD_FIELD}
                preventEnterSubmit={false}
                validate={required}
                caption={t('pages.loginPage.fields.password.caption')}
                autoFocus={initialValues.email}
              />
            }
            forgotPassword={
              <ForgotPasswordLink as={Link} to={forgotPasswordLink}>
                {t('pages.loginPage.fields.password.forgotPassword')}
              </ForgotPasswordLink>
            }
            actions={
              <ButtonComponent
                data-testid="login-button"
                isLoading={isLoading}
                leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
                type="submit"
              >
                {t('pages.companyDetails.sections.contactPerson.logInModal.buttons.logIn')}
              </ButtonComponent>
            }
          />
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  forgotPasswordLink: PropTypes.string.isRequired,
};

export default LoginForm;
