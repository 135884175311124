import React from 'react';

import de from 'date-fns/locale/de';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import ControlWrapper from 'components/ControlWrapper';
import InputContainer from 'components/Input/InputWrapper/InputContainer';
import SymbolWrapper from 'components/SideSymbol/SymbolWrapper';
import { usePartnerConfig } from 'config/partner/hooks';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { convertLocalToUTCDate, convertUTCToLocalDate } from 'utils/helpers';
import { useTranslations } from 'utils/hooks/useTranslations';
import { datepickerFormat, monthFormat, yearFormat } from 'utils/valueFormats';

import { ClearButton, ClearButtonWrapper } from './ClearButton';
import DatePickerWrapper from './DatePickerWrapper';

const sideSymbol = () => <CalendarIcon boxSize={6} />;

interface DatePickerProps {
  selected?: Date;
  caption: string;
  name: string;
  placeholder: string;
  errorMessage: string;
  value: string;
  onChange: (date: Date | undefined) => void;
  optional: boolean;
  tooltip?: string;
  isClearable: boolean;
  minDate?: Date;
  maxDate?: Date;
  includeDates?: any;
}

const DatePickerBase = ({
  caption,
  selected,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  ...rest
}: DatePickerProps & Omit<ReactDatePickerProps, 'onChange'>) => {
  const {
    meta: { language },
  } = usePartnerConfig();
  const t = useTranslations();

  registerLocale('de', de);
  if (language) {
    setDefaultLocale(language);
  }

  const handleChange = (date: Date) => {
    onChange(convertLocalToUTCDate(date));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      onChange(undefined);
    }
  };

  const placeholderText: string = placeholder ? placeholder : t('placeholders.pleaseChoose');

  return (
    <ControlWrapper
      caption={caption}
      optional={optional}
      errorMessage={errorMessage}
      tooltip={tooltip}
      selected={selected}
    >
      <InputContainer data-testid={name}>
        <DatePickerWrapper>
          <DatePickerComponent
            selected={value ? convertUTCToLocalDate(value) : selected}
            placeholderText={placeholderText}
            onChange={handleChange}
            locale={language}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            startDate={minDate ?? null}
            endDate={maxDate ?? null}
            minDate={minDate ?? null}
            maxDate={maxDate ?? null}
            {...rest}
          >
            {isClearable && value && (
              <ClearButtonWrapper>
                <ClearButton onClick={() => onChange(undefined)}>
                  <DeleteIcon boxSize={6} mr={1} />
                  {t('pages.inquiryList.filtersDrawer.buttons.clear')}
                </ClearButton>
              </ClearButtonWrapper>
            )}
          </DatePickerComponent>
        </DatePickerWrapper>
        <SymbolWrapper>{sideSymbol()}</SymbolWrapper>
      </InputContainer>
    </ControlWrapper>
  );
};

export const DayPickerUnwrapped = ({
  selected,
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  includeDates,
}: DatePickerProps) => (
  <DatePickerBase
    selected={selected}
    caption={caption}
    name={name}
    placeholder={placeholder}
    errorMessage={errorMessage}
    value={value}
    onChange={onChange}
    optional={optional}
    tooltip={tooltip}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={datepickerFormat}
    includeDates={includeDates}
  />
);

export const MonthPickerUnwrapped = ({
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
}: DatePickerProps) => (
  <DatePickerBase
    onChange={onChange}
    caption={caption}
    placeholder={placeholder}
    errorMessage={errorMessage}
    optional={optional}
    tooltip={tooltip}
    name={name}
    value={value}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={monthFormat}
    showMonthYearPicker
    showFullMonthYearPicker
  />
);

export const YearPickerUnwrapped = ({
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
}: DatePickerProps) => (
  <DatePickerBase
    onChange={onChange}
    caption={caption}
    placeholder={placeholder}
    errorMessage={errorMessage}
    optional={optional}
    tooltip={tooltip}
    name={name}
    value={value}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={yearFormat}
    showYearPicker
    yearItemNumber={8}
  />
);

const DayPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <DayPickerUnwrapped {...input} {...meta} {...rest} />
);

const MonthPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <MonthPickerUnwrapped {...input} {...meta} {...rest} />
);

const YearPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <YearPickerUnwrapped {...input} {...meta} {...rest} />
);

DayPickerAdapter.propTypes = FinalFormFieldPropTypes;
MonthPickerAdapter.propTypes = FinalFormFieldPropTypes;
YearPickerAdapter.propTypes = FinalFormFieldPropTypes;

export const DayPickerWithField = withFormField(DayPickerAdapter);
export const MonthPickerWithField = withFormField(MonthPickerAdapter);
export const YearPickerWithField = withFormField(YearPickerAdapter);
