import React, { useEffect } from 'react';

import arrayMutators from 'final-form-arrays';
import _isEmpty from 'lodash/isEmpty';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import {
  persistDecorator,
  useGetInitialValuesForCreateMode,
  useGetInitialValuesForEditMode,
} from 'new/form/persist';
import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import { InquiryMode } from 'new/form/state/types';
import { SpecializedFinalPage } from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage';

import { withCanEditInquiryGuard } from '../../modules/InquiryEdit/WithCanEditInquiryGuard';
import { translations } from '../../new/form/common/types';
import { NewProgressBar } from '../../new/form/progress';
import {
  createSpecializedInquiryAction,
  updateSpecializedInquiryAction,
} from '../../pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { useToasts } from '../../shared/hooks/useToasts';
import { getInquiryIdSelector } from '../../store/inquiryDetails/selectors';
import { isLoggedInSelector } from '../../store/user/selectors';
import useDispatchApiCall, { dispatchOptions } from '../../utils/hooks/useDispatchApiCallHook';
import { useTranslations } from '../../utils/hooks/useTranslations';
import { MittweidaFieldTypes } from './fields';
import { MittweidaCompanyStep } from './steps/company';
import { MittweidaFinancingNeedStep } from './steps/financingNeed';
import { MittweidaFundingDetailsStep } from './steps/fundingDetails';
import { MittweidaSummaryStep } from './steps/summary';
import { MittweidaUserProfileStep } from './steps/userProfile';
import { MittweidaStaticUserProfileStep } from './steps/userProfile/MittweidaStaticUserProfileStep';

export const MITTWEIDA_FORM = 'MITTWEIDA_FORM';

/**
 * In react-final-form array fields are not treated as empty arrays [] but as an array with an empty object [{}].
 * Thus the length of the array is always at least 1 and should never be 0, so we make the check for the length and if the
 * first object is empty as well.
 * @param values
 */
function isArrayFieldEmpty(values: Array<any>) {
  if (values.length === 0) {
    return true;
  }

  if (_isEmpty(values[0])) {
    return true;
  }

  return false;
}

function mapCurrentUsage(values: MittweidaFieldTypes) {
  const { currentUsage } = values.financingNeedPage.objectInformationSection;
  const isCurrentUsageEmpty = isArrayFieldEmpty(currentUsage);

  if (isCurrentUsageEmpty) {
    return {
      project_financing_object_usages_current: [],
    };
  }

  return {
    project_financing_object_usages_current: currentUsage.map((usage) => {
      return {
        type_of_use: usage.typeOfUse,
        usage_space: usage.usageSpace,
        number_of_units: usage.numberOfUnits,
      };
    }),
  };
}

function mapFutureUsage(values: MittweidaFieldTypes) {
  const { futureUsage } = values.financingNeedPage.objectInformationSection;
  const isFutureUsageEmpty = isArrayFieldEmpty(futureUsage);

  if (isFutureUsageEmpty) {
    return {
      project_financing_object_usages_future: [],
    };
  }

  return {
    project_financing_object_usages_future: futureUsage.map((usage) => {
      return {
        type_of_use: usage.typeOfUse,
        usage_space: usage.usageSpace,
        number_of_units: usage.numberOfUnits,
        planned_sales_price: usage.plannedSalesPrice,
      };
    }),
  };
}

function mapFinancingNeedData(values: MittweidaFieldTypes) {
  const futureUsage = mapFutureUsage(values);
  const currentUsage = mapCurrentUsage(values);

  const {
    roleSection,
    objectInformationSection,
    otherProjectInformation,
    projectCompositionSection,
  } = values.financingNeedPage;

  return {
    amount: projectCompositionSection.financingAmount,
    project_financing_role: roleSection.projectFinancingRole,
    project_financing_total_investment: projectCompositionSection.totalInvestment,
    project_financing_own_funds: projectCompositionSection.ownFunds,
    project_financing_mezzanine_capital: projectCompositionSection.mezzanineCapital,
    project_financing_subordinated_capital: projectCompositionSection.subordinatedCapital,
    project_financing_own_work: projectCompositionSection.ownWork,
    project_financing_total_investment_share_in_buying_phase:
      projectCompositionSection.totalInvestmentInBuyingPhase,
    project_financing_own_funds_share_in_buying_phase:
      projectCompositionSection.ownFundsInBuyingPhase,
    project_financing_buying_phase_only: projectCompositionSection.financeWholeProject,
    project_financing_object_address: objectInformationSection.address,
    project_financing_object_zip_code: objectInformationSection.zipCode,
    project_financing_object_city: objectInformationSection.city,
    project_financing_object_description: objectInformationSection.description,
    project_financing_lot_size: objectInformationSection.lotSize,
    project_financing_year_of_building: objectInformationSection.year,
    project_financing_year_of_modernization: objectInformationSection.yearOfModernization,
    project_financing_planned_project_start: objectInformationSection.plannedProjectStart,
    project_financing_planned_project_end: objectInformationSection.plannedProjectEnd,
    project_financing_quality_furnishing: objectInformationSection.furnishingQuality,
    project_financing_quality_condition: objectInformationSection.qualityCondition,
    project_financing_balcony: objectInformationSection.balcony,
    project_financing_additional_bathroom: objectInformationSection.additionalBathroom,
    project_financing_lift: objectInformationSection.lift,
    project_financing_cellar: objectInformationSection.cellar,
    project_financing_parking_lots_count: objectInformationSection.parkingLotsCount,
    project_financing_parking_lot_planned_sales_price: objectInformationSection.parkingLotPrice,
    project_financing_parking_lot_rent_current: objectInformationSection.parkingLotRentNow,
    project_financing_parking_lot_rent_future: objectInformationSection.parkingLotRentFuture,
    project_financing_garages_count: objectInformationSection.garagesCount,
    project_financing_garage_planned_sales_price: objectInformationSection.garagesPrice,
    project_financing_garage_rent_current: objectInformationSection.garageRentNow,
    project_financing_garage_rent_future: objectInformationSection.garageRentFuture,
    project_financing_object_type_future: objectInformationSection.futureObjectType,
    ...futureUsage,
    project_financing_usage_deviation: objectInformationSection.usageDeviation,
    project_financing_object_type_current: objectInformationSection.currentObjectType,
    ...currentUsage,
    project_financing_total_rent_residential_current:
      objectInformationSection.currentTotalRentResidential,
    project_financing_total_rent_commercial_current:
      objectInformationSection.currentTotalRentCommercial,
    project_financing_total_rent_residential_future:
      objectInformationSection.futureTotalRentResidential,
    project_financing_total_rent_commercial_future:
      objectInformationSection.futureTotalRentCommercial,
    project_financing_vacancy_residential_in_sqm: objectInformationSection.vacancyResidential,
    project_financing_vacancy_commercial_in_sqm: objectInformationSection.vacancyCommercial,
    project_financing_rental_contracts: objectInformationSection.rentalContracts,
    project_financing_rental_intents: objectInformationSection.rentalIntends,
    project_financing_demolition_necessary: otherProjectInformation.demolitionNecessary,
    project_financing_heritage_protected: otherProjectInformation.heritageProtected,
    project_financing_development_necessary: otherProjectInformation.developmentNecessary,
    project_financing_own_sales_planned: otherProjectInformation.ownSalesPlanned,
    project_financing_energy_pass: otherProjectInformation.energyPass,
    project_financing_building_permit: otherProjectInformation.buildingPermit,
    project_financing_permit_basis: otherProjectInformation.permitBasis,
    project_financing_permit_status: otherProjectInformation.permitStatus,
    project_financing_notes_on_permit: otherProjectInformation.notesOnPermit,
  };
}

function mapCompanyDetailsToApi(values: MittweidaFieldTypes) {
  const { completingCompanyDetailsSection } = values.companyDetailPage;

  return {
    'company-attributes': {
      name: completingCompanyDetailsSection.company.name,
      street: completingCompanyDetailsSection.company.street,
      'zip-code': completingCompanyDetailsSection.company.zipCode,
      city: completingCompanyDetailsSection.company.city,
      industry: completingCompanyDetailsSection.company.industry,
      'legal-form': completingCompanyDetailsSection.company.legalForm,
      'founding-year': completingCompanyDetailsSection.company.foundingYear,
      'turnover-class': completingCompanyDetailsSection.company.turnoverClass,
      'already-customer': completingCompanyDetailsSection.company.alreadyCustomer,
      contact_to_bank_advisor: completingCompanyDetailsSection.company.alreadyContactWithBank,
      bank_advisor_name: completingCompanyDetailsSection.company.nameOfContactPerson,
    },
  };
}

function mapUserDetailsToApi(values: MittweidaFieldTypes, mode: InquiryMode, isLoggedIn: boolean) {
  const { contactPersonSection, termsAndConditionsSection, passwordSection } =
    values.userProfilePage;

  if (mode === 'create' && !isLoggedIn) {
    return {
      'user-attributes': {
        salutation: contactPersonSection.gender,
        'first-name': contactPersonSection.firstName,
        'last-name': contactPersonSection.lastName,
        email: contactPersonSection.email,
        'phone-number': contactPersonSection.phone,
        'terms-and-conditions-accepted': termsAndConditionsSection.termsAndConditionsAccepted,
        password: passwordSection.password,
      },
    };
  }

  return {};
}

function mapFundingDetailsToApi(values: MittweidaFieldTypes) {
  const { otherFundingSection } = values.fundingDetailsPage;
  return {
    other_product_description: otherFundingSection.description,
    other_financing_description: otherFundingSection.description,
  };
}

const useSendMittweidaInquiry = (mode: InquiryMode) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: MittweidaFieldTypes) => {
    const financingNeedData = mapFinancingNeedData(values);
    const fundingData = mapFundingDetailsToApi(values);
    const companyData = mapCompanyDetailsToApi(values);
    const userData = mapUserDetailsToApi(values, mode, isLoggedIn);

    const apiData = {
      ...financingNeedData,
      ...fundingData,
      ...userData,
      ...companyData,
      form_type: 'profi_mittweida',
      purpose_kind: 'project_financing',
      product_kind: 'other',
      compeon_partner: 'mittweida',
      'consider-subsidies': false,
    };

    const sendMittweidaInquiry = () => createSpecializedInquiryAction(apiData, 'profi');
    const updateMittweidaInquiry = (inquiryId: string) =>
      updateSpecializedInquiryAction(inquiryId, apiData);
    return makeCall(inquiryId ? updateMittweidaInquiry(inquiryId) : sendMittweidaInquiry());
  };
};

const useSendInquiry = (mode: InquiryMode) => {
  return useSendMittweidaInquiry(mode);
};

type MittweidaFormProps = {
  children: React.ReactNode;
  initialValues: MittweidaFieldTypes | null;
  handleSubmit: (values: MittweidaFieldTypes) => void;
};

const MittweidaForm = ({ children, initialValues, handleSubmit }: MittweidaFormProps) => {
  const {
    formStateData: { initialFormValues },
  } = useInquiryProcessConfig();

  return (
    <Form
      decorators={[persistDecorator]}
      subscription={{ submitting: true, pristine: true }}
      initialValues={initialValues ?? initialFormValues}
      mutators={{ ...arrayMutators }}
      onSubmit={(values) => handleSubmit(values)}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} id={MITTWEIDA_FORM}>
            {children}
            <NewProgressBar />
          </form>
        );
      }}
    />
  );
};

const EditCustomerRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.financingNeed}
        component={MittweidaFinancingNeedStep}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.companyDetails}
        component={MittweidaCompanyStep}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.requestDetails}
        component={MittweidaFundingDetailsStep}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.userProfile}
        component={MittweidaStaticUserProfileStep}
      />
      <Route
        exact
        path={paths.customer.inquiryDetails.edit.summaryPage}
        component={MittweidaSummaryStep}
      />
    </Switch>
  );
};

const EditCustomerRoutesWithGuard = withCanEditInquiryGuard(EditCustomerRoutes);

function useRootRedirect(mode: InquiryMode) {
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();

  const editPath =
    mode === 'editOperation'
      ? paths.operation.inquiryDetails.edit.financingNeed
      : paths.customer.inquiryDetails.edit.financingNeed;
  const inquiryListPath =
    mode === 'editOperation' ? paths.operation.inquiryList : paths.customer.inquiryList;

  useEffect(() => {
    if (inquiryId) {
      history.push(editPath.replace(':id', inquiryId));
    } else {
      history.push(inquiryListPath);
    }
  }, [editPath, history, inquiryId, inquiryListPath]);
}

export const MittweidaEditCustomerPortalForm = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendInquiry('editCustomer');
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();
  useRootRedirect('editCustomer');

  const handleSubmit = async (values: MittweidaFieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.customer.inquiryDetails.details.replace(':id', inquiryId!!));
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
  };

  return (
    <MittweidaForm initialValues={initialValues} handleSubmit={handleSubmit}>
      <EditCustomerRoutesWithGuard />
    </MittweidaForm>
  );
};

const EditOperationRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.operation.inquiryDetails.edit.financingNeed}
        component={MittweidaFinancingNeedStep}
      />
      <Route
        exact
        path={paths.operation.inquiryDetails.edit.companyDetails}
        component={MittweidaCompanyStep}
      />
      <Route
        exact
        path={paths.operation.inquiryDetails.edit.requestDetails}
        component={MittweidaFundingDetailsStep}
      />
      <Route
        exact
        path={paths.operation.inquiryDetails.edit.userProfile}
        component={MittweidaStaticUserProfileStep}
      />
      <Route
        exact
        path={paths.operation.inquiryDetails.edit.summaryPage}
        component={MittweidaSummaryStep}
      />
    </Switch>
  );
};

const EditOperationRoutesWithGuard = withCanEditInquiryGuard(EditOperationRoutes);

export const MittweidaEditOperationPortalForm = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendInquiry('editOperation');
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();
  useRootRedirect('editOperation');

  const handleSubmit = async (values: MittweidaFieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.operation.inquiryDetails.details.replace(':id', inquiryId!!));
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
  };

  return (
    <MittweidaForm initialValues={initialValues} handleSubmit={handleSubmit}>
      <EditOperationRoutesWithGuard />
    </MittweidaForm>
  );
};

export const MittweidaCreateForm = () => {
  const initialValues = useGetInitialValuesForCreateMode();
  const sendInquiry = useSendInquiry('create');
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();

  const handleSubmit = async (values: MittweidaFieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.finalPage);
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
  };

  return (
    <MittweidaForm initialValues={initialValues} handleSubmit={handleSubmit}>
      <Switch>
        <Route exact path={paths.financingNeed} component={MittweidaFinancingNeedStep} />
        <Route exact path={paths.companyDetails} component={MittweidaCompanyStep} />
        <Route exact path={paths.requestDetails} component={MittweidaFundingDetailsStep} />
        <Route exact path={paths.userProfile} component={MittweidaUserProfileStep} />
        <Route exact path={paths.summaryPage} component={MittweidaSummaryStep} />
        <Route exact path={paths.finalPage} component={SpecializedFinalPage} />
      </Switch>
    </MittweidaForm>
  );
};
