import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { ConfigController } from 'config/ConfigController';
import paths from 'constants/paths';
import useInitInquiryFormByDraft from 'modules/Inquiry/DraftMode/InquiryFormInitializer/useInitInquiryFormByDraft';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getInquiryFlowStartUrl } from 'store/config/selectors';
import { getInquiryListSelector } from 'store/inquiryList/selectors';
import { setInquiryType } from 'store/inquiryProcess/actions';

const InquiryFormDraftInitializer = () => {
  const { id: inquiryDraftId } = useParams();
  const startPoint = useSelector(getInquiryFlowStartUrl);
  const { isInitialized } = useInitInquiryFormByDraft({ inquiryDraftId });
  const inquiries = useSelector(getInquiryListSelector);
  const formType =
    inquiries.find((inquiry) => inquiry.id === inquiryDraftId).formType ?? InquiryType.default;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInquiryType(formType));
    ConfigController.form.selectInquiryType(formType, true);
  }, [dispatch, formType]);

  if (!isInitialized) {
    return null;
  }

  const draftStartingPoints = {
    [InquiryType.default]: startPoint,
    [InquiryType.leaseplan]: paths.customer.inquiryDetails.edit.contractDetails.replace(
      ':id',
      inquiryDraftId,
    ),
  };

  return <Redirect to={draftStartingPoints[formType] ?? startPoint} />;
};

export default InquiryFormDraftInitializer;
