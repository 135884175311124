import _get from 'lodash/get';

import {
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K,
} from 'modules/Inquiry/Form/formFields';

const mapMarketValueFromApi = (response: unknown) => {
  const currentUsage = _get(response, 'current_usage');
  const futureUsage = _get(response, 'future_usage');

  const marketValue = {
    [CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM]: _get(currentUsage, 'residential_rent_per_sqm') || 0,
    [CURRENT_USAGE_EXISTING_RENT_PER_SQM]: _get(currentUsage, 'existing_rent_per_sqm') || 0,
    [CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM]: _get(currentUsage, 'commercial_rent_per_sqm') || 0,
    [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
      _get(currentUsage, 'comparative_value_per_sqm.building') || 0,
    [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT]:
      _get(currentUsage, 'comparative_value_per_sqm.unit') || 0,
    [FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM]: _get(futureUsage, 'residential_rent_per_sqm') || 0,
    [FUTURE_USAGE_EXISTING_RENT_PER_SQM]: _get(futureUsage, 'existing_rent_per_sqm') || 0,
    [FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM]: _get(futureUsage, 'commercial_rent_per_sqm') || 0,
    [FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K]: _get(futureUsage, 'market_value_per_sqm_WHG_K') || 0,
    [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
      _get(futureUsage, 'comparative_value_per_sqm.building') || 0,
    [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT]:
      _get(futureUsage, 'comparative_value_per_sqm.unit') || 0,
  };

  return marketValue;
};

export default mapMarketValueFromApi;
