import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall, { IMakeCall } from 'utils/hooks/useDispatchApiCallHook';

type Props = {
  pathToEdit?: string;
  recordType: string;
  loadData(makeCall: IMakeCall, inquiryId: string, recordType: string): void;
  editFormStructure: EditMarketValue[];
};

const useEditFormConfig = ({ pathToEdit, recordType, loadData, editFormStructure }: Props) => {
  const { makeCall } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const history = useHistory();

  const onEdit = async () => {
    if (loadData) {
      await loadData(makeCall, inquiryId, recordType);
    }

    history.push({
      pathname: pathToEdit,
      state: {
        editFormStructure,
        recordType,
        inquiryId,
      },
    });
  };
  return { onEdit };
};

export default useEditFormConfig;
