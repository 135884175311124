import React, { ReactNode } from 'react';

import {
  StyledTableHeaderCell as HeaderCell,
  StyledTableHeaderContent as HeaderContent,
} from 'components/PortalTable/styles';
import SortingArrows from 'components/PortalTable/TableHeaders/SortingArrows';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';

interface IInquiryTableHeaders {
  children: ReactNode;
  columnId: string;
  onSortChange: (columnKey: string) => void;
  sortBy: string;
  sortDirection?: SORT_DIRECTION;
  headerColor?: string;
}

const InquiryTableHeader: React.FC<IInquiryTableHeaders> = ({
  children,
  columnId,
  onSortChange,
  sortBy,
  sortDirection,
  headerColor,
}: IInquiryTableHeaders) => {
  return (
    <HeaderCell onClick={() => onSortChange(columnId)} scope="col" color={headerColor}>
      <HeaderContent>
        {children}
        {sortDirection && (
          <SortingArrows
            isSorted={sortBy === columnId}
            sortedDesc={sortDirection === SORT_DIRECTION.DESC}
          />
        )}
      </HeaderContent>
    </HeaderCell>
  );
};

export default InquiryTableHeader;
