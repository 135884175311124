import * as React from 'react';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FeatureFlags } from '../../../../shared/featureFlagConfig/configFromAdmin';
import { getInquiryIdSelector } from '../../../../store/inquiryDetails/selectors';
import { useTranslations } from '../../../../utils/hooks/useTranslations';
import { FeatureFlagNavLink } from './FeatureFlagNavLink';

export type NavItem = {
  path: (inquiryId: string) => string;
  labelKey: string;
  featureFlag?: FeatureFlags;
};

export const SubNavigationItem = ({ path, labelKey, featureFlag }: NavItem) => {
  const inquiryId = useSelector(getInquiryIdSelector) as string;
  const t = useTranslations();

  if (featureFlag) {
    return (
      <FeatureFlagNavLink to={path(inquiryId)} featureFlag={featureFlag}>
        {t(labelKey)}
      </FeatureFlagNavLink>
    );
  } else {
    return (
      <li>
        <NavLink activeClassName="active" to={path(inquiryId)}>
          {t(labelKey)}
        </NavLink>
      </li>
    );
  }
};
