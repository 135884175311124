import React from 'react';

import { bool } from 'prop-types';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import LogoutButton from 'components/LogoutButton';
import StaticField from 'components/StaticField';
import VMNumber from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson/ForLoggedInUser/DVAG/VMNumber';
import { getUser as getCustomerSelector } from 'store/user/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const DvagForLoggedInUser = ({ editMode }) => {
  const customer = useSelector(getCustomerSelector);

  const contactPersonTranslator = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields',
  );
  const completingContactDataTranslator = useTranslations(
    'pages.userProfile.sections.completingContactData.fields',
  );

  const tOther = useTranslations('other');

  return (
    <>
      <FormRow alignItems="flex-start">
        <StaticField
          caption={completingContactDataTranslator('userProfileName.caption')}
          text={`${contactPersonTranslator(`companyDetailsGender.genders.${customer.gender}`)} ${
            customer.firstName
          } ${customer.lastName}`}
        />
        <StaticField
          caption={completingContactDataTranslator('userProfileEmail.caption')}
          text={customer.email}
        />
        {!editMode && <LogoutButton />}
      </FormRow>
      <FormRow>
        <StaticField
          caption={contactPersonTranslator('companyDetailsVbNumber.caption')}
          text={customer.vbNumber || tOther('notAvailable')}
        />
      </FormRow>
      <FormRow>
        <VMNumber />
      </FormRow>
    </>
  );
};

DvagForLoggedInUser.propTypes = {
  editMode: bool,
};

DvagForLoggedInUser.defaultProps = {
  editMode: false,
};

export default DvagForLoggedInUser;
