import * as React from 'react';

import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { useOfferFields } from 'modules/helpers/useOfferFields';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  getIndicativeCondition,
  getIndicativeConditionCompleted,
  getIndicativeOffer,
  getInquiryLane,
} from 'store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import { HeadingComponent } from 'theme/components/Heading';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getIndicativeOfferFormattedValueMap } from './utils';

type ProgressIndicativeOfferProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl?: string;
  id?: string;
};

export const ProgressIndicativeOffer = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: ProgressIndicativeOfferProps) => {
  const t = useTranslations();
  const hasOffer = useSelector(getIndicativeConditionCompleted);
  const indicativeConditionCompleted = useSelector(getIndicativeConditionCompleted);
  const indicativeConditions = useSelector(getIndicativeCondition) as Record<string, string>;
  const indicativeOffer = useSelector(getIndicativeOffer) as Record<string, string>;
  const lane = useSelector(getInquiryLane);
  const isContract = lane === InquiryLane.contract;
  const contractHeader = t('pages.inquiryDetails.dashboard.actions.indicativeOffer.headerContract');
  const contractDescription = t(
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.description.contract',
  );
  const description =
    hasOffer || !!indicativeOffer
      ? t('pages.inquiryDetails.dashboard.actions.indicativeOffer.description.offer')
      : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.description.noOffer');
  const fields = useOfferFields();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
        {isContract
          ? contractHeader
          : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.header')}
      </HeadingComponent>

      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={6} mb={6}>
        {' '}
        <GridItem>
          <TextComponent>
            <MarkdownWrapper text={isContract ? contractDescription : description} />
          </TextComponent>
        </GridItem>
      </Grid>

      <Grid templateColumns={['repeat(1, 1fr)', null, null, '1fr 1fr 1fr']} gap={6}>
        {fields.map((item) => (
          <GridItem key={item.name}>
            <Box
              border="1px"
              borderColor="border.lightGrey"
              p="8"
              bgColor="background.white"
              boxShadow="default"
            >
              <DescriptionList>
                <DescriptionListTerm display="flex" alignItems="center">
                  {t(item.title)}
                  {item.info && (
                    <PopoverComponent
                      ml="1px"
                      transform="translateY(-1px)"
                      text={t(item.info)}
                      trigger={
                        <IconButtonComponent
                          icon={<InfoIcon boxSize={6} d="block" />}
                          label={t('buttons.moreInfos')}
                        />
                      }
                    />
                  )}
                </DescriptionListTerm>
                <DescriptionListDescription>
                  {indicativeConditionCompleted
                    ? getIndicativeOfferFormattedValueMap(
                        item,
                        indicativeConditions[item.name] ?? indicativeOffer[item.name],
                      )
                    : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.comingSoon')}
                </DescriptionListDescription>
              </DescriptionList>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </ProgressSection>
  );
};
