import {
  USER_PROFILE_PHONE_NUMBER,
  BANK_DETAILS_IBAN,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
  BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
  BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
  BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
  BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
  BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
  BFS_SERVICE__NEED_FURTHER_ADVICE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

import { pushSelectedFlagInArray } from '../utils';

const getFurtherNeeds = (allFields: any) => {
  const futherNeeds: any[] = [];
  pushSelectedFlagInArray(
    allFields,
    futherNeeds,
    BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
  );
  pushSelectedFlagInArray(allFields, futherNeeds, BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE);
  pushSelectedFlagInArray(
    allFields,
    futherNeeds,
    BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
  );
  pushSelectedFlagInArray(allFields, futherNeeds, BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE);
  pushSelectedFlagInArray(
    allFields,
    futherNeeds,
    BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
  );
  pushSelectedFlagInArray(allFields, futherNeeds, BFS_SERVICE__EXPLICIT_NEED_SEMINARS);
  return futherNeeds;
};

export const mapBfsServiceRequestDetailToAPI: InquiryFormMapper = (allFields: any) => ({
  [USER_PROFILE_PHONE_NUMBER]: allFields[USER_PROFILE_PHONE_NUMBER],
  [BFS_SERVICE__EXPLICIT_NEED_SEMINARS]: allFields[BFS_SERVICE__EXPLICIT_NEED_SEMINARS],
  source_of_information: allFields[BFS_SERVICE__FACTORING_OFFER],
  further_need: allFields[BFS_SERVICE__NEED_FURTHER_ADVICE],
  'further-need-values': getFurtherNeeds(allFields),
  other_source_of_information: allFields[BFS_SERVICE__SOURCE],
  iban: allFields[BANK_DETAILS_IBAN] || null,
  [BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES]: allFields[BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES],
});
