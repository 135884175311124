import React, { ReactNode } from 'react';

import {
  COMPANY_DETAILS,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_PROFILE_COMPANY_NAME,
  COMPANY_DETAILS_COMPANY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_ZIP_CODE,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  REQUEST_DETAILS,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_INDUSTRY,
  BFS_USER_PROFILE_INDUSTRY,
  USER_PROFILE_FOUNDING_YEAR,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import SummaryField from 'modules/SummaryTable/SummaryField';
import { StyledSummaryRows } from 'pages/inquiryFlow/SummaryPage/NewSummaryPage/styles';
import RequestDetailsAdditionalFields from 'pages/inquiryFlow/SummaryPage/RequestDetailsAdditionalFields';

// some steps require custom data parsing
export const customSummaryDataParsers: {
  [stepName: string]: (formValues: any, selectedInquiryType: InquiryType) => ReactNode;
} = {
  [COMPANY_DETAILS]: (formValues: any, selectedInquiryType: InquiryType) => {
    if (
      formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] &&
      formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] !==
        COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
    ) {
      const companyFieldsRow1 = [
        {
          name: COMPANY_DETAILS_COMPANY,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY].name,
        },
        {
          name: USER_PROFILE_COMPANY_STREET,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY].street,
        },
        {
          name: USER_PROFILE_COMPANY_CITY,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY].city,
        },
        {
          name: USER_PROFILE_COMPANY_ZIP_CODE,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['zip-code'],
        },
      ];
      const industryField =
        selectedInquiryType === InquiryType.bfs
          ? {
              name: BFS_USER_PROFILE_INDUSTRY,
              value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['industry'],
            }
          : {
              name: USER_PROFILE_INDUSTRY,
              value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['industry'],
            };
      const companyFieldsRow2 = [
        {
          name: USER_PROFILE_LEGAL_FORM,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['legal-form'],
        },
        industryField,
        {
          name: USER_PROFILE_TURNOVER_CLASS,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['turnover-class'],
        },
        {
          name: USER_PROFILE_FOUNDING_YEAR,
          value: formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY]['founding-year'],
        },
      ];
      return (
        <>
          <StyledSummaryRows>
            {companyFieldsRow1.map((field) => (
              <SummaryField fieldName={field.name} fieldValue={field.value} />
            ))}
          </StyledSummaryRows>
          <StyledSummaryRows>
            {companyFieldsRow2.map((field) => (
              <SummaryField fieldName={field.name} fieldValue={field.value} />
            ))}
          </StyledSummaryRows>
        </>
      );
    }

    if (
      formValues[COMPANY_DETAILS_COMPANY] &&
      formValues[COMPANY_DETAILS_COMPANY] !== COMPANY_DETAILS_COMPANY__NOT_FOUND
    ) {
      return (
        <StyledSummaryRows>
          {[
            {
              name: COMPANY_DETAILS_COMPANY,
              value: formValues[COMPANY_DETAILS_COMPANY].name,
            },
            {
              name: USER_PROFILE_COMPANY_STREET,
              value: formValues[COMPANY_DETAILS_COMPANY].street,
            },
            {
              name: USER_PROFILE_COMPANY_CITY,
              value: formValues[COMPANY_DETAILS_COMPANY].city,
            },
            {
              name: USER_PROFILE_COMPANY_ZIP_CODE,
              value: formValues[COMPANY_DETAILS_COMPANY]['zip-code'],
            },
          ].map((field) => (
            <SummaryField fieldName={field.name} fieldValue={field.value} />
          ))}
        </StyledSummaryRows>
      );
    }
    return (
      <StyledSummaryRows>
        {[
          {
            name: COMPANY_DETAILS_COMPANY,
            value: formValues[USER_PROFILE_COMPANY_NAME],
          },
          {
            name: USER_PROFILE_COMPANY_STREET,
            value: formValues[USER_PROFILE_COMPANY_STREET],
          },
          {
            name: USER_PROFILE_COMPANY_CITY,
            value: formValues[USER_PROFILE_COMPANY_CITY],
          },
          {
            name: USER_PROFILE_COMPANY_ZIP_CODE,
            value: formValues[USER_PROFILE_COMPANY_ZIP_CODE],
          },
        ].map((field) => (
          <SummaryField fieldName={field.name} fieldValue={field.value} />
        ))}
      </StyledSummaryRows>
    );
  },
  [REQUEST_DETAILS]: (formValues) => <RequestDetailsAdditionalFields formValues={formValues} />,
};
