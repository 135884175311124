import React from 'react';

import { MittweidaFieldTypes } from '../../fields';

const SummaryStepContext =
  React.createContext<{ values: MittweidaFieldTypes } | undefined>(undefined);

export const useSummaryStepContext = () => {
  const context = React.useContext(SummaryStepContext);
  if (!context) {
    throw new Error('useSummaryStepContext must be used within a SummaryStepContext');
  }
  return context;
};

export const SummaryStepProvider = ({
  children,
  values,
}: {
  children: React.ReactNode;
  values: MittweidaFieldTypes;
}) => {
  return <SummaryStepContext.Provider value={{ values }}>{children}</SummaryStepContext.Provider>;
};
