import { useState } from 'react';

import {
  useFileValidator,
  DEFAULT_VALIDATORS,
  PDF_VALIDATORS,
} from 'components/UploadBlock/hooks/useFileValidator';
import useNewFileStatusListener from 'components/UploadBlock/hooks/useNewFileStatusListener';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import {
  mapErrorsFromApi,
  UPLOADING_STATE,
} from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import { UploadedFile } from 'models/File.model';
import { useTimeout } from 'utils/hooks/useTimeout';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useSelectFile = (
  onSuccess: (uploadedFile: UploadedFile, selectedFile: File) => void,
  url: string,
  fileRequestId?: string,
  isREE?: boolean,
  isPrivateFile?: boolean,
  isAssessmentFile?: boolean,
) => {
  const t = useTranslations('components.upload');
  const [isDropping, setIsDropping] = useState(false);
  const [uploadingState, setUploadingState] = useState(UPLOADING_STATE.IDLE);
  const resetUploadStateAfterDelay = useTimeout(() => setUploadingState(UPLOADING_STATE.IDLE));
  const validators = isAssessmentFile ? PDF_VALIDATORS : DEFAULT_VALIDATORS;
  const validateFile = useFileValidator(validators);
  const { startUpload } = useNewUploadFile(url);
  const startScanningFile = useNewFileStatusListener({ isPrivateFile, isAssessmentFile });

  const [errorMsg, setErrorMsg] = useState('');
  const showError = (err: string | object) => {
    setErrorMsg(mapErrorsFromApi(err));
    resetUploadStateAfterDelay();
    setUploadingState(UPLOADING_STATE.ERROR);
  };

  const onFileSelected = (selectedFile: File) => {
    validateFile(selectedFile)
      .then((file) => {
        if (file === null || typeof file === 'string') throw new Error(t('validationFailed'));
        setUploadingState(UPLOADING_STATE.UPLOADING);
        return startUpload(
          file,
          fileRequestId || isREE
            ? {
                ...(fileRequestId && { file_upload_request_id: fileRequestId }),
                ...(isREE && { is_real_estate_expert: true }),
              }
            : null,
        );
      })
      .then((file) => {
        setUploadingState(UPLOADING_STATE.SCANNING);
        return startScanningFile(file);
      })
      .then((uploadedFile) => {
        const hasPassedScanning = uploadedFile.attributes.status;
        if (!hasPassedScanning) {
          throw new Error(t('virusScanFailed'));
        }

        setUploadingState(UPLOADING_STATE.FINISHED);
        resetUploadStateAfterDelay();
        onSuccess(uploadedFile, selectedFile);
      })
      .catch(showError);
  };

  return { onFileSelected, errorMsg, isDropping, uploadingState, setIsDropping };
};
