const makeCancellableCallback = (callback: (result: any) => void) => {
  let isCancelled = false;

  return {
    cancel: () => {
      isCancelled = true;
    },
    callback: (result: any) => {
      if (!isCancelled) {
        callback(result);
      }
    },
  };
};

export default makeCancellableCallback;
