import { isOnlyLettersRegex } from 'utils/regexes';

import {
  customValidations,
  fieldValidators,
} from '../../../../../../modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  validateFieldValidationMap,
  ValidateSectionFunction,
} from '../../../../../../new/form/useValidateFields';
import {
  isEmailAvailibleSelector,
  isLoadingEmailAvailabilitySelector,
} from '../../../../../../store/emailAvailable/selectors';
import { isLoggedInSelector } from '../../../../../../store/user/selectors';
import { mittweidaFields } from '../../../../fields';

export const validateContactPersonSection: ValidateSectionFunction = (form, values, store) => {
  const isEmailAvailable = isEmailAvailibleSelector(store.getState());
  const isEmailLoading = isLoadingEmailAvailabilitySelector(store.getState());
  const isLoggedIn = isLoggedInSelector(store.getState());

  if (isLoggedIn) {
    return true;
  }

  return (
    validateFieldValidationMap({
      validationMap: contactPersonSectionValidationMap,
      form,
      values,
    }) &&
    isEmailAvailable &&
    !isEmailLoading
  );
};

const { gender, firstName, lastName, email, phone } =
  mittweidaFields.userProfilePage.contactPersonSection;

export const contactPersonSectionValidationMap = {
  [gender]: fieldValidators.string().required(),
  [firstName]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [lastName]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [email]: fieldValidators.string().required().custom(customValidations.isEmail),
  [phone]: fieldValidators
    .phoneNumber()
    .required()
    .hasPhonePrefix()
    .isPhoneNumber()
    .maxPhoneNumberLength()
    .minPhoneNumberLength(),
};
