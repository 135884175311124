import normalize from 'json-api-normalizer';

const mapFromApiInterface = (rawData) => {
  const { portalUsers } = normalize(rawData);

  const mappedArray = rawData.data.map(({ id, relationships, attributes }) => ({
    id,
    user: portalUsers[relationships.author.data.id].attributes,
    content: attributes.content,
    createdAt: attributes.created_at,
  }));
  return mappedArray;
};

export default mapFromApiInterface;
