import React from 'react';

import PropTypes from 'prop-types';

import { CompeonOfferShape } from 'models/CompeonOffer.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useOffersPdfGenerator } from 'modules/Offers/InquiryOffersSection/CompeonOffers/pdfGenerator/useOffersPdfGenerator';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const DownloadAllOffersButton = ({ offers }) => {
  const generateAndDownloadPDF = useOffersPdfGenerator(offers);
  const { error } = useToasts();
  const t = useTranslations('pages.inquiryDetails.offers.compeon');

  const handlePdfGeneration = async () => {
    try {
      await generateAndDownloadPDF();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      error({ description: t('pdfFailed') });
    }
  };

  return <ButtonComponent onClick={handlePdfGeneration}>{t('downloadPdfSummary')}</ButtonComponent>;
};

DownloadAllOffersButton.propTypes = {
  offers: PropTypes.arrayOf(CompeonOfferShape).isRequired,
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DownloadAllOffersButton,
  default: () => null,
});
