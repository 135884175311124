import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { isSectionValid } from 'modules/Inquiry/Form/formConditions';

import {
  FactoringPurposeSection,
  validateFactoringPurposeSection,
} from './FacotringPurposeSection';
import { OtherSection } from './OtherSection';
import { validateYourBusinessSection, YourBusinessSection } from './YourBusinessSection';

export const OnlineFactoringSections = () => {
  return (
    <>
      <FactoringPurposeSection />
      <Condition
        condition={isSectionValid(validateFactoringPurposeSection)}
        onFail={<DisabledFormSection sectionNumber={2} />}
      >
        <YourBusinessSection />
        <Condition
          condition={isSectionValid(validateYourBusinessSection)}
          onFail={<DisabledFormSection sectionNumber={3} />}
        >
          <OtherSection />
        </Condition>
      </Condition>
    </>
  );
};
