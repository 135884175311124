import { atLeastOne, fieldHasTruthyValue } from 'modules/Inquiry/Form/formConditions';
import {
  EXPERT_ADVICE_ON_ALL,
  FUNDRAISING,
  INVESTMENT_AND_SECURITIES,
  OTHER_FINANCING_SOLUTIONS,
  PAYMENT_TRANSACTIONS,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
  PUBLIC_FUNDING,
  FURTHER_NEED,
} from 'modules/Inquiry/Form/formFields';
import { FormFieldController } from 'store/FormFieldController/FieldController';

export const furtherNeedFields = [
  fieldHasTruthyValue(EXPERT_ADVICE_ON_ALL),
  fieldHasTruthyValue(FUNDRAISING),
  fieldHasTruthyValue(INVESTMENT_AND_SECURITIES),
  fieldHasTruthyValue(OTHER_FINANCING_SOLUTIONS),
  fieldHasTruthyValue(PAYMENT_TRANSACTIONS),
  fieldHasTruthyValue(PRODUCT_KIND__FACTORING),
  fieldHasTruthyValue(PRODUCT_KIND__LEASING),
  fieldHasTruthyValue(PUBLIC_FUNDING),
];

export const validateFurtherNeedSection = (form) => {
  const fieldController = FormFieldController.instance;
  const furtherNeedValue = fieldController.formValues[FURTHER_NEED];
  if (furtherNeedValue === 'false') {
    return true;
  } else return atLeastOne(...furtherNeedFields)({ form });
};
