import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import Spinner from 'components/Spinner';
import PERMISSIONS from 'constants/user/permissions';
import {
  fetchInquiryRevisions,
  mapRevisionsListFromApi,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.service';
import RevisionChange from 'modules/InquiryDetails/InquiryRevision/RevisionChange';
import RevisionDates from 'modules/InquiryDetails/InquiryRevision/RevisionDates';
import {
  StyledDetailedSection as Section,
  StyledRevisionWrapper as Wrapper,
  StyledRevisionSidebar as Sidebar,
  StyledRevisionTitle as Title,
  StyledRevisionChanges as RevisionChanges,
} from 'modules/InquiryDetails/InquiryRevision/styles';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { REVISION_ACTIONS } from './actions';

const InquiryRevision: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const t = useTranslations('pages.inquiryDetails.changelogs');

  const { isPending: isLoading, makeCall } = useDispatchApiCall();
  useEffect(() => {
    dispatch({ type: REVISION_ACTIONS.RESET_REVISIONS_STATE });
    makeCall(fetchInquiryRevisions(inquiryId || ''), ({ payload }: any) => {
      dispatch({
        type: REVISION_ACTIONS.SET_REVISIONS_STATE,
        data: mapRevisionsListFromApi(payload.data),
      });
    });
  }, [dispatch, inquiryId, makeCall]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Section>
      <Title>{t('title')}</Title>
      <Wrapper>
        <Sidebar>
          <RevisionDates />
        </Sidebar>
        <RevisionChanges>
          <RevisionChange />
        </RevisionChanges>
      </Wrapper>
    </Section>
  );
};

export default compose<any>(
  withConfigFlagGuard(CONFIG.IS_CHANGELOG_ON),
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.CHANGELOG),
)(InquiryRevision);
