import { COMPEON_PARTNER, PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import { mapCompanyDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/companyMappers/mapCompanyDetailsToApi';
import { mapFinancingNeedPurposeFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapFinancingNeedPurposeFieldsToApi';

import { mapBFSServicePeopleDetailsToApi } from './peopleMapper/bfsServicemapPeopleDetailsToApi';
import { mapBfsServiceRequestDetailToAPI } from './requestDetailsMappers/mapBfsServiceRequestDetailToAPI';
import { mapBFSServiceUserDetailsToApi } from './userMappers/mapUserDetailsToApi';

export const mapBFSServiceInquiryToApi = (allFields: any, isLoggedIn: boolean) => {
  return {
    'compeon-partner': allFields[COMPEON_PARTNER],
    'product-kind': allFields[PURPOSE_KIND],
    lane: 'lead',
    ...mapFinancingNeedPurposeFieldsToApi(allFields),
    ...mapCompanyDetailsToApi(allFields),
    ...mapBfsServiceRequestDetailToAPI(allFields),
    ...mapBFSServicePeopleDetailsToApi(allFields),
    ...mapBFSServiceUserDetailsToApi(allFields, isLoggedIn),
  };
};
